/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import PostV2, { IResponseCalendarDayPosts } from 'api/PostV2'
import Utils from 'api/Utils'
import { Box, Icons } from 'components'
import SkeletonCard from 'pages/ai-discover/UI/SkeletonCard'
import { useEffect, useRef, useState } from 'react'
import { useCalendarStore } from 'stores/CalendarStore'
import { AppStore, T } from 'utils'
import { SpecificDayCard } from './SpecificDayCard'
import { CardContainer, EmptyState, GridContainer } from 'components/UI'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {}

export const SpecificDayContent = ({}: Props) => {
  /* ----------------- ROUTER  */
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const paramsDate = searchParams.get('date') as string

  /* ----------------- STATE  */
  const [page, setPage] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [canLoadMore, setCanLoadMore] = useState<boolean>()

  /* ----------------- REF  */
  const loaderRef = useRef<HTMLDivElement>(null)

  /* ----------------- ZUSTAND  */
  const {
    update,
    status,
    labelType,
    labels,
    socials,
    year,
    month,
    day,
    dayPost,
    selectedPostsId,
    calendarSection,
    createdFrom,
  } = useCalendarStore((s) => ({
    update: s.update,
    status: s.status,
    labelType: s.labelType,
    labels: s.labels,
    socials: s.socials,
    year: s.year,
    month: s.month,
    day: s.day,
    dayPost: s.dayPost,
    selectedPostsId: s.selectedPostsId,
    calendarSection: s.calendarSection,
    createdFrom: s.createdFrom,
  }))

  const { setDayPost, setSelectedPostsId } = useCalendarStore()

  /* ----------------- VARIABLES  */
  // Cards to render
  const postData = dayPost.map((post) => (
    <CardContainer
      content={<SpecificDayCard post={post} action={() => onClickCard(post._id)} from="calendar" />}
      isSelectable
      isSelected={selectedPostsId.includes(post._id)}
      action={() => handleSelectId(post._id)}
    />
  ))

  let updatedPostList: IResponseCalendarDayPosts[] | null = dayPost
  let updatedPage = page
  const postPerCall = 18

  /* ----------------- METHODS  */
  /**
   * Funzione che gestisce il click sulla card
   * Se ci sono card selezionate, esegue l'azione passata come prop
   * Altrimenti apre il modal di preview del post
   */
  const onClickCard = (postId: string) => {
    if (selectedPostsId.length > 0) {
      handleSelectId(postId)
    } else {
      navigate(`/calendar?view=day&date=${paramsDate}&postId=${postId}`)
    }
  }

  /**
   * Controlla se è possibile caricare altri post
   */
  const checkCanLoadMore = () => {
    if (updatedPostList) {
      if (updatedPostList.length === 0) return setCanLoadMore(false)

      updatedPostList.length % postPerCall === 0 ? setCanLoadMore(true) : setCanLoadMore(false)
    }
  }

  /**
   * Funzione per update il numero della page
   */
  const updatePage = () => {
    updatedPage = updatedPage + 1
    setPage(page + 1)
  }

  /**
   * Funzione per selezionare / deselezionare un post
   * @param id id del post
   */
  const handleSelectId = (id: string) => {
    if (selectedPostsId.includes(id)) {
      setSelectedPostsId(selectedPostsId.filter((postId) => postId !== id))
    } else {
      setSelectedPostsId([...selectedPostsId, id])
    }
  }

  /**
   * Funzione per caricare altri post quando l'elemento riferito da 'loaderRef' interseca la viewport
   * @param entries IntersectionObserverEntry
   */
  const loadMoreData = (entries: IntersectionObserverEntry[]): void => {
    const target = entries[0]

    if (target.isIntersecting && canLoadMore && !loading) {
      updatePage()
    }
  }

  /**
   * Funzione per resettare la pagina e i post
   */
  const resetPageAndPosts = () => {
    setPage(0)
    updatedPage = 0

    setDayPost([])
    updatedPostList = null

    setLoading(true)
  }

  /* ----------------- API CALL  */
  const getDayPostsWithFilters = async () => {
    setLoading(true)

    const labelParam =
      labels.length === 0
        ? undefined
        : {
            type: labelType,
            labels: labels.map((label) => label._id),
          }

    const params = {
      date: new Date(year, month, day),
      page: updatedPage,
      filters: {
        social: socials,
        status: status,
        labels: labelParam,
        createdFrom: createdFrom,
      },
    }

    try {
      const res = await PostV2.getPosts(params)

      const newPosts = res.data!

      setDayPost(updatedPostList ? [...dayPost, ...newPosts] : newPosts)

      updatedPostList = updatedPostList ? [...dayPost, ...newPosts] : newPosts

      checkCanLoadMore()
    } catch (e) {
      console.error(e)
    }

    setLoading(false)
  }

  /* ----------------- USEEFFECT  */
  // UseEffect per fetchare i post al cambio dei filtri
  useEffect(() => {
    if (calendarSection !== 'day') return
    resetPageAndPosts()
    getDayPostsWithFilters()
  }, [update, day, socials, status, labelType, calendarSection, createdFrom])

  // Al cambio del giorno resetta tutto
  // Al cambio di una sezione ritorna nello stadio iniziale di loading true per far vedere come animazione iniziale le skeleton card
  useEffect(() => {
    setSelectedPostsId([])
    resetPageAndPosts()
  }, [day, calendarSection])

  // UseEffect per fetchare i dati delle nuove pagine
  useEffect(() => {
    if (calendarSection !== 'day') return
    getDayPostsWithFilters()
  }, [page])

  // OBSERVER
  useEffect(() => {
    const observer = new IntersectionObserver(loadMoreData)

    if (loaderRef.current) {
      observer.observe(loaderRef.current)
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current)
      }
    }
  }, [canLoadMore, loading])

  return (
    <SpecificDayContentContainer>
      <InnerContainer id="calendar-scroll">
        {/* Empty state */}
        {!canLoadMore && !loading && dayPost.length === 0 && (
          <EmptyState
            text={T.calendarPage.noPostsOnThisDay}
            icon={<Icons.Preview width={72} height={72} />}
            fill={true}
            noPaddingTop
            styles={{ width: '403px', height: '257px' }}
          />
        )}

        {/* Elements */}
        {dayPost.length > 0 && (
          <GridContainer data={postData} cols={3} colGap={24} rowGap={32} styles={{ marginBottom: '24px' }} />
        )}

        {/* OBSERVER */}
        {dayPost.length !== 0 && <div ref={loaderRef} />}

        {/* Loading skeleton */}
        {(canLoadMore || loading) && (
          <LoadingContainer>
            <SkeletonCard key={Utils.generateRandomUIDKey()} num={18} type="calendarCard" />
          </LoadingContainer>
        )}
      </InnerContainer>
    </SpecificDayContentContainer>
  )
}

const SpecificDayContentContainer = styled(Box)`
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  height: calc(100% - 32px);
  max-height: calc(100% - 32px);
  background-color: ${() => AppStore.theme.o.lightestGrey};
`

const InnerContainer = styled(Box)`
  width: 100%;
  overflow-y: scroll;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 16px;
  padding-right: 8px;
  height: 100%;
`

const LoadingContainer = styled(Box)`
  display: grid;
  align-self: 'center';
  gap: 24px;
  grid-column-gap: 24px;
  grid-row-gap: 32px;
  grid-template-columns: repeat(3, 1fr);
`
