import AcceptColabAccount from 'components/AcceptColabAccount'
import { DashboardPage, LoginPage, RecoverPasswordPage } from 'pages'
import { NotificationEnabled } from 'utils/NotificationAbilitate'
import CreateColabAccount from '../components/CreateColabAccount'
import { AnalyticRouter } from './Pages/Analytics'
import { CalendarRouter } from './Pages/Calendar'
import { CretePostRouter } from './Pages/CreatePost'
import { DashboardRouter } from './Pages/Dashboard'
import { DiscoverRouter } from './Pages/Discover'
import { InboxRouter } from './Pages/Indbox'
import { MediaRouter } from './Pages/Media'
import { SettingsRouter } from './Pages/Settings'

export const routerCollab = [
  {
    path: '/createColabAccount',
    element: <CreateColabAccount />,
  },
]

export const routerNoLogged = [
  {
    path: '/',
    element: <LoginPage onLoginCallback={(user) => {}} />,
  },

  {
    path: '/createColabAccount',
    element: <CreateColabAccount />,
  },
  {
    path: '/acceptColaboration',
    element: <AcceptColabAccount />,
  },
  {
    path: '/recoverPassword',
    element: <RecoverPasswordPage />,
  },
  {
    path: '/notifications/enabling',
    element: <NotificationEnabled />,
  },
  {
    path: '*',
    element: <LoginPage onLoginCallback={(user) => {}} />,
  },
]

export const routerLogged = [
  //-----------------  -----------------  DASHBOARD  ----------------- --------------
  ...DashboardRouter,
  //-----------------  -----------------  POST  ----------------- -------------------
  ...CretePostRouter,
  //-----------------  -----------------  CALENDAR  ----------------- ---------------
  ...CalendarRouter,
  //-----------------  -----------------  Media  ----------------- ------------------
  ...MediaRouter,
  //-----------------  -----------------  Analytic  ----------------- ---------------
  ...AnalyticRouter,
  //-----------------  -----------------  Discover  ----------------- ---------------
  ...DiscoverRouter,
  //-----------------  -----------------  IndBox  ----------------- -----------------
  ...InboxRouter,
  //-----------------  -----------------  Settings  ----------------- ---------------
  ...SettingsRouter,
  //---------------------------------------------------------------------------------
  {
    path: '/acceptColaboration',
    element: <AcceptColabAccount />,
  },
  {
    path: '/notifications/enabling',
    element: <NotificationEnabled />,
  },
  {
    path: '*',
    element: <DashboardPage />,
  },
]
