/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons, SocialIcon } from 'components'
import React, { useState } from 'react'
import { AppStore } from 'utils'
import { IFilter, TFilterType } from 'types'
import { NoResultElement } from './NoResultElement'
import { SocialIconsProvider } from 'components/SocialIcon'

/* ------------------- INTERFACES  */
interface Props {
  filter: IFilter
  isSelected: boolean
  isExpanded: boolean
  action: (key: TFilterType) => void
  data: JSX.Element[]
  oldData: JSX.Element[]
  noResultMessage: string
}

export const FilterElement = ({ filter, isExpanded, isSelected, action, oldData, data, noResultMessage }: Props) => {
  /* ------------------- VARIABLES  */
  const icon: string = Icons[filter.icon]

  /* ------------------- RENDER METHODS  */
  /**
   * Renders the correct Icon based on the "filterIcon" prop
   * @returns Icon component
   */
  const renderIcon = () => {
    return icon ? (
      React.createElement(icon, { width: 24, height: 24 })
    ) : (
      <SocialIcon social={filter.icon as SocialIconsProvider} style={{ width: 28, height: 28 }} />
    )
  }

  return (
    <FilterElementContainer>
      {/* ------------------- FILTER ELEMENT  */}
      <FilterElementWrapper className={isExpanded ? 'selected' : ''} onClick={() => action(filter.key)}>
        <TextContainer>
          {renderIcon()}

          {filter.name}
        </TextContainer>

        <Icons.dropdownArrow
          width={24}
          height={24}
          style={{
            backgroundColor: 'transparent',
            rotate: isExpanded ? '0deg' : '-180deg',
            transition: 'all 0.3s',
          }}
        />
      </FilterElementWrapper>
      {/* ------------------- DATA  */}
      <DataContainer className={isExpanded ? 'show' : ''} dataLength={data?.length}>
        {isSelected ? data?.length === 0 ? <NoResultElement message={noResultMessage} /> : data : oldData}
      </DataContainer>
    </FilterElementContainer>
  )
}

const FilterElementContainer = styled(Box)`
  overflow: hidden;
  border-radius: 0px;
`

const FilterElementWrapper = styled(Box)`
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border: 1px solid ${() => AppStore.theme.o.grey};
  background-color: ${() => AppStore.theme.o.lightestGrey};

  & svg {
    fill: ${() => AppStore.theme.o.black};
  }

  &.selected {
    color: ${() => AppStore.theme.o.blue};
    border-color: ${() => AppStore.theme.o.blueOnLight};
    background-color: ${() => AppStore.theme.o.lightBlue};

    &:hover {
      background-color: ${() => AppStore.theme.o.lightBlue};
    }

    & svg {
      fill: ${() => AppStore.theme.o.blue};
    }
  }
`

const TextContainer = styled(Box)`
  transition: 0s ease-in-out;
  user-select: none;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-align: center;
  flex-direction: row;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
`

const DataContainer = styled(Box)<{ dataLength: number | undefined }>`
  align-items: center;
  width: 100%;
  border-radius: 0px;
  overflow-y: scroll;
  height: 0;
  transition: height 0.4s ease-in-out;

  &.show {
    ${({ dataLength }) => dataLength && `height: calc(${dataLength} * 54px + 20px);`}
  }

  ::-webkit-scrollbar {
    display: none;
  }
`
