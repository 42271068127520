import styled from '@emotion/styled'
import { Component } from 'react'
import { AppStore } from 'utils'

type Props = {
  visible: boolean
}

type State = {}

export default class Background extends Component<Props, State> {
  state = {}

  render() {
    const { visible } = this.props
    if (visible) return <ImgFill src={AppStore.theme.backgroundPostPage} />
    return null
  }
}

const ImgFill = styled.img`
  min-width: 100%;
  min-height: 100%;
  max-height: 100vh;
  /* object-fit: cover; */
  position: absolute;

  top: 0;
  left: 0;
  z-index: 0;
  right: 0;
  bottom: 0;
`
