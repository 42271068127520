import { img } from 'assets/icons'
import { Component } from 'react'

export class Icons extends Component {
  static logo = img.logo
  static profile = img.profile
  static analytics = img.analytics
  static inbox = img.inbox
  static media = img.media
  static calendar = img.calendar
  static dashboard = img.dashboard
  static createPost = img.createPost
  static createPostCalendar = img.createPostCalendar
  static backward = img.backWard
  static forward = img.forWard
  static close = img.close
  static carousel = img.carousel
  static video = img.video
  static navbarMask = img.navbarMask
  static notifications = img.notifications
  static settings = img.settings
  static alarms = img.alarms
  static onBoarding = img.onBoarding
  static boxedPlus = img.boxedPlus
  static plus = img.plus
  static minus = img.minus
  static instagramPreviewIcon = img.instagramPreviewIcon
  static twitterPreviewIcon = img.twitterPreviewIcon
  static linkedinPreviewIcon = img.linkedinPreviewIcon
  static facebookPreviewIcon = img.facebookPreviewIcon
  static googleMyBusinnesPreviewIcon = img.googleMyBusinessPreviewIcon
  static instagramPreviewFooter = img.instagramPreviewFooter
  static facebookPreviewFooter = img.facebookPreviewFooter
  static twitterPreviewFooter = img.twitterPreviewFooter
  static socialProfile = img.socialProfile
  static workSpaces = img.workSpaces
  static subscription = img.subscription
  static lightTheme = img.lightTheme
  static darkTheme = img.darkTheme
  static folder = img.folder
  static edit = img.edit
  static add = img.add
  static selectedBox = img.selectedBox
  static notSelectedBox = img.notSelectedBox
  static download = img.download
  static delete = img.delete
  static move = img.move
  static publishNow = img.publishNow
  static saveDraft = img.saveDraft
  static plan = img.plan
  static addQueue = img.addQueue
  static downIcon = img.downIcon
  static instagramIcon = img.instagramIcon
  static facebookIcon = img.facebookIcon
  static twitterIcon = img.twitterIcon
  static linkedinIcon = img.linkedinIcon
  static googleMyBusinessIcon = img.googleMyBusinessIcon
  static liveChatIcon = img.liveChatIcon
  static serviceCenterIcon = img.serviceCenterIcon
  static accountPlaceholder = img.accountPlaceholder
  static activeBox = img.activeBox
  static trash = img.trash
  static templateVideo = img.templateVideo
  static templateImage = img.templateImage
  static message = img.message
  static smallGps = img.smallGps
  static post = img.post
  static USA = img.usa
  static germany = img.germany
  static italy = img.italy
  static spain = img.spain
  static norway = img.norway
  static heart = img.heart
  static text = img.text
  static checkbox = img.checkbox
  static noImage = img.noImage
  static zoom = img.zoom
  static notCheckedBox = img.notCheckedBox
  static change = img.change
  static logoAstersFullSmall = img.logoAstersFullSmall
  static istagramPrimaryAction = img.istagramPrimaryAction
  static bookMark = img.bookMark
  static imgFolderMark = img.imgFolderMark
  static reverseCheckbox = img.reversecheckbox
  static uploadFile = img.uploadFile
  static createFolder = img.createFolder
  static emojiIcons = img.emojiIcons
  static squareMini = img.squareMini
  static changeArrow = img.changeArrow
  static postInsta = img.postInsta
  static arrow = img.arrow
  static createFolderImgSection = img.createFolderImgSection
  static imgEmpty = img.imgEmpty
  static referalGift = img.referalGift
  static reshare = img.reshare
  static card = img.card
  static share = img.share
  static linkedinIconFillAble = img.linkedinIconFillAble
  static facebookIconFillAble = img.facebookIconFillAble
  static twitterIconFillAble = img.twitterIconFillAble
  static whatAppIconFillAble = img.whatAppIconFillAble
  static telegramIconFillAble = img.telegramIconFillAble
  static emailIconFillAble = img.emailIconFillAble
  static link = img.link
  static user = img.user
  static creditCard = img.creditCard
  static copyLink = img.copyLink
  static openSectionArrow = img.openSectionArrow
  static darkUploadFile = img.darkUploadFile
  static preview = img.preview
  static testi = img.testi
  static medias = img.medias
  static socials = img.socials
  static astersAi = img.astersAi
  static template = img.template
  static carosello = img.carosello
  static allSocials = img.allSocials
  static instagramNoColorIcon = img.instagramNoColorIcon
  static facebookIconNoColorIcon = img.facebookIconNoColorIcon
  static linkedinIconNoColorIcon = img.linkedinIconNoColorIcon
  static twitterIconNoColorIcon = img.twitterIconNoColorIcon
  static save = img.save
  static code = img.code
  static planPost = img.planPost
  static userCircle = img.userCircle
  static dragDrop = img.dragDrop
  static position = img.position
  static characterCouter = img.characterCouter
  static readingTime = img.readingTime
  static addSocial = img.addSocial
  static base = img.base
  static advanced = img.advanced
  static toolFolder = img.toolFolder
  static textDifficulty = img.textDifficulty
  static fbMap = img.fbMap
  static facebookLike = img.facebookLike
  static fbLike = img.fbLike
  static fbComment = img.fbComment
  static fbShare = img.fbShare
  static linkedinLike = img.linkedinLike
  static linkedinComment = img.linkedinComment
  static linkedinRepost = img.linkedinRepost
  static linkedinSend = img.linkedinSend
  static instagramLike = img.instagramLike
  static instagramComment = img.instagramComment
  static instagramShare = img.instagramShare
  static instagramBookMark = img.instagramBookMark
  static twitterComment = img.twitterComment
  static twitterRepost = img.twitterRepost
  static twitterHeart = img.twitterHeart
  static twitterLikes = img.twitterLikes
  static twitterShare = img.twitterShare
  static tagIcon = img.tagIcon
  static smallEdit = img.smallEdit
  static smallLink = img.smallLink
  static tagEdit = img.tagEdit
  static tagDelete = img.tagDelete
  static tagCreate = img.tagCreate
  static tagEditDelete = img.tagEditDelete
  static mobile = img.mobile
  static tablet = img.tablet
  static desktop = img.desktop
  static downloadPreview = img.downloadPreview
  static facebookLikeDarkMode = img.facebookLikeDarkMode
  static promotion = img.promotion
  static changeLink = img.changeLink
  static star = img.star
  static playVideo = img.playVideo
  static cancel = img.cancel
  static newMedia = img.newMedia
  static newVideo = img.newVideo
  static emailToConfirm = img.emailToConfirm
  static trashRole = img.trashRole
  static optionsIcon = img.optionsIcon
  static sortIcon = img.sortIcon
  static shortGeneration = img.shortGeneration
  static mediumGeneration = img.mediumGeneration
  static largeGeneration = img.largeGeneration
  static squareTrash = img.squareTrash
  static search = img.search
  static closedMail = img.closedMail
  static teamMembers = img.teamMembers
  static crop = img.crop
  static changeWorkspace = img.changeWorkspace
  static doubleArrowLeft = img.doubleArrowLeft
  static arrowLeft = img.arrowLeft
  static doubleArrowRight = img.doubleArrowRight
  static arrowRight = img.arrowRight
  static frameIndicator = img.frameIndicator
  static playVideoIcon = img.playVideoIcon
  static pauseVideoIcon = img.pauseVideoIcon
  static metaGrid = img.metaGrid
  static metaStories = img.metaStories
  static calendarPost = img.calendarPost
  static addImageWorkspace = img.addImageWorkspace
  static saveIcon = img.saveIcon
  static thumbUp = img.thumbUp
  static thumbDown = img.thumbDown
  static privateBoard = img.privateBoard
  static publicBoard = img.publicBoard
  static simpleCheck = img.simpleCheck
  static starEmpty = img.starEmpty
  static starFilled = img.starFilled
  static lock = img.lock
  static notLock = img.notLock
  static addBoard = img.addboard
  static sadFace = img.sadFace
  static boxgreen = img.BoxGreen
  static checkboxgrey = img.checkBoxGrey
  static sadStar = img.sadStar
  static sadBoard = img.sadBoard
  static sadSearchbar = img.sadSearchbar
  static settingsIcon = img.settingsIcon
  static searchIcon = img.searchIcon
  static hash = img.hash
  static info = img.info
  static closeIcon = img.closeIcon
  static padlock = img.padlock
  static collaboration = img.collaboration
  static dropdownArrow = img.dropdownArrow
  static commentsWithPoints = img.commentsWithPoints
  static users = img.users
  static trashBin = img.trashBin
  static searchIconMenu = img.searchIconMenu
  static followIconMenu = img.followIconMenu
  static boardsIconMenu = img.boardsIconMenu
  static starDiscover = img.starDiscover
  static boardsDiscover = img.boardsDiscover
  static refresh = img.refresh
  static close_icon = img.close_icon
  static tiktokAllBottomIcons = img.tiktokAllBottomIcons
  static tiktokAllTopIcons = img.tiktokAllTopIcons
  static tiktokAdd = img.tiktokAdd
  static tiktokMinorInfo = img.tiktokMinorInfo
  static notifyBell = img.notifyBell
  static fourDotMenu = img.fourDotMenu
  static cryBell = img.cryBell
  static notifyToRead = img.notifyToRead
  static notifyReaded = img.notifyReaded
  static youtubeDownload = img.youtubeDownload
  static youtubeSettings = img.youtubeSettings
  static pen = img.pen
  static roundedCheckmark = img.roundedCheckmark
  static dropdownFilter = img.dropdownFilter
  static Create = img.Create
  static Dashboard = img.Dashboard
  static Preview = img.Preview
  static SetUp = img.SetUp
  static Social = img.Social
  static Metriche = img.Metriche
  static Condividi = img.Condividi
  static Link = img.Link
  static SalvaPdf = img.SalvaPdf
  static sadReport = img.sadReport
  static sadBall = img.sadBall
  static utm = img.utm
  static expand = img.expand
  static collapse = img.collapse
  static saveIcons = img.saveIcons
  static enterpriseCity = img.enterpriseCity
  static xIcon = img.xIcon
  static addSocialAccount = img.addSocialAccount
  static threeDots = img.threeDots
  static youtubeAllTopIcons = img.youtubeAllTopIcons
  static youtubeAllRightIcons = img.youtubeAllRightIcons
  static youtubeAllSongIcons = img.youtubeAllSongIcons
  static youtubeAllBottomIcons = img.youtubeAllBottomIcons
  static postWarning = img.postWarning
  static addInstagram = img.addInstagram
  static closeButton = img.closeButton
  static compare = img.compare
  static dragDropAnalytics = img.dragDropAnalytics
  static sadFaceWithPen = img.sadFaceWithPen
  static magicWand = img.magicWand
  static sun = img.sun
  static dottedLine = img.dottedLine
  static textIcon = img.textIcon
  static autopilotElectric = img.autopilotElectric
  static label = img.label
  static sizerClose = img.sizerClose
  static calendarIcon = img.calendarIcon
  static positiveCheck = img.positiveCheck
  static negativeCheck = img.negativeCheck
  static generation = img.generation
  static person = img.person
  static collaborators = img.collaborators
  static happyPost = img.happyPost
  static postIcon = img.postIcon
  static carouselIcon = img.carouselIcon
  static videoIcon = img.videoIcon
  static reelIcon = img.reelIcon
  static shortStoryIcon = img.shortStoryIcon
  static downloadArrowDown = img.downloadArrowDown
  static trashCan = img.trashCan
  static modifyIcon = img.modifyIcon
  static reuse = img.reuse
  static goToPost = img.goToPost
  static menu = img.menu
  static warningIcon = img.warningIcon
  static createPostSizer = img.createPostSizer
  static calendarPostSizer = img.calendarPostSizer
  static mediasPostSizer = img.mediasPostSizer
  static analyticsPostSizer = img.analyticsPostSizer
  static discoverPostSizer = img.discoverPostSizer
  static reelRightIcons = img.reelRightIcons
  static reelTranslateIcon = img.reelTranslateIcon
  static trashCanSizer = img.trashCanSizer
  static reelMask = img.reelMask
  static partnersSizer = img.partnersSizer
  static usersSizer = img.usersSizer
  static collabSizer = img.collabSizer
  static mediaSizer = img.mediaSizer
  static copySizer = img.copySizer
  static upgradePlan = img.upgradePlan
  static email = img.email
  static userModify = img.userModify
  static password = img.password
  static arrowUp = img.arrowUp
  static magic = img.magic
  static flag = img.flag
  static circleQuestion = img.circleQuestion
  static roadmap = img.roadmap
  static academy = img.academy
  static docs = img.docs
  static settingsSizer = img.settingsSizer
  static billingSizer = img.billingSizer
  static darkModeSizer = img.darkModeSizer
  static logOutSizer = img.logOutSizer
  static notificationSettings = img.logo
  static notificationDiscover = img.notificationDiscover
  static notificationPublish = img.notificationSettings
}
