/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { AppStore, T } from 'utils'
import { MediaContainerPost } from '../../GenericComponents/MediaContainerPost'
import { ImageWithFallback } from 'components/UI/ImageWithFallback'
import MediaUtils from 'utils/MediaUtils'

/* ------------------- INTERFACES  */
interface Props {}

export const ResultPreviewGlobal = ({}: Props) => {
  const defImage = AppStore?.workspace?.image?.presignedUrl ?? ''
  return (
    <ResultPreviewGlobalContainer>
      <AccountInfo>
        <ImageWithFallback
          fallbackSrc={MediaUtils.workspacePlaceholder}
          style={{ width: 40, height: 40, borderRadius: 14 }}
          src={defImage}
        />

        <PlaceholderContainer>
          <PlaceholderPost width={315} />
          <PlaceholderPost width={65} />
        </PlaceholderContainer>
      </AccountInfo>

      <MediaContainerPost width={'100%'} height={466} arrowsPositions={30} />

      <SpaceContainer>
        <PlaceholderContainer>
          <PlaceholderPost width={65} />
          <PlaceholderPost width={374} />
        </PlaceholderContainer>
      </SpaceContainer>

      <CommentContainer>
        <ImageWithFallback
          fallbackSrc={MediaUtils.workspacePlaceholder}
          style={{ width: 25, height: 25, borderRadius: 14 }}
          src={defImage}
        />

        <p>{T.postPage.addComent}</p>
      </CommentContainer>
    </ResultPreviewGlobalContainer>
  )
}

const ResultPreviewGlobalContainer = styled(Box)`
  padding: 12px;
  width: 100%;
  height: 100%;
  border: 1px solid ${() => AppStore.theme.o.grey};
`

const AccountInfo = styled(Box)`
  padding: 16px 12px;
  flex-direction: row;
  gap: 12px;
  width: 100%;
`

const PlaceholderContainer = styled(Box)`
  align-self: center;
  width: '100%';
  gap: 8px;
`

const PlaceholderPost = styled(Box)`
  height: 12px;
  border-radius: 8px;
  background-color: ${() => AppStore.theme.o.lightGrey};
`

const SpaceContainer = styled(Box)`
  padding: 16px 12px;
  gap: 12px;
  width: 100%;
`

const CommentContainer = styled(Box)`
  padding: 11.16px;
  gap: 14px;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  color: ${() => AppStore.theme.o.darkGrey};
`
