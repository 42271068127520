import styled from '@emotion/styled'

import Socialaccounts from 'api/Socialaccounts'
import { Component } from 'react'
import { SocialAccount, Workspace } from 'types'
import { AppStore, T, showError, showInfo, showSuccess } from 'utils'
import { Box } from './Box'
import { Button } from './Button'
import { Icons } from './Icons'
import { Text } from './Text'
import { MixpanelSocialProfile } from 'utils/eventsTracker/socialProfile/socialProfileInterfaces'
import { Tracker } from 'api/Tracker'
import { TRACKEREVENTS, getSocialProfileData } from 'utils/eventsTracker'
import { Input } from './Input'

interface State {
  filter: string
  selectedWorkspace: string
  selectedWorkspaceName: string
  onHoverWorkspace: string
}
interface Props {
  socialAccount: SocialAccount
  handleForceUpdate?: () => void
}
export default class ChangeWorkspaceModal extends Component<Props, State> {
  state: State = {
    filter: '',
    onHoverWorkspace: '',
    selectedWorkspace: '',
    selectedWorkspaceName: '',
  }

  Account = (workspace: Workspace) => {
    const { onHoverWorkspace, selectedWorkspace } = this.state
    const selected = selectedWorkspace === workspace._id
    const onHover = onHoverWorkspace === workspace._id

    const scrollAble = AppStore.loggedUser.workspaces.length > 7
    return (
      <Box
        width={scrollAble ? 312 : 350}
        height={56}
        ph={24}
        pv={12}
        row
        vcenter
        hcenter
        onClick={() => {
          if (selected) {
            this.setState({ selectedWorkspace: '', selectedWorkspaceName: '' })
          } else {
            this.setState({ selectedWorkspace: workspace._id ?? '', selectedWorkspaceName: workspace.name })
          }
        }}
        onMouseEnter={() => {
          this.setState({ onHoverWorkspace: workspace._id ?? '' })
        }}
        onMouseLeave={() => {
          this.setState({ onHoverWorkspace: '' })
        }}
        style={{ border: `1px solid ${selected ? AppStore.theme.o.green : AppStore.theme.o.lightGrey}` }}
        bc={onHover ? AppStore.theme.o.lightestGrey : selected ? AppStore.theme.o.lightGreen : 'transparent'}
      >
        <Box gap={16} flex row>
          <img style={{ borderRadius: 32 }} height={32} width={32} src={workspace.image?.presignedUrl} />
          <Text fontSize={14} weight={700} truncate style={{ marginTop: 5, width: 220 }}>
            {workspace.name}
          </Text>
        </Box>

        <CheackBox bc={selected ? AppStore.theme.o.green : AppStore.theme.o.grey} />
      </Box>
    )
  }

  render() {
    const buttonDisabled = this.state.selectedWorkspace === ''
    const scrollAble = AppStore.loggedUser.workspaces.length > 7

    const allWorkspaces = [
      ...AppStore.loggedUser.workspaces,
      ...AppStore.loggedUser.collabWorkspace.map((w) => w.workspace),
    ]

    return (
      <Box width={470} height={734} p={48} flex relative>
        <Box center>
          <Text fontSize={16} weight={700}>
            {T.postPage.your_workspace}
          </Text>
          <Box>
            <Text style={{ textAlign: 'center' }} fontSize={16} weight={500} color={AppStore.theme.o.darkGrey}>
              {T.postPage.select_the_workspace}
            </Text>
          </Box>
        </Box>

        <Box
          id={'search-social'}
          width={374}
          height={40}
          ph={24}
          pv={11}
          mt={12}
          mb={16}
          style={{ border: `2px dotted ${AppStore.theme.o.darkGrey}` }}
          row
          vcenter
          gap={16}
        >
          <Icons.search fill={AppStore.theme.o.darkGrey} />
          <input
            placeholder={T.settings.searchWorkspace}
            style={{
              width: 300,
              height: 36,
              border: 'none',
              backgroundColor: 'transparent',
              color: AppStore.theme.o.black,
            }}
            onChange={(e) => {
              this.setState({ filter: e.target.value })
            }}
          />
        </Box>

        <WorkspaceScroll
          id={'workspaceRows'}
          style={{ border: `2px dotted ${AppStore.theme.o.darkGrey}`, maxHeight: 530 }}
          p={24}
          flex
          pr={scrollAble ? 44 : 22}
          gap={16}
          center
        >
          <Box scroll flex gap={16} width={scrollAble ? 348 : 370} style={{ maxHeight: 530, borderRadius: 0 }} hcenter>
            {allWorkspaces
              .filter((w) => w._id !== this.props.socialAccount.workspace)
              .map((workspace) => {
                if (this.state.filter === '') return this.Account(workspace)
                if (workspace.name.toUpperCase().includes(this.state.filter.toUpperCase()))
                  return this.Account(workspace)
              })}
          </Box>
        </WorkspaceScroll>

        <Button
          variant={buttonDisabled ? 'neutral' : 'primary'}
          onClick={async () => {
            if (!buttonDisabled) {
              try {
                const res = await Socialaccounts.switchWorkspace(this.props.socialAccount, this.state.selectedWorkspace)
  
                const mixpanelData: MixpanelSocialProfile = {
                  social_channel: this.props.socialAccount.provider,
                  social_channel_type: this.props.socialAccount.profileType ?? 'profile',
                  social_profile_name: this.props.socialAccount.name,
                  social_profile_url: '',
                  workspace_name: this.state.selectedWorkspaceName,
                }
                Tracker.trackEvent(TRACKEREVENTS.SOCIAL_PROFILE_MOVE_COMPLETED, getSocialProfileData(mixpanelData))
  
                showSuccess(T.postPage.switch_accounts_success)
                // AppStore.refreshPage()
                AppStore.closeAllModal()
                this.props.handleForceUpdate && this.props.handleForceUpdate()
              } catch (error: any) {
                showError(error.message)
              }
            } else showInfo(T.info.selectWorkspace)
          }}
          size="small"
          style={{ position: 'absolute', bottom: -50, alignSelf: 'center' }}
        >
          {T.postPage.switch_accounts}
        </Button>
      </Box>
    )
  }
}

const WorkspaceScroll = styled(Box)``
const CheackBox = styled(Box)`
  border: 50%;
  min-width: 18px;
  min-height: 18px;
`
