/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { IPostFilters } from 'api/Reports'
import { isExternalElementClicked } from 'api/Utils'
import { Box, Button, Icons } from 'components'
import { LabelsContent, TLabelsFilters } from 'components/CreatePost/PreviewComponents/LabelsComponents/LabelsContent'
import { ILabel } from 'components/CreatePost/PreviewComponents/LabelsComponents/LabelsContentComponents/Label'
import { CloseButton } from 'components/UI'
import { useEffect, useRef, useState } from 'react'
import { AppStore, showInfo, T } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  updateValues: (labels: any) => void
  apiCallParams?: IPostFilters
}

export const LabelsFilterTableGraph = ({ updateValues, apiCallParams }: Props) => {
  /* ----------------- STATE  */
  const [openFilter, setOpenFilter] = useState<boolean>(false)
  const [labels, setLabels] = useState<ILabel[]>(apiCallParams?.labels?.values ?? [])
  const [labelsFilter, setLabelsFilter] = useState<TLabelsFilters>(apiCallParams?.labels?.type ?? 'and')
  const [firstMount, setFirstMount] = useState(true)

  /* ----------------- REF  */
  const labelContainerRef = useRef<HTMLDivElement>(null)

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */
  const hasLabels: boolean = labels?.length > 0

  const variantType = hasLabels ? 'secondary' : 'neutral'

  /* ----------------- METHODS  */

  const openLabelFilter = () => {
    setOpenFilter((prev) => !prev)
  }

  const resetSelectedLabels = (e) => {
    e.stopPropagation()
    setLabels([])
    updateValues({
      labels: {
        values: [],
        type: labelsFilter,
      },
    })
  }

  /**
   * Funzione per aggiungere una label ai label selezionati
   * @param label Label to add
   */
  const handleAddLabel = (label: ILabel) => {
    // Verifica se la label è già stata aggiunta
    const isAlreadyAdded = labels?.find((lb) => lb._id === label._id)

    // Se la label è già stata aggiunta, non fare nulla
    if (isAlreadyAdded) {
      return showInfo(T.postPage.labelsSection.alreadyAdded)
    }

    // Altrimenti, aggiungi la label ai label selezionati
    const newSelected = [...labels!, { ...label }]

    setLabels(newSelected)
  }

  /**
   * Funzione per rimuovere una label dai label selezionati
   * @param label Label to remove
   */
  const handleRemoveLabel = (label: ILabel) => {
    const newSelected = labels?.filter((lb) => lb._id !== label._id)

    setLabels(newSelected)
  }

  /**
   * Funzione per chiude il filtro delle label se si clicca all'esterno del contenitore
   * @param e Element where the click event was triggered
   */
  const handleClose = (e) => {
    const elToCheck = e.target as Element

    const labelRef = labelContainerRef?.current

    if (elToCheck.classList.contains('widget_analytics')) return

    if (isExternalElementClicked(labelRef, elToCheck)) setOpenFilter(false)
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    if (openFilter) window.addEventListener('click', handleClose)
    return () => window.removeEventListener('click', handleClose)
  })

  useEffect(() => {
    const areValuesEqual = JSON.stringify(labels) === JSON.stringify(apiCallParams?.labels?.values)

    if (!openFilter && !firstMount && !areValuesEqual) {
      updateValues({
        labels: {
          values: labels,
          type: labelsFilter,
        },
      })
    }
    setFirstMount(false)
  }, [openFilter])

  return (
    <LabelsFilterTableGraphContainer
      variant={variantType}
      center
      onClick={openLabelFilter}
      forwardedRef={labelContainerRef}
      className="widget_analytics"
    >
      <Icons.label
        className="widget_analytics"
        fill={hasLabels ? AppStore.theme.o.on_secondary_container : AppStore.theme.o.black}
        width={24}
        height={24}
      />

      <LabelFilter
        className={`${openFilter ? 'active' : 'not-active'}`}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <LabelsContent
          selectedLabels={labels}
          setSelectedLabels={setLabels}
          handleAddLabel={handleAddLabel}
          handleRemoveLabel={handleRemoveLabel}
          fromSection={'widget_analytics'}
          labelsFilter={labelsFilter}
          setLabelsFilter={setLabelsFilter}
        />
      </LabelFilter>

      {hasLabels && <CloseButton action={resetSelectedLabels} />}
    </LabelsFilterTableGraphContainer>
  )
}

const LabelsFilterTableGraphContainer = styled(Button)`
  display: flex;
  align-items: center;
  padding: 8px 16px !important;
  min-height: 40px !important;
  height: 40px !important;
  position: relative;
`

const LabelFilter = styled(Box)`
  position: absolute;
  right: 0;
  border-radius: 14px;
  background-color: ${() => AppStore.theme.o.surface};
  box-shadow: ${() => AppStore.theme.smallBoxShadow};
  transition: 300ms ease-in-out;

  &.not-active {
    scale: 0;
    top: -100%;
    z-index: -1;
    opacity: 0;
    width: 0;
    height: 0;
  }

  &.active {
    scale: 1;
    top: calc(100% + 8px);
    z-index: 1;
    opacity: 1;
    width: 366px;
    height: 474px;
  }

  cursor: default;
  &:hover {
    background: ${() => AppStore.theme.o.surface};
  }
`
