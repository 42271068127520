import { IAiDiscover, ITopic } from 'pages/ai-discover/aiDiscoverInterface'
import { api, responseErrorCheck } from './api'
import AstersBaseResource from './AstersBaseResource'
import { AppStore } from 'utils'

// <-------------------------INFERFACE------------------------->

export default class AiDiscover extends AstersBaseResource {
  static endpoint = '/aiDiscover'

  /*  static async getTopic() {
    return api.get<any>(`${this.endpoint}/topic`).then(responseErrorCheck)
   } */

  // Serve per controllare l'utente può entrare nel mondo aiDiscover
  static async create(aiDiscover: IAiDiscover) {
    return api.post<any>(`${this.endpoint}/${AppStore.workspace._id}/create`, { aiDiscover }).then(responseErrorCheck)
  }

  static async putUpdateAiDiscover(discover: IAiDiscover) {
    return api
      .put<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/${AppStore.discoverUser?._id}/updateAiDiscover`, {
        discover,
      })
      .then(responseErrorCheck)
  }

  static async putUpdateAiDiscoverBoard(boardId: string) {
    return api
      .put<any>(
        `${this.endpoint}/${AppStore.getWorkspaceId()}/${AppStore.discoverUser?._id}/${boardId}/updateAiDiscoverBoard`
      )
      .then(responseErrorCheck)
  }

  static async postRequestAiDiscoverTopic(text?: string, description?: string) {
    return api
      .post<any>(
        `${this.endpoint}/${AppStore.workspace._id}/${AppStore.discoverUser?._id}/${text}/requestAiDiscoverTopic`,
        {
          description,
        }
      )
      .then(responseErrorCheck)
  }

  // serve per cancellare l'oggetto aiDiscover dell'utente ( ATTENZIONE )
  static async delete() {
    return api
      .post<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}${AppStore.discoverUser?._id}/delete`)
      .then(responseErrorCheck)
  }

  // --------------- TOPICS ---------------

  // Per aggiungere / modificare topic dentro utente aiDiscover
  static async putUpdateAiDiscoverTopic(topicId: string) {
    return api
      .put<any>(
        `${this.endpoint}/${AppStore.getWorkspaceId()}/${AppStore.discoverUser?._id}/${topicId}/updateAiDiscoverTopic`
      )
      .then(responseErrorCheck)
  }

  // Per rimuovere topic dentro utente aiDiscover
  static async removeAiDiscoverTopic(topicId: string | string[]) {
    return api
      .delete<any>(
        `${this.endpoint}/${AppStore.getWorkspaceId()}/${AppStore.discoverUser?._id}/removeAiDiscoverTopic`,
        { topicId }
      )
      .then(responseErrorCheck)
  }

  static async getTopic() {
    return api.get<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/topic`, {}).then(responseErrorCheck)
  }

  // --------------- ARTICLES ---------------

  // Get specific article ( QUESTA NON VA MAI CHIAMATA PER ORA )
  static async getArticle(articleId?: string) {
    return api
      .get<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/${articleId}/getArticle`)
      .then(responseErrorCheck)
  }

  // Get topic articles with conditional parament "newerThen" for filter
  static async getTopicArticles(topicId: string, count: number, newerThan?: number, language?: string) {
    return api
      .get<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/${topicId}/topicArticles`, {
        topicId,
        count,
        newerThan,
        language,
      })
      .then(responseErrorCheck)
  }

  static async searchArticleInTopics(
    textSearch: string,
    items: { type: string; id: string }[],
    articleNumber?: number,
    continuation?: string,
    newerThan?: string
  ) {
    return api
      .post<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/searchArticle`, {
        textSearch,
        items,
        articleNumber,
        continuation,
        newerThan,
      })
      .then(responseErrorCheck)
  }

  // Get trending articles
  static async getTrendingArticles(
    language: string,
    articleNumber?: number,
    pageNumber?: number,
    startDate?: string,
    endDate?: string
  ) {
    return api
      .get<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/${AppStore.discoverUser._id}/getTrendingArticles`, {
        language,
        articleNumber,
        pageNumber,
        startDate,
        endDate,
      })
      .then(responseErrorCheck)
  }

  // Get trending articles by followed topic
  static async getArticlebyTopicList(
    pageNumber?: number,
    articleNumber?: number,
    startDate?: string,
    endDate?: string,
    language?: string
  ) {
    return api
      .get<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/${AppStore.discoverUser?._id}/getArticlebyTopicList`, {
        pageNumber,
        articleNumber,
        startDate,
        endDate,
        language,
      })
      .then(responseErrorCheck)
  }

  // Get article by keyword
  static async getArticlebyKeyword(
    keyword: string,
    language: string,
    pageNumber?: number,
    articleNumber?: number,
    startDate?: string,
    endDate?: string
  ) {
    return api
      .get<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/${AppStore.discoverUser?._id}/getArticlebyKeyword`, {
        keyword,
        language,
        pageNumber,
        articleNumber,
        startDate,
        endDate,
      })
      .then(responseErrorCheck)
  }

  // Get article by keyword
  static async getArticlebyUri(articleId: string) {
    return api
      .get<any>(
        `${this.endpoint}/${AppStore.getWorkspaceId()}/${AppStore.discoverUser?._id}/${articleId}/getArticlebyUri`
      )
      .then(responseErrorCheck)
  }
}
