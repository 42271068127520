/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { ThemeProvider } from '@mui/material'
import { LocalizationProvider, PickersShortcutsItem } from '@mui/x-date-pickers'
import { DateRange, DateRangePicker, SingleInputDateRangeField } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Box, Icons } from 'components'
import dayjs, { Dayjs } from 'dayjs'
import InfiniteLoader from 'pages/ai-discover/UI/InfiniteLoader'
import { useState } from 'react'
import { useAnalyticsStore } from 'stores/AnalyticsStore'
import { AnalyticsDate } from 'types/AnalyticsInferfaces'
import { AppStore, T } from 'utils'
import { muiTheme } from 'utils/MuiUtils/MuiThemes'
import { localDate, localLang } from 'utils/MuiUtils/MuiTranslations'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  selectedDate?: AnalyticsDate | null
  setSelectedDate: (any) => void
}

export const SelectedDateSetUp = ({ selectedDate, setSelectedDate }: Props) => {
  /* ----------------- STATE  */
  const reportPreview = useAnalyticsStore.getState().reportPreview

  //Stato per definire se è aperto il date picker
  const [openRangePicker, setOpenRangePicker] = useState<boolean>(false)

  //Stato per resettare il valore di input nel componente di Mui
  const [resetKey, setResetKey] = useState<number | null>(null)

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */
  // Variabile per controllo di style
  const hasSelectedDate: boolean =
    selectedDate !== null && selectedDate?.startDate !== undefined && selectedDate?.endDate !== undefined

  const hasSelectedDateClass = hasSelectedDate ? 'has-selected-date' : ''

  // Lista dei giorni selezionabili come opzioni nel componente range date picker
  const dateRanges: PickersShortcutsItem<DateRange<Dayjs>>[] = [
    {
      label: T.date.today,
      getValue: () => {
        const today = dayjs()
        return [today.startOf('day'), today.endOf('day')]
      },
    },
    {
      label: T.date.yesterday,
      getValue: () => {
        const yesterday = dayjs().subtract(1, 'day')
        return [yesterday.startOf('day'), yesterday.endOf('day')]
      },
    },
    {
      label: T.date.last7Days,
      getValue: () => {
        const today = dayjs()
        return [today.subtract(6, 'day'), today.endOf('day')]
      },
    },
    {
      label: T.date.lastMonth,
      getValue: () => {
        const today = dayjs()
        return [today.subtract(29, 'day'), today.endOf('day')]
      },
    },
  ]

  /* ----------------- METHODS  */
  const handleAccept = (value: DateRange<Dayjs>) => {
    // check if the values are the same
    if (value[0]?.isSame(value[1], 'day')) {
      return
    }

    if (reportPreview) {
      setSelectedDate({
        startDate: value[0],
        endDate: value[1],
      })
    } else {
      setSelectedDate({
        // @ts-ignore
        startDate: value[0]?.$d,
        // @ts-ignore
        endDate: value[1]?.$d,
      })
    }
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  // @@@@@@@@@@ Barbatrucco @@@@@@@@@
  // Non è possibile passare date uguali al report
  const today = dayjs().startOf('day')
  const isToday =
    dayjs(selectedDate?.startDate).isSame(today, 'day') && dayjs(selectedDate?.endDate).isSame(today, 'day')
  // console.log('isToday', isToday)

  return isToday ? (
    <InfiniteLoader></InfiniteLoader>
  ) : (
    <SelectedDateSetUpContainer className={`${hasSelectedDateClass}`} onClick={() => setOpenRangePicker(true)}>
      <ContainerDateShow className={`date-show ${selectedDate?.startDate && selectedDate.endDate ? '' : 'empty'}`}>
        <ThemeProvider theme={muiTheme}>
          <LocalizationProvider dateAdapter={AdapterDayjs} localeText={localLang} adapterLocale={localDate}>
            <DateRangePicker
              // Definisce stile visualizzazione del input
              slots={{ field: SingleInputDateRangeField }}
              defaultValue={[dayjs(selectedDate?.startDate), dayjs(selectedDate?.endDate)]}
              slotProps={{
                //Lista dei pulsanti
                shortcuts: {
                  items: dateRanges,
                },

                // Per cambiare il posizionamento del "tooltip" ossia i due calendari che si aprono
                popper: {
                  placement: 'bottom',
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        // positioni x e y
                        offset: [30, 20],
                      },
                    },
                  ],
                },
              }}
              key={resetKey} // key necessaria per il reset dei valori nel campo input
              onAccept={handleAccept}
              open={openRangePicker}
              onClose={() => setOpenRangePicker(false)}
              disableFuture //disabilitare i giorni futuri
            />
          </LocalizationProvider>
        </ThemeProvider>
      </ContainerDateShow>

      <Icons.calendarPost className="calendar_icon" width={20} height={20} />
    </SelectedDateSetUpContainer>
  )
}

const SelectedDateSetUpContainer = styled(Box)`
  width: 299px;
  height: 43px;
  border-radius: 14px;
  cursor: pointer;
  position: relative;
  align-items: center;
  border: 1px solid ${() => AppStore.theme.o.grey};
  background-color: ${() => AppStore.theme.o.lightestGrey};
  flex-direction: row;
  z-index: 1;
  transition: all 0.3s ease-in-out;

  .calendar_icon {
    fill: ${() => AppStore.theme.o.black};
    cursor: 'pointer';
    margin-left: auto;
    margin-right: 8px;
  }

  &.is-open {
    .date-show {
      transition: all 0.3s ease-in-out;
      max-width: 400px;
      opacity: 1;

      div[class*='MuiFormControl-root'] {
        width: 200px;
      }
    }
  }
`

const ContainerDateShow = styled(Box)`
  border-radius: 0;
  align-items: center;
`
