/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { ILabel, Label } from 'components/CreatePost/PreviewComponents/LabelsComponents/LabelsContentComponents/Label'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  labels: ILabel[]
  addBlackLayer: () => void
  setSelectedIcon: (icon: string) => void
}

export const VisibleLabels = ({ labels, addBlackLayer, setSelectedIcon }: Props) => {
  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */
  const handleOnClickLabel = () => {
    setSelectedIcon('labels')
    addBlackLayer()
  }

  const renderLabels = () => {
    const visibleLabels = labels.slice(0, 4)
    const remainingLabels = labels.slice(4)

    const showLabels = visibleLabels.map((label, i) => (
      <LabelContainer onClick={handleOnClickLabel} id={'labels_container'} data-html2canvas-ignore='true'>
        <Label key={i} id={label._id} label={label.label} color={label.color} />
        <OpacityContainer className="opacity_container_labels" data-html2canvas-ignore='true' />
      </LabelContainer>
    ))

    if (labels.length > 4) {
      return [...showLabels, <Label id={'more-labels'} label={`${remainingLabels.length} +`} color={labels[0].color} data-html2canvas-ignore='true' />]
    } else {
      return showLabels
    }
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return <VisibleLabelsContainer id="zIndex_container_labels" data-html2canvas-ignore='true'>{renderLabels()}</VisibleLabelsContainer>
}

const VisibleLabelsContainer = styled(Box)`
  position: absolute;
  top: -20px;
  left: 32px;
  flex-direction: row;
  gap: 8px;
  background-color: transparent;
`

const LabelContainer = styled(Box)`
  position: relative;
  z-index: 1;

  &:hover {
    cursor: pointer;
  }
`

const OpacityContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1000;
`
