import styled from '@emotion/styled'
import { Popconfirm } from 'antd'
import React, { Component } from 'react'
import { AppStore } from 'utils'
import { Icons } from './Icons'

interface Props {
  title: any
  okText: String
  onConfirm: () => void
}

export default class PopConfirm extends Component<Props> {
  render() {
    return (
      <Popconfirm
        title={this.props.title}
        okText={this.props.okText}
        onConfirm={this.props.onConfirm}
        // ------------------       STYLE       ------------------------
        onCancel={() => {
          const popover = document.querySelector('.ant-popover')
          if (!popover) return
          ;(popover as any).style.visibility = 'hidden'
        }}
        onVisibleChange={(visible) => {
          if (!visible) {
            setTimeout(() => {
              const popover = document.querySelector('.ant-popover')
              if (!popover) return
              ;(popover as any).style.visibility = 'hidden'
            }, 40)
          } else
            setTimeout(() => {
              const popover = document.querySelector('.ant-popover')
              if (!popover) return
              ;(popover as any).style.visibility = 'visible'
            }, 300)
          // --------------------------------------------------------------
        }}
      >
        {this.props.children}
      </Popconfirm>
    )
  }
}
