import styled from '@emotion/styled'
import Workspaces from 'api/Workspaces'
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { Icons } from 'components/Icons'
import { openZoomedModal } from 'components/Modal/ModalManager'
import { Text } from 'components/Text'
import { TextArea } from 'components/TextArea'
import { useEffect, useState } from 'react'
import { T } from 'translation/i18n'
import { IItem } from 'types'
import { AppStore, showError, showSuccess } from 'utils'
import { EditComponent } from './EditComponent'
import { TempleteButtons } from './TempleteButtons'

/* ******************* INTERFACE ******************* */
interface Props {
  item: IItem
  index: number
  variantId?: string
}

const TemplateView = ({ item, index, variantId }: Props) => {
  /* ____________________ STATE ____________________ */

  const [EditName, setEditName] = useState(false)

  const [ChangedName, setChangedName] = useState('')
  const [saveButton, setSaveButton] = useState(false)
  const [updated, setUpdated] = useState('')
  const [value, setValue] = useState(item.text)
  const workspaceId = AppStore.workspace._id ?? ''

  useEffect(() => {
    setValue(item.text)
  }, [item, index])

  // _______ */
  const modifyNameToWorkspace = async ({ text }, idHashtag) => {
    try {
      const results = await Workspaces.modifyName(workspaceId as any, idHashtag, { text })
      item.name = ChangedName

      showSuccess(T.success.nameChanged)
    } catch (error) {
      showError(error)
      if ((AppStore.workspace.hashtags?.length ?? 0) > 25) {
        showError(T.error.nameCantSupass)
      }
    }
  }

  return (
    <TotalBoxContainer className="TotalBoxContainer">
      <InnerFirstBox className="InnerFirstBox">
        {EditName === false && (
          <EditBox className="EditBox" row vcenter>
            <EditText weight={700}>{item.name}</EditText>
            <EditIcon onClick={() => setEditName(true)} width={30} height={30} />
          </EditBox>
        )}

        <Box row>{EditName && <EditComponent item={item} closeEdit={() => setEditName(false)} />}</Box>
      </InnerFirstBox>

      <Box row style={{ marginTop: 10 }}>
        {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
        <Box flex relative>
          <FlexTextArea
            id="text-area-create-post"
            bgcolor={AppStore.theme.o.lightGrey}
            bgcoloractive={AppStore.theme.o.lightGrey}
            value={value}
            onChange={(update) => {
              setValue(update)

              setUpdated(update)
              setSaveButton(true)
            }}
          >
            {value}
          </FlexTextArea>
          <ZoomIcon onClick={() => openZoomedModal(item, index)} />
        </Box>
        {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
        <TempleteButtons
          item={item}
          saveButton={saveButton}
          value={value}
          setSaveButton={setSaveButton}
          variantId={variantId}
        />
        {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
      </Box>
    </TotalBoxContainer>
  )
}

export default TemplateView

const TotalBoxContainer = styled(Box)`
  margin-top: 32px;
`

const InnerFirstBox = styled(Box)`
  max-height: 200px;
  max-width: 100px;
  width: 300px;
`

const EditBox = styled(Box)`
  min-width: 400px;
`

const EditText = styled(Text)`
  font-size: 25px;
  font-family: 'Aktiv Grotesk';
  max-width: 600px;
`

const FlexTextArea = styled(TextArea)`
  min-height: 88px;
  line-height: 24pt !important;
  text-align: left;
  color: black;
  font-size: 18px;
  min-height: 600px;
  position: relative;

  max-height: 264px !important;
  background-color: ${() => AppStore.theme.o.red};
`

const ButtonUpdateDelete = styled(Button)`
  margin-left: 40px;
  width: 130px;
  height: 40px;
`

const EditIcon = styled(Icons.edit)`
  cursor: pointer;
  fill: ${() => AppStore.theme.o.black};
  :hover {
    fill: ${() => AppStore.theme.o.blueOnLight};
  }

  margin-left: 8px;
`

const ZoomIcon = styled(Icons.zoom)`
  width: 25px;
  height: 25px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 9999;
  :hover {
    fill: ${AppStore.theme.o.blueOnLight};
  }
  fill: ${AppStore.theme.o.darkGrey};
`
