import axios, { AxiosRequestConfig } from 'axios'

// <-------------------------INFERFACE------------------------->

export default class AiGeneration {
  static endpoint = '/aiGeneration'

  static async generatePosts(params: {
    prompt: string
    workspaceId: string
    socialAccountId: string
    provider: string
    environment: string
  }) {
    const url = 'https://tze6s2t7dnxcig32fy5fxlgi6a0xklgj.lambda-url.eu-central-1.on.aws/'

    const param = {
      prompt: params.prompt,
      socialAccountId: params.socialAccountId,
      provider: params.provider,
    }
    const datas = JSON.stringify(param)

    const config: AxiosRequestConfig = {
      method: 'POST',
      maxBodyLength: Infinity,
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
      data: datas,
      timeout: 300000000,
    }

    const { data } = await axios.request(config)
    return data
  }
}
