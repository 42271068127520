/* ------------------- IMPORTS */
import Reports, { IMetricParams, IPostFilters } from 'api/Reports'
import Utils from 'api/Utils'
import { Graph } from 'components/Analytics/Graph/Graph'
import { MAX_TABLE_PAGE_SIZE } from 'components/Analytics/Graph/singleGraph/table/TableGraph.utils'
import moment from 'moment'
import SkeletonCard from 'pages/ai-discover/UI/SkeletonCard'
import { ToolTip } from 'pages/settings/Subscription/SubscriptionProvider'
import { useEffect, useState } from 'react'
import { GraphType, IReportConfig, IReportDataComplete } from 'types/AnalyticsInferfaces'
import { T } from 'utils'
import { maxRangeError, notEnoughUsersError } from 'utils/AnalyticsUtils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  graph: IReportDataComplete
  reportInfo: IReportConfig
}

export const RenderGraph = ({ graph, reportInfo }: Props) => {
  /* ----------------- STATE  */
  const [graphData, setGraphData] = useState<any>()
  const [error, setError] = useState<boolean>(false)
  const [graphPagination, setGraphPagination] = useState<any>(null)
  const [emptyMessage, setEmptyMessage] = useState<string | undefined>(undefined)
  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  const tooltipData: ToolTip = {
    text: graph.metricTooltipDescription,
  }

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */
  const fetchMetricData = async (withFilters?: IPostFilters, page?: number) => {
    const metricParams: IMetricParams = {
      account: graph.socialProfile._id!,
      metricId: graph.metricRef,
      since: reportInfo.since,
      until: reportInfo.until,
    }

    if (withFilters) {
      const today = new Date()
      const sameDayOneMonthAgo = moment(today).subtract(1, 'months').toDate()
      const date = {
        from: reportInfo.since ? new Date(reportInfo.since) : sameDayOneMonthAgo,
        to: reportInfo.until ? new Date(reportInfo.until) : today,
      }
      metricParams.filters = {
        ...withFilters,
        date,
      }
    }

    if (page) {
      metricParams.page = page.toString()
    }

    if (graph.metricGraph === 'table') {
      metricParams.limit = MAX_TABLE_PAGE_SIZE.toString()
    }

    try {
      // console.log('metricParams2', metricParams)
      const { data, pagination, errorP: errorP } = await Reports.getMetricData(reportInfo._id!, metricParams)
      // console.log('data', errorP)
      setGraphPagination(pagination)
      setGraphData(data)
    } catch (e: any) {
      const { provider, days, maxRange } = maxRangeError(e.toString())
      if (notEnoughUsersError(e.toString())) {
        setEmptyMessage(T.error.analyticsNotEnoughUsers)
      } else if (maxRange) {
        setGraphData([])
        const errorMessage = T.error.analyticsPeriodErrorSocial
          .replace('{provider}', provider)
          .replace('{days}', days.toString())
          .replace(/^\w/, (c) => c.toUpperCase())
        setEmptyMessage(errorMessage)
      } else {
        setEmptyMessage(T.error.no_data_at_this_moment)
      }
      setGraphData([])
      setError(true)
    }
  }

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    fetchMetricData()
  }, [])

  return (
    <>
      {!error && !graphData && <SkeletonCard type="analyticsGraph" num={1} key={graph.metricRef} />}

      {graphData && (
        <Graph
          emptyMessage={emptyMessage}
          onDataRefresh={fetchMetricData}
          pagination={graphPagination}
          error={error}
          identifier={graph.metricRef}
          socialProfile={graph.socialProfile}
          key={Utils.generateRandomUIDKey()}
          type={graph.metricGraph as GraphType}
          title={graph.metricName}
          socialPicture={graph.socialProfile.picture}
          datasets={graphData}
          tooltipInfo={tooltipData}
          numData={graph.metricGraph === 'text' ? graphData?.[0]?.data : undefined}
          labelText={graph.metricGraph === 'text' ? graphData?.[0]?.label : undefined}
          headerData={graph.metricGraph !== 'text' ? graphData?.[0]?.headerGraphData : undefined}
          tooltipLabels={graphData?.[0]?.config?.tooltip?.labels ?? ['', '']}
        />
      )}

      {/* {error && <div>Error fetching metric data</div>} */}
    </>
  )
}
