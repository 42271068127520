import { CalendarPage } from 'pages'

const CalendarRouter = [
  {
    path: '/calendar',
    element: <CalendarPage />,
  },
]

export { CalendarRouter }
