/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { SocialUser } from 'components/UI'
import { IPostVariant } from 'types/PostInterface'
import { Brand } from '../GenericExtras/Brand'
import { FirstComment } from '../GenericExtras/FirstComment'
import { Location } from '../GenericExtras/LocationsComponents/Location'

/* ------------------- INTERFACES  */
interface Props {
  post: IPostVariant
}

export const FacebookExtrasContainer = ({ post }: Props) => {
  const { picture, provider, name } = post.info.account

  return (
    <FacebookExtrasContainerContainer>
      <SocialUser picture={picture} provider={provider} name={name} />

      <ExtrasContent>
        <FirstComment post={post} />

        <Brand post={post} />

        <Location post={post} />
      </ExtrasContent>
    </FacebookExtrasContainerContainer>
  )
}

const FacebookExtrasContainerContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 40px;
`

const ExtrasContent = styled(Box)`
  width: 100%;
  max-width: 541px;
  flex-direction: column;
  gap: 24px;
  border: none;
`
