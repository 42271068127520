import { MediaPage } from 'pages'

const MediaRouter = [
  {
    path: '/Media',
    element: <MediaPage />,
  },
]

export { MediaRouter }
