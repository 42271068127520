import { questionMark } from 'assets/icons'
import { Box } from 'components/Box'
import imgBackground from './sfondo.jpg'

import styled from '@emotion/styled'
import { Input } from 'antd'
import Utils from 'api/Utils'
import { Button } from 'components/Button'
// import SelectLeghtGeneration from 'components/CreatePost/PostFormComponent/SelectLeghtGeneration'
import { Icons } from 'components/Icons'
import { Text } from 'components/Text'
import { gsap } from 'gsap'
import { FirstOne } from 'pages/demo/Lotties/FirstOne'
import { useEffect, useState } from 'react'
import { AppStore, T, showError, showInfo, showSuccess } from 'utils'

export default function RiminiPage() {
  const [textGenerated, setPost] = useState(false)
  const [texts, setTexts] = useState<string[]>([])

  const [link, setLink] = useState('')
  const [lenght, setLenght] = useState(0)

  useEffect(() => {
    if (textGenerated) {
      setTimeout(() => {
        gsap.set('#lottieContainer', { display: 'none' })
      }, 2000)
    }
  }, [textGenerated])

  const generateFromLink = async () => {
    const social = lenght === 0 ? 'x' : lenght === 1 ? 'Instagram' : 'LinkedIn'
    showInfo(T.info.gettingDataFromSite)
    try {
      const siteData: any = await Utils.getSiteData(link, social)

      if (siteData === null || siteData === undefined) return showError(T.error.retrieveDataFromSite)
      if (siteData.post !== undefined) {
        showSuccess(T.success.postGenerated)
        setTexts(siteData.post)
      } else {
        showInfo(T.info.blockerPreventing)
      }
    } catch (e) {
      showError(T.error.serverError)
      showInfo(T.info.generationAttempt)
      return
    }
  }

  const width = window.innerWidth

  return (
    <Box
      removeTransition
      relative
      flex
      style={{ width: '100vw', height: '100vh' }}
      hcenter
      pt={width > 2000 ? '20%' : '15%'}
    >
      <img
        style={{ width: '100vw', height: '100vh', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
        src={imgBackground}
      />
      {textGenerated && (
        <>
          <Box
            id={'lottieContainer'}
            center
            removeTransition
            style={{
              position: 'absolute',
              minHeight: window.innerHeight,
              minWidth: window.innerWidth,
              maxHeight: ' 80vh',
              y: -200,
              zIndex: 99999,
              scale: 1.5,
            }}
          >
            <Box>
              <FirstOne />
            </Box>
          </Box>
        </>
      )}
      <Box
        p={25}
        style={{
          backgroundColor: AppStore.theme.o.surface,
          width: '80%',
          zIndex: 999,
          maxHeight: 600,
        }}
        relative
        removeTransition
        id={'totalContainer'}
      >
        <TextContainerBox center>
          <Box vcenter row flex gap={24} style={{ width: '100%' }}>
            <Box width={220} flex>
              {/* <SelectLeghtGeneration
                social
                selected={lenght}
                setSelected={(e) => {
                  setLenght(e)
                }}
              /> */}
            </Box>
            <Input
              style={{
                height: 42,
                width: '100%',
                zIndex: 999,
                border: `1px dotted ${AppStore.theme.o.grey}`,
                borderRadius: 16,
              }}
              onChange={(e) => {
                setLink(e?.target?.value ?? '')
              }}
              prefix={<Icons.smallLink fill={AppStore.theme.o.grey} />}
            />
            <Box row vcenter style={{ zIndex: 999 }}>
              <Button
                width={212}
                size="small"
                mr={16}
                variant="primary"
                hideBoxShadowOnHover
                onClick={async () => {
                  await generateFromLink()
                  if (link === '') {
                    showInfo(T.info.enterLink)
                    return
                  }
                  setPost(true)
                }}
              >
                {T.postPage.generate}
              </Button>
              <a id="help-generate-text" title="Tour guidato">
                <img src={questionMark} />
              </a>
            </Box>
          </Box>
          <Box scroll hideBar style={{ maxHeight: 400 }} mt={40}>
            {texts.map((text, index) => (
              <Box mt={20} key={index} row style={{ zIndex: 1, width: 'auto' }}>
                <Box
                  flex
                  p={20}
                  style={{
                    backgroundColor: AppStore.theme.o.lightGrey,
                    borderColor: AppStore.theme.o.green,
                    borderStyle: 'dashed',
                  }}
                >
                  <Text style={{ whiteSpace: 'pre-line' }}>{text}</Text>
                </Box>
              </Box>
            ))}
          </Box>
          <ColorFullContainer />
        </TextContainerBox>
      </Box>
    </Box>
  )
}

const TextContainerBox = styled(Box)`
  min-width: 850px;
  position: relative;
  padding: 20px;
  max-height: 888px;
`

const ColorFullContainer = styled(Box)`
  content: '';
  position: absolute;
  inset: 0;
  stroke: Angular Gradient;
  border-radius: 16px;
  padding: 1.5px;
  background: linear-gradient(45deg, #3fe8ab, #45d9e6, #ffb700, #3fe8ab);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: 0;
`
