/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { AppStore, T } from 'utils'
import { EnvironmentCard } from './EnvironmentCard'
import Utils from 'api/Utils'
import React from 'react'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface IEnvironmentData {
  name: string
  value: number | undefined
  icon: JSX.Element
}

interface Props {
  postPublished: number
  postPlanned: number
  postNotPublished: number
}

export const DashboardEnvironment = ({ postPublished, postPlanned, postNotPublished }: Props) => {
  /* ----------------- CONFIG  */
  const dashboardEnvironmentConfig: IEnvironmentData[] = [
    {
      name: T.DashboardPage.planedPost,
      value: postPlanned,
      icon: <Icons.calendarIcon fill={AppStore.theme.o.black} />,
    },
    {
      name: T.DashboardPage.publishedPost,
      value: postPublished,
      icon: <Icons.positiveCheck fill={AppStore.theme.o.secondary} />,
    },
    {
      name: T.DashboardPage.notPublishedPost,
      value: postNotPublished,
      icon: <Icons.negativeCheck fill={AppStore.theme.o.error} />,
    },
  ]

  return (
    <DashboardEnvironmentContainer>
      {dashboardEnvironmentConfig.map((data, i) => (
        <React.Fragment key={Utils.generateRandomUIDKey()}>
          <EnvironmentCard name={data.name} value={data.value} icon={data.icon} />
          {i + 1 < dashboardEnvironmentConfig.length && <LinerSpace />}
        </React.Fragment>
      ))}
    </DashboardEnvironmentContainer>
  )
}

const DashboardEnvironmentContainer = styled(Box)`
  display: flex;
  padding: 32px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: 14px;
  border: 2px solid ${() => AppStore.theme.o.lightGrey};
  width: 1158px;
  height: 152px;
`

const LinerSpace = styled(Box)`
  background-color: ${() => AppStore.theme.o.lightGrey};
  width: 1.5px;
  height: 88px;
  margin: 0 48px;
`
