import AstersBaseResource from './AstersBaseResource'
import { api } from './api'

export default class Tags extends AstersBaseResource {
  static endpoint = '/tags'

  static searchLables(text) {
    return api.get(`${this.endpoint}/search/${text}`)
  }
}
