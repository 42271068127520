/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import PostV2 from 'api/PostV2'
import { Box } from 'components'
import { LabelsContent } from 'components/CreatePost/PreviewComponents/LabelsComponents/LabelsContent'
import { ILabel } from 'components/CreatePost/PreviewComponents/LabelsComponents/LabelsContentComponents/Label'
import { useState } from 'react'
import { AppStore, showInfo, T } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  postId: string
  className: string
  labels: ILabel[]
  refreshPostLabels: (newLabels: ILabel[]) => void
}

export const PreviewLabels = ({ postId, className, labels, refreshPostLabels }: Props) => {
  /* ----------------- STATE  */
  const [selectedLabels, setSelectedLabels] = useState<ILabel[]>(labels)

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */
  const handleAddLabel = (label: ILabel) => {
    const isAlreadyAdded = selectedLabels?.find((lb) => lb._id === label._id)

    if (isAlreadyAdded) {
      return showInfo(T.postPage.labelsSection.alreadyAdded)
    }

    const newSelected = [...selectedLabels!, { ...label }]

    setSelectedLabels(newSelected)
    updatePostLabels(newSelected)
  }

  const handleRemoveLabel = (label: ILabel) => {
    const newSelected = selectedLabels?.filter((lb) => lb._id !== label._id)

    setSelectedLabels(newSelected)
    updatePostLabels(newSelected)
  }

  /* ----------------- API CALL  */
  const updatePostLabels = async (newSelected: ILabel[]) => {
    const labelsParam: string[] = newSelected.map((label) => label._id)

    try {
      const res = await PostV2.updateLabel(postId, labelsParam)
      refreshPostLabels(res)
    } catch (e) {
      console.error(e)
    }
  }

  /* ----------------- USEEFFECT  */

  return (
    <PreviewLabelsContainer className={className} data-html2canvas-ignore='true'>
      <LabelsContent
        fromSection="previewPost"
        selectedLabels={selectedLabels}
        setSelectedLabels={setSelectedLabels}
        handleAddLabel={handleAddLabel}
        handleRemoveLabel={handleRemoveLabel}
      />
    </PreviewLabelsContainer>
  )
}

const PreviewLabelsContainer = styled(Box)`
  position: absolute;
  top: 3%;
  bottom: 3%;
  right: 3%;
  background-color: ${() => AppStore.theme.o.surface};
  box-shadow: ${() => AppStore.theme.smallOuterShadow};
  width: 654px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 999;

  opacity: 0;
  scale: 0;
  transition: opacity 0.3s ease-in-out, scale 0.3s ease-in-out;

  &.selected_container {
    opacity: 1;
    scale: 1;
  }
`
