/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import Socialaccounts from 'api/Socialaccounts'
import { Box, Icons, Text } from 'components'
import { useEffect, useState } from 'react'
import { IPlace, IPostVariant } from 'types/PostInterface'
import { AppStore, showInfo, T } from 'utils'
import { updatePost } from 'utils/PostUtils/Post'
import { ExtraTitle } from '../ExtraTitle'
import { InfoExtra } from '../InfoExtra'
import { LocationItem } from './LocationItem'
import { clear } from 'console'

/* ------------------- INTERFACES  */
interface Props {
  post: IPostVariant
}

export const Location = ({ post }: Props) => {
  /* ----------------- STATE  */
  const [places, setPlaces] = useState<IPlace[]>([])
  const [inputValue, setInputValue] = useState<string>('')
  const [inputFocus, setInputFocus] = useState<boolean>(false)
  const [urlImg, setUrlImg] = useState<string>('')

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */
  const selectedPlace = post.data.place

  const inInstagram = post.info.account.provider === 'instagram'
  const isInstagramCarousel = inInstagram && post.data.medias.length > 1

  /* ----------------- METHODS  */

  /**
   *  Aggiunge un luogo al post
   * @param place IPlace
   */
  const handleAddPlace = (place: IPlace) => {
    updatePost(post.variantId, { place })
    setInputValue('')
    setInputFocus(false)
  }

  /**
   * Rimuove il luogo dal post
   */
  const handleRemovePlace = () => {
    updatePost(post.variantId, { place: null })
  }

  const handleClickInput = () => {
    if (isInstagramCarousel) showInfo(T.postPage.generalExtra.noCarouselInstagramLocation)
  }

  /* ----------------- API CALL  */
  /**
   * Recupera i luoghi
   */
  const fetchPlaces = async () => {
    try {
      const res = await Socialaccounts.getPlaces(post.info.account.account_id!, inputValue)

      setPlaces(res)
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * Recupera l'immagine del luogo
   * @param id string id del luogo
   */
  const getPlaceImage = async (id: string) => {
    try {
      const res = await fetch(`https://graph.facebook.com/${id}/picture`)
      return res.url
    } catch (e) {
      console.error(e)
    }
    return ''
  }

  const setImg = async (id: string) => {
    const url = await getPlaceImage(id)
    setUrlImg(url)
  }

  let delayDebounceFn
  /* ----------------- USEEFFECT  */
  /**
   * Effettua la chiamata per recuperare i luoghi con debounce
   */
  useEffect(() => {
    if (inputValue === '') return setPlaces([])
    clearTimeout(delayDebounceFn)
    delayDebounceFn = setTimeout(() => {
      fetchPlaces()
    }, 200)

    return () => clearTimeout(delayDebounceFn)
  }, [inputValue])

  useEffect(() => {
    selectedPlace && setImg(selectedPlace.id!)
  }, [selectedPlace])

  return (
    <LocationContainer>
      <Box row gap={8} vcenter>
        <ExtraTitle text={T.postPage.extraLocation} />

        {inInstagram && <InfoExtra infoText={T.postPage.generalExtra.noCarouselInstagramLocation} />}
      </Box>

      <ContainerLocation
        onClick={handleClickInput}
        style={{
          cursor: isInstagramCarousel ? 'not-allowed' : 'text',
        }}
      >
        <Icons.smallGps fill={AppStore.theme.o.darkGrey} width={24} height={24} />

        {selectedPlace && (
          <SelectedItem>
            <Box row center gap={10}>
              <PlaceImg src={urlImg} />
              <Text>{selectedPlace?.name}</Text>
            </Box>

            <RemovePlace onClick={handleRemovePlace}>
              <Icons.sizerClose fill={AppStore.theme.o.darkGrey} />
            </RemovePlace>
          </SelectedItem>
        )}

        {!selectedPlace && (
          <InputPlace
            disabled={isInstagramCarousel}
            onFocus={() => setInputFocus(true)}
            onBlur={() => setInputFocus(false)}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={T.postPage.extraLocationPlaceholder}
            style={{
              cursor: isInstagramCarousel ? 'not-allowed' : 'text',
              zIndex: isInstagramCarousel ? -1 : 1,
            }}
          />
        )}
      </ContainerLocation>

      {inputFocus && inputValue.length !== 0 && places.length > 0 && (
        <ResultsContainer onMouseDown={(e) => e.preventDefault()}>
          {places.map((place) => (
            <LocationItem key={place.id} handleAddPlace={handleAddPlace} place={place} getPlaceImage={getPlaceImage} />
          ))}
        </ResultsContainer>
      )}
    </LocationContainer>
  )
}

const LocationContainer = styled(Box)`
  gap: 16px;
  width: 100%;
  position: relative;
`

const ResultsContainer = styled(Box)`
  width: 100%;
  padding: 8px 16px;
  background-color: ${() => AppStore.theme.o.lightestGrey};
  border: 1px solid ${() => AppStore.theme.o.grey};
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  z-index: 100;
  gap: 4px;
  max-height: 300px;
  overflow-y: scroll;
  padding-right: 8px;
`

const ContainerLocation = styled(Box)`
  width: 100%;
  font-size: 16px;
  color: ${() => AppStore.theme.o.black};
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 54px;
  padding-left: 24px;
  align-items: center;
  border: 1px dashed ${() => AppStore.theme.o.darkGrey};
  background: ${() => AppStore.theme.o.lightGrey};
`

const SelectedItem = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-right: 24px;
`

const PlaceImg = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`

const InputPlace = styled.input`
  background-color: transparent;
  border: none;
  flex: 1;
`

const RemovePlace = styled(Box)`
  border-radius: 50%;
  cursor: pointer;
  background: ${() => AppStore.theme.o.lightGrey};
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: ${() => AppStore.theme.o.surface};
  }
`
