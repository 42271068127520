import styled from '@emotion/styled'
import { Box } from 'components/Box'
import { stat } from 'fs'
import { useAnalyticsStore } from 'stores/AnalyticsStore'
import { AppStore } from 'utils'

const RenderReportName = () => {
  const reportPreviewName = useAnalyticsStore((state) => state.reportPreviewName)

  return <ReportNameContainer>{reportPreviewName ?? ''}</ReportNameContainer>
}

export default RenderReportName

const ReportNameContainer = styled(Box)`
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 21px;
  line-height: 25.24px;
  font-weight: bold;
  letter-spacing: 1%;
  color: ${() => AppStore.theme.o.black};
`
