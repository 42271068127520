/* ------------------- IMPORTS */
import { GridContainer } from 'components/UI'
import { MetricCard } from '../UI/MetricCard'
import { useState, useEffect } from 'react'
import { IDataReport, IMetric, IReportConfig, ISelectableSocial } from 'types/AnalyticsInferfaces'
import { getMetrics } from 'utils/AnalyticsUtils'
import { useAnalyticsStore } from 'stores/AnalyticsStore'

/* ------------------- INTERFACES  */
interface Props {
  metrics: IMetric[]
  reportPreview: boolean
  reportConfig: IReportConfig
  setReportConfig: (reportConfig: IReportConfig) => void
}

export const CreateReportMetrics = ({ metrics, reportPreview, reportConfig, setReportConfig }: Props) => {
  /* ----------------- STATE  */
  // const [metrics, setMetrics] = useState<IMetric[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { accountSelected, setAccountSelected } = useAnalyticsStore((state) => ({
    accountSelected: state.selectedAccount,
    setAccountSelected: state.setSelectedAccount,
  }))
  // const [accountSelected, setAccountSelected] = useState<ISelectableSocial>(
  //   useAnalyticsStore.getState().selectedAccount
  // )

  /* ------------------- ZUSTAND SUBSCRIPTION  */
  // const unsubAccountSelected = useAnalyticsStore.subscribe((state) => {
  //   state.selectedAccount !== accountSelected && setAccountSelected(state.selectedAccount)
  // })

  /* ----------------- VARIABLES RENDER */

  const metricList =
    metrics &&
    metrics
      .filter((metric) => metric.provider === accountSelected.provider /* && metric.query != undefined*/)
      .map((metric) => {
        // Cerca l'indice dell'account selezionato in reportConfig.data
        const selectedIndex = reportConfig.data.findIndex((data) => data.account === accountSelected._id)

        const data = reportConfig.data[selectedIndex] as IDataReport
        // Verifica se l'ID della metrica è presente nell'array delle metriche dell'account selezionato
        const isSelected = selectedIndex !== -1 && data.metrics!.includes(metric.ref!)

        return (
          <MetricCard
            content={metric}
            isSelected={isSelected}
            action={() => onClickMetric(selectedIndex, isSelected, metric)}
          />
        )
      })

  /* ----------------- METHODS  */
  const onClickMetric = (selectedIndex: number, isSelected: boolean, metric: IMetric) => {
    if (selectedIndex !== -1) {
      const updatedReportConfig = { ...reportConfig }
      const accountMetrics = (updatedReportConfig.data[selectedIndex] as IDataReport).metrics

      if (isSelected) {
        // Rimuovi l'ID della metrica se è già selezionato
        accountMetrics!.splice(accountMetrics!.indexOf(metric.ref), 1)
      } else {
        // Aggiungi l'ID della metrica se non è già selezionato
        accountMetrics!.push(metric.ref!)
      }

      setReportConfig(updatedReportConfig)
    }
  }

  const preSelectAllMetrics = () => {
    // Current account metrics
    const currentAccountMetrics =
      reportConfig.data.find((data) => data.provider === accountSelected.provider)?.metrics ?? []

    // User has already selected metrics?
    const hasUserSelectedMetrics = currentAccountMetrics.length > 0 ?? false
    if (hasUserSelectedMetrics) return

    // -------- select all metrics for the selected account --------
    // Pre-select all metrics for the selected account
    metricList.forEach((metric) => {
      const metricRef = metric.props.content.ref
      if (!currentAccountMetrics.includes(metricRef)) {
        currentAccountMetrics.push(metricRef)
      }
    })

    // Update reportConfig with selected metrics
    const updatedReportConfig = { ...reportConfig }
    updatedReportConfig.data.forEach((data) => {
      if (data.provider === accountSelected.provider) {
        data.metrics = currentAccountMetrics
      }
    })

    setReportConfig(updatedReportConfig)
  }

  /* ----------------- API CALL */
  const fetchMetrics = async () => {
    preSelectAllMetrics()
    // setIsLoading(true)
    // const response = await getMetrics()
    // setMetrics(response)
    // // if (!reportPreview) {
    // //   // --all-metrics--
    // //   // set all metrics selected
    // //   const updatedReportConfig = { ...reportConfig }
    // //   updatedReportConfig.data.forEach((data) => {
    // //     data.metrics = response.map((metric) => metric.ref)
    // //   })
    // //   setReportConfig(updatedReportConfig)
    // // }
    // setIsLoading(false)
  }

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    fetchMetrics()
    // return () => {
    //   unsubAccountSelected()
    // }
  }, [accountSelected, metrics])

  return <GridContainer cols={3} data={metricList} isLoading={isLoading} />
}
