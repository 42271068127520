/* ******************* IMPORTS ******************* */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { openManageDiscoverModal } from 'components/Modal/ModalManager'
import { useEffect, useState } from 'react'
import { useDiscoverStore } from 'stores/DiscoverStore'
import { AppStore, T } from 'utils'
import { TSidebarType } from '../aiDiscoverInterface'
import AsideContent from './AsideContent'
import AsideHeader from './AsideHeader'

const DiscoverAside = () => {
  /* ____________________ STATE  ____________________ */
  const [sidebarType, setSidebarType] = useState<TSidebarType>('follow')
  const [searchTopics, setSearchTopics] = useState<string>('')
  const [searchBoards, setSearchBoards] = useState<string>('')

  /* ____________________ ZUSTAND  ____________________ */
  const { subPageActive, inputSearchbar, setActiveTopicSidebar, suggestedTopicList } = useDiscoverStore((state) => ({
    subPageActive: state.subPageActive,
    inputSearchbar: state.inputSearchbar,
    setActiveTopicSidebar: state.setActiveTopicSidebar,
    suggestedTopicList: state.suggestedTopicList,
  }))

  /* ____________________ VARIABLES  ____________________ */

  const topics = AppStore.discoverUser?.topicsId ?? []
  const boards = AppStore.discoverUserBoardList

  /* ____________________ METHODS  ____________________ */
  const changeSidebarType = () => {
    if (subPageActive === 'follow') {
      setSidebarType('follow')
    } else if (subPageActive === 'search' && !inputSearchbar) {
      setSidebarType('follow')
    } else if (subPageActive === 'search' && inputSearchbar) {
      setSidebarType('suggested')
    } else if (subPageActive === 'board') {
      setSidebarType('board')
    }
  }

  /* ____________________ USEEFFECT  ____________________ */
  // Controlla in che sidebar siamo, in base alla pagina selezionata e input ricercato
  useEffect(() => {
    changeSidebarType()
  }, [subPageActive, inputSearchbar])

  useEffect(() => {
    if (subPageActive === 'search') {
      setActiveTopicSidebar(null)
    }
  }, [subPageActive])

  return (
    <Aside>
      {/* Seguiti Sidebar */}
      {sidebarType === 'follow' && (
        <AsideContainer>
          <AsideHeader sidebarType={sidebarType} searchContent={searchTopics} setSearchContent={setSearchTopics} />
          {topics.length > 0 && <AsideContent searchContent={searchTopics} sidebarType={sidebarType} />}
        </AsideContainer>
      )}

      {/* Suggeriti Sibear */}
      {sidebarType === 'suggested' && (
        <AsideContainer>
          <AsideHeader sidebarType={sidebarType} />
          {suggestedTopicList.length > 0 && <AsideContent sidebarType={sidebarType} />}
        </AsideContainer>
      )}

      {/* Board Sidebar */}
      {sidebarType === 'board' && (
        <AsideContainer>
          <AsideHeader sidebarType={sidebarType} searchContent={searchBoards} setSearchContent={setSearchBoards} />
          {boards.length > 0 && <AsideContent searchContent={searchBoards} sidebarType={sidebarType} />}
        </AsideContainer>
      )}

      {/* Button "Gestisci" dei Seguiti */}
      {sidebarType === 'follow' && topics.length > 0 && (
        <AsideButton onClick={() => openManageDiscoverModal(sidebarType)}>
          <Icons.settingsIcon /> {T.aiDiscoverPage.sidebar.manage}
        </AsideButton>
      )}

      {/* Button "Gestisci" dei Board" */}
      {sidebarType === 'board' && boards.length > 0 && (
        <AsideButton onClick={() => openManageDiscoverModal(sidebarType)}>
          <Icons.settingsIcon /> {T.aiDiscoverPage.sidebar.manage}
        </AsideButton>
      )}
    </Aside>
  )
}

export default DiscoverAside

const Aside = styled(Box)`
  max-width: 258px;
  /* Altezza ContentHeader + il gap (66 + 16) */
  margin-top: 82px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const AsideContainer = styled(Box)`
  padding: 32px 16px 32px 24px;
  width: 258px;

  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  border-radius: 14px;
  border: 1px solid ${() => AppStore.theme.o.lightGrey};
  background-color: ${() => AppStore.theme.o.surface};
  box-shadow: ${() => AppStore.theme.mainShadow};
`

const AsideButton = styled.button`
  border: none;
  background-color: transparent;

  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: flex-end;
  gap: 8px;
  margin: 0 auto;
  width: 118px;

  border-radius: 14px;

  border: 1px solid transparent;

  color: ${() => AppStore.theme.o.darkGrey};
  cursor: pointer;

  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  transition: 300ms ease-in-out;

  &:hover {
    border: 1px solid ${() => AppStore.theme.o.grey};
    background: ${() => AppStore.theme.o.lightestGrey};
  }
`
