/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { AppStore } from 'utils'

/* ------------------- INTERFACES  */
interface Props {
  action: (event?: Event) => void
}

export const CloseButton = ({ action }: Props) => {
  return (
    <CloseButtonContainer onClick={action}>
      <Icons.closeButton fill={AppStore.theme.o.black} className="close_icon" />
    </CloseButtonContainer>
  )
}

const CloseButtonContainer = styled(Box)`
  position: absolute;
  top: -4px;
  right: -6px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid ${() => AppStore.theme.o.black};
  background: ${() => AppStore.theme.o.white};

  &:hover {
    scale: 1.1;
  }

  .close_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
