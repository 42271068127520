/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { useCalendarStore } from 'stores/CalendarStore'
import { DayContent } from './SpecificDay/DayContent'
import { CalendarMonth } from './Calendar/CalendarMonth'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {}

export const ContentCalendar = ({}: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */
  const { calendarSection } = useCalendarStore((s) => ({
    calendarSection: s.calendarSection,
  }))

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <ContentCalendarContainer>
      {/*  MONTH */}
      <CalendarElement
        style={{
          transform: ` translateX(${calendarSection === 'month' ? 0 : -1500}px)`,
        }}
      >
        <CalendarMonth />
      </CalendarElement>

      {/* DAY */}
      <CalendarElement
        style={{
          transform: ` translateX(${calendarSection === 'day' ? 0 : 1500}px)`,
        }}
      >
        <DayContent />
      </CalendarElement>
    </ContentCalendarContainer>
  )
}

const ContentCalendarContainer = styled(Box)`
  position: relative;
  overflow: hidden;
  border-radius: 0px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  padding-top: 24px;
`

const CalendarElement = styled(Box)`
  position: absolute;
  z-index: 99;
  gap: 24;
  width: 100%;
  height: 100%;
  max-height: 100%;
  transition: 0.3s ease-in-out;
`
