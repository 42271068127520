import { AVAILABLE_SOCIALS, COOMING_SOCIALS } from 'utils/Constants/Constants'
import { IEnumTab } from 'types/SettingsInterfaces'

export const enumTab: IEnumTab = {
  account: 0,
  social: 1,
  workspace: 2,
  subscription: 3,
  modify: 3,
}

export const socials = AVAILABLE_SOCIALS //[ 'instagram', 'facebook', 'linkedin', 'x', 'TikTok', 'youtube']
export const socialCommingSoon = COOMING_SOCIALS //['Pinterest', 'google']

export const initialDays = [
  {
    day: 'Lun',
    active: false,
  },
  {
    day: 'Mar',
    active: false,
  },
  {
    day: 'Mer',
    active: false,
  },
  {
    day: 'Ven',
    active: false,
  },
  {
    day: 'Gio',
    active: false,
  },
  {
    day: 'Sab',
    active: false,
  },
]
