import styled from '@emotion/styled'
import { Box } from 'components/Box'
import React from 'react'
import { AppStore } from 'utils'

interface Props {
  numOfRow: number
}

const darkMode = localStorage.getItem('darkTheme')

let gradientColor = ''

if (darkMode === 'true') {
  gradientColor = `linear-gradient(
    120deg,
    hsla(0, 0%, 22%, 0.6) 20%,
    hsl(0, 0%, 22%) 38%,
    hsl(0, 0%, 22%) 40%,
    hsla(0, 0%, 22%, 0.6) 80%
  )`
}

if (darkMode === 'false') {
  gradientColor = `linear-gradient(
    120deg,
    hsl(0, 0%, 90%) 20%,
    hsl(0, 0%, 93%) 38%,
    hsl(0, 0%, 93%) 40%,
    hsl(0, 0%, 90%) 80%
  )`
}

const ApiKeySkeletonItem = ({ numOfRow }: Props) => {
  return (
    <>
      {Array.from(Array(numOfRow)).map((ar, i) => {
        if (i % 2 === 0) return <ApiRowTable className="normal-skeleton" />
        return <ApiRowTable className="not-normal-skeleton" />
      })}
    </>
  )
}

export default ApiKeySkeletonItem

const ApiRowTable = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  margin-top: 6px;
  margin-bottom: 6px;
  padding: 12px 40px 12px 16px;

  gap: 20px;
  border-radius: 14;
  border-bottom: 1px solid ${() => AppStore.theme.o.grey};

  background: ${gradientColor};
  background-size: 200% 100%;
  background-position: 100% 0;

  &.normal-skeleton {
    opacity: 0.7;
    animation: loading-skeleton 1.4s infinite;
  }
  &.normal-skeleton {
    opacity: 0.4;
    animation: loading-skeleton 1.6s infinite;
  }
`
