import styled from '@emotion/styled'
import { AVAILABLE_SOCIALS } from 'utils/Constants/Constants'
import { Box, Icons, SocialIcon, Text } from 'components'
import { Component } from 'react'
import { AppStore } from 'utils'
import { getActiveText } from 'utils/PostUtils/Post'

interface State {
  characterBarVisible: boolean
  invisibleBox: boolean
}

interface Props {}

export class TextCharsCount extends Component<Props, State> {
  state: State = {
    characterBarVisible: false,
    invisibleBox: true,
  }

  mockSocial: Array<{ social: string; maxCharacters: number }> = [
    { social: 'facebook', maxCharacters: 4500 },
    { social: 'instagram', maxCharacters: 2200 },
    { social: 'x', maxCharacters: 280 },
    { social: 'linkedin', maxCharacters: 3000 },
    { social: 'tiktok', maxCharacters: 2200 },
    { social: 'youtube', maxCharacters: 2200 },
  ]

  returnIcon = (passed: number) => {
    if (!passed) return null

    const socials = this.mockSocial?.filter((social) => social.social !== 'google')

    const returnedComponents = AVAILABLE_SOCIALS.map((provider) => {
      const found = socials.find((social) => social.social === provider)
      if (found !== undefined && found?.maxCharacters > passed) {
        return null
      }

      if (found === undefined || found === null) return null

      switch (provider) {
        case 'facebook':
          return (
            <IconBox>
              <Icons.facebookIconFillAble fill={AppStore.theme.o.red} />
            </IconBox>
          )
        case 'x':
          return (
            <IconBox>
              <Icons.twitterIconFillAble fill={AppStore.theme.o.red} />
            </IconBox>
          )
        case 'instagram':
          return (
            <IconBox>
              <Icons.instagramNoColorIcon fill={AppStore.theme.o.red} />
            </IconBox>
          )

        case 'linkedin':
          return (
            <IconBox>
              <Icons.linkedinIconFillAble fill={AppStore.theme.o.red} />
            </IconBox>
          )
        default:
          return null
      }
    })

    return returnedComponents.filter((item: any) => item !== null)
  }

  render() {
    const { characterBarVisible, invisibleBox } = this.state

    const socials = this.mockSocial?.filter((social) => social.social !== 'google')

    const min: any = { social: 'null', maxCharacters: 5000 }

    return (
      <Box style={{ position: 'relative' }}>
        <Box
          onHoverStart={() => {
            this.setState({ invisibleBox: true })
            setTimeout(() => {
              this.setState({ characterBarVisible: true })
            }, 100)
          }}
          onHoverEnd={() => {
            this.setState({ characterBarVisible: false })
          }}
          style={{
            backgroundColor: getActiveText()?.length > min.maxCharacters ? AppStore.theme.o.lightRed : 'transparent',
          }}
          height={42}
          borderRadius={8}
          pv={8}
          gap={0}
          ph={4}
          row
          vcenter
        >
          <Icons.characterCouter
            style={{ maxWidth: 25, maxHeight: 25, marginRight: 4 }}
            fill={getActiveText()?.length > min.maxCharacters ? AppStore.theme.o.red : AppStore.theme.o.black}
          />
          <Text
            fontSize={12}
            weight={700}
            color={getActiveText()?.length > min.maxCharacters ? AppStore.theme.o.red : AppStore.theme.o.black}
          >
            {getActiveText()?.length}
          </Text>
          {this.returnIcon(getActiveText()?.length)}
        </Box>

        {/* the on hover box */}
        {invisibleBox && (
          <Box
            removeTransition
            shadow="box-shadow:none!important;"
            style={{
              position: 'absolute',
              minWidth: 300,
              minHeight: 340,
              top: -340,
              overflow: 'hidden',
              zIndex: 1,

              boxShadow: 'none',
            }}
            onHoverStart={() => {
              this.setState({ invisibleBox: false })
            }}
            onHoverEnd={() => {
              this.setState({ invisibleBox: true })
            }}
          >
            <Box
              style={{
                position: 'relative',
              }}
              bc={'yellow'}
            >
              <Box
                id={'hidden-character-bar'}
                style={{
                  zIndex: 100,
                  position: 'absolute',
                  backgroundColor: AppStore.theme.o.surface,
                  top: 70,
                  border: `1px solid ${AppStore.theme.o.darkGrey}`,
                  y: characterBarVisible ? 0 : 1000,
                  height: 266,
                }}
                shadow={AppStore.theme.bigOuterShadow}
                pv={16}
                ph={25}
                gap={28}
              >
                {socials?.map((social) => (
                  <Box vcenter row>
                    <SocialIcon
                      style={{ width: 25, height: 25, marginRight: 4 }}
                      social={social.social as any}
                      active={true}
                    />

                    <TextFont
                      style={{
                        color:
                          getActiveText()?.length <= social.maxCharacters
                            ? AppStore.theme.o.black
                            : AppStore.theme.o.red,
                      }}
                    >
                      {social.social} {getActiveText()?.length}/{social.maxCharacters}
                    </TextFont>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    )
  }
}

const IconBox = styled(Box)`
  max-width: 32px;
  max-height: 32px;
`

const TextFont = styled.p`
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
`
