/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Button } from 'components'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { AppStore, T } from 'utils'

/* ------------------- INTERFACES  */
interface Props {
  isGlobalActive: boolean
}

export const GlobalCheck = ({ isGlobalActive }: Props) => {
  return (
    <GlobalCheckContainer
      variant={isGlobalActive ? 'secondary' : 'neutral'}
      shadow={isGlobalActive ? AppStore.theme.smallOuterShadow : undefined}
      pv={16}
      ph={24}
      style={{ minHeight: 52, minWidth: 'fit-content' }}
      onClick={() => {
        const { setActiveAccountId } = useCreatePostStore.getState()
        setActiveAccountId('GLOBAL')
      }}
    >
      {T.postPage.globalEdit}

      <Box
        style={{
          width: 32,
          height: 20,
          backgroundColor: isGlobalActive ? AppStore.theme.o.green : AppStore.theme.o.darkGrey,
          padding: 2,
        }}
      >
        <Box
          style={{
            width: 16,
            height: 16,
            backgroundColor: isGlobalActive ? AppStore.theme.o.lightGreen : AppStore.theme.o.surface,
            x: isGlobalActive ? 12 : 0,
          }}
        />
      </Box>
    </GlobalCheckContainer>
  )
}

const GlobalCheckContainer = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  gap: 8px;
`
