/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import PostV2 from 'api/PostV2'
import { Tracker } from 'api/Tracker'
import { Box } from 'components'
import { HandleSelectCard } from 'components/UI'
import { useDraftStore } from 'stores/DraftStore'
import { showSuccess, T } from 'utils'
import { getMixpanelPostDeletedDataSmall, TRACKEREVENTS } from 'utils/eventsTracker'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {}

export const DraftFooter = ({}: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */
  const { draftPosts, selectedPostsId } = useDraftStore((s) => ({
    draftPosts: s.draftPosts,
    selectedPostsId: s.selectedPostsId,
  }))

  const { setSelectedPostsId, forceUpdate } = useDraftStore()

  /* ----------------- VARIABLES  */

  const isAllSelected = selectedPostsId.length === draftPosts.length

  /* ----------------- METHODS  */
  /**
   * Funzione che gestisce il tracking di mixpanel per la eliminazione di un post
   */
  const handleMixPanel = () => {
    const isBulk = selectedPostsId.length > 1 ? true : false

    for (let i = 0; i < selectedPostsId.length; i++) {
      const postToDelete = draftPosts.find((post) => post._id === selectedPostsId[0])

      Tracker.trackEvent(TRACKEREVENTS.POST_DELETED, getMixpanelPostDeletedDataSmall(postToDelete!, isBulk))
    }
  }

  /**
   * Funzione che gestisce la selezione/deselezione di tutti i post
   */
  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedPostsId([])
    } else {
      setSelectedPostsId(draftPosts.map((post) => post._id))
    }
  }

  /* ----------------- API CALL  */
  const handleDelete = async () => {
    try {
      const res = await PostV2.deletePosts(selectedPostsId)

      handleMixPanel()

      setSelectedPostsId([])

      forceUpdate()
      showSuccess(T.success.eliminationDraftSuccess)
    } catch (e) {
      console.error(e)
    }
  }

  /* ----------------- USEEFFECT  */

  return (
    <HandleSelectCard isAllSelected={isAllSelected} handleSelectAll={handleSelectAll} handleDelete={handleDelete} />
  )
}
