import { api, responseErrorCheck } from 'api/api'
const Mixpanel = require('mixpanel')

interface ITrackingStrategy {
  init(): void
  setUser(userId: string, userData: object): void
  trackEvent(eventTitle: string, data: object): void
}

//// ------ Tracker ------
class TrackerClass {
  private static instance: TrackerClass
  private strategy: ITrackingStrategy

  private constructor(strategy: ITrackingStrategy) {
    this.strategy = strategy
    this.strategy.init()
  }

  public static getInstance(strategy: ITrackingStrategy): TrackerClass {
    if (!this.instance) this.instance = new TrackerClass(strategy)
    return this.instance
  }

  public setUser(userId: string, userData: object): void {
    try {
      this.strategy.setUser(userId, userData)
    } catch (e) {
      console.error(e)
    }
  }

  public trackEvent(eventTitle: string, data: object): void {
    try {
      this.strategy.trackEvent(eventTitle, data)
    } catch (e) {
      // console.error(e)
    }
  }
}

// ------ MixpanelSDKStrategy ------
class MixpanelSDKStrategy implements ITrackingStrategy {
  private mixpanelInstance: any
  private token = process.env.REACT_APP_MIXPANEL_TOKEN
  private options: any = {}

  init() {
    this.getMixpanelInstance()
  }

  getMixpanelInstance() {
    this.token && !this.mixpanelInstance && (this.mixpanelInstance = Mixpanel.init(this.token, this.options))
    return this.mixpanelInstance
  }

  normalizeData(data: object, options: any) {
    // @todo hp:
    // da aggiungere gestione di aggiunta parametri ricorrenti tramite options
    return data
  }

  setUser(userId: string, userData: object) {
    this.getMixpanelInstance().people.set(userId, userData)
  }

  trackEvent(eventTitle: string, data: object, options: any = undefined) {
    const normData = this.normalizeData(data, options)
    this.getMixpanelInstance().track(eventTitle, normData)
  }
}

// ------ MixpanelAPIStrategy ------
class MixpanelAPIStrategy implements ITrackingStrategy {
  private endpoint: String = '/tracker'

  init(): void {}

  async setUser(userId: string, userData: object) {
    try {
      await api
        .post<any>(`${this.endpoint}/setUser`, {
          User: userData,
        })
        .then(responseErrorCheck)
    } catch (e) {
      console.error(e)
    }
  }

  async trackEvent(eventTitle: string, data: object) {
    try {
      await api
        .post<any>(`${this.endpoint}/track`, {
          name: eventTitle,
          data: data,
        })
        .then(responseErrorCheck)
    } catch (e) {
      console.error(e)
    }
  }
}

// tracker : costante utilizzanda derivando il valore dalll'istanza di Tracker. E' stato creato un singleton (pattern)
// per creare un'istanza condivisa e riutilizzabile. All'interno viene passata la strategia di chiamata a mixpanel (pattern strategy).
// Se passato [MixpanelSDKStrategy], verrà usato mixpanel
// se passato [MixpanelAPIStrategy], verranno utilizzate le api asters.
export const Tracker = TrackerClass.getInstance(new MixpanelSDKStrategy())
