import styled from '@emotion/styled'
import { Box, Icons, Text } from 'components'
import { ToolTip } from 'pages/settings/Subscription/SubscriptionProvider'
import { AppStore } from 'utils'

/* ******************* INTERFACE ******************* */
interface Props {
  tooltip: ToolTip
}

export const AnalyticsTooltip = ({ tooltip }: Props) => {
  return (
    <AnalyticsTooltipContainer data-html2canvas-ignore='true'>
      <IconContainer>
        <Icons.info fill={AppStore.theme.o.black} width={24} />
      </IconContainer>

      <InfoContainer className={'info-container'}>
        <InfoDescription>{tooltip.text}</InfoDescription>
      </InfoContainer>
    </AnalyticsTooltipContainer>
  )
}

const AnalyticsTooltipContainer = styled(Box)`
  position: relative;

  &:hover {
    .info-container {
      display: block;
    }
  }
`

const IconContainer = styled(Box)`
  width: 24px;
  justify-content: center;
  align-items: center;
`

const InfoContainer = styled(Box)`
  display: none;
  position: absolute;
  top: 0;
  right: 32px;
  width: 388px;
  max-width: 388px;
  max-height: 370px;
  overflow-y: scroll;
  padding: 32px 40px;
  border-radius: 14px;
  background: ${() => AppStore.theme.o.surface};
  box-shadow: 0px 0px 20px 0px rgba(99, 99, 99, 0.12);
  z-index: 2;
`

const InfoDescription = styled(Text)`
  color: ${() => AppStore.theme.o.black};
  font-size: 12px;
  font-weight: 400;
  text-transform: none;
`
