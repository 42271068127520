import { AppStore } from 'utils'

const legend: Highcharts.LegendOptions = {
  borderRadius: 14,
  layout: 'horizontal',
  verticalAlign: 'bottom',
  align: 'center',
  itemStyle: {
    transition: 'all 0.3s ease-in-out',
    color: AppStore.theme.o.black,
  },
  itemHoverStyle: {
    color: AppStore.theme.o.green,
  },
}

const defaultOptions: Highcharts.Options = {
  chart: {
    height: 300,
    backgroundColor: 'transparent',
  },
  legend: legend,
  xAxis: {
    labels: {
      style: {
        color: AppStore.theme.o.black,
      },
    },
  },
  yAxis: {
    title: {
      text: '',
    },
    labels: {
      style: {
        color: AppStore.theme.o.black,
      },
    },
  },

  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
}

export const mergeWithDefaultOptions = (options: Highcharts.Options): Highcharts.Options => {
  return {
    ...defaultOptions,
    ...options,
    chart: {
      ...defaultOptions.chart,
      ...(options.chart || {}),
    },
    legend: {
      ...defaultOptions.legend,
      ...(options.legend || {}),
    },
    xAxis: {
      ...defaultOptions.xAxis,
      ...(options.xAxis || {}),
    },
    yAxis: {
      ...defaultOptions.yAxis,
      ...(options.yAxis || {}),
    },
  }
}
