/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { ComingSoon } from 'components/UI/ComingSoon'
import { AppStore } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  active: boolean
  name: string
  onClick: () => void
  comingSoon: boolean
}

export const SideBarBtn = ({ active, name, onClick, comingSoon }: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */
  const handleOnClick = () => {
    !comingSoon && onClick()
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <SideBarBtnContainer onClick={handleOnClick} active={active} comingSoon={comingSoon}>
      {name}
      {comingSoon && (
        <ContainerComingSoon>
          <ComingSoon />
        </ContainerComingSoon>
      )}
    </SideBarBtnContainer>
  )
}

const SideBarBtnContainer = styled(Box)<{ active: boolean; comingSoon: boolean }>`
  position: relative;
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  text-align: center;
  font-family: 'Aktiv Grotesk';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: 1px solid transparent;
  cursor: ${({ comingSoon }) => (comingSoon ? 'not-allowed' : 'pointer')};

  color: ${({ active }) => (active ? () => AppStore.theme.o.on_primary_container : () => AppStore.theme.o.grey)};
  background: ${({ active }) => (active ? () => AppStore.theme.o.primary_container : 'transparent')};
  border-color: ${({ active }) => (active ? () => AppStore.theme.o.on_primary_container : 'transparent')};

  &:hover {
    background: ${({ active }) => active && AppStore.theme.o.primary_container};
    border-color: ${({ active }) => active && AppStore.theme.o.primary_container};
  }
`

const ContainerComingSoon = styled(Box)`
  position: absolute;
  top: 0%;
  right: -25%;
  transform: translateY(-70%);
`
