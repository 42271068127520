import { enUS, itIT } from '@mui/x-date-pickers/locales'
import 'dayjs/locale/en'
import 'dayjs/locale/it'

import { AppStore } from 'utils'

const currentLang = AppStore.getLanguageValue()

export const localLang =
  currentLang === 'it'
    ? itIT.components.MuiLocalizationProvider.defaultProps.localeText
    : enUS.components.MuiLocalizationProvider.defaultProps.localeText

export const localDate = currentLang === 'it' ? 'it' : 'en'
