/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { useEffect, useState } from 'react'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { AppStore } from 'utils'
import { getActiveText } from 'utils/PostUtils/Post'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {}

export const ReadingTime = ({}: Props) => {
  /* ----------------- STATE  */
  const [readingTime, setReadingTime] = useState('')

  /* ----------------- METHODS  */
  const showMinutes = (description) => {
    const numberOfWords = description.replace(/\s+/g, ' ').trim().split(' ').length
    if (description.length > 0) {
      if (numberOfWords === 0) {
        setReadingTime(`0 min 01 sec`)
        return
      }
      const secondsToRead = Math.ceil(numberOfWords / 3.333)
      const minutes = Math.floor(secondsToRead / 60)
      const seconds = secondsToRead % 60
      setReadingTime(`${minutes} min ${seconds >= 10 ? seconds : `0${seconds}`} sec`)
    } else setReadingTime('')
  }

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    showMinutes(getActiveText())
  }, [getActiveText()?.length])

  return (
    <ReadingTimeContainer>
      <Icons.readingTime fill={AppStore.theme.o.black} />
      {readingTime}
    </ReadingTimeContainer>
  )
}

const ReadingTimeContainer = styled(Box)`
  font-size: 12px;
  font-weight: 700;
  color: ${() => AppStore.theme.o.black};
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`
