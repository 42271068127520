import Highcharts from 'highcharts'
import HighchartsHeatMap from 'highcharts/modules/heatmap'
import HighchartsMaps from 'highcharts/modules/map'
import HighchartsMore from 'highcharts/highcharts-more'

HighchartsHeatMap(Highcharts)
HighchartsMaps(Highcharts)
HighchartsMore(Highcharts)

export const HighchartsAsters = Highcharts

// Incorpora la custom animation direttamente nel tuo codice
// const customPieEntranceAnimation = function (H) {
//   H.seriesTypes.pie.prototype.animate = function (init) {
//     const series = this as unknown as Highcharts.Series,
//       chart = series.chart,
//       points = series.points,
//       //@ts-ignore
//       { animation } = series.options,
//       //@ts-ignore
//       { startAngleRad } = series

//     function fanAnimate(point, startAngleRadFunc) {
//       const graphic = point.graphic,
//         args = point.shapeArgs

//       if (graphic && args) {
//         graphic
//           // Set inital animation values
//           .attr({
//             start: startAngleRadFunc,
//             end: startAngleRadFunc,
//             opacity: 1,
//           })
//           // Animate to the final position
//           .animate(
//             {
//               start: args.start,
//               end: args.end,
//             },
//             {
//               duration: animation.duration / points.length,
//             },
//             function () {
//               // On complete, start animating the next point
//               if (points[point.index + 1]) {
//                 fanAnimate(points[point.index + 1], args.end)
//               }
//               // On the last point, fade in the data labels, then
//               // apply the inner size
//               if (point.index === series.points.length - 1) {
//                 //@ts-ignore
//                 series.dataLabelsGroup.animate(
//                   {
//                     opacity: 1,
//                   },
//                   void 0,
//                   function () {
//                     points.forEach((p) => {
//                       p.opacity = 1
//                     })
//                     series.update(
//                       //@ts-ignore
//                       {
//                         enableMouseTracking: true,
//                       },
//                       false
//                     )
//                     chart.update({
//                       plotOptions: {
//                         pie: {
//                           innerSize: '40%',
//                           borderRadius: 8,
//                         },
//                       },
//                     })
//                   }
//                 )
//               }
//             }
//           )
//       }
//     }

//     if (init) {
//       // Hide points on init
//       points.forEach((point) => {
//         point.opacity = 0
//       })
//     } else {
//       fanAnimate(points[0], startAngleRad)
//     }
//   }
// }

// Aggiungi la custom animation alle opzioni del grafico
// customPieEntranceAnimation(HighchartsAsters)
