import styled from '@emotion/styled'
import Workspaces from 'api/Workspaces'
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { Spacer } from 'components/Spacer'
import { useState } from 'react'
import { T } from 'translation/i18n'
import { AppStore, showSuccess } from 'utils'
import { updateItem } from '../../../../utils/PostUtils/TemplatePost'

/* ******************* INTERFACE ******************* */
interface Props {
  item: any
  closeEdit: () => void
}

export const EditComponent = ({ item, closeEdit }: Props) => {
  const [changedName, setChangedName] = useState('')

  const onClick = () => {
    if (changedName === '') return
    const newItem = { ...item, name: changedName }
    updateItem(item, newItem)
    Workspaces.modifyName(AppStore.workspace._id ?? '', item._id, { text: changedName })
    showSuccess(T.success.nameChanged)
    closeEdit()
  }

  return (
    <>
      <Box row style={{ maxHeight: 79 }} flex>
        <InputText style={{ minWidth: '19.5em', maxWidth: 300 }} onChange={(e) => setChangedName(e)} />

        <Box row ml={30}>
          <Button width={120} size="small" variant="secondary-small" onClick={() => onClick()}>
            {T.postPage.update}
          </Button>
          <Spacer />
          <Button width={120} size="small" variant="delete-small" onClick={() => closeEdit()}>
            {T.postPage.cancel}
          </Button>
        </Box>
      </Box>
    </>
  )
}

const InputText = styled(Input)`
  border: none !important;
  max-height: 90px;
  min-width: max-content;
`
