/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { useEffect, useState } from 'react'
import { useAnalyticsStore } from 'stores/AnalyticsStore'
import { ISelectableSocial } from 'types/AnalyticsInferfaces'
import { AppStore } from 'utils'
import { CheckboxMetricElement } from './CheckboxMetricElement'

/* ------------------- INTERFACES  */
interface Props {
  accountIdList: string[]
}

export const CheckboxMetricContainer = ({ accountIdList }: Props) => {
  /* ------------------- ZUSTAND  */
  const { setSelectedAccount } = useAnalyticsStore.getState()

  /* ----------------- STATE  */
  const [accountSelected, setAccountSelected] = useState<ISelectableSocial>(
    useAnalyticsStore.getState().selectedAccount
  )
  const [socialAccounts, setSocialAccounts] = useState<ISelectableSocial[]>([])

  /* ------------------- ZUSTAND SUBSCRIPTION  */
  const unsubSocialSelected = useAnalyticsStore.subscribe((state) => {
    state.selectedAccount !== accountSelected && setAccountSelected(state.selectedAccount)
  })

  /* ------------------- USEEFFECT */

  useEffect(() => unsubSocialSelected(), [])

  useEffect(() => {
    if (accountIdList.length === 0) {
      setSocialAccounts([])
    } else if (accountIdList.length > 0 && socialAccounts.length > 0) {
      return
    } else {
      setSocialAccounts(
        AppStore.socialAccounts
          .filter((account) => accountIdList.includes(account._id!))
          .map((account) => ({
            _id: account._id!,
            picture: account.picture!,
            provider: account.provider!,
          }))
      )
    }
  }, [accountIdList])

  useEffect(() => {
    if (socialAccounts.length === 0) return
    setSelectedAccount({
      _id: socialAccounts[0]._id,
      picture: socialAccounts[0].picture,
      provider: socialAccounts[0].provider,
    })
  }, [socialAccounts])

  return (
    <CheckboxMetricContainerContainer className="checkbox_metric_container">
      {socialAccounts.map((account) => (
        <CheckboxMetricElement account={account} accountSelected={accountSelected} />
      ))}
    </CheckboxMetricContainerContainer>
  )
}

const CheckboxMetricContainerContainer = styled(Box)`
  &.checkbox_metric_container {
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
`
