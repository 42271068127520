import { Box } from 'components'
import styled from '@emotion/styled'
import { AppStore } from 'utils'

export interface Props {
  options: { onClick: () => void; text: string }[]
  active: number
}

/**This is main Button!!*/
export const LoginTabs = ({ active = 0, options, ...props }: Props) => {
  return (
    <ContainerButtonSelection row>
      {options.map((option, index) => (
        <ButtonSelection
          first={index === 0}
          last={index + 1 === options.length}
          flex
          selected={index === active}
          center
          key={index}
          onClick={option.onClick}
          hideBoxShadowOnHover
        >
          {option.text}
        </ButtonSelection>
      ))}
    </ContainerButtonSelection>
  )
}

const ContainerButtonSelection = styled(Box)`
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
`

const ButtonSelection = styled(Box)<{ selected: boolean; first: boolean; last: boolean }>`
  background-color: ${({ selected }) => (selected ? AppStore.theme.o.surface : AppStore.theme.o.grey)};
  border-radius: 0px;
  border-top-left-radius: ${({ first }) => (first ? 14 : 0)}px;
  border-top-right-radius: ${({ last }) => (last ? 14 : 0)}px;
  height: 40px;
  font-weight: 700;
  font-size: 18px;
  color: ${({ selected }) => (selected ? AppStore.theme.o.black : AppStore.theme.o.surface)};
  &:hover {
    background-color: ${() => AppStore.theme.o.lightGrey};
    color: ${() => AppStore.theme.o.black};
  }
`
