import styled from '@emotion/styled'
import { AppStore, T } from 'utils'
import { Box } from './Box'
import { Button } from './Button'
import { Icons } from './Icons'
import { UpgradeModal } from './Modal/Billing/UpgradeModal'

interface Props {}
export const requestUpgradeModal = () => {
  window.history.pushState({}, document.title, window.location.pathname + window.location.search + '#upgrade')
  return AppStore.openModal({
    id: 'upgrade-modal',
    body: (
      <Box style={{ flex: 1, overflow: 'hidden', alignItems: 'center', justifyContent: 'center', zIndex: 9999 }}>
        <UpgradeModal />
      </Box>
    ),
    onCancel: () => {
      window.history.pushState({}, document.title, window.location.pathname + window.location.search)
    },
    style: {
      minWidth: 1400,
      zIndex: 99999999999999,
    },
  })
}

export const HeaderUpgradeButton = ({}: Props) => {
  return (
    <StyledButton onClick={requestUpgradeModal} size="smaller" style={{ width: 189, height: 40, fontWeight: 500 }}>
      <Icons.arrowUp fill={AppStore.theme.o.on_primary_container} style={{ marginRight: 6 }} /> {T.header.upgrade}
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  background-color: ${() => AppStore.theme.o.primary_container};
  border: 1px solid transparent;
  color: ${() => AppStore.theme.o.on_primary_container};

  &:hover {
    border-color: ${() => AppStore.theme.o.on_primary_container};
  }
`
