/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { PreviewResult } from '../../GenericComponents/PreviewResults'
import { TextContainer } from '../../GenericComponents/TextContainer'
import { ResultsPreviewShort } from './ResultsPreviewShort'

/* ------------------- INTERFACES  */
interface Props {}

export const YoutubeShort = ({}: Props) => {
  return (
    <YoutubeShortContainer>
      <PreviewResult component={<ResultsPreviewShort />} />
      <TextContainer />
    </YoutubeShortContainer>
  )
}

const YoutubeShortContainer = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
`
