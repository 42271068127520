/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Button, Icons } from 'components'
import { useState } from 'react'
import { useCalendarStore } from 'stores/CalendarStore'
import { PostStatus } from 'types'
import { IPostVariant } from 'types/PostInterface'
import { AppStore, T, showSuccess } from 'utils'
import { publishSinglePost } from 'utils/PostUtils/Post'
import { DatePostStatus } from './DatePostStatus'

/* ----------------- ENUM  */
export const enumStatusColor = {
  published: {
    background: AppStore.theme.o.secondary_container,
    color: AppStore.theme.o.on_secondary_container,
  },
  error: {
    background: AppStore.theme.o.error_container,
    color: AppStore.theme.o.on_error_container,
  },
  planned: {
    background: AppStore.theme.o.lightestGrey,
    color: AppStore.theme.o.black,
  },
  draft: {
    background: AppStore.theme.o.lightestGrey,
    color: AppStore.theme.o.darkGrey,
  },
  publishing: {
    background: AppStore.theme.o.warning_container,
    color: AppStore.theme.o.warning,
  },
}

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */
interface IStatusOption {
  status: PostStatus
  name: string
  action: () => void
  color: string
}

interface Props {
  status: PostStatus
  date: Date
  post: IPostVariant
}

export const PostStatusInfo = ({ status, date, post }: Props) => {
  /* ----------------- STATE  */
  const [showDropdown, setShowDropdown] = useState<boolean>(false)
  const [hover, setHover] = useState<boolean>(false)

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */
  //? Aggiungere gli altri stati appena pronti
  const canBeClicked = status === 'planned' || status === 'draft'

  /* ----------------- METHODS  */
  const handleClickOpenDropdown = () => {
    if (!canBeClicked) return
    setShowDropdown((prev) => !prev)
  }

  //? Da vedere in futuro quando si modificheranno gli status
  // const optionSwitchStatus = (actualStatus: PostStatus) => {
  //   if (actualStatus === 'planned') {
  //     return ['draft', 'tail']
  //   }

  //   if (actualStatus === 'draft') {
  //     return ['planned', 'tail']
  //   }

  //   if (actualStatus === 'tail') {
  //     return ['planned', 'draft']
  //   }
  // }

  const optionSwitchStatus = (actualStatus: PostStatus): IStatusOption[] => {
    switch (actualStatus) {
      case 'planned':
        return [
          {
            status: 'draft',
            name: T.status.draft,
            action: () => handleClickChangeStatus('draft'),
            color: AppStore.theme.o.darkGrey,
          },
        ]
      case 'draft':
        return [
          {
            status: 'planned',
            name: T.status.planned,
            action: () => handleClickChangeStatus('planned'),
            color: AppStore.theme.o.black,
          },
        ]
    }
    return []
  }

  const handleBackgroundColor = () => {
    if (canBeClicked) {
      if (hover) {
        return AppStore.theme.o.lightGrey
      }
    }

    return enumStatusColor[status].background
  }

  const handleClickPublishPost = () => {
    const newPost = JSON.parse(JSON.stringify(post))
    // Resetta la date e status per poter pubblicare il post immediatamente
    newPost.data.date = undefined
    newPost.data.status = undefined

    publishSinglePost(newPost, newPost.data.status)
    AppStore.closeAllModal()
    showSuccess(T.success.postGenerated)
    useCalendarStore.getState().forceUpdate()
  }

  const handleClickChangeStatus = (newStatus: PostStatus) => {
    const newPost = JSON.parse(JSON.stringify(post))

    // Cambia lo status di un post
    newPost.data.status = newStatus

    publishSinglePost(newPost, newPost.data.status)
    AppStore.closeAllModal()
    useCalendarStore.getState().forceUpdate()
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <PostStatusInfoContainer
      onClick={handleClickOpenDropdown}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        background: handleBackgroundColor(),
        cursor: canBeClicked ? 'pointer' : 'default',
      }}
    >
      <PostInfo>
        <StatusPost
          style={{
            color: enumStatusColor[status].color,
          }}
        >
          {T.status[status]}
        </StatusPost>

        <Box row center gap={24}>
          <DatePostStatus date={date} status={status} />

          {canBeClicked && (
            <IconDropdown
              style={{
                rotate: showDropdown ? '0deg' : '-180deg',
              }}
            >
              <Icons.dropdownArrow fill={AppStore.theme.o.black} />
            </IconDropdown>
          )}
        </Box>
      </PostInfo>

      {showDropdown && (
        <DropdownContainer>
          {optionSwitchStatus(status)?.map((item, i) => (
            <SwitchOption
              onClick={item.action}
              key={item.name + i}
              style={{
                color: item.color,
              }}
            >
              {item.name}

              <DatePostStatus date={date} status={item.status} margin={'0px 44px 0px 0px'} />
            </SwitchOption>
          ))}

          <Spacer />

          <ButtonPublish variant="secondary" onClick={handleClickPublishPost}>
            {T.postPage.publicNowPost}
          </ButtonPublish>
        </DropdownContainer>
      )}
    </PostStatusInfoContainer>
  )
}

const PostStatusInfoContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 14px;
  position: relative;
  width: 422px;
  max-width: 442px;
`

const PostInfo = styled(Box)`
  display: flex;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 14px;
  flex: 1;
  padding: 8px 16px;
`

const StatusPost = styled(Box)`
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.16px;
`

const IconDropdown = styled(Box)``

const DropdownContainer = styled(Box)`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 100%;
  background-color: ${() => AppStore.theme.o.lightestGrey};
  padding: 4px;
  box-shadow: ${() => AppStore.theme.smallOuterShadow};
`

const Spacer = styled(Box)`
  width: 100%;
  height: 2px;
  margin: 8px 0;
  background: ${() => AppStore.theme.o.lightGrey};
`

const ButtonPublish = styled(Button)`
  height: 40px !important;
  min-height: 40px !important;
  padding: 8px 48px !important;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
`

const SwitchOption = styled(Box)`
  height: 38px;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:hover {
    background-color: ${() => AppStore.theme.o.lightGrey};
  }
`
