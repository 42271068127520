import { TPostType } from 'api/Reports'
import { ILabel } from 'components/CreatePost/PreviewComponents/LabelsComponents/LabelsContentComponents/Label'
import { T } from 'utils'

export interface TikTokPosts extends TablePost {
  title: string
  views: number
  likes: number
  comments: number
  shares: number
  addToFavorites?: number
  reach: number
  video_duration: number
  audience_countries: string[] // ITKAudienceCountries[]
  total_time_watched: number
  full_video_watched_rate: number
  impression_sources: string[] //ITKImpressionSource[]
  average_time_watched: number
}

export interface LinkedInPosts extends TablePost {
  reach: number
  shares: number
  engagement: number
  clicks: number
  likes: number
  impressions: number
  comments: number
}

export interface InstagramPosts extends TablePost {
  likes: number
  comments: number
  reach: number
  saved: number
  content_type: string
  interactions_rate: number
  media_type: string
  plays: number
}

export interface FacebookPosts extends TablePost {
  comments: number
  likes: number
  shares: number
  post_video_views_organic: number
  post_impressions: number
  post_impressions_unique: number
  post_reactions_by_type_total: number
  post_video_views_clicked_to_play: number
  post_link_click: number
  interaction_rate: number
  interaction_rate_with_link_clicks: number
}

export interface TablePost {
  _id?: string
  postId: string
  account_id: string
  provider: string
  message?: string
  permalinkUrl: string
  thumbnailUrl?: string
  accountName: string
  imageUrl?: string
  createdAt: Date
  updatedAt?: Date
  astersCreatedAt?: Date
  astersUpdateAt?: Date
  labels?: ILabel[]
  fanbase_at_pubblication_time?: number
  media_type: string
}

export type TIDColumn =
  | 'date' // Che sarebbe createdAt

  //TIKTOK
  | 'title'
  | 'views'
  | 'likes'
  | 'comments'
  | 'shares'
  | 'addToFavorites'
  | 'reach'
  | 'video_duration'
  | 'audience_countries'
  | 'total_time_watched'
  | 'full_video_watched_rate'
  | 'impression_sources'
  | 'average_time_watched'

  //LINKEDIN
  | 'reach'
  | 'shares'
  | 'engagement'
  | 'clicks'
  | 'likes'
  | 'impressions'
  | 'comments'

  //INSTAGRAM
  | 'likes'
  | 'comments'
  | 'reach'
  | 'saved'
  | 'content_type'
  | 'interactions_rate'
  | 'media_type'
  | 'plays'

  //FACEBOOK
  | 'comments'
  | 'likes'
  | 'shares'
  | 'post_video_views_organic'
  | 'post_impressions'
  | 'post_impressions_unique'
  | 'post_reactions_by_type_total'
  | 'post_video_views_clicked_to_play'
  | 'post_link_click'
  | 'interaction_rate'
  | 'interaction_rate_with_link_clicks'

export type ITableGraphSocials = 'instagram' | 'facebook' | 'tiktok' | 'linkedin'

export type TTypeDataTable = 'temporal' | 'numeric' | '%' | 'date'

export interface IColumn {
  id: TIDColumn
  field: string
  headerName: string
  width: number
  type: TTypeDataTable
}

export interface IConfigColumns {
  social: ITableGraphSocials
  columns: IColumn[]
}

//! ORDINAMENTO
// FB:
// Likes
// Comments
// Shares
// Impressions
// Reach
// Link clicks
// Interactions rate ^
// Interactions rate with link clicks
// Video views
// Video views clicked to play

// IG:
// Likes
// Comments
// Saved
// Reach
// Interactions rate ^
// Plays

// LINKEDIN:
// Likes
// Comments
// Shares
// Impressions
// Reach
// Engagement rate ^
// Clicks

// TIKTOK:
// Likes
// Comments
// Shares
// Add to favorites
// Views
// Reach
// Total time watched ^^
// Full video watched rate ^
// Average time watched ^^

// ^ il valore del dato è in forma percentuale (es. 17%)
// ^^ il valore del dato è in forma di secondi e minuti (es. 17s o 1.17m)

export const configColumns: IConfigColumns[] = [
  {
    social: 'instagram',
    columns: [
      {
        id: 'date',
        field: 'col1',
        headerName: T.tableGraphCol.date,
        width: 100,
        type: 'date',
      },
      {
        id: 'likes',
        field: 'col2',
        headerName: T.tableGraphCol.likes,
        width: 156,
        type: 'numeric',
      },
      {
        id: 'comments',
        field: 'col3',
        headerName: T.tableGraphCol.comments,
        width: 156,
        type: 'numeric',
      },
      {
        id: 'saved',
        field: 'col4',
        headerName: T.tableGraphCol.saved,
        width: 156,
        type: 'numeric',
      },
      {
        id: 'reach',
        field: 'col5',
        headerName: T.tableGraphCol.reach,
        width: 156,
        type: 'numeric',
      },
      {
        id: 'interactions_rate',
        field: 'col6',
        headerName: T.tableGraphCol.interactions_rate,
        width: 156,
        type: '%',
      },
      {
        id: 'plays',
        field: 'col7',
        headerName: T.tableGraphCol.plays,
        width: 156,
        type: 'numeric',
      },
    ],
  },
  {
    social: 'facebook',
    columns: [
      {
        id: 'date',
        field: 'col1',
        headerName: T.tableGraphCol.date,
        width: 100,
        type: 'date',
      },
      {
        id: 'likes',
        field: 'col2',
        headerName: T.tableGraphCol.likes,
        width: 80,
        type: 'numeric',
      },
      {
        id: 'comments',
        field: 'col3',
        headerName: T.tableGraphCol.comments,
        width: 85,
        type: 'numeric',
      },
      {
        id: 'shares',
        field: 'col4',
        headerName: T.tableGraphCol.shares,
        width: 75,
        type: 'numeric',
      },
      {
        id: 'post_impressions',
        field: 'col5',
        headerName: T.tableGraphCol.impressions,
        width: 90,
        type: 'numeric',
      },
      {
        id: 'post_impressions_unique',
        field: 'col6',
        headerName: T.tableGraphCol.reach,
        width: 75,
        type: 'numeric',
      },
      {
        id: 'post_link_click',
        field: 'col7',
        headerName: T.tableGraphCol.link_clicks,
        width: 80,
        type: 'numeric',
      },
      {
        id: 'interaction_rate',
        field: 'col8',
        headerName: T.tableGraphCol.interactions_rate,
        width: 97,
        type: '%',
      },
      {
        id: 'interaction_rate_with_link_clicks',
        field: 'col9',
        headerName: T.tableGraphCol.interactions_rate_with_link_clicks,
        width: 122,
        type: 'numeric',
      },
      {
        id: 'post_video_views_organic',
        field: 'col10',
        headerName: T.tableGraphCol.video_views,
        width: 102,
        type: 'numeric',
      },
      {
        id: 'post_video_views_clicked_to_play',
        field: 'col11',
        headerName: T.tableGraphCol.videos_views_clicked_to_play,
        width: 117,
        type: 'numeric',
      },
    ],
  },
  {
    social: 'tiktok',
    columns: [
      {
        id: 'date',
        field: 'col1',
        headerName: T.tableGraphCol.date,
        width: 100,
        type: 'date',
      },
      {
        id: 'likes',
        field: 'col2',
        headerName: T.tableGraphCol.likes,
        width: 85,
        type: 'numeric',
      },
      {
        id: 'comments',
        field: 'col3',
        headerName: T.tableGraphCol.comments,
        width: 90,
        type: 'numeric',
      },
      {
        id: 'shares',
        field: 'col4',
        headerName: T.tableGraphCol.shares,
        width: 90,
        type: 'numeric',
      },
      {
        id: 'addToFavorites',
        field: 'col5',
        headerName: T.tableGraphCol.add_to_favorites,
        width: 110,
        type: 'numeric',
      },
      {
        id: 'views',
        field: 'col6',
        headerName: T.tableGraphCol.views,
        width: 108,
        type: 'numeric',
      },
      {
        id: 'reach',
        field: 'col7',
        headerName: T.tableGraphCol.reach,
        width: 109,
        type: 'numeric',
      },
      {
        id: 'total_time_watched',
        field: 'col8',
        headerName: T.tableGraphCol.total_time_watched,
        width: 109,
        type: 'temporal',
      },
      {
        id: 'full_video_watched_rate',
        field: 'col9',
        headerName: T.tableGraphCol.full_video_watched_rate,
        width: 109,
        type: '%',
      },
      {
        id: 'average_time_watched',
        field: 'col10',
        headerName: T.tableGraphCol.average_time_watched,
        width: 109,
        type: 'temporal',
      },
    ],
  },
  {
    social: 'linkedin',
    columns: [
      {
        id: 'date',
        field: 'col1',
        headerName: T.tableGraphCol.date,
        width: 100,
        type: 'date',
      },
      {
        id: 'likes',
        field: 'col2',
        headerName: T.tableGraphCol.likes,
        width: 124,
        type: 'numeric',
      },
      {
        id: 'comments',
        field: 'col3',
        headerName: T.tableGraphCol.comments,
        width: 124,
        type: 'numeric',
      },
      {
        id: 'shares',
        field: 'col4',
        headerName: T.tableGraphCol.shares,
        width: 124,
        type: 'numeric',
      },
      {
        id: 'impressions',
        field: 'col5',
        headerName: T.tableGraphCol.impressions,
        width: 124,
        type: 'numeric',
      },
      {
        id: 'reach',
        field: 'col6',
        headerName: T.tableGraphCol.reach,
        width: 124,
        type: 'numeric',
      },
      {
        id: 'engagement',
        field: 'col7',
        headerName: T.tableGraphCol.engagement,
        width: 156,
        type: '%',
      },
      {
        id: 'clicks',
        field: 'col8',
        headerName: T.tableGraphCol.clicks,
        width: 156,
        type: 'numeric',
      },
    ],
  },
]

export const configTypology: {
  social: string
  typologies: { id: TPostType; label: string }[]
}[] = [
  {
    social: 'instagram',
    typologies: [
      {
        id: 'carousel',
        label: T.singleWord.carousel,
      },
      {
        id: 'reels',
        label: 'Reel',
      },
      {
        id: 'story',
        label: T.singleWord.story,
      },
      {
        id: 'post_link',
        label: 'Post link',
      },
    ],
  },
  {
    social: 'facebook',
    typologies: [
      {
        id: 'post',
        label: 'Post',
      },
      {
        id: 'carousel',
        label: T.singleWord.carousel,
      },

      {
        id: 'post_link',
        label: 'Post link',
      },
    ],
  },
  {
    social: 'tiktok',
    typologies: [
      {
        id: 'post',
        label: 'Post',
      },
      {
        id: 'post_link',
        label: 'Post link',
      },
    ],
  },
  {
    social: 'linkedin',
    typologies: [
      // {
      //   id: 'post',
      //   label: 'Post',
      // },
      // {
      //   id: 'carousel',
      //   label: T.singleWord.carousel,
      // },
      // {
      //   id: 'post_link',
      //   label: 'Post link',
      // },
    ],
  },
]

export const mockPostsInstagram = [
  {
    _id: '1',
    postId: '1',
    account_id: '1',
    provider: 'instagram',
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    permalinkUrl: 'https://placehold.co/600x400',
    thumbnailUrl: 'https://placehold.co/600x400',
    accountName: 'instagram',
    imageUrl: 'https://placehold.co/600x400',
    createdAt: new Date(),
    updatedAt: new Date(),
    astersCreatedAt: new Date(),
    astersUpdateAt: new Date(),
    labels: [],
    fanbase_at_pubblication_time: 100,
    media_type: 'image',

    title: 'title',
    views: 100,
    likes: 100,
    comments: 100,
    shares: 100,
    reach: 100,
    saved: 100,
    content_type: 'content_type',
    interactions_rate: 100,
    plays: 100,
  },
]

export const mockPostsFacebook = [
  {
    _id: '1',
    postId: '1',
    account_id: '1',
    provider: 'facebook',
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    permalinkUrl: 'https://placehold.co/600x400',
    thumbnailUrl: 'https://placehold.co/600x400',
    accountName: 'facebook',
    imageUrl: 'https://placehold.co/600x400',
    createdAt: new Date(),
    updatedAt: new Date(),
    astersCreatedAt: new Date(),
    astersUpdateAt: new Date(),
    labels: [],
    fanbase_at_pubblication_time: 100,
    media_type: 'image',

    comments: 100,
    likes: 100,
    shares: 100,
    post_video_views_organic: 100,
    post_impressions: 100,
    post_impressions_unique: 100,
    post_reactions_by_type_total: 100,
    post_video_views_clicked_to_play: 100,
    post_link_click: 100,
    interaction_rate: 100,
    interaction_rate_with_link_clicks: 100,
  },
]

export const mockPostsLinkedIn = [
  {
    _id: '1',
    postId: '1',
    account_id: '1',
    provider: 'linkedin',
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    permalinkUrl: 'https://placehold.co/600x400',
    thumbnailUrl: 'https://placehold.co/600x400',
    accountName: 'linkedin',
    imageUrl: 'https://placehold.co/600x400',
    createdAt: new Date(),
    updatedAt: new Date(),
    astersCreatedAt: new Date(),
    astersUpdateAt: new Date(),
    labels: [],
    fanbase_at_pubblication_time: 100,
    media_type: 'image',

    reach: 100,
    shares: 100,
    engagement: 100,
    clicks: 100,
    likes: 100,
    impressions: 100,
    comments: 100,
  },
]

export const mockPostsTiktok = [
  {
    _id: '1',
    postId: '1',
    account_id: '1',
    provider: 'tiktok',
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    permalinkUrl: 'https://placehold.co/600x400',
    thumbnailUrl: 'https://placehold.co/600x400',
    accountName: 'tiktok',
    imageUrl: 'https://placehold.co/600x400',
    createdAt: new Date(),
    updatedAt: new Date(),
    astersCreatedAt: new Date(),
    astersUpdateAt: new Date(),
    labels: [],
    fanbase_at_pubblication_time: 100,
    media_type: 'image',

    title: 'title',
    views: 100,
    likes: 1200000,
    comments: 2129,
    shares: 100,
    reach: 100,
    video_duration: 1002,
    audience_countries: ['IT'],
    total_time_watched: 100,
    full_video_watched_rate: 100,
    impression_sources: ['IT'],
    average_time_watched: 100,
    addToFavorites: 100,
  },

  {
    _id: '2',
    postId: '2',
    account_id: '2',
    provider: 'tiktok',
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    permalinkUrl: 'https://placehold.co/600x400',
    thumbnailUrl: 'https://placehold.co/600x400',
    accountName: 'tiktok',
    imageUrl: 'https://placehold.co/600x400',
    createdAt: new Date(),
    updatedAt: new Date(),
    astersCreatedAt: new Date(),
    astersUpdateAt: new Date(),
    labels: [],
    fanbase_at_pubblication_time: 100,
    media_type: 'image',

    title: 'title',
    views: 200,
    likes: 200,
    comments: 200,
    shares: 200,
    reach: 200,
    video_duration: 200,
    audience_countries: ['IT'],
    total_time_watched: 200,
    full_video_watched_rate: 200,
    impression_sources: ['IT'],
    average_time_watched: 200,
    addToFavorites: 200,
  },

  // {
  //   _id: '2',
  //   postId: '2',
  //   account_id: '2',
  //   provider: 'linkedin',
  //   message:
  //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  //   permalinkUrl: 'https://placehold.co/600x400',
  //   thumbnailUrl: 'https://placehold.co/600x400',
  //   accountName: 'linkedin',
  //   imageUrl: 'https://placehold.co/600x400',
  //   createdAt: new Date(),
  //   updatedAt: new Date(),
  //   astersCreatedAt: new Date(),
  //   astersUpdateAt: new Date(),
  //   labels: [],
  //   fanbase_at_pubblication_time: 100,
  //   media_type: 'image',

  //   reach: 100,
  //   shares: 100,
  //   engagement: 100,
  //   clicks: 100,
  //   likes: 100,
  //   impressions: 100,
  //   comments: 100,
  // },

  // {
  //   _id: '3',
  //   postId: '3',
  //   account_id: '3',
  //   provider: 'instagram',
  //   message:
  //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  //   permalinkUrl: 'https://placehold.co/600x400',
  //   thumbnailUrl: 'https://placehold.co/600x400',
  //   accountName: 'instagram',
  //   imageUrl: 'https://placehold.co/600x400',
  //   createdAt: new Date(),
  //   updatedAt: new Date(),
  //   astersCreatedAt: new Date(),
  //   astersUpdateAt: new Date(),
  //   labels: [],
  //   fanbase_at_pubblication_time: 100,
  //   media_type: 'image',

  //   likes: 100,
  //   comments: 100,
  //   reach: 100,
  //   saved: 100,
  //   content_type: 'content_type',
  //   interactions_rate: 100,
  //   plays: 100,
  // },

  // {
  //   _id: '4',
  //   postId: '4',
  //   account_id: '4',
  //   provider: 'facebook',
  //   message:
  //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  //   permalinkUrl: 'https://placehold.co/600x400',
  //   thumbnailUrl: 'https://placehold.co/600x400',
  //   accountName: 'facebook',
  //   imageUrl: 'https://placehold.co/600x400',
  //   createdAt: new Date(),
  //   updatedAt: new Date(),
  //   astersCreatedAt: new Date(),
  //   astersUpdateAt: new Date(),
  //   labels: [],
  //   fanbase_at_pubblication_time: 100,
  //   media_type: 'image',

  //   comments: 100,
  //   likes: 100,
  //   shares: 100,
  //   post_video_views_organic: 100,
  //   post_impressions: 100,
  //   post_impressions_unique: 100,
  //   post_reactions_by_type_total: 100,
  //   post_video_views_clicked_to_play: 100,
  //   post_link_click: 100,
  //   interaction_rate: 100,
  //   interaction_rate_with_link_clicks: 100,
  // },
]
