/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import Socialaccounts from 'api/Socialaccounts'
import { Box, Icons, Text } from 'components'
import { useState } from 'react'
import { ICollaborator, IPostVariant } from 'types/PostInterface'
import { AppStore, showInfo, T } from 'utils'
import { updatePost } from 'utils/PostUtils/Post'
import { ExtraTitle } from '../../GenericExtras/ExtraTitle'
import { InfoExtra } from '../../GenericExtras/InfoExtra'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  post: IPostVariant
}

export const Collaboration = ({ post }: Props) => {
  /* ----------------- STATE  */
  const [inputValue, setInputValue] = useState<string>('')
  const [inputFocus, setInputFocus] = useState<boolean>(false)

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */
  const collaborators = post.data.collaborators ?? []

  /* ----------------- METHODS  */
  /**
   *  Aggiunge un collaborator al post
   * @param collaborator ICollaborator
   */
  const handleAddCollaborator = async () => {
    if (collaborators.some((collab) => collab.name === inputValue))
      return showInfo(T.postPage.alreadyPresentCollaborator)

    const collabImage = await fetchCollaboratorImage()

    const newCollaborator: ICollaborator = {
      name: inputValue,
      picture: collabImage ?? '',
    }

    const newCollaborators = [...collaborators, newCollaborator]
    updatePost(post.variantId, { collaborators: newCollaborators })
    setInputValue('')
  }

  const handleRemoveCollaborator = (collaborator: ICollaborator) => {
    const newCollaborators = collaborators.filter((c) => c !== collaborator)
    updatePost(post.variantId, { collaborators: newCollaborators })
  }

  /* ----------------- API CALL  */
  const fetchCollaboratorImage = async () => {
    try {
      const response = (await Socialaccounts.getProfileImage(post.info.account.account_id, inputValue)) as string

      const checkResponse = response.split(' ').includes('Impossibile')

      return checkResponse ? null : response
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * Funzione per gestire il tasto enter
   */
  const handleOnKeyPressEnter = (e) => {
    if (e.key === 'Enter') {
      handleAddCollaborator()
    }
  }

  /* ----------------- USEEFFECT  */

  return (
    <CollaborationContainer>
      <Box row gap={8} vcenter>
        <ExtraTitle text={T.postPage.collaborators} />

        <InfoExtra infoText={T.postPage.collaboratorsInfo} />
      </Box>

      <ContainerCollaborators>
        {!collaborators.length && (
          <Icons.searchIcon fill={AppStore.theme.o.darkGrey} style={{ marginLeft: 6, marginRight: 4 }} />
        )}

        {collaborators.map((collaborator, index) => (
          <SelectedItem key={index}>
            <Box row center gap={10}>
              <CollaboratorImg src={collaborator.picture} />
              <Text style={{ lineHeight: '16px' }}>{collaborator.name}</Text>
            </Box>

            <RemoveCollaborator onClick={() => handleRemoveCollaborator(collaborator)}>
              <Icons.sizerClose fill={AppStore.theme.o.darkGrey} />
            </RemoveCollaborator>
          </SelectedItem>
        ))}

        {collaborators.length < 3 && (
          <InputCollaborators
            value={inputValue}
            onFocus={() => setInputFocus(true)}
            onBlur={() => setInputFocus(false)}
            onKeyPress={handleOnKeyPressEnter}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={`${T.postPage.collaboratorsPlaceholder} ⏎`}
          />
        )}

        {/* {inputFocus && inputValue.length !== 0 && collaboratorsList.length > 0 && (
          <ResultsContainer onMouseDown={(e) => e.preventDefault()}>
            {collaboratorsList.map((collaborator, index) => (
              <CollaboratorItem
                key={collaborator.id}
                collaborator={collaborator}
                handleAddCollaborator={handleAddCollaborator}
              />
            ))}
          </ResultsContainer>
        )} */}
      </ContainerCollaborators>
    </CollaborationContainer>
  )
}

const CollaborationContainer = styled(Box)`
  gap: 16px;
  width: 100%;
  position: relative;
`

const ContainerCollaborators = styled(Box)`
  width: 100%;
  font-size: 16px;
  color: ${() => AppStore.theme.o.black};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  border: 1px dashed ${() => AppStore.theme.o.darkGrey};
  background: ${() => AppStore.theme.o.lightGrey};
  padding: 8px;
  min-height: 54px;
  padding-left: 24px;
`

const SelectedItem = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px 4px 16px;
  height: 32px;
  border: 1px solid ${() => AppStore.theme.o.grey};
  color: ${() => AppStore.theme.o.black};
  background: ${() => AppStore.theme.o.background};
`

const InputCollaborators = styled.input`
  background-color: transparent;
  border: none;
  flex: 1;
`

const RemoveCollaborator = styled(Box)`
  margin-left: 10px;
  border-radius: 50%;
  cursor: pointer;
  background: ${() => AppStore.theme.o.background};
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: ${() => AppStore.theme.o.lightGrey};
  }
`

const ResultsContainer = styled(Box)`
  width: 100%;
  padding: 8px 16px;
  background-color: ${() => AppStore.theme.o.lightestGrey};
  border: 1px solid ${() => AppStore.theme.o.grey};
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  z-index: 100;
  gap: 4px;
  max-height: 300px;
  overflow-y: scroll;
  padding-right: 8px;
`

const CollaboratorImg = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`
