/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { ActivePostCard } from 'components/CreatePost/UI/ActivePostCard'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {}

export const LabelsHeader = ({}: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <LabelsHeaderContainer>
      <ActivePostCard />
    </LabelsHeaderContainer>
  )
}

const LabelsHeaderContainer = styled(Box)`
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
