import {
  MixpanelAuthData,
  MixpanelStripeData,
  MixpanelUserSignupData,
  MixpanelWorkspacesData,
} from './authenticationInterfaces'

export function setAuthProperties(authData: MixpanelAuthData): MixpanelAuthData {
  return {
    auth_method: authData.auth_method,
    error_message: authData.error_message ? authData.error_message : undefined,
    referral_code: authData.referral_code ? authData.referral_code : undefined,
  }
}

export function setUserSignupData(userData): MixpanelUserSignupData {
  return {
    id: userData.id,
    name: userData.name,
    email: userData.email,
  }
}

export function setStripeSignupData(stripeData): MixpanelStripeData {
  const stripeSignupData = {
    stripe_customer_id: stripeData.stripe_customer_id,
    subscription_status: 'Free Trial',
    stripe_trial_end: stripeData.stripe_trial_end,
  }

  return stripeSignupData
}

export function setWorkspacesData(workspaceData): MixpanelWorkspacesData {
  const workspaceSignupData = {
    is_invited: true,
    type: workspaceData.type,
    role_name: workspaceData.workspaces[0].roles[0], //(user as any).workspaces[0].roles[0],
    workspace_name: workspaceData.workspaces[0].name, //(user as any).workspaces[0].name,
  }

  return workspaceSignupData
}
