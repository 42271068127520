import { IResponseCalendarDayPosts, IResponseCalendarPosts } from 'api/PostV2'
import { TSocialFilter } from 'components/Calendar/Sidebar/SidebarInterface'
import { ILabel } from 'components/CreatePost/PreviewComponents/LabelsComponents/LabelsContentComponents/Label'
import { AppStore } from 'utils'
import { create } from 'zustand'

export type TPostStatusCalendar = 'published' | 'error' | 'planned' | 'draft' | 'publishing' | 'all'

export type TLabelType = 'and' | 'or'

export type TCalendarSection = 'month' | 'day'

export type TCreatedFrom = 'all' | 'autopilot' | 'manual'

interface CalendarStore {
  // Per forzare l'aggiornamento dei dati del calendario dopo una modifica
  update: boolean
  forceUpdate: () => void

  // Se la pagina calendar è stata montata
  hasMounted: boolean
  setHasMounted: (hasMounted: boolean) => void

  // Risultati della ricerca calendario (mensile)
  calendarPost: IResponseCalendarPosts[]
  setCalendarPost: (calendarPost: IResponseCalendarPosts[]) => void

  // Risultati della ricerca calendario (giornaliera)
  dayPost: IResponseCalendarDayPosts[]
  setDayPost: (dayPost: IResponseCalendarDayPosts[]) => void

  // ID dei post selezionati della sezione giornaliera
  selectedPostsId: string[]
  setSelectedPostsId: (selectedPostsId: string[]) => void

  calendarSection: TCalendarSection
  setCalendarSection: (calendarSection: TCalendarSection) => void

  // Filtri del calendario
  status: TPostStatusCalendar
  setStatus: (status: TPostStatusCalendar) => void

  labelType: TLabelType
  setLabelType: (labelType: TLabelType) => void

  labels: ILabel[]
  setLabels: (labels: ILabel[]) => void

  socials: string[] // id dei social accounts
  setSocials: (socials: string[]) => void

  socialFilter: TSocialFilter | null
  setSocialFilter: (socialFilter: TSocialFilter) => void

  createdFrom: TCreatedFrom
  setCreatedFrom: (createdFrom: TCreatedFrom) => void

  // Calendar dates
  year: number
  setYear: (year: number) => void

  month: number
  setMonth: (month: number) => void

  day: number
  setDay: (day: number) => void

  startDateCalendar: Date | null
  setStartDateCalendar: (startDateCalendar: Date) => void

  endDateCalendar: Date | null
  setEndDateCalendar: (endDateCalendar: Date) => void
}

export const useCalendarStore = create<CalendarStore>((set, get) => ({
  update: false,
  forceUpdate: () => set({ update: !get().update }),

  hasMounted: false,
  setHasMounted: (hasMounted) => set({ hasMounted }),

  calendarPost: [],
  setCalendarPost: (calendarPost) => set({ calendarPost }),

  dayPost: [],
  setDayPost: (dayPost) => set({ dayPost }),

  selectedPostsId: [],
  setSelectedPostsId: (selectedPostsId) => set({ selectedPostsId }),

  calendarSection: 'month',
  setCalendarSection: (calendarSection) => set({ calendarSection }),

  status: 'all',
  setStatus: (status) => set({ status }),

  labelType: 'and',
  setLabelType: (labelType) => set({ labelType }),

  labels: [],
  setLabels: (labels) => set({ labels }),

  socials: AppStore.socialAccounts.map((account) => account._id!),
  setSocials: (socials) => set({ socials }),

  socialFilter: null,
  setSocialFilter: (socialFilter) => set({ socialFilter }),

  createdFrom: 'all',
  setCreatedFrom: (createdFrom) => set({ createdFrom }),

  year: new Date().getFullYear(),
  setYear: (year) => set({ year }),

  month: new Date().getMonth(),
  setMonth: (month) => set({ month }),

  day: new Date().getDate(),
  setDay: (day) => set({ day }),

  startDateCalendar: null,
  setStartDateCalendar: (startDateCalendar) => set({ startDateCalendar }),

  endDateCalendar: null,
  setEndDateCalendar: (endDateCalendar) => set({ endDateCalendar }),
}))
