import { AppStore, T } from 'utils'
import PricingDisplay, { IPlanInfo } from '../PricingDisplay'
import {
  AmountRowElement,
  UpgradeModalContainer,
  UpgradeModalLighterText,
  UpgradeModalNormalText,
  UpgradeModalTextBlue,
} from './UpgradeOptions.style'
import styled from '@emotion/styled'

/**
 * Component for displaying upgrade with prorate
 */

export interface IProrateData {
  currentPlan: {
    fullAmount: number
    proratedAmount: number
    name: string
    interval: string
  }
  newPlan: {
    fullAmount: number
    proratedAmount: number
    name: string
    interval: string
  }
  costDifference: number
  currency: string
  nextBillingDate: string
}

export interface IOptionAmount {
  amount: number | string
  amountWithVAT: number | string
  amountTotal: number | string
}

export const UpgradeOptionComponent: React.FC<{
  title: string
  description: string
  type: 'prorate' | 'cancel'
  fromPlan: IPlanInfo
  toPlan: IPlanInfo
  options: IOptionAmount
}> = ({ fromPlan, toPlan, options, type, title, description }) => {
  // ------- UTILS ------

  const formatAmount = (amount: number | string) => {
    const newAmount = Number(amount)
    // force positive and 2 decimal places
    return newAmount.toFixed(2).replaceAll('.', ',')
  }

  return (
    <UpgradeModalContainer gap={8}>
      <UpgradeModalTextBlue>{title}</UpgradeModalTextBlue>
      <UpgradeModalNormalText>{description}</UpgradeModalNormalText>
      <PricingDisplay fromPlan={fromPlan} toPlan={toPlan} showCurrency={true} showPlusMinusSigns={type == 'prorate'} />

      <AmountContainer>
        <AmountRow
          amount={formatAmount(options.amountWithVAT)}
          description={T.billing.upgradePlan.amountVat}
          type="normal"
        />
        <AmountRow
          amount={formatAmount(options.amount)}
          description={T.billing.upgradePlan.amountWithoutVAT}
          type="normal"
        />
        <AmountRow
          amount={formatAmount(options.amountTotal)}
          description={T.billing.upgradePlan.amountNow}
          type="light"
        />
      </AmountContainer>
    </UpgradeModalContainer>
  )
}

export const AmountRow = ({ amount, description, type }: { amount: string; description: string; type: string }) => {
  const TextComponent = type === 'light' ? UpgradeModalNormalText : UpgradeModalLighterText
  return (
    <AmountRowElement>
      <TextComponent> {description}</TextComponent>
      <TextComponent>
        <strong>{`${amount} ${T.billing.upgradePlan.currency}`}</strong>
      </TextComponent>
    </AmountRowElement>
  )
}

const AmountContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin-top: 8px;
`
