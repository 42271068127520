/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { PreviewResult } from '../../GenericComponents/PreviewResults'
import { TextContainer } from '../../GenericComponents/TextContainer'
import { ResultsPreviewYoutubeStandard } from './ResultsPreviewYoutubeStandard'

/* ------------------- INTERFACES  */
interface Props {}

export const YoutubeStandard = ({}: Props) => {
  return (
    <YoutubeStandardContainer>
      <PreviewResult component={<ResultsPreviewYoutubeStandard />} />
      <TextContainer />
    </YoutubeStandardContainer>
  )
}

const YoutubeStandardContainer = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
`
