/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { InstagramStandard } from './InstagramStandard'
import { InstagramStory } from './InstagramStory'

/* ------------------- INTERFACES  */
interface Props {
  active: string
}

export const Instagram = ({ active }: Props) => {
  return (
    <InstagramContainer>
      {(active === 'INSTAGRAM_STANDARD' || active === 'INSTAGRAM_REEL') && <InstagramStandard active={active} />}
      {active === 'INSTAGRAM_STORY' && <InstagramStory />}
    </InstagramContainer>
  )
}

const InstagramContainer = styled(Box)`
  width: 100%;
  height: 100%;
`
