/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { AppStore } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  selected: boolean
  action: () => void
  containerIsHover?: boolean
}

export const SelectButton = ({ selected, action, containerIsHover }: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <SelectButtonContainer onClick={action} selected={selected} containerIsHover={containerIsHover ?? false}>
      <div />
    </SelectButtonContainer>
  )
}

const SelectButtonContainer = styled(Box)<{ selected: boolean; containerIsHover: boolean }>`
  padding: 14px;
  border-radius: 16px;
  transition: 300ms ease-in-out all;
  background: 'transparent';
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background: ${({ selected, containerIsHover }) => {
      if (selected) return () => AppStore.theme.o.on_secondary_container
      if (containerIsHover) return () => AppStore.theme.o.grey
      return () => AppStore.theme.o.lightGrey
    }};
  }

  &:hover {
    background: ${() => AppStore.theme.o.lightGrey};

    & > div {
      background: ${({ selected }) =>
        selected ? () => AppStore.theme.o.on_secondary_container : () => AppStore.theme.o.grey};
    }
  }
`
