import { areasplineOptions } from './areasplineOptions'
import { doughnutOptions } from './doughnutOptions'
import { geomapOptions } from './geoOptions'
import { heatmapOptions } from './heatmapOptions'
import { lineOptions } from './lineOptions'
import { pieOptions } from './pieOptions'
import { barOptions } from './barOptions'

export * from './pieOptions'
export * from './geoOptions'
export * from './doughnutOptions'
export * from './heatmapOptions'
export * from './lineOptions'
export * from './areasplineOptions'
export * from './geoOptions'
export * from './barOptions'

export const highchartsOptionsEnum = {
  pie: pieOptions,
  doughnut: doughnutOptions,
  line: lineOptions,
  bar: barOptions,
  heatmap: heatmapOptions,
  areaspline: areasplineOptions,
  geomap: geomapOptions,
}
