import styled from '@emotion/styled'
import { useState } from 'react'
import { AppStore } from 'utils'
import { Box } from './Box'
import { Icons } from './Icons'

interface Props {
  removeEvents?: boolean
  admin?: boolean
  user: {
    name: string
    email: string
    role: string
    invitationConfirmed: boolean
    id: string
  }
  index: number
  findRole: (role: string) => any
  NumberComponent: (props: { id: string; roleName: string; i: number }) => JSX.Element
  AddRoleComponent: (user: any, index: number) => JSX.Element
  handleSelectId: (id: string) => void
  isSelected?: boolean
}

const truncateRole = (role: string) => {
  const roleName = role ?? ''
  return roleName.length > 20 ? roleName.substring(0, 20) + '...' : role
}

export default function UserRow(props: Props) {
  const { user, index, findRole, NumberComponent, AddRoleComponent, removeEvents, handleSelectId, isSelected } = props

  const email = truncateRole(user.email)
  const userName = truncateRole(user.name)

  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <>
      <Box
        id={`user-${user.email}-${index}`}
        row
        width={'100%'}
        height={62}
        relative
        vcenter
        p={'16px 16px 16px 12px'}
        justify="space-between"
        style={{ opacity: user.invitationConfirmed ? 1 : 0.3, pointerEvents: removeEvents ? 'none' : 'auto' }}
      >
        {/* USER */}
        <Box
          width={194}
          row
          vcenter
          gap={8}
          onHoverStart={() => setShowTooltip(true)}
          onHoverEnd={() => setShowTooltip(false)}
        >
          <Box
            height={16}
            width={16}
            style={{
              backgroundColor: isSelected ? AppStore.theme.o.green : AppStore.theme.o.grey,
              visibility: removeEvents ? 'hidden' : 'visible',
            }}
            onClick={() => handleSelectId(user.id)}
          />

          {/* IMG */}
          <Box width={24} height={24} style={{ borderRadius: 50, backgroundColor: AppStore.theme.o.red }} />

          {/* NAME AND EMAIL */}
          <Box>
            {user.invitationConfirmed ? <HeaderText>{userName}</HeaderText> : <IconEmail />}
            <EmailText>{email}</EmailText>
          </Box>

          {/* TOOLTIP */}
          {showTooltip && (
            <span
              style={{
                padding: 5,
                paddingInline: 20,
                left: 30,
                color: 'white',
                backgroundColor: '#000000e9',
                borderRadius: 10,
                position: 'absolute',
              }}
            >
              {user.email}
            </span>
          )}
        </Box>

        <Box row vcenter justify="space-around" flex ml={'auto'}>
          {/* ROLES VALUE */}
          {findRole(user?.role)?.rolesNumber.map((session, i) => {
            return NumberComponent({ id: session.name, roleName: user?.role, i })
          })}
        </Box>

        {/*  Role  */}
        {AddRoleComponent(user, index)}
      </Box>
      <DivisionLine />
    </>
  )
}

const IconEmail = styled(Icons.emailToConfirm)`
  fill: ${() => AppStore.theme.o.black};
  height: 15px;
  width: 20px;
  margin-left: -8px;
  margin-top: -4px;
`

const DivisionLine = styled.div`
  background-color: ${() => AppStore.theme.o.grey};
  height: 1px;
  width: 100%;
`

const HeaderText = styled.p`
  font-family: 'Aktiv Grotesk';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${() => AppStore.theme.o.black};
`

const EmailText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Aktiv Grotesk';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${() => AppStore.theme.o.black};
`
