/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Text } from 'components'
import { SpinnerAstersLogo } from 'components/UI'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  colors: {
    background: string
    text: string
  }
  name: string
  value: string | undefined
  icon: JSX.Element
}

export const OrganizationCard = ({ colors, name, value, icon }: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <OrganizationCardContainer
      style={{
        backgroundColor: colors.background,
        color: colors.text,
      }}
      iconColor={colors.text}
    >
      <CardInfo>
        <Text fontSize={16} weight={700} color={colors.text}>
          {name}
        </Text>

        <Box>
          {typeof value !== 'undefined' && (
            <Text
              fontSize={61}
              weight={700}
              color={colors.text}
              style={{
                lineHeight: 0.8,
              }}
            >
              {value}
            </Text>
          )}

          {typeof value === 'undefined' && <SpinnerAstersLogo dimension={40} />}
        </Box>
      </CardInfo>

      <Box width={88} height={88}>
        {icon}
      </Box>
    </OrganizationCardContainer>
  )
}

const OrganizationCardContainer = styled(Box)<{ iconColor: string }>`
  padding: 48px;
  border-radius: 14px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  svg {
    fill: ${(props) => props.iconColor};
  }
`

const CardInfo = styled(Box)`
  position: relative;
  gap: 24px;
`
