import { useState } from 'react'
import styled from '@emotion/styled'
import { Box, Icons, Text } from 'components'
import { AppStore, T } from 'utils'
import { IPostVariant } from 'types/PostInterface'
import { updatePost } from 'utils/PostUtils/Post'
import { ExtraTitle } from '../GenericExtras/ExtraTitle'

/* ******************* INTERFACE ******************* */
interface Props {
  post: IPostVariant
}

export const YouTubeHashtags = ({ post }: Props) => {
  /* ____________________ STATE  ____________________ */
  const [tags, setTags] = useState<string[]>(post.data.hashtags ?? [])

  /* ____________________ VARIABLES  ____________________ */
  const { variantId } = post

  /* ____________________ METHODS  ____________________ */
  const addTag = (event) => {
    const newTag: string = event.target.value
    const regex = /\s/

    const containsSpace = regex.test(newTag.trim())
    const tagAlreadyExists = tags.includes(newTag)

    if (newTag.trim() !== '' && !tagAlreadyExists && !containsSpace) {
      const updatedTags = [...tags, newTag]

      updatePost(variantId, { hashtags: updatedTags })

      setTags(updatedTags)

      event.target.value = ''
    }
  }

  const removeTag = (indexToRemove) => {
    const updatedTags = tags.filter((_, index) => index !== indexToRemove)

    updatePost(variantId, { hashtags: updatedTags })

    setTags(updatedTags)
  }

  return (
    <TagsContainer>
      <ExtraTitle text={T.postPage.youtubeTubeExtra.tag} />

      <TagsInputContainer>
        <TagsList style={tags.length > 0 ? { marginBottom: '12px' } : {}}>
          {tags.map((tag, index) => (
            <Item key={index}>
              {tag}
              <Icons.close
                fill={AppStore.theme.o.black}
                onClick={() => removeTag(index)}
                style={{ cursor: 'pointer', height: '10px' }}
              ></Icons.close>
            </Item>
          ))}
        </TagsList>

        <TagsInput
          type="text"
          placeholder={tags.length === 0 ? T.postPage.youtubeTubeExtra.enterTag : ''}
          onKeyUp={(e) => (e.key === 'Enter' ? addTag(e) : null)}
        />
      </TagsInputContainer>
    </TagsContainer>
  )
}

const TagsList = styled(Box)`
  flex-direction: row;
  flex-wrap: wrap;
  gap: 18px;
`

const TagsInputContainer = styled(Box)`
  border: 1px solid ${() => AppStore.theme.o.grey};
  padding: 16px 24px;
  border-radius: 14px;
`

const TagsInput = styled.input`
  border: none;
  padding: 2px 0;
  background-color: transparent;
  color: ${() => AppStore.theme.o.black} !important;

  &::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: ${() => AppStore.theme.o.black} !important;
  }
`

const Item = styled(Box)`
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  color: ${() => AppStore.theme.o.black};
  background-color: ${() => AppStore.theme.o.lightestGrey};
  border: 1px solid ${() => AppStore.theme.o.grey};
  gap: 8px;
`

const TagsContainer = styled(Box)`
  gap: 12px;
`
