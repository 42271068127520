/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { useState } from 'react'
import { AppStore } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  icon: JSX.Element
  classNames?: string
  label?: string
  isSelected?: boolean
  color?: string
  background?: string
  onClick?: () => void
  style?: React.CSSProperties
}

export const IconContainer = ({ icon, classNames, label, isSelected, color, background, onClick, style }: Props) => {
  /* ----------------- STATE  */
  const [isHover, setIsHover] = useState<boolean>(false)

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */
  const handleColor = (coloration: string | undefined, type: 'background' | 'color') => {
    if (isSelected) return coloration

    if (isHover) return coloration

    return type === 'background' ? AppStore.theme.o.surface : AppStore.theme.o.black
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <IconContainerContainer
      style={style}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={classNames ?? ''}
      onClick={onClick}
      background={handleColor(background, 'background')}
      color={handleColor(color, 'color')}
    >
      <Icon className={classNames}>{icon}</Icon>

      {/* !! MESSO CON  dangerouslySetInnerHTML PERCHé ALTRIMENTI SI ROMPEVA IL COMPONENTE CON GLI SPAZI NEL TESTO*/}
      {label && (
        <IconName style={{ transition: '0s' }} dangerouslySetInnerHTML={{ __html: label.replace(/ /g, '&nbsp;') }} />
      )}
    </IconContainerContainer>
  )
}

const IconContainerContainer = styled(Box)<{ color?: string; background?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  gap: 8px;
  padding: 6px;
  max-width: 44px;
  min-height: 44px;
  max-height: 44px;
  overflow: hidden;
  border-radius: 14px;
  transition: 0.3s ease-in-out;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  background: ${({ background }) => background};
  color: ${({ color }) => color};

  svg {
    fill: ${({ color }) => color};
  }

  &:hover {
    background: ${({ background }) => background};
    color: ${({ color }) => color};

    svg {
      fill: ${({ color }) => color};
    }
  }

  &:hover,
  &.open-label {
    max-width: 100%;
    padding: 6px 16px 6px 6px;
  }

  &.icon-toggle-icons {
    max-height: 44px;
    max-width: 44px;
  }
`

const Icon = styled(Box)`
  width: 32px;
  height: 32px;

  &.icon-toggle-icons {
    svg {
      transition: 0.3s ease-in-out;
      fill: ${() => AppStore.theme.o.darkGrey}!important;
    }

    &:hover {
      svg {
        fill: ${() => AppStore.theme.o.black} !important;
      }
    }

    &.isOpen {
      svg {
        fill: ${() => AppStore.theme.o.black} !important;
      }
    }
  }
`

const IconName = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
