import styled from '@emotion/styled'
import { Input as AntdInput } from 'antd'
import { Box, Input } from 'components'
import React, { useState } from 'react'
import { T } from 'translation/i18n'
import { AppStore } from 'utils'

export interface Props {
  label: string
  value?: string
  variant?: 'default' | 'name' | 'email' | 'password'
  onChange?: (value: string) => void
  onConfirmCallback?: (value?: string) => void
  disabled?: boolean
  onEnabled?: (enabled: boolean) => void
}

export const EditableInput = ({ value, variant, label, onChange, onConfirmCallback, disabled }: Props) => {
  const [enabled, setEnabled] = useState(false)
  const [valueState, setValueState] = useState(`${value}`)
  const inputElement = React.createRef<AntdInput>()

  const onChangeHandler = (_value: string) => {
    setValueState(_value)
    onChange?.(_value)
  }

  const onEnabledHandler = () => {
    if (!!enabled && value !== valueState) onConfirmCallback?.(valueState)
    setEnabled(!enabled)
  }

  return (
    <Box>
      <Box mb={12} row vcenter>
        <Label>{label}</Label>
        {!!onConfirmCallback && !disabled && (
          <>
            <Vspacer />
            <Modify onClick={onEnabledHandler}>{enabled ? T.settings.confirm : T.settings.modify}</Modify>
          </>
        )}
      </Box>
      <Input
        inputRef={inputElement}
        variant={variant}
        onChange={onChangeHandler}
        value={valueState}
        disabled={!enabled}
        onPressEnter={onEnabledHandler}
      />
    </Box>
  )
}

const Label = styled.p`
  font-weight: 700;
  font-size: 16px;
  color: ${() => AppStore.theme.o.black};
`

const Modify = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: ${() => AppStore.theme.o.blueOnLight};
  cursor: pointer;
`

const Vspacer = styled.div`
  width: 2px;
  height: 20px;
  margin-left: 6px;
  margin-right: 6px;
  background-color: ${() => AppStore.theme.o.grey};
`
