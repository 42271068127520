/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { TextContainer } from '../../GenericComponents/TextContainer'
import { ResultsPreviewFacebook } from './ResultsPreviewFacebook'
import { PreviewResult } from '../../GenericComponents/PreviewResults'

/* ------------------- INTERFACES  */
interface Props {}

export const Facebook = ({}: Props) => {
  return (
    <FacebookContainer>
      <PreviewResult component={<ResultsPreviewFacebook />} />
      <TextContainer />
    </FacebookContainer>
  )
}

const FacebookContainer = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
`
