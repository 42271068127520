import styled from '@emotion/styled'
import { Box } from 'components'
import React, { useRef, useState } from 'react'
import { AppStore, T, showSuccess, showWarning } from 'utils'
import { getVerifyNumber } from './utils/apiCall'

interface Props {
  returnData: (otp: string, isValid: boolean) => void // funzione per tornare i dati al padre
}

const OtpField = ({ returnData }: Props) => {
  /* ____________________ REF ____________________ */
  const inputRefs = useRef<Array<HTMLInputElement | null>>([])

  /* ____________________ STATE ____________________ */
  const [otpValues, setOTPValues] = useState<string[]>(Array(6).fill(''))
  const [error, setError] = useState<boolean>(false)

  /* ____________________ METHODS ____________________ */
  /*  // per eliminare il valore al click dell'input
  const onClick = (index: number) => {
    // const inputValue = otpValues[index]
    //     if (inputValue !== '') {
    //   const newOTPValues = [...otpValues]
    //   newOTPValues[index] = ''
    //   setOTPValues(newOTPValues)
    // } 
  } */

  const onSetError = () => setError(true)

  const onRemoveError = () => setError(false)

  // per cambiare il valore all'input
  const onChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    onRemoveError()
    const inputValue = e.target.value.substr(0, 1)
    const newOTPValues = [...otpValues]
    newOTPValues[index] = inputValue
    setOTPValues(newOTPValues)

    // cambio focus
    if (inputValue !== '') {
      const nextIndex = index + 1
      if (nextIndex < otpValues.length) {
        const nextInput = e.currentTarget.nextElementSibling as HTMLInputElement
        if (nextInput) {
          nextInput.focus()
        }
      }
    } else {
      const prevIndex = index - 1
      if (prevIndex >= 0) {
        const prevInput = e.currentTarget.previousElementSibling as HTMLInputElement
        if (prevInput) {
          prevInput.focus()
        }
      }
    }

    verifyOtp()
  }

  // per quando si copia in un input
  const onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    const pastedData = e.clipboardData.getData('text')
    const otpArray = pastedData.split('').slice(0, 6)
    const newOTPValues = Array(6)
      .fill('')
      .map((_, index) => otpArray[index] || '')
    setOTPValues(newOTPValues)
  }

  /**
   * ## shakeInvalidInput
   * - aggiunge la classe shake all'input in caso di errore
   */
  const shakeInvalidInput = () => {
    const input = document.querySelector('.input-container-otp') as HTMLElement

    input?.classList.add('shake')

    setTimeout(() => {
      input?.classList.remove('shake')
    }, 300)
  }

  /* ____________________ API ____________________ */
  /**
   * ## verifyOtp
   * - verifica l'otp inserito
   * - se è corretto ritorna i dati al padre
   * - se è sbagliato mostra un errore e fa fare la shake animation all'input
   *
   */
  const verifyOtp = async () => {
    const refsArr = [...inputRefs.current]
    const otpValue = refsArr.map((ref) => ref?.value).join('')

    if (otpValue.length < 6) return
    const otpVerification = await getVerifyNumber(otpValue)

    if (!otpVerification) {
      onSetError()
      shakeInvalidInput()
      showWarning(T.settings.otpError)
      return
    }
    returnData(otpValue, otpVerification)
    showSuccess(T.settings.otpSuccess)
  }

  return (
    <div className="input-control-otp">
      <InputContainer className={`input-container-otp ${error ? 'error' : ''}`}>
        {otpValues.map((otp, index) => (
          <input
            key={index}
            className={`otp-${index}`}
            placeholder="_"
            type="text"
            value={otp}
            maxLength={1}
            onClick={() => inputRefs.current[index]?.select()}
            onChange={(event) => onChange(event, index)}
            onPaste={onPaste}
            ref={(ref) => (inputRefs.current[index] = ref)}
            autoFocus={index === 0}
            onFocus={() => inputRefs.current[index]?.select()}
          />
        ))}
      </InputContainer>
    </div>
  )
}

export default OtpField

const InputContainer = styled(Box)`
  &.input-container-otp {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;

    input {
      color: ${() => AppStore.theme.o.black};
      background-color: ${() => AppStore.theme.o.lightGrey};
      width: 50px;
      height: 56px;
      border-radius: 14px;
      padding: 0;
      border: none;
      text-align: center;
    }

    &.error {
      input {
        border: 2px solid ${() => AppStore.theme.o.red};
      }
    }

    &.shake {
      animation: 0.1s shake;
    }
  }

  @keyframes shake {
    0% {
      rotate: 0deg;
    }
    40% {
      rotate: 3deg;
    }
    80% {
      rotate: -3deg;
    }
    100% {
      rotate: 0deg;
    }
  }
`
