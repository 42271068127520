import backGroundPostPageDark from '../assets/DarkMode.png'

import backGroundPostPageLight from '../assets/LightMode.png'

const lightTheme = {
  //ora qui l'inadno ha fatto i stili e da ora in poi dobbiamo usare solo quelli per nn rompere asters
  // --__----__----__----__----__----__----__----__----__----__----__----__----__--
  o: {
    background: '#ffffff',
    surface: '#FFFFFF',
    black: '#2E3A59',
    white: '#FFFFFF',
    menuIndicator: '#EEEEEE',
    lightestGrey: '#FAFAFA',
    lightGrey: '#F2F2F3',
    grey: '#D8DCE0',
    darkGrey: '#A3A1AA',
    green: '#007750',
    lightGreen: '#EAFEF7',
    blue: '#00747C',
    blueOnLight: '#00747C',
    lightBlue: '#EAFCFE',
    red: '#9F0C2E',
    lightRed: '#FEEAEE',
    yellow: '#FFB822',
    selectPermissionButton: '#FFFFFF',
    betaComponentText: '#732187',
    betaComponentBackground: '#FAEAFE',

    // ----- Nuove variabili ----- //
    primary: '#00747C',
    on_primary: '#FFFFFF',
    primary_container: '#EAFCFE',
    on_primary_container: '#00747C',

    secondary: '#007750',
    on_secondary: '#EAFEF7',
    secondary_container: '#EAFEF7',
    on_secondary_container: '#007750',

    tertiary: '#A12DBC',
    on_tertiary: '#FCF3FD',
    tertiary_container: '#FCF3FD',
    on_tertiary_container: '#A12DBC',

    error: '#9C102B',
    on_error: '#FFEEEF',
    error_container: '#FFEEEF',
    on_error_container: '#9C102B',

    warning: '#8A671B',
    warning_container: '#FFECCB',
    textDarkGrey: '#676970',
    textDisabledGrey: '#C1C5C9',
  },

  opacityBtn: { opPrimary: '80', opSecondary: '80' },
  // --__----__----__----__----__----__----__----__----__----__----__----__----__--

  backgroundPostPage: backGroundPostPageLight,

  simpleOuterShadow: ';box-shadow: 4px 8px 16px rgba(38, 42, 72, 0.14);',
  // boxHoverStyle: `:hover {border-color: rgba(28,187,201,1); box-shadow: inset 0px 0px 0px 1px rgba(28,187,201,1); transition: 0.5s;}`,
  boxHoverStyle: `:hover {background-color: #F2F2F3; transition: 0.5s;}`,

  smallOuterShadow: '0px 0px 16px 0px rgba(87, 87, 87, 0.2);',
  bigOuterShadow: 'box-shadow: 0px 0px 40px 0px rgba(87, 87, 87, 0.2);',
  bigOuterShadowNoBox: '0px 0px 40px 0px rgba(87, 87, 87, 0.2)',
  pageInnerShadow:
    'box-shadow: 0px 0px 11px 0px rgba(87, 87, 87, 0.08); box-shadow: 0px 0px 20px 0px rgba(87, 87, 87, 0.12); box-shadow: 0px 0px 25px 0px rgba(87, 87, 87, 0.2);',
  calendarCardBoxShadow:
    'box-shadow: 5.92154px 6.77756px 13px rgba(38, 42, 72, 0.08), 2.28089px 2.61062px 4.14074px rgba(38, 42, 72, 0.04),0.482496px 0.552246px 1.05926px rgba(38, 42, 72, 0.02);',
  finalNavbarBoxShadow:
    'box-shadow: 0px 0px 11.424854278564453px 0px rgba(87, 87, 87, 0.04), 0px 0px 44.66079330444336px 0px rgba(87, 87, 87, 0.06), 0px 0px 140.214111328125px 0px rgba(87, 87, 87, 0.10);',

  smallBoxShadow: '4px 4px 32px 0px rgba(87, 87, 87, 0.05)',

  mainShadow: '0px 0px 20px 0px #6363631F',
  smallShadow: '6px 6px 14px 0px #262A481F',
}

const darkTheme = {
  o: {
    background: '#2E2E2E',
    surface: '#3B3B3B',
    black: '#FFFFFF',
    white: '#2E3A59',
    menuIndicator: '#1E1E20',
    lightestGrey: '#2b2b2b',
    lightGrey: '#212121',
    grey: '#4F4F4F',
    darkGrey: '#A9B0B8',

    green: '#A4E2C5',
    lightGreen: '#082118',

    blue: '#C4F6F4',
    blueOnLight: '#C4F6F4',
    lightBlue: '#062126',

    red: '#FFC4CA',
    lightRed: '#9C102B',
    yellow: '#FFB822',
    selectPermissionButton: '#4F4F4F',
    betaComponentText: '#732187',
    betaComponentBackground: '#FAEAFE',

    // ----- Nuove variabili ----- //
    primary: '#C4F6F4',
    on_primary: '#05484D',
    primary_container: '#062126',
    on_primary_container: '#C4F6F4',

    secondary: '#A4E2C5',
    on_secondary: '#084631',
    secondary_container: '#082118',
    on_secondary_container: '#A4E2C5',

    tertiary: '#EECDF9',
    on_tertiary: '#6C1F79',
    tertiary_container: '#380D3F',
    on_tertiary_container: '#EECDF9',

    error: '#FFC4CA',
    on_error: '#9C102B',
    error_container: '#490716',
    on_error_container: '#FFC4CA',

    warning: '#FFDA8D',
    warning_container: '#211903',
    textDarkGrey: '#ffffff',
    textDisabledGrey: '#fff',
  },

  opacityBtn: { opPrimary: '', opSecondary: '80' },

  // --__----__----__----__----__----__----__----__----__----__----__----__----__--
  backgroundPostPage: backGroundPostPageDark,

  // boxHoverStyle: `:hover {border-color:rgba(28,187,201,1); box-shadow: inset 0px 0px 2px 1px rgba(28,187,201,1); background-color: #2E2E2E; transition: 0.5s;}; `,
  boxHoverStyle: `:hover {background-color: #212121; transition: 0.5s;}`,
  simpleOuterShadow: ';box-shadow: 9px 10px 26px 0px #03031066;',
  smallOuterShadow: '0px 0px 16px 0px #03031080;',
  bigOuterShadow: 'box-shadow: 0px 0px 40px 0px #03031080;',
  bigOuterShadowNoBox: '0px 0px 40px 0px #03031080;',
  pageInnerShadow:
    'box-shadow: 0px 0px 11px 0px #0303103D; box-shadow: 0px 0px 44px 0px #0303104D; box-shadow: 0px 0px 40px 0px #03031080;',
  calendarCardBoxShadow:
    'box-shadow: 9px 10px 26px rgba(3, 3, 16, 0.4), 5px 5px 8px rgba(3, 3, 16, 0.24), 2px 2px 2px rgba(3, 3, 16, 0.18);',
  finalNavbarBoxShadow:
    'box-shadow: 0px 0px 11.424854278564453px 0px rgba(3, 3, 16, 0.24), 0px 0px 44.66079330444336px 0px rgba(3, 3, 16, 0.30), 0px 0px 140.214111328125px 0px rgba(3, 3, 16, 0.50);',

  smallBoxShadow: `4px 4px 32px 0px rgba(3, 3, 3, 0.05);`,

  mainShadow: '0px 0px 20px 0px #6363631F',
  smallShadow: '6px 6px 14px 0px #262A481F',
}

export { darkTheme, lightTheme }
