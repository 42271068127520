/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { useEffect, useRef, useState } from 'react'
import { IInstagramTag, IPostVariant } from 'types/PostInterface'
import { BtnGoToProfile } from './BtnGoToProfile'
import { AppStore, showInfo, T } from 'utils'
import { BtnDeleteTag } from './BtnDeleteTag'
import { BtnConfirmTag } from './BtnConfirmTag'
import { updatePost } from 'utils/PostUtils/Post'
import { SpinnerAstersLogo } from 'components/UI'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */
interface Props {
  tag: IInstagramTag
  style: any
  hideCursor: () => void
  showCursor: () => void
  tagWidth: number
  fullTagWidth: number
  post: IPostVariant
  getSocialPicture: (text?: string) => Promise<string | null | undefined>
}

export const ExistingTag = ({
  tag,
  style,
  hideCursor,
  showCursor,
  tagWidth,
  fullTagWidth,
  post,
  getSocialPicture,
}: Props) => {
  /* ----------------- STATE  */
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isTagHovered, setIsTagHovered] = useState<boolean>(false)

  const [isFetchingImg, setIsFetchingImg] = useState<boolean>(false)

  // Valore di testo del utente
  const [inputValue, setInputValue] = useState(tag.text)

  /* ----------------- REF  */
  const tagInput = useRef<HTMLInputElement>(null)

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */
  const hasModifyTag = inputValue !== tag.text

  /* ----------------- METHODS  */
  const handleClickTag = (e) => {
    e.stopPropagation()

    if (isOpen) setInputValue(tag.text) // Reset input value if closing

    setIsOpen((prev) => !prev)
  }

  const handleModifyTag = async (e) => {
    e.stopPropagation()

    if (inputValue === '') return showInfo(T.postPage.usernameRequired)

    const tagToModify = post?.data?.tags?.filter((t) => JSON.stringify(t) === JSON.stringify(tag))[0]

    tagToModify!.text = inputValue

    const newTags = post?.data?.tags?.map((t) => (JSON.stringify(t) === JSON.stringify(tag) ? tagToModify : t))

    updatePost(post.variantId, { tags: newTags })

    fetchImg()
    setInputValue(tag.text)
    setIsOpen(false)
    setIsTagHovered(false)
    showCursor()
  }

  /**
   * Funzione per eliminare un tag
   * @param e evento del click
   */
  const handleDeleteTag = (e) => {
    e.stopPropagation()

    const newTags = post?.data?.tags?.filter((t) => JSON.stringify(t) !== JSON.stringify(tag))

    updatePost(post.variantId, { tags: newTags })

    setInputValue(tag.text)
    setIsOpen(false)
    setIsTagHovered(false)
    showCursor()
  }

  /**
   * Funzione per controllare quale icona mostrare
   * @returns The icon to show
   */
  const checkIconToShow = () => {
    if (isFetchingImg) {
      return <SpinnerAstersLogo dimension={4} />
    }

    if (!isOpen && isTagHovered) {
      return <Icons.modifyIcon />
    }

    if (!isOpen && !isTagHovered && !tag.urlImg) {
      return <Icons.user />
    }

    if (!isOpen && !isTagHovered && tag.urlImg) {
      return <ProfileImage src={tag.urlImg} />
    }
  }

  /**
   *  Funzione per andare al profilo instagram dell'utente
   * @param e  evento del click
   */
  const handleGoToProfile = (e) => {
    e.stopPropagation()
    window.open(`https://www.instagram.com/${tag.text}`, '_blank')
  }

  const updateTextNewTag = (e) => {
    setInputValue(e.target.value)
  }

  /**
   * Funzione per dare focus all'input della scrittura del username tag
   */
  const focusInput = () => {
    if (tagInput.current) tagInput.current.focus()
  }

  /**
   * Funzione per gestire il tasto enter
   */
  const handleOnKeyPressEnter = (e) => {
    if (e.key === 'Enter') {
      handleModifyTag(e)
    }
  }

  /* ----------------- API CALL  */
  const fetchImg = async () => {
    const tagToModify = post?.data?.tags?.filter((t) => JSON.stringify(t) === JSON.stringify(tag))[0]

    try {
      setIsFetchingImg(true)
      const newImgUrl = await getSocialPicture(tag.text)
      tagToModify!.urlImg = newImgUrl

      const newTags = post?.data?.tags?.map((t) => (JSON.stringify(t) === JSON.stringify(tag) ? tagToModify : t))

      updatePost(post.variantId, { tags: newTags })

      setIsFetchingImg(false)
    } catch (e) {
      console.error(e)
    }
  }

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    if (tag.urlImg === undefined) fetchImg()
  }, [])

  //On mounting  focus input
  useEffect(() => {
    isOpen && focusInput()
  }, [isOpen])

  return (
    <ExistingTagContainer
      removeHover
      style={{ ...style, width: isOpen ? fullTagWidth : tagWidth }}
      onClick={handleClickTag}
      onMouseEnter={hideCursor}
      onMouseLeave={showCursor}
    >
      {/* Closed tag view */}
      {!isOpen && (
        <ClosedExistingTag onMouseLeave={() => setIsTagHovered(false)} onMouseEnter={() => setIsTagHovered(true)}>
          {checkIconToShow()}
        </ClosedExistingTag>
      )}

      {/* Open tag view */}
      {isOpen && (
        <OpenExistingTag>
          <ContainerInput>
            {!hasModifyTag && tag.urlImg ? (
              <ProfileImage src={tag.urlImg} style={{ marginRight: 8 }} />
            ) : (
              <Icons.user fill={AppStore.theme.o.black} width={24} height={24} style={{ marginRight: 8 }} />
            )}
            @
            <InputTag
              ref={tagInput}
              placeholder={T.postPage.username}
              onChange={updateTextNewTag}
              value={inputValue}
              onClick={(e) => e.stopPropagation()}
              onKeyPress={handleOnKeyPressEnter}
            />
          </ContainerInput>

          {/* Actions */}
          <BtnDeleteTag action={handleDeleteTag} />

          {hasModifyTag && <BtnConfirmTag action={handleModifyTag} />}

          {!hasModifyTag && <BtnGoToProfile action={handleGoToProfile} />}
        </OpenExistingTag>
      )}
    </ExistingTagContainer>
  )
}

const ExistingTagContainer = styled(Box)`
  position: absolute;
  overflow: hidden;
  transition: all 0s;
  transition: width 0.5s;
  flex-direction: row;
  align-items: center;
`

const ClosedExistingTag = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid ${() => AppStore.theme.o.grey};

  svg {
    width: 24px;
    height: 24px;
    fill: ${() => AppStore.theme.o.black};
  }

  background-color: ${() => AppStore.theme.o.surface};
`

const OpenExistingTag = styled(Box)`
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  overflow: hidden;
  z-index: 99999;
`

const ProfileImage = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: cover;
`

const ContainerInput = styled(Box)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px 8px 8px;
  border-radius: 16px;
  background-color: ${() => AppStore.theme.o.surface};
  color: ${() => AppStore.theme.o.black};
  border: 1px solid ${() => AppStore.theme.o.grey};
`

const InputTag = styled.input`
  margin-left: 4px;
  border: none;
  background-color: transparent;
  color: ${() => AppStore.theme.o.black};
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
`
