/* ___________________ IMPORTS ___________________ */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { gsap } from 'gsap'
import { useEffect, useState } from 'react'
import { T } from 'translation/i18n'
import { getHumanReadableDate } from 'utils'
import AppStore from 'utils/AppStore'
import { IBoard } from '../aiDiscoverInterface'
import { onLoadImageError } from '../commonFunction'
import asters_placeholder from 'assets/asters_placeholder.png'
import { useDiscoverStore } from 'stores/DiscoverStore'

/* ___________________ INTERFACE ___________________ */
interface Props {
  board: IBoard
  isSelected?: boolean
  isOnCarousel?: boolean
  onClick: any
  index: number
  boardSelected?: boolean
}

const PreviewBoard = ({ board, isSelected = false, isOnCarousel, onClick, index, boardSelected = false }: Props) => {
  /* ____________________ STATE ____________________ */

  const [selectedBoardIndex, setSelectedBoardIndex] = useState<number[]>([])
  const [checkBoxChecked, setCheckBoxChecked] = useState<boolean>()

  /* ____________________ ZUSTAND  ____________________ */
  const { subPageActive, setActiveBoard, setActiveDropdown } = useDiscoverStore((state) => ({
    subPageActive: state.subPageActive,
    setActiveBoard: state.setActiveBoard,
    setActiveDropdown: state.setActiveDropdown,
  }))
  /* ____________________ VARIABLES ____________________ */

  const placeHolderContainer = (posLeft: number) => (
    <ImgContainer
      style={{
        zIndex: 12 - posLeft,
        left: `${posLeft * 63}px`,
        width: '180px',
        height: '180px',
        borderRadius: '24px',
        backgroundColor: AppStore.theme.o.lightGrey,
        border: `1.5px solid ${AppStore.theme.o.surface}`,
        objectFit: 'cover',
      }}
    />
  )

  const lockDivStyle =
    subPageActive !== 'board'
      ? {
          transform: 'translate(0, 0)',
          border: `1px ${AppStore.theme.o.grey} solid`,
          borderRadius: 8,
          backgroundColor: AppStore.theme.o.lightGrey,
        }
      : { border: `1px ${AppStore.theme.o.grey} solid`, borderRadius: 8, backgroundColor: AppStore.theme.o.lightGrey }
  /* ____________________ METHODS ____________________ */

  const activeBoardHandler = () => {
    if (isOnCarousel) {
      onClick(board)
      setActiveBoard(board)
      setActiveDropdown(board.private ? 'private' : 'shared')
      return
    }
    if (boardSelected) {
      onClick(board)
      return
    }
    setActiveBoard(board)
    setActiveDropdown(board.private ? 'private' : 'shared')
  }

  const onHover = () => {
    if (subPageActive !== 'board') return
    gsap.to(`#checkDiv-${index}`, {
      y: 0,
      duration: 0.3,
    })
    gsap.to(`#lockDiv-${index}`, {
      x: 0,
      duration: 0.3,
    })
  }

  const onHoverEnd = () => {
    if (subPageActive !== 'board') return
    if (!isSelected) {
      gsap.to(`#checkDiv-${index}`, {
        y: -60,
        duration: 0.3,
      })
      gsap.to(`#lockDiv-${index}`, {
        x: 47,
        duration: 0.3,
      })
    }
  }

  useEffect(() => {
    setCheckBoxChecked(isSelected)
    isSelected ? onHover() : onHoverEnd()
  }, [isSelected])

  return (
    <CardDiv onHoverStart={onHover} onHoverEnd={onHoverEnd} onClick={activeBoardHandler}>
      {/* ____________________ HEADER ____________________  */}
      <Box width={308}>
        {/* Name and lock icon */}
        <Box row style={{ justifyContent: 'space-between' }} removeTransition>
          <Text>{board.boardTitle}</Text>
          <Box row gap={15} removeTransition>
            <LockDiv
              id={'lockDiv-' + index}
              removeTransition
              center
              className={selectedBoardIndex.includes(0) ? 'selected' : ''}
              style={lockDivStyle}
            >
              {board.private ? (
                <Icons.lock fill={AppStore.theme.o.black} width={24} height={24} />
              ) : (
                <Icons.notLock fill={AppStore.theme.o.black} width={15} height={15} />
              )}
            </LockDiv>

            {subPageActive === 'board' && (
              <CheckBoxDiv
                id={'checkDiv-' + index}
                removeTransition
                center
                className={checkBoxChecked ? 'selected ' : ''}
                onClick={(e) => {
                  e.stopPropagation()
                  onClick(board)
                }}
              >
                {checkBoxChecked && <Icons.boxgreen fill={AppStore.theme.o.green} />}
                {!checkBoxChecked && <Icons.checkboxgrey fill={AppStore.theme.o.darkGrey} />}
              </CheckBoxDiv>
            )}
          </Box>
        </Box>
        {/* Article count */}
        <Box width={57} height={15}>
          {board.articles && (
            <Article>
              {board?.articles?.length} {T.AiDiscoverPage.boardCard.articles}{' '}
            </Article>
          )}
        </Box>
      </Box>
      {/* Images section */}
      <Box width={308} height={13} gap={15}>
        <Box relative style={{ overflow: 'hidden' }} height={180} row>
          {Array.from({ length: 3 }).map((_, i) => {
            const indexArtBoard = board.articles.length - 1
            const articleOfBoard = board.articles[indexArtBoard - i]

            const zIndexValue = 12 - i

            if (articleOfBoard)
              return (
                <ImgContainer
                  key={i}
                  style={{
                    zIndex: zIndexValue,
                    left: `${i * 63}px`,
                  }}
                >
                  <img
                    src={articleOfBoard.image ?? asters_placeholder}
                    onError={onLoadImageError}
                    style={{
                      backgroundColor: `${AppStore.theme.o.lightGrey}`,
                      width: '180px',
                      height: '180px',
                      borderRadius: '24px',
                    }}
                    alt={`Article ${i}`}
                  />
                </ImgContainer>
              )
            else return <>{placeHolderContainer(i)}</>
          })}
        </Box>
        {/* Created by and timestamp */}
        <Box row height={13} gap={35} style={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <CreatedByText>{board.user.name}</CreatedByText>
          <CreatedByText> {getHumanReadableDate(board.date)} </CreatedByText>
        </Box>
      </Box>
    </CardDiv>
  )
}

export default PreviewBoard

const CardDiv = styled(Box)`
  padding: 24px;
  overflow: hidden;
  flex-direction: column;
  gap: 18px;
  border-radius: 18px;

  background-color: ${() => `${AppStore.theme.o.surface}`};
  width: 356px;
  height: 321px;
  filter: blur(0px) !important;
  border: 1px solid ${() => `${AppStore.theme.o.grey}`};

  &:hover {
    background-color: ${() => `${AppStore.theme.o.surface}`};
    border: 1px solid transparent;
    box-shadow: ${AppStore.theme.bigOuterShadow.slice(11)};

    .lockDiv.hovered {
      transform: translateX(0px);
    }

    .checkDiv.hovered {
      transform: translateY(0px);
    }
  }
`
const ImgContainer = styled(Box)`
  position: absolute;
  width: 180px;
  height: 180px;
  top: 0;

  img {
    border: 1.5px solid ${() => `${AppStore.theme.o.surface}`};
    object-fit: cover;
  }
`

const Article = styled.p`
  color: ${() => `${AppStore.theme.o.darkGrey}`};
  font-family: Aktiv Grotesk;
  font-size: 11.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12px;
`
const Text = styled(Box)`
  color: ${() => `${AppStore.theme.o.black}`};
  font-family: Aktiv Grotesk;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.21px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  overflow-wrap: break-word;
  display: block;
  max-width: 225px;
  border-radius: 0;
`
const CreatedByText = styled.p`
  color: ${() => `${AppStore.theme.o.black}`};
  text-align: center;
  font-family: Aktiv Grotesk;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1px;
`
const LockDiv = styled(Box)`
  padding: 4px;
  width: 32px;
  height: 32px;
  transform: translateX(47px);
  border: 1px solid ${() => `${AppStore.theme.o.grey}`};
  border-radius: 8px;
  background-color: ${() => `${AppStore.theme.o.lightGrey}`};

  svg {
    fill: ${() => `${AppStore.theme.o.black}`};

    path {
      fill: ${() => `${AppStore.theme.o.black}`};
    }
  }
`

const CheckBoxDiv = styled(Box)`
  padding: 4px;
  width: 32px;
  gap: 10px;
  border-radius: 8px;
  height: 32px;
  transform: translateY(-60px);
  background-color: ${() => `${AppStore.theme.o.lightGrey}`};
  &.selected {
    background-color: ${() => `${AppStore.theme.o.lightGreen}`}; // Cambia il colore in verde o quello che preferisci
  }
`
