import React, { useEffect } from 'react'
import { AppStore, T, __ } from 'utils'
import { Icons } from './Icons'
import { Button } from './Button'
import { Box } from './Box'

interface Props {}

export const HeaderAutopilotButton = ({}: Props) => {
  const [currentUserCredentials, setCurrentUserCredentials] = React.useState<any>(null)

  useEffect(() => {
    if (AppStore.loggedUser) {
      const fullName = AppStore.loggedUser.name
      const firstName = fullName.split(' ')[0]
      const lastName = fullName.split(' ')?.[1] ?? ''
      setCurrentUserCredentials({
        email: AppStore.loggedUser.email,
        firstName: firstName,
        lastName: lastName,
      })
    }
  }, [])

  const onOpenZapierModal = () => {
    return AppStore.openModal({
      id: 'zapier-modal',
      body: (
        <Box width={1400} height={800} style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <zapier-full-experience
            style={{ width: '1200px' }}
            sign-up-email={currentUserCredentials?.email}
            sign-up-first-name={currentUserCredentials?.firstName}
            sign-up-last-name={currentUserCredentials?.lastName}
            client-id={'6p5xQeRwPz3SiS5EiTUe2OFqX12THhlnTB4oslFt'}
            theme={AppStore.darkTheme ? 'dark' : 'light'}
            intro-copy-display="hide"
            app-selection-display="hide"
            zap-limit="0"
            zap-create-from-scratch-display="hide"
          />
        </Box>
      ),
      style: {
        minWidth: 1400,
        minHeight: 800,
      },
    })
  }
  return (
    <Button
      variant={'secondary-revert'}
      onClick={onOpenZapierModal} // da cambiare con il componente zapier
      size="smaller"
      style={{ width: 189, height: 40, fontWeight: 500 }}
    >
      <Icons.autopilotElectric style={{ marginRight: 4 }} /> {T.headerAutopilotAndModal.autopilot}
    </Button>
  )
}
