import { AnalyticsPage } from 'pages'

const AnalyticRouter = [
  {
    path: '/analytics',
    element: <AnalyticsPage />,
  },
  {
    path: '/analytics/createReport',
    element: <AnalyticsPage />,
  },
  {
    path: '/analytics/createReport/:reportId',
    element: <AnalyticsPage />,
  },
  {
    path: '/analytics/report',
    element: <AnalyticsPage />,
  },
  {
    path: '/analytics/report/:reportId',
    element: <AnalyticsPage />,
  },
]

export { AnalyticRouter }
