/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { IPostFilters, TPostType } from 'api/Reports'
import { isExternalElementClicked } from 'api/Utils'
import { Box, Button, Icons, Text } from 'components'
import { CloseButton } from 'components/UI'
import { useEffect, useRef, useState } from 'react'
import { AppStore, T } from 'utils'
import { configTypology } from './tableInterface'

/* ------------------- TYPES  */
export type TTypologies = 'post' | 'carousel' | 'story' | 'reels' | 'short' | 'post_link' | 'feed'

/* ------------------- INTERFACES  */

interface Props {
  updateValues: (value: any) => void
  provider: string
  apiCallParams?: IPostFilters
}

export const TypologyFilterTableGraph = ({ apiCallParams, updateValues, provider }: Props) => {
  /* ----------------- STATE  */
  const [openFilter, setOpenFilter] = useState<boolean>(false)
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const [selectedTypology, setSelectedTypology] = useState<TPostType[]>(apiCallParams?.content_type ?? [])
  const [firstMount, setFirstMount] = useState(true)

  /* ----------------- REF  */
  const typologyContainerRef = useRef<HTMLDivElement>(null)

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */
  const hasTypologies: boolean = selectedTypology.length > 0
  const variantType = hasTypologies ? 'secondary' : 'neutral'
  const hoveredOrOpen = isHovered || openFilter

  const typologies: { id: TPostType; label: string }[] =
    configTypology.find((item) => item.social === provider)?.typologies ?? []

  /* ----------------- METHODS  */
  const resetSelectedTypologies = (e) => {
    e.stopPropagation()
    setOpenFilter(false)
    setSelectedTypology([])
    updateValues({
      content_type: [],
    })
  }

  const handleSelectType = (e, type: TPostType) => {
    e.stopPropagation()
    if (selectedTypology.includes(type)) {
      setSelectedTypology((prev) => prev.filter((item) => item !== type))
    } else {
      setSelectedTypology((prev) => [...prev, type])
    }
  }

  /**
   * Funzione per chiude il filtro delle typology se si clicca all'esterno del contenitore
   * @param e Element where the click event was triggered
   */
  const handleClose = (e) => {
    const elToCheck = e.target as Element

    const labelRef = typologyContainerRef?.current

    if (elToCheck.classList.contains('typology_widget_filter')) return

    if (isExternalElementClicked(labelRef, elToCheck)) setOpenFilter(false)
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    if (openFilter) window.addEventListener('click', handleClose)
    return () => window.removeEventListener('click', handleClose)
  })

  useEffect(() => {
    const areValuesEqual = JSON.stringify(selectedTypology) === JSON.stringify(apiCallParams?.content_type)

    if (!openFilter && !firstMount && !areValuesEqual) {
      updateValues({
        content_type: selectedTypology,
      })
    }
    setFirstMount(false)
  }, [openFilter])

  return typologies.length > 0 ? (
    <TypologyFilterTableGraphContainer
      onClick={() => setOpenFilter((prev) => !prev)}
      variant={variantType}
      style={{
        width: hoveredOrOpen ? 210 : 56,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      forwardedRef={typologyContainerRef}
      className="typology_widget_filter"
    >
      {/* Icon */}
      <Icons.mediaSizer
        fill={hasTypologies ? AppStore.theme.o.secondary : AppStore.theme.o.black}
        className="typology_widget_filter"
      />

      {/* Title */}
      <Text
        style={{
          color: hasTypologies ? AppStore.theme.o.secondary : AppStore.theme.o.black,
          marginLeft: hoveredOrOpen ? 8 : 0,
          opacity: hoveredOrOpen ? 1 : 0,
          display: hoveredOrOpen ? 'flex' : 'none',
          transition: '0.3s ease-in-out',
        }}
        classes="typology_widget_filter"
      >
        {T.singleWord.typology}
      </Text>

      {/* Arrow dropdown */}
      <Icons.dropdownArrow
        fill={hasTypologies ? AppStore.theme.o.secondary : AppStore.theme.o.black}
        style={{
          transform: openFilter ? 'rotate(180deg)' : 'rotate(0deg)',
          transition: '0.3s ease-in-out',
          marginLeft: 'auto',
          display: hoveredOrOpen ? 'flex' : 'none',
        }}
        className="typology_widget_filter"
      />

      {/* Dropdown */}
      {openFilter && (
        <DropdownContainer onClick={(e) => e.stopPropagation()} removeHover>
          {typologies.map((type) => (
            <DropDownItem
              key={type.id}
              onClick={(e) => handleSelectType(e, type.id)}
              selected={selectedTypology.includes(type.id)}
            >
              {type.label}

              <Ball className="type_ball_selected" />
            </DropDownItem>
          ))}
        </DropdownContainer>
      )}

      {/* Remove selected "X" */}
      {hasTypologies && <CloseButton action={resetSelectedTypologies} />}
    </TypologyFilterTableGraphContainer>
  ) : null
}

const TypologyFilterTableGraphContainer = styled(Button)`
  display: flex;
  align-items: center;
  padding: 8px 16px !important;
  min-height: 40px !important;
  height: 40px !important;
  position: relative;
`

const DropdownContainer = styled(Box)`
  position: absolute;
  top: calc(100% + 8px);
  width: 210px;
  border-radius: 14px;
  padding: 8px;
  border: 1px solid ${() => AppStore.theme.o.lightGrey};
  background: ${() => AppStore.theme.o.surface};
  box-shadow: ${() => AppStore.theme.smallBoxShadow};
  gap: 2px;
  z-index: 100;
`

const DropDownItem = styled(Box)<{ selected: boolean }>`
  padding: 16px;
  height: 42px;
  color: ${() => AppStore.theme.o.black};
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border: 1px solid transparent;

  .type_ball_selected {
    background: ${() => AppStore.theme.o.lightGrey};
  }

  &:hover {
    background: ${() => AppStore.theme.o.lightGrey};

    .type_ball_selected {
      background: ${() => AppStore.theme.o.grey};
    }
  }

  ${(props) =>
    props.selected &&
    `
       border-color: ${AppStore.theme.o.lightGrey};

    .type_ball_selected {
      background: ${AppStore.theme.o.secondary} !important; 
    }
  `}
`

const Ball = styled(Box)`
  &.type_ball_selected {
    width: 12px;
    height: 12px;
  }
`
