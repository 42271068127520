import { Box } from 'components'
import { Header } from 'components/Header'
import { ReactNode } from 'react'
import { AppStore } from 'utils'
import Background from './Background'
import { SubscriptionChecker } from './SubscriptionChecker'
import { NotPermissionPage, TPage } from './Comments/NotPermission'

interface Props {
  checkPermission?: TPage
  title?: string
  children?: any
  noScroll?: boolean
  customButton?: ReactNode
  noHeader?: boolean
  style?: React.CSSProperties
}

export function Page({ style, noHeader, children, noScroll, title, customButton, checkPermission }: Props) {
  /* ------------------- VARIABLES  */
  // View page permissions
  const permission = checkPermission ? AppStore.limits[checkPermission] : true

  return (
    <Box
      flex
      style={{
        height: '100vh',
        overflow: 'hidden',
        ...style,
      }}
      borderRadius={0}
    >
      <SubscriptionChecker />
      <Box
        flex
        borderRadius={0}
        bgPrimaryColor
        id={title + '-page'}
        style={
          noScroll
            ? {}
            : {
                backgroundColor: AppStore.theme.o.background,
                overflow: 'auto',
                overflowX: 'hidden',
              }
        }
      >
        <Background visible={title === 'Post'} />

        <Box
          borderRadius={0}
          flex
          pt={8}
          pb={8}
          pl={noHeader ? 0 : 105}
          pr={noHeader ? 0 : 90}
          shadow={AppStore.theme.mainShadow}
          style={{ backgroundColor: AppStore.theme.o.background, overflow: 'hidden' }}
        >
          {/* HEADER */}
          {!noHeader && <Header customButton={customButton} title={title} />}

          {/* No permissions page */}
          {checkPermission && !permission && <NotPermissionPage page={checkPermission} />}

          {/* Page content */}
          {permission && (
            <Box
              animation="fade"
              flex
              pl={noHeader ? 0 : 3}
              pr={noHeader ? 0 : 15}
              style={{
                overflowY: 'hidden',
                overflowX: 'scroll',
              }}
            >
              {children}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}
