/* ___________________ IMPORTS ___________________ */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { AppStore } from 'utils'

interface Props {
  commentsLength: number
}

const InfoComments = ({ commentsLength }: Props) => {
  return (
    <InfoCommentsContainer>
      <Icons.commentsWithPoints />
      {commentsLength > 99 ? '99+' : commentsLength}
    </InfoCommentsContainer>
  )
}

export default InfoComments

const InfoCommentsContainer = styled(Box)`
  background-color: ${() => AppStore.theme.o.surface};
  color: ${() => AppStore.theme.o.black};

  min-width: 66px;
  max-height: 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.48px;
  letter-spacing: 0.32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 4px 16px;
  border: 1px solid ${() => AppStore.theme.o.grey};
  border-radius: 8px;
  z-index: 10;

  svg {
    path {
      fill: ${() => AppStore.theme.o.black};
    }
  }
`
