/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { SocialType } from 'api/Utils'
import { Box, SocialIcon } from 'components'
import { capitalizeFirstLetter } from 'utils'

/* ------------------- INTERFACES  */
interface Props {
  provider: SocialType
  active?: boolean
  margin?: number | string
}

export const SocialCardName = ({ provider, active = true, margin }: Props) => {
  return (
    <SocialCardNameContainer style={{ margin: margin }}>
      <SocialIcon social={provider} active={active} style={{ width: 64, height: 64 }} />
      {capitalizeFirstLetter(provider)}
    </SocialCardNameContainer>
  )
}

const SocialCardNameContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  font-weight: 700;
  font-size: 21px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
`
