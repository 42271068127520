/* ******************* IMPORT ******************* */
import { useState } from 'react'
import styled from '@emotion/styled'
import { Box, Text } from 'components'
import { AppStore, T } from 'utils'
import { ExtraTitle } from '../GenericExtras/ExtraTitle'

/* ******************* INTERFACE ******************* */
interface Props {
  onBrandedOptionsClick: (value) => void
  isSelfOnlySelected: boolean
}

export const DiscloseOptions = ({ onBrandedOptionsClick, isSelfOnlySelected }: Props) => {
  /* ____________________ STATE ____________________ */
  const [value, setValue] = useState<boolean>(false)
  const [yourBrand, setYourBrand] = useState<boolean>(false)
  const [isBrandedContent, setIsBrandedContent] = useState<boolean>(false)

  /* ____________________ METHODS ____________________ */
  const handleDisclosePostContent = () => {
    setValue(!value)
    setYourBrand(false)
    setIsBrandedContent(false)
    onBrandedOptionsClick(false)
  }

  const handleYourBrand = () => {
    setYourBrand(!yourBrand)
  }

  const handleBrandedContent = () => {
    setIsBrandedContent(!isBrandedContent)
    onBrandedOptionsClick(!isBrandedContent)
  }

  return (
    <ContainerDiscloseOptions>
      <DiscloseOptionHeader>
        <Title>
          <ExtraTitle text={T.postPage.tiktokExtra.discloseVideoContent} />

          <Box
            style={{
              width: 27,
              height: 16,
              backgroundColor: value ? AppStore.theme.o.green : AppStore.theme.o.grey,
            }}
            onClick={handleDisclosePostContent}
          >
            <Box
              style={{
                width: 16,
                height: 16,
                border: `1px solid ${value ? AppStore.theme.o.green : AppStore.theme.o.darkGrey}`,
                backgroundColor: AppStore.theme.o.surface,
                x: value ? 11 : 0,
              }}
            />
          </Box>
        </Title>

        <Text fontSize={14} color={AppStore.theme.o.darkGrey}>
          {T.postPage.tiktokExtra.letOthersKnow}
        </Text>
      </DiscloseOptionHeader>

      {value && (
        <DiscloseOptionsChoices>
          <DiscloseChoice>
            <ChoiceHeader>
              <ChoiceTitle>{T.postPage.tiktokExtra.yourBrand}</ChoiceTitle>
              <Box
                style={{
                  width: 27,
                  height: 16,
                  backgroundColor: yourBrand ? AppStore.theme.o.green : AppStore.theme.o.grey,
                }}
                onClick={handleYourBrand}
              >
                <Box
                  style={{
                    width: 16,
                    height: 16,
                    border: `1px solid ${yourBrand ? AppStore.theme.o.green : AppStore.theme.o.darkGrey}`,
                    backgroundColor: AppStore.theme.o.surface,
                    x: yourBrand ? 11 : 0,
                  }}
                />
              </Box>
            </ChoiceHeader>

            <ChoiceDescription style={{ opacity: yourBrand ? 1 : 0.5 }}>
              {T.postPage.tiktokExtra.promoteYourselfOrBusiness}
            </ChoiceDescription>
          </DiscloseChoice>

          <DiscloseChoice style={{ opacity: isSelfOnlySelected ? 0.6 : 1 }}>
            <ChoiceHeader>
              <ChoiceTitle>{T.postPage.tiktokExtra.brandedContent}</ChoiceTitle>
              <BrandedContentCheckBoxWrapper className={isSelfOnlySelected ? 'disabled' : ''}>
                <BrandedContentCheckBox
                  className={isSelfOnlySelected ? 'disabled' : ''}
                  style={{
                    width: 27,
                    height: 16,
                    backgroundColor: isBrandedContent ? AppStore.theme.o.green : AppStore.theme.o.grey,
                  }}
                  onClick={handleBrandedContent}
                >
                  <Box
                    style={{
                      width: 16,
                      height: 16,
                      border: `1px solid ${isBrandedContent ? AppStore.theme.o.green : AppStore.theme.o.darkGrey}`,
                      backgroundColor: AppStore.theme.o.surface,
                      x: isBrandedContent ? 11 : 0,
                    }}
                  />
                </BrandedContentCheckBox>
              </BrandedContentCheckBoxWrapper>
            </ChoiceHeader>

            <ChoiceDescription style={{ opacity: isBrandedContent ? 1 : 0.5 }}>
              {isSelfOnlySelected ? T.postPage.tiktokExtra.toDisclose : T.postPage.tiktokExtra.inPaidPartnership}
            </ChoiceDescription>
          </DiscloseChoice>
        </DiscloseOptionsChoices>
      )}
    </ContainerDiscloseOptions>
  )
}

export default DiscloseOptions

const BrandedContentCheckBoxWrapper = styled(Box)`
  &.disabled {
    cursor: not-allowed;
  }
`

const BrandedContentCheckBox = styled(Box)`
  cursor: pointer;

  &.disabled {
    pointer-events: none;
  }
`
const ChoiceTitle = styled((props) => <Text {...props} />)`
  font-size: 16px;
  font-weight: 500 !important;
  color: ${() => AppStore.theme.o.black};
`

const ChoiceDescription = styled(Box)`
  font-size: 13.5px;
  color: ${() => AppStore.theme.o.black};
`

const ChoiceHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

const DiscloseChoice = styled(Box)``

const DiscloseOptionsChoices = styled(Box)`
  gap: 18px;
  background-color: ${() => AppStore.theme.o.lightestGrey};
  padding: 20px 15px;
`

const Title = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

const DiscloseOptionHeader = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 12px;
`

const ContainerDiscloseOptions = styled(Box)`
  gap: 10px;
  width: 100%;
`
