import { Page } from 'components'
import { AppStore, T } from 'utils'
import { MediaContainerComp } from './MediaContainer'

export const MediaPage = (props) => {
  const { noHeader } = props

  return (
    <Page noHeader={noHeader} title={T.mediaPage.media} checkPermission="media">
      <MediaContainerComp style={{ marginTop: 10, boxShadow: AppStore.theme.mainShadow }} />
    </Page>
  )
}
