/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { AppStore } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  isActive: boolean
}

export const TogglerUI = ({ isActive }: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <TogglerContainer>
      <Ball isActive={isActive} />
    </TogglerContainer>
  )
}

const TogglerContainer = styled(Box)`
  height: 20px;
  width: 40px;
  border-radius: 11.79px;
  position: relative;
  border: 1px solid ${() => AppStore.theme.o.grey};
  background-color: ${() => AppStore.theme.o.lightGrey};
`

const Ball = styled(Box)<{ isActive: boolean }>`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 1px solid ${() => AppStore.theme.o.lightGrey};
  position: absolute;
  background-color: ${() => AppStore.theme.o.black};

  top: 50%;
  transform: translateY(-50%);

  ${({ isActive }) =>
    isActive
      ? `
    right: 2px;

  `
      : `
    left: 2px;

  `}
`
