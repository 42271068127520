/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { PreviewResult } from '../../GenericComponents/PreviewResults'
import { TextContainer } from '../../GenericComponents/TextContainer'
import { ResultsPreviewTiktok } from './ResultsPreviewTiktok'

/* ------------------- INTERFACES  */
interface Props {}

export const Tiktok = ({}: Props) => {
  return (
    <TiktokContainer>
      <PreviewResult component={<ResultsPreviewTiktok />} />
      <TextContainer />
    </TiktokContainer>
  )
}

const TiktokContainer = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
`
