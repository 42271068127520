/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { commonToAll } from 'translation/commonToAll'
import { AppStore, T } from 'utils'

/* ------------------- INTERFACES  */
interface Props {}

export const StatusTooltip = ({}: Props) => {
  return (
    <StatusTooltipContainer>
      <GeneralDescription>
        <TitleGeneralDescription>AI Brand Voice</TitleGeneralDescription>

        <div>
          {T.textGenerator.brandVoiceTooltip1} <br />
          {T.textGenerator.brandVoiceTooltip2}{' '}
          <b>
            {commonToAll.socials.facebook}, {commonToAll.socials.instagram} {T.singleWord.and}{' '}
            {commonToAll.socials.linkedin}.
          </b>
        </div>
      </GeneralDescription>

      <SpecificDescription>
        <SpecificDescriptionContainer>
          <IconContainer>
            <Icons.refresh fill={AppStore.theme.o.black} />
          </IconContainer>

          <InfoContainer>
            <TitleDescription>Step 1: Learning mode</TitleDescription>

            <TextDescription>{T.textGenerator.brandVoiceTooltip3}</TextDescription>
          </InfoContainer>
        </SpecificDescriptionContainer>

        <DottedLine>
          <Icons.dottedLine fill={AppStore.theme.o.grey} />
        </DottedLine>

        <SpecificDescriptionContainer>
          <IconContainer>
            <Icons.sun fill={AppStore.theme.o.black} />
          </IconContainer>

          <InfoContainer>
            <TitleDescription>Step 2: Tuning mode</TitleDescription>

            <TextDescription>{T.textGenerator.brandVoiceTooltip4}</TextDescription>
          </InfoContainer>
        </SpecificDescriptionContainer>
      </SpecificDescription>
    </StatusTooltipContainer>
  )
}

const StatusTooltipContainer = styled(Box)`
  width: 666px;
  padding: 32px 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 14px;
  background: ${() => AppStore.theme.o.surface};
  z-index: 100;

  /* DropShadows/LightMode/Main */
  box-shadow: 0px 0px 20px 0px rgba(99, 99, 99, 0.12);
`

const GeneralDescription = styled(Box)`
  text-align: start;
  color: ${() => AppStore.theme.o.black};
  font-family: 'Aktiv Grotesk';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
`

const TitleGeneralDescription = styled(Box)`
  color: ${() => AppStore.theme.o.green};
  font-family: 'Aktiv Grotesk';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
`

const SpecificDescription = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: space-between;
  position: relative;
`

const SpecificDescriptionContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`

const TitleDescription = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  font-family: 'Aktiv Grotesk';
  font-size: 12px;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
`

const TextDescription = styled(Box)`
  color: ${() => AppStore.theme.o.darkGrey};
  font-family: 'Aktiv Grotesk';
  font-size: 10px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
`

const InfoContainer = styled(Box)`
  width: 522px;
  gap: 8px;
`

const IconContainer = styled(Box)`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 4px;
  border-radius: 14px;
  border: 0px solid ${() => AppStore.theme.o.grey};
  background: ${() => AppStore.theme.o.lightestGrey};
  width: 52px;
  height: 36px;
`

const DottedLine = styled(Box)`
  position: absolute;
  top: 42px;
  left: 25px;
`
