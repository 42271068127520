/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { AppStore } from 'utils'

/* ------------------- INTERFACES  */
interface Props {
  message: string
}

export const NoResultElement = ({ message }: Props) => {
  return <NoResultElementContainer>{message}</NoResultElementContainer>
}

const NoResultElementContainer = styled(Box)`
  justify-content: center;
  align-items: center;
  background-color: ${() => AppStore.theme.o.lightGrey};
  color: ${() => AppStore.theme.o.black};
  height: 38px;
  width: 100%;
  font-style: normal;
  font-weight: 700;
  margin-top: 16px;
`
