import { Box } from 'components'
import { SocialIconsProvider } from 'components/SocialIcon'
import { SocialAccount } from 'types'
import { SpecialSocialAccountRow } from './SpecialSocialAccountRow'

interface Props {
  social: SocialIconsProvider
  socialAccounts: SocialAccount[]
  onClick: () => void
  onSocialAccountClick?: (account: SocialAccount) => void
  onDeleteAccount?: (account: SocialAccount) => Promise<void>
}

export const SocialProvider = ({ onClick, onSocialAccountClick, social, socialAccounts, onDeleteAccount }: Props) => (
  <Box>
    {socialAccounts.map((account, index) => (
      <SpecialSocialAccountRow
        style={{ marginTop: 10 }}
        key={index}
        account={account}
        onClick={onSocialAccountClick}
        onDelete={onDeleteAccount}
      />
    ))}
  </Box>
)
