/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { useState } from 'react'
import { IPostVariant } from 'types/PostInterface'
import { ExtraTitle } from '../GenericExtras/ExtraTitle'
import { AppStore, T } from 'utils'
import { updatePost } from 'utils/PostUtils/Post'

/* ------------------- INTERFACES  */
interface Props {
  post: IPostVariant
}

export const YouTubeAudience = ({ post }: Props) => {
  /* ----------------- STATE  */
  const [isSuitableForChildren, setIsSuitableForChildren] = useState<boolean>(post.data.adultOnly ?? false)

  /* ----------------- VARIABLES  */
  const { variantId } = post

  /* ----------------- METHODS  */
  const handleIsSuitableForChildren = () => {
    setIsSuitableForChildren((prev) => {
      updatePost(variantId, { adultOnly: !prev })
      return !prev
    })
  }

  return (
    <YouTubeAudienceContainer>
      <ExtraTitle text={T.postPage.youtubeTubeExtra.audience} />

      <OptionContainer
        style={{
          backgroundColor: !isSuitableForChildren ? AppStore.theme.o.lightGreen : AppStore.theme.o.lightestGrey,
          border: `1px solid ${!isSuitableForChildren ? AppStore.theme.o.green : AppStore.theme.o.grey}`,
        }}
        onClick={handleIsSuitableForChildren}
      >
        <OptionText style={{ color: !isSuitableForChildren ? AppStore.theme.o.green : AppStore.theme.o.black }}>
          {T.postPage.youtubeTubeExtra.isSuitableForChildren}
        </OptionText>
        <Box
          style={{
            width: 27,
            height: 16,
            backgroundColor: !isSuitableForChildren ? AppStore.theme.o.green : AppStore.theme.o.grey,
          }}
        >
          <Box
            style={{
              width: 16,
              height: 16,
              border: `1px solid ${!isSuitableForChildren ? AppStore.theme.o.green : AppStore.theme.o.darkGrey}`,
              backgroundColor: AppStore.theme.o.surface,
              x: !isSuitableForChildren ? 11 : 0,
            }}
          />
        </Box>
      </OptionContainer>
    </YouTubeAudienceContainer>
  )
}

const YouTubeAudienceContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const OptionContainer = styled(Box)`
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  flex: 1;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

const OptionText = styled.p`
  font-weight: 500;
  line-height: normal;
  /* margin-right: 8px; */
  margin-right: auto;
  /* border: 1px solid black; */
`

const Title = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`
