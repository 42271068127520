/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { SocialUser } from 'components/UI'
import { IPostVariant } from 'types/PostInterface'
import { getFirstMedia, updatePost } from 'utils/PostUtils/Post'
import { PreviewVideoContainer } from '../GenericExtras/PreviewVideoContainer'
import { useState } from 'react'
import { ExtraOptions } from './ExtraOptions'
import DiscloseOptions from './DiscloseOptions'
import GeneralPrivacyOptions from '../GenericExtras/GeneralPrivacyOptions'
import { T } from 'utils'
import { TIKTOKPRIVACY } from 'utils/Constants/Constants'

/* ------------------- INTERFACES  */
interface Props {
  post: IPostVariant
}

export const TiktokExtrasContainer = ({ post }: Props) => {
  const { picture, provider, name } = post.info.account

  const { medias } = post.data
  const { type } = post.info

  //TODO: Adesso messo veloce , però da vedere come fare emglio
  const [title, setTitle] = useState('private')
  const [isBrandedContentSelected, setIsBrandedContentSelected] = useState(false)
  const [isSelfOnlySelected, setIsSelfOnlySelected] = useState(false)

  const handleBrandedContentClick = (newIsBrandedContentSelected: boolean) => {
    setIsBrandedContentSelected(newIsBrandedContentSelected)
  }

  const handlePrivacySelected = (value: string) => {
    // Aggiorna lo stato del post con il nuovo livello di privacy
    const newIsSelfOnlySelected = value === 'SELF_ONLY'
    setIsSelfOnlySelected(newIsSelfOnlySelected)
  }
  // Trasforma l'oggetto in un array di opzioni
  const tiktokPrivacyOptions = Object.entries(TIKTOKPRIVACY()).map(([value, label]) => ({ value, label }))

  return (
    <TiktokExtrasContainerContainer>
      <SocialUser picture={picture} provider={provider} name={name} />

      <ExtrasContent>
        <GeneralPrivacyOptions
          post={post}
          privacyOptions={tiktokPrivacyOptions}
          onSelectOption={handlePrivacySelected}
          disabledOptions={isBrandedContentSelected ? ['SELF_ONLY'] : []}
          titleText={T.postPage.tiktokExtra.whoCanWatchThisVideo}
        />
        {/* -------------------- PREVIEW VIDEO PER COPERTINA TIK TOK */}
        {medias && medias.length > 0 && getFirstMedia(medias, 'video') && (
          <PreviewVideoContainer post={post} filterVideoUrl={getFirstMedia(medias, 'video')} />
        )}

        <ExtraOptions post={post} />

        <DiscloseOptions onBrandedOptionsClick={handleBrandedContentClick} isSelfOnlySelected={isSelfOnlySelected} />
      </ExtrasContent>
    </TiktokExtrasContainerContainer>
  )
}

const TiktokExtrasContainerContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 40px;
`

const ExtrasContent = styled(Box)`
  width: 100%;
  max-width: 541px;
  flex-direction: column;
  gap: 24px;
  border: none;
`
