/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { AppStore, T } from 'utils'
import {
  INFINITE_SYMBOL,
  OWNER_COLLABORATORS_LIMITS_INFINITE,
  OWNER_COPIES_INFINITE,
  OWNER_POST_LIMITS_INFINITE,
  OWNER_SOCIAL_ACCOUNTS_LIMITS_INFINITE,
} from 'utils/Constants/LimitUserConstants'
import { OrganizationCard } from './OrganizationCard'
import Utils from 'api/Utils'

/* ------------------- INTERFACES  */
interface IColors {
  background: string
  text: string
}

interface IOrganizationConfig {
  name: string
  value: string
  icon: JSX.Element
  limits: {
    available: number
    used: number
    infinite: boolean
  }
}
interface Props {
  usedCopies: number
  availableCopies: number

  usedCollaborators: number
  availableCollaborators: number

  usedPosts: number
  availablePosts: number

  usedSocials: number
  availableSocials: number
}

export const DashboardOrganization = ({
  usedCopies,
  availableCopies,
  usedCollaborators,
  availableCollaborators,
  usedPosts,
  availablePosts,
  usedSocials,
  availableSocials,
}: Props) => {
  /* --------------- ENUM */
  const enumColors = {
    green: {
      background: AppStore.theme.o.secondary_container,
      text: AppStore.theme.o.secondary,
    },
    orange: {
      background: AppStore.theme.o.warning_container,
      text: AppStore.theme.o.warning,
    },
    red: {
      background: AppStore.theme.o.error_container,
      text: AppStore.theme.o.error,
    },
  }

  /* --------------- METHODS */
  const getColorsFromQuantity = (available: number | string, used: number | string, infinite: boolean): IColors => {
    const availableNum = Number(available)
    const usedNum = Number(used)

    if (used === undefined) return enumColors.green

    if (infinite) return enumColors.green

    if (usedNum < availableNum * 0.9) return enumColors.green

    if (usedNum < availableNum * 1) return enumColors.orange

    return enumColors.red
  }

  /* --------------- CONFIG */
  const organizationConfig: IOrganizationConfig[] = [
    {
      name: T.DashboardPage.socialPosts,
      value: `${usedPosts}/${OWNER_POST_LIMITS_INFINITE ? INFINITE_SYMBOL : availablePosts} `,
      icon: <Icons.happyPost />,
      limits: {
        available: availablePosts,
        used: usedPosts,
        infinite: OWNER_POST_LIMITS_INFINITE,
      },
    },
    {
      name: T.DashboardPage.connectedSocials,
      value: `${usedSocials}/${OWNER_SOCIAL_ACCOUNTS_LIMITS_INFINITE ? INFINITE_SYMBOL : availableSocials}`,
      icon: <Icons.person />,
      limits: {
        available: availableSocials,
        used: usedSocials,
        infinite: OWNER_SOCIAL_ACCOUNTS_LIMITS_INFINITE,
      },
    },
    {
      name: T.DashboardPage.generatedText,
      value: `${usedCopies}/${OWNER_COPIES_INFINITE ? INFINITE_SYMBOL : availableCopies}`,
      icon: <Icons.generation />,
      limits: {
        available: availableCopies,
        used: usedCopies,
        infinite: OWNER_COPIES_INFINITE,
      },
    },
    {
      name: T.DashboardPage.teamMember,
      value: `${usedCollaborators}/${OWNER_COLLABORATORS_LIMITS_INFINITE ? INFINITE_SYMBOL : availableCollaborators}`,
      icon: <Icons.collaborators />,
      limits: {
        available: availableCollaborators,
        used: usedCollaborators,
        infinite: OWNER_COLLABORATORS_LIMITS_INFINITE,
      },
    },
  ]

  return (
    <DashboardOrganizationContainer>
      {organizationConfig.map((data) => (
        <OrganizationCard
          key={Utils.generateRandomUIDKey()}
          colors={getColorsFromQuantity(data.limits.available, data.limits.used, data.limits.infinite)}
          name={data.name}
          value={data.limits.used === undefined ? undefined : data.value}
          icon={data.icon}
        />
      ))}
    </DashboardOrganizationContainer>
  )
}

const DashboardOrganizationContainer = styled(Box)`
  padding: 32px;
  width: 1158px;
  height: 532px;
  border-radius: 14px;
  border: 2px solid ${() => AppStore.theme.o.lightGrey};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
`
