import { mergeWithDefaultOptions } from '../defaultChartOptions'
import { HighchartsAsters } from '../highchartsAsters'

export const geomapOptions: Highcharts.Options = mergeWithDefaultOptions({
  series: [
    {
      name: 'Regions',
      // data: [
      //   {
      //     'hc-key': 'us', // Country code or ID
      //     value: 100, // Value for the country
      //   },
      //   {
      //     'hc-key': 'ca',
      //     value: 50,
      //   },
      //   {
      //     'hc-key': 'mx',
      //     value: 75,
      //   },
      // ],

      dataLabels: {
        enabled: true,
        format: '{point.name}',
      },
    },
  ] as Highcharts.SeriesOptionsType[],
  credits: {
    enabled: false,
  },
})
