/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Tracker } from 'api/Tracker'
import { Box } from 'components'
import { PageStructure } from 'components/UI'
import { useEffect } from 'react'
import { AppStore } from 'utils'
import { TRACKEREVENTS } from 'utils/eventsTracker'
import { DraftContent } from './DraftContent/DraftContent'
import { DraftHeader } from './DraftHeader/DraftHeader'
import { DraftSidebar } from './DraftSidebar/DraftSidebar'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {}

export const DraftModal = ({}: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */
  const from = window.location.pathname

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */
  // Set modal container to full width and height per rimuovere scrollbar automatica and mixpanel event
  useEffect(() => {
    const modalContainer = document.getElementsByClassName('ant-modal-wrap ant-modal-centered')
    modalContainer[0].setAttribute('style', 'overflow:hidden')

    // Mixpanel
    Tracker.trackEvent(TRACKEREVENTS.DRAFT_OPENED, {
      $current_url: from,
    })
  }, [])

  return (
    <AbbalabaModalContainer>
      <DraftHeader />

      <Box height={790}>
        <PageStructure
          sidebar={<DraftSidebar />}
          content={<DraftContent />}
          gap={24}
          styles={{
            paddingTop: 0,
          }}
        />
      </Box>
    </AbbalabaModalContainer>
  )
}

const AbbalabaModalContainer = styled(Box)`
  height: 893px;
  max-height: 893px;
  flex: 1;
  width: 100%;
  padding-right: 10px;
  padding-left: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  gap: 24px;
  background-color: ${() => AppStore.theme.o.background};
`
