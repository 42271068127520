import NoDataFoundLottie from 'assets/no_data_found.json'
import Lottie from 'react-lottie'

const NoDataFoundOptions = {
  loop: true,
  autoplay: true,
  animationData: NoDataFoundLottie,
}
interface Props {
  width?: number | string
  height?: number | string
  margin?: number | string
}
export const LottieSadSearch = ({ width, height, margin }: Props) => {
  return (
    <Lottie
      isClickToPauseDisabled
      options={NoDataFoundOptions}
      width={width ? width : '500px'}
      height={height ? height : '500px'}
      style={{
        margin: margin ? margin : '0',
        zIndex: 2,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
      }}
    />
  )
}
