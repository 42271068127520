/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Text } from 'components'
import { truncateText } from 'pages/ai-discover/commonFunction'
import { SelectButton } from './SelectButton'
import { AppStore } from 'utils'
import { useState } from 'react'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  selected: boolean
  action: () => void
  title: string
  icon?: JSX.Element
  styles?: any
}

export const SelectCard = ({ selected, action, title, icon, styles }: Props) => {
  /* ----------------- STATE  */
  const [hover, setHover] = useState<boolean>(false)

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <SelectCardContainer
      style={{ ...styles }}
      onClick={action}
      selected={selected}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Box row center gap={8}>
        {icon && <IconContainer selected={selected}>{icon}</IconContainer>}

        <CardText selected={selected}>{truncateText(title, 12)}</CardText>
      </Box>

      <SelectButton action={action} selected={selected} containerIsHover={hover} />
    </SelectCardContainer>
  )
}

const SelectCardContainer = styled(Box)<{ selected: boolean }>`
  transition: 200ms ease-in-out;
  width: 100%;
  padding: 2px 8px 2px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 14px;
  border: 1px solid transparent;

  border-color: ${({ selected }) =>
    selected ? () => AppStore.theme.o.on_secondary_container : () => AppStore.theme.o.lightGrey};
  background: ${({ selected }) =>
    selected ? () => AppStore.theme.o.secondary_container : () => AppStore.theme.o.background};

  &:hover {
    background: ${({ selected }) =>
      selected ? () => AppStore.theme.o.secondary_container : () => AppStore.theme.o.lightGrey};

    border-color: ${({ selected }) => (selected ? () => 'transparent' : () => AppStore.theme.o.lightGrey)};
  }
`

const IconContainer = styled(Box)<{ selected: boolean }>`
  padding: 6px;
  width: 32px;
  height: 32px;
  border-radius: 50%;

  & > svg {
    fill: ${({ selected }) =>
      selected ? () => AppStore.theme.o.on_secondary_container : () => AppStore.theme.o.black};
  }
`

const CardText = styled(Box)<{ selected: boolean }>`
  font-family: 'Aktiv Grotesk';
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  letter-spacing: 0.14px;
  color: ${({ selected }) =>
    selected ? () => AppStore.theme.o.on_secondary_container : () => AppStore.theme.o.black} !important;
`
