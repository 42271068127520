import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { AppStore } from 'utils'
import { Box } from './Box'

interface Props {
  values: any[]
  onChange?: (value: string, index: number) => Promise<void> | void
  style?: React.CSSProperties
  cellStyle?: React.CSSProperties
  preselectedIndex?: number
  id?: string
  scontoTag?: boolean
  forceSelect?: boolean | undefined
}

export const MultiToggleButton = ({
  values,
  onChange,
  style,
  cellStyle,
  preselectedIndex = 0,
  id = 'multi-toggle-button',
  forceSelect,
}: Props) => {
  const [selected, setSelected] = useState(preselectedIndex)

  const onSelect = (item, index) => {
    onChange?.(item, index)
    setSelected(index)
  }
  useEffect(() => {
    // Update the document title using the browser API
    if (forceSelect) setSelected(preselectedIndex)
  })

  return (
    <Container id={id} style={style} flex>
      <Box vcenter flex row gap={12}>
        {values.map((item, index) => (
          <IconContainer
            hideBoxShadowOnHover
            key={index}
            onClick={() => onSelect(item, index)}
            imSelected={selected === index}
            center
            style={{
              ...cellStyle,
              fontSize:"38px"
            }}
          >
            <MyGrid imSelected={selected === index}>{item}</MyGrid>
          </IconContainer>
        ))}
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  background-color: ${() => AppStore.theme.o.lightGrey};
  border-radius: 14px;
  min-height: 52px;
`

const IconContainer = styled(Box)<{ imSelected: any }>`
  padding: 8px 32px;
  margin: 4px 8px;
  border-radius: 14px;
  transition: background-color 0.5s ease-in-out;
  background-color: ${({ imSelected }) => (imSelected ? AppStore.theme.o.background : 'transparent')};
  /* width: 42px; */
  :hover {
    background-color: ${() => AppStore.theme.o.background};
  }
`

const MyGrid = styled(Box)<{ imSelected: any }>`
  font-weight: ${({ imSelected }) => (imSelected ? '800' : 'bold')};
  font-size: 15px;
  color: ${({ imSelected }) => (imSelected ? AppStore.theme.o.black : AppStore.theme.o.darkGrey)};
`
