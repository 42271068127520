/* ------------------- IMPORTS */
import { Box, Icons } from 'components'
import { AiTextSettings } from '../AiTextComponents/AiTextSettings'
import { AiTextResults } from '../AiTextComponents/AiTextResults'
import { AiLinkSettings } from '../AiLinkComponents/AiLinkSettings'
import { AiLinkResults } from '../AiLinkComponents/AiLinkResults'
import { TSubsection } from 'types/CreatePostInferfaces'
import { useLocation, useNavigate } from 'react-router-dom'
import { EmptyState } from 'components/UI'
import { T } from 'utils'

interface Props {
  isBrandVoiceActive: boolean
  canUseBrandVoice: boolean | undefined
  BRANDVOICE_ACTIVATED: boolean
}

export const AnimationContainer = ({ isBrandVoiceActive, canUseBrandVoice, BRANDVOICE_ACTIVATED }: Props) => {
  /* ------------------- ROUTER  */
  const navigate = useNavigate()
  const location = useLocation()

  /* ------------------- VARIABLES  */
  const subsection = (location.pathname.split('/')[3] ?? 'basic') as TSubsection

  return (
    <Box
      style={{
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '0px',
        maxHeight: '100%',
        height: '100%',
        minHeight: '100%',
      }}
    >
      {/* Generazione tramite TEXT */}
      <Box
        style={{
          position: 'absolute',
          transform: `translateX(${subsection === 'astersAiText' ? 0 : -1000}px)`,
          zIndex: 99,
          width: '100%',
          gap: 24,
          maxHeight: '100%',
          height: '100%',
          transition: '.4s ease',
        }}
        id={'Text'}
      >
        {/* Quando siamo su "Neutro" */}
        {!isBrandVoiceActive && (
          <>
            <AiTextSettings isBrandVoiceActive={isBrandVoiceActive} />
            <AiTextResults />
          </>
        )}

        {/*Quando è attivo brand voice e  non ha brand voice attivo nel piano */}
        {isBrandVoiceActive && !BRANDVOICE_ACTIVATED && (
          <EmptyState
            text={T.error.brand_voice_not_included}
            icon={<Icons.sadFaceWithPen />}
            textButton={T.settings.do_upgrade}
            action={() => navigate('/settings/subscription')}
            noPaddingTop
          />
        )}

        {/* Quando è attivo brand voice e brand voice è attivo nel piano, ma non siamo sui social in cui è possibile utilizzarlo */}
        {isBrandVoiceActive && BRANDVOICE_ACTIVATED && !canUseBrandVoice && (
          <EmptyState text={T.error.brand_voice_requirements} icon={<Icons.magicWand />} noPaddingTop />
        )}

        {/* Quando è attivo brand voice e brand voice è attivo nel piano, e siamo sui social in cui è possibile utilizzarlo */}
        {isBrandVoiceActive && BRANDVOICE_ACTIVATED && canUseBrandVoice && (
          <>
            <AiTextSettings isBrandVoiceActive={isBrandVoiceActive} />
            <AiTextResults />
          </>
        )}
      </Box>

      {/* Generazione tramite LINK */}
      <Box
        style={{
          position: 'absolute',
          transform: ` translateX(${subsection === 'astersAiText' ? 1000 : 0}px)`,
          zIndex: 99,
          width: '100%',
          gap: 24,
          maxHeight: '100%',
          height: '100%',
          transition: '.4s ease',
        }}
        id={'Link'}
      >
        <AiLinkSettings />
        <AiLinkResults />
      </Box>
    </Box>
  )
}
