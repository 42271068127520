/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { TextCharsCount } from './TextCharsCount'
import { ReadingTime } from './ReadingTime'
import { TextDifficulty } from './TextDifficulty'

/* ------------------- INTERFACES  */
interface Props {}

export const TextPostInfo = ({}: Props) => {
  return (
    <TextPostInfoContainer>
      <TextCharsCount />

      <Container>
        <ReadingTime />

        {/* //TODO: COMMENTANTO PERCHè ATTUALMENTE NN FUNZIONA */}
        {/* <TextDifficulty /> */}
      </Container>
    </TextPostInfoContainer>
  )
}

const TextPostInfoContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 32px;
`

const Container = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
`
