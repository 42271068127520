/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { AppStore, T } from 'utils'
import { TiktokAssets } from './TiktokAssets'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { IPostVariant } from 'types/PostInterface'
import { MediaContainerPost } from '../../GenericComponents/MediaContainerPost'

/* ------------------- INTERFACES  */
interface Props {
  postPreview?: IPostVariant
}

export const ResultsPreviewTiktok = ({ postPreview }: Props) => {
  /* ----------------- ZUSTAND  */
  const { activePost } = useCreatePostStore((s) => ({
    activePost: s.activePost as IPostVariant,
  }))

  /* ----------------- VARIABLES  */
  const post = postPreview ?? activePost

  const urls = post?.data?.urls

  const metadataVisibleIndex = urls && urls.findIndex((url) => url.metaData.visible === true)

  const metadata = urls && metadataVisibleIndex !== -1 && urls[metadataVisibleIndex!]

  return (
    <ResultsPreviewTiktokContainer>
      <ContainerTiktok>
        <TiktokAssets imgSrc={post?.info?.account?.picture} />

        {postPreview && !metadata && post?.data?.medias?.length === 0 ? (
          <></>
        ) : (
          <MediaContainerPost width={'100%'} height={'100%'} arrowsPositions={16} postPreview={postPreview} />
        )}

        <NameAccount>{activePost?.info?.account?.name}</NameAccount>

        <PreviewText>
          {post?.data?.text.length === 0 && T.postPage.yourText}

          {post?.data?.text?.length > 25 ? (
            <>
              {post?.data?.text?.slice(0, 25)} <SeeMoreText>... {T.postPage.more}</SeeMoreText>
            </>
          ) : (
            post?.data?.text
          )}
        </PreviewText>

        <Hashtag>#fyp</Hashtag>
      </ContainerTiktok>
    </ResultsPreviewTiktokContainer>
  )
}

const ResultsPreviewTiktokContainer = styled(Box)`
  height: 614px;
  width: 347px;
  margin: 0 auto;
`

const ContainerTiktok = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
`

const NameAccount = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  font-size: 13px;
  font-weight: 600;
  position: absolute;
  bottom: 100.62px;
  left: 11.43px;
`

const PreviewText = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  border-radius: 0;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  position: absolute;
  font-size: 12px;
  bottom: 79.5px;
  left: 11.43px;
`

const Hashtag = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  bottom: 61.5px;
  left: 11.43px;
`

const SeeMoreText = styled.span`
  margin-left: 4px;
  color: ${() => AppStore.theme.o.darkGrey};
`
