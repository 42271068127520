/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { AppStore } from 'utils'

/* ------------------- INTERFACES  */
interface Props {
  text: string
}

export const GenerationSettingTitle = ({ text }: Props) => {
  return <GenerationSettingTitleContainer>{text}</GenerationSettingTitleContainer>
}

const GenerationSettingTitleContainer = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 4px;
`
