import { Box, Text } from 'components'
import { SocialPicture } from 'components/UI/SocialPicture'
import { SocialAccount } from 'types'
import { AppStore, T } from 'utils'
import styled from '@emotion/styled'

export const SocialAccountsAddable: React.FC<{
  account: SocialAccount
  key: any
  style?: any
  onClick?: any
  onhoverNull?: boolean
  noCheckBox?: boolean
  long?: boolean
  fullWidth?: boolean
  isSelected?: boolean
  isTokenExpired?: boolean
  bg?: string
}> = ({ account, key, style, onClick, onhoverNull, noCheckBox, long, fullWidth, isSelected, isTokenExpired, bg }) => {
  return (
    <ContainerAddSocial
      onClick={() => onClick(account)}
      vcenter
      row
      width={fullWidth ? '100%' : long ? 280 : 240}
      removeHover={onhoverNull}
      key={key}
      pv={8}
      ph={20}
      style={{ height: '65px', border: `1px solid ${AppStore.theme.o.grey}`, backgroundColor: bg, ...style }}
      infiniteAnnimation={isTokenExpired}
      infiniteAnnimationColor={AppStore.theme.o.red}
    >
      <SocialPicture
        picture={account.picture}
        provider={account.provider}
        isTokenExpired={isTokenExpired}
        dimensionProvider={24}
      />
      <Box flex={1} ml={10}>
        <Box center row vcenter>
          <Box flex={1}>
            <Text
              style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '180px' }}
              fontSize={14}
              weight={600}
              color={AppStore.theme.o.black}
            >
              {account.name}
            </Text>
            <Text fontSize={12} weight={400} color={AppStore.theme.o.darkGrey}>
              {T.socialProfileType[account?.profileType ?? 'profile']}
            </Text>
          </Box>

          {noCheckBox !== true && (
            <Box
              style={{
                width: 18,
                height: 18,
                radius: 14,
                backgroundColor:
                  (isSelected ? AppStore.theme.o.green : AppStore.theme.o.grey) ||
                  (account.selected ? AppStore.theme.o.green : AppStore.theme.o.grey),
              }}
            ></Box>
          )}
        </Box>
      </Box>
    </ContainerAddSocial>
  )
}

const ContainerAddSocial = styled(Box)`
  &:hover {
    background: ${() => AppStore.theme.o.lightGrey} !important;
  }
`
