import { Button } from 'components'
import { T } from 'utils'
import { useNavigate } from 'react-router-dom'
import { requestUpgradeModal } from 'components/HeaderUpgradeButton'
import {
  OWNER_COLLABORATORS_LIMITS_INFINITE,
  OWNER_COLLABORATORS_LIMITS_USED,
  OWNER_COLLABORATORS_LIMITS_AVAILABLE,
  OWNER_SOCIAL_ACCOUNTS_LIMITS_USED,
  OWNER_SOCIAL_ACCOUNTS_LIMITS_INFINITE,
} from 'utils/Constants/LimitUserConstants'

export default function AddSocialButton() {
  const navigate = useNavigate()
  const navigateFunc = () => navigate('/settings/social')

  const checkSocialAdding = () => {
    // console.log('checkSocialAdding', OWNER_SOCIAL_ACCOUNTS_LIMITS_USED, OWNER_COLLABORATORS_LIMITS_AVAILABLE)
    if (!OWNER_SOCIAL_ACCOUNTS_LIMITS_INFINITE) {
      if (OWNER_SOCIAL_ACCOUNTS_LIMITS_USED >= OWNER_COLLABORATORS_LIMITS_AVAILABLE) {
        requestUpgradeModal()
        return
      }
    }
    navigateFunc()
  }

  return (
    <Button
      style={{
        minHeight: 52,
        fontSize: 16,
        fontWeight: 700,
        maxWidth: 'fit-content',
      }}
      variant="secondary"
      onClick={checkSocialAdding}
    >
      {T.DashboardPage.addSocial}
    </Button>
  )
}
