import styled from '@emotion/styled'
import { Box } from 'components/Box'
import { darkTheme, lightTheme } from 'config/themes'
import { useEffect } from 'react'
import { useAnalyticsStore } from 'stores/AnalyticsStore'
import { IReportConfig } from 'types/AnalyticsInferfaces'
import { AppStore, T } from 'utils'
import { ReportContent } from './ReportContent'
import { ReportHeader } from './ReportHeader'

interface SelectedDates {
  startDate: string | null
  endDate: string | null
}

interface Props {
  report: IReportConfig
  selectedDates?: SelectedDates | null
}

const RenderReport = ({ report, selectedDates }: Props) => {
  const { setReportUpdateDate, reportPreviewName } = useAnalyticsStore.getState()

  // --------------- USE EFFECTS
  useEffect(() => {
    setReportUpdateDate(report.info.updatedAt)
  }, [])

  return (
    <PreviewReportContainer
      className="asters-report-container"
      style={{
        backgroundColor: AppStore.darkTheme ? darkTheme.o.lightestGrey : lightTheme.o.lightestGrey,
      }}
    >
      <ReportHeader report={report} />

      <ReportContent report={report} />

      {selectedDates && selectedDates.startDate && selectedDates.endDate ? (
        <SelectedDate>{`${T.analytics.reportDate}: ${selectedDates?.startDate} - ${selectedDates?.endDate}`}</SelectedDate>
      ) : null}
    </PreviewReportContainer>
  )
}

export default RenderReport

const PreviewReportContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  cursor: unset !important;
  width: 1117px;

  .react-grid-layout {
    position: relative !important;
  }
`

const SelectedDate = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${() => AppStore.theme.o.darkGrey};
  text-align: center;
`
