import Metrics from 'api/Metrics'
import Reports from 'api/Reports'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import ResponsiveGridLayout from 'react-grid-layout'
import { useAnalyticsStore } from 'stores/AnalyticsStore'
import { T } from 'translation/i18n'
import { GraphType, IDataReport, IReportConfig, IReportDataComplete } from 'types/AnalyticsInferfaces'
import { AppStore, showError, showSuccess } from 'utils'

const analyticsCheckboxIcons = {
  active: ['instagram', 'facebook', 'tiktok', 'linkedin'],
  // comingSoon: ['youtube', 'x'],
}

const createReport = async (reportConfig: IReportConfig, isUpdated = false) => {
  try {
    const response = await Reports.createReport(reportConfig)

    return response
  } catch (e) {
    console.error(e)
  }
}

const getReportList = async () => {
  try {
    const response = await Reports.getReportList()

    return response
  } catch (e) {}
}

const getReport = async (reportId) => {
  try {
    const response = await Reports.getReport(reportId)

    return response
  } catch (e) {}
}

const changeReportName = async (reportId: string, reportName: string, isReportCard: boolean): Promise<boolean> => {
  const { setReportList, setIsReportUpdating, onSetReportName, setReportUpdateDate } = useAnalyticsStore.getState()
  try {
    setIsReportUpdating(true)
    const response = await Reports.changeReportName(reportId, reportName, isReportCard)

    onSetReportName(reportName)
    setReportUpdateDate(response.info.updatedAt)
    setIsReportUpdating(false)

    // updates report list everywhere
    // getReportList().then((res) => {
    //   setReportList(res)
    // })

    showSuccess(T.success.reportNameChanged)
    return true
  } catch (e: any) {
    if (e.message === 'Report name already exists') {
      showError('Report name already exists')
    }
    return false
  }
}

const changeReportLogo = async (reportId: string, reportName: string) => {
  const { setReportPreview, setIsReportUpdating } = useAnalyticsStore.getState()
  try {
    setIsReportUpdating(true)
    const response = await Reports.changeReportLogo(reportId, reportName)

    setReportPreview(response)
    setIsReportUpdating(false)
    return response
  } catch (e) {
    showError(T.error.serverError)
  }
}

//Da testare in futuro
// const updateReport = async (reportId) => {
//   try {
//     const response = await Reports.updateReport(reportId)

//     return response
//   } catch (e) {}
// }

const deleteReport = async (reportId: string | string[]) => {
  try {
    const response = await Reports.deleteReport(reportId)

    return response
  } catch (e) {}
}

const getMetrics = async () => {
  try {
    const response = await Metrics.getMetrics()
    return response.filter((metric) => !metric?.hideMetric)
  } catch (e) {}
}

const saveLayout = async (layout: ResponsiveGridLayout.Layout[]) => {
  const { setIsReportUpdating, setReportUpdateDate, reportPreview } = useAnalyticsStore.getState()
  if (layout.length === 0) return
  try {
    setIsReportUpdating(true)
    const response = await Reports.updateGraphPosition(layout, reportPreview?._id ?? '')
    if (!response) showError(T.error.serverError)
    setReportUpdateDate(response.info.updatedAt)
    setIsReportUpdating(false)
  } catch (error) {
    showError(T.error.serverError)
  }
}

// --------------- UTILS FUNCTIONS
const formatDate = (updatedAt: Date | string) => {
  if (updatedAt === '') return ''
  if (updatedAt instanceof Date) {
    return (
      updatedAt.toLocaleDateString() +
      ` ${T.singleWord.at} ` +
      updatedAt.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })
    )
  } else {
    return (
      new Date(updatedAt!).toLocaleDateString() +
      ` ${T.singleWord.at} ` +
      new Date(updatedAt!).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })
    )
  }
}

/**
 *
 * @returns [thirtyDaysAgoDate, currentDateString]
 */
const dateRange28DaysAgo = () => {
  const currentDate = new Date()
  currentDate.setDate(currentDate.getDate() - 1)
  const currentDateString = currentDate.toISOString().split('T')[0]
  const thirtyDaysAgo = new Date()
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 28)
  const thirtyDaysAgoDate = thirtyDaysAgo.toISOString().split('T')[0]

  return [thirtyDaysAgoDate, currentDateString]
}
/* --------------  */

/* -------------- REACT-GRID-LAYOUT */
/**
 * Imposta le posizioni di un oggetto grafico `g` basato su alcuni parametri.
 * Se l'oggetto `g` contiene proprietà `x`, `y`, e `width`, ritorna queste proprietà
 * convertite in numeri insieme ad un'altezza `h` di 1.
 * Altrimenti, chiama la funzione `positionGridItem` per determinare la posizione dell'elemento nella griglia.
 *
 * @param {any} g - L'oggetto grafico da posizionare.
 * @param {number} i - L'indice dell'elemento nella griglia.
 * @returns {Object} Un oggetto contenente le proprietà `x`, `y`, `w`, e `h` dell'elemento.
 */
export const setPositions = (g: IReportDataComplete, i: number) => {
  // Controlla se l'oggetto `g` ha le proprietà `x`, `y` e `width`
  if (g.x && g.y && g.width) {
    // Ritorna un oggetto con `x`, `y` convertiti in numeri float, `width` convertito in numero float e altezza `h` di 1

    const h = g.metricGraph === 'table' ? 2 : 1

    return {
      x: parseFloat(g.x),
      y: parseFloat(g.y),
      w: parseFloat(g.width),
      h: h,
    }
  }

  // Se `g` non ha le proprietà `x`, `y`, `width`, determina la posizione dell'elemento nella griglia
  // Utilizza la funzione `positionGridItem` passando l'indice `i` e una larghezza di 2 se `g.metricGraph` è 'heatmap', altrimenti una larghezza di 1
  return positionGridItem(g, i)
}

/**
 * Determina la posizione dell'elemento nella griglia basata sull'indice `i` e sulla larghezza `width`.
 * Posiziona gli elementi in una griglia di due colonne.
 *
 * @param {any} g - L'oggetto grafico da posizionare.
 * @param {number} i - L'indice dell'elemento nella griglia.
 * @returns {Object} Un oggetto contenente le proprietà `x`, `y`, `w`, e `h` dell'elemento.
 */
const positionGridItem = (g: IReportDataComplete, i: number) => {
  const w = g.metricGraph === 'heatmap' || g.metricGraph === 'table' ? 2 : 1
  const h = g.metricGraph === 'table' ? 2 : 1

  return {
    x: i % 2 === 0 ? 0 : 1, // Posiziona l'elemento nella colonna 0 se `i` è pari, altrimenti nella colonna 1
    y: calculateYPosition(i), // Determina la posizione verticale dell'elemento
    w: w, // Imposta la larghezza dell'elemento
    h: h, // Imposta l'altezza dell'elemento
  }
}

/**
 * Calcola la posizione verticale dell'elemento nella griglia basata sull'indice `i`.
 * La posizione `y` aumenta ogni due elementi.
 *
 * @param {number} i - L'indice dell'elemento nella griglia.
 * @returns {number} La posizione verticale `y` dell'elemento.
 */
const calculateYPosition = (i: number) => {
  let y = 0

  // Se l'indice è maggiore o uguale a 2, calcola la posizione verticale come la metà arrotondata verso il basso dell'indice incrementato di 1
  if (i >= 2) {
    y = Math.floor((i + 1) / 2)
  }

  return y
}

export const isIReportDataComplete = (data: IDataReport | IReportDataComplete): data is IReportDataComplete => {
  return 'metricName' in data
}

export const getClassNameGridItem = (metricGraph: GraphType) => {
  if (metricGraph === 'heatmap' || metricGraph === 'table') return 'full-grid-item'

  return 'grid-item'
}

export const getMetricWidth = (metricGraph: GraphType) => {
  if (metricGraph === 'heatmap' || metricGraph === 'table') return 1117

  return 542
}

export const getMetricHeight = (metricGraph: GraphType) => {
  if (metricGraph === 'table') return 428 * 2 + 24

  return 428
}

const enumYposition = {
  0: 0,
  1: 0,
  2: 428 * 1 + 24,
  3: 428 * 1 + 24,
  4: 428 * 2 + 24,
  5: 428 * 2 + 24,
  6: 428 * 3 + 24,
  7: 428 * 3 + 24,
  8: 428 * 4 + 24,
  9: 428 * 4 + 24,
  10: 428 * 5 + 24,
  11: 428 * 5 + 24,
  12: 428 * 6 + 24,
  13: 428 * 6 + 24,
  14: 428 * 7 + 24,
  15: 428 * 7 + 24,
  16: 428 * 8 + 24,
  17: 428 * 8 + 24,
  18: 428 * 9 + 24,
  19: 428 * 9 + 24,
  20: 428 * 10 + 24,
  21: 428 * 10 + 24,
  22: 428 * 11 + 24,
  23: 428 * 11 + 24,
  24: 428 * 12 + 24,
  25: 428 * 12 + 24,
  26: 428 * 13 + 24,
  27: 428 * 13 + 24,
}

const addClassname = (zoom) => {
  const style = document.createElement('style')
  style.type = 'text/css'
  style.innerHTML = `.ant-select-dropdown {
      transform: scale(${zoom}%);
    }

    .ant-popover {
      transform: scale(${zoom}%);
      visibility: hidden;
    }
    `
  document.head.appendChild(style)
}

const pdfConstants = {
  width: 1240,
  height: 1754,
  pageHeight: 1754,
  pageWidth: 1240,
  yOffset: 50,
  maxImageWidth: 250,
  // maxImageHeight: 50,
}

const generateFirstPage = async (pdf: any, reportName: string, report: IReportConfig | null) => {
  pdf.setFillColor(AppStore.theme.o.lightestGrey)
  pdf.rect(0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height, 'F')
  const { width, height } = pdfConstants

  try {
    const logoImg = await loadImage(report?.info?.reportLogo)
    if (!logoImg) return
    const { newHeight, newWidth } = calculateImageDimensions(logoImg)
    pdf.addImage(logoImg, 'PNG', (width - newWidth) / 2, 100, newWidth, newHeight) // Disegna il logo nella parte superiore sinistra

    // disegna testo centrale
    pdf.setFontSize(40)
    pdf.setTextColor(AppStore.theme.o.black)
    pdf.text(reportName, width / 2, height / 2, { align: 'center' }) // Disegna il testo al centro della pagina

    // disegna piè di pagina
    pdf.setFontSize(30)
    pdf.setTextColor(AppStore.theme.o.darkGrey)
    pdf.text(
      `Data report:  ${new Date(report?.since ?? new Date().toLocaleDateString()).toLocaleDateString()} - ${new Date(
        report?.until ?? new Date().toLocaleDateString()
      ).toLocaleDateString()}`,
      width / 2,
      height - 150,
      { align: 'center' }
    ) // Disegna la data nella parte inferiore della pagina
  } catch (error) {}
  pdf.addPage()
}

async function loadImage(imageUrl?: string): Promise<HTMLImageElement | undefined> {
  const image =
    imageUrl ??
    (AppStore.darkTheme ? process.env.REACT_APP_ALTERNATIVE_LOGO_DARK : process.env.REACT_APP_ALTERNATIVE_LOGO_LIGHT) ??
    ''
  try {
    const response = await fetch(image)
    if (!response.ok) {
      throw new Error(`Failed to fetch image: ${response.statusText}`)
    }
    const blob = await response.blob()
    const imgData = await URL.createObjectURL(blob)
    const img = new Image()
    img.src = imgData
    return await new Promise((resolve, reject) => {
      img.onload = () => resolve(img)
      img.onerror = () => reject(new Error('Failed to load image'))
    })
  } catch (error) {
    console.error('Error loading image:', error)
    return undefined // Indicate failure by returning undefined
  }
}

const calculateImageDimensions = (image: HTMLImageElement): { newWidth: number; newHeight: number } => {
  const { maxImageWidth } = pdfConstants
  const imageRatio = image.width / image.height
  const newWidth = Math.min(maxImageWidth, image.width)
  const newHeight = Math.round(newWidth / imageRatio)
  return { newWidth, newHeight }
}

const generateLastPage = async (pdf: any, reportName: string, report: IReportConfig | null) => {
  pdf.setFillColor(AppStore.theme.o.lightestGrey)
  pdf.rect(0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height, 'F')
  const { width, height } = pdfConstants

  try {
    const image = await loadImage(report?.info?.reportLogo)
    if (!image) return
    const { newHeight, newWidth } = calculateImageDimensions(image)
    pdf.addImage(image, 'PNG', (width - newWidth) / 2, (height - newHeight) / 2, newWidth, newHeight) // Disegna il logo nella parte superiore sinistra

    pdf.addPage()
  } catch (error) {
    return
  }
}

const applyZoomEffect = (clonedDocument: Document, clonedElement: HTMLElement) => {
  const scrollableContentDiv = clonedDocument.createElement('div')
  scrollableContentDiv.style.position = 'absolute'
  scrollableContentDiv.style.width = 'auto'
  scrollableContentDiv.style.height = clonedElement.scrollHeight + 'px'
  scrollableContentDiv.appendChild(clonedElement)

  // Add zoom effect to the cloned element
  clonedDocument.body.appendChild(scrollableContentDiv)
  const rootElement = clonedDocument.getElementById('root')!
  const zoom = 100
  rootElement.style.cssText = `
            transform: scale(${zoom}%, ${zoom}%);
            transform-origin: left top;
            max-width: ${100 * (100 / zoom)}vw;
            min-width: ${100 * (100 / zoom)}vw;
            max-height: ${100 * (100 / zoom)}vh;
            min-height: ${100 * (100 / zoom)}vh;
          `
  addClassname(zoom)
}

// ------------DOWNLOAD
const downloadPdf = async (reportName: string, report: IReportConfig | null): Promise<boolean> => {
  const element = document.getElementById('report_preview_container')

  if (element) {
    // Due to pdf size limit of 14000px height if the report is too large show an error
    if (element?.scrollHeight + element?.offsetHeight > 30000) {
      showError(T.analytics.reportTooLargeForPdf)
      return false
    }
    let width = 0
    let height = 0
    let headerHeight = 72

    // Cattura dello screenshot e generazione del PDF
    return html2canvas(element, {
      onclone: async (document) => {
        // Clone the element to avoid users seeing the zoom effect
        const clonedDocument = document as Document
        const clonedElement = clonedDocument.getElementById('report_preview_container')
        clonedElement!.style.cssText = clonedElement!.style.cssText + '\nborder-radius: 0px !important;'
        const draggableElements = clonedElement!.getElementsByClassName('drag-handle')
        const logoImage = clonedElement!.getElementsByClassName('logo_image')
        headerHeight = logoImage[0]?.scrollHeight + headerHeight
        const draggableElementsArray = Array.from(draggableElements)
        draggableElementsArray.forEach((el) => {
          el.remove()
        })

        if (clonedElement) {
          applyZoomEffect(clonedDocument, clonedElement)
          const rect = clonedElement.getBoundingClientRect()
          width = clonedElement.scrollWidth
          height = clonedElement.scrollHeight
          // Wait for the zoom effect to be applied
          await new Promise((resolve) => setTimeout(resolve, 250))
        }
      },
      useCORS: true,
      allowTaint: true,
      height: element.scrollHeight,
    })
      .then(async (canvas) => {
        const maxWidth = 1240
        const maxHeight = 1754
        const ratio = maxWidth / width
        const newCanvas = document.createElement('canvas')
        newCanvas.width = maxWidth
        newCanvas.height = ratio * height
        const newCtx = newCanvas.getContext('2d')
        newCtx?.drawImage(canvas, 0, 0, maxWidth, ratio * height)
        const page = Math.ceil(height / maxHeight)
        const baseHeight = 1380 + headerHeight

        // Create temporary canvas for each page of the report if the report is too height, mantain correct ratio, and split original calvas in multiple canvas
        const tempCanvasArray: any = []
        if (page > 1) {
          for (let i = 0; i <= page; i++) {
            const tempCanvas1 = document.createElement('canvas')
            tempCanvas1.width = maxWidth
            tempCanvas1.height = maxHeight
            const tempCtx = tempCanvas1.getContext('2d')
            const start =
              i < 1
                ? i * Math.round(baseHeight * ratio)
                : (i - 1) * Math.round(1380 * ratio) + Math.round(baseHeight * ratio)
            if (height - start < 200) continue
            tempCtx?.drawImage(
              newCanvas,
              0,
              start, // y-coordinate to start extracting from
              maxWidth, // width to extract
              i === 0
                ? Math.round(baseHeight * ratio) + Math.round(15 * ratio)
                : Math.round(1380 * ratio) + Math.round(15 * ratio), // height to extract
              0,
              0,
              maxWidth,
              maxHeight
            )
            // tempCtx?.drawImage(
            //   newCanvas,
            //   0,
            //   i * maxHeight, // y-coordinate to start extracting from
            //   maxWidth, // width to extract
            //   maxHeight, // height to extract
            //   0,
            //   0,
            //   maxWidth,
            //   maxHeight
            // )
            tempCanvasArray.push(tempCanvas1)
          }
        } else {
          tempCanvasArray.push(newCanvas)
        }

        const pdf = new jsPDF('p', 'pt', [maxWidth, maxHeight])

        // Add first page
        await generateFirstPage(pdf, reportName, report)

        tempCanvasArray.forEach((tempCanvas1, index) => {
          const imgData = tempCanvas1.toDataURL('image/png')
          const x = (pdf.internal.pageSize.getWidth() - maxWidth) / 2
          pdf.setFillColor(AppStore.theme.o.lightestGrey)
          pdf.rect(0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height, 'F')

          pdf.addImage(imgData, 'PNG', x, 0, maxWidth, index > 0 ? Math.round(1380 * ratio) : maxHeight)
          //add page to pdf
          if (index <= page) {
            pdf.addPage()
          }
        })

        // Add last page
        await generateLastPage(pdf, reportName, report)

        pdf.deletePage(pdf.internal.pages.length - 1)

        pdf.save(`report_${reportName}.pdf`)

        return true
      })
      .catch((error) => {
        console.error('Errore durante la cattura dello screenshot o la generazione del PDF:', error)
        return false
      })
  }
  return false
}

// if error has "max range" of "Max range of instagram -> [[30]] " return true and the provider and number of days
const maxRangeError = (errorString: string): { maxRange: boolean; provider: string; days: number } => {
  const maxRange = errorString.match(/Max range of (.*) -> \[\[(.*)\]\]/)
  if (!maxRange) return { maxRange: false, provider: '', days: 0 }
  return { maxRange: true, provider: maxRange[1], days: parseInt(maxRange[2]) }
}

const notEnoughUsersError = (errorString: string): boolean => {
  return errorString.match(/enough users/) !== null
}

export {
  analyticsCheckboxIcons,
  changeReportLogo,
  changeReportName,
  createReport,
  dateRange28DaysAgo,
  deleteReport,
  /* ------------DOWNLOAD */
  downloadPdf,
  enumYposition,
  /* --------------- UTILS FUNCTIONS */
  formatDate,
  getMetrics,
  getReport,
  getReportList,
  /* -------------- REACT-GRID-LAYOUT */
  positionGridItem,
  saveLayout,
  maxRangeError,
  notEnoughUsersError,
}
