/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { AppStore } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  action: (e) => void
}

export const BtnDeleteTag = ({ action }: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <BtnDeleteTagContainer onClick={action} removeHover>
      <Icons.trashCanSizer />
    </BtnDeleteTagContainer>
  )
}

const BtnDeleteTagContainer = styled(Box)`
  width: 36px;
  height: 36px;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${() => AppStore.theme.o.error_container};
  border: 1px solid ${() => AppStore.theme.o.on_error_container};

  svg {
    width: 24px;
    height: 24px;
    fill: ${() => AppStore.theme.o.on_error_container};
  }

  &:hover {
    border-color: transparent;
  }
`
