/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { AppStore } from 'utils'

/* ------------------- INTERFACES  */

interface Props {
  isSelected?: boolean // Booleano per gestire se l'elemento è selezionato
  action?: () => void // Azione al onClick del checkbox
}

export const CardCheckBox = ({ isSelected, action }: Props) => {
  return (
    <CardCheckBoxContainer className={`card_checkbox ${isSelected ? 'selected' : 'not_selected'}`} onClick={action}>
      <CheckBox className={`card_checkbox_check ${isSelected ? 'selected' : ''}`} />
    </CardCheckBoxContainer>
  )
}

const CardCheckBoxContainer = styled(Box)`
  &.card_checkbox {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background: ${() => AppStore.theme.o.lightestGrey};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const CheckBox = styled(Box)`
  &.card_checkbox_check {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid ${() => AppStore.theme.o.darkGrey};

    &.selected {
      border-color: ${() => AppStore.theme.o.green};
      background: ${() => AppStore.theme.o.green};
    }
  }
`
