/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { TSubsection } from 'types/CreatePostInferfaces'
import { AppStore } from 'utils'
import { AddTextToPostButton } from './AddTextToPostButton'
import { GenerationFeedbackButtons } from './GenerationFeedbackButtons'
import React from 'react'

/* ------------------- INTERFACES  */
interface Props {
  generation: any // scusate nn so che mettere bisogna vedere chiamata
}

export const GenerationResult = ({ generation }: Props) => {
  const subsection = (location.pathname.split('/')[3] ?? 'astersAiText') as TSubsection

  const isLink = subsection === 'astersAiText' ? false : true

  const formattedText = generation.generatedTexts.split('\n').map((text, index) => (
    <React.Fragment key={index}>
      {text}
      <br />
    </React.Fragment>
  ))

  return (
    <GenerationResultContainer>
      {/* Attualmente commentato perchè svolge lo stesso compito però non resiza correttamente (lascia margine di scrolling interno) e rende tutto testo sfuocato */}
      {/* <AutoresizingTextarea disabled={true} value={generation.generatedTexts}></AutoresizingTextarea> */}

      <ContainerGenerationText>{formattedText}</ContainerGenerationText>
      <ContainerButtonsGeneration>
        <AddTextToPostButton generatedText={generation.generatedTexts} />
        <GenerationFeedbackButtons generatedText={generation.generatedTexts} isLink={isLink} />
      </ContainerButtonsGeneration>
    </GenerationResultContainer>
  )
}

const GenerationResultContainer = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 24px;
`

const ContainerGenerationText = styled.p`
  width: 620px;
  height: auto;
  border-radius: 16px;
  resize: none;
  padding: 24px;
  background-color: ${() => AppStore.theme.o.lightGrey};
`

const ContainerButtonsGeneration = styled(Box)`
  gap: 8px;
  width: 20%;
`
