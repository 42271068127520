/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { useEffect, useState } from 'react'
import { IInstagramTag, IPostVariant } from 'types/PostInterface'
import { updatePost } from 'utils/PostUtils/Post'
import { CreatingNewTag } from './CreatingNewTag'
import { ExistingTag } from './ExistingTag'
import { TagCursor } from './TagCursor'
import Socialaccounts from 'api/Socialaccounts'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  post: IPostVariant
  tags: IInstagramTag[]
  selectedMediaId: string
}

export const PinningContainer = ({ post, tags, selectedMediaId }: Props) => {
  /* ----------------- STATE  */
  const [newTagData, setNewTagData] = useState<IInstagramTag>({
    mediaId: selectedMediaId,
    text: '',
    coordinates: {
      x: '0',
      y: '0',
    },
  })

  const [creatingNewTag, setCreatingNewTag] = useState<boolean>(false)

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */
  // Variabili per dimensioni oggetti
  // Vengono salvate come variabili oltre perché vengono usate più volte, perché è necessario che siano uguali e precise per il calcolo delle posizioni
  const containerDimension = 800
  const fullTagWidth = 360
  const tagWidth = 52
  const tagHeight = 36
  const cursorDimension = 24

  /* ----------------- METHODS  */
  /**
   * Funzione per creare un nuovo tag
   * @param e evento del click
   */
  const createTag = (e) => {
    e.stopPropagation()
    hideCursor()

    const pinContainer = document.getElementById('pinContainer') as HTMLDivElement

    const x: any = (
      (e.pageX - pinContainer.getBoundingClientRect().x) /
      pinContainer.getBoundingClientRect().width
    ).toFixed(2)
    const y: any = (
      (e.pageY - pinContainer.getBoundingClientRect().y) /
      pinContainer.getBoundingClientRect().height
    ).toFixed(2)

    setNewTagData({
      ...newTagData,
      coordinates: {
        x,
        y,
      },
    })

    setCreatingNewTag(true)
  }

  /**
   * Funzione per aggiornare il testo del nuovo tag
   * @param e evento dell'input
   */
  const updateTextNewTag = (e) => {
    setNewTagData({
      ...newTagData,
      text: e.target.value,
    })
  }

  /**
   * Funzione per resettare la creazione di un nuovo tag
   * @param e evento del click
   */
  const resetNewTag = (e: Event) => {
    e.stopPropagation()

    setNewTagData({
      mediaId: selectedMediaId,
      text: '',
      coordinates: {
        x: '0',
        y: '0',
      },
    })

    setCreatingNewTag(false)
  }

  /**
   *  Funzione per gestire l'effetto del mouse personalizzato
   * @param e evento del mouse
   */
  const handleMouseEffect = (e) => {
    e.stopPropagation()

    const pinContainer = document.getElementById('pinContainer') as HTMLDivElement
    const cursor = document.querySelector('.tag_cursor') as HTMLDivElement

    const x: any = (
      (e.pageX - pinContainer.getBoundingClientRect().x) /
      pinContainer.getBoundingClientRect().width
    ).toFixed(2)
    const y: any = (
      (e.pageY - pinContainer.getBoundingClientRect().y) /
      pinContainer.getBoundingClientRect().height
    ).toFixed(2)
    const distanceFromLeft = e.pageX - pinContainer.getBoundingClientRect().x
    const distanceFromTop = e.pageY - pinContainer.getBoundingClientRect().y
    const addOnValueX = x * ((pinContainer.getBoundingClientRect().width - containerDimension) * -1)
    const addOnValueY = y * ((pinContainer.getBoundingClientRect().height - containerDimension) * -1)
    const totalX: number = distanceFromLeft + addOnValueX
    const totalY: number = distanceFromTop + addOnValueY

    cursor.style.left = `${totalX - cursorDimension / 2}px`
    cursor.style.top = `${totalY - cursorDimension / 2}px`
  }

  /**
   * Funzione per mostrare il cursore personalizzato appena si entra dentro il container
   */
  const showCursor = () => {
    const cursor = document.querySelector('.tag_cursor') as HTMLDivElement
    cursor.style.display = 'flex'
  }

  /**
   * Funzione per nascondere il cursore personalizzato appena si esce dal container
   */
  const hideCursor = () => {
    const cursor = document.querySelector('.tag_cursor') as HTMLDivElement
    cursor.style.display = 'none'
  }

  /**
   * Funzione per aggiornare i tag (aggiungendo il nuovo tag)
   * @param e evento del click
   */
  const updateTags = async (e: Event) => {
    e.stopPropagation()
    const oldTags = post.data.tags || []

    const newTags: IInstagramTag[] = [...oldTags, { ...newTagData }]

    updatePost(post.variantId, { tags: newTags })
    setCreatingNewTag(false)
  }

  /* ----------------- API CALL  */
  /**
   *  Funzione per ottenere l'immagine del profilo del social account
   * @returns The profile image of the social account
   */
  const getSocialPicture = async (text?: string) => {
    try {
      const response = (await Socialaccounts.getProfileImage(
        post.info.account.account_id,
        text ?? newTagData.text
      )) as string

      const checkResponse = response.split(' ').includes('Impossibile')

      return checkResponse ? null : response
    } catch (e) {
      console.error(e)
    }
  }

  /* ----------------- USEEFFECT  */
  // Al cambio del media, resetta la creazione di un nuovo tag e setta i dati del nuovo tag
  useEffect(() => {
    setCreatingNewTag(false)

    setNewTagData({
      mediaId: selectedMediaId,
      text: '',
      coordinates: {
        x: '0',
        y: '0',
      },
    })
  }, [selectedMediaId])

  return (
    <PinningContainerContainer
      id="pinContainer"
      removeHover
      onClick={createTag}
      onMouseMove={handleMouseEffect}
      onMouseEnter={showCursor}
      onMouseLeave={hideCursor}
    >
      {/* Existing tags */}
      {tags.map((tag, index) => (
        <ExistingTag
          key={index + tag.text}
          tag={tag}
          post={post}
          hideCursor={hideCursor}
          showCursor={showCursor}
          getSocialPicture={getSocialPicture}
          tagWidth={tagWidth}
          fullTagWidth={fullTagWidth}
          style={{
            width: tagWidth,
            height: tagHeight,
            left: Number(tag.coordinates.x) * containerDimension - tagWidth / 2,
            top: Number(tag.coordinates.y) * containerDimension - tagHeight / 2,
          }}
        />
      ))}

      {/* Creating tag */}
      {creatingNewTag && (
        <CreatingNewTag
          newTagData={newTagData}
          updateTextNewTag={updateTextNewTag}
          updateTags={updateTags}
          resetNewTag={resetNewTag}
          hideCursor={hideCursor}
          showCursor={showCursor}
          tagWidth={tagWidth}
          fullTagWidth={fullTagWidth}
          style={{
            width: tagWidth,
            height: tagHeight,
            left: Number(newTagData.coordinates.x) * containerDimension - tagWidth / 2,
            top: Number(newTagData.coordinates.y) * containerDimension - tagHeight / 2,
          }}
        />
      )}

      {/* Cursore personalizzato */}
      <TagCursor
        style={{
          width: cursorDimension,
          height: cursorDimension,
        }}
      />
    </PinningContainerContainer>
  )
}

const PinningContainerContainer = styled(Box)`
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  cursor: none;
`
