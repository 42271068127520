import { SocialAccount } from 'types'
import { IFBPage, IPlace } from 'types/PostInterface'
import { AppStore } from 'utils'
import AstersBaseResource from './AstersBaseResource'
import { api, responseErrorCheck } from './api'

export default class Socialaccounts extends AstersBaseResource {
  static endpoint = '/socialaccounts'

  //get linkedin socialaccounts
  static async getRequestUrl(provider: string) {
    return api
      .get<string>(`${this.endpoint}/requestUrl`, {
        provider,
      })
      .then(responseErrorCheck)
  }

  //get linkedin socialaccounts
  static async getLinkedinAccounts(code: string, redirectUri: string) {
    return api
      .get<{ socialaccounts: SocialAccount[]; tokens: any }>(`${this.endpoint}/linkedin/accounts`, {
        code,
        redirectUri,
      })
      .then(responseErrorCheck)
  }

  //get twitter socialaccounts
  static async getTwitterAccounts(code: string, codeVerifier: string) {
    const workspaceId = AppStore.getWorkspaceId()

    return api
      .get<{ socialaccounts: SocialAccount[]; tokens: any }>(`${this.endpoint}/twitter/accounts`, {
        code,
        codeVerifier,
        workspaceId,
      })
      .then(responseErrorCheck)
  }

  static async getYoutubeAccounts(code: string) {
    const workspaceId = AppStore.getWorkspaceId()

    return api
      .post<{ socialaccounts: SocialAccount[]; tokens: any }>(`${this.endpoint}/youtube/accounts`, {
        code,
        workspaceId,
      })
      .then(responseErrorCheck)
  }

  //get tiktok socialaccounts
  static async getTikTok() {
    return api.post(`${this.endpoint}/getTiktokToken`).then(responseErrorCheck)
  }

  // getTiktokUser
  static async getTiktokUser(code: string) {
    const workspaceId = AppStore.getWorkspaceId()
    return api
      .post<{ socialaccounts: SocialAccount[]; tokens: any }>(`${this.endpoint}/tiktok/accounts`, {
        code,
        workspaceId,
      })
      .then(responseErrorCheck)
  }

  static async checkRefreshToken(socialaccounts: SocialAccount[], workspaceId?: string): Promise<SocialAccount[]> {
    return api
      .post<SocialAccount[]>(`${this.endpoint}/checkRefreshToken/${workspaceId ?? AppStore.getWorkspaceId()}`, {
        socials: socialaccounts,
      })
      .then(responseErrorCheck)
  }

  static async switchWorkspace(social: SocialAccount, workspaceId: string): Promise<SocialAccount> {
    const data = { social, workspaceId }
    return api.post<SocialAccount>(`${this.endpoint}/changeWorkspace`, data).then(responseErrorCheck)
  }

  static async getOnlineFollower(accountId: string, workspaceId: string, timezone: number) {
    const userTimezoneOffset = new Date().getTimezoneOffset()
    const timezoneOffsetHours = -Math.floor(userTimezoneOffset / 60)

    return api.post(`${this.endpoint}/getOnlineFollowerData`, {
      accountId: accountId,
      workspaceId: workspaceId,
      timeZone: timezone,
    })
  }

  static async connectOauth1(oauthToken: string, oauthVerifier: string, oauthTokenSecret: string) {
    return api
      .post(`${this.endpoint}/x/oauth1`, {
        oauth_token: oauthToken,
        oauth_verifier: oauthVerifier,
        oauth_token_secret: oauthTokenSecret,
        workspaceId: AppStore.getWorkspaceId(),
      })
      .then(responseErrorCheck)
  }

  static async getOauth1Url() {
    const workspaceId = AppStore.getWorkspaceId()
    return api.get<string>(`${this.endpoint}/x/oauth1/url`, { workspaceId: workspaceId }).then(responseErrorCheck)
  }

  /**
   * The function `checkSocialExist` asynchronously sends a POST request to the API endpoint to check
   * the existence of social accounts.
   * @param {SocialAccount[]} accounts - The `accounts` parameter is an array of `SocialAccount`
   * objects.
   * @returns The `checkSocialExist` method is returning a Promise that resolves to the result of the
   * API call made using the `post` method. The API call is sending a POST request to
   * `${this.endpoint}/checkSocialExist/${AppStore.getWorkspaceId()}` with the `accounts` data. The
   * response from the API call is then passed through the `responseErrorCheck` function.
   */
  static async checkSocialExist(accounts: string[]) {
    return api
      .post<SocialAccount[]>(`${this.endpoint}/checkSocialExist/${AppStore.getWorkspaceId()}`, {
        accountIds: accounts,
      })
      .then(responseErrorCheck)
  }

  /**
   * The function `getProfileImage` asynchronously sends a POST request to the API endpoint to get the profile image
   * @param  socialAccountId ,  search
   * @returns url of the social profile image
   */
  static async getProfileImage(socialAccountId, search) {
    return api
      .get(`${this.endpoint}/${socialAccountId}/${search}/getInstagramTag`, { socialAccountId, search })
      .then(responseErrorCheck)
  }

  /**
   * The function `getPlaces` asynchronously sends a GET request to the API endpoint to get the places
   * @param socialAccountId
   * @param search
   * @returns Array of places
   */
  static async getPlaces(socialAccountId: string, search: string) {
    return api.get<IPlace[]>(`${this.endpoint}/getPlaces/${socialAccountId}/${search}`).then(responseErrorCheck)
  }

  /**
   * The function `getPages` asynchronously sends a GET request to the API endpoint to get the pages
   * @param socialAccountId
   * @param search
   * @returns Array of pages
   */
  static async getPages(socialAccountId: string, search: string) {
    return api.get<IFBPage[]>(`${this.endpoint}/getPages/${socialAccountId}/${search}`).then(responseErrorCheck)
  }
}
