/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { MediaContainerPost } from '../../GenericComponents/MediaContainerPost'
import { YoutubeShortAssets } from './YoutubeShortAssets'
import { IPostVariant } from 'types/PostInterface'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { AppStore, T } from 'utils'

/* ------------------- INTERFACES  */
interface Props {
  postPreview?: IPostVariant
}

export const ResultsPreviewShort = ({ postPreview }: Props) => {
  /* ----------------- ZUSTAND  */
  const { activePost } = useCreatePostStore((s) => ({
    activePost: s.activePost as IPostVariant,
  }))

  /* ----------------- VARIABLES  */
  const post = postPreview ?? activePost

  return (
    <ResultsPreviewShortContainer>
      <YoutubeShortAssets />

      <MediaContainerPost width={'100%'} height={'100%'} arrowsPositions={16} postPreview={postPreview} />

      <AccountInfo>
        <AccountImg src={activePost?.info?.account?.picture} />
        <AccountName>{activePost?.info?.account?.name}</AccountName>
      </AccountInfo>

      <PreviewText>
        {post?.data?.text.length === 0 && T.postPage.yourText}

        {post?.data?.text?.length > 25 ? (
          <>
            {post?.data?.text?.slice(0, 25)} <SeeMoreText>... {T.postPage.more}</SeeMoreText>
          </>
        ) : (
          post?.data?.text
        )}
      </PreviewText>

      <Hashtag>#fyp</Hashtag>
    </ResultsPreviewShortContainer>
  )
}

const ResultsPreviewShortContainer = styled(Box)`
  margin: 0 auto;
  height: 614px;
  width: 347px;
  position: relative;
`

const AccountInfo = styled(Box)`
  position: absolute;
  bottom: 92px;
  left: 13px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`

const AccountImg = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`

const AccountName = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  font-size: 9px;
  font-weight: 500;
`

const PreviewText = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  border-radius: 0;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  position: absolute;
  font-size: 12px;
  bottom: 68px;
  left: 13px;
`

const Hashtag = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  bottom: 50px;
  left: 13px;
`

const SeeMoreText = styled.span`
  margin-left: 4px;
  color: ${() => AppStore.theme.o.darkGrey};
`
