import Socialaccounts from 'api/Socialaccounts'
import { MetaAccounts, SocialAccount } from 'types'
import { T, showError, initFB } from 'utils'
import AppStore from './AppStore'

const instagramScopeMeta = [
  'instagram_basic',
  'instagram_content_publish',
  'instagram_manage_insights',
  'instagram_manage_insights',
  //TODO: Remove this scope in prod until permission is approved by meta in qty
  'instagram_manage_comments',
  'business_management',
].join(',')

const facebookScopeMeta = [
  'public_profile',
  'email',
  'pages_manage_posts',
  'pages_show_list',
  'pages_read_engagement',
  'business_management',
  'pages_manage_engagement',
  'pages_read_user_content',
  'read_insights',
].join(',')

export const getMetaAccount = (callback: ({ accessToken, userID }) => void, reject, scope) => {
  initFB()
  FB.getLoginStatus((response) => {
    try {
      if (response.status === 'connected') {
        FB.logout(() => {
          FB.login(
            (loginResponse) => {
              if (loginResponse.status === 'connected') {
                callback({
                  accessToken: loginResponse.authResponse.accessToken,
                  userID: loginResponse.authResponse.userID,
                })
              } else {
                console.error(loginResponse)
                showError(T.error.autorizationFails)
                reject('')
              }
            },
            { scope, enable_profile_selector: true, auth_type: 'reauthorize' }
          )
        })
      } else {
        FB.logout()
        FB.login(
          (loginResponse) => {
            if (loginResponse.status === 'connected') {
              callback({
                accessToken: loginResponse.authResponse.accessToken,
                userID: loginResponse.authResponse.userID,
              })
            } else {
              console.error(loginResponse)
              showError(T.error.autorizationFails)
              reject('')
            }
          },
          { scope, enable_profile_selector: true, auth_type: 'reauthorize' }
        )
      }
    } catch (error) {
      console.error(error)
      reject(error)
    }
  }, true)
}

export const getFBAccounts = async (paging?: { cursor: { before: string; after: string } }) => {
  return new Promise<SocialAccount[]>(async (resolve, reject) => {
    const allAccounts: any[] = []
    await FB.logout()

    const fetchAccounts = (userID, cursor) => {
      FB.api(
        `/${userID}/accounts`,
        {
          fields: ['id', 'name', 'access_token', 'picture', 'name_with_location_descriptor'],
          after: cursor?.after ?? null, // Use the cursor for paging if it exists
          xfbml: true,
        },
        (response: MetaAccounts) => {
          if (response) {
            const accounts = response.data.map(
              (account) =>
                ({
                  access_token: account.access_token,
                  account_id: account.id,
                  name: account?.name_with_location_descriptor ?? account.name,
                  picture: account.picture.data.url,
                  workspace: AppStore.workspace,
                  user: AppStore.loggedUser,
                  provider: 'facebook',
                  profileType: 'page',
                } as SocialAccount)
            )

            Array.isArray(accounts) && allAccounts.push(...accounts)

            if (response.paging && response.paging.cursors && response.paging.cursors.after) {
              // If there is an after cursor, continue fetching recursively
              fetchAccounts(userID, { after: response.paging.cursors.after })
            } else {
              // Resolve the promise with all accounts if there are no more pages
              resolve(allAccounts)
            }
          } else {
            // Reject the promise in case of error
            reject((response as any)?.error)
          }
        }
      )
    }

    getMetaAccount(
      (loginResponse) => {
        const { userID } = loginResponse
        // Inizia la fetch ricorsiva con il cursore iniziale
        fetchAccounts(userID, paging?.cursor)
      },
      reject,
      facebookScopeMeta
    )
  })
}

export const normalizeMetaAccounts = (account: any, provider: string, type: string) => {
  const accountid = provider === 'instagram' || type === 'page' ? 'instagram_business_account.id' : 'id'
  const picture = provider === 'instagram' || type === 'page' ? 'picture.data.url' : 'icon'
  return {
    access_token: account.access_token,
    account_id: account[accountid],
    name: account.name,
    picture: account[picture],
    workspace: AppStore.workspace,
    user: AppStore.loggedUser,
    provider,
    profileType: type,
  } as SocialAccount
}

export const getIGAccounts = (paging?: { cursor: { before: string; after: string } }) => {
  return new Promise<SocialAccount[]>(async (resolve, reject) => {
    const allAccounts: any[] = []
    await FB.logout()

    const fetchAccounts = (userID, cursor) => {
      FB.api(
        `/${userID}/accounts`,
        {
          fields: ['id', 'name', 'access_token', 'instagram_business_account', 'picture'],
          after: cursor?.after ?? null, // Usa il cursore per il paging
          xfbml: true,
        },
        (response: any) => {
          if (response) {
            // Processa gli account attuali
            const accounts = response.data
              ?.filter((acc) => !!acc.instagram_business_account)
              .map(
                (account) =>
                  ({
                    access_token: account.access_token,
                    account_id: account.instagram_business_account!.id,
                    name: account.name,
                    picture: account.picture.data.url,
                    workspace: AppStore.workspace,
                    user: AppStore.loggedUser,
                    provider: 'instagram',
                    profileType: 'business',
                  } as SocialAccount)
              )

            Array.isArray(accounts) && allAccounts.push(...accounts)

            if (response.paging && response.paging.cursors && response.paging.cursors.after) {
              // Se esiste un cursore after, continua a fetchare ricorsivamente
              fetchAccounts(userID, { after: response.paging.cursors.after })
            } else {
              // Altrimenti, abbiamo finito: risolvi la promessa con tutti gli account
              resolve(allAccounts)
            }
          } else {
            // In caso di errore, rifiuta la promessa
            reject(response.error)
          }
        }
      )
    }

    getMetaAccount(
      (loginResponse) => {
        const { userID } = loginResponse
        // Inizia la fetch ricorsiva con il cursore iniziale
        fetchAccounts(userID, paging?.cursor)
      },
      reject,
      instagramScopeMeta
    )
  })
}

export const getTiktokUser = async (code: string) => {
  const res = await Socialaccounts.getTiktokUser(code)
  return res
}

export const getLinkedinAccounts = async (code: string) => {
  const res = await Socialaccounts.getLinkedinAccounts(code, window.location.origin + '/settings/social/linkedin')
  return res
}

export const getTwitterAccounts = async (code: string, codeVerifier: string) => {
  const res = await Socialaccounts.getTwitterAccounts(code, codeVerifier)
  return res
}

export const getYoutubeAccounts = async (code: string) => {
  const res = await Socialaccounts.getYoutubeAccounts(code)
  return res
}

export const getLinkedinAuthUrl = async () => {
  try {
    const url = await Socialaccounts.getRequestUrl('linkedin')
    window.location.href = url
  } catch (error) {
    showError(error)
  }
}

export const getTwitterAuthUrl = async () => {
  try {
    const stringUrl = await Socialaccounts.getRequestUrl('x')
    const codeVerifier = stringUrl.split('&code_verifier=')[1]
    const url = stringUrl.split('&code_verifier=')[0]
    if (codeVerifier !== '') localStorage.setItem('state', codeVerifier)

    window.location.href = url
  } catch (error) {
    showError(error)
  }
}

export const getTiktokAuthUrl = async () => {
  try {
    const url = await Socialaccounts.getRequestUrl('tiktok')
    window.location.href = url
  } catch (error) {
    showError(error)
  }
}

export const getYoutubeAuthUrl = async () => {
  try {
    const url = await Socialaccounts.getRequestUrl('youtube')
    window.location.href = url
  } catch (error) {
    showError(error)
  }
}

export const getGoogleMyBusinessAuthUrl = () => {
  window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${''}&redirect_uri=${''}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fbusinessmanagement.all%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fbusinessmanagement.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fbusinessmanagement.readwrite&access_type=offline&prompt=consent`
}
