import {
  ILabel,
  TColorLabel,
} from 'components/CreatePost/PreviewComponents/LabelsComponents/LabelsContentComponents/Label'
import { AppStore } from 'utils'
import { api, responseErrorCheck } from './api'
import AstersBaseResource from './AstersBaseResource'

// <-------------------------INTERFACE------------------------->
export interface IPLabel {
  workspaceId: string
  label: string
  color: TColorLabel
}

export interface IPDeleteLabel {
  labelsId: string[]
}

export interface IRDeleteLabels {
  acknowledged: boolean
  deletedCount: number
}

export interface IPUpdateLabel {
  labelId: string
  newLabel: IPLabel
}

export default class Labels extends AstersBaseResource {
  static endpoint = '/label'

  /**
   * API CALL per ottenere le labels del workspace in base all'id
   * @returns ILabel[]
   */
  static async getWorkspaceLabels(workspaceId: string = AppStore.getWorkspaceId()) {
    return api.get<ILabel[]>(`${this.endpoint}/${workspaceId}`).then(responseErrorCheck)
  }

  /**
   * API CALL per creare una label
   * @param createLabelParam
   * @returns ILabel
   */
  static async createLabel(createLabelParam: IPLabel) {
    return api.post<ILabel>(`${this.endpoint}/create`, { ...createLabelParam }).then(responseErrorCheck)
  }

  /**
   * API CALL per aggiornare una label
   * @param labelId "_id" della label sulla quale fare la modifica
   * @param updateLabelParam
   * @returns ILabel
   */
  static async updateLabel(labelId: string, newLabel: IPLabel) {
    return api.put<ILabel>(`${this.endpoint}/update/${labelId}`, { ...newLabel }).then(responseErrorCheck)
  }

  /**
   * API CALL per eliminare una o più labels (array di id)
   * @param deleteLabelParam
   * @returns IRDeleteLabels
   */
  static async deleteLabels(deleteLabelParam: IPDeleteLabel) {
    return api.delete<IRDeleteLabels>(`${this.endpoint}`, { ...deleteLabelParam }).then(responseErrorCheck)
  }
}
