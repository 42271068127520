/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'

/* ------------------- INTERFACES  */
interface Props {
  component: React.ReactNode
}

export const PreviewResult = ({ component }: Props) => {
  return <PreviewResultContainer>{component}</PreviewResultContainer>
}

const PreviewResultContainer = styled(Box)`
  width: 414px;
  height: 100%;
`
