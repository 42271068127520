import AppStore from './AppStore'
import { User, Workspace } from 'types'

export const courtesyRoutePath = {
  subscription_expired: '/subscription-expired',
  subscription_expired_free_trial: '/free-trial',
  email_validation: '/validate-email',
}

/**
 * Verifica se un utente è pagante o meno.
 * @param loggedUser utente loggato. es.: AppStore.loggedUser
 * @returns boolean
 */
export const checkIfUserIsPayed = (loggedUser: User): boolean => {
  const workspace: Workspace | undefined = loggedUser.workspaces.find(
    (w) => (w._id ?? '').toString() === AppStore.getWorkspaceId()
  )
  const ws = loggedUser.collabWorkspace.find(
    (w) => (w.workspace._id ?? '').toString() === AppStore.workspaceId
  )?.workspace

  return workspace ? checkUser(loggedUser) : checkWorkspaceOwner(ws)
}

/**
 * The function `checkUser` in TypeScript checks if a user is valid based on their plan expiration
 * date, free trial status, and the current route.
 * @param loggedUser - The `loggedUser` parameter is an object that contains information about the
 * user, such as their subscription plan details and free trial information. It is used in the
 * `checkUser` function to determine if the user is valid based on criteria like expiration date of the
 * plan, validity of free trial, and
 * @returns The function `checkUser` returns a boolean value, which is determined based on the
 * conditions checked within the function. If the `isInspectableRoute` condition is true, it returns
 * `true`. Otherwise, it returns the result of the logical OR operation between `isExpirationDateValid`
 * and `isFreeTrialValid`.
 */
export const checkUser = (loggedUser: User): boolean => {
  // const isExpirationDateValid =
  //   loggedUser?.plan?.expirationDate && new Date(loggedUser?.plan?.expirationDate) > new Date()
  // const isFreeTrialValid = loggedUser?.freeTrial && new Date(loggedUser?.freeTrial) > new Date()
  const expirationDate = loggedUser?.subscription?.activePlan?.expirationDate
  const isExpirationDateValid = expirationDate ? new Date(expirationDate) > new Date() : false

  const isInspectableRoute = checkCourtesyRoutes(window.location.pathname)
  if (isInspectableRoute) return true

  return isExpirationDateValid /*|| isFreeTrialValid */ || false
}

/**
 * The function `checkWorkspaceOwner` in TypeScript checks if the workspace owner's plan expiration
 * date or free trial expiration date is valid, or if the current route is inspectable.
 * @param {Workspace} workspace - The `workspace` parameter is an object that represents a workspace.
 * It likely contains information such as the owner of the workspace, plan expiration dates, free trial
 * expiration dates, and other relevant data related to the workspace.
 * @returns The function `checkWorkspaceOwner` returns a boolean value, either `true` or `false`.
 */
export const checkWorkspaceOwner = (workspace?: Workspace): boolean => {
  if (!workspace) return false
  const expirationDate = workspace.owner?.planExpirationDate
  const isExpirationDateValid = expirationDate ? new Date(expirationDate) > new Date() : false

  const freeTrialExpirationDate = workspace.owner?.freeTrialExpirationDate
  const isFreeTrialValid = freeTrialExpirationDate ? new Date(freeTrialExpirationDate) > new Date() : false

  const isInspectableRoute = checkCourtesyRoutes(window.location.pathname)
  if (isInspectableRoute) return true

  return /*isFreeTrialValid ||*/ isExpirationDateValid
}

/**
 * Verifica se la pagina è una pagina di cortesia
 * @param pathName
 * @returns boolean
 */
export const checkCourtesyRoutes = (pathName): boolean => {
  return Object.values(courtesyRoutePath).includes(pathName)
}

/**
 * Verifica se l'utente è pagante o meno, se non lo è lo reindirizza alla pagina di cortesia
 * @returns boolean
 */
export const validateSubscription = (navigate): boolean => {
  // Check if user has valid email
  const emailValid = !AppStore.loggedUser.temp_token
  if (!emailValid) {
    navigate(courtesyRoutePath.email_validation)
    return true
  }

  // Check if user is payed
  const subscriptionValid = checkIfUserIsPayed(AppStore.loggedUser)

  if (!subscriptionValid) {
    const route = AppStore.loggedUser?.subscription?.isTrialEnabled
      ? courtesyRoutePath.subscription_expired_free_trial
      : courtesyRoutePath.subscription_expired
    navigate(route)
  }
  return true
}

export const calculateVAT = (amount: number): string => {
  return (amount * 0.22).toFixed(2)
}
