import { SocialAccount, User, Workspace } from 'types'
import { TPermission } from 'types/Permissions'
import { AppStore } from 'utils'
import AstersBaseResource from './AstersBaseResource'
import { api, responseErrorCheck } from './api'
import { ILimitPermissionsPages } from 'utils/AppStore'

export default class Workspaces extends AstersBaseResource {
  static endpoint = '/workspaces'

  static changeTimezone(timezone: { name: string; value: string }, idWorkspace: string) {
    return api.post(`${this.endpoint}/${idWorkspace}/timezone`, { timezone }).then(responseErrorCheck)
  }

  static addHashtagToWorkspace(data: { text: string; name: string; type: string }, workspaceId: string) {
    return api.post<string[]>(`${this.endpoint}/${workspaceId}/add-hashtag`, data).then(responseErrorCheck)
  }

  static modifyModelToWorkspace(workspaceId: string, idHashtag: string, data: { text: string }) {
    return api
      .post<string[]>(`${this.endpoint}/${workspaceId}/edit-hashtag/${idHashtag}`, data)
      .then(responseErrorCheck)
  }

  static deleteModalGruop(workspaceId: string, modelId: any) {
    return api.delete<string[]>(`${this.endpoint}/${workspaceId}/hashtag/${modelId}`).then(responseErrorCheck)
  }

  static modifyName(workspaceId: string, idHashtag: string, data: { text: string }) {
    return api.post<string[]>(`${this.endpoint}/${workspaceId}/edit-name/${idHashtag}`, data).then(responseErrorCheck)
  }

  ///------------------------------  ROLES  ---------------------------------///

  static getRoles(idWorkspace: string, idRole: string) {
    return api.get<any>(`${this.endpoint}/${idWorkspace}/roles/${idRole}`).then(responseErrorCheck)
  }

  static addRole(data: { name: string; permissions: string[]; description: string }, idWorkspace: string) {
    return api.post<any>(`${this.endpoint}/${idWorkspace}/roles`, data).then(responseErrorCheck)
  }
  static modifyRole(
    data: { name: string; permissions: string[]; description: string },
    idWorkspace: string,
    idRole: string
  ) {
    return api.post<any>(`${this.endpoint}/${idWorkspace}/roles/${idRole}`, data).then(responseErrorCheck)
  }

  static deleteRole(idWorkspace: string, idRole: string) {
    return api.delete<any>(`${this.endpoint}/${idWorkspace}/roles/${idRole}`).then(responseErrorCheck)
  }
  ///------------------------------  COLABORATORS  ---------------------------------///
  // /:idWorkspace/collaborators/:roleId
  static addCollaborator(data: { email: string }, idWorkspace: string, idRole: string): Promise<Workspace> {
    const encodedData = {
      email: encodeURI(data.email),
    }
    return api
      .post<Workspace>(`${this.endpoint}/${idWorkspace}/collaborators/${idRole}`, encodedData)
      .then(responseErrorCheck)
  }

  // acceptColaboration
  static acceptColaboration(tempToken: string, workspaceId: string, password?: string, colabName?: string) {
    const data = {
      tempToken,
      workspaceId,
      colabPassword: password,
      colabName,
    }

    return api.post<User>(`${this.endpoint}/acceptColaboration`, data)
  }

  static changeColaboratorRole(idColaborator: string, newRoleId: string, idWorkspace: string = AppStore.workspaceId!) {
    return api
      .post(`${this.endpoint}/${idWorkspace}/collaborators/${idColaborator}/${newRoleId}`)
      .then(responseErrorCheck)
  }
  static getSocialAccounts(idWorkspace: string = AppStore.workspace._id!) {
    return api.get<SocialAccount[]>(`${this.endpoint}/${idWorkspace}/socialaccounts`).then(responseErrorCheck)
  }

  static async getAiDiscover() {
    return api.get<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/aiDiscover`).then(responseErrorCheck)
  }

  /**
   * Delete collaborator from workspace
   * @param idCollaborator : identifier of the collaborator
   * @param idWorkspace : identifier of the workspace
   * @returns
   */
  static async deleteCollaborator(idCollaborators: string[], idWorkspace: string) {
    return api
      .delete(`${this.endpoint}/deleteCollaborator/${idWorkspace}`, {
        idCollaborators,
      })
      .then(responseErrorCheck)
  }

  static async checkPermission(permissionToCheck?: TPermission, workspaceId?: string) {
    return api
      .get<Workspace>(`${this.endpoint}/checkPermission/${workspaceId ?? AppStore.getWorkspaceId()}`, {
        permissions: permissionToCheck,
      })
      .then(responseErrorCheck)
  }

  static async checkInitPermission(permissionToCheck?: TPermission, workspaceId?: string) {
    return api
      .get<ILimitPermissionsPages>(
        `${this.endpoint}/checkInitialPermission/${workspaceId ?? AppStore.getWorkspaceId()}`
      )
      .then(responseErrorCheck)
  }
}
