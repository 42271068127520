/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { IResponseCalendarPosts } from 'api/PostV2'
import { formatMaxNumber } from 'api/Utils'
import { Box, Text } from 'components'
import moment from 'moment'
import { useCalendarStore } from 'stores/CalendarStore'
import { AppStore } from 'utils'
import { SocialCellCard } from './SocialCellCard'
import { useNavigate, useSearchParams } from 'react-router-dom'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  date: moment.Moment
  postData: IResponseCalendarPosts
}

export const CalendarCell = ({ date, postData }: Props) => {
  /* ----------------- ROUTER  */
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const paramsDate = searchParams.get('date') as string

  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */
  const { month } = useCalendarStore((s) => ({
    month: s.month,
  }))

  const { setCalendarSection, setDay, setMonth, setYear } = useCalendarStore()

  /* ----------------- VARIABLES  */

  const isDayFromCurrentMonth: boolean = date.month() === month

  const isToday: boolean = date.isSame(moment(), 'day') && isDayFromCurrentMonth

  const bgColor: string = isToday
    ? AppStore.theme.o.lightBlue
    : !isDayFromCurrentMonth
    ? AppStore.theme.o.lightGrey
    : AppStore.theme.o.lightestGrey

  const textColor: string = !isDayFromCurrentMonth ? AppStore.theme.o.grey : AppStore.theme.o.darkGrey

  /* ----------------- METHODS  */
  /**
   * Function to handle the click on the cell, it will navigate to the day section if the day is from the current month, otherwise it will change the month
   */
  const handleClickCell = (e) => {
    e.stopPropagation()
    // setMonth(date.month())
    // setYear(date.year())
    navigate(`/calendar?view=month&date=${date.year()}-${date.month() + 1}`)

    if (date.month() === month) {
      // setCalendarSection('day')
      // setDay(date.date())
      navigate(`/calendar?view=day&date=${date.year()}-${date.month() + 1}-${date.date()}`)
    }
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <CalendarCellContainer onClick={(e) => handleClickCell(e)} style={{ backgroundColor: bgColor }}>
      <UpperRow>
        <NumberOfDay style={{ color: textColor }}>{date.date()}</NumberOfDay>

        {postData?.total && <TotalPostOfDay>{formatMaxNumber(postData?.total, 999)}</TotalPostOfDay>}
      </UpperRow>

      <SocialPostsInfoContainer>
        {postData?.socials?.map((social) => (
          <SocialCellCard key={social.provider} provider={social.provider} number={social.totalPost} />
        ))}
      </SocialPostsInfoContainer>
    </CalendarCellContainer>
  )
}

const CalendarCellContainer = styled(Box)`
  height: 100%;
  padding: 8px;
  gap: 4px;
  border: 1px solid transparent;

  &:hover {
    background-color: ${() => AppStore.theme.o.surface} !important;
    border-color: ${() => AppStore.theme.o.lightGrey};
  }
`

const UpperRow = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 20px;
`

const NumberOfDay = styled(Text)`
  font-family: 'Aktiv Grotesk';
  font-size: 16px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px !important;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TotalPostOfDay = styled(Box)`
  height: 100%;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Aktiv Grotesk';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${() => AppStore.theme.o.black};
  background-color: ${() => AppStore.theme.o.lightGrey};
`

const SocialPostsInfoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  width: 101%;
  position: relative;
  left: -2px;
`
