/* ------------------- IMPORTS */
import { useEffect } from 'react'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { Global } from './BasicContentComponents/Previews/Global/Global'
import { X } from './BasicContentComponents/Previews/X/X'
import { Facebook } from './BasicContentComponents/Previews/Facebook/Facebook'
import { Tiktok } from './BasicContentComponents/Previews/Tiktok/Tiktok'
import { Linkedin } from './BasicContentComponents/Previews/Linkedin/Linkedin'
import { checkYTPreview, getPostByAccountId, getPreviewByVariant } from 'utils/PostUtils/Post'
import { Instagram } from './BasicContentComponents/Previews/Instagram/Instagram'
import { Youtube } from './BasicContentComponents/Previews/Youtube/Youtube'

/* ------------------- INTERFACES  */
interface Props {}

export const BasicContent = ({}: Props) => {
  /* ----------------- ZUSTAND  */
  const { activePost, activePreview, activeAccountId, activeMediaType } = useCreatePostStore((s) => ({
    activePost: s.activePost as any,
    activePreview: s.activePreview,
    activeAccountId: s.activeAccountId,
    activeMediaType: s.activeMediaType,
  }))

  const { setActivePost, setActivePreview } = useCreatePostStore.getState()

  /* ----------------- USEEFFECT  */
  // Setta post attivo in base all'id del account selezionato
  useEffect(() => {
    checkYTPreview(setActivePost(getPostByAccountId()!))
  }, [activeAccountId])

  // Cambio preview in base al post attivo
  useEffect(() => {
    checkYTPreview(setActivePreview(getPreviewByVariant(activePost, activeMediaType)))
  }, [activePost, activeMediaType])

  const content = {
    GLOBAL: <Global />,
    X: <X />,
    FACEBOOK: <Facebook />,
    TIKTOK: <Tiktok />,
    LINKEDIN: <Linkedin />,
    INSTAGRAM_STANDARD: <Instagram active={'INSTAGRAM_STANDARD'} />,
    INSTAGRAM_REEL: <Instagram active={'INSTAGRAM_REEL'} />,
    INSTAGRAM_STORY: <Instagram active={'INSTAGRAM_STORY'} />,
    YOUTUBE_STANDARD: <Youtube active={'YOUTUBE_STANDARD'} />,
    YOUTUBE_SHORT: <Youtube active={'YOUTUBE_SHORT'} />,
  }

  return content[activePreview]
}
