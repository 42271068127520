import { FolderOutlined } from '@ant-design/icons'
import { Tracker } from 'api/Tracker'
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { Input } from 'components/Input'
import React, { useState } from 'react'
import { T } from 'translation/i18n'
import { AppStore } from 'utils'
import { TRACKEREVENTS } from 'utils/eventsTracker'

export const RenameRoleModal: React.FC<{
  onConfirm: (name: string) => void
}> = ({ onConfirm }) => {
  const [name, setName] = useState('')

  const onSubmit = () => {
    onConfirm(name)
    AppStore.closeAllModal()
    Tracker.trackEvent(TRACKEREVENTS.WORKSPACE_ROLE_CREATION_INITIATED, { role_name: name })
  }

  return (
    <Box row flex vcenter p={50}>
      <form style={{ display: 'flex', flex: 1 }} onSubmit={onSubmit}>
        <Box justify={'space-evenly'} flex>
          <Input
            width={'30vw'}
            autoFocus
            prefix={<FolderOutlined style={{ color: AppStore.theme.o.black }} />}
            style={{ marginBottom: 20 }}
            defaultValue={name}
            placeholder={T.teamMembers.roleName}
            onChange={setName}
          />
          <Button size="small" variant="neutral" type="submit">
            {T.settings.save}
          </Button>
        </Box>
      </form>
    </Box>
  )
}
