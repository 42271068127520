import React, { Component } from 'react'

export default class RemoveDemoScript extends Component {
  componentDidMount(): void {
    let doc = document.getElementById('demoScript')?.remove()
    doc = document.getElementById('gg')?.remove()
    // @ts-ignore
    document.getElementsByClassName('iubenda-tp-btn iubenda-cs-preferences-link')[1]?.remove()
    const node = document.querySelector('[title="Le tue preferenze relative al consenso"]')?.remove()
  }
  render() {
    return <></>
  }
}
