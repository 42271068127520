/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import Medias from 'api/Medias'
import { Box, Icons } from 'components'
import { useEffect, useRef, useState } from 'react'
import { useAnalyticsStore } from 'stores/AnalyticsStore'
import { AnalyticsDate, IReportConfig } from 'types/AnalyticsInferfaces'
import { AppStore, T } from 'utils'
import { SelectedDateSetUp } from './SelectedDateSetUp'
import Utils from 'api/Utils'

/* ------------------- INTERFACES  */
interface Props {
  reportConfig: IReportConfig
  setReportConfig: (reportConfig: IReportConfig) => void
}

export const CreateReportSetup = ({ reportConfig, setReportConfig }: Props) => {
  /* ------------------- VARIABLES */
  const reportPreview = useAnalyticsStore.getState().reportPreview
  const { setSelectedDatesReports } = useAnalyticsStore.getState()

  const unsubSelectedDate = useAnalyticsStore.subscribe((state) => {
    state.selectedDatesReports !== selectedDate && setSelectedDate(state.selectedDatesReports)
  })

  /* ------------------- STATE  */
  const [selectedDate, setSelectedDate] = useState<AnalyticsDate | null>(null)
  const [reportLogo, setReportLogo] = useState<string>(reportConfig.info.reportLogo ?? '')

  /* ------------------- REF  */
  const inputRef = useRef<HTMLInputElement | null>(null)
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  /* ------------------- METHODS  */
  const handleLogoClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const onFileChangeReportImg = async (event) => {
    const selectedFile = event.target.files[0]
    if (!selectedFile) {
      setReportLogo('')
      return
    }

    const formData = new FormData()
    formData.append('file', selectedFile, selectedFile.name)
    formData.append('prefix', 'report_logo/')

    try {
      // const response = await Medias.upload(formData)
      const extention = selectedFile.name.split('.').pop()
      const fileUrlLoaded = await Utils.saveImageRemoteBlob(
        selectedFile,
        `${AppStore.workspaceId}/reports/report_image_${new Date().getTime()}.${extention}`,
        'report_logo',
        true
      )

      const data = fileUrlLoaded?.data
      if (data && data.url) {
        setReportLogo(data.url) // Aggiorna l'immagine caricata
      } else {
        setReportLogo('') // Resetta l'immagine se il caricamento fallisce
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onChangeSelectedDateReports = (dates: any) => {
    if (reportPreview) {
      setSelectedDatesReports(dates)
    } else {
      setReportConfig({
        ...reportConfig,
        since: dates.startDate,
        until: dates.endDate,
      })
    }
  }

  const setLast30Days = () => {
    const currentDay = new Date()
    const thirtyDaysAgo = new Date()
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 29)

    const since = thirtyDaysAgo.toISOString()
    const until = currentDay.toISOString()

    return [since, until]
  }

  /* ------------------- USEEFFECT  */
  useEffect(() => {
    const [since, until] = setLast30Days()

    setTimeout(() => {
      setReportConfig({
        ...reportConfig,
        info: {
          ...reportConfig.info,
          reportLogo: reportLogo,
        },
        since,
        until,
      })
    }, 200)
  }, [reportLogo])

  useEffect(() => {
    inputRef?.current?.focus()
    unsubSelectedDate()
  }, [])

  useEffect(() => {
    const dates = reportPreview
      ? {
          startDate: reportPreview?.since,
          endDate: reportPreview?.until,
        }
      : {
          startDate: reportConfig?.since,
          endDate: reportConfig?.until,
        }

    // @ts-ignore
    setSelectedDate(dates)
  }, [reportConfig, reportPreview])

  return (
    <ReportSetupContainer className="report_setup_container">
      <SelectPeriodContainer>
        <SelectPeriodText>{T.analytics.selectAPeriod}</SelectPeriodText>

        <SelectedDateSetUp selectedDate={selectedDate} setSelectedDate={onChangeSelectedDateReports} />
      </SelectPeriodContainer>

      <ReportNameContainer>
        <ReportNameText>{T.analytics.reportName}</ReportNameText>

        <ReportNameInput
          ref={inputRef}
          className="report_name_input"
          type="text"
          placeholder={T.analytics.enterTheNameOfTheReport}
          value={reportConfig.info.reportName ?? ''}
          onChange={(e) =>
            setReportConfig({
              ...reportConfig,
              info: {
                ...reportConfig.info,
                reportName: e.target.value,
              },
            })
          }
        />
      </ReportNameContainer>

      {!reportConfig.info.reportLogo && (
        <ReportLogoWithNoImg className="report_logo_with_no_img" onClick={handleLogoClick}>
          <input
            type="file"
            ref={fileInputRef}
            onChange={onFileChangeReportImg}
            style={{ display: 'none' }}
            accept="image/*"
          />
          <Icons.uploadFile />
          {T.analytics.uploadLogo}
        </ReportLogoWithNoImg>
      )}

      {reportConfig.info.reportLogo && (
        <ReportLogoWithImg className="report_logo_with_img" onClick={handleLogoClick}>
          <input
            type="file"
            ref={fileInputRef}
            onChange={onFileChangeReportImg}
            style={{ display: 'none' }}
            accept="image/*"
          />
          <img src={reportConfig.info.reportLogo} />
          <Icons.uploadFile />
        </ReportLogoWithImg>
      )}

      <InformativeTextLogo className="informative_text_logo">
        {T.analytics.recommendedDimensionLogo}:
        <br />
        512x512, 1024x1024 e 1920x1920
      </InformativeTextLogo>
    </ReportSetupContainer>
  )
}

const SelectPeriodText = styled(Box)`
  font-size: 14px;
  font-weight: 500;
  color: ${() => AppStore.theme.o.black};
`

const SelectPeriodContainer = styled(Box)`
  gap: 8px;
`

const ReportNameContainer = styled(Box)`
  gap: 8px;
`

const ReportNameText = styled(Box)`
  font-size: 14px;
  font-weight: 500;
  color: ${() => AppStore.theme.o.black};
`

const ReportSetupContainer = styled(Box)`
  &.report_setup_container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
  }
`

const ReportNameInput = styled.input`
  &.report_name_input {
    display: flex;
    width: 299px;
    height: 43px;
    padding: 12px 16px;
    border-radius: 14px;
    border: 1px solid ${() => AppStore.theme.o.grey};
    background: ${() => AppStore.theme.o.surface};
    transition: 300ms ease-in-out;

    :hover,
    :focus {
      background: ${() => AppStore.theme.o.lightestGrey};
    }
  }
`

const ReportLogoWithNoImg = styled(Box)`
  &.report_logo_with_no_img {
    width: 146px;
    height: 64px;
    border-radius: 14px;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 14px;
    border: 1px solid ${() => AppStore.theme.o.grey};
    background: ${() => AppStore.theme.o.lightestGrey};

    color: ${() => AppStore.theme.o.black};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    &:hover {
      background: ${() => AppStore.theme.o.grey};
    }

    svg {
      fill: ${() => AppStore.theme.o.black};
    }
  }
`

const ReportLogoWithImg = styled(Box)`
  &.report_logo_with_img {
    width: 146px;
    height: 64px;
    border-radius: 14px;
    cursor: pointer;
    position: relative;
    border: 1px solid ${() => AppStore.theme.o.grey};
    background: ${() => AppStore.theme.o.lightestGrey};

    img {
      width: 90%;
      height: 90%;
      border-radius: 14px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: contain;
    }

    svg {
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: 0.3s ease-in-out;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      svg {
        border-radius: 12px;
        background-color: rgba(128, 128, 128, 0.5);
        opacity: 1;
        fill: ${() => AppStore.theme.o.black};
      }
    }
  }
`

const InformativeTextLogo = styled(Box)`
  &.informative_text_logo {
    color: ${() => AppStore.theme.o.darkGrey};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
  }
`
