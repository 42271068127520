import { AppStore } from 'utils'
import { Box } from './Box'
import { Icons } from './Icons'
import styled from '@emotion/styled'
import { useState } from 'react'

/* ******************* INTERFACE ******************* */
interface Props {
  onClick?: (e?: Event) => void
  style?: React.CSSProperties
  iconStyle?: React.CSSProperties
}

export const ForwardIcon = ({ onClick, style, iconStyle }: Props) => {
  return (
    <BackwardIconContainer onClick={onClick} style={{ ...style }}>
      <Icons.forward style={iconStyle} width={12} height={12} />
    </BackwardIconContainer>
  )
}

const BackwardIconContainer = styled(Box)`
  cursor: pointer;
  padding: 8px;
  background-color: ${() => AppStore.theme.o.surface};
  svg {
    fill: ${() => AppStore.theme.o.black};
  }

  :hover {
    box-shadow: none;
    background-color: ${() => AppStore.theme.o.lightGrey};
    svg {
      fill: ${() => AppStore.theme.o.blueOnLight};
    }
  }
`
