/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Button } from 'components'
import { useNavigate } from 'react-router-dom'
import { AppStore, T, showSuccess } from 'utils'
import { getActiveText, updateActive } from 'utils/PostUtils/Post'

/* ------------------- INTERFACES  */
interface Props {
  generatedText: string
}

export const AddTextToPostButton = ({ generatedText }: Props) => {
  /* ------------------- ROUTER  */
  const navigate = useNavigate()

  return (
    <AddTextToPostButtonContainer
      variant="neutral"
      onClick={() => {
        updateActive({ text: getActiveText() + ' ' + generatedText })
        navigate(`/post/preview/basic`)
        showSuccess(T.postPage.savedSucces)
      }}
    >
      {T.postPage.add}
    </AddTextToPostButtonContainer>
  )
}

const AddTextToPostButtonContainer = styled(Button)`
  padding: 8px 32px !important;
  min-height: 34px !important;

  color: ${() => AppStore.theme.o.black};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
