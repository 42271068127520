/* ------------------- IMPORTS */
import { useEffect, useState } from 'react'
import ResponsiveGridLayout from 'react-grid-layout'
import { IDataReport, IReportConfig, IReportDataComplete } from 'types/AnalyticsInferfaces'
import {
  getClassNameGridItem,
  getMetricWidth,
  isIReportDataComplete,
  saveLayout,
  setPositions,
} from 'utils/AnalyticsUtils'
import { RenderGraph } from './RenderGraph'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  report: IReportConfig
}

export const ReportContent = ({ report }: Props) => {
  /* ----------------- STATE  */
  const [scale, setScale] = useState<number>(window.innerWidth / 1920)

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */
  const renderReports = () => {
    return report.data.map((g: IReportDataComplete | IDataReport, index: number) => {
      if (!isIReportDataComplete(g)) return <></> // per evadere l'errore di typescript

      return (
        <div
          key={g.metricRef}
          className={getClassNameGridItem(g.metricGraph)}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: getMetricWidth(g.metricGraph),
          }}
          data-grid={setPositions(g, index)}
        >
          <RenderGraph key={g.metricRef} graph={g} reportInfo={report} />
        </div>
      )
    })
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    addEventListener('resize', () => {
      setScale(window.innerWidth / 1920)
    })

    return () => {
      removeEventListener('resize', () => {
        setScale(window.innerWidth / 1920)
      })
    }
  }, [])

  return (
    <ResponsiveGridLayout
      className="layout grid_container_layout"
      style={{ position: 'relative', alignItems: 'start' }}
      useCSSTransforms={false}
      rowHeight={428}
      cols={2}
      width={1117}
      onDragStop={(e) => saveLayout(e)}
      isDraggable={true}
      isResizable={true}
      resizeHandles={['e', 'se', 's']}
      draggableHandle=".drag-handle"
      droppingItem={{
        i: 'n',
        w: 2,
        h: 2,
      }}
      isBounded={true}
      transformScale={scale}
      margin={[24, 32]}
    >
      {renderReports()}
    </ResponsiveGridLayout>
  )
}
