/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { AVAILABLE_SOCIALS } from 'utils/Constants/Constants'
import { Box } from 'components'
import { AppStore } from 'utils'
import {
  filterSocialAccountsByProvider,
  filterSocialAccountsBySearchTerm,
  renderSocialGridForProvider,
} from 'utils/PostUtils/SocialPost'

/* ------------------- INTERFACES  */
interface Props {
  textInputFilter: string // Il filtro input search
}

export const SocialsGridsContainer = ({ textInputFilter }: Props) => {
  /* ------------------- VARIABLES  */
  // Get all added social accounts
  const allAddedAccounts = AppStore.socialAccounts

  // Get available social providers
  const availableProviders = AVAILABLE_SOCIALS

  // Map available providers to social grid items
  const socialGridItems = availableProviders.map((provider, index) => {
    const socialAccounts = filterSocialAccountsByProvider(allAddedAccounts, provider)

    const filteredAccounts = filterSocialAccountsBySearchTerm(socialAccounts, textInputFilter)

    const isLastItem = index === availableProviders.length - 1
    return renderSocialGridForProvider(provider, filteredAccounts, isLastItem)
  })

  return (
    <SocialContentContainer style={{ width: '100%' }} className="social_content_container">
      {socialGridItems}
    </SocialContentContainer>
  )
}

const SocialContentContainer = styled(Box)`
  &.social_content_container {
    display: flex;
    flex-direction: column;
    padding: 8px 32px;
    gap: 24px;
    width: 100%;
  }
`
