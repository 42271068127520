/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Button, Icons } from 'components'
import { useCalendarStore } from 'stores/CalendarStore'
import { AppStore, T } from 'utils'
import moment from 'moment'
import { daysInPreviousMonth, getDaysInMonth } from 'api/Utils'
import { useNavigate } from 'react-router-dom'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {}

export const CalendarNavigation = ({}: Props) => {
  /* ----------------- ROUTER  */
  const navigate = useNavigate()

  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */
  const { month, year, calendarSection, day } = useCalendarStore((s) => ({
    month: s.month,
    year: s.year,
    day: s.day,
    calendarSection: s.calendarSection,
  }))

  const { setMonth, setYear, setDay } = useCalendarStore()

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /**
   * Function to change the month and year
   * @param type  next or back
   */
  const changeMonthAndYear = (type: 'next' | 'back') => {
    const newMonth = type === 'next' ? (month + 1) % 12 : (month - 1 + 12) % 12
    let newYear: number = year

    // Update year if necessary (consider edge cases for January and December)
    if (type === 'next' && month === 11) {
      newYear = year + 1
    } else if (type === 'back' && month === 0) {
      newYear = year - 1
    }

    navigate(`/calendar?view=${calendarSection}&date=${newYear}-${newMonth + 1}`)

    // setYear(newYear)
    // setMonth(newMonth)

    return { newMonth, newYear }
  }

  /**
   * Function to go back in the calendar, change the effect based on the section (month or day)
   */
  const handleBack = () => {
    switch (calendarSection) {
      case 'month':
        changeMonthAndYear('back')
        break
      case 'day':
        if (day === 1) {
          const { newYear, newMonth } = changeMonthAndYear('back')
          const newDay = daysInPreviousMonth(month, year)
          navigate(`/calendar?view=${calendarSection}&date=${newYear}-${newMonth + 1}-${newDay}`)

          // setDay(newDay)
        } else {
          const newDay = day - 1
          navigate(`/calendar?view=${calendarSection}&date=${year}-${month + 1}-${newDay}`)
          // setDay(newDay)
        }
        break
    }
  }

  /**
   * Function to set the calendar to today
   */
  const handleToday = () => {
    const today = moment()

    // setYear(today.year())
    // setMonth(today.month())
    // setDay(today.date())

    if (calendarSection === 'day') {
      navigate(`/calendar?view=${calendarSection}&date=${today.year()}-${today.month() + 1}-${today.date()}`)
    } else {
      navigate(`/calendar?view=${calendarSection}&date=${today.year()}-${today.month() + 1}`)
    }
  }

  /**
   * Function to go next in the calendar, change the effect based on the section (month or day)
   */
  const handleNext = () => {
    switch (calendarSection) {
      case 'month':
        changeMonthAndYear('next')
        break
      case 'day':
        const daysInCurrentMonth = getDaysInMonth(month, year)
        if (day === daysInCurrentMonth) {
          const { newYear, newMonth } = changeMonthAndYear('next')
          const newDay = 1
          navigate(`/calendar?view=${calendarSection}&date=${newYear}-${newMonth + 1}-${newDay}`)
          // setDay(newDay)
        } else {
          const newDay = day + 1
          navigate(`/calendar?view=${calendarSection}&date=${year}-${month + 1}-${newDay}`)
          // setDay(newDay)
        }
        break
    }
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <CalendarNavigationContainer>
      {/* BACK */}
      <CalendarNavigationButton variant="neutral" onClick={handleBack}>
        <Icons.dropdownArrow width={24} height={24} fill={AppStore.theme.o.black} style={{ rotate: '270deg' }} />
      </CalendarNavigationButton>

      {/* TODAY */}
      <CalendarNavigationButton variant="neutral" className="today_btn" onClick={handleToday}>
        {T.calendarPage.today}
      </CalendarNavigationButton>

      {/* NEXT */}
      <CalendarNavigationButton variant="neutral" onClick={handleNext}>
        <Icons.dropdownArrow width={24} height={24} fill={AppStore.theme.o.black} style={{ rotate: '90deg' }} />
      </CalendarNavigationButton>
    </CalendarNavigationContainer>
  )
}

const CalendarNavigationContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
`

const CalendarNavigationButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 14px;
  height: 40px !important;
  min-height: 40px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;

  &.today_btn {
    color: ${() => AppStore.theme.o.black};
    text-align: center;
    font-family: 'Aktiv Grotesk';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 8px 48px !important;
  }
`
