import { IArticle, IArticleShort, IBoard } from 'pages/ai-discover/aiDiscoverInterface'
import { api, responseErrorCheck } from './api'
import AstersBaseResource from './AstersBaseResource'
import { AppStore } from 'utils'

// <-------------------------INFERFACE------------------------->

export default class Boards extends AstersBaseResource {
  static endpoint = '/boards'

  static async getBoardList() {
    return api
      .get<any>(`${this.endpoint}/${AppStore.discoverUser?._id}/${AppStore.getWorkspaceId()}/boardList`)
      .then(responseErrorCheck)
  }

  static async createBoard(board: IBoard, article?: IArticle) {
    return api
      .post<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/${AppStore.discoverUser?._id}/createBoard`, {
        board,
        article,
      })
      .then(responseErrorCheck)
  }

  static async changeArticleInANewBoard(boardId: string, article: IArticleShort, newBoardId: string) {
    return api
      .post<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/${boardId}/${newBoardId}/changeArticleBoard`, {
        article,
      })
      .then(responseErrorCheck)
  }

  static async getBoard(boardId: string) {
    return api.get<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/${boardId}/boardById`).then(responseErrorCheck)
  }

  static async updateBoard(board: IBoard) {
    return api
      .put<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/${board._id}/updateBoard`, { board })
      .then(responseErrorCheck)
  }

  static async saveArticleInBoard(boardId: string, article?: IArticle) {
    return api
      .post<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/saveArticle`, { boardId, article })
      .then(responseErrorCheck)
  }

  static async removeArticleInBoard(boardId: string, articleId: string | string[]) {
    return api
      .put<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/${boardId}/removeArticleInBoard`, { articleId })
      .then(responseErrorCheck)
  }

  static async deleteBoard(boardId: string | string[]) {
    return api.delete<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/delete`, { boardId }).then(responseErrorCheck)
  }
}
