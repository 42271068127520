/* ******************* IMPORTS ******************* */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { useEffect, useState } from 'react'
import { AppStore, T } from 'utils'
import { TSidebarType } from '../aiDiscoverInterface'
import { useDiscoverStore } from 'stores/DiscoverStore'

/* ******************* INTERFACE ******************* */

interface Props {
  sidebarType: TSidebarType
  searchContent?: string
  setSearchContent?: (string) => void
}

const AsideHeader = ({ sidebarType, searchContent, setSearchContent }: Props) => {
  /* ____________________ STATE ____________________ */
  const [isInputFocus, setIsInputFocus] = useState<boolean>(false)
  const [sidebarTitle, setsidebarTitle] = useState<string>('')

  /* ____________________ ZUSTAND  ____________________ */
  const { suggestedTopicList } = useDiscoverStore((state) => ({
    suggestedTopicList: state.suggestedTopicList,
  }))

  /* ____________________ VARIABLES ____________________ */
  const boards = AppStore.discoverUserBoardList
  const topics = AppStore.discoverUser?.topicsId ?? []
  /* ____________________ METHODS ____________________ */

  const sidebarTitleChange = () => {
    if (sidebarType === 'follow') {
      setsidebarTitle(T.aiDiscoverPage.sidebar.sidebarTitleFollowed)
    } else if (sidebarType === 'suggested') {
      setsidebarTitle(T.aiDiscoverPage.sidebar.sidebarTitleSuggested)
    } else if (sidebarType === 'board') {
      setsidebarTitle(T.aiDiscoverPage.sidebar.sidebarTitleBoard)
    }
  }

  /* ____________________ USEEFFECT ____________________ */

  useEffect(() => {
    sidebarTitleChange()
  }, [])

  return (
    <AsideHeaderContainer>
      <AsideHeaderTitle>
        <h4>{sidebarTitle}</h4>
        {sidebarType === 'follow' && <span>{topics.length}</span>}
        {sidebarType === 'suggested' && <span>{suggestedTopicList.length}</span>}
        {sidebarType === 'board' && <span>{boards.length}</span>}
      </AsideHeaderTitle>

      {/* Se non ci sono topics mostra questo */}
      {topics.length <= 0 && sidebarType === 'follow' && (
        <AsideHeaderNoContent>{T.aiDiscoverPage.sidebar.noArgumentFollowed}</AsideHeaderNoContent>
      )}

      {/* Se non ci sono boards mostra questo */}
      {boards.length <= 0 && sidebarType === 'board' && (
        <AsideHeaderNoContent>{T.aiDiscoverPage.sidebar.noBoardsCreated}</AsideHeaderNoContent>
      )}

      {/* L'input sidebar follow */}
      {topics.length > 0 && sidebarType === 'follow' && (
        <AsideHeaderInput className={isInputFocus ? 'inputFocus' : ''}>
          <Icons.searchIcon fill={AppStore.theme.o.darkGrey} />
          <input
            onFocus={() => setIsInputFocus(true)}
            onBlur={() => setIsInputFocus(false)}
            type="text"
            placeholder={T.aiDiscoverPage.sidebar.search}
            value={searchContent}
            onChange={(e) => setSearchContent!(e.target.value)}
          />
        </AsideHeaderInput>
      )}

      {/* L'input sidebar board */}
      {boards.length > 0 && sidebarType === 'board' && (
        <AsideHeaderInput className={isInputFocus ? 'inputFocus' : ''}>
          <Icons.searchIcon fill={AppStore.theme.o.darkGrey} />
          <input
            onFocus={() => setIsInputFocus(true)}
            onBlur={() => setIsInputFocus(false)}
            type="text"
            placeholder={T.aiDiscoverPage.sidebar.search}
            value={searchContent}
            onChange={(e) => setSearchContent!(e.target.value)}
          />
        </AsideHeaderInput>
      )}
    </AsideHeaderContainer>
  )
}

export default AsideHeader

const AsideHeaderContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding-right: 16px;
`

const AsideHeaderTitle = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h4 {
    margin: 0;
    color: ${() => AppStore.theme.o.black};
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.21px;
  }

  span {
    color: ${() => AppStore.theme.o.darkGrey};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`

const AsideHeaderNoContent = styled(Box)`
  margin-top: 32px;
  display: flex;
  gap: 16px;

  display: flex;
  padding: 16px;
  align-items: center;
  align-self: stretch;
  text-align: center;

  border-radius: 14px;
  background: ${() => AppStore.theme.o.lightestGrey};

  color: ${() => AppStore.theme.o.black};

  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

const AsideHeaderInput = styled(Box)`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  gap: 12px;

  padding: 12px 16px;
  align-items: center;

  border-radius: 14px;
  border: 1px solid ${() => AppStore.theme.o.grey};
  background: ${() => AppStore.theme.o.lightestGrey};

  input {
    border: none;
    width: 100%;
    background-color: transparent;

    color: ${() => AppStore.theme.o.darkGrey};
    text-align: left;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  :hover {
    background-color: ${() => AppStore.theme.o.lightGrey};
  }

  &.inputFocus {
    background-color: ${() => AppStore.theme.o.lightGrey};
  }
`
