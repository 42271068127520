import styled from '@emotion/styled'
import { Box } from 'components/Box'
import { Icons } from 'components/Icons'
import React from 'react'
import { AppStore, T } from 'utils'

/* ___________________ INTERFACE ___________________ */
interface Props {
  forCategories?: string[]
}

const NotifyEmpty = ({ forCategories }: Props) => {
  return (
    <EmptyContainer style={forCategories ? { paddingInline: 12, textAlign: 'center', width: 250 } : {}}>
      {/* __________DEFAULT__________ */}
      <Icons.cryBell />
      {!forCategories && T.emptyNotify}
      {/* __________ WITH FILTERS__________ */}
      {forCategories ? (forCategories?.length === 1 ? T.emptyNotifyForCategory : T.emptyNotifyForCategories) : null}
    </EmptyContainer>
  )
}

export default NotifyEmpty

const EmptyContainer = styled(Box)`
  background-color: ${() => AppStore.theme.o.lightestGrey};
  color: ${() => AppStore.theme.o.darkGrey};
  height: 103px;
  min-width: 154px;
  width: fit-content;
  max-width: 200px;
  font-size: 12px;
  font-weight: 600;
  line-height: 15.36px;
  letter-spacing: 0.12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-inline: 12px;
  margin-inline: auto;
  border-radius: 8px;

  svg {
    path {
      fill: ${() => AppStore.theme.o.darkGrey} !important;
    }
  }
`
