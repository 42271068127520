/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'

/* ------------------- INTERFACES  */
interface Props {
  content: React.ReactNode
  showFooter?: boolean
}

export const ContentFooterStructure = ({ content, showFooter = true }: Props) => {
  return (
    <ContentFooterStructureContainer className={`content_footer_structure ${showFooter ? 'show' : 'hide'}`}>
      {content}
    </ContentFooterStructureContainer>
  )
}

const ContentFooterStructureContainer = styled(Box)`
  &.content_footer_structure {
    &.show {
      height: 56px;
      max-height: 56px;
      margin-top: 16px;
      margin-bottom: 16px;
      z-index: 1;
      opacity: 1;
    }

    &.hide {
      height: 0px;
      max-height: 0px;
      margin-top: 0px;
      z-index: -1;
      opacity: 0;
    }
  }
`
