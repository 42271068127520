/* ------------------- IMPORTS */
import {
  ContentContainerStructure,
  ContentFooterStructure,
  ContentHeaderStructure,
  ContentStructure,
} from 'components/UI'
import { useCalendarStore } from 'stores/CalendarStore'
import { CalendarFooter } from './CalendarFooter'
import { ContentCalendar } from './Content/ContentCalendar'
import { HeaderCalendar } from './Content/HeaderCalendar'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {}

export const CalendarContent = ({}: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */
  const { calendarSection, selectedPostsId } = useCalendarStore((s) => ({
    calendarSection: s.calendarSection,
    selectedPostsId: s.selectedPostsId,
  }))

  /* ----------------- VARIABLES  */
  const showContentFooter: boolean = calendarSection === 'day' && selectedPostsId.length > 0 ? true : false

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <ContentStructure
      width={1320}
      header={<ContentHeaderStructure content={<HeaderCalendar />} mb="0" />}
      content={
        <ContentContainerStructure
          content={<ContentCalendar />}
          noShadow
          bgColor="transparent"
          padding="0"
          borderRadius="0"
        />
      }
      footer={<ContentFooterStructure showFooter={showContentFooter} content={<CalendarFooter />} />}
      styles={{
        marginLeft: 0,
        marginRight: 'auto',
      }}
    />
  )
}
