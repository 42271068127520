import styled from '@emotion/styled'
import Utils from 'api/Utils'
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { Icons } from 'components/Icons'
import { Text } from 'components/Text'
import { Component } from 'react'
import { AppStore } from 'utils'
import { navigate, navigateBack } from 'utils/router'

interface Props {
  goNext: any
  mock: any
  selectCategory: any
  goBack: any
}
export default class SecondPage extends Component<Props> {
  render() {
    const SelectionInput = [
      'Marketing e Comunicazione',
      'Sport e Benessere',
      'Formazione e Consulenza',
      'Industria e Servizi',
      'Horeka e Turismo',
      'Vendita al dettaglio',
    ]
    return (
      <Box mt={'15vh'}>
        <Container>
          <InnerContainer height={'85vh'} style={{ maxHeight: '85vh' }} scroll>
            <MiniBox center>
              <Text weight={700} fontSize={16} style={{ textAlign: 'center', marginBottom: 8 }}>
                Prima di iniziare,<br></br> puoi dirci qual è il tuo settore?
              </Text>
              <Text weight={400} fontSize={14} style={{ textAlign: 'center' }}>
                Questa informazione ci servirà per personalizzare al meglio la tua esperienza
              </Text>
            </MiniBox>

            {this.props.mock.map((e, index) => (
              <Selectble
                onClick={async () => {
                  this.props.goNext()
                  this.props.selectCategory(e)
                  !navigate('/demo/step3')
                  await Utils.saveOnAirtableOnClick({ value: e.Section })
                }}
                id={index}
              >
                <Text style={{ fontSize: 16, fontWeight: 700, color: AppStore.theme.o.surface }}>
                  {SelectionInput[index]}
                </Text>
              </Selectble>
            ))}
            <Selectble
              onClick={async () => {
                this.props.selectCategory('other')
                this.props.goNext()
                !navigate('/demo/step3')
                await Utils.saveOnAirtableOnClick({ value: 'Altro' })
              }}
              id={'other'}
            >
              <Text style={{ fontSize: 16, fontWeight: 700, color: AppStore.theme.o.surface }}>Altro</Text>
            </Selectble>
            <Box center mt={'6vh'}>
              <Text weight={700} fontSize={16} color="#666666" onClick={this.props.goBack}>
                torna indietro
              </Text>
            </Box>
            <Box center height={'20vh'} />
          </InnerContainer>
        </Container>
      </Box>
    )
  }
}

const Container = styled(Box)`
  width: 90vw;
  max-width: 375px;
  height: 100%;
  overflow: hidden;
`
const InnerContainer = styled(Box)`
  width: 100%;
  height: 100%;
`

const Selectble = styled(Button)`
  /* CTA */
  /* Auto layout */
  margin-top: 1.5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  background: #18818b;
  /* drop_light */
  box-shadow: inset !important;
  /* box-shadow: 4px 8px 16px rgba(38, 42, 72, 0.14); */
  border-radius: 14px;
  box-shadow: 4px 8px 16px rgba(240, 240, 240, 0.14);
`

const MiniBox = styled(Box)`
  padding: 24px;
  /* width: 100%; */
  height: 20vh;
  /* white */
  background: #ffffff;
  /* drop_light */
  /* box-shadow: 4px 8px 16px rgba(38, 42, 72, 0.14); */
  border-radius: 14px;
`
