import { ReactNode } from 'react'
import { l_facebook, l_google } from '../assets/icons'

import { Spacer, Box } from 'components'
import styled from '@emotion/styled'
import { AppStore } from 'utils'

// import { facebook, google } from 'assets/icons';

export interface Props {
  children: ReactNode
  variant: 'facebook' | 'google'
  onClick: () => void
  flex?: boolean | number
}

/**This is main Button!!*/
export const SocialButton = ({ children, variant = 'facebook', onClick, ...props }: Props) => {
  return (
    <Container vcenter pl={15} row onClick={onClick} variant={variant} {...props}>
      <MyIcon src={variant === 'facebook' ? l_facebook : l_google} variant={variant}></MyIcon>
      <Spacer />
      <Text variant={variant}>{children}</Text>
    </Container>
  )
}

const Container = styled(Box)<{ variant: string }>`
  ${(props) => (props.flex === true ? `width: 100%;` : !!props.flex && `flex: ${props.flex};`)}
  min-height: 56px;
  line-height: 0px;
  background: ${({ variant }) => (variant === 'facebook' ? '#1877F2' : '#ffffff')};
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
  border-radius: 14px;
  :hover {
    background: ${({ variant }) => (variant === 'facebook' ? '#1877F2' : '#ffffff')};
  }
`

const MyIcon = styled.img<{ variant: string }>`
  width: 18px;
  height: 18px;
  background: #ffffff;
`

const Text = styled.div<{ variant: string }>`
  color: ${({ variant }) => (variant === 'facebook' ? 'white' : 'black')};
  font-size: 16px;
  font-weight: 600;
`
