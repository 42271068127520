/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import Utils from 'api/Utils'
import Workspaces from 'api/Workspaces'
import { Box, Icons } from 'components'
import { AppStore, T, showError, showSuccess } from 'utils'
import { useEffect, useState } from 'react'
import { FeedbackModal } from './FeedbackModalGenerationsResult'
import { useAiGenerationStore } from 'stores/AiGenerationStore'

/* ------------------- INTERFACES  */
interface Props {
  generatedText: string
  isLink: boolean
}

export const GenerationFeedbackButtons = ({ generatedText, isLink }: Props) => {
  const { generatedByTextResults, generatedByLinkResults } = useAiGenerationStore((s) => ({
    generatedByTextResults: s.generatedByTextResults,
    generatedByLinkResults: s.generatedByLinkResults,
  }))
  let storeItem: any[]

  if (isLink) {
    storeItem = generatedByLinkResults
  } else {
    storeItem = generatedByTextResults
  }

  const [isLiked, setIsLiked] = useState<boolean>(false)
  const [isNotLiked, setIsNotLiked] = useState<boolean>(false)
  const [isSaved, setIsSaved] = useState<boolean>(false)
  const [modelName, setModelName] = useState<string>('')
  const [feedbackTextCheck, setFeedbackTextCheck] = useState<string>('')

  const [modelId, setModelId] = useState<string>('')
  const [feedbackId, setFeedbackId] = useState<string | undefined>(undefined)

  useEffect(() => {
    const model = storeItem.find((item) => item.generatedTexts === generatedText)

    if (model) {
      setModelName(model.name)
      setIsLiked(model.like)
      setIsNotLiked(model.isNotLike)
      setFeedbackTextCheck(model.feedbackNotes)
      setModelId(model.modelId)
      setFeedbackId(model.feedbackId)

      // Controlla che esista nel workspace e non sia stato eliminato da altre fonti
      const isInWorkspaceHashtags = AppStore.workspace.hashtags?.find((item) => item._id === model.modelId)
      if (isInWorkspaceHashtags) {
        setIsSaved(model.saved)
      }
    }
  }, [storeItem])

  const deleteHashtagGruop = async (index) => {
    try {
      AppStore.workspace.hashtags = await Workspaces.deleteModalGruop(AppStore.workspace._id as any, index)
      showSuccess(T.success.modalEliminated)
    } catch (error) {
      showError(error)
    }
  }

  const filterHastagId = async (name: string) => {
    // Gli assegna l'id che trova nel workspace
    const filteredId = AppStore.workspace?.hashtags?.filter((item) => item.name === name)

    setModelId(filteredId![0]._id)
    const ID = storeItem.find((item) => item.name === name)
    if (ID) {
      ID.modelId = filteredId![0]._id
    }
  }

  const addAiGenerationToWorkspace = async ({ text, name, type }) => {
    try {
      const results = (await Workspaces.addHashtagToWorkspace(
        { text, name, type },
        AppStore.workspace._id as any
      )) as any
      AppStore.workspace.hashtags = results as any
      showSuccess(T.success.theModalCreated)
      filterHastagId(modelName)
    } catch (error) {
      showError(error)
      if ((AppStore.workspace.hashtags?.length ?? 0) > 25) {
        showError(T.error.cantHaveMoreThan25)
      }
    }
  }

  const sendFeedback = async (
    id: string | undefined,
    text: string,
    thumbUp: boolean,
    socialAcc: string,
    ArgumentType: string,
    toneofVoice: string,
    From: string,
    toLanguage: string,
    generatedTexts: string,
    feedbackNotes: string
  ) => {
    try {
      const response = await Utils.airtableThumb({
        id,
        text,
        thumbUp,
        socialAcc,
        ArgumentType,
        toneofVoice,
        From,
        toLanguage,
        generatedTexts,
        feedbackNotes,
      })

      if (!feedbackId) {
        const item = storeItem.find((i) => i.name === modelName)
        if (item) {
          item.feedbackId = (response.data as string[])[0]
          setFeedbackId((response.data as string[])[0])
        }
      }
      showSuccess(T.textGenerator.thanksForFeedback)
    } catch (error) {
      showError(T.textGenerator.somethingWentWrong)
    }
  }
  return (
    <Box row center gap={16}>
      <ButtonIcon
        style={{ backgroundColor: isSaved ? AppStore.theme.o.lightGreen : AppStore.theme.o.lightestGrey }}
        onClick={() => {
          if (!isSaved) {
            addAiGenerationToWorkspace({
              text: generatedText,
              name: modelName,
              type: 'caption',
            })

            setIsSaved(true)
            const item = storeItem.find((i) => i.name === modelName)
            if (item) {
              item.saved = true
            }
          } else {
            deleteHashtagGruop(modelId)
            setIsSaved(false)
            const item = storeItem.find((i) => i.name === modelName)
            if (item) {
              item.saved = false
            }
          }
        }}
      >
        <Icons.saveIcon fill={isSaved ? AppStore.theme.o.green : AppStore.theme.o.black} />
      </ButtonIcon>
      <ButtonIcon
        disabled={isLiked}
        onClick={() => {
          const item = storeItem.find((i) => i.name === modelName)
          if (item) {
            item.like = true
            item.isNotLike = false
            item.feedbackNotes = ''
            setIsNotLiked(false)
            setFeedbackTextCheck('')
            setIsLiked(true)
            sendFeedback(
              item.feedbackId,
              item.text,
              true,
              item.socialAcc,
              item.ArgumentType,
              item.toneofVoice,
              item.From,
              item.toLanguage,
              item.generatedTexts,
              item.feedbackNotes
            )
          }
        }}
        style={{ backgroundColor: isLiked ? AppStore.theme.o.lightGreen : AppStore.theme.o.lightestGrey }}
      >
        <Icons.thumbUp fill={isLiked ? AppStore.theme.o.green : AppStore.theme.o.black} />
      </ButtonIcon>
      <ButtonIcon
        disabled={isNotLiked}
        //TODO: Da mettere dentro manager modal
        onClick={() => {
          AppStore.openModal({
            id: 'feedback-modal',
            body: (
              <FeedbackModal
                setFeedbackTextCheck={setFeedbackTextCheck}
                sendFeedback={sendFeedback}
                setIsNotLiked={setIsNotLiked}
                setIsLiked={setIsLiked}
                modelName={modelName}
                storeItem={storeItem}
              />
            ),
            width: 787,
          })
        }}
        style={{
          backgroundColor:
            feedbackTextCheck.length >= 1 && isNotLiked ? AppStore.theme.o.lightRed : AppStore.theme.o.lightestGrey,
        }}
      >
        <Icons.thumbDown
          fill={feedbackTextCheck.length >= 1 && isNotLiked ? AppStore.theme.o.red : AppStore.theme.o.black}
        />
      </ButtonIcon>
    </Box>
  )
}

const ButtonIcon = styled.button`
  margin: 0 !important;
  padding: 4 !important;
  min-height: 24px !important;
  min-width: 24px !important;
  border-radius: 4px !important;
  border: none !important;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  transition: all 0.3s ease-in-out;

  :hover {
    background-color: ${() => AppStore.theme.o.lightBlue}!important;
    svg {
      transition: all 0.3s ease-in-out;
      fill: ${() => AppStore.theme.o.blue};
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
`
