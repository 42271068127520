/* ******************* IMPORT ******************* */
import styled from '@emotion/styled'
import { Box, Button } from 'components'
import { AppStore, T } from 'utils'
import ClearFiltersButton from './ClearFiltersButton'
import { defaultLanguage, useDiscoverStore } from 'stores/DiscoverStore'
import { openLanguageSelectModal } from '../Modals/LanguageSelectModal'

/* ******************* INTERFACE ******************* */ interface Props {
  isInputSelectedFocus?: boolean
  fromInputSearchbarSelected?: boolean
  fromInputSearchbar?: boolean
  fromArticle?: boolean
  defaultLanguageArticle?: string
  languageFilterArticle?: string[]
  setLanguageFilterArticle?: (language: string[]) => void
  openLanguageOnArticle?: (outputLanguage, setOutputLanguage) => void
  clearLanguage?: () => void
}

export const ChangeLanguageButton = ({
  isInputSelectedFocus,
  fromInputSearchbarSelected,
  fromArticle,
  fromInputSearchbar,
  defaultLanguageArticle,
  languageFilterArticle,
  setLanguageFilterArticle,
  openLanguageOnArticle,
  clearLanguage,
}: Props) => {
  /* ____________________ ZUSTAND  ____________________ */
  const {
    languageFilterSearch,
    languageFilterFollow,
    subPageActive,
    setLanguageFilterSearch,
    setLanguageFilterFollow,
  } = useDiscoverStore((state) => ({
    languageFilterSearch: state.languageFilterSearch,
    languageFilterFollow: state.languageFilterFollow,
    subPageActive: state.subPageActive,
    setLanguageFilterSearch: state.setLanguageFilterSearch,
    setLanguageFilterFollow: state.setLanguageFilterFollow,
  }))

  /* ____________________ VARIABLES ___________________ */

  const hasValueSearch = defaultLanguage[0] !== languageFilterSearch[0]
  const hasValueFollow = defaultLanguage[0] !== languageFilterFollow[0]

  /* ____________________ STYLE ____________________ */

  const buttonStyleArticle = {
    fontSize: 14,
    fontWeight: 700,
    maxHeight: 34,
    minHeight: 34,
  }

  return (
    <>
      {!fromArticle && subPageActive === 'search' && (
        <ChangeLanguageButtonContainer
          id={fromInputSearchbarSelected ? 'buttonChangeLanguage' : ''}
          hasValue={hasValueSearch}
          className={`${fromInputSearchbarSelected ? 'fromInputSearchbarSelected' : ''}
        ${fromInputSearchbarSelected && isInputSelectedFocus ? 'isInputSelectedFocus' : ''}
        
      `}
          onClick={() => {
            openLanguageSelectModal(languageFilterSearch, setLanguageFilterSearch, fromInputSearchbar)
          }}
        >
          {languageFilterSearch[0].toUpperCase()}

          {hasValueSearch && <ClearFiltersButton defaultValue={defaultLanguage} setFilter={setLanguageFilterSearch} />}
        </ChangeLanguageButtonContainer>
      )}

      {!fromArticle && subPageActive === 'follow' && (
        <ChangeLanguageButtonContainer
          hasValue={hasValueFollow}
          onClick={() => {
            openLanguageSelectModal(languageFilterFollow, setLanguageFilterFollow)
          }}
        >
          {languageFilterFollow[0].toUpperCase()}

          {hasValueFollow && <ClearFiltersButton defaultValue={defaultLanguage} setFilter={setLanguageFilterFollow} />}
        </ChangeLanguageButtonContainer>
      )}

      {fromArticle && languageFilterArticle && setLanguageFilterArticle && (
        <ChangeLanguageButtonContainerOnArticle
          hasValue={defaultLanguageArticle !== languageFilterArticle[0]}
          className="ChangeLanguageButton_Article"
          variant="neutral"
          ph={24}
          style={buttonStyleArticle}
          onClick={() => {
            if (openLanguageOnArticle) {
              openLanguageOnArticle(languageFilterArticle, setLanguageFilterArticle)
              return
            }
          }}
        >
          {/* Parola Translate quando non c'è un valore diverso  */}
          {defaultLanguageArticle === languageFilterArticle[0] && <>{T.singleWord.translate}</>}

          {/* Quando si seleziona una lingua diversa da quella del articolo */}
          {defaultLanguageArticle !== languageFilterArticle[0] && <>{languageFilterArticle[0].toUpperCase()}</>}

          {/* CLEAR BTN */}
          {defaultLanguageArticle !== languageFilterArticle[0] && (
            <ClearFiltersButton onlyInvokeFunction={clearLanguage} clickedFrom={'article'} />
          )}
        </ChangeLanguageButtonContainerOnArticle>
      )}
    </>
  )
}

const ChangeLanguageButtonContainer = styled(Box)<{ hasValue: boolean }>`
  position: relative;
  width: 70px;
  height: 26px;
  background-color: ${({ hasValue }) => (hasValue ? AppStore.theme.o.lightGreen : AppStore.theme.o.lightestGrey)};
  border: 1px solid ${({ hasValue }) => (hasValue ? AppStore.theme.o.green : AppStore.theme.o.grey)};
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: ${({ hasValue }) => (hasValue ? AppStore.theme.o.green : AppStore.theme.o.black)};
  text-align: center;
  font-family: Aktiv Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  cursor: pointer;

  &:hover {
    background-color: ${({ hasValue }) => (hasValue ? AppStore.theme.o.lightGreen : AppStore.theme.o.lightGrey)};
    border: 1px solid ${() => AppStore.theme.o.grey};
  }

  &.fromInputSearchbarSelected {
    visibility: hidden;
    opacity: 0;
    transition: opacity 200ms ease-in;
    transition-delay: 400ms;
    margin-left: auto;

    & div {
      transition: all 0ms ease-in-out;
    }
  }

  &.isInputSelectedFocus {
    visibility: visible;
    opacity: 1;
    transition: all 200ms !important;
    transition-delay: 200ms !important;
    & div {
      transition: all 300ms ease-in-out !important;
    }
  }

  &.ChangeLanguageButton_Article {
    width: 98px;
    height: 34px;
  }
`

const ChangeLanguageButtonContainerOnArticle = styled(Button)<{ hasValue: boolean }>`
  background-color: ${({ hasValue }) => (hasValue ? AppStore.theme.o.lightGreen : AppStore.theme.o.lightestGrey)};
  border: 1px solid ${({ hasValue }) => (hasValue ? AppStore.theme.o.green : AppStore.theme.o.grey)};
  color: ${({ hasValue }) => (hasValue ? AppStore.theme.o.green : AppStore.theme.o.black)};
  &.ChangeLanguageButton_Article {
    position: relative;
  }

  &:hover {
    background-color: ${({ hasValue }) => (hasValue ? AppStore.theme.o.lightGreen : AppStore.theme.o.lightGrey)};
    border: 1px solid ${() => AppStore.theme.o.grey};
  }
`
