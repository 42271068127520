/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Text } from 'components'
import { enumStatusColor } from 'components/Modal/PreviewPostModal/PostStatusInfo'
import { TPostStatusCalendar } from 'stores/CalendarStore'
import { T } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  status: TPostStatusCalendar
  date: string
}

export const DateInfoCalendarCard = ({ status, date }: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */
  const formatTime = (stringDate: string) => {
    const newDate = new Date(stringDate)

    const hours = ('0' + newDate.getHours()).slice(-2)
    const minutes = ('0' + newDate.getMinutes()).slice(-2)
    return hours + ':' + minutes
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  /* ----------------- ENUM  */

  return (
    <DateInfoCalendarCardContainer style={{ background: enumStatusColor[status].background }}>
      <Text fontSize={14} color={enumStatusColor[status].color} style={{ lineHeight: '80' }}>
        {date ? formatTime(date) : T.draftModal.noDateInserted}
      </Text>
    </DateInfoCalendarCardContainer>
  )
}

const DateInfoCalendarCardContainer = styled(Box)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
`
