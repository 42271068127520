/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { useRef, useState } from 'react'
import { Media } from 'types'
import { IPostVariant } from 'types/PostInterface'
import { AppStore, T } from 'utils'
import { getFirstMedia, updatePost } from 'utils/PostUtils/Post'
import { ControlsVideoPreview } from './ControlsVideoPreview'
import { ExtraTitle } from './ExtraTitle'
import { LottiePreviewVideo } from './LottiePreviewVideo'
import { VideoPreview } from './VideoPreview'
import { ReelMaskThumbnail } from './ReelMaskThumbnail'

/* ------------------- INTERFACES  */

interface Props {
  post: IPostVariant
  filterVideoUrl?: string
  YTminiature?: string | undefined
  isReel?: boolean
}

export const PreviewVideoContainer = ({ post, filterVideoUrl: initialFilterVideoUrl, YTminiature, isReel }: Props) => {
  /* ----------------- VARIABLES  */
  const { timestamp } = post.data
  const { provider } = post.info.account
  const showThumbnail = post.data?.thumbnail?.mimetype?.includes('image')

  /* ----------------- STATE  */
  const [videoCoverTimestampMsState, setVideoCoverTimestampMsState] = useState<number>(timestamp ?? 0)
  const [maxVideoCoverTimestampMs, setMaxVideoCoverTimestampMs] = useState<number>(0)
  const [videoAspectRatio, setVideoAspectRatio] = useState<string>('')
  const [frames, setFrames] = useState<string[]>([])
  const [isSeekbarDisabled, setIsSeekbarDisabled] = useState(false)
  const [filterVideoUrl, setFilterVideoUrl] = useState(initialFilterVideoUrl)
  const [hasVideoLoaded, setHasVideoLoaded] = useState<boolean>(false)
  const [originalThumbnail, setOriginalThumbnail] = useState({
    url: filterVideoUrl,
    timestamp: videoCoverTimestampMsState,
  })

  /* ----------------- REF  */
  const videoRef = useRef<HTMLVideoElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)

  /* ----------------- METHODS  */
  function setCurrentTimeVideo(ms: number) {
    if (videoRef.current) {
      videoRef.current.currentTime = ms / 1000
    }
  }

  //Controlla che il valore non vadi sotto 0 / più del valore massimo di MS del video, se il valore dovesse superare il 0 o max MS, prende il valore massimo applicabile.
  function changeTimestamp(ms: number): void {
    const newTimestamp = videoCoverTimestampMsState + ms

    if (newTimestamp >= 0 && newTimestamp <= maxVideoCoverTimestampMs) {
      setVideoCoverTimestampMsState(newTimestamp)
      setCurrentTimeVideo(newTimestamp)
    } else if (newTimestamp < 0) {
      setVideoCoverTimestampMsState(0)
      setCurrentTimeVideo(0)
    } else if (newTimestamp > maxVideoCoverTimestampMs) {
      setVideoCoverTimestampMsState(maxVideoCoverTimestampMs)
      setCurrentTimeVideo(maxVideoCoverTimestampMs)
    }
  }

  const onVideoLoad = async () => {
    setHasVideoLoaded(false)

    const duration = videoRef.current?.duration

    if (duration && duration > 0) {
      //Prende durata totale video e la setta come state
      setMaxVideoCoverTimestampMs(duration * 1000)

      // Genera i frames
      let i = 0
      while (i <= duration) {
        await seekToTime(i)
        generateFrame(i)
        // Attualmente impostato come 21 frames nel riguardo input
        i += duration / 20
      }
    }

    // Setta tempo iniziale del video con tempo salvato in precedenza o 0
    if (videoRef.current) {
      setCurrentTimeVideo(timestamp ?? 0)
    }

    // Aggiungi qui il controllo per il rapporto di aspetto
    if (provider === 'youtube' && videoRef.current) {
      const width = videoRef.current.videoWidth
      const height = videoRef.current.videoHeight
      const aspectRatio = width / height

      let videoFormat
      if (aspectRatio.toFixed(2) === (16 / 9).toFixed(2)) {
        videoFormat = '16:9'
      } else if (aspectRatio.toFixed(2) === (9 / 16).toFixed(2)) {
        videoFormat = '9:16'
      }

      setVideoAspectRatio(videoFormat)
    }
    setHasVideoLoaded(true)
  }

  // Aspettare che il video sia "visto" così da non generare IMG vuote
  function seekToTime(time: number) {
    return new Promise((resolve) => {
      if (videoRef.current) {
        videoRef.current.currentTime = time
        videoRef.current.onseeked = () => resolve(true)
      } else {
        resolve(false)
      }
    })
  }

  function generateFrame(i: number) {
    const context = canvasRef.current?.getContext('2d')
    if (context && videoRef.current) {
      const canvas = canvasRef.current
      const width = canvas?.width
      const height = canvas?.height
      context.drawImage(videoRef.current, 0, 0, width!, height!)
      const dataURL = canvasRef.current?.toDataURL()
      setFrames((prevFrames) => [...prevFrames, dataURL!])
    }
  }

  const onMediaSelected = (selectedMedias: Media[]) => {
    const newVideoUrl = getFirstMedia(selectedMedias, 'video')
    if (newVideoUrl) {
      setFilterVideoUrl(newVideoUrl) // Aggiorna l'URL del video
    }
    // updateActive({ medias: selectedMedias })

    updatePost(post.variantId, { thumbnail: selectedMedias[0] })
    setVideoCoverTimestampMsState(0)
    setIsSeekbarDisabled(true)
    setOriginalThumbnail({ url: filterVideoUrl, timestamp: videoCoverTimestampMsState }) // Memorizza la miniatura e il timestamp originali
  }

  const removeThumbnail = () => {
    updatePost(post.variantId, { thumbnail: null })
    setIsSeekbarDisabled(false)
  }

  const SocialConfiguration = {
    youtube: {
      videoAspectRatio: videoAspectRatio,
    },
    instagram: {
      width: 541,
    },
    tiktok: {
      width: 541,
    },
  }

  const configuration = SocialConfiguration[provider]

  const handleWidthMiniature = (prv: string) => {
    switch (prv) {
      case 'instagram':
        return 195
      case 'tiktok':
        return 195
      case 'youtube':
        if (videoAspectRatio === '16:9') return 339
        if (videoAspectRatio === '9:16') return 195
        return
      default:
        return 195
    }
  }

  const handleHeightMiniature = (prv: string) => {
    switch (prv) {
      case 'instagram':
        return 339
      case 'tiktok':
        return 339
      case 'youtube':
        if (videoAspectRatio === '16:9') return 195
        if (videoAspectRatio === '9:16') return 339
        return
      default:
        return 339
    }
  }

  return (
    <Box>
      {!hasVideoLoaded && (
        <Box
          style={{
            opacity: hasVideoLoaded ? 0 : 1,
          }}
        >
          <LottiePreviewVideo />
        </Box>
      )}

      <Box
        style={{
          opacity: hasVideoLoaded ? 1 : 0,
          transition: '500ms ease-in-out',
          height: hasVideoLoaded ? 'auto' : 0,
        }}
      >
        <Container className={`${provider}-container`}>
          <ExtraTitle text={T.postPage.youtubeTubeExtra.thumbnail} />

          <Box center gap={24} style={{ position: 'relative' }}>
            <VideoPreview
              ref={videoRef}
              post={post}
              onVideoLoad={onVideoLoad}
              filterVideoUrl={filterVideoUrl as string}
              setVideoCoverTimestampMsState={setVideoCoverTimestampMsState}
              videoAspectRatio={configuration ? configuration.videoAspectRatio : videoAspectRatio}
              style={{ opacity: showThumbnail ? 0 : 1 }}
            />

            {showThumbnail && (
              <MiniatureContainer
                style={{
                  width: handleWidthMiniature(provider),
                  height: handleHeightMiniature(provider),
                }}
              >
                <img src={post.data.thumbnail?.url} />
                <RemoveMiniatureIcon onClick={removeThumbnail}>
                  <Icons.closeIcon />
                </RemoveMiniatureIcon>
              </MiniatureContainer>
            )}

            {isReel && <ReelMaskThumbnail />}

            <ControlsVideoPreview
              ref={canvasRef}
              post={post}
              frames={frames}
              setVideoCoverTimestampMsState={setVideoCoverTimestampMsState}
              videoCoverTimestampMsState={videoCoverTimestampMsState}
              maxVideoCoverTimestampMs={maxVideoCoverTimestampMs}
              changeTimestamp={changeTimestamp}
              setCurrentTimeVideo={setCurrentTimeVideo}
              onMediaSelected={onMediaSelected}
              isSeekbarDisabled={isSeekbarDisabled}
            />
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

const Container = styled(Box)`
  gap: 12px;
`

const MiniatureContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  border-radius: 14;

  img {
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
`

const RemoveMiniatureIcon = styled(Box)`
  position: absolute;
  top: 0;
  left: calc(100% + 4px);
  width: 20px;
  aspect-ratio: 1;

  svg {
    background-color: ${() => AppStore.theme.o.background};
    border-radius: 8px;
    path {
      stroke: ${() => AppStore.theme.o.black};
    }
  }

  &:hover {
    svg {
      background-color: ${() => AppStore.theme.o.lightRed};
      border-radius: 8px;
      path {
        stroke: ${() => AppStore.theme.o.red};
      }
    }
  }
`
