/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import {
  TColorLabel,
  colorEnumLabel,
} from 'components/CreatePost/PreviewComponents/LabelsComponents/LabelsContentComponents/Label'
import { AppStore } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  id: string
  selectedColor: TColorLabel
  changeColorLabel: (color: TColorLabel) => void
}

export const LabelColors = ({ id, selectedColor, changeColorLabel }: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */
  const colors: TColorLabel[] = ['purple', 'blue', 'red', 'green', 'yellow']

  /* ----------------- METHODS  */
  const handleColorChange = (color: TColorLabel) => {
    changeColorLabel(color)
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <LabelColorsContainer>
      {colors.map((color, i) => (
        <RadioColor
          key={id + i}
          onClick={() => handleColorChange(color)}
          color={color}
          style={{
            backgroundColor: selectedColor === color ? colorEnumLabel[color].color : colorEnumLabel[color].background,
            border: `1px solid ${colorEnumLabel[color].color}`,
          }}
        />
      ))}
    </LabelColorsContainer>
  )
}

const LabelColorsContainer = styled(Box)`
  display: flex;
  gap: 10px;
  flex-direction: row;
`

const RadioColor = styled(Box)<{ color: TColorLabel }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.3);
    box-shadow: 0px 0px 10px 0px ${({ color }) => colorEnumLabel[color].color};
  }
`
