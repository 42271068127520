/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { useState } from 'react'
import { AppStore } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  infoText: string
}

export const InfoExtra = ({ infoText }: Props) => {
  /* ----------------- STATE  */
  const [isHovered, setIsHovered] = useState<boolean>(false)
  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <InfoExtraContainer onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <Icons.info fill={AppStore.theme.o.blue} width={16} height={16} />

      {isHovered && <InfoText>{infoText}</InfoText>}
    </InfoExtraContainer>
  )
}

const InfoExtraContainer = styled(Box)`
  border-radius: 0px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: help;
`

const InfoText = styled(Box)`
  position: absolute;
  top: -30%;
  left: calc(100% + 8px);
  padding: 8px 16px;
  background-color: ${() => AppStore.theme.o.surface};
  color: ${() => AppStore.theme.o.black};
  font-size: 12px;
  white-space: wrap;
  width: 360px;
  display: flex;
  z-index: 1;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  border-radius: 14px;
  min-width: 300px;
  max-width: 400px;
`
