/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { IPostVariant } from 'types/PostInterface'
import { MediaContainerPost } from '../../GenericComponents/MediaContainerPost'
import { AppStore, T } from 'utils'
import { canShowMetadata, findVisibleMetadata } from 'utils/PostUtils/ShortUrl'
import { truncateText } from 'pages/ai-discover/commonFunction'

/* ------------------- INTERFACES  */
interface Props {
  postPreview?: IPostVariant
}

export const ResultsPreviewX = ({ postPreview }: Props) => {
  /* ----------------- ZUSTAND  */
  const { activePost } = useCreatePostStore((s) => ({
    activePost: s.activePost as IPostVariant,
  }))

  /* ----------------- VARIABLES  */
  const post = postPreview ?? activePost

  const firstMetadata = findVisibleMetadata(post)
  const visibleLink = firstMetadata ? firstMetadata[0] : undefined

  const showMetadata = canShowMetadata(post)
  /* ----------------- METHODS  */
  const formatUrl = (url: string) => {
    // Creare un oggetto URL con la stringa fornita
    const urlObj = new URL(url)

    // Ottenere l'host dallo stesso
    const host = urlObj.host

    return host
  }

  const urls = post?.data?.urls

  const metadataVisibleIndex = urls && urls.findIndex((url) => url.metaData.visible === true)

  const metadata = urls && metadataVisibleIndex !== -1 && urls[metadataVisibleIndex!]

  return (
    <ResultsPreviewXContainer>
      <HeaderPreview>
        <ProfileImg src={post?.info?.account?.picture} />

        <ProfileContent>
          <ProfileInfo>
            <ProfileMinorInfo>
              <ProfileName>{truncateText(post?.info?.account?.name, 20)}</ProfileName>

              <ProfileDate>
                @{truncateText(post?.info?.account?.name, 15)} &#x2022;{' '}
                {new Date().toLocaleDateString('it-IT', { day: 'numeric', month: 'long' })}
              </ProfileDate>
            </ProfileMinorInfo>

            <Icons.threeDots />
          </ProfileInfo>

          <PreviewText>
            {post?.data?.text?.length > 140 ? (
              <>
                {post?.data?.text?.slice(0, 140)} <SeeMoreText>... {T.postPage.more}</SeeMoreText>
              </>
            ) : (
              post?.data?.text
            )}
          </PreviewText>
        </ProfileContent>
      </HeaderPreview>

      <ContainerContent>
        {postPreview && !metadata && post?.data?.medias?.length === 0 ? (
          <></>
        ) : (
          <MediaContainerPost width={'100%'} height={313} arrowsPositions={10} postPreview={postPreview} />
        )}

        {showMetadata && (
          <MetadataUrl>
            {T.singleWord.from} {formatUrl(visibleLink.originalUrl)}
          </MetadataUrl>
        )}

        <FooterPreview>
          <InteractionContainer>
            <Icons.twitterComment fill={AppStore.theme.o.black} />6
          </InteractionContainer>

          <InteractionContainer>
            <Icons.twitterRepost fill={AppStore.theme.o.black} /> 22
          </InteractionContainer>

          <InteractionContainer style={{ color: AppStore.theme.o.red }}>
            <Icons.twitterHeart fill={AppStore.theme.o.red} /> 436
          </InteractionContainer>

          <InteractionContainer>
            <Icons.twitterLikes fill={AppStore.theme.o.black} />
            22
          </InteractionContainer>

          <InteractionContainer>
            <Icons.twitterShare fill={AppStore.theme.o.black} />
          </InteractionContainer>
        </FooterPreview>
      </ContainerContent>
    </ResultsPreviewXContainer>
  )
}

const ResultsPreviewXContainer = styled(Box)`
  width: 100%;
  border: 1px solid ${() => AppStore.theme.o.grey};
`

const HeaderPreview = styled(Box)`
  padding: 8px 12px;
  gap: 8px;
  display: flex;
  flex-direction: row;
  width: 100%;
`

const PreviewText = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  border-radius: 0;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`

const SeeMoreText = styled.span`
  margin-left: 4px;
  color: ${() => AppStore.theme.o.darkGrey};
`

const ProfileContent = styled(Box)`
  display: flex;
  gap: 4px;
  width: 338px;
`

const ProfileInfo = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const ProfileImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`

const ProfileMinorInfo = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 4px;
`

const ProfileName = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.3px;
`

const ProfileDate = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  color: ${() => AppStore.theme.o.darkGrey};
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.26px;
`

const FooterPreview = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 8px 12px 0px;
`

const MetadataUrl = styled(Box)`
  color: ${() => AppStore.theme.o.darkGrey};

  font-family: Aktiv Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 8px 0;
`

const InteractionContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  color: ${() => AppStore.theme.o.black};
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.002px; /* 81.264% */
`

const ContainerContent = styled(Box)`
  margin-left: 49px;
  margin-right: 12px;
`
