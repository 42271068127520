import React from 'react'
import styled from '@emotion/styled'
import { Box } from 'components/Box'
import { AppStore, T } from 'utils'

interface Props {
  name: string
  activeCategory: string[]
  handleActiveCategory: (cat: string) => void
}

const ChipNotify = ({ name, activeCategory, handleActiveCategory }: Props) => {
  // se activeCategory è uguale a name allora aggiungo la classe active
  // se activeCategory è diverso da name e diverso dallo stato di default ("") allora aggiungo la classe not-active per l'effetto non attivo della chip ma una delle chip è attiva

  return (
    <ChipNotifyEl
      className={`chip-notify ${activeCategory.includes(name) ? 'active' : ''} ${
        activeCategory.length > 0 && !activeCategory.includes(name) ? 'not-active' : ''
      }`}
      onClick={() => handleActiveCategory(name.toLowerCase())}
    >
      {T.notifyCategory[name]}
    </ChipNotifyEl>
  )
}

export default ChipNotify

const ChipNotifyEl = styled(Box)`
  &.chip-notify {
    width: calc(100% / 3 - 16px / 3);
    height: 28px;
    color: ${() => AppStore.theme.o.green};
    background-color: ${() => AppStore.theme.o.lightGreen};
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    border-radius: 8px;
    padding: 4px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    cursor: pointer;
    border: 1px solid transparent;

    &:hover,
    &.active {
      border: 1px solid ${() => AppStore.theme.o.green};
      background-color: ${() => AppStore.theme.o.lightGreen};
    }

    &.not-active {
      color: ${() => AppStore.theme.o.darkGrey};
      background-color: ${() => AppStore.theme.o.lightGrey};
      border: 1px solid transparent;

      &:hover {
        color: ${() => AppStore.theme.o.green};
        background-color: ${() => AppStore.theme.o.lightGreen};
      }
    }
  }
`
