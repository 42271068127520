/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { MultiSelectRow } from 'components/UI/MultiSelectRow'
import { useDraftStore } from 'stores/DraftStore'
import { T } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {}

export const CreatedFilterDraft = ({}: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */
  const { createdFrom } = useDraftStore((s) => ({ createdFrom: s.createdFrom }))

  const { setCreatedFrom } = useDraftStore()

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  /* ----------------- CONFIG  */
  const data = [
    {
      name: T.status.all,
      value: 'all',
      action: () => setCreatedFrom('all'),
    },
    {
      name: T.calendarPage.manual,
      value: 'manual',
      action: () => setCreatedFrom('manual'),
    },
    {
      name: T.calendarPage.autopilot,
      value: 'autopilot',
      action: () => setCreatedFrom('autopilot'),
    },
  ]

  return <MultiSelectRow active={createdFrom} options={data} />
}
