import { Form } from 'antd'
import { Button, Input, Spacer, Text, ZoomedContainer } from 'components'
import { Component } from 'react'
import { T } from 'translation/i18n'
import { showError } from 'utils'

interface Props {
  onConfirm: (width: number, height: number) => void
}

export default class CustomMediaModal extends Component<Props> {
  render() {
    return (
      <ZoomedContainer pv={48} ph={80}>
        <Form
          style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
          autoComplete="off"
          onFinish={(values: any) => {
            const { width, height } = values
            //check if width and height are numbers
            if (isNaN(Number(width)) || isNaN(Number(height))) {
              showError(T.error.insertPixlLeght)
              return
            }

            this.props.onConfirm(Number(width), Number(height))
          }}
          onFinishFailed={(errorInfo) => {
            showError(errorInfo)
          }}
        >
          <Form.Item name="height" rules={[{ required: true }]}>
            <Input autoFocus label="Altezza" placeholder="Altezza in px" variant="numeric" />
          </Form.Item>
          <Spacer />
          <Form.Item name="width" rules={[{ required: true }]}>
            <Input label="Larghezza" placeholder="Larghezza in px" variant="numeric" />
          </Form.Item>
          <Spacer />
          <Button animation="slide" mb={16} size="small" type="submit">
            {T.settings.confirm}
          </Button>
        </Form>
      </ZoomedContainer>
    )
  }
}
