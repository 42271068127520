import { Box, Card, Page } from 'components'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useUserStore } from 'stores/UserStore'
import { T } from 'translation/i18n'
import { TabType } from 'types/SettingsInterfaces'
import { settingTabs } from 'utils/SettingPageConfiguration'
import { enumTab } from 'utils/SettingPageUtils'
import AsideTab from './AsideTab/AsideTab'

const extractTabs = (currentUser) => {
  return currentUser?.enterprise || currentUser.owner ? settingTabs.slice(0, -1) : settingTabs
}

const SettingsPage = () => {
  const { currentUser } = useUserStore((s) => ({
    currentUser: s.user,
  }))

  const navigate = useNavigate()
  const location = useLocation()
  const [tabs, setTabs] = useState<TabType[]>(extractTabs(currentUser))
  // const [tabs, setTabs] = useState<TabType[]>(settingTabs)
  const [selected, setSelected] = useState<number>(0)

  const onChangeTab = (tab: TabType, selectedTab: number) => {
    const path = '/settings/' + tab.path

    if (tab.path.includes('subscription')) navigate(path, { state: { from: 'settings', part: 'MyPlan' } })
    else navigate(path)
    setSelected(selectedTab)
  }

  useEffect(() => {
    const section = location.pathname.split('/')[2]
    const subSection = location.pathname.split('/')[3]

    if (subSection && subSection === 'addOn') {
      setSelected(enumTab['subscription' as string])
      return
    }
    setSelected(enumTab[section as string])
  }, [location])

  return (
    <Page title={T.settings.settings}>
      <Box row flex center>
        {/* ---------------------------------- Aside Setting ( all tabs ) */}
        <Card center height={800} width={220} mr={18}>
          {tabs.map((tab, index, arr) => (
            <AsideTab
              key={`aside-tab-${index}`}
              tab={tab}
              index={index}
              onChangeTab={onChangeTab}
              selectedTab={selected}
              allTabsLength={arr.length}
            />
          ))}
        </Card>

        {/* ---------------------------------- Content settings ( all tabs ) */}
        <Card height={800} width={1080}>
          {tabs[selected].component}
        </Card>
      </Box>
    </Page>
  )
}

export default SettingsPage
