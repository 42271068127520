/* ------------------- IMPORTS */
import { Box } from 'components'
import { AppStore } from 'utils'

/* ------------------- INTERFACES  */
interface Props {
  bgColor?: string
  width?: number | string
  height?: number | string
  margin?: number | string
  mt?: number | string
  mb?: number | string
  ml?: number | string
  mr?: number | string
}

export const Divider = ({
  bgColor = AppStore.theme.o.grey,
  width = '100%',
  height = 1,
  margin = '16px 0',
  mt,
  mb,
  ml,
  mr,
}: Props) => {
  return (
    <Box
      style={{
        backgroundColor: bgColor,
        width: width,
        height: height,
        margin: margin,
        marginTop: mt && mt,
        marginBottom: mb && mb,
        marginLeft: ml && ml,
        marginRight: mr && mr,
      }}
    />
  )
}
