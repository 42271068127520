import styled from '@emotion/styled'
import { AppStore, T, showError } from 'utils'
import { Box } from './Box'
import { Text } from './Text'

interface Props {
  objective: any
  options: any
  icon?: any
  open?: boolean
  setOpen?: any
  setObjective?: any
  width?: number
  active?: boolean
  dropdownClass?: string
}
export default function Select(props: Props) {
  const { objective, options, icon, open, setOpen, setObjective, width, active, dropdownClass } = props
  return (
    <Box
      width={width ?? 255}
      borderRadius={14}
      height={40}
      style={{
        maxHeight: 40,
        backgroundColor: AppStore.theme.o.lightGrey,
        borderRadius: 14,
        border: `1px solid ${AppStore.theme.o.darkGrey}`,
        opacity: active ? 1 : 0.5,
        cursor: 'pointer',
      }}
      relative
      vcenter
      row
      ph={21}
      onClick={() => {
        if (active !== false) setOpen(!props.open)
        else {
          showError(T.error.selectObjectiveFirst)
        }
      }}
    >
      <Box flex vcenter>
        <Text>{objective}</Text>
      </Box>
      {icon ? (
        <ContainerIcon
          style={{
            backgroundColor: 'transparent',
            rotate: open ? '0deg' : '-180deg',
            transition: 'all 0.3s',
          }}
        >
          {icon}
        </ContainerIcon>
      ) : (
        <></>
      )}
      <Box
        absolute
        style={{
          maxHeight: 40,
          left: 0,
          top: 44,
          right: 0,
          bottom: 0,
          zIndex: 99,
          backgroundColor: AppStore.theme.o.lightGrey,
        }}
      >
        <Box
          width={width ?? 255}
          borderRadius={14}
          absolute
          gap={4}
          style={{
            maxHeight: 40,
            zIndex: 99,
            position: 'fixed',
            backgroundColor: AppStore.theme.o.lightGrey,
          }}
        >
          {open && (
            <DropdownMenu
              className={`dropdown-menu ${dropdownClass ? dropdownClass : ''}`}
              style={{ backgroundColor: AppStore.theme.o.surface }}
              pv={16}
              shadow={AppStore.theme.bigOuterShadowNoBox}
            >
              {options != undefined &&
                options.map((item, index) => (
                  <div
                    onClick={() => {
                      setOpen(false)
                    }}
                  >
                    <Items
                      width={'90%'}
                      borderRadius={14}
                      height={40}
                      center
                      onClick={() => {
                        setObjective(index)
                      }}
                    >
                      <Text>{typeof item === 'object' ? item.name : item}</Text>
                    </Items>
                  </div>
                ))}
            </DropdownMenu>
          )}
        </Box>
      </Box>
    </Box>
  )
}

const Items = styled(Box)`
  margin: auto;
  :hover {
    background-color: ${() => AppStore.theme.o.lightestGrey};
  }
`
const ContainerIcon = styled(Box)``

const DropdownMenu = styled(Box)`
  &.max-h-overflow {
    max-height: 156px;
    overflow-y: auto;
  }
`
