/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { useAiGenerationStore } from 'stores/AiGenerationStore'
import { GenerationResult } from '../AiGeneric/GenerationResult'
import { AppStore, T } from 'utils'
import { useState } from 'react'

/* ------------------- INTERFACES  */
interface Props {}

export const AiTextResults = ({}: Props) => {
  /* ----------------- ZUSTAND  */
  const { generatedByTextResults, selectedOption } = useAiGenerationStore((s) => ({
    generatedByTextResults: s.generatedByTextResults,
    selectedOption: s.selectedOption,
  }))

  /* ----------------- VARIABLES  */
  const isBrandVoiceSelected = selectedOption !== T.textGenerator.neutral

  const generatedTextsNeutral = generatedByTextResults.filter((gen) => gen?.isBrandVoice === (false || undefined))
  const generatedTextsBrandVoice = generatedByTextResults.filter((gen) => gen?.isBrandVoice === true)

  const renderResults = isBrandVoiceSelected ? generatedTextsBrandVoice : generatedTextsNeutral

  return renderResults.length > 0 ? (
    <AiTextResultsContainer>
      {renderResults.map((generation) => (
        <GenerationResult key={generation.name} generation={generation} />
      ))}
    </AiTextResultsContainer>
  ) : (
    <PlaceholderGeneration>{T.error.generation_placeholder}</PlaceholderGeneration>
  )
}

const AiTextResultsContainer = styled(Box)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
  overflow-y: scroll;
  flex: 1;
`

const PlaceholderGeneration = styled(Box)`
  display: flex;
  height: 92px;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 14px;
  border: 0.5px solid ${() => AppStore.theme.o.lightGrey};
  color: ${() => AppStore.theme.o.darkGrey};
  text-align: center;
  font-family: 'Aktiv Grotesk';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
`
