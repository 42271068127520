/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'

/* ------------------- INTERFACES  */

interface Props {
  sidebar?: React.ReactNode
  navigation?: React.ReactNode
  gap?: number | string
  margin?: number | string
  content: React.ReactNode
  styles?: React.CSSProperties
}

export const PageStructure = ({ sidebar, navigation, gap, margin, content, styles }: Props) => {
  return (
    <PageStructureContainer
      className="page_structure"
      style={{
        gap: gap || '40px',
        margin: margin || '0px',
        ...styles,
      }}
    >
      <>
        {sidebar && sidebar}
        {content}
        {navigation && navigation}
      </>
    </PageStructureContainer>
  )
}

const PageStructureContainer = styled(Box)`
  &.page_structure {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 40px;
    position: relative;
    flex: 1;
    max-height: calc(100% - 24px);
    padding-top: 10px;
    z-index: 0;
  }
`
