import styled from '@emotion/styled'

import Medias from 'api/Medias'
import { Box, Button, Icons, MediaImage, Text } from 'components'
import gsap from 'gsap'
import { Component } from 'react'
import { Media } from 'types'
import { AppStore, showError, T } from 'utils'

interface State {
  medias: Media[]
  loading: boolean
  path: string
  height: number
  hoverIndex: number
  secondaryMedia: Media[]
  secondaryPath: string
  renderSecond: boolean
  firstOrSecond: boolean | undefined
  animationInAction: boolean
}

interface Props {
  path: string
  onFinish: any
  fodler?: boolean
  selectedMedia?: Media[]
}
export default class ChangeFileFolder extends Component<Props, State> {
  state: State = {
    medias: [],
    path: this.props.path,
    loading: true,
    hoverIndex: -1,
    height: document.getElementById('allFolderContainer')?.clientHeight ?? 0,
    secondaryMedia: [],
    secondaryPath: this.props.path,
    renderSecond: false,
    firstOrSecond: undefined,
    animationInAction: false,
  }

  componentDidMount(): void {
    this.refreshMedia()
    this.refreshSecondaryMedia()
  }

  refreshMedia = async () => {
    this.setState({ loading: true })
    try {
      const medias = await Medias.paginate<Media>({
        path: `${AppStore.workspace._id}/${this.state.path.replace(AppStore.workspace._id ?? '', '')}`.replace(
          /\/\//g,
          '/'
        ),
        workspace: AppStore.workspace._id,
      })

      this.setState({ medias: medias?.results ?? [], loading: false, hoverIndex: -1 })
    } catch (error) {
      showError(error)
      this.setState({ medias: [], loading: false })
    }
  }

  refreshSecondaryMedia = async () => {
    this.setState({ loading: true })
    try {
      const medias = await Medias.paginate<Media>({
        path: `${AppStore.workspace._id}/${this.state.secondaryPath.replace(AppStore.workspace._id ?? '', '')}`.replace(
          /\/\//g,
          '/'
        ),
        workspace: AppStore.workspace._id,
      })

      this.setState({ secondaryMedia: medias?.results ?? [], loading: false, hoverIndex: -1 })
    } catch (error) {
      showError(error)
      this.setState({ secondaryMedia: [], loading: false })
    }
  }

  changePath = async (media?: Media) => {
    if (!media) {
      await this.setState({ path: this.state.path.split('/').slice(0, -2).join('/') + '/' })
    } else {
      await this.setState({ path: media.path + media.name + '/' })
    }
    // this.setState({ animationInAction: false })
    this.refreshMedia()
  }

  changePathSecondary = async (media?: Media) => {
    if (!media) {
      await this.setState({ secondaryPath: this.state.secondaryPath.split('/').slice(0, -2).join('/') + '/' })
    } else {
      await this.setState({ secondaryPath: media.path + media.name + '/' })
    }
    // this.setState({ height: document.getElementById('allFolderContainer')?.clientHeight ?? 0 })
    this.refreshSecondaryMedia()
  }

  animationGsap = async (media: Media) => {
    if (this.state.animationInAction) return

    this.setState({ renderSecond: true, animationInAction: true })

    const { firstOrSecond } = this.state

    setTimeout(async () => {
      let FirstContainer
      let SecondContainer

      const aniamteFirst = this.state.firstOrSecond ?? true

      if (aniamteFirst) {
        FirstContainer = document.getElementById('allFolderContainer')
        SecondContainer = document.getElementById('secondaryFcontainer')

        gsap.set(FirstContainer, { autoAlpha: 1 })
        gsap.set(SecondContainer, { autoAlpha: 0, x: 1000, y: -920 })

        setTimeout(() => {
          if (aniamteFirst) this.changePathSecondary(media)
          else this.changePath(media)

          setTimeout(() => {
            gsap.set(FirstContainer, { x: -1000, autoAlpha: 1 })
            gsap.set(SecondContainer, { x: 0, autoAlpha: 1 })

            setTimeout(() => {
              this.setState({ firstOrSecond: !aniamteFirst, animationInAction: false })
              if (aniamteFirst) this.changePath(media)
              else this.changePathSecondary(media)
            }, 200)
          }, 300)
        }, 200)
      } else {
        FirstContainer = document.getElementById('allFolderContainer')
        SecondContainer = document.getElementById('secondaryFcontainer')

        gsap.set(SecondContainer, { autoAlpha: 1, x: 0 })
        gsap.set(FirstContainer, { autoAlpha: 0 })
        gsap.set(FirstContainer, { x: 1000, delay: 0.2 })
        setTimeout(() => {
          if (aniamteFirst) this.changePathSecondary(media)
          else this.changePath(media)

          setTimeout(() => {
            gsap.set(SecondContainer, { x: -1000, autoAlpha: 0 })
            gsap.set(FirstContainer, { x: 0, autoAlpha: 1 })
            setTimeout(() => {
              this.setState({ firstOrSecond: !aniamteFirst, animationInAction: false })
              if (aniamteFirst) this.changePath(media)
              else this.changePathSecondary(media)
            }, 200)
          }, 300)
        }, 200)
      }
    }, 200)
  }

  animationGsapReverse = () => {
    if (this.state.animationInAction) return
    this.setState({ renderSecond: true, animationInAction: true })

    const { firstOrSecond } = this.state

    setTimeout(async () => {
      let FirstContainer
      let SecondContainer

      const animateFirst = false

      if (animateFirst) {
        FirstContainer = document.getElementById('allFolderContainer')
        SecondContainer = document.getElementById('secondaryFcontainer')

        gsap.set(FirstContainer, { autoAlpha: 1 })
        gsap.set(SecondContainer, { autoAlpha: 0, x: -1000 })

        setTimeout(() => {
          if (animateFirst) this.changePathSecondary()
          else this.changePath()

          setTimeout(() => {
            gsap.set(FirstContainer, { x: +1000, autoAlpha: 1 })
            gsap.set(SecondContainer, { x: 0, autoAlpha: 1 })

            setTimeout(() => {
              this.setState({ firstOrSecond: !animateFirst, animationInAction: false })
              if (animateFirst) this.changePath()
              else this.changePathSecondary()
            }, 200)
          }, 300)
        }, 200)
      } else {
        FirstContainer = document.getElementById('allFolderContainer')
        SecondContainer = document.getElementById('secondaryFcontainer')

        gsap.set(SecondContainer, { autoAlpha: 1, height: '200px', x: 0 })
        gsap.set(FirstContainer, { autoAlpha: 0 })
        gsap.set(FirstContainer, { x: -1000, delay: 0.2 })
        setTimeout(() => {
          if (animateFirst) this.changePathSecondary()
          else this.changePath()

          setTimeout(() => {
            gsap.set(SecondContainer, { x: +1000, autoAlpha: 0 })
            gsap.set(FirstContainer, { x: 0, autoAlpha: 1 })
            setTimeout(() => {
              this.setState({ firstOrSecond: !animateFirst, animationInAction: false })
              if (animateFirst) this.changePath()
              else this.changePathSecondary()
            }, 200)
          }, 300)
        }, 200)
      }
    }, 200)
  }

  render() {
    const { hoverIndex } = this.state

    return (
      <Box>
        <ChangeFolderBox id={'fullBox'} p={24}>
          <Box height={70} id="MainText" center>
            <Text fontSize={24} weight={700} style={{ position: 'relative', color: AppStore.theme.o.black }}>
              {this.props.fodler
                ? 'Dove vuoi spostare il tuo folder selezionato ?'
                : 'Dove vuoi spostare i tuoi file selezionati ?'}
            </Text>
          </Box>
          <Box>
            <Box
              flex
              id="allFolderContainer"
              style={{ gap: 16, minHeight: 900, maxHeight: 70, width: '-webkit-fill-available' }}
              mt={24}
              scroll
            >
              <Box id="main" style={{ gap: 16, maxHeight: '60vh' }}>
                {this.state.path.split('/').length > 2 && (
                  <Box
                    vcenter
                    row
                    style={{
                      width: '-webkit-fill-available',
                      height: 70,
                      backgroundColor:
                        // AppStore.theme.o.lightGrey
                        hoverIndex === -999 ? AppStore.theme.o.lightBlue : AppStore.theme.o.lightGrey,
                    }}
                    onClick={() => this.animationGsapReverse()}
                    onHoverEnd={() => this.setState({ hoverIndex: -1 })}
                    onHoverStart={() => this.setState({ hoverIndex: -999 })}
                  >
                    <Box style={{ width: '12%', height: '50%' }} ml={20}>
                      <Icons.folder></Icons.folder>
                    </Box>
                    <Icons.backward fill={AppStore.theme.o.black}></Icons.backward>
                  </Box>
                )}
                {this.state.medias
                  .filter((m) => m.type === 'folder')
                  .map((media, index) => (
                    <Box
                      vcenter
                      row
                      style={{
                        width: '-webkit-fill-available',
                        height: 70,
                        backgroundColor: hoverIndex === index ? AppStore.theme.o.lightBlue : AppStore.theme.o.lightGrey,
                      }}
                      onClick={() => this.animationGsap(media)}
                      onHoverEnd={() => this.setState({ hoverIndex: -1 })}
                      onHoverStart={() => this.setState({ hoverIndex: index })}
                    >
                      <Box style={{ width: '12%', height: '50%' }} mr={6}>
                        <Icons.folder />
                      </Box>
                      <Text
                        fontSize={21}
                        weight={700}
                        style={{
                          textOverflow: 'ellipsis',
                          color: AppStore.theme.o.black,
                          overflow: ' hidden',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {media.visible_name ?? media.name}
                      </Text>
                    </Box>
                  ))}

                {this.state.medias
                  .filter((m) => m.type !== 'folder')
                  .map((media, index) => (
                    <Box
                      vcenter
                      row
                      style={{
                        width: '-webkit-fill-available',
                        height: 70,
                        backgroundColor: AppStore.theme.o.lightGrey,
                        opacity: 0.5,
                      }}
                    >
                      <Box
                        style={{ width: 50, height: 50, overflow: 'hidden', alignContent: 'center' }}
                        ml={20}
                        mr={14}
                      >
                        <MediaImage media={media} width={50} height={50} />
                      </Box>
                      <Text
                        fontSize={21}
                        weight={700}
                        style={{
                          textOverflow: 'ellipsis',
                          color: AppStore.theme.o.black,
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {media.visible_name ?? media.name}
                      </Text>
                    </Box>
                  ))}
              </Box>
            </Box>
            {/* -.----.----.----.----.----.----.----.----.----.----.----.----.----.----.----.----.----.----.----.----.----.--- */}
            {this.state.renderSecond && (
              <Box style={{ minHeight: 700, width: '-webkit-fill-available' }}>
                <Box
                  flex
                  mt={24}
                  id="secondaryFcontainer"
                  style={{
                    gap: 16,
                    minHeight: 900,
                    maxHeight: '60vh',
                    width: '-webkit-fill-available',
                  }}
                  scroll
                >
                  <Box id="main" style={{ gap: 16, maxHeight: '60vh' }}>
                    {this.state.secondaryPath.split('/').length > 2 && (
                      <Box
                        vcenter
                        row
                        style={{
                          width: '-webkit-fill-available',
                          height: 70,
                          backgroundColor:
                            hoverIndex === -999 ? AppStore.theme.o.lightBlue : AppStore.theme.o.lightGrey,
                        }}
                        onClick={() => this.animationGsapReverse()}
                        onHoverStart={() => this.setState({ hoverIndex: -999 })}
                        onHoverEnd={() => this.setState({ hoverIndex: -1 })}
                      >
                        <Box style={{ width: '12%', height: '50%' }} ml={20}>
                          <Icons.folder></Icons.folder>
                        </Box>

                        <Icons.backward fill={AppStore.theme.o.black}></Icons.backward>
                      </Box>
                    )}
                    {this.state.secondaryMedia
                      .filter((m) => m.type === 'folder')
                      .map((media, index) => (
                        <Box
                          vcenter
                          row
                          style={{
                            width: '-webkit-fill-available',
                            height: 70,
                            backgroundColor:
                              hoverIndex === index ? AppStore.theme.o.lightBlue : AppStore.theme.o.lightGrey,
                          }}
                          onClick={() => this.animationGsap(media)}
                          onHoverEnd={() => this.setState({ hoverIndex: -1 })}
                          onHoverStart={() => this.setState({ hoverIndex: index })}
                        >
                          <Box style={{ width: '12%', height: '50%' }} ml={20}>
                            <Icons.folder></Icons.folder>
                          </Box>
                          <Text
                            fontSize={21}
                            weight={700}
                            style={{
                              textOverflow: 'ellipsis',
                              color: AppStore.theme.o.black,
                              overflow: ' hidden',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {media.visible_name ?? media.name}
                          </Text>
                        </Box>
                      ))}

                    {this.state.secondaryMedia
                      .filter((m) => m.type !== 'folder')
                      .map((media, index) => (
                        <Box
                          vcenter
                          row
                          style={{
                            width: '-webkit-fill-available',
                            height: 70,
                            backgroundColor: AppStore.theme.o.lightGrey,
                            opacity: 0.5,
                          }}
                        >
                          <Box style={{ width: '12%', height: '60%', overflow: 'hidden' }} center ml={20}>
                            <MediaImage media={media} width={50} height={50} />
                          </Box>
                          <Text
                            fontSize={21}
                            weight={700}
                            style={{
                              textOverflow: 'ellipsis',
                              color: AppStore.theme.o.black,
                              overflow: ' hidden',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {media.visible_name ?? media.name}
                          </Text>
                        </Box>
                      ))}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </ChangeFolderBox>

        <Box style={{ position: 'absolute', bottom: -80, alignSelf: 'center' }} center>
          <Button
            width={300}
            variant="neutral"
            onClick={async () => {
              await this.props.onFinish(this.state.path, this.props.selectedMedia)
            }}
          >
            {T.mediaPage.move}
          </Button>
        </Box>
      </Box>
    )
  }
}

const ChangeFolderBox = styled(Box)`
  width: 100%;
  max-height: 700px;
  min-height: 700px;
  position: relative;
  overflow: hidden;
`
