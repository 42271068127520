/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { AppStore, T } from 'utils'
import { GenerationSettingTitle } from './GenerationSettingTitle'

/* ------------------- INTERFACES  */
interface Props {
  selectedLength: number
  setSelectedLength: (index: number) => void
}

export const GenerationLength = ({ selectedLength, setSelectedLength }: Props) => {
  /* ----------------- VARIABLES  */
  const possiblesLength = [
    {
      type: 'short',
      description: `${T.textGenerator.optimalFor} ${T.common.socials.xTwitter}`,
      icon: (
        <Icons.shortGeneration fill={selectedLength === 0 ? AppStore.theme.o.blueOnLight : AppStore.theme.o.darkGrey} />
      ),
    },
    {
      type: 'medium',
      description: `${T.textGenerator.optimalFor} ${T.common.socials.facebook}/${T.common.socials.instagram}`,
      icon: (
        <Icons.mediumGeneration
          fill={selectedLength === 1 ? AppStore.theme.o.blueOnLight : AppStore.theme.o.darkGrey}
        />
      ),
    },
    {
      type: 'long',
      description: `${T.textGenerator.optimalFor} ${T.common.socials.linkedin}`,
      icon: (
        <Icons.largeGeneration fill={selectedLength === 2 ? AppStore.theme.o.blueOnLight : AppStore.theme.o.darkGrey} />
      ),
    },
  ]

  return (
    <GenerationLengthContainer>
      <GenerationSettingTitle text={T.postPage.lengthGeneration} />

      <Container row gap={16}>
        {possiblesLength.map((item, index) => (
          <Item
            selectedLength={selectedLength === index}
            key={index}
            center
            onClick={() => {
              setSelectedLength(index)
            }}
          >
            {item.icon}
          </Item>
        ))}
      </Container>

      <ContainerDescription>
        <DescriptionText>{possiblesLength[selectedLength].description}</DescriptionText>
      </ContainerDescription>
    </GenerationLengthContainer>
  )
}

const GenerationLengthContainer = styled(Box)`
  position: relative;
  border-radius: none;
`

const Container = styled(Box)`
  width: 152px;
  height: 40px;
  background-color: ${() => AppStore.theme.o.lightGrey};
  padding: 8px 24px;
  align-items: center;
`

const Item = styled(Box)<{ selectedLength?: boolean }>`
  width: 24px;
  height: 24px;
  border-radius: 8px;
  background-color: ${({ selectedLength }) =>
    selectedLength ? AppStore.theme.o.lightBlue : AppStore.theme.o.lightGrey};
  border: ${({ selectedLength }) =>
    selectedLength ? `1px solid ${AppStore.theme.o.blueOnLight}` : '1px solid transparent'};

  :hover {
    background-color: ${() => AppStore.theme.o.lightBlue};
  }
`

const ContainerDescription = styled(Box)`
  margin-top: 8px;
  width: 100px;
`

const DescriptionText = styled(Box)`
  font-family: 'Aktiv Grotesk';
  font-style: italic;
  font-weight: 800;
  font-size: 12px;
  line-height: 13px;
  color: ${() => AppStore.theme.o.darkGrey};
`
