import { IReply, IComments, IArticle } from 'pages/ai-discover/aiDiscoverInterface'
import { api, responseErrorCheck } from './api'
import AstersBaseResource from './AstersBaseResource'
import { AppStore } from 'utils'

// <-------------------------INFERFACE------------------------->

export default class Comments extends AstersBaseResource {
  static endpoint = '/comments'

  static async createComment(comment: IComments, article: IArticle) {
    return api
      .post<any>(
        `${this.endpoint}/${AppStore.getWorkspaceId()}/${comment.boardId}/${comment.articleId}/createComment`,
        {
          comment,
          article,
        }
      )
      .then(responseErrorCheck)
  }

  static async getComments(boardId: string, articleId: string, language: string) {
    return api
      .get<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/${boardId}/${articleId}/getComments`, {
        language,
      })
      .then(responseErrorCheck)
  }

  static async updateComment(boardId: string, comment: string, commentId: string) {
    return api
      .put<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/${commentId}/${boardId}/updateComment`)
      .then(responseErrorCheck)
  }

  static async deleteComment(commentId: string, boardId: string, articleId: string) {
    return api
      .delete<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/${commentId}/${boardId}/${articleId}`)
      .then(responseErrorCheck)
  }

  static async createAnswer(reply: IReply, commentId: string, boardId: string) {
    return api
      .post<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/${commentId}/${boardId}/createAnswer`, {
        reply,
        commentId,
      })
      .then(responseErrorCheck)
  }

  static async updateAnswer(text: string, commentId: string, answerId: string, boardId: string) {
    return api
      .put<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/${answerId}/${commentId}/${boardId}/updateAnswer`, {
        text,
      })
      .then(responseErrorCheck)
  }

  static async deleteAnswer(commentId: string, answerId: string, boardId: string) {
    return api
      .delete<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/${answerId}/${commentId}/${boardId}/deleteAnswer`)
      .then(responseErrorCheck)
  }
}
