/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Button, Icons } from 'components'
import { openCrello } from 'components/Modal/ModalManager'
import { truncateText } from 'pages/ai-discover/commonFunction'
import { useEffect, useRef, useState } from 'react'
import { CrelloResponse } from 'types'
import { AppStore, CrelloType, T } from 'utils'
import { ITemplate } from './TemplateSection'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  template: ITemplate
  isProject: boolean
  onCrelloSave?: (crelloResponse: CrelloResponse) => void
}

export const CrelloProject = ({ template, onCrelloSave, isProject }: Props) => {
  /* ----------------- STATE  */
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const [dimensions, setDimensions] = useState<{
    width: number
    height: number
  }>({ width: 0, height: 0 })

  /* ----------------- REF  */
  const imgRef = useRef<HTMLImageElement>(null)

  /* ----------------- METHODS  */
  const handleOnClickCrelloTemplate = () => {
    const formatTemplate = {
      ...template,
    }
    formatTemplate.image = null

    AppStore.closeAllModal()
    setTimeout(() => {
      openCrello(formatTemplate, [], onCrelloSave, isProject ? CrelloType.PROJECT : CrelloType.TEMPLATE)
    }, 100)
  }

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    if (imgRef.current) {
      imgRef.current.onload = () => {
        setDimensions({
          width: imgRef.current?.naturalWidth ?? 0,
          height: imgRef.current?.naturalHeight ?? 0,
        })
      }
    }
  }, [])

  return (
    <CrelloProjectContainer
      removeHover
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <ProjectImageContainer>
        {template.image ? (
          <ProjectImage ref={imgRef} src={template.image} alt="Template custom editor" />
        ) : (
          <ProjectImagePlaceholder>
            <Icons.medias fill={AppStore.theme.o.darkGrey} width={48} height={48} />
            <PlaceholderText>{T.calendarPage.previewInProgress}...</PlaceholderText>
          </ProjectImagePlaceholder>
        )}

        {/* Hover Container */}
        {template.image !== null && isHovered && (
          <HoveredContainer>
            <OpenProjectBtn variant="neutral" size="small" onClick={handleOnClickCrelloTemplate}>
              {T.mediaPage.openProject}
            </OpenProjectBtn>
          </HoveredContainer>
        )}
      </ProjectImageContainer>

      <ProjectName>{truncateText(template.name, 23)}</ProjectName>

      <ProjectDimension>
        {dimensions.width} x {dimensions.height} px
      </ProjectDimension>
    </CrelloProjectContainer>
  )
}

const CrelloProjectContainer = styled(Box)``

const ProjectImageContainer = styled(Box)`
  position: relative;
  width: 254px;
  height: 254px;
  margin-bottom: 8px;
  background-color: ${() => AppStore.theme.o.lightestGrey};
  margin: 0 auto;
`

const ProjectImage = styled.img`
  width: 254px;
  height: 254px;
  object-fit: contain;
  border-radius: 14px;
  margin: 0 auto;
`

const ProjectName = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  font-size: 21px;
  font-weight: 500;
  width: 254px;
  white-space: nowrap;
`

const ProjectDimension = styled(Box)`
  color: ${() => AppStore.theme.o.darkGrey};
  font-size: 16px;
  font-weight: 400;
  margin-right: auto;
`

const HoveredContainer = styled(Box)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`

const OpenProjectBtn = styled(Button)`
  color: ${() => AppStore.theme.o.black};
  padding: 8px 48px 8px 48px !important;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
`

const ProjectImagePlaceholder = styled(Box)`
  width: 254px;
  height: 254px;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: ${() => AppStore.theme.o.lightestGrey};
`

const PlaceholderText = styled(Box)`
  color: ${() => AppStore.theme.o.darkGrey};
  font-size: 12px;
  font-weight: 400;
`
