import styled from '@emotion/styled'

import Auth from 'api/Auth'
import { Tracker } from 'api/Tracker'
import { Box, Button } from 'components'
import { useEffect } from 'react'
import { useUserStore } from 'stores/UserStore'
import { User } from 'types'
import { AppStore } from 'utils'
import { TRACKEREVENTS } from 'utils/eventsTracker'
import ConfirmEmailComponent from './ConfirmEmailComponent'

const ValidateEmailPage = () => {
  const user = useUserStore((state) => state.user)

  const forceRedirect = () => {
    if (user) {
      const isEmailValid = !(user as User).temp_token
      if (isEmailValid) window.location.href = '/'
    }
  }

  useEffect(() => {
    forceRedirect()
  }, [])

  return (
    <Box
      style={{
        width: '100%',
        height: '100%',
        // fill the background image
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        backgroundSize: 'contain',
        backgroundColor: AppStore.theme.o.background,
      }}
    >
      <ConfirmEmailComponent />
      <LogoutButton />
    </Box>
  )
}

export default ValidateEmailPage

const LogoutButton = () => {
  const handleLogOut = () => {
    try {
      Auth.logout()
      Tracker.trackEvent(TRACKEREVENTS.LOGOUT_SUCCESSFUL, {})
      localStorage.clear()
    } catch (error) {
      Tracker.trackEvent(TRACKEREVENTS.LOGOUT_FAILED, { error_message: 'An error occured. Please try again later' })
    }
  }

  return <LogoutButtonStyle onClick={() => handleLogOut()}>Logout</LogoutButtonStyle>
}

const SubscriptionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  border-radius: 8px;
  background: transparent;
  margin: 0 auto;
`

const LogoutButtonStyle = styled(Button)`
  padding: 12px 24px;
  min-height: 40px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  width: 200px;
  line-height: normal;
  margin-top: 8px;
  color: ${AppStore.theme.o.black};
  background-color: ${AppStore.theme.o.background};
`
