/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { AppStore } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  action: (e) => void
}

export const BtnConfirmTag = ({ action }: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <BtnConfirmTagContainer onClick={action} removeHover>
      <Icons.tagCreate />
    </BtnConfirmTagContainer>
  )
}

const BtnConfirmTagContainer = styled(Box)`
  width: 36px;
  height: 36px;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${() => AppStore.theme.o.secondary_container};
  border: 1px solid ${() => AppStore.theme.o.on_secondary_container};

  svg {
    width: 24px;
    height: 24px;
    fill: ${() => AppStore.theme.o.on_secondary_container};
  }

  &:hover {
    border-color: transparent;
  }
`
