/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { LogoAstersFullDynamicTheme } from 'components/LogoAstersFullDynamicTheme'
import { IReportConfig } from 'types/AnalyticsInferfaces'
import { AppStore } from 'utils'
import RenderReportName from './RenderReportName'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  report: IReportConfig
}

export const ReportHeader = ({ report }: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <ReportHeaderContainer>
      <ReportLogoContainer className="logo_image">
        {report.info.reportLogo ? (
          <img src={report.info.reportLogo} alt="your_logo" />
        ) : (
          <LogoAstersFullDynamicTheme logoHeight={64} logoWidth={146} />
        )}
      </ReportLogoContainer>

      <RenderReportName />
    </ReportHeaderContainer>
  )
}

const ReportHeaderContainer = styled(Box)`
  width: 100%;
  max-height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  padding-inline: 24px;
  margin-bottom: 48px;
`

const ReportLogoContainer = styled(Box)`
  flex: 1;
  justify-content: flex-start;

  img {
    width: 200%;
    max-width: 40%;
    max-height: 130px;
    object-fit: contain;
    border-radius: 14px;
  }

  //GIVING COLOR TO LOGO
  & svg {
    g {
      path {
        // JUST FOR LETTERS
        &:nth-child(-n + 6) {
          fill: ${() => AppStore.theme.o.black};
        }
      }
    }
  }
`
