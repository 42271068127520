/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { TColorLabel } from 'components/CreatePost/PreviewComponents/LabelsComponents/LabelsContentComponents/Label'
import { AppStore, T } from 'utils'
import { LabelColors } from './LabelColors'
import { useState } from 'react'
import { SelectButton } from 'components/UI/SelectButton'
import { InputEditableContent } from 'components/InputEditableContent'
import Labels from 'api/Labels'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  id: string
  label: string
  color: TColorLabel
  isSelected: boolean
  handleSelectLabel: (id: string) => void
}

export const ModifyLabelCard = ({ id, label, color, isSelected, handleSelectLabel }: Props) => {
  /* ----------------- STATE  */
  const [labelNameInput, setLabelNameInput] = useState<string>(label)
  const [labelColors, setLabelColors] = useState<TColorLabel>(color)

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */
  const modifyLabelName = async (newLabelName: string) => {
    const updateParam = {
      color: color,
      label: newLabelName,
      workspaceId: AppStore.getWorkspaceId(),
    }

    try {
      const res = await Labels.updateLabel(id, updateParam)
      setLabelNameInput(newLabelName)
      return true
    } catch (e) {
      return false
    }
  }

  const modifyLabelColor = async (newColor: TColorLabel) => {
    const updateParam = {
      color: newColor,
      label: label,
      workspaceId: AppStore.getWorkspaceId(),
    }

    try {
      const res = await Labels.updateLabel(id, updateParam)
      setLabelColors(newColor)
    } catch (e) {
      console.error(e)
    }
  }

  /* ----------------- USEEFFECT  */

  return (
    <ModifyLabelCardContainer>
      <ModifyContent>
        <InputEditableContent
          value={labelNameInput}
          submitAction={(currentLabelName) => modifyLabelName(currentLabelName)}
          defaultValue={labelNameInput}
          maxWidth={200}
          maxLength={50}
          notifyInfo={T.postPage.labelsSection.labelRename(1, 50)}
        />

        <LabelColors id={id} selectedColor={labelColors} changeColorLabel={modifyLabelColor} />
      </ModifyContent>

      <SelectButton action={() => handleSelectLabel(id)} selected={isSelected} />
    </ModifyLabelCardContainer>
  )
}

const ModifyLabelCardContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  height: 48px;
`

const ModifyContent = styled(Box)`
  display: flex;
  flex: 1;
  padding: 8px 16px 8px 6px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  border-radius: 14px;
  border: 1px solid ${() => AppStore.theme.o.lightGrey};
  background: ${() => AppStore.theme.o.surface};
`
