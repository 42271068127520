/* ___________________ IMPORTS ___________________ */
import styled from '@emotion/styled'
import Utils from 'api/Utils'
import { Box } from 'components'
import { AppStore } from 'utils'

/* ___________________ TYPE ___________________ */
type TSkeleton = 'article' | 'board' | 'calendarCard' | 'calendarCell' | 'crelloTemplates' | 'analyticsGraph'

interface Props {
  type: TSkeleton
  num: number
  key?: string
  skeletonKey?: string
  width?: number
  height?: number
}

const darkMode = localStorage.getItem('darkTheme')

let gradientColor = ''

if (darkMode === 'true') {
  gradientColor = `linear-gradient(
    120deg,
    hsla(0, 0%, 22%, 0.6) 20%,
    hsl(0, 0%, 22%) 38%,
    hsl(0, 0%, 22%) 40%,
    hsla(0, 0%, 22%, 0.6) 80%
  )`
}

if (darkMode === 'false') {
  gradientColor = `linear-gradient(
    120deg,
    hsl(0, 0%, 90%) 20%,
    hsl(0, 0%, 93%) 38%,
    hsl(0, 0%, 93%) 40%,
    hsl(0, 0%, 90%) 80%
  )`
}

const SkeletonCard = ({ type, num = 1, width, height, skeletonKey }: Props) => {
  const enumSkeletonToView = (typeEl, number) => {
    const cards = {
      article: <SkeletonArticle key={`skeleton-article-${number}}`} />,
      board: <SkeletonBoard key={`skeleton-board-${number}`} />,
      calendarCard: <SkeletonCalendarCard key={`skeleton-calendar-card-${number}`} />,
      calendarCell: <SkeletonCalendarCell key={`skeleton-calendar-${number}`} />,
      crelloTemplates: <SkeletonCrelloTemplates key={`skeleton-crello-${number}`}> </SkeletonCrelloTemplates>,
      analyticsGraph: <SkeletonAnalyticsGraph key={`skeleton-analytics-${number}`} width={width} height={height} />,
    }

    return cards[typeEl]
  }

  return <>{Array.from(Array(num)).map((ar, index) => enumSkeletonToView(type, index))}</>
}

export default SkeletonCard

const SkeletonArticle = styled(Box)`
  width: 351px;
  height: 423px;
  background-color: ${() => AppStore.theme.o.lightGrey};
  border-radius: 24px;

  background: ${gradientColor};

  background-size: 200% 100%;
  background-position: 100% 0;
  animation: loading-skeleton 1.5s infinite;
`

const SkeletonBoard = styled(Box)`
  width: 356px;
  height: 312px;
  background-color: ${() => AppStore.theme.o.lightGrey};
  border-radius: 24px;

  background: ${gradientColor};

  background-size: 200% 100%;
  background-position: 100% 0;
  animation: loading-skeleton 1.5s infinite;
`

const SkeletonCalendarCard = styled(Box)`
  width: 100%;
  height: 257px;
  background-color: ${() => AppStore.theme.o.lightGrey};
  border-radius: 14px;

  background: ${gradientColor};

  background-size: 200% 100%;
  background-position: 100% 0;
  animation: loading-skeleton 1.5s infinite;
`

const SkeletonCalendarCell = styled(Box)`
  width: 100%;
  height: 100%;
  background-color: ${() => AppStore.theme.o.lightGrey};
  border-radius: 14px;

  background: ${gradientColor};

  background-size: 200% 100%;
  background-position: 100% 0;
  animation: loading-skeleton 1.5s infinite;
`

const SkeletonCrelloTemplates = styled(Box)`
  width: 264px;
  height: 264px;
  background-color: ${() => AppStore.theme.o.lightGrey};
  border-radius: 14px;

  background: ${gradientColor};
`

const SkeletonAnalyticsGraph = styled(Box)`
  /* width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`}; */

  width: 100%;
  height: 100%;
  background-color: ${() => AppStore.theme.o.lightGrey};
  border-radius: 24px;
  background: ${gradientColor};
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: loading-skeleton 1.5s infinite;
`
