import { api, responseErrorCheck } from './api'
import AstersBaseResource from './AstersBaseResource'

export default class AssetsCreator extends AstersBaseResource {
  static endpoint = '/assetsCreator'

  /**
   * API CALL GET per ottenere tutti i template del workspace
   * @param workspaceId id del workspace
   */
  static async getAllAssetsCreations(workspaceId: string) {
    return api
      .get<any>(
        `${this.endpoint}/all/${workspaceId}`,
        {},
        { headers: { 'X-API-KEY': process.env.REACT_APP_PUBLIC_API_KEY } }
      )
      .then(responseErrorCheck)
  }

  /**
   *  API CALL GET per ottenere tutti i progetti di un utente
   * @param userId id dell'utente
   */
  static async getAllProjectsForUser(userId: string) {
    return api.get<any>(`${this.endpoint}/projectsForUser/${userId}`).then(responseErrorCheck)
  }
}
