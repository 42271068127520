import { useCallback } from 'react'
import { showInfo, T } from 'utils'
import { UpgradeOptionsPhase } from '../UpgradeOptionsModal'
import { Button } from 'components/Button'
import { useUpgradePlanStore } from 'stores/UpgradePlanStore'

/**
 * Component for the next button
 */
export const GoToNextButton: React.FC<{
  selectedSection: UpgradeOptionsPhase
  handleSelect: (optionSelected: UpgradeOptionsPhase) => void
}> = ({ selectedSection, handleSelect }) => {
  const { isLoading } = useUpgradePlanStore((state) => ({ isLoading: state.processing }))
  const isVisible = selectedSection != UpgradeOptionsPhase.OPTIONS
  const handleClick = () => {
    if (selectedSection === UpgradeOptionsPhase.OPTIONS) showInfo(T.billing.upgradePlan.selectOption)
    else handleSelect(selectedSection)
  }

  return isVisible ? (
    <Button
      variant="primary"
      size="smaller"
      width={180}
      loading={isLoading}
      height={40}
      style={{ position: 'absolute', bottom: -50, right: '-40px', zIndex: 999 }}
      onClick={handleClick}
    >
      <p>{T.billing.upgradePlan.confirm}</p>
    </Button>
  ) : null
}
