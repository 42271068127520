/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Tracker } from 'api/Tracker'
import Utils, { openFrill } from 'api/Utils'
import { Box, Button } from 'components'
import { openDraftModal } from 'components/Modal/ModalManager'
import { useNavigate } from 'react-router-dom'
import { AppStore, T } from 'utils'
import { TRACKEREVENTS } from 'utils/eventsTracker'

/* ------------------- INTERFACES  */
interface IButtonConfig {
  id: string
  name: string
  action: () => void
}

interface Props {}

export const LowerButtonsDashboard = ({}: Props) => {
  /* ----------------- ROUTER  */
  const navigate = useNavigate()

  /* ----------------- METHODS  */
  const handleDraftClick = async () => {
    try {
      openDraftModal()
    } catch (e) {
      console.error(e)
    }
  }

  const handleWorkspaceClick = () => {
    navigate('/settings/workspace', { state: { section: 'workspace' } })
  }

  const handleAccountManagerClick = () => {
    navigate('/settings/account')
  }

  const handleHelpClick = () => {
    if (AppStore.loggedUser.language === 'en') {
      window.open('https://help.asters.ai/en', '_blank')
    } else {
      window.open('https://help.asters.ai/it', '_blank')
    }
    Tracker.trackEvent(TRACKEREVENTS.HELP_CENTER_VISITED, { position: 'Dashboard' })
  }

  const handleRoadmapClick = async () => {
    openFrill()
  }

  /* ----------------- CONFIG  */
  const upperSectionBtnConfig: IButtonConfig[] = [
    {
      id: 'draft-dashboard-button',
      name: T.DashboardPage.draft,
      action: handleDraftClick,
    },
    {
      id: 'workspace-dashboard-button',
      name: T.DashboardPage.enviromentManager,
      action: handleWorkspaceClick,
    },
    {
      id: 'account-dashboard-button',
      name: T.DashboardPage.accountManager,
      action: handleAccountManagerClick,
    },
  ]

  const lowerSectionBtnConfig: IButtonConfig[] = [
    {
      id: 'help-dashboard-button',
      name: T.DashboardPage.guideAndTutorial,
      action: handleHelpClick,
    },
    {
      id: 'roadmap-dashboard-button',
      name: T.DashboardPage.publicRoadMap,
      action: handleRoadmapClick,
    },
  ]

  return (
    <LowerButtonsDashboardContainer>
      <UpperSection>
        {upperSectionBtnConfig.map((btn) => (
          <Button key={btn.id} size="small" variant="neutral" onClick={btn.action} flex hideBoxShadowOnHover>
            {btn.name}
          </Button>
        ))}
      </UpperSection>

      <LowerSection>
        {lowerSectionBtnConfig.map((btn) => (
          <Button key={btn.id} size="small" variant="neutral" onClick={btn.action} flex hideBoxShadowOnHover>
            {btn.name}
          </Button>
        ))}
      </LowerSection>
    </LowerButtonsDashboardContainer>
  )
}

const LowerButtonsDashboardContainer = styled(Box)`
  margin-top: 50px;
  gap: 56px;
  width: 407px;
`

const UpperSection = styled(Box)`
  padding: 35px 45px;
  gap: 24px;
  border-radius: 14px;
  border: 2px solid ${() => AppStore.theme.o.lightGrey};
`

const LowerSection = styled(Box)`
  display: flex;
  gap: 16px;
  margin: 0 auto;
  width: 337px;
`
