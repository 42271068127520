/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { AppStore, T } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  text: string
  description?: string
}

export const PreviewPostContent = ({ text, description }: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <PreviewPostContentContainer>
      {text && (
        <>
          <ContentPreview
            style={{
              height: description ? 56 : '100%',
              minHeight: description ? 56 : 300,
            }}
          >
            <TextareaStyle>{text}</TextareaStyle>
          </ContentPreview>

          {description && (
            <ContentPreview
              style={{
                flex: 1,
                minHeight: 300,
              }}
            >
              <TextareaStyle>{description}</TextareaStyle>
            </ContentPreview>
          )}
        </>
      )}

      {!text && (
        <ContentPreview
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: AppStore.theme.o.darkGrey,
            fontWeight: 500,
            fontSize: 16,
          }}
        >
          {T.postPage.noTextInserted}.
        </ContentPreview>
      )}
    </PreviewPostContentContainer>
  )
}

const PreviewPostContentContainer = styled(Box)`
  flex-direction: column;
  gap: 16px;
  max-height: 620px;
  width: 422px;
  max-width: 442px;
`

const ContentPreview = styled(Box)`
  padding: 16px;
  border-radius: 14px;
  border: 1px solid ${() => AppStore.theme.o.lightGrey};
  background: ${() => AppStore.theme.o.surface};
  color: ${() => AppStore.theme.o.black};

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

const TextareaStyle = styled.div`
  resize: none;
  height: 100%;
  background: ${() => AppStore.theme.o.surface};
  color: ${() => AppStore.theme.o.black};
  border: 0px solid transparent;
  white-space: pre-wrap;
`
