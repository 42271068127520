import Highcharts from 'highcharts'
import { mergeWithDefaultOptions } from '../defaultChartOptions'
import { AppStore, formatNumber } from 'utils'

const tooltipOptions: Highcharts.TooltipOptions = {
  borderRadius: 14,
  backgroundColor: AppStore.theme.o.lightestGrey,
  headerFormat: `<span style="color:{point.color}">{point.name}</span><br/>`,
  pointFormatter: function () {
    return `
    </br><b style="color: ${AppStore.theme.o.darkGrey}">${this.series.name[0]}:</b> <b style="color:${
      AppStore.theme.o.black
    }">${this.series.yAxis.categories[this.y!]}</b>

    </br><b style="color: ${AppStore.theme.o.darkGrey}">${this.series.name[1]}:</b> <b style="color:${
      AppStore.theme.o.black
    }">${this.series.xAxis.categories[this.x]}</b>

    </br><b style="color: ${AppStore.theme.o.darkGrey}">${this.series.name[2]}:</b> <b style="color:${
      AppStore.theme.o.black
    }">${this.value ? formatNumber(this.value) : 'NaN'}</b>`
  },
}

export const heatmapOptions: Highcharts.Options = mergeWithDefaultOptions({
  chart: {
    type: 'heatmap',
  },
  yAxis: {
    tickLength: 1000,
    reversed: true,
  },
  legend: {
    borderWidth: 0,
    borderColor: 'transparent',
  },
  tooltip: tooltipOptions,

  plotOptions: {
    heatmap: {
      borderWidth: 0.5, // Set the border width for the points
      borderColor: AppStore.theme.o.surface, // Set the border color for the points
    },
  },
  colorAxis: {
    reversed: true,
    type: 'linear',
    tickAmount: 5,
    stops: [
      [0, '#48d7e433'], // Color for the lowest data value
      [0.25, '#48d7e466'], // Color for the mid-low data value
      [0.5, '#48d7e4aa'], // Color for the mid-high data value
      [0.75, '#48d7e4bb'], // Color for the high data value
      [1, '#48D7E4'], // Color for the highest data value
    ],
    labels: {
      style: {
        color: AppStore.theme.o.black,
      },
    },
  },
})
