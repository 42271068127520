import React from 'react'
import { Box } from './Box'
import { AppStore } from 'utils'
import styled from '@emotion/styled'

export default function BetaComponent() {
  return (
    <Box
      width={60}
      height={21}
      style={{
        border: `1px solid ${AppStore.theme.o.betaComponentText}`,
        borderRadius: 8,
        backgroundColor: AppStore.theme.o.betaComponentBackground,
      }}
      center
    >
      <StyledText>β beta</StyledText>
    </Box>
  )
}

const StyledText = styled.p`
  color: ${() => AppStore.theme.o.betaComponentText};
  font-size: 12px;
  font-family: Aktiv Grotesk;
  font-weight: 500;
  line-height: 20px;
`
