/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import MediaButton from './AddMediaButton'
import EditorButton from './EditorButton'
import { ActivePostCard } from '../UI/ActivePostCard'

export const PostMediaHeader = () => {
  return (
    <PostMediaHeaderContainer>
      {/*-------- Account Info--------*/}
      <ActivePostCard />
      {/*-------- Modals--------*/}
      <MediaHeaderButtonsContainer>
        {/*-------- Add Media Button--------*/}
        <MediaButton />
        {/*-------- OpenEditor--------*/}
        <EditorButton />
      </MediaHeaderButtonsContainer>
    </PostMediaHeaderContainer>
  )
}

const PostMediaHeaderContainer = styled(Box)`
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const MediaHeaderButtonsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
`
