import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { AppStore } from 'utils'
import { keyframes } from '@emotion/react'
import { DigitsState, NotificationLabelProps, NotificationLimit } from 'types/NotifyInterface'

interface Props {
  count?: number
  style?: React.CSSProperties
  limit?: NotificationLimit
  openNotify?: () => void // for notify on header
}

export const NotificationIcon = ({ count = 0, style, limit = NotificationLimit.DEFAULT, openNotify }: Props) => {
  const [isIncreasing, setIsIncreasing] = useState(true)
  const [animation, setAnimation] = useState<'scaleIn' | 'scaleOut' | 'none'>('none')
  const [isVisible, setIsVisible] = useState(count > 0)

  // Mantiene lo stato delle cifre per le notifiche.
  const [digits, setDigits] = useState<DigitsState[]>(
    Array.from(count.toString()).map((d) => ({ value: d, animate: false }))
  )
  // Effetto per gestire l'animazione di ingresso/uscita dell'icona delle notifiche.
  useEffect(() => {
    let timer
    if (count <= 0) {
      setAnimation('scaleOut')
      timer = setTimeout(() => {
        setIsVisible(false)
      }, 300) // 300ms è la durata dell'animazione
    } else {
      setIsVisible(true)
      setAnimation('scaleIn')
    }

    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [count])

  // Effetto per aggiornare lo stato delle cifre quando cambia il conteggio delle notifiche + animazioni.
  useEffect(() => {
    const newDigits = Array.from(count.toString())
    const previousCountString = digits.map((d) => d.value).join('')
    // Mappa ogni cifra del nuovo conteggio a un oggetto che contiene la cifra e se dovrebbe essere animato o meno
    const newDigitsState = newDigits.map((d, index) => {
      // Una cifra dovrebbe essere animata se:
      // 1. Esisteva una cifra precedente nello stesso indice e è diversa dalla cifra corrente
      // 2. La lunghezza delle cifre del nuovo conteggio è diversa dalla lunghezza delle cifre precedenti
      const shouldAnimate = (digits[index] && digits[index].value !== d) || newDigits.length !== digits.length

      return {
        value: d,
        animate: shouldAnimate,
      }
    })
    // Imposta 'isIncreasing' a true se il nuovo conteggio è maggiore del conteggio precedente
    // o se la lunghezza delle cifre del nuovo conteggio è maggiore di quella delle cifre precedenti
    setIsIncreasing(count > parseInt(previousCountString) || newDigits.length > digits.length)
    setDigits(newDigitsState)
  }, [count])

  // Gestione della fine dell'animazione delle cifre.
  const handleAnimationEnd = (index: number) => {
    if (digits[index] && digits[index].animate) {
      const newDigits = [...digits]
      newDigits[index].animate = false
      setDigits(newDigits)
    }
  }
  // Stili derivati dalle props per la box delle notifiche.
  const notificationStyle = {
    backgroundColor: style?.backgroundColor,
    borderColor: style?.borderColor,
    width: style?.width,
    height: style?.height,
    borderRadius: style?.borderRadius,
    padding: style?.padding,
    margin: style?.margin,
    display: style?.display,
    position: style?.position,
    transform: style?.transform,
    justifyContent: style?.justifyContent,
    top: style?.top,
    bottom: style?.bottom,
    left: style?.left,
    right: style?.right,
    alignItems: style?.alignItems,
  }

  return (
    <>
      {isVisible && (
        <NotificationBox
          className="notification-icon-counter"
          animation={animation}
          style={notificationStyle}
          onClick={openNotify && openNotify}
        >
          <DigitsContainer>
            {count > NotificationLimit.DEFAULT ? (
              <>
                {Array.from(NotificationLimit.DEFAULT.toString()).map((digit, index) => (
                  <NotificationLabel animate="enterUp" key={`limit-${index}`}>
                    {digit}
                  </NotificationLabel>
                ))}
                <NotificationLabel animate="enterUp" className="animate-down">
                  +
                </NotificationLabel>
              </>
            ) : (
              digits.map((digitState, index) => (
                <NotificationLabel
                  key={`digit-${index}`}
                  animate={digitState.animate ? (isIncreasing ? 'enterUp' : 'enterDown') : 'none'}
                  onAnimationEnd={() => handleAnimationEnd(index)}
                >
                  {digitState.value}
                </NotificationLabel>
              ))
            )}
          </DigitsContainer>
        </NotificationBox>
      )}
    </>
  )
}

// Animazioni keyframe per il testo delle notifiche.
const slideUpAndFadeOut = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-50%);
    opacity: 0;
  }
`

const slideDownAndFadeIn = keyframes`
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
 
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`
const slideDownAndFadeOut = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(50%);
    opacity: 0;
  }
`

const slideUpAndFadeIn = keyframes`
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`
// Animazioni keyframe per la box delle notifiche.
const scaleInAnimation = keyframes`
  0% { transform: scale(2.1); opacity: 0; }
  100% { transform: scale(1); opacity: 1; }
`

const scaleOutAnimation = keyframes`
  0% { transform: scale(1); opacity: 1; }
  100% { transform: scale(2.1); opacity: 0; }
`

export const NotificationBox = styled.div<{ animation: 'scaleIn' | 'scaleOut' | 'none' }>`
  background-color: ${() => `${AppStore.theme.o.lightGreen}`};
  border: 1px solid ${() => `${AppStore.theme.o.green}`};
  width: fit-content;
  height: 17px;
  display: inline-flex;
  padding: 4px 8px;
  align-items: center;
  justify-content: center;
  gap: 0;
  border-radius: 8px;
  animation: ${(props) =>
      props.animation === 'scaleIn' ? scaleInAnimation : props.animation === 'scaleOut' ? scaleOutAnimation : 'none'}
    0.3s forwards;
`
const DigitsContainer = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
`
export const NotificationLabel = styled.label<NotificationLabelProps>`
  color: ${() => `${AppStore.theme.o.black}`};
  text-align: center;
  font-family: AktivGrotesk-Regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
  transform-origin: center center;
  top: 0;
  bottom: 0;

  animation: ${(props) => {
      switch (props.animate) {
        case 'enterUp':
          return slideUpAndFadeIn
        case 'exitUp':
          return slideUpAndFadeOut
        case 'enterDown':
          return slideDownAndFadeIn
        case 'exitDown':
          return slideDownAndFadeOut
        default:
          return 'none'
      }
    }}
    0.5s forwards;
`
