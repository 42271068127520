import styled from '@emotion/styled'
import Socialaccounts from 'api/Socialaccounts'
import { useState } from 'react'
import { T } from 'translation/i18n'
import { SocialAccount, SocialMedias, Workspace } from 'types'
import { AppStore, showError, showInfo, showSuccess } from 'utils'
import {
  getFBAccounts,
  getGoogleMyBusinessAuthUrl,
  getIGAccounts,
  getLinkedinAuthUrl,
  getTiktokAuthUrl,
  getTwitterAuthUrl,
  getYoutubeAuthUrl,
} from 'utils/socials'
import { Box } from './Box'
import { Icons } from './Icons'
import { changeWorkspaceModal, openConfirmModalSocial } from './Modal/ModalManager'
import { SocialButtonWithImage } from './SocialButtonWithImage'
import { Text } from './Text'

interface Props {
  account: SocialAccount
  skey: any
  onClick?: (account: SocialAccount) => void
  onDelete?: (account: SocialAccount) => void
  style?: any
  forceFailed?
  changeWorkspace?: boolean
  handleForceUpdate?: () => void
}

export const SocialAccountRow = ({
  account,
  skey,
  onClick,
  onDelete,
  style,
  forceFailed,
  changeWorkspace,
  handleForceUpdate,
}: Props) => {
  /* ----------------- STATE  */
  const [showTooltip, setShowTooltip] = useState(false)

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */
  const failed = AppStore.socialNearToExpire.indexOf(account.account_id) !== -1 || account.expired || forceFailed

  /* ----------------- METHODS  */
  const addAccounts = async (social: SocialMedias) => {
    localStorage.setItem(
      'socialToBeRefreshed',
      JSON.stringify({
        isRefresh: true,
        fromWorkspaceSection: window.location.href.includes('workspace'),
        isX: social === 'x',
      })
    )

    let accounts: SocialAccount[] = []
    switch (social) {
      case 'facebook':
        accounts = await getFBAccounts()
        break
      case 'instagram':
        accounts = await getIGAccounts()
        break
      case 'linkedin':
        return getLinkedinAuthUrl()
      case 'google':
        return getGoogleMyBusinessAuthUrl()
      case 'x':
        return getTwitterAuthUrl()
      case 'youtube':
        return getYoutubeAuthUrl()
      case 'tiktok':
        return getTiktokAuthUrl()
    }

    const accountFounded: SocialAccount[] = []

    const isRefresh = localStorage.getItem('socialToBeRefreshed')
    localStorage.removeItem('socialToBeRefreshed')

    accounts.forEach(async (e) => {
      for (const socialAccount of AppStore.socialAccountList) {
        if (socialAccount.account_id === e.account_id && socialAccount.provider === e.provider) {
          try {
            const response = await saveAccount(e, !!isRefresh)
            response &&
              showSuccess(
                T.success.accontIsUpdated.one +
                  account.provider +
                  T.success.accontIsUpdated.two +
                  e.name +
                  T.success.accontIsUpdated.three
              )
          } catch (error) {
            console.error('errore: ', error)
          }
          // Update AppStore.socialNearToExpire
          const index = AppStore.socialNearToExpire.indexOf(socialAccount.account_id)
          if (index !== -1) {
            AppStore.socialNearToExpire.splice(index, 1)
          }
          //----- ----- ----- ----- ----- ----- ----- ----- ----- -----
          accountFounded.push(socialAccount)
        }
      }
    })

    localStorage.removeItem('socialToBeRefreshed')

    handleForceUpdate && handleForceUpdate()

    if (accounts.length === 0) showError(T.error.errorAccount)
  }

  const confirm = () =>
    new Promise(async (resolve) => {
      try {
        await onDelete?.(account)
      } catch (error) {
        showError(error)
      }

      resolve(null)
    })

  /* ----------------- API CALL  */
  const saveAccount = async (account1: SocialAccount, withRefresh?: boolean): Promise<boolean> => {
    try {
      if (!account1.workspace) {
        account1.workspace = AppStore.workspace
      }
      const exisingSocial = AppStore.socialAccountList.find((acc) => acc.account_id === account1.account_id)
      if (
        withRefresh &&
        AppStore.workspaceId !==
          (typeof exisingSocial?.workspace === 'string' ? exisingSocial?.workspace : exisingSocial?.workspace?._id)
      ) {
        account1.workspace = exisingSocial?.workspace as Workspace
      }
      await Socialaccounts.save(account1)
      return true
    } catch (error: any) {
      throw new Error(error?.message ?? T.error.error)
    }
  }

  const handleDeleteSocial = async () => {
    try {
      const check = await AppStore.checkPermission('delete_social_account')
      if (!check) return showInfo(`${T.error.noPermissionTo} ${T.error.deleteSocials}`)

      await openConfirmModalSocial(confirm)
    } catch (e) {
      console.error(e)
    }
  }

  /* ----------------- USEEFFECT  */

  return (
    <Box
      vcenter
      row
      width={'100%'}
      key={skey}
      pv={20}
      ph={20}
      style={{ border: `1px solid ${AppStore.theme.o.grey}`, ...style }}
      infiniteAnnimation={failed}
      infiniteAnnimationColor={failed ? AppStore.theme.o.red : undefined}
    >
      {/* SE IL TOKEN è FAILATO ALLORA CI SATA UN TOKEN FAILeD */}
      {/* mettere tokenFailed */}
      <SocialButtonWithImage
        socialAccount={account}
        onclicked={(e) => {
          addAccounts(e)
        }}
        tokenFailed={failed}
      />
      <Box
        style={{
          marginLeft: 10,
          marginRight: 10,
          flex: 1,
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          width: 140,
          position: 'relative',
        }}
      >
        {/*  -------------   Profile Name   ------------- */}
        <b
          style={{
            color: AppStore.theme.o.black,
            display: 'inline-block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            width: 140,
            textOverflow: 'ellipsis',
          }}
        >
          {account.name}
        </b>

        {/*  -------------   Profile Type   ------------- */}
        {
          <Text fontSize={12} weight={400} color={AppStore.theme.o.darkGrey}>
            {T.socialProfileType[account?.profileType ?? 'profile']}
          </Text>
        }

        {/*  -------------   Profile E-mail   ------------- */}
        {failed && (
          <>
            <UserEmailText onHoverStart={() => setShowTooltip(true)} onHoverEnd={() => setShowTooltip(false)}>
              {`${T.misc.connectedBy}: ${
                typeof account.user === 'string' ? '' : account?.user?.email?.substring(0, 10)
              }`}
            </UserEmailText>
            {/* --------- User Email tooltip ---------*/}
            {showTooltip && (
              <span
                style={{
                  padding: 5,
                  paddingInline: 20,
                  top: 64,
                  color: 'white',
                  backgroundColor: 'black',
                  borderRadius: 10,
                  position: 'absolute',
                }}
              >
                {typeof account.user === 'string' ? '' : account.user.email}
              </span>
            )}
          </>
        )}

        {/*  -------------   Token expired   ------------- */}
        {failed && (
          <b
            style={{
              fontSize: 9,
              fontWeight: 700,
              color: AppStore.theme.o.red,
            }}
          >
            {T.settings.tokenExpired}
          </b>
        )}
      </Box>

      {/*  -------------   Delete   ------------- */}
      <Box gap={4} row>
        {!!onDelete && (
          <RedContainer>
            <TrashIcon fill={AppStore.theme.o.darkGrey} onClick={handleDeleteSocial} />
          </RedContainer>
        )}

        {/*  -------------   Change workspace   ------------- */}
        {changeWorkspace && (
          <GreenContainer p={3.5}>
            <ChangeWorkspaceIcon
              fill={AppStore.theme.o.darkGrey}
              onClick={() => changeWorkspaceModal(account, handleForceUpdate)}
            />
          </GreenContainer>
        )}
      </Box>
    </Box>
  )
}

const RedContainer = styled(Box)`
  background-color: transparent;
  :hover {
    background-color: ${() => AppStore.theme.o.lightRed};
  }
`
const GreenContainer = styled(Box)`
  background-color: transparent;
  :hover {
    background-color: ${() => AppStore.theme.o.lightGreen};
  }
`

const ChangeWorkspaceIcon = styled(Icons.changeWorkspace)`
  height: 32px;
  width: 32px;
  cursor: pointer;
  //onHover fill color as white
  &:hover {
    fill: ${() => AppStore.theme.o.green};
  }
  animation: fadeIn 0.5s;
`
const TrashIcon = styled(Icons.trash)`
  height: 40px;
  width: 40px;
  cursor: pointer;
  //onHover fill color as white
  &:hover {
    fill: ${() => AppStore.theme.o.red};
  }
  animation: fadeIn 0.5s;
`

const UserEmailText = styled(Box)`
  display: block;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  color: ${() => AppStore.theme.o.darkGrey};
`
