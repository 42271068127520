import { AppStore, T } from 'utils'
import styled from '@emotion/styled'
import { Button, Icons } from 'components'
import { openMediaModal } from 'components/Modal/ModalManager'
import { updateActive } from 'utils/PostUtils/Post'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { Media } from 'types'
import { IPostData } from 'types/PostInterface'

export default function MediaButton() {
  const addMedia = (media) => {
    const { activePost, setRefreshMediaContent } = useCreatePostStore.getState()
    let activePostMedias: Media[] = []

    if ('_id' in activePost && activePost._id === 'GLOBAL') {
      // IPOST DATA
      const activePostData = activePost as IPostData
      activePostMedias = activePostData.medias
      updateActive({ medias: [...activePostMedias, ...media] })
    } else if ('variantId' in activePost) {
      // IPOSTVariant
      activePostMedias = activePost.data.medias
      updateActive({ medias: [...activePostMedias, ...media] })
    } else {
      updateActive({ medias: media })
    }

    setRefreshMediaContent(true)
  }

  return (
    <AddMediaButton
      style={{ minHeight: 40 }}
      onClick={() => openMediaModal({ onMediaSelected: (media: Media[]) => addMedia(media), selectedMedias: [] })}
      variant="neutral"
    >
      <Icons.uploadFile fill={AppStore.theme.o.black} />
      {T.settings.caricaMedia}
    </AddMediaButton>
  )
}

const AddMediaButton = styled(Button)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Aktiv Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  gap: 8px;
`
