/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { AppStore, T } from 'utils'
import { ILabel, Label } from './Label'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  onKeyDownLabel: (e: React.KeyboardEvent<HTMLInputElement>) => void
  inputValueFilter: string
  setInputValueFilter: (newValue: string) => void
  handleRemoveLabel: (value: ILabel) => void
  selectedLabels: ILabel[]
}

export const LabelContentInput = ({
  onKeyDownLabel,
  inputValueFilter,
  setInputValueFilter,
  handleRemoveLabel,
  selectedLabels,
}: Props) => {
  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- METHODS  */
  const handleInputChange = (e) => {
    e.stopPropagation()
    const newValue = e.target.value
    setInputValueFilter(newValue)
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <LabelContentInputContainer>
      <TitleInput>{T.postPage.labelsSection.createOrApplyLabel}</TitleInput>

      <ContainerInput gap={16} width={'100%'} row>
        {selectedLabels?.map((label, i) => (
          <Label
            key={label.label + i}
            id={label._id}
            label={label.label}
            color={label.color}
            removeLabel={handleRemoveLabel}
          />
        ))}

        <InputLabel
          onKeyDown={onKeyDownLabel}
          value={inputValueFilter}
          onChange={handleInputChange}
          placeholder={`${T.postPage.labelsSection.addHere} ⏎`}
        />
      </ContainerInput>
    </LabelContentInputContainer>
  )
}

const LabelContentInputContainer = styled(Box)`
  margin-top: 16px;
  width: 100%;
  border-radius: 0px;
`

const TitleInput = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
  margin-right: auto;
  border-radius: none;
`

const ContainerInput = styled(Box)`
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
  border-radius: 14px;
  border: 1px solid ${() => AppStore.theme.o.grey};
  background: ${() => AppStore.theme.o.lightestGrey};
  overflow-y: scroll;
  padding: 10px 16px;
  min-height: 42px;
  max-height: 250px;
`

const InputLabel = styled.input`
  width: 40%;
  height: 32px;
  border: none;
  background: transparent;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
  z-index: 1;
  color: ${() => AppStore.theme.o.black};

  &::placeholder {
    color: ${() => AppStore.theme.o.grey};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`
