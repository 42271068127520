import styled from '@emotion/styled'
import { Box } from 'components'
import { IPostVariant } from 'types/PostInterface'
import { AppStore } from 'utils'
import { updatePost } from 'utils/PostUtils/Post'

type Props = {
  post: IPostVariant

  value: boolean
  setValue: (value: boolean) => void
  name: string
  postName: string
}

export const ExtraSwitch = ({ post, value, setValue, name, postName }: Props) => {
  const { variantId } = post

  return (
    <Box
      flex
      row
      center
      ph={16}
      pv={8}
      style={{
        backgroundColor: value ? AppStore.theme.o.lightGreen : AppStore.theme.o.lightestGrey,
        border: `1px solid ${value ? AppStore.theme.o.green : AppStore.theme.o.grey}`,
      }}
      onClick={() => {
        setValue(!value)
        updatePost(variantId, { [postName]: !value })
      }}
    >
      <TextExtras style={{ color: value ? AppStore.theme.o.green : AppStore.theme.o.black }}>{name}</TextExtras>
      <Box
        style={{
          width: 27,
          height: 16,
          backgroundColor: value ? AppStore.theme.o.green : AppStore.theme.o.grey,
        }}
      >
        <Box
          style={{
            width: 16,
            height: 16,
            border: `1px solid ${value ? AppStore.theme.o.green : AppStore.theme.o.darkGrey}`,
            backgroundColor: AppStore.theme.o.surface,
            x: value ? 11 : 0,
          }}
        />
      </Box>
    </Box>
  )
}

const TextExtras = styled.p`
  font-family: 'Aktiv Grotesk';
  margin: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 8px;
  position: relative;
`
