/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { AppStore } from 'utils'
import { TTypeDataTable } from './tableInterface'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  value: number
  type: TTypeDataTable
}

export const RowFormatValue = ({ value, type }: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */
  const formatValue = () => {
    switch (type) {
      case 'temporal':
        return formatTemporalValue(value)
      case 'numeric':
        return formatNumericValue(value)
      case '%':
        return `${value}%`
      default:
        return value
    }
  }

  const formatTemporalValue = (seconds: number) => {
    const h = Math.floor(seconds / 3600)
    const m = Math.floor((seconds % 3600) / 60)
    const s = seconds % 60

    const hDisplay = h > 0 ? `${h}h ` : ''
    const mDisplay = m > 0 ? `${m}m ` : ''
    const sDisplay = s > 0 ? `${s}s` : ''

    return `${hDisplay}${mDisplay}${sDisplay}`.trim()
  }

  const formatNumericValue = (num: number) => {
    if(num === -1) return 'N.A.'
    if (num < 1000) {
      return num.toString()
    } else if (num < 1000000) {
      return `${(num / 1000).toFixed(1)}K`
    } else if (num < 1000000000) {
      return `${(num / 1000000).toFixed(1)}M`
    } else if (num < 1000000000000) {
      return `${(num / 1000000000).toFixed(1)}B`
    } else {
      return `${(num / 1000000000000).toFixed(1)}T`
    }
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return <RowFormatValueContainer>{formatValue()}</RowFormatValueContainer>
}

const RowFormatValueContainer = styled(Box)`
  font-size: 14px;
  color: ${() => AppStore.theme.o.darkGrey};
`
