/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { SocialType } from 'api/Utils'
import { Box, Icons } from 'components'
import { SocialAccountsAddable } from 'components/CreatePost/SocialComponents/SocialAccountsAddable'
import { EmptyState, GridContainer } from 'components/UI'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAnalyticsStore } from 'stores/AnalyticsStore'
import { SocialAccount } from 'types'
import { IDataReport, IReportConfig } from 'types/AnalyticsInferfaces'
import { AppStore, T, capitalizeFirstLetter } from 'utils'

/* ------------------- INTERFACES  */
interface Props {
  reportConfig: IReportConfig
  setReportConfig: (reportConfig: IReportConfig) => void
}

export const CreateReportSocials = ({ reportConfig, setReportConfig }: Props) => {
  /* ----------------- ROUTER  */
  const navigate = useNavigate()

  /* ----------------- STATE  */
  const [socialSelected, setSocialSelected] = useState<SocialType>(useAnalyticsStore.getState().selectedSocial)

  /* ----------------- VARIABLES  */
  const socialElements = AppStore.socialAccounts.filter((account) => account.provider === socialSelected)

  const showLinkedinAlert =
    socialElements.length > 0 &&
    socialSelected === 'linkedin' &&
    socialElements.some((acc) => !acc.account_id.includes('urn:li:organization'))

  /* ------------------- ZUSTAND SUBSCRIPTION  */
  const unsubSocialSelected = useAnalyticsStore.subscribe((state) => {
    state.selectedSocial !== socialSelected && setSocialSelected(state.selectedSocial)
  })

  /* ----------------- METHODS  */
  const onClickSocialCard = (account: SocialAccount) => {
    // Se l'account è di linkedin e non è un account di organizzazione non fare nulla
    if (account.provider === 'linkedin' && !account.account_id.includes('urn:li:organization')) return

    const selectedIndex = reportConfig.data.findIndex((data) => data.provider === account.provider)

    const updatedReportConfig = { ...reportConfig }

    if (selectedIndex === -1) {
      // Remove any existing account from the same provider
      updatedReportConfig.data = updatedReportConfig.data.filter(
        (data: any) => data.provider !== account.provider
      ) as IDataReport[]

      const data: IDataReport = {
        account: account._id,
        provider: account.provider,
        metrics: [],
      }
      updatedReportConfig.data.push(data)
    } else if (updatedReportConfig.data[selectedIndex].account !== account._id) {
      // If a different account from the same provider is selected, replace it
      updatedReportConfig.data.splice(selectedIndex, 1)

      // @gian-any
      const data: any = {
        account: account._id,
        provider: account.provider,
        metrics: [],
      }

      updatedReportConfig.data.push(data)
    } else {
      // Deselect the account if it's already selected
      updatedReportConfig.data.splice(selectedIndex, 1)
    }

    setReportConfig(updatedReportConfig)
  }

  const checkIfIsPersonalLinkedin = (account: SocialAccount) => {
    return account.provider === 'linkedin' && !account.account_id.includes('urn:li:organization')
  }

  /* ----------------- RENDER VARIABLES  */
  const socialProfiles = AppStore.socialAccounts
    .filter((account) => account.provider === socialSelected)
    .map((account, index) => (
      <SocialAccountsAddable
        account={account}
        key={index}
        onClick={onClickSocialCard}
        fullWidth
        isSelected={reportConfig.data.some((data) => {
          return data.account === account._id
        })}
        noCheckBox={checkIfIsPersonalLinkedin(account)}
        bg={checkIfIsPersonalLinkedin(account) ? AppStore.theme.o.lightGrey : AppStore.theme.o.surface}
      />
    ))

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    return () => {
      unsubSocialSelected()
    }
  }, [])

  return (
    <>
      <ScrollableContainer height={showLinkedinAlert ? 368 : undefined} mt={48}>
        {/* Lista dei social da selezionare  */}
        {socialElements.length > 0 && (
          <GridContainer cols={3} data={socialProfiles} colGap={10} rowGap={10} width={902} />
        )}
      </ScrollableContainer>

      {/* Linkedin Personal Account Alert */}
      {showLinkedinAlert && (
        <LinkedinAlert>
          {T.analytics.linkedinAlert1} <br />
          {T.analytics.linkedinAlert2}
        </LinkedinAlert>
      )}

      {/* Empty state del social  */}
      {socialElements.length === 0 && (
        <EmptyState
          text={T.analytics.noSocialProfilesConnectedTo + ' ' + capitalizeFirstLetter(socialSelected)}
          icon={<Icons.sadBall />}
          fill
          textButton={T.analytics.addProfile}
          action={() => navigate(`/settings/social`)}
        />
      )}
    </>
  )
}

const ScrollableContainer = styled(Box)`
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`

const LinkedinAlert = styled(Box)`
  margin-top: auto;
  margin-bottom: 24px;

  border-radius: 14px;
  background: ${() => AppStore.theme.o.warning_container};
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  color: ${() => AppStore.theme.o.warning};
  text-align: center;
  font-family: 'Aktiv Grotesk';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`
