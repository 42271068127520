import { FolderOutlined } from '@ant-design/icons'
import Medias from 'api/Medias'
import { Tracker } from 'api/Tracker'
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { Input } from 'components/Input'
import React, { useState } from 'react'
import { T } from 'translation/i18n'
import { AppStore, showInfo } from 'utils'
import { TRACKEREVENTS } from 'utils/eventsTracker'

export const FolderModal: React.FC<{
  onConfirm?: () => void
  path?: string
  media?: string
  moreSocials?: any
  refreshMedias?: () => void
}> = ({ path, onConfirm, media, moreSocials, refreshMedias }) => {
  const [name, setName] = useState('')

  const createFolder = async () => {
    try {
      if (path === undefined) return
      await Medias.createFolder({ path, name })
      Tracker.trackEvent(TRACKEREVENTS.CREATE_FOLDER_COMPLETED, {})
      onConfirm?.()
      AppStore.closeModal('folder-modal')
    } catch (error) {
      console.error(error)
    }
  }

  const onSubmit = async () => {
    try {
      // Check permessi
      const check = await AppStore.checkPermission('manage_media')
      if (!check) return showInfo(`${T.error.noPermissionTo} ${T.error.manageFolders}`)

      // Se molteplici media sono selezionati
      if (moreSocials !== undefined) {
        moreSocials.forEach((mediaToRename, index) => {
          if (index !== 0) Medias.rename(mediaToRename._id, `${name}-(${index - 1})`)
        })
      }

      // Rename media / folder
      if (media) {
        await Medias.rename(media, name)
      }

      // Creazione cartella
      if (media === undefined) {
        createFolder()
      }

      refreshMedias?.()
    } catch (error) {
      console.error(error)
    }

    AppStore.closeModal('folder-modal')
  }

  return (
    <Box row flex vcenter p={50}>
      <Box justify={'space-evenly'} flex>
        <Input
          width={'30vw'}
          autoFocus
          prefix={<FolderOutlined style={{ color: AppStore.theme.o.black }} />}
          style={{ marginBottom: 20 }}
          defaultValue={name}
          placeholder={T.mediaPage.typeHere}
          onChange={setName}
        />
        <Button size="small" variant="neutral" onClick={onSubmit}>
          {media === undefined ? T.mediaPage.create : T.mediaPage.rename}
        </Button>
      </Box>
    </Box>
  )
}
