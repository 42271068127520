import styled from '@emotion/styled'
import { ThemeProvider } from '@mui/material/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker'
import { subDays } from 'date-fns'
import dayjs from 'dayjs'
import { IPostVariant } from 'types/PostInterface'
import { updatePost } from 'utils/PostUtils/Post'
import { muiTheme } from '../utils/MuiUtils/MuiThemes'
import { localDate, localLang } from '../utils/MuiUtils/MuiTranslations'
import { AppStore } from 'utils'
import { Box } from './Box'
import { formatDate } from 'utils/AnalyticsUtils'
import { useEffect, useState } from 'react'

/* ----------------- INTERFACE  */
interface Props {
  post: IPostVariant
  isOpen: string
  handleOpenClose: (value?: string) => void
}

export default function MuiDateTimePicker({ isOpen, post, handleOpenClose }: Props) {
  /* ----------------- METHODS  */
  const handleAccept = (value) => {
    updatePost(post.variantId, { date: new Date(new Date(value).toUTCString()).toISOString() })
    handleOpenClose('')
  }

  const disableDateBeforeCurrent = (date) => {
    return date < subDays(new Date(), 1)
  }

  return (
    <>
      <ShowMuiDatePicker onClick={() => handleOpenClose(post.variantId)}>
        {post.data.date ? formatDate(post.data.date as Date) : formatDate(new Date())}
      </ShowMuiDatePicker>

      {isOpen === post.variantId && (
        <MuiContainer id={`mui-date-picker-${post.variantId}`} className="Mui-Schedule-Picker">
          <ThemeProvider theme={muiTheme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} localeText={localLang} adapterLocale={localDate}>
              <StaticDateTimePicker
                defaultValue={dayjs(post.data.date)}
                onAccept={handleAccept}
                shouldDisableDate={disableDateBeforeCurrent}
              />
            </LocalizationProvider>
          </ThemeProvider>
        </MuiContainer>
      )}
    </>
  )
}

const MuiContainer = styled.div`
  top: 0px;
  left: 710px;
  position: absolute;
  z-index: 1000;
  & .MuiTabs-indicator {
    display: none;
  }

  & .MuiPickersLayout-root {
    border-radius: 14px;
  }
`

const ShowMuiDatePicker = styled(Box)`
  display: flex;
  align-items: center;
  border: 1px solid ${() => AppStore.theme.o.grey};
  background-color: ${() => AppStore.theme.o.lightestGrey};
  border-radius: 14px;
  min-height: 30px;
  width: 185px;
  padding: 4px;
`
