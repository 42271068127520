/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { GenerationSettingTitle } from './GenerationSettingTitle'
import { AppStore, T } from 'utils'
import Select from 'components/Select'
import { useState } from 'react'

/* ------------------- INTERFACES  */
interface Props {
  selectedObjective: number
  selectedTone: number
  setSelectedTone: (index: number) => void
  configSettings: any
  dropdownClass?: string
}

export const GenerationTone = ({
  selectedObjective,
  selectedTone,
  setSelectedTone,
  configSettings,
  dropdownClass,
}: Props) => {
  /* ----------------- STATE  */
  const [openVoiceTone, setOpenVoiceTone] = useState(false)

  /* ----------------- VARIABLES  */
  const toneGenerationSet = configSettings[selectedObjective]?.subSection

  let value
  if (toneGenerationSet === undefined) {
    value = 'Tone of Voice'
  } else {
    value = toneGenerationSet[selectedTone]
  }

  return (
    <GenerationToneContainer>
      <GenerationSettingTitle text={T.textGenerator.selectToneOfVoice} />

      <Select
        objective={value ?? 'Tone of Voice'}
        icon={<Icons.dropdownArrow fill={AppStore.theme.o.darkGrey} width={28} height={28} />}
        width={276}
        options={toneGenerationSet}
        open={openVoiceTone}
        setOpen={setOpenVoiceTone}
        setObjective={setSelectedTone}
        active={selectedObjective !== -1}
        dropdownClass={dropdownClass}
      />
    </GenerationToneContainer>
  )
}

const GenerationToneContainer = styled(Box)`
  margin-right: 2px;
`
