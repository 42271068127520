import styled from '@emotion/styled'
import { facebook, google, instagram, linkedin, tiktok, x, youtube } from 'assets/icons'
import { Box } from 'components'
import { useState } from 'react'
import { SocialAccount } from 'types'
import { AppStore } from 'utils'
import { Icons } from './Icons'
import { IVariantSocial } from 'types/PostInterface'

export interface Props {
  socialAccount: SocialAccount | IVariantSocial
  size?: 'small' | 'medium' | 'large'
  tokenFailed?: boolean
  onclicked?: any
}

const socials = {
  facebook: facebook,
  instagram: instagram,
  linkedin: linkedin,
  x: x,
  google: google,
  tiktok: tiktok,
  youtube: youtube,
}

/**This is main Button!!*/
export const SocialButtonWithImage = ({ socialAccount, size = 'medium', tokenFailed, onclicked }: Props) => {
  const [error, setError] = useState(false)
  const sizes = {
    small: {
      image: { width: 32, height: 32, borderRadius: 32 },
      icon: { width: 24, height: 24, borderRadius: 20 },
    },
    medium: {
      image: { width: 40, height: 40, borderRadius: 40 },
      icon: { width: 28, height: 28, borderRadius: 24 },
    },
    large: {
      image: { width: 48, height: 48, borderRadius: 48 },
      icon: { width: 36, height: 36, borderRadius: 32 },
    },
  }

  return (
    <Box center style={{ position: 'relative', padding: '0px' }}>
      <MyImage
        style={{ ...sizes[size].image }}
        src={error ? 'https://app.asters.io/inc/themes/backend/default/assets/img/avatar.jpg' : socialAccount.picture}
        onError={() => setError(true)}
      />

      <Box
        style={
          tokenFailed
            ? {
                ...sizes[size].icon,
                position: 'absolute',
                bottom: '-6px',
                left: '-6px',
                backgroundColor: 'rgba(0, 0, 0, 0.4)',
              }
            : { position: 'absolute', bottom: '-6px', left: '-6px' }
        }
      >
        <MyIcon style={sizes[size].icon} src={socials[socialAccount.provider]} />
      </Box>

      {tokenFailed === true && (
        <>
          <Box
            infiniteAnnimation={tokenFailed}
            infiniteAnnimationColor={AppStore.theme.o.red}
            style={{ ...sizes[size].image, backgroundColor: 'rgba(0, 0, 0, 0.4)', position: 'absolute' }}
            center
            onClick={() => onclicked(socialAccount.provider)}
          >
            <Icons.change width={30} height={30} fill={AppStore.theme.o.surface} />
          </Box>
        </>
      )}
    </Box>
  )
}

const MyIcon = styled.img`
  /* position: absolute;
  bottom: -6px;
  left: -8px; */
`

const MyImage = styled.img``
