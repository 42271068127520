/* ------------------- IMPORTS */
import { AppStore, T } from 'utils'
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { useState } from 'react'

export const InfoTags = () => {
  /* ----------------- STATE  */
  const [isHovered, setIsHovered] = useState<boolean>(false)

  return (
    <>
      <InfoIcon onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <Icons.info fill={AppStore.theme.o.black} />
      </InfoIcon>

      <InfoContainer
        style={{
          display: isHovered ? 'flex' : 'none',
        }}
      >
        {T.postPage.sfortunatly_we_cant}
      </InfoContainer>
    </>
  )
}

const InfoIcon = styled(Box)`
  z-index: 1;
  position: absolute;
  top: 52px;
  left: calc(100% + 12px);
  cursor: pointer;
  background-color: ${() => AppStore.theme.o.surface};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  cursor: help;

  svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    background-color: ${() => AppStore.theme.o.background};
  }
`

const InfoContainer = styled(Box)`
  position: absolute;
  top: 52px;
  left: 16px;
  right: 16px;
  background-color: ${() => AppStore.theme.o.surface};
  border: 1px solid ${() => AppStore.theme.o.grey};
  padding: 16px;
  color: ${() => AppStore.theme.o.black};
  font-size: 16px;
  text-align: center;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
`
