export enum TRACKEREVENTS {
  SOFTWARE_LAUNCHED = 'Software Launched',
  LOGIN_SUCCESSFUL = 'Log In Successful',
  LOGIN_FAILED = 'Log In Failed',
  LOGOUT_SUCCESSFUL = 'Log Out Successful',
  LOGOUT_FAILED = 'Log Out Failed',
  SIGNUP_INITIATED = 'Sign Up Initiated',
  SIGNUP_SUCCESSFUL = 'Sign Up Successful',
  SIGNUP_FAILED = 'Sign Up Failed',
  DASHBOARD_PAGE_VIEWED = 'Dashboard Page Viewed',
  UPLOAD_FILE_COMPLETED = 'Upload File Completed',
  UPLOAD_FILE_INITIATED = 'Upload File Initiated',
  MEDIA_PAGE_VIEWED = 'Media Page Viewed',
  CREATE_FOLDER_INITIATED = 'Create Folder Initiated',
  CREATE_FOLDER_COMPLETED = 'Create Folder Completed',
  DELETE_FOLDER_COMPLETED = 'Delete Folder Completed',
  GRAPHIC_EDITOR_INITIATED = 'Graphic Editor Initiated',
  GRAPHIC_EDITOR_OPENED = 'Graphic Editor Opened',
  EXPORT_GRAPHIC_EDITOR_EXPORT_FILE = 'Export Graphic Editor File Completed',
  CALENDAR_PAGE_VIEWED = 'Calendar Page Viewed',
  SOCIAL_CHANNELS_PAGE_VIEWED = 'Social Channels Page Viewed',
  SOCIAL_PROFILE_CONNECTION_INITIATED = 'Social Profile Connection Initiated',
  SOCIAL_PROFILE_DELETED = 'Social Profile Deleted',
  SOCIAL_PROFILE_MOVE_COMPLETED = 'Social Profile Move Completed',
  ACCOUNT_PAGE_VIEWED = 'Account Page Viewed',
  PLAN_PRICING_PAGE_VIEWED = 'Plan Pricing Page Viewed',
  PLAN_CHECKOUT_PAGE_VIEWED = 'Plan Checkout Page Viewed',
  PLAN_CUSTOMIZATION_PAGE_VIEWED = 'Plan Customization Page Viewed',
  AI_CREDITS_PAGE_VIEWED = 'AI Credits Page Viewed',
  AI_CAPTION_GENERATED = 'AI Caption Generated',
  AI_CREDITS_CHECKOUT_PAGE_VIEWED = 'AI Credits Checkout Page Viewed',
  AI_CREDITS_PURCHASE_SUCCESSFUL = 'AI Credits Purchase Successful',
  AI_CREDITS_PURCHASE_FAILED = 'AI Credits Purchase Failed',
  POST_DELETED = 'Post Deleted',
  POST_SCHEDULED = 'Post Scheduled',
  AI_CAPTION_FEEDBACK_SUBMITTED = 'AI Caption Feedback Submitted',
  POST_RECYCLED = 'Post Recycled',
  POST_EDITED = 'Post Preview Edited',
  POST_DIRECTLY_PUBLISHED = 'Post Directly Published',
  BLOG_VISITED = 'Blog Visited',
  COMMUNITY_VISITED = 'Community Visited',
  HELP_CENTER_VISITED = 'Help Center Visited',
  ROADMAP_VISITED = 'Roadmap Visited',
  WEBINAR_VISITED = 'Webinar Visited',
  REVIEWS_VISITED = 'Reviews Visited',
  REFERRAL_VISITED = 'Referral Visited',
  REFERRAL_LINK_SHARED_INITIATED = 'Referral Link Shared Initiated',
  REFERRAL_VIDEO_REVIEW_INITIATED = 'Referral Video Review Initiated',
  POST_PREVIEW_VISITED = 'Post Preview Visited',
  WORKSPACE_ROLES_VISITED = 'Workspace Roles Visited',
  WORKSPACE_ROLE_CREATION_INITIATED = 'Workspace Role Creation Initiated',
  WORKSPACE_ROLE_CREATION_SUCCESSFUL = 'Workspace Role Creation Successful',
  WORKSPACE_INVITATION_SUBMITTED = 'Workspace Invitation Submitted',
  WORKSPACE_INVITATION_RECEIVED = 'Workspace Invitation Received',
  WORKSPACE_INVITATION_DELIVERY_FAILED = 'Workspace Invitation Delivery Failed',
  WORKSPACE_CREATION_SUCCESSFUL = 'Workspace Creation Successful',
  WORKSPACE_SETTINGS_VISITED = 'Workspace Settings Visited',
  WORKSPACE_PAGE_VIEWED = 'Workspace Page Viewed',
  EMAIL_VERIFICATION_SUBMITTED = 'Email Verification Submitted',
  PASSWORD_RESET_SUBMITTED = 'Password Reset Submitted',
  DRAFT_OPENED = 'Post Drafts Visited',
  POST_TEMPLATE_COMPLETED = 'Post Template Completed',
  POST_TEMPLATE_DELETED = 'Post Template Deleted',
  POST_DRAFT_SAVED = 'Post Draft Saved',
}
