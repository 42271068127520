import { mergeWithDefaultOptions } from '../defaultChartOptions'

export const barOptions: Highcharts.Options = mergeWithDefaultOptions({
  chart: {
    type: 'bar',
    inverted: false,
  },
  xAxis: {
    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  },
  series: [
    {
      name: 'Data',
      data: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60],
    },
  ] as Highcharts.SeriesOptionsType[],
})
