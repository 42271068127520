/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Text } from 'components'
import { useEffect, useState } from 'react'
import { IFBPage } from 'types/PostInterface'
import { AppStore } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  handleAddBrand: (brand: IFBPage) => void
  brand: IFBPage
  getPageImage: (id: string) => Promise<string>
}

export const BranItem = ({ handleAddBrand, brand, getPageImage }: Props) => {
  /* ----------------- STATE  */
  const [urlImg, setUrlImg] = useState<string>('')

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */
  const setImg = async () => {
    const url = await getPageImage(brand.id)
    setUrlImg(url)
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    setImg()
  }, [])

  return (
    <BranItemContainer onClick={() => handleAddBrand(brand)}>
      <BrandImg src={urlImg} />
      <NameBrand>{brand.name}</NameBrand>
    </BranItemContainer>
  )
}

const BranItemContainer = styled(Box)`
  padding: 8px 16px 8px 8px;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  border: 1px solid transparent;
  color: ${() => AppStore.theme.o.black};

  &:hover {
    border-color: ${() => AppStore.theme.o.grey};
    background-color: ${() => AppStore.theme.o.surface};
    cursor: pointer;
  }
`

const BrandImg = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`

const NameBrand = styled(Text)`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: ${() => AppStore.theme.o.black};
`
