import { TOptions, showBrandVoice } from 'components/CreatePost/TextComponents/TextPostContent'
import { T } from 'utils'
import { create } from 'zustand'

interface ITextAiGeneration {
  name: string // bo?
  text: string // testo scritto dall'utente
  like: boolean //  AIRTABLE (?)
  isNotLike: boolean //  AIRTABLE (?)
  saved: boolean //  AIRTABLE (?)
  modelId: string
  socialAcc: string // AIRTABLE (?)
  ArgumentType: string // AIRTABLE (?)
  toneofVoice: string // AIRTABLE (?)
  From: string // AIRTABLE (?) //text o link
  toLanguage: string // AIRTABLE (?)
  generatedTexts: any // da vedere poi cosa ritorna la call
  feedbackNotes?: string // AIRTABLE (?)
  feedbackId?: string // AIRTABLE (?)
  isBrandVoice?: boolean
}

interface IAiGenerationStore {
  generatedByTextResults: ITextAiGeneration[]
  setGeneratedByTextResults: (newGeneratedByTextResults: ITextAiGeneration[]) => void

  generatedByLinkResults: ITextAiGeneration[]
  setGeneratedByLinkResults: (newGeneratedByLinkResults: ITextAiGeneration[]) => void

  selectedOption: TOptions
  setSelectedOption: (newSelectedOption: TOptions) => void

  generationsQueue: number
  setGenerationsQueue: (newGenerationsQueue: number) => void
}

export const useAiGenerationStore = create<IAiGenerationStore>((set, get) => ({
  generatedByTextResults: [],
  setGeneratedByTextResults: (newGeneratedByTextResults) => set({ generatedByTextResults: newGeneratedByTextResults }),

  generatedByLinkResults: [],
  setGeneratedByLinkResults: (newGeneratedByLinkResults) => set({ generatedByLinkResults: newGeneratedByLinkResults }),

  // Per selezione brand voice / neutro
  selectedOption: (showBrandVoice ? T.textGenerator.brandVoice : T.textGenerator.neutral) as TOptions,
  setSelectedOption: (newSelectedOption: TOptions) => set({ selectedOption: newSelectedOption }),

  generationsQueue: 0,
  setGenerationsQueue: (newGenerationsQueue) => set({ generationsQueue: newGenerationsQueue }),
}))
