/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { useState } from 'react'
import { AppStore, T } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {}

export const ReelMaskThumbnail = ({}: Props) => {
  /* ----------------- STATE  */
  const [isMaskHovered, setIsMaskHovered] = useState<boolean>(false)

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <ReelMaskThumbnailContainer
      onMouseEnter={() => setIsMaskHovered(true)}
      onMouseLeave={() => setIsMaskHovered(false)}
    >
      <InstagramMask />
      {isMaskHovered && <InfoHover>{T.postPage.instagramExtra.previewDisplayInProfileFeed}</InfoHover>}
    </ReelMaskThumbnailContainer>
  )
}

const ReelMaskThumbnailContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 195px;
  height: 339px;
  border-radius: 14px;
  z-index: 1;
`

const InstagramMask = styled((props) => <Icons.reelMask {...props} />)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
`

const InfoHover = styled(Box)`
  position: absolute;
  top: 169.5px;
  left: 50%;
  width: 380px;
  text-align: center;
  transform: translate(-50%, -50%);
  z-index: 1;

  font-size: 14px;
  font-weight: 500;
  color: ${() => AppStore.theme.o.black};
  background-color: ${() => AppStore.theme.o.lightestGrey};
  padding: 8px 16px 8px 16px;
`
