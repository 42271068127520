/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { truncateText } from 'pages/ai-discover/commonFunction'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { IPostVariant } from 'types/PostInterface'
import { AppStore, T } from 'utils'
import { MediaContainerPost } from '../../GenericComponents/MediaContainerPost'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  postPreview?: IPostVariant
  mediaIndex?: number
  setMediaIndex?: (index: number) => void
}

export const ResultsPreviewInstagramReel = ({ postPreview, mediaIndex, setMediaIndex }: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */
  const { activePost } = useCreatePostStore((s) => ({
    activePost: s.activePost as IPostVariant,
  }))
  /* ----------------- VARIABLES  */
  const post = postPreview ?? activePost

  const urls = post?.data?.urls

  const metadataVisibleIndex = urls && urls.findIndex((url) => url.metaData.visible === true)

  const metadata = urls && metadataVisibleIndex !== -1 && urls[metadataVisibleIndex!]

  // Extra location
  const location = post?.data?.place?.name

  const collaborators = post?.data?.collaborators

  const profileName = truncateText(post?.info?.account?.name, 40)

  const profileCollaborators = collaborators
    ? collaborators.length > 1
      ? AppStore.loggedUser.language === 'en'
        ? `${collaborators.length} ${T.singleWord.others.toLowerCase()}`
        : `${T.singleWord.others.toLowerCase()} ${collaborators.length}`
      : `${collaborators[0]?.name}`
    : ''

  const profileTitle =
    collaborators && collaborators.length > 0
      ? `${profileName} ${T.singleWord.and} ${profileCollaborators}`
      : profileName

  // Extra sponsor
  const sponsor = post?.data?.brand && T.postPage.extraPaidSponsor

  // Minor info array container
  const minorInfoProfile = [location && location, sponsor && sponsor].filter((info) => info)

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <ResultsPreviewInstagramReelContainer>
      <ContainerReel>
        <ReelRightIcons />
        <ReelTranslateIcon />

        {postPreview && !metadata && post?.data?.medias?.length === 0 ? (
          <></>
        ) : (
          <MediaContainerPost
            width={'100%'}
            height={'100%'}
            arrowsPositions={16}
            postPreview={postPreview}
            mediaIndex={mediaIndex}
            setMediaIndex={setMediaIndex}
          />
        )}

        <AccountInfo>
          <AccountImg src={activePost?.info?.account?.picture} />

          <Box>
            <AccountName>{profileTitle}</AccountName>
            {minorInfoProfile && (
              <ProfileLocation>
                {minorInfoProfile.map((info, i) => (
                  <span>
                    {info} {i !== minorInfoProfile.length - 1 && <span>&#x2022;</span>}
                  </span>
                ))}
              </ProfileLocation>
            )}
          </Box>
        </AccountInfo>

        <PreviewText>
          {post?.data?.text.length === 0 && T.postPage.yourText}

          {post?.data?.text?.length > 20 ? (
            <>
              {post?.data?.text?.slice(0, 20)} <SeeMoreText>... {T.postPage.more}</SeeMoreText>
            </>
          ) : (
            post?.data?.text
          )}
        </PreviewText>
      </ContainerReel>
    </ResultsPreviewInstagramReelContainer>
  )
}

const ResultsPreviewInstagramReelContainer = styled(Box)`
  height: 614px;
  width: 347px;
  margin: 0 auto;
`

const ContainerReel = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
`

const ReelRightIcons = styled((props) => <Icons.reelRightIcons {...props} />)`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 20;
`

const ReelTranslateIcon = styled((props) => <Icons.reelTranslateIcon {...props} />)`
  position: absolute;
  left: 15.02px;
  bottom: 18.01px;
  z-index: 20;
`

const AccountInfo = styled(Box)`
  position: absolute;
  bottom: 76.5px;
  left: 15.02px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`

const AccountImg = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`

const AccountName = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  font-size: 12px;
  font-weight: 700;
`

const PreviewText = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  border-radius: 0;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  position: absolute;
  font-size: 14px;
  font-weight: 400;
  bottom: 47.25px;
  left: 15.02px;
`

const SeeMoreText = styled.span`
  margin-left: 4px;
  color: ${() => AppStore.theme.o.darkGrey};
`

const ProfileLocation = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  color: ${() => AppStore.theme.o.black};
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.26px;
`
