/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Tracker } from 'api/Tracker'
import { Box } from 'components'
import { ILabel } from 'components/CreatePost/PreviewComponents/LabelsComponents/LabelsContentComponents/Label'
import { useEffect, useState } from 'react'
import { IPostVariant } from 'types/PostInterface'
import { getMixpanelPostData, TRACKEREVENTS } from 'utils/eventsTracker'
import { IconsContainer } from './IconsContainer'
import { PostSocialInfo } from './PostSocialInfo'
import { PostStatusInfo } from './PostStatusInfo'
import { ContentSelectedIcons } from './PreviewContainers/ContentSelectedIcons'
import { PreviewPostContent } from './PreviewPostContent'
import { PreviewPostMedia } from './PreviewPostMedia'
import { TIconPost } from './PreviewPostModal'
import { ReportButton } from './ReportButton'
import { VisibleLabels } from './VisibleLabels'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  post: IPostVariant
}

export const PreviewContent = ({ post }: Props) => {
  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */
  const { publishDate } = post

  const { medias, status, text, description, statusMessage, labels, urls } = post?.data
  const { type, account, linkRef } = post?.info

  const metadataVisibleIndex = urls && urls.findIndex((url) => url.metaData.visible === true)

  const metadata = urls && metadataVisibleIndex !== -1 && urls[metadataVisibleIndex!]

  /* ----------------- STATE  */
  const [selectedIcon, setSelectedIcon] = useState<string>('')

  const [postLabels, setPostLabels] = useState<ILabel[]>(labels ?? [])

  /* ----------------- METHODS  */
  /**
   * Aggiunge il black layer (usato per oscurare il contenuto principale quando si opera con  un'icona a destra)
   */
  const addBlackLayer = () => {
    const mainContainer = document.getElementById('opacity_container_preview_post')
    const labelsContainer = document.getElementsByClassName('opacity_container_labels')
    const labelsContainerZIndex = document.getElementById('zIndex_container_labels')

    if (mainContainer && labelsContainer && labelsContainerZIndex) {
      mainContainer.style.zIndex = '0'
      labelsContainerZIndex.style.zIndex = '2'

      const labelsArray = Array.from(labelsContainer)
      labelsArray.forEach((label) => {
        ;(label as HTMLElement).style.zIndex = '2'
        ;(label as HTMLElement).style.backgroundColor = 'rgba(0, 0, 0, 0.3)'
      })
    }
  }

  /**
   * Rimuove il black layer (usato per oscurare il contenuto principale quando si opera con  un'icona a destra)
   */
  const removeBlackLayer = () => {
    const mainContainer = document.getElementById('opacity_container_preview_post')
    const labelsContainer = document.getElementsByClassName('opacity_container_labels')
    const labelsContainerZIndex = document.getElementById('zIndex_container_labels')

    if (mainContainer && labelsContainer && labelsContainerZIndex) {
      mainContainer.style.zIndex = '-1'
      labelsContainerZIndex.style.zIndex = ''

      const labelsArray = Array.from(labelsContainer)
      labelsArray.forEach((label) => {
        ;(label as HTMLElement).style.zIndex = '-1'
        ;(label as HTMLElement).style.backgroundColor = 'transparent'
      })
    }
  }

  /**
   * Resetta lo stato lo stato dell'icone selezionate e rimuove il black layer
   */
  const resetSelectedIcon = () => {
    setSelectedIcon('')
    removeBlackLayer()
  }

  /**
   * Restituisce il tipo di icona da visualizzare in base al tipo di post
   * @returns {TIconPost} Tipo di icona da visualizzare
   */
  const getIconType = (): TIconPost => {
    if (type === 'SHORT') return 'SHORT'

    if (type === 'STORY') return 'STORY'

    if (account.provider === 'instagram' && medias.length === 1 && medias[0].metadata.fileType === 'video')
      return 'REEL'

    if (metadata) return 'LINK'

    if (medias.length === 1 && medias[0].metadata.fileType === 'image') return 'STANDARD'

    if (medias.length === 1 && medias[0].metadata.fileType === 'video') return 'VIDEO'

    if (medias.length > 1) return 'CAROUSEL'

    return 'STANDARD'
  }

  const refreshPostLabels = (newLabels: ILabel[]) => {
    setPostLabels(newLabels)
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    Tracker.trackEvent(TRACKEREVENTS.POST_PREVIEW_VISITED, getMixpanelPostData(post))
  }, [])

  return (
    <>
      {/* Etichette visibili in alto a sinistra*/}
      <VisibleLabels labels={postLabels} addBlackLayer={addBlackLayer} setSelectedIcon={setSelectedIcon} />

      {/* Header che contiene le informazioni del post */}
      <HeaderPreviewPost>
        <PostSocialInfo socialAccountInfo={account} typeIcon={getIconType()} />
        <PostStatusInfo status={status!} date={publishDate!} post={post} />
      </HeaderPreviewPost>

      {/* Preview del post */}
      <ContentPreviewPost>
        <PreviewPostMedia postPreview={post} />
        <PreviewPostContent text={text} description={description} />
      </ContentPreviewPost>

      {/* Container contenente le azioni che si possono fare dalla selezione delle icone a destra */}
      <ContentSelectedIcons
        selectedIcon={selectedIcon}
        resetSelectedIcon={resetSelectedIcon}
        post={post}
        labels={postLabels}
        refreshPostLabels={refreshPostLabels}
      />

      {/* Icone in alto a destra per eseguire i comandi sul post */}
      <IconsContainer
        post={post}
        selectedIcon={selectedIcon}
        setSelectedIcon={setSelectedIcon}
        status={status!}
        addBlackLayer={addBlackLayer}
        removeBlackLayer={removeBlackLayer}
      />

      {/* Bottone di report se c'è un problema con il post */}
      {status === 'error' && <ReportButton message={statusMessage} />}

      {/* Black layer  */}
      <OpacityContainer id="opacity_container_preview_post" onClick={resetSelectedIcon} />
    </>
  )
}

const OpacityContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: -1;

  &:hover {
    background-color: rgba(0, 0, 0, 0.4);
    cursor: default;
  }
`

const HeaderPreviewPost = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 24px;
  flex: 1;
`

const ContentPreviewPost = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 24px;
  flex: 1;
`
