import { Box } from './Box'
import { Icons } from './Icons'
import styled from '@emotion/styled'
import { AppStore, T } from 'utils'
import React from 'react'
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { Text } from './Text'

export const AnimatedPlaceholderLogo = ({
  style,
  containerStyle,
  spinTime = '1.5s',
  progress,
  progressBarStyle,
}: {
  style?: React.CSSProperties
  containerStyle?: React.CSSProperties
  progressBarStyle?: React.CSSProperties
  spinTime?: string
  //progress from 0 to 100
  progress?: number
}) => {
  const Logo = () => (
    <AnimationRotatedLogo spinTime={spinTime} center>
      <Icons.logo style={{ ...{ height: '7vh', width: '7vh' }, ...style }} />
    </AnimationRotatedLogo>
  )

  return (
    <Box
      flex
      center
      style={{ backgroundColor: AppStore.theme.o.surface, padding: 100, borderRadius: 0, ...containerStyle }}
    >
      {progress !== undefined && (
        <Box hcenter style={{ ...{ height: '15vh', width: '15vh', position: 'relative' }, ...progressBarStyle }}>
          <CircularProgressbarWithChildren
            value={progress}
            strokeWidth={3}
            styles={buildStyles({
              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: 'butt',
              trailColor: AppStore.theme.o.grey,
              backgroundColor: AppStore.theme.o.green,
            })}
          >
            <Logo />
          </CircularProgressbarWithChildren>
          {progress >= 99 && (
            <Text style={{ textAlign: 'center', marginTop: 20, position: 'absolute', bottom: '-12vh', width: '30ch' }}>
              {T.loading.loadingCompleted1}
              <br />
              {T.loading.loadingCompleted2}
            </Text>
          )}
        </Box>
      )}
      {progress === undefined && <Logo />}
    </Box>
  )
}

const AnimationRotatedLogo = styled(Box)<{ spinTime: string }>`
  animation: spin ${({ spinTime }) => spinTime} linear infinite;
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`
