/* ******************* IMPORT ******************* */
import { useEffect, useRef, useState } from 'react'
import { AppStore, showInfo, T } from 'utils'
import { Box } from '../../../../components/Box'
import { Icons } from '../../../../components/Icons'
import styled from '@emotion/styled'
import { SearchPreview } from './SearchPreview'
import { createTopic, getFilteredTopics } from '../../commonFunction'
import DiscoverSearchFilters from './DiscoverSearchFilters'
import { ChangeLanguageButton } from 'pages/ai-discover/UI/ChangeLanguageButton'
import { ITopicDB } from 'pages/ai-discover/aiDiscoverInterface'
import ToggleFollowingTopicButton from 'pages/ai-discover/UI/ToggleFollowingTopicButton'
import { useDiscoverStore } from 'stores/DiscoverStore'
import { AiTagCard } from 'components/UI'

export function DiscoverSearchBar() {
  /* ____________________ ZUSTAND  ____________________ */
  // InputSearchBar viene settato quando viene selezionato un elemento, per mandare informazioni alle altre pagine
  // setSuggestedTopicList setta la lista dei suggeriti per avere poi i dati nella sidebar
  // selectedTopic / setSelectedTopic è il valore di un topic cercato/cliccato
  const {
    inputSearchbar,
    setSelectedTopic,
    setInputSearchbar,
    languageFilterSearch,
    selectedTopic,
    setSuggestedTopicList,
  } = useDiscoverStore((state) => ({
    inputSearchbar: state.inputSearchbar,
    setSelectedTopic: state.setSelectedTopic,
    setInputSearchbar: state.setInputSearchbar,
    languageFilterSearch: state.languageFilterSearch,
    selectedTopic: state.selectedTopic,
    setSuggestedTopicList: state.setSuggestedTopicList,
  }))

  /* ____________________ STATE ____________________ */
  // Controllo per vedere se si può cercare il contenuto tramite permesso "search_content"
  const [canSearchContent, setCanSearchContent] = useState<boolean>(false)

  // Valore input attuale
  const [inputValue, setInputValue] = useState<string>('')

  // Controllo per stile se la searchbar e filtro sono in focus
  const [isInputFocus, setIsInputFocus] = useState<boolean>(false)
  const [isInputFocusSelected, setIsInputFocusSelected] = useState<boolean>(false)

  // I topic ritornati come match di quello che sta scrivendo nell'input
  const [filteredTopics, setFilteredTopics] = useState<ITopicDB[]>([])

  // Timer per funzione debounce di 500ms
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

  /* ____________________ REF ____________________ */

  const inputSelectedRef = useRef<HTMLInputElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  /* ____________________ METHODS ____________________ */

  const onBack = () => {
    setSelectedTopic(null)
    setInputValue('')
    setInputSearchbar('')
  }

  const formatInput = (input) => {
    // Rimuovi gli spazi vuoti all'inizio e alla fine
    let formattedString = input.trim()

    // Se formattedString è sono una stringa vuota allora esce
    if (formattedString === '') return ''

    // Dividi la stringa in parole
    const words = formattedString.split(' ')

    // Capitalizza la prima lettera della prima parola
    if (words.length > 0) {
      words[0] = words[0][0].toUpperCase() + words[0].slice(1)
    }

    // Riunisci le words in una stringa con spazi tra di loro
    formattedString = words.join(' ')

    return formattedString
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (!canSearchContent) return showInfo(`${T.error.noPermissionTo} ${T.error.searchContent}`)

      const formattedInput = formatInput(inputValue)

      if (formattedInput === '') return

      setIsInputFocus(false)
      setIsInputFocusSelected(false)
      onSelect(formattedInput)
    }
  }

  // Funzione per controllare lo stato di inputfocus/inputfocusselected per questione di stile e funzionalità
  const handleOutsideClick = (e) => {
    const elementsToCheck = [
      document.getElementById('languageBoxInput'),
      document.getElementById('inputSearchBar'),
      document.getElementById('containerSearchBar'),
      document.getElementById('buttonChangeLanguage'),
    ]

    for (const element of elementsToCheck) {
      if (element && element.contains(e.target)) {
        setIsInputFocus(true)
        setIsInputFocusSelected(true)
        return
      }
    }

    setIsInputFocus(false)
    setIsInputFocusSelected(false)
  }

  const changeInputFocus = () => {
    setIsInputFocus(false)
    setIsInputFocusSelected(false)
  }

  /* ____________________ API CALL ____________________ */
  const onChange = (e) => {
    // Resetto valore di filteredTopics per non avere i valori precedenti
    setFilteredTopics([])

    setInputValue(e.target.value)

    // Resetta il timer se l'utente preme nuovamente entro 500 ms
    if (timer !== null) {
      clearTimeout(timer)
    }

    // Imposta un nuovo timer per avviare la funzione dopo 500 ms
    const newTimer = setTimeout(() => {
      getFilteredTopics(languageFilterSearch[0], formatInput(e.target.value), setFilteredTopics)
    }, 500)

    setTimer(newTimer)
  }

  const onSelect = async (selectedTitle: string) => {
    try {
      // Effettua la chiamata API con la stringa selezionata
      await createTopic(selectedTitle, languageFilterSearch[0], setSelectedTopic)

      // Imposta la stringa selezionata nell'input
      setInputSearchbar(selectedTitle)
    } catch (e) {}
  }

  // Quando c'è la stringa vuota in input e si focussa nella searchbar, richiama i più topic più rankati e li setta in setFilteredTopics
  const onFirstFocus = (string) => {
    getFilteredTopics(languageFilterSearch[0], string, setFilteredTopics)
  }

  /* ____________________ USEEFFECT ____________________ */

  // useEffect per controllo stili
  useEffect(() => {
    ;(async () => {
      const checkCanSearchContent = await AppStore.checkPermission('edit_post')
      setCanSearchContent(checkCanSearchContent)
    })()

    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  // useEffect per :
  // - Resettare alcuni stati
  // - Settare lista dei suggested
  useEffect(() => {
    setInputValue('')

    // <---- SET SUGGESTED ---->
    if (selectedTopic !== null && selectedTopic.title !== '') {
      ;(async () => {
        await getFilteredTopics(languageFilterSearch[0], selectedTopic.title, setSuggestedTopicList)
      })()
    }
  }, [selectedTopic])

  // Se si cambia la lingua di un topic selezionato e  aggiorna il valore dei suggeriti
  useEffect(() => {
    if (selectedTopic !== null) {
      onSelect(inputSearchbar!)
    }
    if (inputValue === '') {
      onFirstFocus('')
    }
    if (inputValue !== '') {
      onFirstFocus(inputValue)
    }
  }, [languageFilterSearch])

  return (
    <ContainerSearchbar>
      {/* Quando non c'è nessun topic selezionato o quando cerca una stringa vuota */}
      {(selectedTopic === null || selectedTopic.title === '') && (
        <div style={{ width: 373, height: 42, position: 'relative' }}>
          <DefaultSearchbar
            id="containerSearchBar"
            className={isInputFocus ? 'inputFocus' : ''}
            onClick={() => {
              inputRef.current?.focus()
              setIsInputFocus(true)
            }}
          >
            <SearchIcon>
              <Icons.search fill={AppStore.theme.o.primary} />
            </SearchIcon>

            <Input
              autoComplete="off"
              ref={inputRef}
              id="inputSearchBar"
              placeholder={T.aiDiscoverPage.searchbar.searchByTopic}
              onChange={(e) => onChange(e)}
              onKeyDown={(e) => handleKeyDown(e)}
              onFocus={(e) => {
                if (!canSearchContent) return showInfo(`${T.error.noPermissionTo} ${T.error.searchContent}`)

                if (e.target.value === '') {
                  onFirstFocus('')
                }
              }}
            />

            <ChangeLanguageButton fromInputSearchbar={true} />

            {/*  Suggeriti risultati di ricerca */}
            {canSearchContent && filteredTopics.length > 0 && isInputFocus && (
              <SearchPreview filteredTopics={filteredTopics} onSelect={onSelect} changeInputFocus={changeInputFocus} />
            )}
          </DefaultSearchbar>

          <AiTagCard aiTagComplete={false} aiTagDirection="right" right={'-25px'} />
        </div>
      )}

      {/* Quando c'è un topic selezionato */}
      {selectedTopic !== null && selectedTopic.title !== '' && (
        <>
          <SelectedSearchbarContainer>
            <ArrowBackIcon
              onClick={() => {
                onBack()
              }}
            >
              <Icons.backward />
            </ArrowBackIcon>

            <SelectedSearchbar
              id="containerSearchBar"
              className={isInputFocusSelected ? 'inputFocusSelected' : ''}
              onClick={() => {
                inputSelectedRef.current?.focus()
                setIsInputFocusSelected(true)
              }}
            >
              <SearchIcon>
                <Icons.search fill={AppStore.theme.o.primary} />
              </SearchIcon>

              <InputSelected
                autoComplete="off"
                ref={inputSelectedRef}
                id="inputSearchBar"
                placeholder={T.aiDiscoverPage.searchbar.searchByTopic}
                onChange={(e) => onChange(e)}
                onKeyDown={(e) => handleKeyDown(e)}
                onFocus={(e) => {
                  onFirstFocus(e)
                }}
                className={isInputFocusSelected ? 'inputFocusSelected__input' : ''}
              />

              <ChangeLanguageButton
                fromInputSearchbar={true}
                fromInputSearchbarSelected={true}
                isInputSelectedFocus={isInputFocusSelected}
              />

              {/* Suggeriti risultati di ricerca */}
              {filteredTopics.length > 0 && isInputFocusSelected && (
                <SearchPreview
                  filteredTopics={filteredTopics}
                  onSelect={onSelect}
                  changeInputFocus={changeInputFocus}
                />
              )}

              <AiTagCard aiTagComplete={false} aiTagDirection="right" right={'-25px'} />
            </SelectedSearchbar>
            <TextContainer className={isInputFocusSelected ? 'hide__content' : ''}>
              <TextLight>{T.aiDiscoverPage.searchbar.resultsFor}</TextLight>
              <TextHiglight>{selectedTopic?.title}</TextHiglight>
            </TextContainer>
            <ToggleFollowingTopicButton isInputFocusSelected={isInputFocusSelected} />
          </SelectedSearchbarContainer>

          <DiscoverSearchFilters />
        </>
      )}
    </ContainerSearchbar>
  )
}

const ContainerSearchbar = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 100;
  width: 100%;
`

const DefaultSearchbar = styled(Box)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;

  width: 373px;
  height: 42px;
  background-color: ${() => AppStore.theme.o.lightestGrey};

  display: flex;
  flex-direction: row;

  align-items: center;
  gap: 8px;
  padding: 8px 16px;

  &:hover {
    background-color: ${() => AppStore.theme.o.lightGrey};
  }

  &.inputFocus {
    background-color: ${() => AppStore.theme.o.lightGrey};
  }

  /* For colored border */
  z-index: 1;
  &::before {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    width: calc(100% + 2px);
    border-radius: 16px;
    background-image: linear-gradient(90deg, #00747c, #66d1ff);
    z-index: -1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 16px;
    background-color: ${() => AppStore.theme.o.background};
    z-index: -1;
  }
`

const SearchIcon = styled(Box)`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Input = styled.input`
  border: none;
  width: 100%;
  background-color: transparent;
  border-radius: 0px;
  color: ${() => AppStore.theme.o.black};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const TextLight = styled.p`
  color: ${() => AppStore.theme.o.darkGrey};

  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
`

const TextHiglight = styled.p`
  color: ${() => AppStore.theme.o.black};

  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.21px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  overflow-wrap: break-word;
  display: block;
  max-width: 400px;
`

// Selected style
const SelectedSearchbarContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`

const ArrowBackIcon = styled(Box)`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: ${() => AppStore.theme.o.darkGrey};
  }
`

const TextContainer = styled(Box)`
  visibility: visible;
  opacity: 1;

  transition: all 0ms ease-in-out;

  margin-left: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: auto;

  &.hide__content {
    width: 290px;
    opacity: 0;
    visibility: hidden;
  }
`

// Input selected style
const SelectedSearchbar = styled(Box)`
  position: absolute;
  top: 0;
  left: 50px;
  width: 56px;
  height: 42px;

  border-radius: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  z-index: 100;
  padding: 8px 16px;

  cursor: pointer;

  background-color: ${() => AppStore.theme.o.lightestGrey};

  &:hover {
    background-color: ${() => AppStore.theme.o.lightGrey};
  }

  &.inputFocusSelected {
    width: 373px;
    background-color: ${() => AppStore.theme.o.lightGrey};
  }

  /* For colored border */
  z-index: 1;
  &::before {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    width: calc(100% + 2px);
    border-radius: 16px;
    background-image: linear-gradient(90deg, #00747c, #66d1ff);
    z-index: -1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 16px;
    background-color: ${() => AppStore.theme.o.background};
    z-index: -1;
  }
`

const InputSelected = styled.input`
  visibility: hidden;
  opacity: 0;

  border: none;
  background-color: transparent;
  border-radius: 0px;
  color: ${() => AppStore.theme.o.black};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &.inputFocusSelected__input {
    visibility: visible;
    opacity: 1;
  }
`
