/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { AppStore, T } from 'utils'
import { useEffect, useState } from 'react'
import { generateByBrandVoice, generateByText } from 'utils/PostUtils/AiTextPost'
import { GenerationLength } from '../AiGeneric/GenerationLength'
import { DEFAULT_LANGUAGE } from 'utils/Constants/Constants'
import { GenerationObjective } from '../AiGeneric/GenerationObjective'
import { objectiveTreeText } from './configSettings'
import { GenerationTone } from '../AiGeneric/GenerationTone'
import { GenerateButton } from '../AiGeneric/GenerateButton'
import { LanguageFilter } from '../AiGeneric/LanguageFilter'
import { useParams } from 'react-router-dom'
import { TMode } from '../TextPostContent'
import { GenerationFeedbacks } from '../AiGeneric/GenerationFeedbacks'
import { GenerationStatus } from '../AiGeneric/GenerationStatus'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { MixpanelGeneratedCaption } from 'utils/eventsTracker/aiSection/aiSectionInterfaces'
import { isVisible } from 'utils/CheckVisibleElement'

/* ------------------- INTERFACES  */
interface Props {
  isBrandVoiceActive: boolean
}

export const AiTextSettings = ({ isBrandVoiceActive }: Props) => {
  /* ------------------- ZUSTAND  */
  const { activePost } = useCreatePostStore((s) => ({
    activePost: s.activePost,
  }))

  /* ----------------- STATE  */
  const [text, setText] = useState<string>('')
  const [selectedLength, setSelectedLength] = useState<number>(0)
  const [selectedObjective, setSelectedObjective] = useState<number>(-1)
  const [selectedTone, setSelectedTone] = useState<number>(-1)
  const [outputLanguage, setOutputLanguage] = useState(DEFAULT_LANGUAGE)
  const [disabled, setDisabled] = useState<boolean>(true)
  const [captionSettings, setCaptionSettings] = useState<MixpanelGeneratedCaption>({
    ai_caption_source_type: 'Keywords',
    ai_caption_source: text,
    ai_caption_lenght: '',
    ai_caption_goal: '',
    ai_caption_tone_of_voice: '',
    ai_caption_input_language: '',
    ai_caption_output_language: outputLanguage[1] ?? '',
    ai_caption_brand_voice: isBrandVoiceActive,
  })

  /* ----------------- VARIABLES  */
  const configSettings = objectiveTreeText[selectedLength].objective

  /* ----------------- ROUTER  */
  const { text: urlText } = useParams()

  /* ----------------- METHODS  */
  const isTunned = (): TMode => {
    if (activePost._id === 'GLOBAL') return 'Learning mode'
    if ('info' in activePost) {
      return activePost.info.account.tuned ? 'Tuning mode' : 'Learning mode'
    } else return 'Learning mode'
  }

  useEffect(() => {
    if (!isBrandVoiceActive)
      setDisabled(!(selectedLength !== -1 && selectedObjective !== -1 && selectedTone !== -1 && text.length > 0))
  }, [selectedLength, selectedObjective, selectedTone, text])

  /* ----------------- USEEFFECT  */

  const requestTextGeneration = () => {
    if (!isBrandVoiceActive)
      generateByText(selectedObjective, selectedTone, selectedLength, text, outputLanguage, captionSettings)
    else generateByBrandVoice(text, outputLanguage, captionSettings)
  }

  useEffect(() => {
    setCaptionSettings((prev) => ({
      ...prev,
      ai_caption_lenght: objectiveTreeText[selectedLength]?.id ?? '',
      ai_caption_source: text,
      ai_caption_goal: configSettings[selectedObjective]?.value ?? '',
      ai_caption_tone_of_voice: configSettings[selectedObjective]?.subSection[selectedTone] ?? '',
      ai_caption_output_language: outputLanguage[1],
      ai_caption_brand_voice: isBrandVoiceActive,
    }))
  }, [selectedLength, selectedObjective, selectedTone, text, outputLanguage, isBrandVoiceActive])

  const currentType = isBrandVoiceActive ? 'brandvoice' : 'neutro'

  return (
    <AiTextSettingsContainer>
      {/* Top Settings section  */}
      <TopSettings style={{ height: isBrandVoiceActive ? 'auto' : '94px' }}>
        {!isBrandVoiceActive && (
          <>
            <GenerationLength selectedLength={selectedLength} setSelectedLength={setSelectedLength} />

            <GenerationObjective
              selectedLength={selectedLength}
              selectedObjective={selectedObjective}
              setSelectedObjective={setSelectedObjective}
              configSettings={configSettings}
            />

            <GenerationTone
              selectedObjective={selectedObjective}
              selectedTone={selectedTone}
              setSelectedTone={setSelectedTone}
              configSettings={configSettings}
            />
          </>
        )}

        {isBrandVoiceActive && (
          <>
            <GenerationStatus generationMode={isTunned()} />
            {/* Feedback generations */}
            {/* Messo in isDevelopment finchè non si aggiungono le funzionalità di feedback */}
            {isVisible('createPost.aiText.generationFeedbacks') && <GenerationFeedbacks />}
          </>
        )}
      </TopSettings>

      {/* TextArea section */}
      <TextArea
        placeholder={T.postPage.insertTextToGenerateText}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />

      {/* Bottom Settings section  */}
      <BottomSettings>
        <GenerateBtnContainer>
          {/* GENERATE BTN */}
          <GenerateButton
            key={`generate_btn_${currentType}`}
            type={currentType}
            disabled={disabled}
            aiGenerateFunction={requestTextGeneration}
          />

          {/* QUESTION MARK */}
          {/* {isDevelopment && <TourGenerateText />} */}
        </GenerateBtnContainer>

        {/* LANGUAGE */}
        <LanguageFilter outputLanguage={outputLanguage} setOutputLanguage={setOutputLanguage} />
      </BottomSettings>
    </AiTextSettingsContainer>
  )
}

const AiTextSettingsContainer = styled(Box)`
  display: flex;
  border-radius: none;
  gap: 16px;
`

const TopSettings = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: none;
`

const BottomSettings = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const GenerateBtnContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-direction: row;
`

const TextArea = styled.textarea`
  padding: 12px 16px;
  border-radius: 14px;
  height: 101px;
  border-color: ${() => AppStore.theme.o.darkGrey};
  background-color: ${() => AppStore.theme.o.lightGrey};
  resize: none;

  &::placeholder {
    font-weight: 700;
  }
`
