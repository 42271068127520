import { InboxPage } from 'pages'

const InboxRouter = [
  {
    path: '/creators',
    element: <InboxPage />,
  },
]

export { InboxRouter }
