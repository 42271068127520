import React, { Component } from 'react'
import { List as AntdList, ListProps } from 'antd'
import { EmptyList } from './EmptyList'
import { Box } from './Box'

export class List extends Component<ListProps<any>> {
  render() {
    if ((this.props.dataSource ?? []).length === 0) {
      return (
        <Box height={'100%'} mh={50} center>
          <EmptyList />
        </Box>
      )
    }

    return <AntdList {...this.props} />
  }
}
