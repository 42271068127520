import React, { useState } from 'react'
import styled from '@emotion/styled'
import { AppStore, T } from 'utils'
import { Box } from 'components'
import { IPostVariant } from 'types/PostInterface'
import { updatePost } from 'utils/PostUtils/Post'
import { ExtraTitle } from './ExtraTitle'

interface PrivacyOption {
  value: string
  label: string
}

interface GenericPrivacyOptionsProps {
  post: IPostVariant
  privacyOptions: PrivacyOption[]
  onSelectOption: (value: string) => void
  disabledOptions?: string[]
  additionalStyles?: React.CSSProperties
  titleText?: string
}

export const GeneralPrivacyOptions: React.FC<GenericPrivacyOptionsProps> = ({
  post,
  privacyOptions,
  onSelectOption,
  disabledOptions = [],
  additionalStyles,
  titleText,
}) => {
  const provider = post.info.account.provider

  const defaultPrivacyActive =
    provider === 'tiktok' ? post.data.privacyLevel : provider === 'youtube' ? post.data.privacyStatus : null

  const privacy = provider === 'tiktok' ? 'privacyLevel' : 'privacyStatus'

  const [selectedOption, setSelectedOption] = useState(defaultPrivacyActive)

  const handleOptionChange = (value) => {
    setSelectedOption(value) // Aggiorna l'opzione selezionata
    onSelectOption(value) // Chiama la funzione passata come prop
    updatePost(post.variantId, { [privacy]: value }) // Aggiorna il post
  }

  return (
    <PrivacyOptionsContainer className="PrivacyOptionsContainer" style={additionalStyles}>
      {titleText && <ExtraTitle text={titleText} />}
      <OptionsRow className="OptionsRow" row vcenter gap={24} justify="space-between">
        {privacyOptions.map((option) => (
          <RadioButton
            key={option.value}
            className={selectedOption === option.value ? 'selected' : ''}
            onClick={() => {
              if (!disabledOptions.includes(option.value)) handleOptionChange(option.value)
            }}
            disabled={disabledOptions.includes(option.value)}
          >
            {option.label}

            {disabledOptions.includes(option.value) && provider === 'tiktok' && (
              <div className="tooltip">{T.postPage.tiktokExtra.brandedContentCantBePrivate}</div>
            )}
          </RadioButton>
        ))}
      </OptionsRow>
    </PrivacyOptionsContainer>
  )
}
const PrivacyOptionsContainer = styled(Box)`
  width: 541px;
  gap: 12px;
`
const OptionsRow = styled(Box)`
  display: flex;
  justify-content: space-between;
`

const RadioButton = styled.label<{ disabled: boolean }>`
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  flex: 1 1 100%;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  padding: 8px 16px;

  border-radius: 14px;
  opacity: 1;

  border: 1px solid ${() => AppStore.theme.o.grey};
  background-color: ${() => AppStore.theme.o.lightestGrey};

  color: ${() => AppStore.theme.o.darkGrey};
  font-size: 14px;
  text-align: center;
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  transition: 300ms ease-in-out;
  position: relative;

  &:hover {
    .tooltip {
      opacity: 1;
      visibility: visible;
      color: ${() => AppStore.theme.o.white};
      z-index: 9;
    }
  }

  &.selected {
    opacity: 1;
    color: ${() => AppStore.theme.o.background};
    background-color: ${() => AppStore.theme.o.green};
    border: none;
  }

  .tooltip {
    cursor: default;
    position: absolute;
    top: 120%;
    width: 200px;
    line-height: 20px;
    font-size: 13px;
    background-color: ${() => AppStore.theme.o.black}80;
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
`
export default GeneralPrivacyOptions
