import Socialaccounts from 'api/Socialaccounts'
import { Box } from 'components/Box'
import { SocialButtonWithImage } from 'components/SocialButtonWithImage'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { AppStore, T } from 'utils'
import BestTimeToPost from './BestTimeToPost'
import { Button } from 'components/Button'
import { publish, resetPage, updatePost } from 'utils/PostUtils/Post'
import styled from '@emotion/styled'
import { Text } from 'components/Text'

// MUI
import MuiDateTimePicker from '../../MuiDatePicker'
import { IPostVariant } from 'types/PostInterface'
import { AiTagCard } from 'components/UI'
import { truncateText } from 'pages/ai-discover/commonFunction'

/* ------------------- TYPES  */
interface OnlineFollowersData {
  values: Record<string, number> | undefined
  end_time: string
}

// # refactor troppi any
interface State {
  selectedDate: any
  instagramFollowersForAccount: {
    accountId?: string
    dayValues?: undefined | OnlineFollowersData[] | any[]
    newDate?: any | undefined
    postDate?: any | undefined
  }[]
}
/* ------------------- INTERFACES  */

export const PostPlanning = ({}) => {
  /* ----------------- STATE  */
  const [instagramFollowersForAccount, setInstagramFollowersForAccount] = useState<
    State['instagramFollowersForAccount']
  >([])
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<string>('')

  /* ----------------- ZUSTAND  */
  const { posts } = useCreatePostStore((s) => ({
    posts: s.posts,
  }))

  /* ----------------- VARIABLES  */
  const instagramAccounts = posts.filter((p) => !!p.info.account && p.info.account.provider === 'instagram')
  const allNonInstagramAccounts = posts.filter((p) => !!p.info.account && p.info.account.provider !== 'instagram')

  /* ----------------- METHODS  */
  const isExternalElementClicked = (currentElement, elementToCheck): boolean => {
    return !currentElement?.contains(elementToCheck)
  }

  const handleOutsideClick = (e) => {
    const elToCheck = e.target as Element
    posts.map((p) => {
      const muiDatePicker = document.getElementById(`mui-date-picker-${p.info.account.name}`)
      if (isExternalElementClicked(muiDatePicker, elToCheck)) setIsDatePickerOpen('')
      // if (elToCheck.classList.contains('chip-notify')) return
    })
    // const notificationRef = notifyRef?.current

    // if (isExternalElementClicked(notificationRef, elToCheck)) setIsOpen(false)
  }

  const filterDays = (id) => {
    const post = instagramFollowersForAccount.filter((s: any) => s.accountId === id)[0]
    if (post === undefined) return undefined
    return post.dayValues
  }

  const changeDate = (postId, newDate) => {
    //ricerca post
    const statePrev = instagramFollowersForAccount

    statePrev.forEach((p: any) => {
      if (p.accountId === postId) {
        p.newDate = newDate
      }
    })

    setInstagramFollowersForAccount(statePrev)

    updatePost(postId, { date: new Date(newDate.toISOString()) })

    //? old post logic?
    // const index = posts.findIndex((p) => p.info.account.account_id === postId)
    // posts[index].data.date = moment(newDate).toDate()
    // setPosts(posts)
  }

  const openCloseMui = (value = '') => {
    if (value === isDatePickerOpen) setIsDatePickerOpen('')
    else setIsDatePickerOpen(value)
  }

  const handleClickPlanning = async () => {
    try {
      await publish('schedule')
      AppStore.closeAllModal()
    } catch (error) {
      console.error(error)
    }
  }

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    posts.map(async (post, i) => {
      const account = post.info.account

      if (account?.provider === undefined) return

      const workspaceId = AppStore.workspace._id ?? ''
      const accountId = account.account_id ?? ''

      let response: any

      if (account?.provider === 'instagram') {
        const timezoneOffset = parseInt(AppStore?.workspace?.timezone?.value.slice(3, 5) ?? '00')
        const isDST = moment.tz(AppStore.workspace.timezone?.name ?? 'Europe/Rome').isDST()
        response = await Socialaccounts.getOnlineFollower(accountId, workspaceId, timezoneOffset + (isDST ? 1 : 0))
      }

      setInstagramFollowersForAccount((prev) => {
        return [...prev, { accountId: account.account_id, dayValues: response?.data, newDate: post.data.date }]
      })
    })
  }, [])

  useEffect(() => {
    if (!isDatePickerOpen) window.addEventListener('click', handleOutsideClick)
    return () => window.removeEventListener('click', handleOutsideClick)
  })

  /* ----------------- COMPONENTS  */
  const bestTimeToPostCard = (post: IPostVariant) => {
    return (
      <SocialaccountRow vcenter row gap={16} style={{ justifyContent: 'space-between', zIndex: 2 }}>
        <Box vcenter row gap={16}>
          <SocialButtonWithImage socialAccount={post.info.account!} />
          <CustomNameText>{truncateText(post.info.account?.name, 25)}</CustomNameText>
        </Box>

        <Text color={AppStore.theme.o.darkGrey}>
          {/* // MUI DATE PICKER */}
          <MuiDateTimePicker post={post} handleOpenClose={openCloseMui} isOpen={isDatePickerOpen} />
        </Text>
      </SocialaccountRow>
    )
  }

  const instagramAccountElements = instagramAccounts.map((post) => (
    <Box key={post.info.account.account_id} gap={16}>
      {bestTimeToPostCard(post)}
      <BestTimeToPost post={post} dayValues={filterDays(post.info.account.account_id)} changeDate={changeDate} />
    </Box>
  ))

  const allNonInstagramAccountsElements = allNonInstagramAccounts.map((post) => (
    <Box key={post.info.account.account_id}>{bestTimeToPostCard(post)}</Box>
  ))

  return (
    <AbsoluteCenteredContainer animation="fade" id="planning_post_container">
      <GridAccounts>
        {instagramAccountElements.length > 0 && (
          <InstagramPostContainer>
            <AiTagCard aiTagComplete aiTagDirection="top" />
            {instagramAccountElements}
          </InstagramPostContainer>
        )}

        <NonInstagramPostContainer>{allNonInstagramAccountsElements}</NonInstagramPostContainer>
      </GridAccounts>

      <Box center mt={24}>
        <Button size="small" variant="secondary" ph={50} onClick={handleClickPlanning}>
          {T.postPage.plan}
        </Button>
      </Box>
    </AbsoluteCenteredContainer>
  )
}

const AbsoluteCenteredContainer = styled(Box)`
  width: 100%;
  padding: 32px 48px 24px 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`

//Grid with 2 columns  with header and rows
const GridAccounts = styled(Box)`
  width: 100%;
  display: grid;
`

const CustomNameText = styled.p`
  font-weight: bold;
  font-size: 16px;
  color: ${() => AppStore.theme.o.black};
  overflow-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const SocialaccountRow = styled(Box)`
  .date-picker-post-planning {
    transition: 0.3s ease-in-out;
    &:hover {
      border: 0.5px solid transparent !important;
    }

    .ant-picker-clear {
      background: transparent !important;
    }
  }
`

const InstagramPostContainer = styled(Box)`
  padding: 48px;
  border-radius: 16px;
  gap: 40px;
  background-color: ${() => AppStore.theme.o.surface};
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    width: calc(100% + 2px);
    border-radius: 16px;
    background-image: linear-gradient(90deg, #00747c, #66d1ff);
    z-index: -1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 16px;
    background-color: ${() => AppStore.theme.o.surface};
    z-index: 0;
  }
`

const NonInstagramPostContainer = styled(Box)`
  padding: 48px;
  gap: 40px;
`
