import { SocialAccount } from 'types'
import { api, responseErrorCheck } from './api'

// <-------------------------INFERFACE------------------------->

export default class User {
  static endpoint = 'users'

  static async addToken(tokenId: string) {
    const data = {
      tokenId,
    }

    return api.post(`${this.endpoint}/firebaseToken`, data)
  }

  static async enableUserNotifications(phoneNumber: string) {
    return api.post(`${this.endpoint}/notifications/enabling`, { phoneNumber })
  }

  // Retrieve all the social account that the user is able to see in all his workspaces
  static async getAllUserSocials(): Promise<SocialAccount[]> {
    return api.get<SocialAccount[]>(`${this.endpoint}/socialaccounts`).then(responseErrorCheck)
  }

  static async updateUserProfileImage(fileUrl: File) {
    return api.post(`${this.endpoint}/updateProfileImage`, {
      profileImage: fileUrl,
    })
  }
}
