/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import AssetsCreator from 'api/assetsCreator'
import Utils from 'api/Utils'
import { Box, Icons, Title } from 'components'
import { EmptyState } from 'components/UI'
import SkeletonCard from 'pages/ai-discover/UI/SkeletonCard'
import { useEffect, useState } from 'react'
import { CrelloResponse } from 'types'
import { AppStore, T } from 'utils'
import { CrelloProject } from './CrelloProject'
import { ITemplate } from './TemplateSection'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  onCrelloSave?: (crelloResponse: CrelloResponse) => void
}

export const MyProjectSection = ({ onCrelloSave }: Props) => {
  /* ----------------- STATE  */
  const [projects, setProjects] = useState<ITemplate[]>([])
  const [isLoading, setIsLoading] = useState(true)

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */
  const fetchProjects = async () => {
    setIsLoading(true)
    try {
      const res = await AssetsCreator.getAllProjectsForUser(AppStore.loggedUser._id ?? '')

      setProjects(res.associatedProjects ?? [])
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    fetchProjects()
  }, [])

  return (
    <MyProjectSectionContainer>
      {/* Loading */}
      {isLoading && (
        <ContainerMyProject>
          <SkeletonCard type={'crelloTemplates'} num={6} key={Utils.generateRandomUIDKey()} />
        </ContainerMyProject>
      )}

      {/* Projects */}
      {!isLoading && projects.length > 0 && (
        <>
          <Title style={{ textAlign: 'center', width: '100%' }}>{T.mediaPage.selectAProject}</Title>

          <ContainerMyProject>
            {projects.map((template: any) => (
              <CrelloProject isProject={true} key={template._id} template={template} onCrelloSave={onCrelloSave} />
            ))}
          </ContainerMyProject>
        </>
      )}

      {/* Empty state */}
      {!isLoading && projects.length === 0 && (
        <EmptyState
          noPaddingTop
          text={T.error.noProjects}
          icon={<Icons.sadFace fill={AppStore.theme.o.black} width={72} height={72} />}
          styles={{ width: '403px', height: '257px' }}
        />
      )}
    </MyProjectSectionContainer>
  )
}

const MyProjectSectionContainer = styled(Box)`
  gap: 16px;
  height: 100%;
`

const ContainerMyProject = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 'auto';
  row-gap: 31px;
  column-gap: 8px;
  overflow-y: scroll;
  height: 90%;
  margin-left: 8px;
`
