import { useState } from 'react'

export const ImageWithFallback = ({ src, fallbackSrc, ...props }) => {
  const [imgSrc, setImgSrc] = useState(src)

  const handleError = () => {
    if (imgSrc !== fallbackSrc) {
      setImgSrc(fallbackSrc)
    }
  }

  return <img src={imgSrc}  onError={handleError} {...props} />
}
