import styled from '@emotion/styled'
import AiDiscover from 'api/AiDiscover'
import Workspaces from 'api/Workspaces'
import { Box, Page } from 'components'
import { useEffect } from 'react'
import { defaultLanguage, useDiscoverStore } from 'stores/DiscoverStore'
import { AppStore, T } from 'utils'
import DiscoverAside from './DiscoverAside/DiscoverAside'
import DiscoverContent from './DiscoverContent/DiscoverContent'
import { DiscoverMenu } from './DiscoverMenu/DiscoverMenu'
import { IAiDiscover } from './aiDiscoverInterface'
import { getBoardsList, getFeedTrendArticles } from './commonFunction'

const AiDiscoverPage = () => {
  /* ____________________ VARIABLES  ____________________ */
  const user = AppStore.loggedUser

  /* ____________________ ZUSTAND  ____________________ */
  const { firstMount } = useDiscoverStore((s) => ({
    firstMount: s.firstMount,
  }))

  const { setFirstMount, onForceUpdate } = useDiscoverStore()

  /* ___________________ API CALL  ____________________ */

  /**
   * se l'utente esiste in aiDiscover allora lo setto in AppStore.discoverUser
   *
   * se l'utente non esiste lo credo e lo setto in AppStore.discoverUser
   */
  const checkUserExist = async () => {
    const data: IAiDiscover = {
      userId: user._id,
      workspaceId: AppStore.workspace._id ?? '',
    }

    // check discoverUser exist
    try {
      const response: IAiDiscover = await Workspaces.getAiDiscover()

      // if discoverUser not exist create it
      if (!response) {
        // create discoverUser
        const res: IAiDiscover = await AiDiscover.create(data)
        AppStore.discoverUser = res

        return false
      }

      if (response) {
        AppStore.discoverUser = response
        return true
      }
    } catch (e: any) {
      if (e.message === 'AiDiscover data not found') {
        const res = await AiDiscover.create(data)
        AppStore.discoverUser = res
        if (res) return true
      }
    }
  }

  const firstLoad = async () => {
    const canSeeDiscover = await checkUserExist()

    if (!canSeeDiscover) return

    // Per articoli carousel (trend in generale)
    if (!AppStore.discoverTrendArticleList) getFeedTrendArticles(onForceUpdate, defaultLanguage[0])

    getBoardsList(onForceUpdate)
  }

  /* ____________________ USEEFFECT  ____________________ */
  useEffect(() => {
    if (firstMount) {
      firstLoad()
      setFirstMount(false)
    }
  }, [])

  return (
    <Page
      title={T.AiDiscoverPage.titlePage}
      checkPermission="discover"
      style={{ flex: 1, maxHeight: '100%', height: '100%', overflow: 'hidden' }}
    >
      <PageContainer id="ai-discover-container" className="page-container">
        <DiscoverAside />
        <DiscoverContent />
        <DiscoverMenu />
      </PageContainer>
    </Page>
  )
}

export default AiDiscoverPage

const PageContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 40px;
  position: relative;
  flex: 1;
  max-height: 100%;
  overflow: hidden;
`
