/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Button } from 'components'
import { openLanguageSelectModal } from 'components/Modal/ModalManager'
import { AppStore, T } from 'utils'

/* ------------------- INTERFACES  */
interface Props {
  outputLanguage: any
  setOutputLanguage: any
}

export const LanguageFilter = ({ outputLanguage, setOutputLanguage }: Props) => {
  return (
    <LanguageFilterContainer>
      {T.postPage.generateIn}{' '}
      <Button
        onClick={() => openLanguageSelectModal(outputLanguage, setOutputLanguage)}
        width={70}
        height={34}
        center
        variant="neutral"
        style={{ minHeight: 36, fontWeight: 700, fontSize: 16 }}
      >
        {outputLanguage[0]}
      </Button>
    </LanguageFilterContainer>
  )
}

const LanguageFilterContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-direction: row;
  color: ${() => AppStore.theme.o.darkGrey};
  font-weight: 700;
`
