import AstersBaseResource from './AstersBaseResource'
import { api } from './api'

export default class RemoteConfig extends AstersBaseResource {
  static endpoint = '/remoteConfig'

  static list() {
    return api.get<any>(`${this.endpoint}/`)
  }

  static verify(key: string, userEmail: string) {
    return api.post<any>(`${this.endpoint}/verify`, { configIdentifier: key, email: userEmail })
  }

  static async verifyAll(userEmail: string) {
    const res = await api.post<any>(`${this.endpoint}/verifyAll`, { email: userEmail })
    return res.data ?? []
  }
}
