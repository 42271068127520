import styled from '@emotion/styled'
import { AppStore } from 'utils'

export interface TextProps {
  style?: React.CSSProperties
  classes?: string
  fontSize?: any
  color?: any
  weight?: any
  onClick?: () => void
  unselectabled?: boolean
  truncate?: boolean
}

export const Text: React.FC<TextProps> = ({
  children,
  style,
  classes,
  color,
  fontSize = 16,
  weight = 'normal',

  onClick,
  ...rest
}) => {
  //calculate fontSize proportionally to the width of the screen
  const fontSizeToUse = parseFloat(fontSize) * (window.innerWidth / 1080)
  return (
    <Paragraph
      className={classes ?? ''}
      onClick={onClick}
      style={{
        ...{
          fontSize: fontSize,
          fontWeight: weight === undefined ? 'normal' : weight,
          color: color ?? AppStore.theme.o.black,
          // whiteSpace: 'pre-line',
        },
        ...(style ?? {}),
      }}
      {...rest}
    >
      {children}
    </Paragraph>
  )
}

const Paragraph = styled.p<{ onClick?: () => void; unselectabled?: boolean; truncate?: boolean }>`
  width: fit-content;
  word-break: break-word;
  cursor: ${({ onClick }) => (!!onClick ? 'pointer' : 'unset')};
  ${({ unselectabled }) =>
    unselectabled ? '-webkit-user-select: none;-moz-user-select: none; -ms-user-select: none; user-select: none;' : ''}
  ${({ truncate }) =>
    truncate === true &&
    '   width: 140px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis; align-text: center;'}
`
