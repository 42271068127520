/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { ContentContainerStructure, ContentFooterStructure, ContentStructure } from 'components/UI'
import { useDraftStore } from 'stores/DraftStore'
import { DraftFooter } from './DraftFooter'
import { DraftCardsContent } from './DraftCardsContent'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {}

export const DraftContent = ({}: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */
  const { selectedPostsId } = useDraftStore((s) => ({
    selectedPostsId: s.selectedPostsId,
  }))

  /* ----------------- VARIABLES  */
  const showContentFooter: boolean = selectedPostsId.length > 0 ? true : false

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <ContentStructure
      width={1286}
      header={<div />}
      content={
        <ContentContainerStructure
          content={<DraftCardsContent />}
          noShadow
          bgColor="transparent"
          padding="0"
          borderRadius="0"
        />
      }
      footer={<ContentFooterStructure showFooter={showContentFooter} content={<DraftFooter />} />}
    />
  )
}
