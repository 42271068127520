/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { TSubsection } from 'types/CreatePostInferfaces'
import { BasicHeader } from './BasicComponents/BasicHeader'
import { LabelsHeader } from './LabelsComponents/LabelsHeader'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  subsection: TSubsection
}

export const PreviewHeader = ({ subsection }: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  /* ----------------- ENUM  */
  const content = {
    basic: <BasicHeader />,
    drafts: false,
    templates: false,
    labels: <LabelsHeader />,
  }

  return content[subsection]
}
