import React, { Component } from 'react'
import OnClickDemo from 'assets/demo_click.json'
import Lottie from 'react-lottie'

const OnClickDemoAnimation = {
  loop: true,
  autoplay: false,
  animationData: OnClickDemo,
  zIndex: -9999,
}

export class OnClick extends Component {
  render() {
    return <Lottie animation={'fade'} isClickToPauseDisabled options={OnClickDemoAnimation} />
  }
}
