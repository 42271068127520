import { Modal as AntdModal, ModalProps } from 'antd'
import './index.css'

interface Props extends ModalProps {
  visible: boolean
  children: React.ReactNode
  centered?: boolean
  footer?: React.ReactNode
  closeIcon?: React.ReactNode
}

export function Modal({ children, footer, ...props }: Props) {
  return (
    <AntdModal footer={footer ?? null} {...props}>
      {children}
    </AntdModal>
  )
}
