/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { T, showInfo } from 'utils'
import { GenerationsRemaining } from './GenerationsRemaining'
import { Box, Button } from 'components'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { OWNER_COPIES_AVAILABLE, OWNER_COPIES_INFINITE, OWNER_COPIES_USED } from 'utils/Constants/LimitUserConstants'
import { useAiGenerationStore } from 'stores/AiGenerationStore'

/* ------------------- INTERFACES  */
interface Props {
  aiGenerateFunction: any
  disabled: boolean
  type: 'brandvoice' | 'neutro' | 'link'
}

export const GenerateButton = ({ type, aiGenerateFunction, disabled }: Props) => {
  /* ----------------- ZUSTAND  */
  const { currentGenerationState } = useCreatePostStore((s) => ({ currentGenerationState: s.currentGenerationState }))

  const { generationsQueue } = useAiGenerationStore((s) => ({ generationsQueue: s.generationsQueue }))
  const { setGenerationsQueue } = useAiGenerationStore.getState()

  /* ----------------- METHODS  */
  const handleOnClickGenerate = () => {
    //? Controlla che l'utente non abbia superato il limite di generazioni
    if (!OWNER_COPIES_INFINITE) {
      if (OWNER_COPIES_USED + generationsQueue >= OWNER_COPIES_AVAILABLE) {
        return showInfo(T.limitUser.max_reached_generation)
      }
    }

    setGenerationsQueue(generationsQueue + 1)
    return aiGenerateFunction()
  }

  return (
    <GenerationContainer>
      <GenerateButtonContainer
        loading={currentGenerationState[type]}
        variant={disabled ? 'neutral' : 'primary'}
        size="small"
        onClick={handleOnClickGenerate}
      >
        {T.postPage.generate}
      </GenerateButtonContainer>
      <GenerationsRemaining />
    </GenerationContainer>
  )
}

const GenerationContainer = styled(Box)``

const GenerateButtonContainer = styled(Button)`
  width: 212px;
`
