import { MixpanelSocialProfile } from './socialProfileInterfaces'

export function getSocialProfileData(data: MixpanelSocialProfile): MixpanelSocialProfile {
  return {
    social_channel: data.social_channel,
    social_channel_type: data.social_channel_type,
    social_profile_name: data.social_profile_name,
    social_profile_url: data.social_profile_url,
    workspace_name: data.workspace_name,
  }
}
