/* ******************* IMPORT ******************* */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { AppStore } from 'utils'

/* ******************* TYPE ******************* */

type ClickedFrom = 'article'

/* ******************* INTERFACE ******************* */

interface Props {
  defaultValue?: string[] | []
  setFilter?: (data?: any) => void

  onlyInvokeFunction?: any
  clickedFrom?: ClickedFrom
}

const ClearFiltersButton = ({ defaultValue, setFilter, clickedFrom, onlyInvokeFunction }: Props) => {
  /* ____________________ METHODS ____________________ */

  const handleResetFilters = (e) => {
    e.stopPropagation()

    if (clickedFrom === 'article') {
      onlyInvokeFunction()
      return
    }

    setFilter && setFilter(defaultValue)
  }

  return (
    <ClearFiltersButtonContainer removeHover onClick={(e) => handleResetFilters(e)}>
      <Icons.close_icon style={{ position: 'relative' }} />
    </ClearFiltersButtonContainer>
  )
}

export default ClearFiltersButton

const ClearFiltersButtonContainer = styled(Box)`
  transition: all 0.3s ease-in-out;

  position: absolute;
  top: -4px;
  right: -4px;
  width: 16px;
  height: 16px;

  background-color: ${() => `${AppStore.theme.o.black}`};

  border: 1px solid ${() => `${AppStore.theme.o.surface}`};
  border-radius: 16px;

  align-items: center;
  justify-content: center;

  &:hover {
    scale: 1.1;
    /* background-color: ${() => `${AppStore.theme.o.red}`}; */
  }

  &:active {
    scale: 0.9;
  }

  svg {
    width: 6px;
    height: 6px;
    fill: ${() => `${AppStore.theme.o.surface}`};
  }
`
