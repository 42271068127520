/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, SocialIcon } from 'components'
import { SocialIconsProvider } from 'components/SocialIcon'
import { truncateText } from 'pages/ai-discover/commonFunction'
import { IMetric } from 'types/AnalyticsInferfaces'
import { AppStore } from 'utils'

/* ------------------- INTERFACES  */
interface Props {
  content: IMetric
  isSelected: boolean
  action: () => void
}

export const MetricCard = ({ content, isSelected, action }: Props) => {
  /* ----------------- VARIABLES  */
  const { name, description, provider } = content
  const canShowTooltip = description?.length > 85

  return (
    <MetricCardContainer
      className={`metric_card_container ${isSelected ? 'selected' : 'not_selected'} `}
      onClick={action}
      title={canShowTooltip ? description : ''}
    >
      <MetricHeader className="metric_header">
        <SocialIcon social={provider as SocialIconsProvider} active={true} style={{ width: 24, height: 24 }} />

        <MetricName className="metric_name">{name}</MetricName>
      </MetricHeader>
      <MetricDescription className="metric_description">{description}</MetricDescription>
    </MetricCardContainer>
  )
}

const MetricCardContainer = styled(Box)`
  &.metric_card_container {
    display: flex;
    padding: 24px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 24px;
    cursor: pointer;
    height: 120px;

    &.selected {
      border: 1px solid ${() => AppStore.theme.o.green};
      background: ${() => AppStore.theme.o.lightGreen};

      &:hover {
        background: ${() => AppStore.theme.o.lightGreen};
      }
    }

    &.not_selected {
      border: 1px solid ${() => AppStore.theme.o.grey};
      background: ${() => AppStore.theme.o.surface};
    }

    &:hover {
      background: ${() => AppStore.theme.o.lightestGrey};
    }
  }
`

const MetricHeader = styled(Box)`
  &.metric_header {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 8px;
    position: relative;
    left: -6px;
  }
`

const MetricName = styled(Box)`
  &.metric_name {
    color: ${() => AppStore.theme.o.black};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.21px;
    max-width: 275px;
  }
`

const MetricDescription = styled(Box)`
  &.metric_description {
    color: ${() => AppStore.theme.o.darkGrey};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.1;
    letter-spacing: 0.1px;
    border-radius: 0;
    /*   per generare i puntini senza Javascript */
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* number of lines to show */
  }
`
