/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { AppStore, T } from 'utils'

/* ------------------- TYPES  */
export type TTrend = 'positive' | 'negative'

/* ------------------- INTERFACES  */

interface Props {
  trend: TTrend
  value: string
  text?: boolean
}

export const CompareComparison = ({ trend, value, text }: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <CompareComparisonContainer>
      <ValueContainer
        style={{
          color: trend === 'positive' ? AppStore.theme.o.green : AppStore.theme.o.red,
        }}
      >
        <TrendArrow
          style={{
            transform: `rotate(${trend === 'positive' ? '90deg' : '270deg'})`,
            marginBottom: '2px',
            marginRight: '4px',
          }}
        >
          <Icons.arrowLeft fill={trend === 'positive' ? AppStore.theme.o.green : AppStore.theme.o.red} />
        </TrendArrow>
        {value}
      </ValueContainer>

      {text && <SubLabel>{T.analytics.vsPreviousPeriod}</SubLabel>}
    </CompareComparisonContainer>
  )
}

const CompareComparisonContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 27px;
`

const ValueContainer = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 16px;
  line-height: normal;
`

const TrendArrow = styled(Box)``

const SubLabel = styled(Box)`
  color: ${() => AppStore.theme.o.darkGrey};
  font-size: 10px;

  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1px;
`
