/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { FacebookExtrasContainer } from './FacebookExtras/FacebookExtrasContainer'
import { IPostVariant } from 'types/PostInterface'
import { InstagramExtrasContainer } from './InstagramExtras/InstagramExtrasContainer'
import { LinkedinExtrasContainer } from './LinkedinExtras/LinkedinExtrasContainer'
import { TiktokExtrasContainer } from './TiktokExtras/TiktokExtrasContainer'
import { YoutubeExtrasContainer } from './YoutubeExtras/YoutubeExtrasContainer'
import { XExtrasContainer } from './XExtras/XExtrasContainer'
import { Divider } from 'components/UI'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const ExtraContent = () => {
  /* ----------------- ROUTER  */
  const navigate = useNavigate()

  /* ----------------- ZUSTAND  */
  const { posts } = useCreatePostStore((s) => ({ posts: s.posts }))

  /* ----------------- METHODS  */
  const returnSocialSection = (post: IPostVariant) => {
    switch (post.info.account.provider) {
      case 'instagram':
        return <InstagramExtrasContainer post={post} />

      case 'facebook':
        return <FacebookExtrasContainer post={post} />

      case 'linkedin':
        return <LinkedinExtrasContainer post={post} />

      case 'tiktok':
        return <TiktokExtrasContainer post={post} />

      case 'youtube':
        return <YoutubeExtrasContainer post={post} />

      case 'x':
        return <XExtrasContainer post={post} />

      default:
        return null
    }
  }

  const getAllStandardPosts = () => {
    const standardPosts = posts.filter((post) => post.info.type === 'STANDARD')
    return standardPosts
  }

  useEffect(() => {
    if (posts.length === 0) navigate('/post/preview/basic')
  }, [])

  return (
    <ExtraContentContainer className="extra_content_container">
      {getAllStandardPosts().map((post, index) => (
        <>
          {returnSocialSection(post)}

          {/* //DIVIDER */}
          {index !== getAllStandardPosts().length - 1 && (
            <Divider width={'95%'} mt={40} mb={40} ml={'auto'} mr={'auto'} />
          )}
        </>
      ))}
    </ExtraContentContainer>
  )
}

const ExtraContentContainer = styled(Box)`
  &.extra_content_container {
    display: flex;
    flex-direction: column;
    padding: 8px 32px;
    width: 100%;
    height: 100%;

    & > div:last-child {
      padding-bottom: 32px;
    }
  }
`
