/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { AppStore } from 'utils'

/* ------------------- INTERFACES  */
interface DynamicHeader {
  isEmpty: boolean
  fromTopEmpty: number | string
  fromTopFull: number | string
}

interface Props {
  content: React.ReactNode
  height?: number | string
  dynamicHeader?: DynamicHeader
  mb?: string
}

export const ContentHeaderStructure = ({ content, height: height, dynamicHeader, mb }: Props) => {
  const yValue = dynamicHeader?.isEmpty ? dynamicHeader?.fromTopEmpty : dynamicHeader?.fromTopFull

  return (
    <ContentHeaderStructureContainer
      className="content_header_structure"
      style={{ y: yValue ?? 0, minHeight: height ?? '66px', marginBottom: mb ? `${mb}px` : '16px' }}
    >
      {content}
    </ContentHeaderStructureContainer>
  )
}

const ContentHeaderStructureContainer = styled(Box)`
  &.content_header_structure {
    width: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
    background: transparent;
    margin-bottom: 16px;

    color: ${() => AppStore.theme.o.black};
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.21px;
  }
`
