import { Component } from 'react'
import { AppStore } from 'utils'
import { Text, TextProps } from './Text'

interface Props extends TextProps {
  style?: React.CSSProperties
}

export class Title extends Component<Props> {
  render() {
    const { style, children, ...rest } = this.props
    return (
      <Text style={{ ...style }} color={AppStore.theme.o.black} fontSize={21} {...rest}>
        <b>{children}</b>
      </Text>
    )
  }
}
