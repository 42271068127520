import styled from '@emotion/styled'
import { Box } from 'components'
import { openThumbnailConfirmModal } from 'components/Modal/ModalManager'
import { ConfirmModal } from 'components/UI/ConfirmModal'
import { forwardRef } from 'react'
import { IPostVariant } from 'types/PostInterface'
import { T } from 'utils'
import AppStore from 'utils/AppStore'

type Props = {
  post: IPostVariant
  disabled?: boolean
  frames: string[]
  maxVideoCoverTimestampMs: number
  videoCoverTimestampMsState: number
  setVideoCoverTimestampMsState: (newState: number) => void
  setCurrentTimeVideo: (value: number) => void
}

export const ControlInputRange = forwardRef<HTMLCanvasElement, Props>((props, ref) => {
  const {
    frames,
    maxVideoCoverTimestampMs,
    videoCoverTimestampMsState,
    setVideoCoverTimestampMsState,
    setCurrentTimeVideo,
    disabled,
  } = props

  const handleRangeClick = () => {
    if (disabled) return

    // {
    //   AppStore.openModal({
    //     id: 'preview-modal',
    //     body: (
    //       <ConfirmModal
    //         text={
    //           <p>
    //             {T.settings.popEnvConfirmAccount} <br /> {T.settings.popEnvConfirm2}
    //             <span style={{ marginRight: 5, marginLeft: 5 }} />
    //             {T.settings.popEnvConfirm3}
    //             <br />
    //             {T.settings.popEnvConfirm4}
    //           </p>
    //         }
    //         cancelText={T.postPage.cancel}
    //         confirmText={T.settings.confirm}
    //         onClickCancel={() => {
    //           AppStore.closeModal('preview-modal')
    //         }}
    //         onClickConfirm={async () => {
    //           await confirm()
    //           AppStore.closeModal('preview-modal')
    //         }}
    //       />
    //     ),
    //     style: {
    //       minHeight: 300,
    //       minWidth: 700,
    //       alignContent: 'center',
    //     },
    //   })
    //   return
    // }
  }



  return (
    <FrameContainer width={520} height={16} borderRadius={16} relative row onClick={handleRangeClick}>
      <canvas ref={ref} style={{ display: 'none' }} />
      {frames.map((it, i) => {
        const containerWidth = 520
        const size = containerWidth / frames.length
        return (
          <img
            key={'frame-img' + i}
            src={it}
            style={{
              flex: '1',
              width: size,
              maxWidth: size,
              objectFit: 'cover',
            }}
          ></img>
        )
      })}
      <InputRangeGradient>
        <InputRange
          type="range"
          min={0}
          max={maxVideoCoverTimestampMs}
          value={videoCoverTimestampMsState}
          className={disabled ? 'disabled' : ''}
          onChange={(e) => {
            if (disabled) return
            const newTimestamp = parseInt(e.target.value)
            setCurrentTimeVideo(newTimestamp)
            setVideoCoverTimestampMsState(newTimestamp)
          }}
        />
      </InputRangeGradient>
    </FrameContainer>
  )
})

const FrameContainer = styled(Box)`
  img:first-of-type {
    border-radius: 16px 0 0 16px;
  }
  img:last-of-type {
    border-radius: 0 16px 16px 0;
  }
`

const InputRangeGradient = styled(Box)`
  width: 100%;
  height: 100%;

  position: absolute;
  left: 0;
  top: 0;
  &.disabled {
    opacity: 0.35; // Rendi la seekbar più trasparente
    cursor: not-allowed; // Cambia il cursore per indicare che è disabilitata
    background-color: #ffffff;
    width: 100%;
  }
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0.35) 100%);
`

const frameIndicatorIcon = `url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' width='17' height='26' viewBox='0 0 17 26' fill='none'><path d='M9 7L9 25' stroke='%232E3A59' stroke-width='2' stroke-linecap='round'/><circle cx='9' cy='4' r='4' fill='%232E3A59'/><circle cx='9' cy='4' r='2' fill='white'/></svg>")`

const InputRange = styled.input`
  /* Toglie il default "blu" dell'input */
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;

  width: 103%;
  height: 16px;
  border-radius: 16px;

  position: absolute;
  top: 0;
  left: -1.5%;

  &::-webkit-slider-thumb {
    /* Rimuove defaul thumb */
    -webkit-appearance: none;
    appearance: none;

    width: 17px;
    height: 26px;

    position: relative;
    bottom: 4px;

    cursor: grab;
    background: ${frameIndicatorIcon};
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  &.disabled {
    opacity: 0.35; // Rendi la seekbar più trasparente
    cursor: not-allowed; // Cambia il cursore per indicare che è disabilitata
    background-color: #ffffff;
    width: 101.5%;
  }
`
