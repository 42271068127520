/* ******************* IMPORT ******************* */
import styled from '@emotion/styled'
import { AppStore } from 'utils'
import { Box } from '../../../../components/Box'
import { truncateText } from '../../commonFunction'
import { ITopicDB } from 'pages/ai-discover/aiDiscoverInterface'

/* ******************* INTERFACE ******************* */

interface Props {
  onSelect: (arg: any) => void
  filteredTopics: ITopicDB[]
  changeInputFocus
}

export function SearchPreview({ onSelect, filteredTopics, changeInputFocus }: Props) {
  return (
    <SearchResultsBox id="searchResultBox">
      {filteredTopics.map((item) => (
        <SearchInputs
          key={item._id}
          onClick={(e) => {
            e.stopPropagation()

            onSelect(item.title)
            changeInputFocus()
          }}
        >
          <TopicName>{truncateText(item.title, 20)}</TopicName>
          <LanguageBoxContainer>{item.language.toUpperCase()}</LanguageBoxContainer>
          {item.description && <TopicDesc>{truncateText(item.description, 15)}</TopicDesc>}
        </SearchInputs>
      ))}
    </SearchResultsBox>
  )
}

const TopicName = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  text-align: left;

  font-family: Aktiv Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

const TopicDesc = styled(Box)`
  color: ${() => AppStore.theme.o.darkGrey};
  text-align: right;

  font-family: Aktiv Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const SearchInputs = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 42px;
  border: 1px solid transparent;
  transition: 200ms ease-in-out;
  padding: 16px;

  :hover {
    border: 1px solid ${() => AppStore.theme.o.grey};
    background-color: ${() => AppStore.theme.o.surface};
  }
`

const SearchResultsBox = styled(Box)`
  z-index: 1000;
  position: absolute;
  top: 120%;
  left: 0px;
  overflow: scroll;

  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  max-height: 255px;
  padding: 8px 16px;
  margin: 0 auto;
  background-color: ${() => AppStore.theme.o.lightestGrey};
  border: 1px solid ${() => AppStore.theme.o.grey};
  border-radius: 14px;

  box-shadow: 4px 4px 32px 0px rgba(38, 42, 72, 0.05);

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`

const LanguageBoxContainer = styled(Box)`
  margin-left: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 30px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid ${() => AppStore.theme.o.grey};

  font-size: 8px;
  letter-spacing: 1px;
  color: ${() => AppStore.theme.o.black};
`
