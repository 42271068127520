/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { ResultsPreviewFacebook } from 'components/CreatePost/PreviewComponents/BasicComponents/BasicContentComponents/Previews/Facebook/ResultsPreviewFacebook'
import { ResultsPreviewInstagramReel } from 'components/CreatePost/PreviewComponents/BasicComponents/BasicContentComponents/Previews/Instagram/ResultsPreviewInstagramReel'
import { ResultsPreviewInstagramStandard } from 'components/CreatePost/PreviewComponents/BasicComponents/BasicContentComponents/Previews/Instagram/ResultsPreviewInstagramStandard'
import { ResultsPreviewStory } from 'components/CreatePost/PreviewComponents/BasicComponents/BasicContentComponents/Previews/Instagram/ResultsPreviewStory'
import { ResultsPreviewLinkedin } from 'components/CreatePost/PreviewComponents/BasicComponents/BasicContentComponents/Previews/Linkedin/ResultsPreviewLinkedin'
import { ResultsPreviewTiktok } from 'components/CreatePost/PreviewComponents/BasicComponents/BasicContentComponents/Previews/Tiktok/ResultsPreviewTiktok'
import { ResultsPreviewX } from 'components/CreatePost/PreviewComponents/BasicComponents/BasicContentComponents/Previews/X/ResultsPreviewX'
import { ResultsPreviewShort } from 'components/CreatePost/PreviewComponents/BasicComponents/BasicContentComponents/Previews/Youtube/ResultsPreviewShort'
import { ResultsPreviewYoutubeStandard } from 'components/CreatePost/PreviewComponents/BasicComponents/BasicContentComponents/Previews/Youtube/ResultsPreviewYoutubeStandard'
import { IPostVariant, TActiveMediaType } from 'types/PostInterface'
import { getPreviewByVariant } from 'utils/PostUtils/Post'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  postPreview: IPostVariant
}

export const PreviewPostMedia = ({ postPreview }: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */
  const activeMedia =
    postPreview?.data?.medias && postPreview?.data?.medias[0]
      ? (postPreview?.data?.medias[0].metadata?.fileType as TActiveMediaType)
      : ''

  const activePreview = getPreviewByVariant(postPreview, activeMedia)

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  /* ----------------- USEEFFECT  */
  const content = {
    X: <ResultsPreviewX postPreview={postPreview} />,
    FACEBOOK: <ResultsPreviewFacebook postPreview={postPreview} />,
    TIKTOK: <ResultsPreviewTiktok postPreview={postPreview} />,
    LINKEDIN: <ResultsPreviewLinkedin postPreview={postPreview} />,
    INSTAGRAM_STANDARD: <ResultsPreviewInstagramStandard postPreview={postPreview} />,
    INSTAGRAM_REEL: <ResultsPreviewInstagramReel postPreview={postPreview} />,
    INSTAGRAM_STORY: <ResultsPreviewStory postPreview={postPreview} />,
    YOUTUBE_STANDARD: <ResultsPreviewYoutubeStandard postPreview={postPreview} />,
    YOUTUBE_SHORT: <ResultsPreviewShort postPreview={postPreview} />,
  }

  return <PreviewPostMediaContainer>{content[activePreview]}</PreviewPostMediaContainer>
}

const PreviewPostMediaContainer = styled(Box)`
  width: 422px;
  max-width: 442px;
`
