import { Box, Text } from 'components'
import { useEffect, useState } from 'react'
import { IPostVariant } from 'types/PostInterface'
import { AppStore, T } from 'utils'
import { ExtraSwitch } from './ExtraSwitch'

type Props = {
  post: IPostVariant
}

export const ExtraOptions = ({ post }: Props) => {
  const [duet, setDuet] = useState(post.data.duet ?? true)
  const [stitch, setStich] = useState(post.data.stitch ?? true)
  const [commentAvaible, setCommentAvaible] = useState(post.data.commentAvaible ?? true)

  return (
    <Box width={384}>
      <Text fontSize={16} color={AppStore.theme.o.blue} style={{ fontWeight: '500', marginBottom: '12px' }}>
        {T.postPage.tiktokExtra.allowUsersTo}
      </Text>
      <Box row gap={24}>
        <ExtraSwitch post={post} name={T.postPage.tiktokExtra.duet} postName={'duet'} value={duet} setValue={setDuet} />
        <ExtraSwitch post={post} name={'Stitch'} postName={'stitch'} value={stitch} setValue={setStich} />
        <ExtraSwitch
          post={post}
          name={T.postPage.comment}
          postName={'commentAvaible'}
          value={commentAvaible}
          setValue={setCommentAvaible}
        />
      </Box>
    </Box>
  )
}
