import React, { useRef, useState, useCallback } from 'react'
import styled from 'styled-components'

const HoverPlayVideo = ({ url }) => {
  const videoRef: any = useRef(null)
  const [isHovering, setIsHovering] = useState(false)

  const handleMouseEnter = useCallback(() => {
    setIsHovering(true)
    videoRef.current?.play()
  }, [])

  const handleMouseLeave = useCallback(() => {
    setIsHovering(false)
    videoRef.current?.pause()
  }, [])

  return (
    <VideoContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <StyledVideo ref={videoRef} src={url} loop muted playsInline />
      <HoverOverlay isVisible={!isHovering}></HoverOverlay>
    </VideoContainer>
  )
}

export default HoverPlayVideo

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
    border-radius: 14px;

  overflow: hidden;
`

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const HoverOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  pointer-events: none;
`
