import { ModalType } from 'types'
import { create } from 'zustand'

interface MenuNavigationStore {
  locationPage: string
  setLocationPage: (locationPage: string) => void

  locationSection: string
  setLocationSection: (locationSection: string) => void

  locationSubSection: string
  setLocationSubSection: (locationSubSection: string) => void
}

/*   ---------------- ATTENZIONE ------------------   */
// Questo store è stato creato per gestire la navigazione quando non si clicca direttamente nel menu principale, quindi non va utilizzato sempre ma solo nei casi limite in cui non si può utilizzare il menu principale per spostarsi tra le pagine
export const useMenuNavigationStore = create<MenuNavigationStore>((set, get) => ({
  locationPage: '',
  setLocationPage: (locationPage) => {
    set(() => ({ locationPage }))
  },

  locationSection: '',
  setLocationSection: (locationSection) => {
    set(() => ({ locationSection }))
  },

  locationSubSection: '',
  setLocationSubSection: (locationSubSection) => {
    set(() => ({ locationSubSection }))
  },
}))
