import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Box } from 'components'
import { AppStore, T, showSuccess, showWarning } from 'utils'
import { IPhoneCountry, IPhoneNumber } from 'types/NotifyInterface'
import InputFlagPhoneNumber from './InputFlagPhoneNumber'
import OtpField from './OtpField'
import { postAddNumber } from './utils/apiCall'

interface Props {
  phone?: IPhoneNumber
  canModifyBtn?: boolean
  onReturnData: (data, isValid) => void // funzione per tornare i dati(numero) al padre
  inputWidth?: string
  onReturnOtp: (otp: string, isValid: boolean) => void // funzione per tornare i dati dell'otp al padre
}

const WhatsAppRequest = ({ phone, canModifyBtn, onReturnData, onReturnOtp, inputWidth }: Props) => {
  /* ____________________ STATE ____________________ */
  const [phoneNumber, setPhoneNumber] = useState<IPhoneNumber>({ prefix: '', number: '' })
  const [valid, setValid] = useState<boolean>(true)
  const [btnModifyVisibility, setBtnModifyVisibility] = useState<boolean>()
  const [canSendOtp, setCanSendOtp] = useState<boolean>(false)
  const [openOtp, setOpenOtp] = useState<boolean>(false)

  /* ____________________ METHODS ____________________ */
  /**
   *
   * ## handleChange
   *  - setta lo stato del numero di telefono e se è valido
   *
   *  - richiamo funzione logica e settaggio se il numero è valido in base al prefisso ed al formato del paese
   *
   * @param value string numero con prefisso
   * @param country { name: "italia", dialCode: "39", countryCode: "it", format: "+.. ... ......."}
   *
   * @returns object {prefix: "39", phone: "3346985763"}
   */
  const handleChange = (value: string, country: IPhoneCountry) => {
    const validation = countryCodeVerifyNum(value, country)

    setValid(validation)
    setPhoneNumber({ prefix: country.dialCode, number: value.replace(country.dialCode, '') })
    onReturnData({ prefix: country.dialCode, phone: value.replace(country.dialCode, '') }, validation)
  }

  /**
   * ## countryCodeVerifyNum
   *
   * logica per la verifica se il numero è valido in base al prefisso ed al formato del paese
   *
   * - se è valido setta lo stato di canSendOtp a true altrimenti a false
   *
   * @param num string numero con prefisso
   * @param countryData { name: "italia", dialCode: "39", countryCode: "it", format: "+.. ... ......."}
   * @returns boolean
   */
  const countryCodeVerifyNum = (num: string, countryData: IPhoneCountry) => {
    const { dialCode, format } = countryData

    const formatLength = format.split('.').length - 1
    const numLengthIsValid = num.split('').length === formatLength
    const numPrefixIsValid = num.startsWith(dialCode)

    const validation = numLengthIsValid && numPrefixIsValid

    validation && setCanSendOtp(true)
    !validation && setCanSendOtp(false)

    return validation
  }

  /**
   * ## shakeInvalidInput
   * - aggiunge la classe shake all'input in caso di errore
   */
  const shakeInvalidInput = () => {
    const input = document.querySelector('.input-container') as HTMLElement

    input?.classList.add('shake')

    setTimeout(() => {
      input?.classList.remove('shake')
    }, 300)
  }

  /**
   *
   * @param otp stringa dell'otp string che ritorna dal componente OtpField
   * @param isValid boolean che ritorna dal componente OtpField per sapere se è valido o meno il codice inserito e già verificato tramite api
   */
  const retriveOtp = (otp: string, isValid: boolean) => {
    if (isValid) {
      setOpenOtp(false)
      setBtnModifyVisibility(true)
    }
    onReturnOtp(otp, isValid)
  }

  /* ____________________ API ____________________ */
  const onSendWhatsappOtp = async () => {
    if (btnModifyVisibility) return

    if (!canSendOtp || phoneNumber.number === '' || phoneNumber.number === undefined) {
      showWarning(T.settings.otpSendError)
      return shakeInvalidInput()
    }

    const sendMsg = await postAddNumber(String(phoneNumber.prefix), String(phoneNumber.number))

    if (sendMsg) {
      setOpenOtp(true)
      showSuccess(T.settings.otpSendSuccess)
      return
    }
  }

  /* ____________________ USEEFFECT ____________________ */
  useEffect(() => {
    setPhoneNumber(phone ?? { prefix: '', number: '' })
    setBtnModifyVisibility(canModifyBtn ?? false)

    if (phone) {
      setCanSendOtp(true)
    }

    return () => {
      setBtnModifyVisibility(false)
    }
  }, [])

  return (
    <UserNumberContainer>
      <InfoContainer>
        <Label>{T.settings.yourPhone}</Label>
        <Vspacer />
        {!btnModifyVisibility && <BtnResendCode onClick={onSendWhatsappOtp}>{T.settings.resendCode}</BtnResendCode>}
        {btnModifyVisibility && (
          <BtnModify onClick={() => setBtnModifyVisibility(false)}>{T.settings.modify}</BtnModify>
        )}
      </InfoContainer>
      {!openOtp && (
        <InputFlagPhoneNumber
          phoneNumber={phoneNumber}
          handleChange={handleChange}
          isValid={valid}
          width={inputWidth}
          canModify={btnModifyVisibility}
        />
      )}

      {openOtp && <OtpField returnData={retriveOtp} />}
    </UserNumberContainer>
  )
}

export default WhatsAppRequest

const UserNumberContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  border-radius: 0;
`

const InfoContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  border-radius: 0;
  margin-bottom: 6px;
`

const Label = styled.p`
  font-weight: 700;
  font-size: 16px;
  color: ${() => AppStore.theme.o.black};
`
const Vspacer = styled.div`
  width: 2px;
  height: 20px;
  margin-left: 6px;
  margin-right: 6px;
  background-color: ${() => AppStore.theme.o.grey};
`
const BtnResendCode = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: ${() => AppStore.theme.o.blue};
  cursor: pointer;
`
const BtnModify = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: ${() => AppStore.theme.o.blue};
  cursor: pointer;
`
