// const config = {
//   endpoint: process.env.REACT_APP_VAY_BACKEND_URL
//     ? process.env.REACT_APP_VAY_BACKEND_URL
//     : 'https://heroku-asters-env-test.herokuapp.com/',
//   DEV: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
// }

const config = {
  endpoint: process.env.REACT_APP_BACKEND_URL
    ? process.env.REACT_APP_BACKEND_URL
    : 'https://heroku-asters-env-test.herokuapp.com/',
  DEV: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
}

export default config
