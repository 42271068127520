import styled from '@emotion/styled'
import { AppStore } from 'utils'

/* ******************* INTERFACE ******************* */
interface Props {
  checked: boolean
  onChange: () => void
}

export const CustomCheckbox = ({ checked, onChange }: Props) => {
  return (
    <CheckboxWrapper>
      <CheckboxInput type="checkbox" checked={checked} onChange={onChange} />
      <Checkmark className="checkmark" />
    </CheckboxWrapper>
  )
}

const CheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const CheckboxInput = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + .checkmark {
    background-color: ${AppStore.theme.o.green};
  }

  &:checked + .checkmark:after {
    display: block;
  }
`

const Checkmark = styled.span`
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: none;
  background-color: ${() => AppStore.theme.o.grey};

  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 6px;
    top: 2px;
    width: 5px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`
