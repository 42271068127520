/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { PreviewResult } from '../../GenericComponents/PreviewResults'
import { TextContainer } from '../../GenericComponents/TextContainer'
import { ResultsPreviewLinkedin } from './ResultsPreviewLinkedin'

/* ------------------- INTERFACES  */
interface Props {}

export const Linkedin = ({}: Props) => {
  return (
    <LinkedinContainer>
      <PreviewResult component={<ResultsPreviewLinkedin />} />
      <TextContainer />
    </LinkedinContainer>
  )
}

const LinkedinContainer = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
`
