import Auth from 'api/Auth'
import { useEffect, useState } from 'react'
import { AppStore, showError } from 'utils'
import { NotificationEnabled } from 'utils/NotificationAbilitate'
import { history } from 'utils/router'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

export const MobileDetection = () => {
  /* ----------------- STATE  */

  const [pageToShow, setPageToShow] = useState('mobileNotAvaible')
  /* ----------------- REF  */

  useEffect(() => {
    onMount()
  }, [])

  const onMount = async () => {
    if (history.location.pathname === '/token') {
      await AppStore.setToken(history.location.search.substring(14))
    }
    if (history.location.pathname.includes('confirmUser')) {
      try {
        const token = await Auth.confirmUser(history.location.search.substring(11))
        await AppStore.setToken(token)
      } catch (error) {
        await AppStore.setToken()
      }
    }
    if (history.location.pathname.includes('acceptNewEmail')) {
      try {
        const token = await Auth.acceptNewEmail(history.location.search.substring(7))
        await AppStore.setToken(token)
      } catch (error) {
        await AppStore.setToken()
        showError(error)
      }
    }
    if (history.location.pathname.includes('notifications')) {
      setPageToShow('notificationsAbilitate')
    }
    await AppStore.init()
  }

  if (pageToShow === 'mobileNotAvaible') {
    return <>{location.replace('https://asters.ai/mobile-alert/')}</>
  }

  if (pageToShow === 'notificationsAbilitate') {
    return <NotificationEnabled />
  }

  return <></>
}
