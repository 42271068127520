/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { useEffect, useState } from 'react'
import { useDiscoverStore } from 'stores/DiscoverStore'
import { AppStore, T } from 'utils'

/* ------------------- TYPES  */
type TFilterValues = ['more-recent', 'less-recent'] | ['more-recent', 'less-recent', 'private', 'shared']

type TFilterValue = 'more-recent' | 'less-recent' | 'private' | 'shared'

/* ------------------- INTERFACES  */

interface Props {
  invokedFrom: string
}

export const FilterDropdown = ({ invokedFrom }: Props) => {
  /* ----------------- STATE  */
  const [filters, setFilters] = useState<TFilterValues>()
  const [selectedFilter, setSelectedFilter] = useState<TFilterValue>('more-recent')
  const [onIsHoverFilter, setOnIsHoverFilter] = useState<boolean>(false)

  /* ----------------- ZUSTAND  */
  const {
    trendingArticlesByTopicList,
    setTrendingArticlesByTopicList,
    trendingArticlesByFollowedTopic,
    setTrendingArticlesByFollowedTopic,
    searchedArticle,
    setSearchedArticle,
    activeTopicSidebar,
  } = useDiscoverStore((state) => ({
    trendingArticlesByTopicList: state.trendingArticlesByTopicList,
    setTrendingArticlesByTopicList: state.setTrendingArticlesByTopicList,
    trendingArticlesByFollowedTopic: state.trendingArticlesByFollowedTopic,
    setTrendingArticlesByFollowedTopic: state.setTrendingArticlesByFollowedTopic,
    searchedArticle: state.searchedArticle,
    setSearchedArticle: state.setSearchedArticle,
    activeTopicSidebar: state.activeTopicSidebar,
  }))

  /* ----------------- METHODS  */
  const filterTextChange = (text: string) => {
    if (text === 'more-recent') {
      return T.aiDiscoverPage.sidebar.moreRecent
    }
    if (text === 'less-recent') {
      return T.aiDiscoverPage.sidebar.lessRecent
    }
  }

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    if (invokedFrom === 'header_search') {
      setFilters(['more-recent', 'less-recent'])
    }

    if (invokedFrom == 'header_follow') {
      setFilters(['more-recent', 'less-recent'])
    }
  }, [])

  // Applica Filtri
  useEffect(() => {
    if (invokedFrom === 'header_search') {
      if (searchedArticle)
        if (selectedFilter === 'more-recent') {
          setSearchedArticle(
            searchedArticle.sort((a, b) => new Date(b.dateTimePub).getTime() - new Date(a.dateTimePub).getTime())
          )
        } else if (selectedFilter === 'less-recent') {
          setSearchedArticle(
            searchedArticle.sort((a, b) => new Date(a.dateTimePub).getTime() - new Date(b.dateTimePub).getTime())
          )
        }
    }

    if (invokedFrom === 'header_follow') {
      if (trendingArticlesByFollowedTopic) {
        if (activeTopicSidebar !== null) {
          if (selectedFilter === 'more-recent') {
            setTrendingArticlesByFollowedTopic(
              trendingArticlesByFollowedTopic.sort(
                (a, b) => new Date(b.dateTimePub).getTime() - new Date(a.dateTimePub).getTime()
              )
            )
          }

          if (selectedFilter === 'less-recent') {
            setTrendingArticlesByFollowedTopic(
              trendingArticlesByFollowedTopic.sort(
                (a, b) => new Date(a.dateTimePub).getTime() - new Date(b.dateTimePub).getTime()
              )
            )
          }
        }
      }

      if (trendingArticlesByTopicList) {
        if (!activeTopicSidebar) {
          if (selectedFilter === 'more-recent') {
            setTrendingArticlesByTopicList(
              trendingArticlesByTopicList.sort(
                (a, b) => new Date(b.dateTimePub).getTime() - new Date(a.dateTimePub).getTime()
              )
            )
          }

          if (selectedFilter === 'less-recent') {
            setTrendingArticlesByTopicList(
              trendingArticlesByTopicList.sort(
                (a, b) => new Date(a.dateTimePub).getTime() - new Date(b.dateTimePub).getTime()
              )
            )
          }
        }
      }
    }
  }, [selectedFilter])

  return (
    <FilterDropdownContainer
      onMouseEnter={() => setOnIsHoverFilter(true)}
      onMouseLeave={() => setOnIsHoverFilter(false)}
    >
      <SelectedFilter>
        {filterTextChange(selectedFilter)}
        <Icons.dropdownArrow fill={AppStore.theme.o.black} className={`${onIsHoverFilter ? 'filter__hovered' : ''}`} />
      </SelectedFilter>
      {onIsHoverFilter && (
        <>
          <SpacerFilter />
          <ContentFilter>
            {filters?.map((filter) => (
              <FilterOption
                key={filter}
                onClick={() => {
                  setSelectedFilter(filter)
                }}
              >
                {filterTextChange(filter)}
              </FilterOption>
            ))}
          </ContentFilter>
        </>
      )}
    </FilterDropdownContainer>
  )
}

const FilterDropdownContainer = styled(Box)`
  position: relative;
  width: 156px;
  display: flex;
  flex-direction: column;
  background: transparent;
`

const SelectedFilter = styled(Box)`
  display: flex;
  flex-direction: row;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border-radius: 14px;
  border: 1px solid ${() => AppStore.theme.o.grey};
  background: ${() => AppStore.theme.o.lightestGrey};
  color: ${() => AppStore.theme.o.black};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  & > svg {
    transition: 250ms ease-in-out;
  }

  & > .filter__hovered {
    rotate: -180deg;
  }
`

const SpacerFilter = styled(Box)`
  width: 100%;
  height: 10px;
  position: absolute;
  top: 100%;
  left: 0;
  background: transparent;
`

const ContentFilter = styled(Box)`
  position: absolute;
  display: flex;
  transition: 300ms ease-in-out;
  top: 110%;
  left: 0;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  z-index: 10;
  padding: 12px 16px;
  border-radius: 14px;
  border: 1px solid ${() => AppStore.theme.o.grey};
  background: ${() => AppStore.theme.o.lightestGrey};
`

const FilterOption = styled(Box)`
  border-radius: 8px;
  padding: 10px 10px;
  color: ${() => AppStore.theme.o.darkGrey};
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  :hover {
    color: ${() => AppStore.theme.o.black};
  }
`
