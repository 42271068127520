import Reports from 'api/Reports'
import { SpinnerAstersLogo } from 'components/UI'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAnalyticsStore } from 'stores/AnalyticsStore'
import { IReport, IReportConfig } from 'types/AnalyticsInferfaces'
import { AppStore, T, showError, showInfo } from 'utils'
import { getReport } from 'utils/AnalyticsUtils'
import RenderReport from './RenderPreviewReport/RenderReport'
import { useReportStore } from 'stores/ReportStore'

/* ___________________ INTERFACE ___________________ */

interface Props {
  reportPreview: IReportConfig | null
}

export const PreviewReport = ({ reportPreview }: Props) => {
  /* ____________________ ROUTER ____________________ */
  const location = useLocation()

  const newReportConfig = location.state?.newReportConfig ?? undefined
  const idToModifyReport = location.state?.idToModifyReport ?? undefined
  // const [reportPreview, setStateReportPreview] = useState<IReportConfig | null>(
  //   useAnalyticsStore.getState().reportPreview
  // )

  // const reportPreview = useAnalyticsStore((state) => state.reportPreview)
  const setReportPreview = useAnalyticsStore((state) => state.setReportPreview)
  const onSetReportName = useAnalyticsStore((state) => state.onSetReportName)

  const { reportId } = useParams()
  const navigate = useNavigate()

  /* ____________________ REF ____________________ */

  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  /* ____________________ ZUSTAND ____________________ */
  // const { setReportPreview, onSetReportName } = useAnalyticsStore.getState()

  // const unsubReport = useAnalyticsStore.subscribe(
  //   (state) => state.reportPreview,
  //   (newReport, oldReport) => {
  //     console.log('newReport', newReport)
  //     if (newReport !== oldReport) {
  //       setStateReportPreview(newReport)
  //     }
  //   }
  // )

  /* ____________________ API CALL ____________________ */
  const createOrUpdateReport = async () => {
    let report: IReport | undefined
    const updateReport = { ...newReportConfig, reportId: idToModifyReport }

    try {
      report = await Reports.createReport(updateReport)
    } catch (err) {
      showError(T.error.generic_error)
      navigate('/analytics')
      return
    }

    if (!window.location.pathname.split('/')[2]) {
      const reportsList = await Reports.getReportList()

      useAnalyticsStore.getState().setReportList(reportsList)
      showInfo(
        `${T.analyticsPage.yourReportNotice} "${report?.info?.reportName}" ${T.analyticsPage.yourReportNoticeIsReady}`
      )

      return
    }

    report && navigate(`/analytics/report/${report?._id}`, { replace: true, state: { subsection: 'setup' } })
    report && getApiReport(report._id)
  }


  const getApiReport = async (id: string | undefined = undefined) => {
    const idReport = reportId ?? id
    const addReport: any = await getReport(idReport)

    if (!addReport) {
      intervalRef.current = setTimeout(() => getApiReport(idReport), 10000)
      return
    } else {
      navigate(`/analytics/report/${idReport}`, { replace: true, state: { subsection: 'setup' } })
      intervalRef.current = null
    }

    if (!reportPreview || reportPreview._id !== idReport) {
      setTimeout(() => {
        onSetReportName(addReport.info.reportName!)
        setReportPreview(addReport)
      }, 200)
    }
  }

  const onMount = async () => {
    useReportStore.getState().clearMetrics()
    const check = await AppStore.checkPermission('view_report')

    if (!check) {
      showInfo(`${T.error.noPermissionTo} ${T.error.viewReport}`)
      navigate('/analytics')
    } else {
      if (newReportConfig) createOrUpdateReport()
      else if (reportId) {
        !reportPreview && navigate(`/analytics/report/${reportId}`, { replace: true, state: { subsection: 'loading' } })

        getApiReport()
      } else navigate('/analytics')

      // return () => {
      //   unsubReport()
      // }
    }
  }

  /* ____________________ USEEFFECT ____________________ */
  useEffect(() => {
    console.log('useEffect')
    onMount()
  }, [])

  return reportPreview && reportPreview._id === reportId ? (
    <RenderReport report={reportPreview} />
  ) : (
    <SpinnerAstersLogo dimension={100} />
  )
}
