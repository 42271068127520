import { SubscriptionPlan } from 'types/SubscriptionInterfaces'
import { AppStore, getHumanReadableDate, T } from 'utils'
import { useUserStore } from 'stores/UserStore'
import { UpgradeOptionComponent } from './UpgradeOptionComponent'
import { calculateVAT } from 'utils/UsersUtils'

/**
 * Component for displaying upgrade with cancel at end confirmation
 */
export const UpgradeWithCancelInterface: React.FC<{ plan: SubscriptionPlan }> = ({ plan }) => {
  const { currentPlan, currentLanguage } = useUserStore((state) => ({
    currentPlan: state.user?.subscription?.activePlan,
    currentLanguage: state.user?.language ?? 'it',
  }))

  //@todo  ----------- Da calcolare
  const upgradeConfig = {
    amount: plan.price,
    previousPlanAmount: currentPlan?.plan?.price ?? 0,
    isCredit: false,
    dateExpiringCurrentPlan: currentPlan?.expirationDate
      ? getHumanReadableDate(new Date(currentPlan.expirationDate))
      : '-',
    newPlanName: plan?.data[currentLanguage].title ?? '-',
    oldPlanName: AppStore.loggedUser.subscription?.activePlan?.plan?.data?.[currentLanguage].title ?? '-',
  }

  // Information about current plan
  const fromPlan = {
    name: upgradeConfig.oldPlanName,
    description: T.billing.upgradePlan.pricePlan,
    amount: upgradeConfig.previousPlanAmount,
    creditColor: AppStore.theme.o.black,
  }

  // Information about new Plan
  const toPlan = {
    name: upgradeConfig.newPlanName,
    description: T.billing.upgradePlan.pricePlan,
    amount: upgradeConfig.amount,
    creditColor: AppStore.theme.o.black,
  }

  // Amounts of operations
  const operationAmount = {
    amount: upgradeConfig.amount,
    amountWithVAT: calculateVAT(upgradeConfig.amount),
    amountTotal: 0, //upgradeConfig.amount + calculateVAT(upgradeConfig.amount),
  }

  return (
    <UpgradeOptionComponent
      fromPlan={fromPlan}
      toPlan={toPlan}
      type="cancel"
      options={operationAmount}
      title={T.billing.upgradePlan.upgrade}
      description={T.billing.upgradePlan.upgradePlanDescription}
    />
  )
}
