/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { AppStore } from 'utils'
import { TLabelsFilters } from '../LabelsContent'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  labelsFilter?: TLabelsFilters
  setLabelsFilter?: (filter: TLabelsFilters) => void
}

export const SwitchLabelFilter = ({ labelsFilter, setLabelsFilter }: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  /* ----------------- USEEFFECT  */
  const options = [
    {
      id: 'and',
      name: 'AND',
      action: () => setLabelsFilter && setLabelsFilter('and'),
    },
    {
      id: 'or',
      name: 'OR',
      action: () => setLabelsFilter && setLabelsFilter('or'),
    },
  ]

  return (
    <SwitchLabelFilterContainer>
      {options.map((otp, i) => (
        <SwitchFilter
          key={otp.id + i}
          onClick={otp.action}
          className={`${labelsFilter === otp.id ? 'active_option' : ''}`}
        >
          {otp.name}
        </SwitchFilter>
      ))}
    </SwitchLabelFilterContainer>
  )
}

const SwitchLabelFilterContainer = styled(Box)`
  display: flex;
  padding: 3px 8px;
  gap: 8px;
  flex-direction: row;
  border-radius: 14px;
  background: ${() => AppStore.theme.o.lightestGrey};
  height: 32px;
  justify-content: center;
  align-items: center;
`

const SwitchFilter = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  border-radius: 14px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  height: 100%;
  color: ${() => AppStore.theme.o.grey};
  background: ${() => AppStore.theme.o.lightestGrey};

  &.active_option {
    background: ${() => AppStore.theme.o.surface};
    color: ${() => AppStore.theme.o.black};
  }
`
