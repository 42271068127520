/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { useEffect, useState } from 'react'
import { enumStatusColor } from './PostStatusInfo'
import { PostStatus } from 'types'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  date: Date
  status: PostStatus
  margin?: string
}

export const DatePostStatus = ({ date, status, margin }: Props) => {
  /* ----------------- STATE  */
  const [formattedDay, setFormattedDay] = useState<string>('')
  const [formattedTime, setFormattedTime] = useState<string>('')

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */
  const formatDate = (fDate: Date) => {
    const day = ('0' + fDate.getDate()).slice(-2)
    const month = ('0' + (fDate.getMonth() + 1)).slice(-2)
    const year = fDate.getFullYear().toString().slice(-2)
    return day + '/' + month + '/' + year
  }

  const formatTime = (fDate: Date) => {
    const hours = ('0' + fDate.getHours()).slice(-2)
    const minutes = ('0' + fDate.getMinutes()).slice(-2)
    return hours + ':' + minutes
  }

  const convertTimestamp = (timestamp) => {
    const fDate = new Date(timestamp)

    setFormattedDay(formatDate(fDate))
    setFormattedTime(formatTime(fDate))
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    convertTimestamp(date)
  }, [])

  return (
    <DatePostStatusContainer
      style={{
        color: enumStatusColor[status].color,
        margin: margin ?? '0',
      }}
    >
      <DayContainer>{formattedDay}</DayContainer>
      <TimeContainer>{formattedTime}</TimeContainer>
    </DatePostStatusContainer>
  )
}

const DatePostStatusContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 16px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.16px;
`

const DayContainer = styled(Box)``

const TimeContainer = styled(Box)``
