/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import PostV2 from 'api/PostV2'
import { Box } from 'components'
import { SpinnerAstersLogo } from 'components/UI'
import { useEffect, useState } from 'react'
import { IPostVariant } from 'types/PostInterface'
import { AppStore } from 'utils'
import { PreviewContent } from './PreviewContent'

/* ------------------- TYPES  */

export type TIconPost = 'STANDARD' | 'CAROUSEL' | 'VIDEO' | 'SHORT' | 'STORY' | 'REEL' | 'LINK'

/* ------------------- INTERFACES  */

interface Props {
  postId: string
}

export const PreviewPostModal = ({ postId }: Props) => {
  /* ----------------- STATE  */
  const [post, setPost] = useState<IPostVariant | null>(null)

  /* ----------------- VARIABLES  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */
  /**
   * Fetch dei dati del post visualizzato
   */
  const getPostData = async () => {
    try {
      const res = await PostV2.getSinglePost(postId)

      setPost(res.data!)
    } catch (e) {
      console.error(e)
    }
  }

  /* ----------------- USEEFFECT  */
  // Al mounting fetch dei dati del post visualizzato
  useEffect(() => {
    const documentClass = document.getElementsByClassName('ant-modal-content') as HTMLCollectionOf<HTMLElement>
    documentClass[0].style.backgroundColor = 'transparent'
    getPostData()
  }, [])

  return (
    <div style={{ borderRadius: 14 }} id="post_preview_modal">
      <PreviewPostModalContainer>
        {!post && <SpinnerAstersLogo dimension={50} />}

        {post && <PreviewContent post={post} />}
      </PreviewPostModalContainer>
    </div>
  )
}

const PreviewPostModalContainer = styled(Box)`
  padding: 24px 32px;
  position: relative;
  gap: 16px;
  background-color: ${() => AppStore.theme.o.surface};
  border-radius: 14px;
`
