import styled from '@emotion/styled'
import { CreateHashtagGroupModal } from 'components/Modal/ModalManager'
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { Icons } from 'components/Icons'
import { MultiToggleButton } from 'components/MultiToggleButton'
import { Text } from 'components/Text'
import { AppStore, T } from 'utils'

/* ------------------- INTERFACES  */
interface Props {
  setFilter: (filter) => void
}

export const HearderTemplate = ({ setFilter }: Props) => {
  const multiToggleStyles = {
    maxWidth: 424,
    maxHeight: 64,
    padding: 8,
  }

  return (
    <Container row gap={16} vcenter>
      <MultiToggleButton
        values={[T.postPage.all, T.postPage.caption, T.postPage.hashtag]}
        style={multiToggleStyles}
        onChange={(e) => setFilter(e)}
      />

      {/* @todo ruben creare styled components  */}
      <Button
        style={{ borderRadius: 14, height: 56 }}
        variant="primary"
        onClick={async () => CreateHashtagGroupModal()}
      >
        <Box row vcenter>
          <TemplateIcon />
          <Text
            style={{
              fontSize: 16,
              fontWeight: 700,
              color: AppStore.theme.o.blue,
            }}
          >
            {T.postPage.createModal}
          </Text>
        </Box>
      </Button>
    </Container>
  )
}

const Container = styled(Box)``

const TemplateIcon = styled(Icons.template)`
  width: 32px;
  height: 32px;
  fill: ${() => AppStore.theme.o.blue};
`
