import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { AppStore } from 'utils'
import { Box } from './Box'
import { Icons } from './Icons'

export const HeaderButtonSettings = () => {
  const navigate = useNavigate()
  return (
    <ButtonSettings
      id={'settings-button'}
      onClick={() => {
        navigate('/settings/account')
        AppStore.setActiveMenu(-1)
      }}
      style={{
        backgroundColor: window.location.pathname.includes('settings') ? AppStore.theme.o.lightBlue : undefined,
        pointerEvents: window.location.pathname.includes('settings') ? 'none' : 'all',
      }}
    >
      <Icons.settings
        fill={window.location.pathname.includes('settings') ? AppStore.theme.o.blueOnLight : AppStore.theme.o.black}
        style={{ margin: 20 }}
      />
    </ButtonSettings>
  )
}

const ButtonSettings = styled(Box)`
  svg {
    fill: ${() => AppStore.theme.o.black};
  }

  &:hover {
    background-color: ${() => AppStore.theme.o.lightBlue};

    svg {
      fill: ${() => AppStore.theme.o.blueOnLight};
    }
  }
`
