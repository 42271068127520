/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { POST_NAVBAR_ABSOLUTE_RIGHT, POST_NAVBAR_ABSOLUTE_TOP } from 'utils/Constants/CreatePostConstants'

/* ------------------- INTERFACES  */
interface Props {
  showNavigation?: boolean
  positionAbsolute?: boolean
  content: React.ReactNode
}

export const NavigationStructure = ({ showNavigation = true, content, positionAbsolute = false }: Props) => {
  return (
    <NavigationStructureContainer
      className="navigation_structure"
      style={
        positionAbsolute
          ? {
              position: 'absolute',
              top: POST_NAVBAR_ABSOLUTE_TOP,
              right: POST_NAVBAR_ABSOLUTE_RIGHT,
              transform: 'translate(-50%,-0%)',
            }
          : { position: 'relative' }
      }
    >
      {showNavigation && content}
    </NavigationStructureContainer>
  )
}

const NavigationStructureContainer = styled(Box)`
  &.navigation_structure {
    /* Altezza ContentHeader + il gap (66 + 16) */
    margin-left: auto;
    margin-top: 82px;
    min-width: 100px;
    justify-content: center;
    align-items: center;
  }
`
