import styled from '@emotion/styled'
import { Box } from 'components/Box'
import React from 'react'
import { AppStore } from 'utils'

/* ___________________ INTERFACE ___________________ */
interface Props {
  isOn: boolean
  handleToggle: () => void
}

const ToggleNotify = ({ isOn, handleToggle }: Props) => {
  return (
    <NotifyToggleContainer onClick={handleToggle} className={isOn ? 'on' : 'off'} removeHover>
      <NotifyCircle className="notify-toggle-circle" />
    </NotifyToggleContainer>
  )
}

export default ToggleNotify

const NotifyToggleContainer = styled(Box)`
  width: 28px;
  height: 16px;
  position: relative;
  background-color: ${() => AppStore.theme.o.grey};
  transition: 0.3s ease-in-out !important;

  .notify-toggle-circle {
    background-color: ${() => AppStore.theme.o.surface};
    left: 1px;
  }

  &.on {
    background-color: ${() => AppStore.theme.o.green};
    justify-content: flex-end;

    .notify-toggle-circle {
      left: 12px;
    }
  }
`

const NotifyCircle = styled(Box)`
  width: 15px;
  height: 15px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`
