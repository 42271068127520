/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Tracker } from 'api/Tracker'
import { Box, Button } from 'components'
import { useState } from 'react'
import { AppStore, T } from 'utils'
import { TRACKEREVENTS, getAiCaptionFeedback } from 'utils/eventsTracker'
import { MixpanelAiCaptionFeedback } from 'utils/eventsTracker/aiSection/aiSectionInterfaces'

/* ------------------- INTERFACES  */
interface Props {
  isFeedbackPositive: boolean
}

export const FeedbackGenerationModal = ({ isFeedbackPositive }: Props) => {
  /* ----------------- STATE  */
  const [textAreaValue, setTextAreaValue] = useState<string>('')

  /* ----------------- METHODS  */
  const handleSendFeedback = () => {
    //TODO Fa chiamata API a airtable, in base a isFeedbackPositive sa se positivo o negativo

    AppStore.closeModal('generation-feedback-modal')

    const feedbackData: MixpanelAiCaptionFeedback = {
      ai_caption_feedback_type: isFeedbackPositive ? 'Positive' : 'Negative',
      ai_caption_feedback_note: textAreaValue,
    }

    Tracker.trackEvent(TRACKEREVENTS.AI_CAPTION_FEEDBACK_SUBMITTED, getAiCaptionFeedback(feedbackData))
  }

  /* ----------------- API CALL  */

  return (
    <FeedbackGenerationModalContainer>
      <ContainerFeedback>
        <TitleFeedback>
          {isFeedbackPositive ? T.textGenerator.feedbackBrandVoiceLike : T.textGenerator.feedbackBrandVoiceDislike}
        </TitleFeedback>

        <TextAreaFeedback
          onChange={(e) => setTextAreaValue(e.target.value)}
          value={textAreaValue}
          placeholder={T.textGenerator.enterHere}
        />
      </ContainerFeedback>

      <SendButtonContainer>
        <SendFeebackBtn disabled={textAreaValue === ''} onClick={() => handleSendFeedback()}>
          {T.textGenerator.sendFeedback}
        </SendFeebackBtn>
      </SendButtonContainer>
    </FeedbackGenerationModalContainer>
  )
}

const FeedbackGenerationModalContainer = styled(Box)`
  background: transparent;
  position: relative;
  width: 787px;
`

const ContainerFeedback = styled(Box)`
  display: flex;
  padding: 40px 56px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 14px;
  background: ${() => AppStore.theme.o.surface};
`

const TitleFeedback = styled(Box)`
  color: ${() => AppStore.theme.o.darkGrey};
  font-family: 'Aktiv Grotesk';
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.21px;
`

const TextAreaFeedback = styled.textarea`
  display: flex;
  width: 100%;
  height: 179px;
  padding: 24px;
  gap: 10px;
  border-radius: 14px;
  border: 1px dashed ${() => AppStore.theme.o.darkGrey};
  background: ${() => AppStore.theme.o.lightGrey};
  color: ${() => AppStore.theme.o.black};
  resize: none;

  &::placeholder {
    color: ${() => AppStore.theme.o.darkGrey};
    font-family: 'Aktiv Grotesk';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;
  }
`

const SendButtonContainer = styled(Box)`
  position: absolute;
  bottom: -60px;
  width: 100%;
`

const SendFeebackBtn = styled(Button)`
  display: flex;
  min-height: 44px !important;
  padding: 12px 78px !important;
  align-items: center;
  margin: 0 auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
