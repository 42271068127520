import { Box } from 'components'
import React, { Component } from 'react'
import { OnClick } from './Lotties/OnClick'

interface Props {
  index: number
  x: number
  y: number
  eliminateIndex: any
}
interface State {
  show: boolean
}
export default class LottieAnimation extends Component<Props, State> {
  state: State = {
    show: true,
  }
  componentDidMount(): void {
    setTimeout(() => {
      this.setState({ show: false })
    }, 700)
  }
  render() {
    const { x, y, index } = this.props
    return (
      <>
        {this.state.show && (
          <Box style={{ position: 'absolute' }} ml={x - 200} mt={y - 200}>
            <Box>
              <OnClick />
            </Box>
          </Box>
        )}
      </>
    )
  }
}
