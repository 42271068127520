import styled from '@emotion/styled'
import SendAnimationLottie from 'assets/signup_send_animated_logo.json'
import Lottie from 'react-lottie'
import { Box, Text } from 'components'
import { AppStore, T, showError, showSuccess } from 'utils'
import Auth from 'api/Auth'

const bgUrl = 'https://asters-general.s3.eu-central-1.amazonaws.com/background-blurred-expired.png'

const ConfirmEmailComponent = () => {
  const correctOptions = {
    loop: true,
    autoplay: true,
    animationData: SendAnimationLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <>
      <Box pv={48} ph={45} center>
        <Box>
          <Lottie options={correctOptions} height={200} width={300} />
        </Box>
        <Box mt={12}>
          <Text style={{ justifyContent: 'center', textAlign: 'center' }} fontSize={16} weight={700}>
            {T.header.weNoticed[1]}
            <br />
            {T.header.weNoticed[2]}
          </Text>
        </Box>
        <a
          onClick={async () => {
            try {
              const done = await Auth.resendEmailConfirmation()
              if (done) showSuccess(T.success.mailSended)
              else showError(T.error.mailNotSended)
              AppStore.closeAllModal()
            } catch (e) {
              showError(e)
            }
          }}
          style={{
            cursor: 'pointer',
            color: AppStore.theme.o.black,
            fontWeight: 400,
            textDecoration: 'underline',
            fontSize: 16,
          }}
        >
          {T.header.clickHereToReceveMail}
        </a>
      </Box>
    </>
  )
}

export default ConfirmEmailComponent
