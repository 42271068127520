import { useCreatePostStore } from 'stores/CreatePostStore'
import { IPostData } from 'types/PostInterface'

export const formatTitle = () => {
  const { activePost } = useCreatePostStore.getState()

  if ('_id' in activePost && activePost._id === 'GLOBAL') return `Global Post`

  if ('variantId' in activePost) return `${activePost.info?.account.name}`
}

export const getSelectedMedias = (post?) => {
  const { activePost } = useCreatePostStore.getState()

  const workingPost = post ?? activePost

  if (workingPost === undefined) return []

  if ('_id' in workingPost && workingPost._id === 'GLOBAL') {
    return (workingPost as IPostData).medias
  }
  if ('variantId' in workingPost) {
    return workingPost.data.medias
  }

  return []
}
