// ---------------------- TYPES ----------------------
export type TSection = 'preview' | 'text' | 'media' | 'extra' | 'socials'
export type TSubsection = TSubsectionPreview | TSubsectionModels | TSubsectionMedia | TConditionalClicked
export type TSubsectionPreview = 'basic' | 'drafts' | 'templates' | 'labels'
export type TSubsectionText = 'astersAiText' | 'astersAiLink' | 'templates'
export type TSubsectionModels = 'astersAiText' | 'astersAiLink' | 'templates'
export type TConditionalClicked = 'publishPost' | 'publishPostNow'
// PAGINE SENZA SOTTO SEZIONI, ha bisogno della stringa "basic" perché la utilizzata come default nel caso non ci sia una sottosezzione es: /post/media -> /post/media/basic non è visibile ma nel codice è presente nel componente CreatePostPage.tsx a riga 13
export type TSubsectionMedia = 'basic'
export type TSubsectionExtra = 'basic'
export type TSubsectionSocial = 'basic'

// ---------------------- ENUM ----------------------
export enum ESubSectionPreview {
  basic = 0,
  drafts = 1,
  templates = 2,
  labels = 3,
}
export enum ESubSectionText {
  astersAiText = 0,
  astersAiLink = 0,
  templates = 1,
}

export enum ESubSectionModels {
  astersAiText = 0,
  astersAiLink = 1,
  templates = 2,
}
export enum EConditionalClicked {
  publishPost = 1,
  publishPostNow = 1,
}

// ---------------------- INTERFACCE ----------------------
// INTERFACCIA CHE RACCHIUDE TUTTE LE SOTTO SEZIONI CON SOTTOSEZIONI
interface ESubsectionInterface {
  preview: Record<TSubsectionPreview, ESubSectionPreview>
  text: Record<TSubsectionText, ESubSectionText>
  models: Record<TSubsectionModels, ESubSectionModels>
  media: Record<TSubsectionMedia, undefined>
  extra: Record<TSubsectionExtra, undefined>
  socials: Record<TSubsectionSocial, undefined>
  createPostClicked: Record<TConditionalClicked, EConditionalClicked>
}

// ENUM variabile che viene utilizzata per gestire in base alla sotto sezione gli elementi attivi nel componente PostNavigation.tsx -> SideBar.tsx
export const ESubsection: ESubsectionInterface = {
  preview: {
    basic: ESubSectionPreview.basic,
    drafts: ESubSectionPreview.drafts,
    templates: ESubSectionPreview.templates,
    labels: ESubSectionPreview.labels,
  },
  text: {
    astersAiText: ESubSectionText.astersAiText,
    astersAiLink: ESubSectionText.astersAiLink,
    templates: ESubSectionText.templates,
  },
  models: {
    astersAiText: ESubSectionModels.astersAiText,
    astersAiLink: ESubSectionModels.astersAiLink,
    templates: ESubSectionModels.templates,
  },
  media: {
    basic: undefined,
  },
  extra: {
    basic: undefined,
  },
  socials: {
    basic: undefined,
  },
  createPostClicked: {
    publishPost: EConditionalClicked.publishPost,
    publishPostNow: EConditionalClicked.publishPostNow,
  },
}
