import styled from '@emotion/styled'
import React from 'react'
import { AppStore } from 'utils'
import { Box, BoxProps } from './Box'

export const Card = (props: BoxProps) => {
  const { children, ...rest } = props
  return <CardStyled {...rest}>{children}</CardStyled>
}

const CardStyled = styled(Box)`
  border-radius: 14px;
  box-shadow: ${() => AppStore.theme.mainShadow};
  background-color: ${() => AppStore.theme.o.background};
`
