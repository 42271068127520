/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { AppStore, T } from 'utils'
import { PreviewMetadataItem } from './PreviewMetadataItem'
import { IPostVariant } from 'types/PostInterface'
import { useEffect, useState } from 'react'

export const onClickOpenSelectPreviewMetadataModal = (post, section) => {
  AppStore.openModal({
    id: 'preview-modal',
    body: <SelectPreviewMetadataModal post={post} section={section} />,
    style: {
      minWidth: 750,
      minHeight: 750,
    },
  })
}

/* ------------------- INTERFACE */
interface Props {
  post: IPostVariant
  section: string
}

export const SelectPreviewMetadataModal = ({ post, section }: Props) => {
  /* ------------------- STATE */
  const [postState, setPostState] = useState(post)

  return (
    <SelectPreviewMetadataModalContainer scroll hideBar>
      <SelectPreviewMetadataTitle>{T.DashboardPage.select_your_preview_link}</SelectPreviewMetadataTitle>
      <SelectPreviewMetadataList scroll hideBar>
        {section === 'preview' &&
          postState?.data?.urls
            ?.filter((url) => url.metaData.ogUrl !== undefined)
            .map((url) => (
              <PreviewMetadataItem post={postState} setPostState={setPostState} url={url} section={section} />
            ))}

        {section === 'extra' &&
          postState?.data?.firstComment?.urls
            ?.filter((url) => url.metaData.ogUrl !== undefined)
            .map((url) => (
              <PreviewMetadataItem post={postState} setPostState={setPostState} url={url} section={section} />
            ))}
      </SelectPreviewMetadataList>
    </SelectPreviewMetadataModalContainer>
  )
}

const SelectPreviewMetadataModalContainer = styled(Box)`
  max-height: 750px;
  padding: 24px;
  gap: 32px;
`

const SelectPreviewMetadataTitle = styled(Box)`
  font-size: 24px;
  font-weight: 700;
  color: ${() => AppStore.theme.o.black};
  margin: 0 auto;
`

const SelectPreviewMetadataList = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: ${() => AppStore.theme.o.black};
  width: 700px;
  height: auto;
`
