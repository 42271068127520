/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { IVariantSocial } from 'types/PostInterface'
import { TIconPost } from './PreviewPostModal'
import { SocialPicture } from 'components/UI/SocialPicture'
import { AppStore } from 'utils'
import { truncateText } from 'pages/ai-discover/commonFunction'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  socialAccountInfo: IVariantSocial
  typeIcon: TIconPost
}

export const PostSocialInfo = ({ socialAccountInfo, typeIcon }: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */
  const { name, provider, picture, profileType } = socialAccountInfo

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  /* ----------------- ENUM  */
  const IconEnum = {
    STANDARD: <Icons.postIcon fill={AppStore.theme.o.black} />,
    CAROUSEL: <Icons.carouselIcon fill={AppStore.theme.o.black} />,
    VIDEO: <Icons.videoIcon fill={AppStore.theme.o.black} />,
    REEL: <Icons.reelIcon fill={AppStore.theme.o.black} />,
    SHORT: <Icons.shortStoryIcon fill={AppStore.theme.o.black} />,
    STORY: <Icons.shortStoryIcon fill={AppStore.theme.o.black} />,
    LINK: <Icons.link fill={AppStore.theme.o.black} />,
  }

  return (
    <PostSocialInfoContainer>
      {IconEnum[typeIcon]}

      <AccountCard>
        <SocialPicture picture={picture} provider={provider} dimensionPicture={25} dimensionProvider={20} />

        <AccountInfo>
          <AccountName>{truncateText(name, 50)}</AccountName>
          {profileType && <AccountType>{profileType}</AccountType>}
        </AccountInfo>
      </AccountCard>
    </PostSocialInfoContainer>
  )
}

const PostSocialInfoContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 14px;
  width: 422px;
  max-width: 442px;
`

const AccountCard = styled(Box)`
  flex: 1;
  display: flex;
  height: 38px;
  padding: 8px 16px;
  gap: 10px;
  align-self: stretch;
  align-items: center;
  flex-direction: row;

  border-radius: 14px;
  border: 1px solid ${() => AppStore.theme.o.lightGrey};
  background: ${() => AppStore.theme.o.lightestGrey};
`

const AccountInfo = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

const AccountName = styled(Box)`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${() => AppStore.theme.o.black};
`

const AccountType = styled(Box)`
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${() => AppStore.theme.o.darkGrey};
`
