import React, { useState } from 'react'
import { Box } from './Box'
import { AppStore, T } from 'utils'
import { Input } from './Input'
import styled from '@emotion/styled'
import moment from 'moment-timezone'

import { SearchOutlined } from '@ant-design/icons'

interface Props {
  setSelectedTimezone: (timezone: any) => void
  setOpenTimezone: (element?: any) => void
}

export default function SearchTimezone(props: Props) {
  const [searchText, setSearchText] = useState('')
  const options = getTimezonesWithOffsets()

  function getTimezonesWithOffsets() {
    const timezones = moment.tz.names()
    const timezonesWithOffsets = timezones.map((momentTimezone: any) => ({
      name: momentTimezone,
      value: `UTC${moment.tz(momentTimezone).format('Z')}`,
    }))

    return timezonesWithOffsets
  }

  return (
    <Box
      relative
      width={344}
      style={{
        zIndex: 9999,
        backgroundColor: AppStore.theme.o.surface,
        borderRadius: 8,
        maxHeight: 268,
      }}
      shadow={AppStore.theme.smallOuterShadow}
      scroll
      hideBar
      ph={24}
      pv={16}
    >
      <Box center width={288}>
        <Input
          variant="whiteDotted"
          style={{
            borderRadius: 14,
            height: 34,
            marginBottom: 16,
            width: 288,
          }}
          prefix={<SearchOutlined />}
          placeholder={T.teamMembers.setTimeZone}
          onChange={(e) => setSearchText(e)}
          borderColor={AppStore.theme.o.grey}
          pixelBorder={1}
          removeHover={true}
        />
      </Box>
      <Box scroll hideBar>
        {options &&
          options
            .filter((option) => option.name.toLowerCase().includes(searchText.toLowerCase()))
            .map((element) => (
              <Box
                vcenter
                width={288}
                height={41}
                onClick={() => {
                  props.setSelectedTimezone(element)
                  props.setOpenTimezone(false)
                }}
              >
                <Text>{element.name}</Text>
              </Box>
            ))}
      </Box>
    </Box>
  )
}

const Text = styled.p`
  font-family: Aktiv Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  color: ${() => AppStore.theme.o.black};
  margin-left: 12px;
`
