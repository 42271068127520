/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Tracker } from 'api/Tracker'
import { Box, Icons } from 'components'
import { useNavigate } from 'react-router-dom'
import { T } from 'utils'
import { TRACKEREVENTS, getRefUrlProperties } from 'utils/eventsTracker'
import { DashboardBtn } from './DashboardBtn'

/* ------------------- INTERFACES  */
interface IButtonConfig {
  name: string
  icon: JSX.Element
  action: () => void
}

interface Props {}

export const UpperButtonsDashboard = ({}: Props) => {
  /* --------------- ROUTER */
  const navigate = useNavigate()

  /* ----------------- METHODS  */
  const handleClickUploadFile = () => {
    Tracker.trackEvent(TRACKEREVENTS.UPLOAD_FILE_INITIATED, getRefUrlProperties())
    navigate('/media')
  }

  const handleClickAddSocial = () => {
    navigate('/settings/social')
  }

  /* ----------------- CONFIG  */
  const buttonsConfig: IButtonConfig[] = [
    {
      name: T.DashboardPage.loadFile,
      icon: <Icons.uploadFile />,
      action: handleClickUploadFile,
    },
    {
      name: T.DashboardPage.createSocial,
      icon: <Icons.addSocialAccount />,
      action: handleClickAddSocial,
    },
  ]

  return (
    <UpperButtonsDashboardContainer>
      {buttonsConfig.map((button, i) => (
        <DashboardBtn key={button.name + i} name={button.name} icon={button.icon} action={button.action} />
      ))}
    </UpperButtonsDashboardContainer>
  )
}

const UpperButtonsDashboardContainer = styled(Box)`
  flex-direction: row;
  gap: 15px;
`
