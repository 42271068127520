/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { SocialUser } from 'components/UI'
import { IPostVariant } from 'types/PostInterface'
import { FirstComment } from '../GenericExtras/FirstComment'

/* ------------------- INTERFACES  */
interface Props {
  post: IPostVariant
}

export const XExtrasContainer = ({ post }: Props) => {
  const { picture, provider, name } = post.info.account

  return (
    <XExtrasContainerContainer>
      <SocialUser picture={picture} provider={provider} name={name} />

      <ExtrasContent>
        <FirstComment post={post} />
      </ExtrasContent>
    </XExtrasContainerContainer>
  )
}

const XExtrasContainerContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 40px;
`

const ExtrasContent = styled(Box)`
  width: 100%;
  max-width: 541px;
  flex-direction: column;
  gap: 24px;
  border: none;
`
