import { IPostFilters } from 'api/Reports'
import { update } from 'commandbar/build/internal/src/middleware/generics'
import { create } from 'zustand'

interface IReportMetric {
  id: string
  filters: IPostFilters
}

interface IReportStore {
  currentReport: any
  setCurrentReport: (currentReport: any) => void

  metrics: IReportMetric[]
  setMetric: (filter: IPostFilters, identifier: string) => void
  updateMetrics: (filter: IPostFilters, identifier: string) => void
  getMetric: (identifier: string) => IPostFilters | undefined
  clearMetrics: () => void // CLEAR FILTERS
}

export const useReportStore = create<IReportStore>((set, get) => ({
  currentReport: undefined,
  setCurrentReport: (currentReport) => set({ currentReport }),

  metrics: [],
  setMetric: (filter, identifier) => {
    if (get().metrics.some((metric) => metric.id === identifier)) {
      get().updateMetrics(filter, identifier)
      return
    }
    set({ metrics: [...get().metrics, { id: identifier, filters: filter }] })
  },
  updateMetrics: (filter, identifier) => {
    const metricIndex = get().metrics.findIndex((metric) => metric.id === identifier)
    if (metricIndex !== -1) {
      const newMetrics = [...get().metrics]
      newMetrics[metricIndex].filters = filter
      set({ metrics: newMetrics })
    }
  },
  clearMetrics: () => set({ metrics: [] }),
  getMetric: (identifier) => {
    const metric = get().metrics.find((e) => e.id === identifier)
    return metric?.filters
  },
}))
