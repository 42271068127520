import styled from '@emotion/styled'
import { Box, HeaderAllButtons } from 'components'
import { ReactNode, useState } from 'react'
import { AppStore } from 'utils'
import { Banner } from './Banner'
import BetaComponent from './BetaComponent'

export interface Props {
  title?: string
  customButton?: ReactNode
}

export const Header = ({ title, customButton }: Props) => {
  const [workspaceTimezone, setWorkspaceTimezone] = useState(AppStore?.workspace?.timezone?.value ?? 'UTC+01:00')

  return (
    <HeaderContainer>
      <Box flex row vcenter justify={'space-between'} gap={24}>
        {/* ---------------------------------- TITLE */}
        <CustomTitle>
          {title} {title === 'Analytics' && <BetaComponent />}
        </CustomTitle>

        {/* ---------------------------------- BANNER */}
        <Banner />

        {/* ---------------------------------- BUTTONS */}
        <Box id="setting-buttons" vcenter justify={'flex-end'} row>
          <HeaderAllButtons title={title} />
        </Box>
      </Box>
    </HeaderContainer>
  )
}

const HeaderContainer = styled(Box)`
  z-index: 1;
`

const CustomTitle = styled(Box)`
  font-weight: bold;
  font-size: 27px;
  color: ${() => AppStore.theme.o.black};
  flex-direction: row;
  align-items: center;
  gap: 16px;
`
