import { AppStore } from 'utils'
import AstersBaseResource from './AstersBaseResource'
import { api, responseErrorCheck } from './api'
import { TCreatedFrom, TPostStatusCalendar } from 'stores/CalendarStore'
import { SocialType } from './Utils'
import { IPostData, IPostVariant, IVariantInfo, TVariantType } from 'types/PostInterface'
import { User, Workspace } from 'types'
import { TContentType } from 'stores/DraftStore'

// INTERFACE PARAMETRI
interface ILabelParam {
  type: 'and' | 'or'
  labels: string[] // id delle labels
}

interface IFilters {
  social: string[] // id dei social accounts
  status: TPostStatusCalendar // status del post (di base è "all")
  labels: ILabelParam | undefined
  createdFrom: TCreatedFrom
}

interface IFiltersDrafts {
  social: string[] // id dei social accounts
  labels: ILabelParam | undefined
  createdFrom: TCreatedFrom
  contentType: TContentType
}

interface IParamsCalendarPosts {
  startDate: Date
  endDate: Date
  filters: IFilters
}

interface IParamsCalendarDayPosts {
  date: Date
  page: number
  filters: IFilters
}

interface IParamsDrafts {
  page: number
  filters: IFiltersDrafts
}

// INTERFACE RISPOSTE
interface IResponseCalendarSocial {
  totalPost: number
  provider: SocialType
}

export interface IResponseCalendarPosts {
  date: Date
  total: number
  socials: IResponseCalendarSocial[]
}

export interface IMediaCalendar {
  _id: string
  type: 'image' | 'video'
  url: string // url del media
  videoDuration?: number // durata del video
  timestamp?: number // probabilmente da togliere
}

export interface ISocialProfile {
  _id: string
  name: string
  provider: SocialType
  profileImage: string // url immagine profilo
}

export interface IResponseCalendarDayPosts {
  _id: string
  status: TPostStatusCalendar
  text: string // testo del post
  media: IMediaCalendar[]
  metadata?: IMetadataPost // metadati del post se presenti
  socialProfile: ISocialProfile
  publishedAt: string
  type: TVariantType
}

export interface IMetadataPost {
  url: string
  imageUrl: string
  title: string
}

export interface IResponsePost {
  _id: string
  variantId: string

  data: IPostData
  info: IVariantInfo

  user: User

  workspace: Workspace

  grouper?: any

  verifyStatus: {
    type: string
    status: 'pending' | 'verified' | 'rejected' | 'verifying'
    relatedData: string
    retryHistory: string[]
  }

  publishDate: Date
  creationDate: Date
  created_at: Date
}

export default class PostV2 extends AstersBaseResource {
  static endpoint = '/Postsv2'

  /**
   * API CALL per ottenere le draft
   * @param calendarDayParam IParamsCalendarDayPosts
   * @returns IResponseCalendarDayPosts
   */
  static getDrafts(draftDayParam: IParamsDrafts) {
    return api.post<IResponseCalendarDayPosts[]>(`${this.endpoint}/getDrafts/${AppStore.getWorkspaceId()}`, {
      filters: draftDayParam.filters,
      page: draftDayParam.page,
    })
  }

  static deletePosts(postsId: string[]) {
    return api
      .delete(`${this.endpoint}/${AppStore.getWorkspaceId()}/delete`, { postsId: postsId })
      .then(responseErrorCheck)
  }

  /**
   * API CALL per aggiungere / rimuovere le label in un post
   * @param postId ID del post
   * @param labelsIds Array di ids delle labels aggiornate
   */
  static updateLabel(postId: string, labelsIds: string[]) {
    return api.put<any>(`${this.endpoint}/updateLabels/${postId}`, { labelsIds }).then(responseErrorCheck)
  }

  /**
   * API CALL per ottenere tutti i post di un mese con filtri
   * @param calendarParam IParamsCalendarPost
   * @returns IResponseCalendarPost
   */
  static getPostsWithFilters(calendarParam: IParamsCalendarPosts) {
    return api.post<IResponseCalendarPosts[]>(
      `${this.endpoint}/calendar/getPostsWithFilters/${AppStore.getWorkspaceId()}`,
      { ...calendarParam }
    )
  }

  /**
   * API CALL per ottenere i post di un giorno specifico
   * @param calendarDayParam IParamsCalendarDayPosts
   * @returns IResponseCalendarDayPosts
   */
  static getPosts(calendarDayParam: IParamsCalendarDayPosts) {
    return api.post<IResponseCalendarDayPosts[]>(`${this.endpoint}/getPosts/${AppStore.getWorkspaceId()}`, {
      date: calendarDayParam.date,
      filters: calendarDayParam.filters,
      page: calendarDayParam.page,
    })
  }

  /**
   * API CALL per ottenere un singolo post
   * @param postId ID del post
   * @returns IResponseCalendarDayPosts
   */
  static getSinglePost(postId: string) {
    return api.get<IPostVariant>(`${this.endpoint}/getSinglePost/${postId}`)
  }

  /**
   * The function `downloadImage` downloads an image from a specified URL using an API call and returns
   * a Promise with the image data.
   * @param {string} url - The `url` parameter in the `downloadImage` function is a string that
   * represents the URL from which the image will be downloaded.
   * @returns A Promise that resolves to a string is being returned.
   */
  static downloadImage(url: string): Promise<string> {
    return api.get<string>(`${this.endpoint}/downloadImage`, { url: url }).then(responseErrorCheck)
  }
}
