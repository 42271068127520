/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { UpperSectionCalendarHeader } from './Header/UpperSectionCalendarHeader'
import { LowerSectionCalendarHeader } from './Header/LowerSectionCalendarHeader'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {}

export const HeaderCalendar = ({}: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <HeaderCalendarContainer>
      <UpperSectionCalendarHeader />
      <LowerSectionCalendarHeader />
    </HeaderCalendarContainer>
  )
}

const HeaderCalendarContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  z-index: 9999;
  width: 100%;
`
