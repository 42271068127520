import { Box, Button, Text } from 'components'

interface Props {
  text: string | JSX.Element
  onClickConfirm: () => void
  onClickCancel: () => void
  confirmText: string
  cancelText: string
}
export const ConfirmModal = (props: Props) => {
  return (
    <Box width={700} height={'100%'} p={24} center>
      <Text fontSize={24} style={{ textAlign: 'center' }}>
        {props.text}
      </Text>

      <Box row gap={32} mt={24}>
        {props.cancelText !== '' && (
          <Button variant="secondary" width={200} onClick={props.onClickCancel}>
            {props.cancelText}
          </Button>
        )}
        {props.confirmText !== '' && (
          <Button variant="delete" width={200} onClick={props.onClickConfirm}>
            {props.confirmText}
          </Button>
        )}
      </Box>
    </Box>
  )
}
