export const commonToAll = {
  socials: {
    instagram: 'Instagram',
    facebook: 'Facebook',
    googleBusiness: 'Google Business',
    youtube: 'Youtube',
    tiktok: 'TikTok',
    linkedin: 'LinkedIn',
    xTwitter: 'X',
    x: 'X',
    twitter: 'X',
    pinterest: 'Pinterest',
    whatsapp: 'Whatsapp',
    telegram: 'Telegram',
  },
}
