import React, { Component } from 'react'
import horizontalAnimation from 'assets/horizontalAnimation.json'
import verticalAnimation from 'assets/second_one.json'
import Lottie from 'react-lottie'

const horizontal = {
  loop: false,
  // autoplay: true,
  animationData: verticalAnimation,

  position: -99999999,
}

const vertical = {
  loop: false,
  animationData: horizontalAnimation,
  position: -99999999,
}

export class FirstOne extends Component {
  render() {
    if (window.innerWidth > 1184) return <Lottie isClickToPauseDisabled options={horizontal} />
    return <Lottie isClickToPauseDisabled options={vertical} />
  }
}
