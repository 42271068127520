import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { Component } from 'react'
import { AppStore } from 'utils'

interface Props {
  moveCarousel: (index: number) => void
}

export default class BackwardForwardIcons extends Component<Props> {
  render() {
    const { moveCarousel } = this.props
    return (
      <>
        {/* Left arrow */}
        <Arrow
          center
          onClick={() => moveCarousel(-1)}
          style={{
            left: '-50px',
          }}
        >
          <Icons.backward
            fill={AppStore.theme.o.black}
            style={{ minWidth: 16, minHeight: 16, position: 'relative', left: '-2px' }}
          />
        </Arrow>

        {/* Right arrow */}
        <Arrow
          center
          onClick={() => moveCarousel(1)}
          style={{
            right: '-50px',
          }}
        >
          <Icons.forward
            fill={AppStore.theme.o.black}
            style={{ minWidth: 16, minHeight: 16, position: 'relative', left: '2px' }}
          />
        </Arrow>
      </>
    )
  }
}

const Arrow = styled(Box)`
  background-color: ${() => AppStore.theme.o.surface};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 1px solid ${() => AppStore.theme.o.grey};

  &:hover {
    border-color: transparent;
  }
`
