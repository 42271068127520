/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { FilterElement } from './FilterElement'
import { AppStore, T } from 'utils'
import { IFilter, TFilterType } from 'types'
import { useState } from 'react'

/* ------------------- INTERFACES  */
interface Props {
  filters: IFilter[]
  selectedFilter: TFilterType | null
  action: (key: TFilterType) => void
  data: JSX.Element[]
  oldData: JSX.Element[]
  upperElement?: JSX.Element
}

export const FilterBar = ({ filters, selectedFilter, action, data, oldData, upperElement }: Props) => {
  const [filterStates, setFilterStates] = useState<{ [key: string]: boolean }>(
    Object.fromEntries(filters.map((filter) => [filter.key, false]))
  )

  const handleFilterClick = (filterKey) => {
    setFilterStates((prevFilterStates) => {
      return Object.fromEntries(
        //Creating an array of previous filter states
        Object.entries(prevFilterStates).map(([prevKey, prevValue]) => [
          prevKey,
          prevKey === filterKey ? !prevValue : false,
        ])
      )
    })

    action(filterKey)
  }

  return (
    <FilterBarContainer>
      {upperElement && upperElement}

      {filters.map((filter, i) => {
        return (
          <FilterElement
            key={filter.key + i}
            filter={filter}
            isExpanded={filterStates[filter.key]}
            isSelected={selectedFilter === filter.key}
            action={() => handleFilterClick(filter.key)}
            oldData={oldData}
            data={data}
            noResultMessage={T.analytics.noReports}
          />
        )
      })}
    </FilterBarContainer>
  )
}

const FilterBarContainer = styled(Box)`
  padding-right: 13px;
  overflow-y: scroll;
  flex: 1;
  gap: 16px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${() => AppStore.theme.o.grey};
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`
