/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { IResponseCalendarPosts } from 'api/PostV2'
import { getDayName, getPostsByDate } from 'api/Utils'
import { Box, Button, Icons, Text } from 'components'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useCalendarStore } from 'stores/CalendarStore'
import { AppStore, T } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {}

export const SpecificDayHeader = ({}: Props) => {
  /* ----------------- ROUTER  */
  const navigate = useNavigate()

  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */
  const { year, month, day, calendarPost, postOfDay } = useCalendarStore((s) => ({
    year: s.year,
    month: s.month,
    day: s.day,
    calendarPost: s.calendarPost,
    postOfDay: s.dayPost.length,
  }))

  const { setCalendarSection } = useCalendarStore()
  /* ----------------- VARIABLES  */
  const momentDate: moment.Moment = moment(new Date(year, month, day))

  const postsOfToday: IResponseCalendarPosts = getPostsByDate(momentDate, calendarPost)

  /* ----------------- METHODS  */
  const handleChangeSection = () => {
    // setCalendarSection('month')
    navigate(`/calendar?view=month&date=${year}-${month + 1}`)
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <SpecificDayHeaderContainer>
      <GoBackButton variant="neutral" onClick={handleChangeSection}>
        <Icons.dropdownArrow width={24} height={24} fill={AppStore.theme.o.black} style={{ rotate: '270deg' }} />
      </GoBackButton>

      <Text weight={500} fontSize={16}>
        {getDayName(day, month, year)} {day}
      </Text>

      <Line />

      <TotalPosts>
        <Text>
          {postOfDay ? postOfDay : 0} {T.sidebarNavigation.post}
        </Text>
      </TotalPosts>
    </SpecificDayHeaderContainer>
  )
}

const SpecificDayHeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  flex-direction: row;
  height: 32px;
`

const GoBackButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px !important;
  height: 32px !important;
  min-height: 32px !important;
  padding: 8px !important;
`

const Line = styled(Box)`
  flex: 1;
  height: 1px;
  background-color: #d9d9d9;
`

const TotalPosts = styled(Box)`
  display: flex;
  padding: 4px 16px;
  align-items: center;
  align-self: stretch;
  background-color: ${() => AppStore.theme.o.lightestGrey};
`
