/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { AppStore } from 'utils'

/* ------------------- INTERFACES  */
interface Props {
  showSidebar?: boolean
  header?: React.ReactNode
  content?: React.ReactNode
  mt?: string | number
  styles?: React.CSSProperties
}
export const SidebarStructure = ({ showSidebar = true, header, content, mt, styles }: Props) => {
  return (
    <>
      {showSidebar && (
        <SidebarStructureContainer
          style={{
            marginTop: mt ? mt : 0,
            ...styles,
          }}
          className="sidebar_structure"
        >
          {header && header}
          {content && content}
        </SidebarStructureContainer>
      )}

      {!showSidebar && <HideSidebarStructureContainer className="hide_sidebar_structure" />}
    </>
  )
}

const SidebarStructureContainer = styled(Box)`
  &.sidebar_structure {
    max-width: 258px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 32px 13px 32px 24px;
    border-radius: 14px;
    border: 1px solid ${() => AppStore.theme.o.lightGrey};
    background-color: ${() => AppStore.theme.o.background};
    color: ${() => AppStore.theme.o.black};
    flex: 1;
    overflow: hidden;
    box-shadow: ${() => AppStore.theme.mainShadow};
  }
`

const HideSidebarStructureContainer = styled(Box)`
  &.hide_sidebar_structure {
    width: 258px;
  }
`
