import styled from '@emotion/styled'
import { Box, Button } from 'components'
import { Icons } from 'components/Icons'
import { Text } from 'components/Text'
import { Component } from 'react'
import { AppStore } from 'utils'

interface Props {
  textSelected: any
  imgSelected: string
  paramToPass: any
}

export default class FifthPage extends Component<Props> {
  textRrender = (e) => {
    if (e === '') {
      return <br />
    }
    return <span> {e}</span>
  }
  render() {
    return (
      //

      <Container>
        <InnerConteiner>
          <MiniBox center>
            <Text style={{ textAlign: 'center' }} weight={500} fontSize={16}>
              Wow! 🎉 <br /> Ecco il tuo post di qualità
              <br /> pronto in pochi semplici passaggi,
              <br /> senza stress 😎
            </Text>
          </MiniBox>
          <PostConteiner ph={37}>
            <Box row mb={13}>
              <Icons.logo width={32} height={32} />
              <Box ml={8}>
                <Text
                  style={{
                    fontFamily: 'Lexend',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: 10.4362,

                    color: '#2E3A59',
                  }}
                >
                  Asters
                </Text>
                <Text
                  style={{
                    fontFamily: 'Lexend',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: 10.4362,

                    color: '#2E3A59',
                  }}
                >
                  Verona
                </Text>
              </Box>
            </Box>
            <Box mb={17}>
              <ImgConteiner>
                <img
                  src={`https://asters.s3.eu-central-1.amazonaws.com/demo/${this.props.imgSelected}`}
                  style={{ width: 250, height: 250, objectFit: 'cover', borderRadius: 8 }}
                ></img>
              </ImgConteiner>
            </Box>
            <Box row style={{ justifyContent: 'space-between' }}>
              <Icons.istagramPrimaryAction fill={AppStore.theme.o.black} />
              <Icons.bookMark fill={AppStore.theme.o.black} />
            </Box>
            <Box mt={16} width={'25vw'} style={{ borderTopRightRadius: 0, borderBottomLeftRadius: 0, minWidth: 270 }}>
              <PostTextConteiner>
                {this.props.textSelected.map((e2) => (
                  <p>{this.textRrender(e2)}</p>
                ))}
              </PostTextConteiner>
            </Box>
          </PostConteiner>
          <ButtonIscriviti
            onClick={() => {
              // navigate('dashborddemo')
              ;/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
                ? window.open('https://lp.asters.ai/sito/registrazione' + this.props.paramToPass)
                : window.open('https://beta.asters.ai/' + this.props.paramToPass)
            }}
          >
            <Text
              style={{
                fontSize: 16,
                fontWeight: 700,
                color: AppStore.theme.o.surface,
              }}
            >
              Iscriviti ad Asters
            </Text>
          </ButtonIscriviti>
          <Box height={'15vh'} />
        </InnerConteiner>
      </Container>
    )
  }
}

const Container = styled(Box)`
  width: 100%;
  /* z-index: 999999; */
  height: 90vh;
`

const InnerConteiner = styled(Box)`
  width: 200vw;
  height: 90vh;

  min-width: 325px;
  min-height: 200px;

  max-height: 90vh;
  max-width: 300px;

  padding-right: 4px; /* Increase/decrease this value for cross-browser compatibility */
`

const ButtonIscriviti = styled(Button)`
  margin-top: 16px;
  max-width: 355px;
  align-items: center;
  padding: 16px;
  background: #18818b;
  box-shadow: 4px 8px 16px rgba(213, 214, 218, 0.14);
  border-radius: 14px;
`

const MiniBox = styled(Box)`
  padding: 24px;
  gap: 8px;
  background: #ffffff;
  box-shadow: 4px 8px 16px rgba(246, 246, 246, 0.14);
  border-radius: 14px;
  margin-bottom: 14px;
`

const PostTextConteiner = styled(Text)`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 140%;
  max-width: 260px;
  /* text-overflow: 'ellipsis'; */
  color: #a3a1aa;
`

const ImgConteiner = styled(Box)`
  border-radius: 12.1755px;
  flex: none;
`

const PostConteiner = styled(Box)`
  background: #ffffff;
  box-shadow: 4px 8px 16px rgba(246, 246, 246, 0.14);
  border-radius: 14px;
  padding: 37px;
  margin-bottom: 8px;
`
