
import { MixpanelCreditsPage, MixpanelGeneratedCaption, MixpanelAiCaptionFeedback } from './aiSectionInterfaces'

export const getAiCaptionGeneratedData = (data: MixpanelGeneratedCaption): MixpanelGeneratedCaption => {
  return {
    ai_caption_source_type: data.ai_caption_source_type,
    ai_caption_source: data.ai_caption_source,
    ai_caption_lenght: data.ai_caption_lenght,
    ai_caption_goal: data.ai_caption_goal,
    ai_caption_tone_of_voice: data.ai_caption_tone_of_voice,
    ai_caption_input_language: data.ai_caption_input_language,
    ai_caption_output_language: data.ai_caption_output_language,
    ai_caption_brand_voice: data.ai_caption_brand_voice,
  }
}

export const getAiCreditsPageViewedData = (data: MixpanelCreditsPage): MixpanelCreditsPage => {
  return {
    initial_referrer: data.initial_referrer,
    product_name: data.product_name,
    product_ai_captions_quantity: data.product_ai_captions_quantity,
    product_ai_images_quantity: data.product_ai_images_quantity,
    subtotal: data.subtotal,
    total: data.total,
  }
}

export const getAiCreditsPurchaseSuccessful = (data: MixpanelCreditsPage): MixpanelCreditsPage => {
  return {
    initial_referrer: data.initial_referrer,
    product_name: data.product_name,
    product_id: data.product_id,
    price_id: data.price_id,
    vat: data.vat,
    currency: data.currency,
    product_ai_captions_quantity: data.product_ai_captions_quantity,
    product_ai_images_quantity: data.product_ai_images_quantity,
    subtotal: data.subtotal,
    total: data.total,
    coupon: data.coupon,
    num_invoice: data.num_invoice,
  }
}

export const getAiCreditsPurchaseFailed = (data: MixpanelCreditsPage): MixpanelCreditsPage => {
  return {
    initial_referrer: data.initial_referrer,
    product_name: data.product_name,
    product_id: data.product_id,
    price_id: data.price_id,
    vat: data.vat,
    currency: data.currency,
    product_ai_captions_quantity: data.product_ai_captions_quantity,
    product_ai_images_quantity: data.product_ai_images_quantity,
    subtotal: data.subtotal,
    total: data.total,
    coupon: data.coupon,
    error_message: data.error_message,
  }
}

export const getAiCaptionFeedback = (data: MixpanelAiCaptionFeedback): MixpanelAiCaptionFeedback => {
  return {
    ai_caption_feedback_type: data.ai_caption_feedback_type,
    ai_caption_feedback_note: data.ai_caption_feedback_note,
  }
}
