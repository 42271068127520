const es = {
  title: '[SPA] ASTERS',

  misc: {},
  error: {
    user_not_valid: 'Utente non valido',
    password_not_valid: 'Password non valida',
    user_or_password_wrong: 'Utente o password errati',
  },
  messages: {},
  placeholder: {},
  confirm: {},
  labels: {},
}

export default es
