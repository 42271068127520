import { DashboardPage } from 'pages'

const DashboardRouter = [
  {
    path: '/dashboard',
    element: <DashboardPage />,
  },
]

export { DashboardRouter }
