/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { AppStore } from 'utils'

/* ------------------- INTERFACES  */
interface Props {
  text: string
  styles?: React.CSSProperties
}

export const ExtraTitle = ({ text, styles }: Props) => {
  return (
    <ExtraTitleContainer className="extratitle" style={styles ?? {}}>
      {text}
    </ExtraTitleContainer>
  )
}

const ExtraTitleContainer = styled(Box)`
  color: ${() => AppStore.theme.o.blue};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
