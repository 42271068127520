import { create } from 'zustand'

interface ModalNavigationStore {
  navigatePage: string
  setNavigatePage: (navigatePage: string) => void
}

export const useModalNavigationStore = create<ModalNavigationStore>((set, get) => ({
  navigatePage: '',

  /**
   * Funzione che ci permette di navigare all'interno di una modale
   * @param navigatePage path di dove si vuole navigate , es "/post/preview/basic"
   */
  setNavigatePage: (navigatePage) => {
    set(() => ({ navigatePage }))
  },
}))
