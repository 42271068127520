/* ******************* IMPORT ******************* */
import styled from '@emotion/styled'
import Utils from 'api/Utils'
import { Box } from 'components'
import { AppStore } from 'utils'

/* ******************* INTERFACE ******************* */

interface Props {
  carouselType: string
}

const darkMode = localStorage.getItem('darkTheme')

let gradientColor = ''

if (darkMode === 'true') {
  gradientColor = `linear-gradient(
    120deg,
    hsla(0, 0%, 22%, 0.6) 20%,
    hsl(0, 0%, 22%) 38%,
    hsl(0, 0%, 22%) 40%,
    hsla(0, 0%, 22%, 0.6) 80%
  )`
}

if (darkMode === 'false') {
  gradientColor = `linear-gradient(
    120deg,
    hsl(0, 0%, 90%) 20%,
    hsl(0, 0%, 93%) 38%,
    hsl(0, 0%, 93%) 40%,
    hsl(0, 0%, 90%) 80%
  )`
}

const SkeletonCarousel = ({ carouselType }: Props) => {
  return (
    <SkeletonCarouselContainer>
      <SkeletonTitle />
      <SkeletonCarouselContent className={`skeleton_${carouselType}`}>
        {carouselType === 'Topics' ? (
          <>
            <SkeletonArticle key={Utils.generateRandomUIDKey()} />
            <SkeletonArticle key={Utils.generateRandomUIDKey()} />
            <SkeletonArticle key={Utils.generateRandomUIDKey()} />
          </>
        ) : (
          <>
            <SkeletonBoard key={Utils.generateRandomUIDKey()} />
            <SkeletonBoard key={Utils.generateRandomUIDKey()} />
            <SkeletonBoard key={Utils.generateRandomUIDKey()} />
          </>
        )}
      </SkeletonCarouselContent>

      <SkeletonContainerButtons>
        {/* {carouselType === 'Topics' && <SkeletonRefreshButton />} */}
        <SkeletonShowMoreButton />
      </SkeletonContainerButtons>
    </SkeletonCarouselContainer>
  )
}

export default SkeletonCarousel

const SkeletonCarouselContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const SkeletonTitle = styled(Box)`
  width: 158px;
  height: 40px;
  border-radius: 15px;
  background-color: ${() => AppStore.theme.o.lightGrey};
`

const SkeletonCarouselContent = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 32px;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 8px;
  border-radius: 24px;
  background: ${() => AppStore.theme.o.lightestGrey};
  height: 471px;
  width: 1180px;

  &.skeleton_Topics {
    height: 471px;
  }

  &.skeleton_Boards {
    height: 360px;
  }
`

const SkeletonArticle = styled(Box)`
  width: 351px;
  height: 423px;
  background-color: ${() => AppStore.theme.o.lightGrey};
  border-radius: 24px;

  background: ${gradientColor};

  background-size: 200% 100%;
  background-position: 100% 0;
  animation: loading-skeleton 1.5s infinite;
`

const SkeletonBoard = styled(Box)`
  width: 356px;
  height: 312px;
  background-color: ${() => AppStore.theme.o.lightGrey};
  border-radius: 24px;

  background: ${gradientColor};

  background-size: 200% 100%;
  background-position: 100% 0;
  animation: loading-skeleton 1.5s infinite;
`

const SkeletonContainerButtons = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-left: auto;
  height: 40px;
`

// const SkeletonRefreshButton = styled(Box)`
//   width: 180px;
//   height: 30px;
//   border-radius: 15px;
//   background-color: ${() => AppStore.theme.o.lightGrey};
// `

const SkeletonShowMoreButton = styled(Box)`
  width: 180px;
  height: 30px;
  border-radius: 15px;
  background-color: ${() => AppStore.theme.o.lightGrey};
`
