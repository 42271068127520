import styled from '@emotion/styled'
import { lightTheme } from 'config/themes'
import CSS from 'csstype'
import { ReactNode, useState } from 'react'
import { AppStore, parseSize } from 'utils'
import { Box } from './Box'
import { Icons } from './Icons'
import { Spacer } from './Spacer'

export type VariantsType =
  //----------- bottoni v1 NEW ----------------
  | 'primary'
  | 'primary-small'
  | 'secondary'
  | 'secondary-revert'
  | 'secondary-shadow'
  | 'secondary-small'
  | 'neutral'
  | 'neutral-shadow'
  | 'delete'
  | 'delete-revert'
  | 'delete-small'
  | 'unselected-billing'
  | 'selected-billing'

export type SizeBtn = 'smaller' | 'small' | 'medium' | 'large'
export interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  children: ReactNode
  disabled?: boolean
  onClick?: (e?: any) => void
  loading?: boolean
  preventLoading?: boolean // evade the show of the spinner logo
  variant?: VariantsType
  size?: SizeBtn
  fullWidth?: boolean
  ref?: React.MutableRefObject<any>
  flex?: number | boolean | string
  row?: boolean
  center?: boolean
  vcenter?: boolean
  hcenter?: boolean
  justify?: CSS.Property.JustifyContent
  m?: number | string // margin
  mv?: number | string // margin vertical
  mh?: number | string // margin horizontal
  mt?: number | string // margin top
  mb?: number | string // margin bottom
  mr?: number | string // margin right
  ml?: number | string // margin left
  p?: number | string // padding
  pv?: number | string // padding vertical
  ph?: number | string // padding horizontal
  pt?: number | string // padding top
  pb?: number | string // padding bottom
  pr?: number | string // padding right
  pl?: number | string // padding left
  bgGrey?: boolean
  height?: string | number
  width?: string | number
  hideBar?: boolean
  animation?: 'fade' | 'slide'
  id?: any
  forwardedRef?: React.MutableRefObject<any> | null
  overflow?: CSS.Property.Overflow
  shadow?: CSS.Property.BoxShadow
  borderRadius?: number
  hideBoxShadowOnHover?: boolean
  hideBoxShadow?: boolean
  leftIcon?: any
  rightIcon?: any
  selected?: boolean
}

/**This is main Button!!*/
export const Button = ({
  flex,
  children,
  loading,
  preventLoading,
  disabled = false,
  variant,
  size,
  onClick,
  leftIcon,
  rightIcon,
  ...props
}: ButtonProps) => {
  const [internalLoading, setinternalLoading] = useState(false)

  const onPrePress = async (e?: any) => {
    try {
      if (internalLoading || loading) return
      if (!onClick) return

      try {
        setinternalLoading(true)
        await onClick(e)
        setinternalLoading(false)
      } catch (error) {
        //ignore
      }
    } catch (error) {}
    setinternalLoading(false)
  }

  const load = preventLoading ? false : internalLoading || loading

  return (
    <Container
      hcenter={leftIcon ? false : true}
      vcenter
      size={size}
      variant={variant}
      row
      ph={48}
      onClick={onPrePress}
      disabled={disabled}
      flex={flex}
      {...props}
    >
      {leftIcon}
      {!!leftIcon && <Spacer width={'8px'} />}

      {load && <div style={{ display: 'none' }}>{children}</div>}
      {!load && children}
      {load && (
        <AnimationRotatedLogo spinTime={'1.5s'} center>
          <Icons.logo style={{ height: 18 }} />
        </AnimationRotatedLogo>
      )}

      {!!rightIcon && (
        <Box flex style={{ alignItems: 'flex-end' }}>
          {rightIcon}
        </Box>
      )}
    </Container>
  )
}

const Container = styled.button<ButtonProps>`
  ${(props) => (props.flex === true ? `width: 100%;` : !!props.flex && `flex: ${props.flex};`)}
  display: flex;
  border-color: transparent;
  border-radius: 14px;
  line-height: 0px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  box-shadow: ${() => AppStore.theme.simpleOuterShadow};
  ${({ disabled, variant = 'primary' }) => {
    switch (variant) {
      //----------- bottoni v1 NEW ----------------

      case 'primary':
        return `background-color: ${AppStore.theme.o.primary_container};   color: ${AppStore.theme.o.on_primary_container}; border: .5px solid ${AppStore.theme.o.on_primary_container}; box-shadow:none `

      case 'primary-small':
        return `background-color: ${AppStore.theme.o.primary_container};   color: ${AppStore.theme.o.on_primary_container}; border: .5px solid transparent; box-shadow:none `

      case 'secondary':
        return `background-color: ${AppStore.theme.o.secondary_container};   color: ${AppStore.theme.o.on_secondary_container}; border: .5px solid ${AppStore.theme.o.on_secondary_container}; box-shadow:none `

      case 'secondary-revert':
        return `background-color: ${AppStore.theme.o.secondary_container};   color: ${AppStore.theme.o.on_secondary_container}; border: .5px solid transparent; box-shadow:none `

      case 'secondary-shadow':
        return `background-color: ${AppStore.theme.o.secondary_container};   color: ${AppStore.theme.o.on_secondary_container}; border: .5px solid ${AppStore.theme.o.on_secondary_container}; box-shadow: ${AppStore.theme.bigOuterShadow} `

      case 'secondary-small':
        return `background-color: ${AppStore.theme.o.secondary_container};   color: ${AppStore.theme.o.on_secondary_container}; border: .5px solid transparent; box-shadow:none `

      case 'neutral':
        return `background-color: ${AppStore.theme.o.lightestGrey};   color: ${
          disabled ? AppStore.theme.o.darkGrey : AppStore.theme.o.black
        }; border: .5px solid ${AppStore.theme.o.lightGrey}; box-shadow:none`

      case 'neutral-shadow':
        return `background-color: ${AppStore.theme.o.lightestGrey};   color: ${
          disabled ? AppStore.theme.o.darkGrey : AppStore.theme.o.black
        }; border: .5px solid ${AppStore.theme.o.lightGrey}; box-shadow: ${AppStore.theme.bigOuterShadow} `

      case 'delete':
        return `background-color: ${AppStore.theme.o.error_container}; color: ${AppStore.theme.o.on_error_container}; border: .5px solid ${AppStore.theme.o.on_error_container} ; box-shadow:none `

      case 'delete-revert':
        return `background-color: ${AppStore.theme.o.error_container}; color: ${AppStore.theme.o.on_error_container}; border: .5px solid transparent ;  box-shadow:none`

      case 'delete-small':
        return `background-color: ${AppStore.theme.o.error_container}; color: ${AppStore.theme.o.on_error_container}; border: .5px solid transparent; box-shadow: none`

      // ---- customs ---
      case 'unselected-billing':
        return `background-color: ${lightTheme.o.primary_container};   color: ${lightTheme.o.blue}; border: .5px solid ${lightTheme.o.blue}; box-shadow:none `

      case 'selected-billing':
        return `background-color: ${lightTheme.o.primary} !important;   color: ${lightTheme.o.primary_container} !important; border: .5px solid ${lightTheme.o.primary_container} !important; box-shadow:none `

      default:
        return `background-color: ${AppStore.theme.o.surface}; color: ${AppStore.theme.o.white}; box-shadow:none`
    }
  }};

  ${({ size = 'medium' }) => {
    switch (size) {
      case 'smaller':
        return `font-size: 14px;font-weight: bold;min-height: 32px;`
      case 'small':
        return `font-size: 16px;font-weight: bold;min-height: 40px;`
      case 'medium':
        return `font-size: 21px;font-weight: bold;min-height: 40px;`
      case 'large':
        return `font-size: 26px;font-weight: bold;min-height: 56px;`
      default:
        return `font-size: 21px;font-weight: bold;min-height: 40px;`
    }
  }}

  &:hover {
    ${({ disabled, variant = 'primary' }) => {
      switch (variant) {
        //----------- bottoni v1 NEW ----------------

        case 'primary':
          return `background-color: ${AppStore.theme.o.primary_container};  color: ${() =>
            AppStore.theme.o.on_primary_container}; border: .5px solid transparent; box-shadow:none`

        case 'primary-small':
          return `background-color: ${AppStore.theme.o.primary_container}${AppStore.theme.opacityBtn.opPrimary}; color: ${AppStore.theme.o.on_primary_container}; border: .5px solid ${AppStore.theme.o.on_primary_container}; box-shadow:none `

        case 'secondary':
          return `background-color: ${AppStore.theme.o.secondary_container}; color: ${AppStore.theme.o.on_secondary_container}; border: .5px solid transparent; box-shadow:none  `

        case 'secondary-revert':
          return `background-color: ${AppStore.theme.o.secondary_container};   color: ${AppStore.theme.o.on_secondary_container}; border: .5px solid ${AppStore.theme.o.on_secondary_container}; box-shadow:none `

        case 'secondary-shadow':
          return `background-color: ${AppStore.theme.o.secondary_container};   color: ${AppStore.theme.o.on_secondary_container}; border: .5px solid transparent; box-shadow: none `

        case 'secondary-small':
          return `background-color: ${AppStore.theme.o.secondary_container}${AppStore.theme.opacityBtn.opSecondary}; color: ${AppStore.theme.o.on_secondary_container}; border: .5px solid ${AppStore.theme.o.on_secondary_container} ; box-shadow:none `

        case 'neutral':
          return `background-color: ${AppStore.theme.o.lightestGrey};   color: ${
            disabled ? AppStore.theme.o.darkGrey : AppStore.theme.o.black
          }; border: .5px solid ${disabled ? AppStore.theme.o.lightGrey : 'transparent'}; box-shadow:none `

        case 'neutral-shadow':
          return `background-color: ${AppStore.theme.o.lightestGrey};   color: ${
            disabled ? AppStore.theme.o.darkGrey : AppStore.theme.o.black
          }; border: .5px solid ${disabled ? AppStore.theme.o.lightGrey : 'transparent'}; box-shadow:none `

        case 'delete':
          return `background-color: ${AppStore.theme.o.error_container}; color: ${AppStore.theme.o.on_error_container}; border: .5px solid transparent ;  box-shadow:none`

        case 'delete-revert':
          return `background-color: ${AppStore.theme.o.error_container}; color: ${AppStore.theme.o.on_error_container}; border: .5px solid ${AppStore.theme.o.on_error_container} ;  box-shadow:none`

        case 'delete-small':
          return `background-color: ${AppStore.theme.o.error_container}${AppStore.theme.opacityBtn.opSecondary}; color: ${AppStore.theme.o.on_error_container}; border: .5px solid ${AppStore.theme.o.on_error_container}; box-shadow: none`

        // ---- customs ---

        case 'unselected-billing':
          return `background-color: ${lightTheme.o.blue};   color: ${lightTheme.o.white}; border: .5px solid ${lightTheme.o.white}; box-shadow:none `

        case 'selected-billing':
          return `background-color: ${lightTheme.o.primary_container};   color: ${lightTheme.o.blue}; border: .5px solid ${lightTheme.o.blue}; box-shadow:none `

        default:
          return `background-color: ${AppStore.theme.o.surface}; color: ${AppStore.theme.o.white};  box-shadow:none `
      }
    }}
  }

  transition: 0.5s;
  ${({ hcenter, row }) => hcenter && (row ? 'justify-content:center;' : 'align-items:center;')}
  ${({ vcenter, row }) => vcenter && (row ? 'align-items:center;' : 'justify-content:center;')}
   ${({ center }) => center && 'justify-content:center; align-items:center;'}
   ${({ justify }) => justify && `justify-content:${justify};`}
   ${({ mv }) =>
    mv !== undefined && `margin-top: ${parseSize(mv)} !important; margin-bottom: ${parseSize(mv)} !important;`}
   ${({ mh }) =>
    mh !== undefined && `margin-left: ${parseSize(mh)} !important; margin-right: ${parseSize(mh)} !important;`}
   ${({ mt }) => mt !== undefined && `margin-top: ${parseSize(mt)} !important;`}
   ${({ mb }) => mb !== undefined && `margin-bottom: ${parseSize(mb)} !important;`}
   ${({ mr }) => mr !== undefined && `margin-right: ${parseSize(mr)} !important;`}
   ${({ ml }) => ml !== undefined && `margin-left: ${parseSize(ml)} !important;`}
   ${({ m }) => m !== undefined && `margin: ${parseSize(m)};`}
   ${({ pv }) =>
    pv !== undefined && `padding-top: ${parseSize(pv)} !important; padding-bottom: ${parseSize(pv)} !important;`}
   ${({ ph }) =>
    ph !== undefined && `padding-left: ${parseSize(ph)} !important; padding-right: ${parseSize(ph)} !important;`}
   ${({ pt }) => pt !== undefined && `padding-top: ${parseSize(pt)} !important;`}
   ${({ pb }) => pb !== undefined && `padding-bottom: ${parseSize(pb)} !important;`}
   ${({ pr }) => pr !== undefined && `padding-right: ${parseSize(pr)} !important;`}
   ${({ pl }) => pl !== undefined && `padding-left: ${parseSize(pl)} !important;`}
   ${({ p }) => p !== undefined && `padding: ${parseSize(p)};`}
   ${({ bgGrey, theme }) => bgGrey && `background-color: ${() => AppStore.theme.o.background};`}
   ${({ height }) => height !== undefined && `height: ${typeof height === 'number' ? `${height}px` : height};`}
   ${({ width }) => width !== undefined && `width: ${typeof width === 'number' ? `${width}px` : width};`}
   ${({ width }) => width !== undefined && `min-width: ${typeof width === 'number' ? `${width}px` : width};`}
   //*${({ onClick, hideBoxShadow }) => !!onClick && !hideBoxShadow && AppStore.theme.boxHoverStyle}*//
   ${({ overflow }) => overflow && `overflow: ${overflow};`}
   ${({ shadow }) => shadow && `box-shadow: ${shadow};`}
   transition: all 0.3s ease-in-out;
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}px;`}
  ${({ hideBoxShadowOnHover }) => hideBoxShadowOnHover && `&:hover { box-shadow: none; }`}
  ${({ hideBoxShadow }) => hideBoxShadow && `box-shadow: none;`}
`

const AnimationRotatedLogo = styled(Box)<{ spinTime: string }>`
  animation: spin ${({ spinTime }) => spinTime} linear infinite;
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`
