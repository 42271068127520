import { Text } from 'components/Text'

import { Component } from 'react'
import { AppStore } from 'utils'

interface Props {
  language: any
  selected: boolean
  onClickLanguage: any
}
interface State {
  resfresh: boolean
}

export class LanguageButton extends Component<Props, State> {
  state: State = {
    resfresh: false,
  }

  render() {
    return (
      <>
        <Text
          style={{
            fontSize: 16,
            fontWeight: this.props.selected ? 700 : 400,
            color: this.props.selected ? AppStore.theme.o.black : AppStore.theme.o.darkGrey,
          }}
          onClick={() => {
            this.props.onClickLanguage([this.props.language.traslationValue, this.props.language.language])
            AppStore.closeModal('language-modal')
          }}
        >
          {this.props.language.language}
        </Text>
      </>
    )
  }
}
