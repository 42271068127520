import { Box, Button, Text } from 'components'
import { AppStore, T } from 'utils'
import FilterModal from './FilterModal'
import { MediaSortFilter } from './MediaSortFilter'

interface Props {
  search: string
  medias: any
  getMediaName: any
  onChangeSelect: (index: number) => void
  sortTypes: any
  filters: [{ item: string }] | undefined
  setFilters: (value: [{ item: string }]) => void
}

export default function MediaSort(props: Props) {
  const { search, medias, getMediaName, onChangeSelect, sortTypes, filters, setFilters } = props

  const openFilterModal = () => {
    AppStore.openModal({
      id: 'preview-modal',
      body: <FilterModal filters={filters} setFilters={setFilters} />,
      style: { minWidth: 680 },
    })
  }

  return (
    <>
      {medias.filter((m) => m.type === 'file' && getMediaName(m.name).includes(search)).length > 0 && (
        <Box style={{ width: 'auto' }} row vcenter gap={16}>
          {/* TITLE */}
          <Box flex>
            <Text style={{ padding: 10 }} fontSize={21} weight={700}>
              {T.mediaPage.file}
            </Text>
          </Box>

          {/* SORT */}
          <MediaSortFilter sortTypes={sortTypes} onChangeSelect={onChangeSelect} />

          {/* ADD FILTERS */}
          <Button
            variant="neutral"
            size="small"
            onClick={() => {
              openFilterModal()
            }}
          >
            {T.mediaPage.addFilters}
          </Button>
        </Box>
      )}
    </>
  )
}
