import {
  ContentContainerStructure,
  ContentFooterStructure,
  ContentHeaderStructure,
  ContentStructure,
} from 'components/UI'
import { useEffect, useState } from 'react'
import { useAnalyticsStore } from 'stores/AnalyticsStore'
import {
  IDataReport,
  IReport,
  IReportConfig,
  TActiveReportFilter,
  TActiveReportList,
  TSection,
  TSubsection,
} from 'types/AnalyticsInferfaces'
import { getReportList } from 'utils/AnalyticsUtils'
import { CreateReport } from './CreateReport/CreateReport'
import { CreateReportFooter } from './CreateReport/CreateReportFooter'
import { CreateReportHeader } from './CreateReport/CreateReportHeader'
import { ContentDashboardAnalytics } from './DashboardAnalytics/ContentDashboardAnalytics'
import { FooterDashboardAnalytics } from './DashboardAnalytics/FooterDashboardAnalytics'
import { HeaderDashboardAnalytics } from './DashboardAnalytics/HeaderDashboardAnalytics'
import { PreviewReport } from './PreviewReport/PreviewReport'
import { PreviewReportHeader } from './PreviewReport/PreviewReportHeader'

/* ------------------- INTERFACES  */
interface Props {
  section: TSection
  subsection: TSubsection
}

export const AnalyticsContent = ({ section, subsection }: Props) => {
  /* ------------------- ZUSTAND  */
  const { setFilteredReportList, setReportList, setActiveReportList, setActiveReportFilter } =
    useAnalyticsStore.getState()

  /* ------------------- STATE  */
  const [showContentFooter, setShowContentFooter] = useState<boolean>(false)
  const [reportIdSelected, setReportIdSelected] = useState<string[]>([])
  const [reportConfig, setReportConfig] = useState<IReportConfig>({
    info: {},
    data: [],
  })

  const { reportsList, reportListActive, reportListFiltered, activeReportsFilter } = useAnalyticsStore((state) => ({
    reportsList: state.reportList,
    reportListActive: state.activeReportList,
    reportListFiltered: state.filteredReportList,
    activeReportsFilter: state.activeReportFilter,
  }))

  // const {setReportsList, setActiveReportList, setFilteredReportList} = useAnalyticsStore((state) => ({
  //   setReportList: state.setReportsList,
  //   setActiveReportList: state.setActiveReportList,
  //   setFilteredReportList: state.setFilteredReportList,
  // }))

  // const [reportsList, setReportsList] = useState<IReport[] | null>(null)
  // const [reportListActive, setReportListActive] = useState<TActiveReportList | null>(null)
  // const [reportListFiltered, setReportListFiltered] = useState<IReport[] | null>(null)
  // const [activeReportsFilter, setActiveReportsFilter] = useState<TActiveReportFilter | null>(null)

  const { reportPreview } = useAnalyticsStore((state) => ({
    reportPreview: state.reportPreview,
  }))

  // /* ------------------- ZUSTAND SUBSCRIPTION  */
  // const unsubReportList = useAnalyticsStore.subscribe((state) => {
  //   state.reportList !== reportsList && setReportsList(state.reportList)
  // })

  // const unsubReportListActive = useAnalyticsStore.subscribe((state) => {
  //   state.activeReportList !== reportListActive && setReportListActive(state.activeReportList)
  // })

  // const unsubReportListFiltered = useAnalyticsStore.subscribe((state) => {
  //   state.filteredReportList !== reportListFiltered && setReportListFiltered(state.filteredReportList)
  // })

  // const unsubActiveReportsFilter = useAnalyticsStore.subscribe((state) => {
  //   state.activeReportFilter !== activeReportsFilter && setActiveReportsFilter(state.activeReportFilter)
  // })

  /* ------------------- VARIABLES  */
  const accountIdList = (reportConfig.data as IDataReport[])
    .filter((data) => data.account !== undefined)
    .map((data) => data.account as string)

  /* ------------------- METHODS  */
  const handleSelectId = (report: string | string[]) => {
    if (typeof report === 'string') {
      //Se il report è già stato selezionato lo rimuovo
      if (reportIdSelected.includes(report)) {
        reportIdSelected.splice(reportIdSelected.indexOf(report), 1)
        setReportIdSelected([...reportIdSelected])
        reportIdSelected.length === 0 && setShowContentFooter(false)
      } else {
        //Se il report non è stato ancora selezionato lo aggiungo
        setReportIdSelected((prev) => [...prev, report])
        setShowContentFooter(true)
      }
    }

    // Per gestire report in array
    if (typeof report === 'object') {
      if (report.length === 0) setShowContentFooter(false)
      if (report.length > 1) setShowContentFooter(true)
      setReportIdSelected(report)
    }
  }

  /* ------------------- API CALLS  */
  const getReportData = async () => {
    const res = await getReportList()

    setReportList(res as IReport[])
  }

  /* ------------------- USEEFFECT  */
  useEffect(() => {
    return () => {
      // unsubReportList()
      // unsubReportListActive()
      // unsubReportListFiltered()
      // unsubActiveReportsFilter()
      setActiveReportList(null)
    }
  }, [])

  //Reset del report config al cambio di sezione : analytics / createReport / report
  useEffect(() => {
    setReportConfig({
      info: {},
      data: [],
    })
    setFilteredReportList(null)
    setActiveReportList(null)

    if (section === 'analytics') {
      getReportData()
      useAnalyticsStore.getState().setSelectedDatesReports(null)
    }
  }, [section])

  // Setta di nuovo valore di filteredReports quando reportList cambia (ossia quando monta e quando un elemento viene eliminato)
  useEffect(() => {
    if (reportsList === null) return
    setFilteredReportList(
      reportsList.sort((a, b) => new Date(b.info.updatedAt!).getTime() - new Date(a.info.updatedAt!).getTime())
    )
  }, [reportsList])

  // Setta filteredReports in base al filtro di report attivo
  useEffect(() => {
    if (reportListActive !== 'all' || reportListActive !== null) {
      const filteredReports = reportsList?.filter((report) => {
        return report.social.some((account) => reportListActive?.includes(account.provider))
      })
      setFilteredReportList(filteredReports ?? null)
    }

    if (reportListActive === null || reportListActive === 'all') {
      setFilteredReportList(reportsList ?? null)
    }
  }, [reportListActive])

  const content = {
    analytics: (
      <ContentStructure
        header={
          <ContentHeaderStructure
            content={
              <HeaderDashboardAnalytics
                activeReportList={reportListActive}
                filteredReportList={reportListFiltered}
                activeReportFilter={activeReportsFilter}
              />
            }
          />
        }
        content={
          <ContentContainerStructure
            noShadow
            content={
              <ContentDashboardAnalytics
                reportIdSelected={reportIdSelected}
                handleSelectId={handleSelectId}
                reportListActive={reportListActive}
                reportListFiltered={reportListFiltered}
              />
            }
          />
        }
        footer={
          <ContentFooterStructure
            showFooter={showContentFooter}
            content={
              <FooterDashboardAnalytics
                reportIdSelected={reportIdSelected}
                handleSelectId={handleSelectId}
                reportsList={reportsList}
              />
            }
          />
        }
      />
    ),
    createReport: (
      <ContentStructure
        header={
          <ContentHeaderStructure
            content={<CreateReportHeader subsection={subsection} accountIdList={accountIdList} />}
          />
        }
        content={
          <ContentContainerStructure
            noShadow
            content={
              <CreateReport subsection={subsection} reportConfig={reportConfig} setReportConfig={setReportConfig} />
            }
          />
        }
        footer={
          <ContentFooterStructure
            content={
              <CreateReportFooter
                subsection={subsection}
                reportConfig={reportConfig}
                setReportConfig={setReportConfig}
              />
            }
          />
        }
      />
    ),
    report: (
      <ContentStructure
        header={<ContentHeaderStructure content={<PreviewReportHeader reportPreview={reportPreview} />} />}
        content={<ContentContainerStructure noShadow content={<PreviewReport reportPreview={reportPreview} />} />}
        footer={<ContentFooterStructure content={null} showFooter={false} />}
      />
    ),
  }

  return content[section]
}
