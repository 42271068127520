import styled from '@emotion/styled'

import { Box, Button, Text } from 'components'
import { useEffect, useState } from 'react'
import { AppStore, T } from 'utils'

interface Props {
  filters: [{ item: string }] | undefined
  setFilters: (value: [{ item: string }]) => void
}

export default function FilterModal(props: Props) {
  const [selected, setSelected] = useState([{ item: '' }])

  useEffect(() => {
    if (props.filters) {
      setSelected(props.filters)
    } else {
      setSelected([])
    }
  }, [])

  const types = [
    {
      name: 'JPG',
      value: 'jpeg',
    },
    {
      name: 'PNG',
      value: 'png',
    },
    {
      name: 'WEBP',
      value: 'webp',
    },

    {
      name: 'MP4',
      value: 'mp4',
    },

    {
      name: 'GIF',
      value: 'gif',
    },
  ]

  const mainType = [
    {
      name: T.mediaPage.images,
      value: 'image',
    },
    {
      name: 'Video',
      value: 'video',
    },
  ]

  useEffect(() => {
    props.setFilters(selected.length > 0 ? (selected as any) : undefined)
  }, [selected])

  const isSelected = (item: string) => {
    if (selected.find((s) => s.item === item) === undefined) {
      return false
    }
    return true
  }

  const addRemoveToSelected = async (item: string) => {
    if (isSelected(item)) {
      let selectedRemoved = selected.filter((s) => s.item !== item)

      await setSelected(selected.filter((s) => s.item !== item))

      let removeImg = 0

      for (const type of ['jpeg', 'png', 'webp']) {
        const selectedCopy = selectedRemoved.filter((s) => s.item === type).length

        if (selectedCopy >= 1) removeImg++
      }
      if (removeImg !== 3) {
        selectedRemoved = selectedRemoved.filter((s) => s.item !== 'image')
      }

      let removeVideo = 0
      for (const type of ['mp4', 'gif']) {
        const selectedCopy = selectedRemoved.filter((s) => s.item === type).length
        if (selectedCopy >= 1) removeVideo++
      }

      if (removeVideo !== 2) {
        selectedRemoved = selectedRemoved.filter((s) => s.item !== 'video')
      }

      setSelected(selectedRemoved)
    } else {
      let selectedAdded = [...selected, { item }]

      let addImg = true
      for (const type of ['jpeg', 'png', 'webp']) {
        const selectedCopy = selectedAdded.filter((s) => s.item === type).length
        if (selectedCopy <= 0) addImg = false
      }
      if (addImg) {
        selectedAdded = [...selectedAdded, { item: 'image' }]
      }

      let addVideo = true
      for (const type of ['mp4', 'gif']) {
        const selectedCopy = selectedAdded.filter((s) => s.item === type).length
        if (selectedCopy <= 0) addVideo = false
      }
      if (addVideo) {
        selectedAdded = [...selectedAdded, { item: 'video' }]
      }

      setSelected(selectedAdded)
    }
  }

  return (
    <Box relative>
      <Box p={32} gap={24} vcenter>
        <Box style={{ width: '100%' }} vcenter row justify="space-between">
          <TextTitle fontSize="16px">{T.mediaPage.selectFormat}</TextTitle>

          {selected.length > 0 && (
            <Button
              variant="secondary"
              size="small"
              onClick={() => {
                setSelected([])
              }}
            >
              {T.mediaPage.deselectAll}
            </Button>
          )}
        </Box>

        <Box row gap={16} center>
          <GridMain>
            {mainType.map((type, index) => (
              <Button
                variant={isSelected(type.value) ? 'primary' : 'neutral'}
                size="small"
                style={{
                  fontSize: '14px',
                }}
                onClick={() => {
                  addRemoveToSelected(type.value)
                }}
              >
                {type.name}
              </Button>
            ))}
          </GridMain>

          <GridSecondary>
            {types.map((item, index) => (
              <Button
                variant={isSelected(item.value) ? 'primary' : 'neutral'}
                size="small"
                style={{
                  fontSize: '14px',
                }}
                onClick={() => {
                  addRemoveToSelected(item.value)
                }}
              >
                {item.name}
              </Button>
            ))}
          </GridSecondary>
        </Box>
      </Box>

      {selected.length > 0 && (
        <ContainerButton removeHover onClick={() => AppStore.closeAllModal()}>
          <Button variant="secondary" size="small">
            {T.mediaPage.showResults}
          </Button>
        </ContainerButton>
      )}
    </Box>
  )
}

const GridMain = styled(Box)`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 16px;
`

const GridSecondary = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
`

const TextTitle = styled((props) => <Text {...props} />)`
  font-weight: 700;
  font-size: 20px;
  color: ${() => AppStore.theme.o.black};
`

const ContainerButton = styled(Box)`
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
`
