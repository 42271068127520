/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { IPostFilters } from 'api/Reports'
import { Box, Icons } from 'components'
import { EmptyState } from 'components/UI'
import { SocialPicture } from 'components/UI/SocialPicture'
import { ToolTip } from 'pages/settings/Subscription/SubscriptionProvider'
import { useAnalyticsStore } from 'stores/AnalyticsStore'
import { SocialAccount } from 'types'
import { GraphType } from 'types/AnalyticsInferfaces'
import { AppStore, T } from 'utils'
import { AnalyticsTooltip } from '../AnalyticsTooltip'
import { CompareComparison, TTrend } from './CompareComparison'
import { HighChartsGraph } from './HighChartsGraph'
import { ReferenceValue } from './ReferenceValue'
import { TextInfoGraph } from './singleGraph/TextInfoGraph'
import { TableGraph } from './singleGraph/table/TableGraph'
import { useEffect } from 'react'

/* ------------------- INTERFACES  */
interface Props {
  type: GraphType
  title: string
  emptyMessage?: string
  socialProfile: SocialAccount
  labelText?: string
  numData?: number
  datasets?: any
  options?: any
  width?: number
  socialPicture: string
  headerData?: IHeaderGraphData[]
  tooltipLabels?: string[] | string[][] // ["data", "impressions"] | [["data", "reel"], ["data", "carousel"]]
  tooltipInfo: ToolTip
  error: boolean
  onDataRefresh: (filters?: IPostFilters, page?: number) => Promise<void>
  pagination?: GraphPagination
  identifier?: string
}

export interface GraphPagination {
  totalItems?: number
  totalPages?: number
  page?: number
}

export interface IHeaderGraphData {
  label: string
  value: string
  comparative: string
  type: TTrend
}

export const Graph = ({
  type,
  datasets = [],
  title,
  options = {},
  width,
  labelText,
  numData,
  emptyMessage,
  socialProfile,
  headerData,
  tooltipLabels,
  tooltipInfo,
  error,
  onDataRefresh,
  pagination,
  identifier,
}: Props) => {
  /* ----------------- ZUSTAND  */

  const { compareDatesReports, compareDatesEnabled } = useAnalyticsStore((state) => ({
    compareDatesReports: state.compareDatesReports,
    compareDatesEnabled: state.compareDatesEnabled,
  }))

  /* ----------------- VARIABLES  */

  // Valore che serve per definire se il grafico ha un errore o no
  const canShowGraphNonTable: boolean =
    datasets.length > 0 &&
    datasets[0]?.data !== null &&
    datasets[0]?.data !== undefined &&
    datasets[0].noDataText === undefined &&
    !error

  const canShowGraphTable = true

  const canShowGraph = type === 'table' ? canShowGraphTable : canShowGraphNonTable

  const renderGraph = () => {
    if (error && type !== 'table') {
      return <div>Error fetching metric data</div>
    }
    switch (type) {
      case 'table':
        return (
          <TableGraph
            emptyMessage={emptyMessage}
            error={error}
            identifier={identifier}
            pagination={pagination}
            data={datasets}
            provider={socialProfile.provider}
            onDataRefresh={onDataRefresh}
          />
        )

      case 'text':
        const validDataset = datasets.find((dataset) => dataset && dataset?.data !== null)
        return <TextInfoGraph textGraph={validDataset} />

      case 'areaspline':
        return (
          <HighChartsGraph
            type={type}
            dataSeries={datasets[0]?.data}
            xAxisLabels={datasets[0]?.dateArray ?? undefined}
            tooltipLabels={tooltipLabels}
          />
        )

      case 'pie':
        return (
          <HighChartsGraph
            type={type}
            dataSeries={datasets[0]?.data}
            xAxisLabels={datasets[0]?.dateArray ?? undefined}
          />
        )
      case 'doughnut':
        return (
          <HighChartsGraph
            type={type}
            dataSeries={datasets[0]?.data}
            xAxisLabels={datasets[0]?.dateArray ?? undefined}
          />
        )

      case 'heatmap':
        return (
          <HighChartsGraph
            type={type}
            dataSeries={datasets[0]?.data}
            xAxisLabels={datasets[0]?.xAxis?.categories ?? undefined}
            yAxisLabels={datasets[0]?.yAxis?.categories ?? undefined}
            tooltipLabels={tooltipLabels}
          />
        )
    }
  }

  return (
    <GraphContainer
      width={width ?? '100%'}
      style={{
        height: '100%',
      }}
    >
      {/* ----------------- HEADER GRAPH CONTAINER */}
      <GraphHeader>
        {/* ----------------- TITLE / ICON CONTAINER */}
        <GraphTitleContainer>
          <SocialPicture
            picture={socialProfile.picture}
            provider={socialProfile.provider}
            dimensionPicture={28}
            dimensionProvider={22}
          />
          {title}
          <TooltipContainer>
            <AnalyticsTooltip tooltip={tooltipInfo} />
          </TooltipContainer>
        </GraphTitleContainer>

        {headerData && (
          <GraphSubHeader>
            {headerData.map((data) => {
              return <ReferenceValue valueLabel={data.label} currentValue={data.value} />
            })}

            {compareDatesEnabled &&
              headerData[0].comparative &&
              headerData.map((data, index) => {
                return <CompareComparison key={`compare-${index}`} trend={data.type} value={data.comparative} text />
              })}
          </GraphSubHeader>
        )}
      </GraphHeader>

      {/* ----------------- GRAPH CONTAINER */}
      {canShowGraph ? (
        <GraphWrapper isTextGraph={type === 'text'}>{renderGraph()}</GraphWrapper>
      ) : (
        <EmptyState
          noPaddingTop
          text={
            emptyMessage
              ? emptyMessage
              : !datasets[0]?.noDataText
              ? T.error.no_data_at_this_moment
              : datasets[0]?.noDataText === 'No post in selected period'
              ? T.error.noPostInSelectedPeriod
              : T.error.no_data_at_this_moment
          }
          icon={<Icons.sadFace fill={AppStore.theme.o.red} />}
        />
      )}

      <DragDropHandle className="drag-handle">
        <Icons.dragDropAnalytics fill={AppStore.theme.o.darkGrey} />
      </DragDropHandle>
    </GraphContainer>
  )
}

const GraphContainer = styled(Box)`
  background-color: ${() => AppStore.theme.o.surface};
  width: 542px;
  max-width: 400px;
  height: 428px;
  min-height: 428px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 12px;
  padding: 16px 24px;
  border: 1px solid ${() => AppStore.theme.o.lightGrey};
  border-radius: 24px;
  justify-content: start;
  cursor: unset !important;
  position: relative;
`


const GraphHeader = styled(Box)`
  gap: 8px;
  align-items: flex-start;
`

const GraphSubHeader = styled(Box)`
  display: flex;
  min-width: 52px;
  flex-direction: row;
  align-items: flex-start;
  text-align: left;
  align-self: flex-start;
  margin: 0px 8px;
  height: 52px;
  gap: 32px;
  margin-left: auto;
  gap: 16px;
`

const GraphTitleContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
  font-size: 16px;
  font-weight: 800;
  line-height: 19.23px;
  letter-spacing: 0.16px;
  text-transform: capitalize;
`

const GraphWrapper = styled(Box)<{ isTextGraph: boolean }>`
  flex: 1;
  width: 100%;
  justify-content: ${(props) => (props.isTextGraph ? 'center' : 'flex-end')};
  margin-bottom: ${(props) => (props.isTextGraph ? '-20px' : '')};
`

const DragDropHandle = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  cursor: move;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: 300ms ease-in-out all;
`

const TooltipContainer = styled(Box)`
  margin-left: auto;
`
