/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { IResponseCalendarDayPosts } from 'api/PostV2'
import { Box, Text } from 'components'
import { TIconPost } from 'components/Modal/PreviewPostModal/PreviewPostModal'
import { AppStore, T } from 'utils'
import { DateInfoCalendarCard } from './DateInfoCalendarCard'
import { MediaCalendarCard } from './MediaCalendarCard'
import { SocialInfoCalendarCard } from './SocialInfoCalendarCard'
import { TextCalendarCard } from './TextCalendarCard'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */
type TFromSpecificCard = 'drafts' | 'calendar'

interface Props {
  post: IResponseCalendarDayPosts
  action: () => void
  from: TFromSpecificCard
  someSelected?: boolean
}

export const SpecificDayCard = ({ post, action, someSelected, from }: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */
  const { media, text, publishedAt, status, socialProfile, metadata, type } = post

  /* ----------------- METHODS  */

  /**
   * Funzione che restituisce l'icona in base al tipo di media
   * @returns tipo di icona
   */
  const getIconType = (): TIconPost => {
    if (type === 'SHORT') return 'SHORT'

    if (type === 'STORY') return 'STORY'

    if (media[0] !== null) {
      if (socialProfile.provider === 'instagram' && media.length === 1 && media[0].type === 'video') return 'REEL'

      if (metadata) return 'LINK'

      if (media.length === 1 && media[0].type === 'image') return 'STANDARD'

      if (media.length === 1 && media[0].type === 'video') return 'VIDEO'

      if (media.length > 1) return 'CAROUSEL'
    }

    return 'STANDARD'
  }

  const handleOnClick = () => {
    if (from === 'calendar') return action()

    if (from === 'drafts' && someSelected) return action()
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <SpecificDayCardContainer onClick={handleOnClick}>
      <Row>
        <SocialInfoCalendarCard socialProfile={socialProfile} mediaType={getIconType()} />
        <DateInfoCalendarCard status={status} date={publishedAt} />
      </Row>

      <Row height={183}>
        <MediaCalendarCard media={media} metadata={metadata} />
        <TextCalendarCard text={text} />
      </Row>

      {from === 'drafts' && !someSelected && (
        <HoverEffect className="hover_card_day">
          <EditBtn onClick={action}>
            <Text fontSize={14} weight={600}>
              {T.singleWord.edit}
            </Text>
          </EditBtn>
        </HoverEffect>
      )}
    </SpecificDayCardContainer>
  )
}

const SpecificDayCardContainer = styled(Box)`
  position: relative;
  height: 257px;
  width: 100%;
  padding: 16px;
  border-radius: 14px;
  gap: 12px;
  border: 1px solid ${() => AppStore.theme.o.lightGrey};
  background: ${() => AppStore.theme.o.surface};

  &:hover {
    background-color: ${() => AppStore.theme.o.surface} !important;

    .hover_card_day {
      opacity: 1;
    }
  }
`

const Row = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 16px;
  width: 100%;
`

const HoverEffect = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(143, 143, 143, 0) 100%);
  opacity: 0;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
`

const EditBtn = styled(Box)`
  background-color: ${() => AppStore.theme.o.lightestGrey};
  border: 1px solid ${() => AppStore.theme.o.lightGrey};
  border-radius: 14px;
  padding: 8px 48px;
`
