import styled from '@emotion/styled'
import React from 'react'
import { AppStore, T } from 'utils'

export interface IPlanInfo {
  name: string
  description: string
  amount: number
  isCredit?: boolean
  creditColor: string
  vatDescription?: string
  vatAmount?: number
}

interface PricingDisplayProps {
  fromPlan: IPlanInfo
  toPlan: IPlanInfo
  showCurrency?: boolean
  showPlusMinusSigns?: boolean
}

export const PricingDisplay: React.FC<PricingDisplayProps> = ({
  fromPlan,
  toPlan,
  showCurrency = true,
  showPlusMinusSigns = false,
}) => {
  const formatAmount = (amount: number, isCredit?: boolean) => {
    const sign = showPlusMinusSigns ? (isCredit ? '+' : '-') : ''
    const currency = showCurrency ? '€' : ''
    return `${sign}${Math.abs(amount).toFixed(2).replaceAll('.', ',')}${currency}`
  }

  return (
    <Container>
      {/** Current plan information **/}
      <PlanRow>
        <PlanInfo>
          <PlanName>
            <PlanDescription> {T.billing.upgradePlan.from} </PlanDescription> {fromPlan.name}
          </PlanName>
          <PlanDescription>{fromPlan.description}</PlanDescription>
        </PlanInfo>
        <PlanAmount creditColor={fromPlan.creditColor}>{formatAmount(fromPlan.amount, fromPlan.isCredit)}</PlanAmount>
      </PlanRow>

      {/** New Plan information **/}
      <PlanRow>
        <PlanInfo>
          <PlanName>
            <PlanDescription> {T.billing.upgradePlan.to} </PlanDescription> {toPlan.name}
          </PlanName>
          <PlanDescription>{toPlan.description}</PlanDescription>
        </PlanInfo>
        <PlanAmount creditColor={toPlan.creditColor}>{formatAmount(toPlan.amount, toPlan.isCredit)}</PlanAmount>
      </PlanRow>
    </Container>
  )
}

const Container = styled.div`
  font-family: Arial, sans-serif;
  /* max-width: 400px; */
  margin-top: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: ${AppStore.theme.o.lightestGrey};
  border-radius: 8px;
`

const PlanRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const PlanInfo = styled.div`
  flex-direction: column;
  display: flex;
  gap: 0px;
`

const PlanName = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: ${AppStore.theme.o.black};
`

const PlanDescription = styled.span`
  font-size: 16px;
  color: ${AppStore.theme.o.textDarkGrey};
`

const PlanAmount = styled.span<{ creditColor?: string }>`
  font-size: 24px;
  font-weight: bold;
  color: ${(props) => props.creditColor ?? AppStore.theme.o.textDarkGrey};
`

const PlanVATAamount = styled.span`
  font-size: 16px;
  font-weight: lighter;
  color: ${AppStore.theme.o.textDarkGrey};
`

export default PricingDisplay
