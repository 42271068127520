/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { useState } from 'react'
import { AppStore, T } from 'utils'
import { SideBarBtn } from './SideBarBtn'

/* ------------------- TYPES  */
export type TModeCalendar = 'calendar' | 'queue'

/* ------------------- INTERFACES  */
export interface ICalendarBtn {
  id: TModeCalendar
  name: string
  action: () => void
  comingSoon: boolean
}

interface Props {}

export const SwitchModeCalendar = ({}: Props) => {
  /* ----------------- STATE  */
  const [activeMode, setActiveMode] = useState<TModeCalendar>('calendar')

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  /* ----------------- CONFIG  */
  const calendarBtnConfig: ICalendarBtn[] = [
    {
      id: 'calendar',
      name: T.calendarPage.calendar,
      action: () => {},
      comingSoon: false,
    },
    {
      id: 'queue',
      name: T.calendarPage.queue,
      action: () => {},
      comingSoon: true,
    },
  ]

  return (
    <SwitchModeCalendarContainer>
      {calendarBtnConfig.map((btn) => (
        <SideBarBtn
          key={btn.id}
          active={activeMode === btn.id}
          onClick={() => setActiveMode(btn.id)}
          comingSoon={btn.comingSoon}
          name={btn.name}
        />
      ))}
    </SwitchModeCalendarContainer>
  )
}

const SwitchModeCalendarContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  gap: 8px;
  border-radius: 14px;
  border: 1px solid ${() => AppStore.theme.o.lightGrey};
`
