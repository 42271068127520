import { ConditionOperatorV } from 'commandbar/build/internal/src/middleware/helpers/argument'
import { T } from 'translation/i18n'
import { Post } from 'types'

const maxChars = {
  instagram: 2200,
  facebook: 4500,
  twitter: 280,
  linkedin: 3000,
}

function checkIfPostsAreValids(posts: Post[]) {
  const errors: { message: string; post: Post }[] = []
  posts.forEach((post) => {
    if (!post.socialaccount?.provider) return
    const containsPhotoAndVideo =
      !!post.medias?.find((m) => m?.mimetype?.includes('video')) &&
      !!post.medias?.find((m) => !m?.mimetype?.includes('video'))

    if (post.socialaccount.expired === true) {
      errors.push({
        message: T.error.updateConnection,
        post,
      })
    }

    switch (post.socialaccount?.provider) {
      case 'instagram':
        if (post.description?.length > maxChars.instagram) {
          errors.push({
            message: 'La descrizione del post di instagram è troppo lunga.',
            post,
          })
        }

        if ((post.medias ?? []).length === 0) {
          errors.push({
            message: `${T.error.obligatoryMedia}  instagram.`,
            post,
          })
        }
        if ((post.medias ?? []).length > 10) {
          errors.push({
            message: `Instagram ${T.error.MaxMedia} 10 ${T.postPage.media}.`,
            post,
          })
        }
        // Check if there is more than 30 hashtag in the description
        if ((post.description?.match(/#/g)?.length ?? 0) > 30) {
          errors.push({
            message: `Instagram ${T.error.maxHashtag1} 30 ${T.error.maxHashtag2} `,
            post,
          })
        }
        break
      case 'x':
        // Check if the description is too long
        if (post.description?.length > maxChars.twitter) {
          errors.push({
            message: 'La descrizione del post di twitter è troppo lunga.',
            post,
          })
        }

        if ((post.medias ?? []).length === 0 && post.description?.length === 0) {
          errors.push({
            message: 'Media o descrizione obbligatorio per il post di Twitter.',
            post,
          })
        }

        if ((post.medias ?? []).length > 4) {
          errors.push({
            message: `Twitter ${T.error.MaxMedia} 4 ${T.postPage.media}.`,
            post,
          })
        }
        if (containsPhotoAndVideo) {
          errors.push({
            message: `Twitter ${T.error.dontConsentFotoAndVideo}`,
            post,
          })
        }
        break
      case 'facebook':
        // Check if the description is too long
        if (post.description?.length > maxChars.facebook) {
          errors.push({
            message: 'La descrizione del post di facebook è troppo lunga.',
            post,
          })
        }

        //check if medias contains photos and videos
        if (containsPhotoAndVideo) {
          errors.push({
            message: `Facebook ${T.error.dontConsentFotoAndVideo}`,
            post,
          })
        }
        break
      case 'linkedin':
        // Check if the description is too long
        if (post.description?.length > maxChars.linkedin) {
          errors.push({
            message: 'La descrizione del post di linkedin è troppo lunga.',
            post,
          })
        }

        if ((post.medias ?? []).length > 9) {
          errors.push({
            message: `Linkedin  ${T.error.MaxMedia} 9 ${T.postPage.media}.`,
            post,
          })
        }
        if (containsPhotoAndVideo) {
          errors.push({
            message: `Linkedin ${T.error.dontConsentFotoAndVideo}`,
            post,
          })
        }
        break

      case 'youtube':
        //at least one video at position 0 and no other media allowed
        // ________________________________________________________________________________________________________________________
        if ((post.medias ?? []).length === 0) {
          errors.push({
            message: `${T.error.obligatoryMedia}  youtube.`,
            post,
          })
        }
        if ((post.medias ?? []).length > 1) {
          errors.push({
            message: `Youtube ${T.error.MaxMedia} 1 ${T.postPage.media}.`,
            post,
          })
        }
        if (
          (post.medias ?? []).length === 1 &&
          post.medias !== undefined &&
          !post.medias[0]?.mimetype?.includes('video')
        ) {
          errors.push({
            message: `Youtube only video allowed`,
            post,
          })
        }
        // ________________________________________________________________________________________________________________________
        //obligatory title param

        if (post.description === undefined || post.description === null || post.description === '') {
          errors.push({
            message: `Youtube obligatoryTitle`,
            post,
          })
        }

        if (post.extraSection.youtubeExtras?.privacyStatus === undefined) {
          errors.push({
            message: `Youtube obligatoryPrivacyStatus`,
            post,
          })
        }
        if (post.extraSection.youtubeExtras?.category === undefined) {
          errors.push({
            message: `Youtube obligatoryCategory`,
            post,
          })
        }
        if (post.extraSection.youtubeExtras?.adultOnly === undefined) {
          errors.push({
            message: `Youtube obligatoryAdultOnly`,
            post,
          })
        }
        // _______________________________________________________________________________________________________________________
        //youtube limitation

        if (post.description?.length > 1000) {
          errors.push({
            message: `Youtube T.error.maxCharTitle 1000`,
            post,
          })
        }

        // _______________________________________________________________________________________________________________________
        break

      case 'google':
        break
      default:
        break
    }
  })
  return errors
}

export { checkIfPostsAreValids }
