/* ------------------- IMPORTS */
import { Template } from 'components/CreatePost/TextComponents/Template/Template'
import { openDraftModal } from 'components/Modal/ModalManager'
import { useEffect } from 'react'

/* ------------------- INTERFACES  */
interface Props {}

export const DraftsContent = ({}: Props) => {
  /* ----------------- USEEFFECT  */
  useEffect(() => {
    openDraftModal()
  }, [])

  return <Template />
}
