import styled from '@emotion/styled'
import React from 'react'
import { AppStore, T, __ } from 'utils'
import { Box } from './Box'
import { Icons } from './Icons'
import { ButtonWithOptions } from './ButtonWithOptions'
import { Tracker } from 'api/Tracker'
import { TRACKEREVENTS } from 'utils/eventsTracker'

export const HeaderButtonWithOptions = () => {
  const options = [
    {
      onClick: () => {
        window.open(' https://www.trustpilot.com/review/asters.ai', '_blank')
        Tracker.trackEvent(TRACKEREVENTS.REVIEWS_VISITED, {})
      },
      text: __(T.header.leaveComment),
      id: 'service-center-header',
      style: { width: 300, fontSize: 21, fontWeight: 400 },
      icon: <></>,
    },
    {
      onClick: () => {
        if (AppStore.loggedUser.language === 'en') {
          window.open('https://help.asters.ai/en', '_blank')
        } else {
          window.open('https://help.asters.ai/it', '_blank')
        }
        Tracker.trackEvent(TRACKEREVENTS.HELP_CENTER_VISITED, { position: 'Menu Top' })
      },
      text: T.header.guideTutorial,
      id: 'service-center-header',
      style: { width: 300, fontSize: 21, fontWeight: 400 },
      icon: <></>,
    },
    {
      onClick: () => {
        window.open('https://asters.ai/it/webinar/', '_blank')
        Tracker.trackEvent(TRACKEREVENTS.WEBINAR_VISITED, {})
      },
      text: 'Webinar',
      id: 'service-center-header',
      style: { width: 300, fontSize: 21, fontWeight: 400 },
      icon: <></>,
    },
    {
      onClick: () => {},
      text: T.guidedTour,
      id: 'Tour-guidato',
      style: { width: 300, fontSize: 21, fontWeight: 400 },
      icon: <></>,
    },
    // {
    //   onClick: () => {
    //     const customerly = getCustomerly()

    //     setTimeout(() => {
    //       customerly.open()
    //     }, 200)
    //   },
    //   text: 'Chat di assistenza',
    //   id: 'live-chat-header',
    //   style: { width: 300, fontSize: 21, fontWeight: 400 },
    //   icon: <Icons.liveChatIcon fill={AppStore.theme.o.black} />,
    // },
  ]

  return (
    <ButtonWithOptions id="settings-button" options={options}>
      <ButtonSvgContainer>
        <Icons.notifications style={{ margin: 17 }} />
      </ButtonSvgContainer>
    </ButtonWithOptions>
  )
}

const ButtonSvgContainer = styled(Box)`
  background-color: transparent;

  svg {
    fill: ${() => AppStore.theme.o.black};
  }

  &:hover {
    background-color: ${() => AppStore.theme.o.lightBlue};

    svg {
      fill: ${() => AppStore.theme.o.blueOnLight};
    }
  }
`
