import styled from '@emotion/styled'
import { Box } from 'components/Box'
import { useEffect, useState } from 'react'
import { IPostVariant } from 'types/PostInterface'
import { AppStore, T } from 'utils'

interface Props {
  dayValues: OnlineFollowersData[] | undefined
  post: IPostVariant
  changeDate: any
}

export interface OnlineFollowersData {
  values: Record<string, number> | undefined
  end_time: string
}

interface WeekDays {
  name: string
  value: any
  end_time: string | undefined
}

interface BestTimeToPostData {
  dayName: string
  hour: string
}

interface IBestTimeData {
  dayName: string
  hour: string
  dateTime: Date | undefined
  active: boolean
}

export default function BestTimeToPost({ dayValues, post, changeDate }: Props) {
  const [bestTimeData, setBestTimeData] = useState<IBestTimeData[]>()
  const [loading, setLoading] = useState<boolean>(true)

  const controlActiveDay = (date) => {
    if (!bestTimeData) return

    const all3Dates = bestTimeData

    all3Dates.forEach((d) => {
      if (d.dateTime === undefined) return
      // const newDateDay = date?.toLocaleDateString('en-US', { weekday: 'long' })
      // const newDateHours = date?.getHours()
      // const dayLong = d.dateTime?.toLocaleDateString('en-US', { weekday: 'long' })
      // const dayHours = d.dateTime?.getHours()
      // if (dayLong === newDateDay && dayHours === newDateHours) d.active = true
      else d.active = false
    })

    setBestTimeData(all3Dates)
  }

  /**
   * riordinare la lista dei giorni della settimana in base al giorno odierno
   * @param data array di oggetti con i dati dei giorni della settimana
   * @returns
   */
  const reorderDays = (data: any) => {
    const today = new Date().getDay()
    const currentDayName = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'][today]

    const daysBeforeToday = data.filter((item) => {
      const itemDayIndex = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].indexOf(
        item.dayName
      )
      return itemDayIndex < today || (itemDayIndex === today && parseInt(item.hour) <= new Date().getHours())
    })

    const daysAfterToday = data.filter((item) => {
      const itemDayIndex = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].indexOf(
        item.dayName
      )
      return itemDayIndex > today || (itemDayIndex === today && parseInt(item.hour) > new Date().getHours())
    })

    const reorderedData = [...daysAfterToday, ...daysBeforeToday]
    const currentDayIndex = reorderedData.findIndex((item) => item.dayName === currentDayName)

    if (currentDayIndex !== -1) {
      const currentDay = reorderedData.splice(currentDayIndex, 1)[0]
      reorderedData.push(currentDay)
    }

    return reorderedData
  }

  /**
   * se si mette anche l'orario, restituisce il prossimo giorno della settimana con l'orario in formato data es (Mon Jul 17 2023 12:00:00 GMT+0200 (Ora legale dell’Europa centrale)
   *
   * se non si mette l'orario restituisce questo  ( es: lun. 17:00)
   *
   * @param dayName nome del giorno
   * @param hours orario di pubblicazione
   * @returns es: lun. 17:00
   */
  const findNextDay = (dayName, hours: any = null) => {
    // Ottenere la data di oggi
    const today = new Date()

    // Mappare i giorni della settimana
    const days = {
      sunday: 0,
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
    }

    // Verificare se il giorno passato come input esiste
    if (!days.hasOwnProperty(dayName.toLowerCase())) {
      return
    }

    // Calcolare la differenza di giorni per arrivare al prossimo giorno della settimana
    const todayDay = today.getDay()
    const daysToAdd = (days[dayName] + 7 - todayDay) % 7

    // Impostare la data di partenza al prossimo giorno della settimana
    let startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + daysToAdd)

    if (hours) {
      // Impostare l'ora desiderata
      startDate.setHours(hours.split(':')[0], 0, 0, 0)

      if (startDate.getDay() === today.getDay()) {
        // Se la data è antecedente o uguale alla data odierna
        startDate = new Date(startDate.getTime() + 7 * 24 * 60 * 60 * 1000)
      }

      // ritorna il primo giorno della settimana selezionato entro i prossimi 7 giorni
      return startDate
    } else {
      let futureDate = startDate
      let userLang = navigator.language

      if (userLang !== 'it-IT' && userLang !== 'en-US') userLang = 'en-US'

      if (futureDate.getHours() <= today.getHours() && futureDate.getDay() === today.getDay()) {
        // Se la data è antecedente o uguale alla data odierna
        futureDate = new Date(futureDate.getTime() + 7 * 24 * 60 * 60 * 1000)
      }

      // Ottieni il nome abbreviato del giorno della settimana (Es. Tue)
      const dayNameShort = startDate.toLocaleDateString(userLang, { weekday: 'short' })

      // Ottieni il giorno del mese (Es. 11)
      const gg = futureDate.getDate()

      // Ottieni l'anno a due cifre (Es. 23)
      const mm = (futureDate.getMonth() + 1).toString().padStart(2, '0')

      // Crea la stringa nel formato desiderato (Es. Tue. 11/23)
      const formatToPrint = dayNameShort + '. ' + gg + '/' + mm

      return formatToPrint.charAt(0).toUpperCase() + formatToPrint.slice(1)
    }
  }

  const onChangeActiveDay = (dateSelected: Date | undefined) => {
    if (!bestTimeData) return

    const all3Dates = bestTimeData

    all3Dates.forEach((date) => {
      if (date.dateTime === dateSelected) {
        date.active = true
      } else {
        date.active = false
      }
    })

    setBestTimeData(all3Dates)

    // changeDate(post.info.account?.account_id, dateSelected)
    changeDate(post.variantId, dateSelected)
  }

  const startFunction = async () => {
    processData(dayValues)
  }

  const separateDate = (listOfDays: OnlineFollowersData[]) => {
    const weekDays: WeekDays[] = [
      { name: 'Monday', value: [], end_time: undefined },
      { name: 'Tuesday', value: [], end_time: undefined },
      { name: 'Wednesday', value: [], end_time: undefined },
      { name: 'Thursday', value: [], end_time: undefined },
      { name: 'Friday', value: [], end_time: undefined },
      { name: 'Saturday', value: [], end_time: undefined },
      { name: 'Sunday', value: [], end_time: undefined },
    ]

    weekDays.forEach((day) => {
      listOfDays.forEach((value: OnlineFollowersData) => {
        const endTime = new Date(value.end_time).toLocaleDateString('en-US', { weekday: 'long' })

        if (endTime === day.name) {
          day.value.push({ ...value })
          // aggiunta per avere la data del giorno
          day.end_time = value.end_time
        }
      })
    })

    return weekDays
  }

  const calculateVariableAverages = (daysForAverage: WeekDays[]) => {
    const result = {
      value: {},
      end_time: daysForAverage[0].value.end_time,
    }

    daysForAverage.forEach((element) => {
      const values = element.value
      for (const key in values) {
        if (!result.value.hasOwnProperty(key)) {
          result.value[key] = 0
        }
        result.value[key] += values[key] / daysForAverage.length
      }
    })

    for (const key in result.value) {
      result.value[key] = Math.round(result.value[key])
    }

    return [result]
  }

  const getTopThreeDays = (averagedWeek: WeekDays[], daysToCheck: WeekDays[]) => {
    const averages: any = []

    averagedWeek.forEach((entry) => {
      const values = Object.values(entry.value.value)
      const sum: any = values.reduce((acc: any, value) => acc + value, 0)
      const average = Math.round(sum / values.length)

      averages.push({
        day: entry.name,
        average: average.toString(),
      })
    })

    const bestDays = averages.sort((a, b) => parseInt(b.average) - parseInt(a.average)).slice(0, 3)
    const nameOfBestDays = bestDays.map((day) => day.day)

    return daysToCheck.filter((item) => nameOfBestDays.includes(item.name))
  }

  const findBestHours = (bestThreeDays: WeekDays[]) => {
    const bestTime: BestTimeToPostData[] = []
    let bestHour = ''
    let maxFollowers = 0

    bestThreeDays.forEach((bestDay) => {
      for (const key in bestDay.value.value) {
        const followers = bestDay.value.value[key]

        if (followers > maxFollowers) {
          maxFollowers = followers
          bestHour = key
        }
      }

      bestTime.push({ dayName: bestDay.name, hour: bestHour + ':00' })
    })

    return bestTime
  }

  const processData = (data: OnlineFollowersData[] | undefined) => {
    if (!data) return

    const processedWeek = separateDate(data)

    const dataToAverage = processedWeek
    dataToAverage.forEach((day) => {
      day.value = calculateVariableAverages(day.value)[0]
    })

    const bestDays = getTopThreeDays(dataToAverage, processedWeek)

    const bestTimeToPost = findBestHours(bestDays)

    const bestTimeSort = reorderDays(bestTimeToPost)

    const best3Days = bestTimeSort.map((day) => {
      return {
        dayName: findNextDay(day.dayName.toLowerCase()),
        hour: day.hour,
        dateTime: findNextDay(day.dayName.toLowerCase(), day.hour),
        active: false,
      } as IBestTimeData
    })

    setBestTimeData(best3Days)
    setLoading(false)
  }

  useEffect(() => {
    if (dayValues === undefined || !dayValues?.values) {
      setLoading(false)

      return
    }

    setBestTimeData([])
    setLoading(true)

    startFunction()
  }, [dayValues])

  useEffect(() => {
    setLoading(true)
  }, [])

  useEffect(() => {
    controlActiveDay(post.data.date)
  }, [post.data.date])

  return (
    <Box style={!loading ? { width: '100%', display: 'flex', justifyContent: 'center' } : {}}>
      <SuggestedTimeContainer flex className={!loading && !bestTimeData ? 'no-border' : ''}>
        <SuggestedTitle
          style={
            bestTimeData ? { backgroundClip: AppStore.theme.o.lightestGrey, color: AppStore.theme.o.darkGrey } : {}
          }
        >
          {loading && <span style={{ color: AppStore.theme.o.black, marginBottom: 4 }}>{T.postPage.loadingData}</span>}
          {!loading && !bestTimeData && (
            <span style={{ color: AppStore.theme.o.darkGrey, padding: '8px auto' }}>{T.postPage.under100Follower}</span>
          )}
          {!loading && bestTimeData && (
            <span style={{ color: AppStore.theme.o.black }}>{T.postPage.suggestedDate}</span>
          )}
        </SuggestedTitle>
        <Box row gap={64} style={{ justifyContent: 'space-between', width: '100%' }}>
          {bestTimeData?.map((day, i) => {
            return (
              <BestDaysBox
                ml={0}
                mr={0}
                mb={16}
                className={day.active ? 'selected' : ''}
                key={day.dayName + i}
                onClick={() => onChangeActiveDay(day.dateTime)}
              >
                <p style={{ fontWeight: 700, fontSize: 12 }}>{day.dayName}</p>
                <p style={{ fontWeight: 400, fontSize: 16 }}>{day.hour}</p>
              </BestDaysBox>
            )
          })}
        </Box>
      </SuggestedTimeContainer>
    </Box>
  )
}

const SuggestedTimeContainer = styled(Box)`
  border-radius: 14px;
  padding-inline: 24px;
  padding-top: 12px;
  position: relative;
  border: 1px solid ${() => AppStore.theme.o.lightGrey};
  z-index: 1;

  &.no-border {
    margin-inline: auto;
    padding-top: 8px;

    &::before {
      content: '';
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      width: calc(100% + 2px);
      border-radius: 14px;
      background-image: linear-gradient(
        90deg,
        ${() => AppStore.theme.o.lightestGrey},
        ${() => AppStore.theme.o.lightestGrey}
      );
      z-index: -1;
    }
  }
`

const SuggestedTitle = styled.p`
  font-family: Aktiv Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${() => AppStore.theme.o.black};
  margin-bottom: 6px;
  z-index: 1;
`

const BestDaysBox = styled(Box)`
  width: 92px;
  height: 56px;
  min-width: fit-content;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.48px;
  border-radius: 14px;
  color: ${() => AppStore.theme.o.black};
  border: 1px solid transparent;
  z-index: 1;

  :hover {
    border: 1px solid ${() => AppStore.theme.o.grey};
    background-color: ${() => AppStore.theme.o.lightestGrey};
  }

  &.selected {
    background-color: ${() => AppStore.theme.o.lightGreen};
    border-color: ${() => AppStore.theme.o.green};
    color: ${() => AppStore.theme.o.green};
  }
`
