/* ******************* IMPORT ******************* */
import styled from '@emotion/styled'
import { Box, Button, Icons } from 'components'
import { useEffect, useState } from 'react'
import { AppStore, T } from 'utils'
import { TSidebarType } from '../aiDiscoverInterface'
import { deleteBoard, previewImages, removeAiDiscoverTopic, truncateText } from '../commonFunction'
import { useDiscoverStore } from 'stores/DiscoverStore'

/* ******************* TYPE ******************* */
type TFilterValues = ['more-recent', 'less-recent'] | ['more-recent', 'less-recent', 'private', 'shared']

type TFilterValue = 'more-recent' | 'less-recent' | 'private' | 'shared'

/* ******************* INTERFACE ******************* */

interface Props {
  sidebarType: TSidebarType | string
  selectedBoards?: string[]
  setSelectedBoard?: (selectedBoards) => void
}

const ManageContentModal = ({ sidebarType, selectedBoards, setSelectedBoard }: Props) => {
  /* ____________________ STATE ____________________ */

  const [isInputFocus, setIsInputFocus] = useState(false)
  const [sidebarTitle, setsidebarTitle] = useState<string>('')

  // Contiene l'id degli elementi selezionati
  const [selectedContent, setSelectedContent] = useState<string[]>([])

  const [searchContent, setSearchContent] = useState<string>('')
  const [results, setResults] = useState<any[]>([])

  const [filters, setFilters] = useState<TFilterValues>()
  const [selectedFilter, setSelectedFilter] = useState<TFilterValue>('more-recent')
  const [onIsHoverFilter, setOnIsHoverFilter] = useState<boolean>(false)

  // Può essergli passato l'id dell'elemento o la stringa in caso del button "selectAll"
  const [onIsHoverSelectableItem, setOnIsHoverSelectableItem] = useState<any>(null)

  const [forceUpdate, setForceUpdate] = useState(false)

  /* ____________________ VARIABLES ____________________ */

  const topics = AppStore.discoverUser?.topicsId ?? []
  const boards = AppStore.discoverUserBoardList

  /* ____________________ ZUSTAND  ____________________ */
  const { onForceUpdate } = useDiscoverStore((state) => ({
    onForceUpdate: state.onForceUpdate,
  }))

  /* ____________________ METHODS ____________________ */

  const sidebarTitleChange = () => {
    if (sidebarType === 'follow') {
      setsidebarTitle(T.aiDiscoverPage.sidebar.sidebarTitleFollowed)
    }
    if (sidebarType === 'board') {
      setsidebarTitle(T.aiDiscoverPage.sidebar.sidebarTitleBoard)
    }
  }

  const filterTextChange = (text: string) => {
    if (text === 'more-recent') {
      return T.aiDiscoverPage.sidebar.moreRecent
    }
    if (text === 'less-recent') {
      return T.aiDiscoverPage.sidebar.lessRecent
    }
    if (text === 'private') {
      return T.aiDiscoverPage.sidebar.private
    }
    if (text === 'shared') {
      return T.AiDiscoverPage.word.shared
    }
  }

  // Controlla se tutti i risultati sono checkati
  function areAllResultsSelected(resultss, selectedContentt, SidebarType) {
    return resultss.every((result) => selectedContentt.includes(SidebarType === 'board' ? result._id : result.id))
  }

  const handleSelect = (id: string) => {
    setSelectedContent((prevSelectedItems) => {
      if (prevSelectedItems.includes(id)) {
        return prevSelectedItems.filter((itemId) => itemId !== id)
      } else {
        return [...prevSelectedItems, id]
      }
    })
  }

  const handleSelectAll = () => {
    let newSelectedContent

    if (sidebarType === 'board') {
      if (areAllResultsSelected(results, selectedContent, sidebarType)) {
        newSelectedContent = selectedContent.filter((item) => !results.some((result) => result._id === item))
      } else {
        const missingIds = results.map((item) => item._id).filter((id) => !selectedContent.includes(id))
        newSelectedContent = [...selectedContent, ...missingIds]
      }
    }

    if (sidebarType === 'follow') {
      if (areAllResultsSelected(results, selectedContent, sidebarType)) {
        newSelectedContent = selectedContent.filter((item) => !results.some((result) => result.id === item))
      } else {
        const missingIds = results.map((item) => item.id).filter((id) => !selectedContent.includes(id))
        newSelectedContent = [...selectedContent, ...missingIds]
      }
    }
    setSelectedContent(newSelectedContent)
  }

  // Background per il circle dei SelectableItem
  const getBackgroundColor = (element, elementId?) => {
    if (element === 'selectAll') {
      if (areAllResultsSelected(results, selectedContent, sidebarType)) {
        return AppStore.theme.o.green
      } else if (onIsHoverSelectableItem === 'selectAll') {
        return AppStore.theme.o.grey
      } else {
        return AppStore.theme.o.lightGrey
      }
    } else {
      if (selectedContent.includes(elementId)) {
        return AppStore.theme.o.green
      } else if (onIsHoverSelectableItem === elementId) {
        return AppStore.theme.o.grey
      } else {
        return AppStore.theme.o.lightGrey
      }
    }
  }

  // Testo del buttone che seleziona tutto
  const getSelectAllText = () => {
    if (searchContent) {
      return T.aiDiscoverPage.sidebar.selectAllResults
    } else if (selectedFilter === 'private') {
      return T.aiDiscoverPage.sidebar.selectAllPrivateBoards
    } else if (selectedFilter === 'shared') {
      return T.aiDiscoverPage.sidebar.selectAllSharedBoards
    } else {
      return T.aiDiscoverPage.sidebar.selectAll
    }
  }

  /* ____________________ API CALL ____________________ */
  const handleDelete = async (ids: string[]) => {
    if (sidebarType === 'follow') {
      await removeAiDiscoverTopic(ids, onForceUpdate)
      setForceUpdate((prev) => !prev)
    }

    if (sidebarType === 'board') {
      await deleteBoard(ids, onForceUpdate)
      setForceUpdate((prev) => !prev)
    }

    setSelectedContent([])

    // Aggiunta questa funzione in modo che se si eliminano le board selezionate nella "BottomBar" essa scompaia e non rimanga attiva
    if (setSelectedBoard) {
      setSelectedBoard([])
    }
  }
  /* ____________________ USEEFFECT ____________________ */

  // Setta titolo della modale
  useEffect(() => {
    sidebarTitleChange()
  }, [])

  // Cambia i filtri in base alle sidebarType
  useEffect(() => {
    if (sidebarType === 'follow') {
      setFilters(['more-recent', 'less-recent'])
    } else if (sidebarType == 'board') {
      setFilters(['more-recent', 'less-recent', 'private', 'shared'])
    }
  }, [])

  // Cambia i results in base alle sidebarType, testo nell'input ricercato e filtri applicati
  useEffect(() => {
    let filteredContents: any[] = []

    if (sidebarType === 'follow') {
      filteredContents = topics.filter((content) => content.title.toLowerCase().includes(searchContent.toLowerCase()))
    } else if (sidebarType === 'board') {
      filteredContents = boards.filter((content) =>
        content.boardTitle.toLowerCase().includes(searchContent.toLowerCase())
      )
    }

    // Applica i filtri
    if (selectedFilter === 'more-recent') {
      filteredContents.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    } else if (selectedFilter === 'less-recent') {
      filteredContents.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    }

    if (selectedFilter === 'private') {
      filteredContents = filteredContents.filter((content) => content.private)
    } else if (selectedFilter === 'shared') {
      filteredContents = filteredContents.filter((content) => !content.private)
    }

    setResults(filteredContents)
  }, [searchContent, selectedFilter, forceUpdate])

  // Se selectedBoards esiste, setta i loro id come già selezionati
  useEffect(() => {
    if (selectedBoards) {
      for (let i = 0; i < selectedBoards.length; i++) {
        const id = selectedBoards[i]
        setSelectedContent((prev) => [...prev, id])
      }
    }
  }, [])

  return (
    <ModalContainer>
      <ModalContent>
        <ModalHeader>
          <h4>{sidebarTitle}</h4>
          {sidebarType === 'follow' && <span>{topics.length}</span>}
          {sidebarType === 'board' && <span>{boards.length}</span>}
        </ModalHeader>
        <ModalInputs>
          <ModalSearch className={isInputFocus ? 'inputFocus' : ''}>
            <Icons.searchIcon fill={AppStore.theme.o.darkGrey} />
            <input
              onFocus={() => setIsInputFocus(true)}
              onBlur={() => setIsInputFocus(false)}
              type="text"
              placeholder={T.aiDiscoverPage.sidebar.search}
              value={searchContent}
              onChange={(e) => setSearchContent(e.target.value)}
            />
          </ModalSearch>

          <ModalFilter onMouseEnter={() => setOnIsHoverFilter(true)} onMouseLeave={() => setOnIsHoverFilter(false)}>
            <SelectedFilter>
              {filterTextChange(selectedFilter)}
              <Icons.dropdownArrow
                fill={AppStore.theme.o.black}
                className={`${onIsHoverFilter ? 'filter__hovered' : ''}`}
              />
            </SelectedFilter>
            {onIsHoverFilter && (
              <>
                <SpacerFilter />
                <ContentFilter>
                  {filters?.map((filter) => (
                    <FilterOption
                      key={filter}
                      onClick={() => {
                        setSelectedFilter(filter)
                      }}
                    >
                      {filterTextChange(filter)}
                    </FilterOption>
                  ))}
                </ContentFilter>
              </>
            )}
          </ModalFilter>
        </ModalInputs>

        {/* Buttone seleziona tutti */}
        {results.length >= 1 && (
          <ModalSelectAllButton>
            <SelectableItem
              className={areAllResultsSelected(results, selectedContent, sidebarType) ? 'selected_content' : ''}
              style={{
                border: `1px solid ${
                  areAllResultsSelected(results, selectedContent, sidebarType)
                    ? AppStore.theme.o.green
                    : AppStore.theme.o.grey
                }`,
              }}
              onClick={() => handleSelectAll()}
              onMouseEnter={() => setOnIsHoverSelectableItem('selectAll')}
              onMouseLeave={() => setOnIsHoverSelectableItem(null)}
            >
              <SelectableItemContent>
                <SelectableItemText
                  style={{
                    width: 'auto',
                    color: areAllResultsSelected(results, selectedContent, sidebarType)
                      ? AppStore.theme.o.green
                      : AppStore.theme.o.darkGrey,
                  }}
                >
                  {getSelectAllText()}
                </SelectableItemText>
                <SelectableCircle
                  style={{
                    backgroundColor: getBackgroundColor('selectAll'),
                  }}
                />
              </SelectableItemContent>
            </SelectableItem>
          </ModalSelectAllButton>
        )}

        <ModalContentContainer>
          {results.map((element) => (
            <>
              {sidebarType === 'follow' && (
                <SelectableItem
                  onMouseEnter={() => setOnIsHoverSelectableItem(element.id)}
                  onMouseLeave={() => setOnIsHoverSelectableItem(null)}
                  key={element.id}
                  className={selectedContent.includes(element.id) ? 'selected_content' : ''}
                  style={{
                    border: `1px solid ${
                      selectedContent.includes(element.id) ? AppStore.theme.o.green : AppStore.theme.o.grey
                    }`,
                  }}
                  onClick={() => handleSelect(element.id)}
                >
                  <SelectableItemContent>
                    <SelectableItemText
                      style={{
                        color: selectedContent.includes(element.id) ? AppStore.theme.o.green : AppStore.theme.o.black,
                      }}
                    >
                      <Icons.starDiscover
                        fill={selectedContent.includes(element.id) ? AppStore.theme.o.green : AppStore.theme.o.black}
                        style={{ transition: '300ms ease-in-out' }}
                      />
                      {truncateText(element.title, 14)}
                      <LanguageBoxContainer
                        style={{
                          border: `1px solid ${
                            selectedContent.includes(element.id) ? AppStore.theme.o.green : AppStore.theme.o.grey
                          }`,
                          transition: 'border-color 300ms ease-in-out',
                        }}
                      >
                        {element.language.toUpperCase()}
                      </LanguageBoxContainer>
                    </SelectableItemText>
                    <SelectableCircle
                      style={{
                        backgroundColor: getBackgroundColor(element, element.id),
                      }}
                    />
                  </SelectableItemContent>
                </SelectableItem>
              )}

              {sidebarType === 'board' && (
                <SelectableItem
                  onMouseEnter={() => setOnIsHoverSelectableItem(element._id)}
                  onMouseLeave={() => setOnIsHoverSelectableItem(null)}
                  key={element._id}
                  className={selectedContent.includes(element._id) ? 'selected_content' : ''}
                  style={{
                    border: `1px solid ${
                      selectedContent.includes(element._id) ? AppStore.theme.o.green : AppStore.theme.o.grey
                    }`,
                  }}
                  onClick={() => handleSelect(element._id)}
                >
                  <SelectableItemContent>
                    <SelectableItemText
                      style={{
                        color: selectedContent.includes(element._id) ? AppStore.theme.o.green : AppStore.theme.o.black,
                      }}
                    >
                      {element.private && (
                        <Icons.padlock
                          fill={selectedContent.includes(element._id) ? AppStore.theme.o.green : AppStore.theme.o.black}
                        />
                      )}

                      {!element.private && (
                        <Icons.collaboration
                          fill={selectedContent.includes(element._id) ? AppStore.theme.o.green : AppStore.theme.o.black}
                        />
                      )}

                      {truncateText(element.boardTitle, 14)}

                      <PreviewImagesContainer>
                        {previewImages(element.articles.slice(Math.max(element.articles.length - 3, 0)))}
                      </PreviewImagesContainer>
                    </SelectableItemText>
                    <SelectableCircle
                      style={{
                        backgroundColor: getBackgroundColor(element, element._id),
                      }}
                    />
                  </SelectableItemContent>
                </SelectableItem>
              )}
            </>
          ))}

          {results.length === 0 && <NoResultsText>{T.aiDiscoverPage.sidebar.noResultsFound}</NoResultsText>}
        </ModalContentContainer>
      </ModalContent>

      <ContainerConfirmButton>
        <ConfirmButton
          variant={selectedContent.length <= 0 ? 'neutral' : 'delete'}
          disabled={selectedContent.length <= 0}
          onClick={() => {
            handleDelete(selectedContent)
          }}
        >
          {sidebarType === 'follow'
            ? T.aiDiscoverPage.sidebar.unfollow
            : selectedContent.length > 1
            ? T.aiDiscoverPage.sidebar.deleteBoards
            : T.aiDiscoverPage.sidebar.deleteBoard}
        </ConfirmButton>
      </ContainerConfirmButton>
    </ModalContainer>
  )
}

export default ManageContentModal

const ModalContainer = styled(Box)`
  position: relative;
  min-height: 742px;
`

const ModalContent = styled(Box)`
  padding: 32px 40px;
  gap: 16px;
`

const ModalHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h4 {
    margin: 0;
    color: ${() => AppStore.theme.o.black};
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.21px;
  }

  span {
    color: ${() => AppStore.theme.o.darkGrey};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`

const ModalInputs = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 16px;
  box-sizing: border-box;
  height: 40px;
`

const ModalSearch = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 178px;
  padding: 12px 16px;
  align-items: center;
  border-radius: 14px;
  border: 1px solid ${() => AppStore.theme.o.grey};
  background: ${() => AppStore.theme.o.lightestGrey};

  input {
    border: none;
    width: 100%;
    background-color: transparent;
    color: ${() => AppStore.theme.o.darkGrey};
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  :hover {
    background-color: ${() => AppStore.theme.o.lightGrey};
  }

  &.inputFocus {
    background-color: ${() => AppStore.theme.o.lightGrey};
  }
`

const ModalFilter = styled(Box)`
  position: relative;
  width: 156px;
  display: flex;
  flex-direction: column;
  background: transparent;
`

const SelectedFilter = styled(Box)`
  display: flex;
  flex-direction: row;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border-radius: 14px;
  border: 1px solid ${() => AppStore.theme.o.grey};
  background: ${() => AppStore.theme.o.lightestGrey};
  color: ${() => AppStore.theme.o.black};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  & > svg {
    transition: 250ms ease-in-out;
  }

  & > .filter__hovered {
    rotate: -180deg;
  }
`

const SpacerFilter = styled(Box)`
  width: 100%;
  height: 10px;
  position: absolute;
  top: 100%;
  left: 0;
  background: transparent;
`

const ContentFilter = styled(Box)`
  position: absolute;
  display: flex;
  transition: 300ms ease-in-out;
  top: 110%;
  left: 0;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  z-index: 10;
  padding: 12px 16px;
  border-radius: 14px;
  border: 1px solid ${() => AppStore.theme.o.grey};
  background: ${() => AppStore.theme.o.lightestGrey};
`

const FilterOption = styled(Box)`
  border-radius: 8px;
  padding: 10px 10px;
  color: ${() => AppStore.theme.o.darkGrey};
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  :hover {
    color: ${() => AppStore.theme.o.black};
  }
`

const ModalSelectAllButton = styled(Box)`
  position: relative;
`

const ModalContentContainer = styled(Box)`
  padding: 24px;
  border: 2px dashed ${() => AppStore.theme.o.grey};
  max-height: 515px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`

const SelectableItem = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 12px 16px 12px 12px;
  margin-bottom: 10px;
  background-color: ${() => AppStore.theme.o.surface};

  &:hover {
    background-color: ${() => AppStore.theme.o.lightestGrey};
  }

  &.selected_content {
    background-color: ${() => AppStore.theme.o.lightGreen};

    &.hover {
      background-color: ${() => AppStore.theme.o.lightGreen};
    }
  }

  &:last-of-type {
    margin-bottom: 0px;
  }
`

const SelectableItemContent = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const SelectableItemText = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  position: relative;
  width: 250px;
  color: ${() => AppStore.theme.o.black};
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

const PreviewImagesContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);

  .gray_preview_img {
    width: 24px;
    height: 24px;
    border: 2px solid ${() => AppStore.theme.o.surface};
    border-radius: 50%;
    background-color: ${() => AppStore.theme.o.grey};
  }

  div {
    img {
      width: 24px;
      height: 24px;
      border: 2px solid ${() => AppStore.theme.o.surface};
      border-radius: 50%;
    }
  }

  & div:nth-of-type(1) {
    position: relative;
    right: -24px;
    z-index: 5;
  }
  & div:nth-of-type(2) {
    position: relative;
    right: -12px;
  }
`

const SelectableCircle = styled(Box)`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
`

const ContainerConfirmButton = styled(Box)`
  position: absolute;
  bottom: -55px;
  width: 100%;
`

const ConfirmButton = styled(Button)`
  min-height: 42px;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 700;
  width: 180px;
  padding: 12px 24px !important;
`

const NoResultsText = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  display: flex;
  height: 41px;
  padding: 16px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 14px;
  background: ${() => AppStore.theme.o.lightestGrey};
`

const LanguageBoxContainer = styled(Box)`
  margin-left: auto;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 20px;
  border-radius: 4px;
  font-size: 8px;
  letter-spacing: 1px;
`
