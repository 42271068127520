/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { PreviewResult } from '../../GenericComponents/PreviewResults'
import { ResultsPreviewStory } from './ResultsPreviewStory'

/* ------------------- INTERFACES  */
interface Props {}

export const InstagramStory = ({}: Props) => {
  return (
    <InstagramStoryContainer>
      <PreviewResult component={<ResultsPreviewStory />} />
    </InstagramStoryContainer>
  )
}

const InstagramStoryContainer = styled(Box)`
  width: 100%;
`
