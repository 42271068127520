import { IResponseCalendarDayPosts } from 'api/PostV2'
import { TSocialFilter } from 'components/Calendar/Sidebar/SidebarInterface'
import { ILabel } from 'components/CreatePost/PreviewComponents/LabelsComponents/LabelsContentComponents/Label'
import { AppStore } from 'utils'
import { create } from 'zustand'

export type TLabelType = 'and' | 'or'

export type TCalendarSection = 'month' | 'day'

export type TCreatedFrom = 'all' | 'autopilot' | 'manual'

export type TContentType = 'all' | 'image' | 'video' | 'text'

interface IDraftStore {
  // Per forzare l'aggiornamento dei dati delle draft dopo una modifica
  update: boolean
  forceUpdate: () => void

  // Risultati della ricerca draft
  draftPosts: IResponseCalendarDayPosts[]
  setDraftPosts: (dayPost: IResponseCalendarDayPosts[]) => void

  // ID dei post selezionati
  selectedPostsId: string[]
  setSelectedPostsId: (selectedPostsId: string[]) => void

  // Filtri
  labelType: TLabelType
  setLabelType: (labelType: TLabelType) => void

  labels: ILabel[]
  setLabels: (labels: ILabel[]) => void

  socials: string[] // id dei social accounts
  setSocials: (socials: string[]) => void

  socialFilter: TSocialFilter | null
  setSocialFilter: (socialFilter: TSocialFilter) => void

  createdFrom: TCreatedFrom
  setCreatedFrom: (createdFrom: TCreatedFrom) => void

  contentType: TContentType
  setContentType: (contentType: TContentType) => void
}

export const useDraftStore = create<IDraftStore>((set, get) => ({
  update: false,
  forceUpdate: () => set({ update: !get().update }),

  draftPosts: [],
  setDraftPosts: (draftPosts) => set({ draftPosts }),

  selectedPostsId: [],
  setSelectedPostsId: (selectedPostsId) => set({ selectedPostsId }),

  labelType: 'and',
  setLabelType: (labelType) => set({ labelType }),

  labels: [],
  setLabels: (labels) => set({ labels }),

  socials: AppStore.socialAccounts.map((account) => account._id!),
  setSocials: (socials) => set({ socials }),

  socialFilter: null,
  setSocialFilter: (socialFilter) => set({ socialFilter }),

  createdFrom: 'all',
  setCreatedFrom: (createdFrom) => set({ createdFrom }),

  contentType: 'all',
  setContentType: (contentType) => set({ contentType }),
}))
