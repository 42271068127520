import { SearchOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Tracker } from 'api/Tracker'
import Workspaces from 'api/Workspaces'
import { Box, Button, Input } from 'components'
import { Icons } from 'components/Icons'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Workspace } from 'types'
import { AppStore, T, showInfo } from 'utils'
import { RemoteConfigValues, isVisible } from 'utils/CheckVisibleElement'
import {
  OWNER_COLLABORATORS_LIMITS_AVAILABLE,
  OWNER_COLLABORATORS_LIMITS_INFINITE,
  OWNER_COLLABORATORS_LIMITS_USED,
} from 'utils/Constants/LimitUserConstants'
import { TRACKEREVENTS, getWorkspaceSettingsPageData } from 'utils/eventsTracker'
import {
  MixpanelWorkspaceSettingsPage,
  TWorkspaceSettingsStatus,
} from 'utils/eventsTracker/workspace/workspaceInterfaces'
import { navigate } from 'utils/router'
import InviteMembers from './InviteMembers'
import RoleGridSection from './RoleGridSection'
import UsersGridSection from './UsersGridSection'
import { requestUpgradeModal } from './HeaderUpgradeButton'
import { ImageWithFallback } from './UI/ImageWithFallback'
import MediaUtils from 'utils/MediaUtils'

interface Props {
  changeSection: (section: string, workspace: Workspace) => void
  workspace: Workspace
  openWorkspaceModal: (workspace?: Workspace, onUpdateData?: () => void) => void
  onWorkspaceChange: (workspace: Workspace) => void
}

interface ICollaborator {
  name: string
  email: string
  role: string
  invitationConfirmed: boolean
  id: string
}

const WorkspaceManagement = ({ changeSection, workspace, openWorkspaceModal, onWorkspaceChange }: Props) => {
  /* ------------------- ROUTER  */
  const { workspaceId } = useParams()

  /* ------------------- STATE  */
  const [searchText, setSearchText] = useState('')
  const [currentWorkspace, setCurrentWorkspace] = useState<Workspace>()
  const [Users, setUsers] = useState<Array<ICollaborator | undefined>>([])
  const [filteredUsers, setFilteredUsers] = useState<Array<ICollaborator | undefined>>([])
  const [arrayRolesPermissions, setArrayRolesPermissions] = useState<any>([])

  // Per aggiornare i dati dello user alla eliminazione di un collaboratore
  const [forceUpdate, setForceUpdate] = useState(false)

  /* ------------------- VARIABLES  */
  const allPermissions = [
    {
      name: T.teamMembers.rulesTitle.publication,
      roles: ['edit_post', 'publish_post', 'ai_copy_generator', 'manage_draft'],
    },

    {
      name: T.teamMembers.rulesTitle.calendar,
      roles: ['calendar_show_posts'],
    },

    {
      name: T.teamMembers.rulesTitle.media,
      roles: ['upload_media', 'manage_media', 'media_editor'],
    },

    {
      name: T.teamMembers.rulesTitle.analytics,
      roles: ['create_report', 'view_report', 'share_report'],
    },

    {
      name: T.teamMembers.rulesTitle.discover,
      roles: ['search_content', 'manage_discover'],
    },

    {
      name: T.teamMembers.rulesTitle.settings,
      roles: ['link_social_account', 'delete_social_account', 'edit_workspaces'],
    },
  ]

  /* ------------------- METHODS  */
  const handleForceUpdate = () => {
    setForceUpdate((prev) => !prev)
  }

  const getWorkspaceSettingsData = () => {
    const numUsers: number = workspace?.collaborators?.length ?? 0
    const workspaceName: string = workspace?.name ?? ''
    const timezone = `${workspace?.timezone?.name} (${workspace?.timezone?.value})` ?? ''
    const workspaceStatus: TWorkspaceSettingsStatus = numUsers > 0 ? 'Shared' : 'Private'

    const data: MixpanelWorkspaceSettingsPage = {
      num_users: numUsers,
      workspace_name: workspaceName,
      time_zone: timezone,
      workspace_status: workspaceStatus,
    }

    Tracker.trackEvent(TRACKEREVENTS.WORKSPACE_SETTINGS_VISITED, getWorkspaceSettingsPageData(data))
  }

  const callForCurrentWorkspace = async () => {
    const workspaceCurrent = (await Workspaces.checkPermission(undefined, workspaceId)) as Workspace

    const workingWorkspace = [
      ...AppStore.loggedUser.collabWorkspace.map((cw) => cw.workspace),
      ...AppStore.loggedUser.workspaces,
    ].find((w) => w?._id === workspaceCurrent?._id)

    const image = workingWorkspace?.image

    setCurrentWorkspace({ ...workspaceCurrent, image: image })
  }

  function comparePermissions(roleToControl, toVerify, roleName) {
    const newArray: any = []

    const matchedPermissions = roleToControl.map((permission) => {
      const matchedRoles = permission.roles.filter((role) => toVerify.includes(role))
      return {
        name: permission.name,
        permissionSelected: matchedRoles.length ?? 0,
      }
    })
    const newObject = {
      roleName: roleName,
      rolesNumber: matchedPermissions,
    }
    newArray.push(newObject)

    return newArray
  }

  const findRole = (roleId: string) => {
    const workspaceRole = currentWorkspace?.roles
    if (workspaceRole !== undefined) {
      const index = workspaceRole.findIndex((role) => role._id === roleId)
      if (index === -1) {
        return { name: 'Undefined', permissions: [], description: '' }
      }
      return workspaceRole[index]
    }
    return { name: '', permissions: [], description: '' }
  }

  const openManageRoles = () => {
    AppStore.openModal({
      id: 'preview-modal',
      body: (
        <RoleGridSection
          changeSession={(newSessions) => changeSection(newSessions, currentWorkspace!)}
          workspace={currentWorkspace}
          users={filteredUsers}
          rolesNumber={arrayRolesPermissions}
          onWorkspaceChange={onWorkspaceChange}
        />
      ),
      style: {
        minWidth: 1079,
      },
    })
    Tracker.trackEvent(TRACKEREVENTS.WORKSPACE_ROLES_VISITED, { num_roles: workspace.roles?.length })
  }

  const openInviteMemberModal = () => {
    if (!OWNER_COLLABORATORS_LIMITS_INFINITE) {
      if (OWNER_COLLABORATORS_LIMITS_USED >= OWNER_COLLABORATORS_LIMITS_AVAILABLE) {
        requestUpgradeModal()
        return
      }
    }

    AppStore.openModal({
      id: 'preview-modal',
      body: (
        <InviteMembers
          workspace={currentWorkspace!}
          changeSession={(newSession) => changeSection(newSession, workspace)}
          onWorkspaceChange={onWorkspaceChange}
        />
      ),
      style: {
        minHeight: 213,
        maxHeight: 678,
        minWidth: 669,
        backgroundColor: 'transparent',
      },
    })
  }

  /* ------------------- USEFFECT  */
  useEffect(() => {
    if (workspace) {
      setCurrentWorkspace(workspace)
      callForCurrentWorkspace()
      getWorkspaceSettingsData()
    } else if (workspaceId) {
      callForCurrentWorkspace()
      getWorkspaceSettingsData()
    } else {
      navigate('/settings/workspace')
    }
  }, [workspace, forceUpdate])

  useEffect(() => {
    const collaborators = currentWorkspace?.collaborators

    const newUsers = collaborators?.map((collaborator) => {
      if (collaborator.user === null) return
      return {
        name: collaborator.user.name,
        email: collaborator.user.email,
        role: findRole(collaborator.roleId).name,
        invitationConfirmed: collaborator.tempToken === undefined ? true : false,
        id: collaborator.user._id ?? '',
        collabId: collaborator._id ?? '',
      }
    })

    if (newUsers && newUsers.length > 0) {
      setUsers(newUsers)
      setFilteredUsers(newUsers)
    }
    const roleArray = currentWorkspace?.roles?.map((collaborator) => {
      return {
        roleName: collaborator.name,
        roles: collaborator.permissions,
      }
    })
    const numbersOfRole = roleArray?.map((role) => {
      return comparePermissions(allPermissions, role.roles, role.roleName)
    })

    if (roleArray !== undefined) setArrayRolesPermissions(numbersOfRole?.flat())
  }, [currentWorkspace])

  useEffect(() => {
    setFilteredUsers(Users)
  }, [Users])

  useEffect(() => {
    setFilteredUsers(
      Users.filter((user) => {
        if (user?.name !== undefined && user !== undefined)
          return user.name.toLowerCase().includes(searchText.toLowerCase())
      })
    )
  }, [searchText])

  return currentWorkspace ? (
    <Box animation="fade" flex pv={64} ph={56} relative style={{ overflow: 'hidden' }}>
      <Box vcenter row>
        <BackwardIcon
          id={`workspace-row-${currentWorkspace._id}`}
          onClick={() => {
            changeSection('workspace', currentWorkspace)
          }}
          width={20}
          height={20}
          style={{ marginRight: '12px' }}
        />

        <ImageWithFallback
          fallbackSrc={MediaUtils.workspacePlaceholder}
          style={{ marginRight: 20, borderRadius: 50 }}
          height={50}
          width={50}
          src={currentWorkspace.image?.presignedUrl}
        />

        <TitleText>{currentWorkspace.name}</TitleText>

        <EditIcon
          id={`workspace-row-${currentWorkspace._id}`}
          onClick={() =>
            openWorkspaceModal(currentWorkspace, () => {
              setForceUpdate((prev) => !prev)
            })
          }
          width={30}
          height={30}
        />
      </Box>

      <Box mb={24} mt={16} row style={{ justifyContent: 'space-between' }} removeHover>
        <Input
          style={{ width: 310, height: 44, borderRadius: 14, color: AppStore.theme.o.black }}
          placeholder={T.teamMembers.searchTeamMember}
          prefix={<SearchOutlined />}
          onChange={(txt) => setSearchText(txt)}
        />

        <WorkspaceSettingsButtons removeHover>
          {isVisible(RemoteConfigValues.NotificationDisabled) && (
            <WorkspaceSettingButton variant="neutral" onClick={() => changeSection('notification', workspace)}>
              <ButtonText>{T.settings.manageNotifications}</ButtonText>
            </WorkspaceSettingButton>
          )}

          <WorkspaceSettingButton onClick={() => openManageRoles()} id="manageRoleModalOpen" variant="neutral">
            <ButtonText>{T.teamMembers.manageRole}</ButtonText>
          </WorkspaceSettingButton>

          <WorkspaceSettingButton
            id="add-workspace-button-setting"
            variant="primary"
            onClick={() => openInviteMemberModal()}
          >
            <ButtonText>{T.teamMembers.inviteMembers}</ButtonText>
          </WorkspaceSettingButton>
        </WorkspaceSettingsButtons>
      </Box>

      <UsersGridSection
        changeSession={changeSection}
        workspace={currentWorkspace}
        users={filteredUsers}
        rolesNumber={arrayRolesPermissions}
        onWorkspaceChange={onWorkspaceChange}
        handleForceUpdate={handleForceUpdate}
      />
    </Box>
  ) : (
    <></>
  )
}
export default WorkspaceManagement

const TitleText = styled.p`
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 27px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: ${() => AppStore.theme.o.green};
`

const BackwardIcon = styled(Icons.backward)`
  cursor: pointer;
  fill: ${() => AppStore.theme.o.black};
  :hover {
    fill: ${() => AppStore.theme.o.blueOnLight};
  }
`

const ButtonText = styled.p`
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
`

const EditIcon = styled(Icons.edit)`
  margin-left: 12px;
  cursor: pointer;
  fill: ${() => AppStore.theme.o.black};
  :hover {
    fill: ${() => AppStore.theme.o.blueOnLight};
  }
`

const WorkspaceSettingButton = styled(Button)`
  margin-right: 8px;
  min-height: 42px !important;
  padding: 8px 40px !important;
  font-size: 14px !important;
`

const WorkspaceSettingsButtons = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16;
`
