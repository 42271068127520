/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Text } from 'components'
import { SpinnerAstersLogo } from 'components/UI'

/* ------------------- INTERFACES  */

interface Props {
  name: string
  value: number | string | undefined
  icon: JSX.Element
}

export const EnvironmentCard = ({ name, value, icon }: Props) => {
  return (
    <EnvironmentCardContainer>
      <CardInfo>
        <Text fontSize={16} weight={700}>
          {name}
        </Text>

        <Box>
          {typeof value !== 'undefined' && (
            <Text
              fontSize={61}
              weight={700}
              style={{
                lineHeight: 0.8,
              }}
            >
              {value}
            </Text>
          )}

          {typeof value === 'undefined' && <SpinnerAstersLogo dimension={40} />}
        </Box>
      </CardInfo>

      <Box width={88} height={88}>
        {icon}
      </Box>
    </EnvironmentCardContainer>
  )
}

const EnvironmentCardContainer = styled(Box)`
  flex-direction: row;
  justify-content: space-between;
  width: 290px;
`

const CardInfo = styled(Box)`
  position: relative;
  gap: 16px;
`
