/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { isExternalElementClicked } from 'api/Utils'
import { Box, Button, Icons } from 'components'
import { LabelsContent } from 'components/CreatePost/PreviewComponents/LabelsComponents/LabelsContent'
import { ILabel } from 'components/CreatePost/PreviewComponents/LabelsComponents/LabelsContentComponents/Label'
import { CloseButton } from 'components/UI'
import { useEffect, useRef, useState } from 'react'
import { useCalendarStore } from 'stores/CalendarStore'
import { AppStore, showInfo, T } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {}

export const LabelFilterCalendar = ({}: Props) => {
  /* ----------------- STATE  */
  const [openFilter, setOpenFilter] = useState<boolean>(false)

  /* ----------------- REF  */
  const labelContainerRef = useRef<HTMLDivElement>(null)

  /* ----------------- ZUSTAND  */
  const { labels, labelType } = useCalendarStore((s) => ({ labels: s.labels, labelType: s.labelType }))

  const { setLabels, forceUpdate, setLabelType } = useCalendarStore()

  /* ----------------- VARIABLES  */
  const hasLabels: boolean = labels?.length > 0

  const variantType = hasLabels ? 'secondary' : 'neutral'

  /* ----------------- METHODS  */
  const openLabelFilter = () => {
    setOpenFilter((prev) => !prev)
  }

  const resetSelectedLabels = (e) => {
    e.stopPropagation()
    setLabels([])
    forceUpdate()
  }

  /**
   * Funzione per aggiungere una label ai label selezionati
   * @param label Label to add
   */
  const handleAddLabel = (label: ILabel) => {
    // Verifica se la label è già stata aggiunta
    const isAlreadyAdded = labels?.find((lb) => lb._id === label._id)

    // Se la label è già stata aggiunta, non fare nulla
    if (isAlreadyAdded) {
      return showInfo(T.postPage.labelsSection.alreadyAdded)
    }

    // Altrimenti, aggiungi la label ai label selezionati
    const newSelected = [...labels!, { ...label }]

    setLabels(newSelected)
    forceUpdate()
  }

  /**
   * Funzione per rimuovere una label dai label selezionati
   * @param label Label to remove
   */
  const handleRemoveLabel = (label: ILabel) => {
    const newSelected = labels?.filter((lb) => lb._id !== label._id)

    setLabels(newSelected)
    forceUpdate()
  }

  /**
   * Funzione per chiude il filtro delle label se si clicca all'esterno del contenitore
   * @param e Element where the click event was triggered
   */
  const handleClose = (e) => {
    const elToCheck = e.target as Element

    const labelRef = labelContainerRef?.current

    if (elToCheck.classList.contains('calendar_label')) return

    if (isExternalElementClicked(labelRef, elToCheck)) setOpenFilter(false)
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    if (openFilter) window.addEventListener('click', handleClose)
    return () => window.removeEventListener('click', handleClose)
  })

  return (
    <LabelFilterCalendarContainer
      variant={variantType}
      center
      onClick={openLabelFilter}
      forwardedRef={labelContainerRef}
      className="calendar_label"
    >
      <Icons.label
        className="calendar_label"
        fill={hasLabels ? AppStore.theme.o.on_secondary_container : AppStore.theme.o.black}
        width={24}
        height={24}
      />

      <LabelFilter
        className={`${openFilter ? 'active' : 'not-active'}`}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <LabelsContent
          selectedLabels={labels}
          setSelectedLabels={setLabels}
          handleAddLabel={handleAddLabel}
          handleRemoveLabel={handleRemoveLabel}
          fromSection={'calendar'}
          labelsFilter={labelType}
          setLabelsFilter={setLabelType}
        />
      </LabelFilter>

      {hasLabels && <CloseButton action={resetSelectedLabels} />}
    </LabelFilterCalendarContainer>
  )
}

const LabelFilterCalendarContainer = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px !important;
  min-height: 40px !important;
  height: 40px !important;
  position: relative;
`

const LabelFilter = styled(Box)`
  position: absolute;
  left: 0;
  border-radius: 14px;
  background-color: ${() => AppStore.theme.o.surface};
  box-shadow: ${() => AppStore.theme.smallBoxShadow};
  transition: 300ms ease-in-out;

  &.not-active {
    scale: 0;
    top: -100%;
    z-index: -1;
    opacity: 0;
    width: 0;
    height: 0;
  }

  &.active {
    scale: 1;
    top: calc(100% + 29px);
    z-index: 1;
    opacity: 1;
    width: 366px;
    height: 474px;
  }

  cursor: default;
  &:hover {
    background: ${() => AppStore.theme.o.surface};
  }
`
