/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { useEffect, useState } from 'react'
import { AppStore } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  refreshAction: any
  style?: React.CSSProperties
}

export const RefreshButton = ({ refreshAction, style }: Props) => {
  /* ----------------- STATE  */
  const [isClicked, setIsClicked] = useState(false)

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */
  const handleRefresh = async () => {
    setIsClicked(!isClicked)
    try {
      await refreshAction()
    } catch (error) {}
  }

  /* ----------------- USEEFFECT  */

  return (
    <RefreshButtonContainer style={style} onClick={handleRefresh}>
      <Icons.refresh
        fill={AppStore.theme.o.black}
        style={{
          backgroundColor: 'transparent',
          transform: `rotate(${isClicked ? '360deg' : '0deg'})`,
          transition: 'transform 0.5s',
        }}
      />
    </RefreshButtonContainer>
  )
}

const RefreshButtonContainer = styled(Box)``
