import BillingService from 'api/service/BillingService'
import { IProrateData } from 'components/Modal/Billing/UpgradeOptions/UpgradeWithProrate'
import { create } from 'zustand'

interface IUpgradePlanStore {
  processing: boolean
  setProcessing: (processing: boolean) => void

  prorateData: null | IProrateData
  requestProrateData: (planId: string) => void

  requestUpgradePlan: (planId: string, prorate: boolean) => Promise<any>
}

export const useUpgradePlanStore = create<IUpgradePlanStore>((set, get) => ({
  processing: false,
  setProcessing: (processing) => set({ processing }),

  prorateData: null,
  requestProrateData: async (planId) => {
    set({ processing: true })
    const { data } = await BillingService.recoverProrateData(planId)
    set({ prorateData: data, processing: false })
  },

  requestUpgradePlan: async (planId: string, prorate: boolean) => {
    return new Promise(async (resolve, reject) => {
      set({ processing: true })
      const { data } = await BillingService.upgradePlan(planId, prorate)
      setTimeout(async () => {
        set({ processing: false })
        resolve(data)
      }, 2000)
    })
  },
}))
