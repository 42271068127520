/* ******************* IMPORT ******************* */
import styled from '@emotion/styled'
import { Box } from 'components'
import { useState } from 'react'
import { AppStore } from 'utils'

/* ******************* TYPE ******************* */

//Questo Toggler ha 3 stati, completo, non completo, completo ma non del tutto.
type TStyles = 'all-checked' | 'some-checked' | 'nothing-checked'

/* ******************* INTERFACE ******************* */

interface Props {
  value: TStyles | (() => TStyles)
  setValue: () => void
  id: string
  className?: string
}

export const Toggler = ({ value, setValue, id, className }: Props) => {
  //  ;'all-checked' | 'some-checked' | 'nothing-checked'
  const [computedValue, setComputedValue] = useState<TStyles>(typeof value === 'function' ? value() : value)

  return (
    <TogglerContainer
      className={`${className} ${computedValue}`}
      id={id}
      onClick={() => {
        // setComputedValue(computedValue === 'all-checked' ? 'nothing-checked' : 'all-checked')
        // setTimeout(() => {
        setValue()
        // }, 300)
      }}
    >
      <TogglerBall id={id} className={computedValue} />
    </TogglerContainer>
  )
}

const TogglerContainer = styled(Box)`
  width: 40px !important;
  height: 20px !important;
  border-radius: 11.79px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  position: relative;

  &.all-checked {
    background-color: ${() => AppStore.theme.o.lightBlue};
    outline: 1px solid ${() => AppStore.theme.o.blue};
  }

  &.some-checked {
    background-color: ${() => AppStore.theme.o.grey};
    outline: 1px solid ${() => AppStore.theme.o.grey};
  }

  &.nothing-checked {
    background-color: ${() => AppStore.theme.o.grey};
    outline: 1px solid ${() => AppStore.theme.o.grey};
  }
`

const TogglerBall = styled(Box)`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  position: absolute;
  transition: 0.3s ease-in-out;

  &.all-checked {
    background-color: ${() => AppStore.theme.o.blue};
    right: 2px;
  }

  &.some-checked {
    background-color: ${() => AppStore.theme.o.black};
    right: 2px;
  }

  &.nothing-checked {
    background-color: ${() => AppStore.theme.o.surface};
    left: 2px;
  }
`
