import styled from '@emotion/styled'
import { postMarkNotificationAsRead } from 'api/service/notifyServices'
import { Box } from 'components/Box'
import { Icons } from 'components/Icons'
import { SocialIcon, SocialIconsProvider } from 'components/SocialIcon'
import { dateToString } from 'pages/ai-discover/commonFunction'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCalendarStore } from 'stores/CalendarStore'
import { useModalNavigationStore } from 'stores/ModalNavigationStore'
import { useNotifyStore } from 'stores/NotifyStore'
import { INotifications } from 'types/NotifyInterface'
import { AppStore } from 'utils'

/* ___________________ INTERFACE ___________________ */
interface Props {
  notice: INotifications
  changeNotifications: () => void
}

const NotifyCard = ({ notice, changeNotifications }: Props) => {
  /* ____________________ STATE ____________________ */
  const [forceSetFalse, setForceSetFalse] = useState(false)
  const refCard = useRef<HTMLDivElement>(null)

  /* ____________________ VARIABLES ____________________ */
  const userId = AppStore.loggedUser._id

  /* ____________________ zustand ____________________ */
  const { addNotificationReaded } = useNotifyStore.getState()

  /* ____________________ ROUTER ____________________ */
  const navigate = useNavigate()

  /* ____________________ METHODS ____________________ */
  const noticeIsReaded = () => {
    if (forceSetFalse) return false

    const userJustReaded = notice?.notificationReaded?.find((el) => el === userId)

    return userJustReaded ? false : true
  }

  const onReadNotify = (e) => {
    e.stopPropagation()
    addNotificationReaded(notice._id!)
    postMarkNotificationAsRead(notice._id!)
  }

  /* ____________________ API ____________________ */

  const onClickNotificationCard = async () => {
    try {
      // Setta la notifica come letta
      if (noticeIsReaded()) {
        addNotificationReaded(notice._id!)
        await postMarkNotificationAsRead(notice._id!)
      }

      // Gestisce la navigazione alla destinazione della notifica
      const invalidPaths = ['/settings/subscription', '/settings/subscription/', '/settings/discover/']
      const trimmed = notice.on_click_destination.replace('https://app.asters.ai', '')
      if (invalidPaths.includes(trimmed)) navigate('/dashboard')
      else navigate(trimmed)
    } catch (e) {
      console.error(e)
    }
  }

  const notificationCategoriesIcon = {
    Settings: <Icons.notificationSettings />,
    Publish: <Icons.notificationPublish />,
    Discover: <Icons.notificationDiscover />,
  }

  return (
    <CardNotify
      ref={refCard}
      onClick={onClickNotificationCard}
      className={`notify-card ${noticeIsReaded() ? 'to-read' : ''}`}
    >
      <ImageNotifyContainer>
        {/* _________________________ ICON-default or IMAGE-social-account  */}
        {notice.socialAccount?.image ? (
          <img src={notice.socialAccount.image} alt="social account" />
        ) : (
          notificationCategoriesIcon[notice.category] ?? <Icons.logo />
        )}
        {/* _________________________ ICONE SOCIAL  */}
        {notice.socialAccount && (
          <SocialIcon
            style={{
              width: 24,
              height: 24,
              position: 'absolute',
              top: 20,
              left: 0,
            }}
            social={notice.socialAccount.provider as SocialIconsProvider}
          />
        )}
      </ImageNotifyContainer>

      <IconTextContainer>
        {/* _________________________ TITLE  */}
        <NotifyTitle className="notify-title">{notice.title}</NotifyTitle>
        {/* _________________________ DESCRIPTION  */}
        <NotifyDescription className="notify-description">{notice.description}</NotifyDescription>
        {/* _________________________ DATE  */}
        <NotifyDate className="notify-date">{dateToString(notice.updated_at)}</NotifyDate>
      </IconTextContainer>

      {/* _________________________ ICON NOTIFY read or not read  */}
      <IconNotifyContainer>
        {noticeIsReaded() ? (
          <Icons.notifyToRead className="icon-to-read" onClick={onReadNotify} />
        ) : (
          <Icons.notifyReaded className="icon-readed" />
        )}
      </IconNotifyContainer>
    </CardNotify>
  )
}

export default NotifyCard

const CardNotify = styled(Box)`
  background-color: ${() => AppStore.theme.o.lightestGrey};
  width: 100%;
  min-height: 95px;
  max-height: 137px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 16px;
  justify-content: space-between;
  border-radius: 14px;
  border: 1px solid transparent;
  color: ${() => AppStore.theme.o.black};

  &.to-read {
    color: ${() => AppStore.theme.o.blueOnLight};
    background: ${() => AppStore.theme.o.lightBlue};

    .notify-date {
      color: ${() => AppStore.theme.o.blueOnLight};
    }

    &:hover {
      border: 1px solid ${() => AppStore.theme.o.blueOnLight};
    }
  }

  &:hover {
    border: 1px solid ${() => AppStore.theme.o.grey};
  }
`

const ImageNotifyContainer = styled(Box)`
  min-width: 40px;
  min-height: 36px;
  max-width: 40px;
  max-height: 36px;
  position: relative;
  background-color: ${() => AppStore.theme.o.white};

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  svg {
    min-width: 40px;
    min-height: 36px;
    max-width: 40px;
    max-height: 36px;
  }
`

const IconTextContainer = styled(Box)`
  flex: 2;
  gap: 8px;
  border-radius: 0;
`

const NotifyTitle = styled(Box)`
  font-size: 16px;
  font-weight: 700;
  line-height: 20.8px;
  letter-spacing: 0.16px;
  border-radius: 0;
  width: 100%;
`

const NotifyDescription = styled(Box)`
  display: block;
  border-radius: 0;
  max-width: 255px;
  /*   per generare i puntini senza Javascript */
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* number of lines to show */
  white-space: normal;
`

const NotifyDate = styled(Box)`
  font-size: 12px;
  font-weight: 400;
  line-height: 15.36px;
  letter-spacing: 0.12px;
  color: ${() => AppStore.theme.o.darkGrey};
`

const IconNotifyContainer = styled(Box)`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0;

  svg {
    min-width: 24px;
    min-height: 24px;
    transition: all 0.3s ease-in-out;

    &.icon-to-read {
      cursor: pointer;
    }
    &.icon-readed {
      scale: 1 !important;
    }

    &:hover {
      scale: 1.1;
    }
    &:active {
      scale: 0.9;
    }
  }
`
