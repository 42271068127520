import axios from 'axios'
import { MetaPreview } from 'types'
import { IShortUrl } from 'types/PostInterface'
import { AppStore } from 'utils'

// <-------------------------INFERFACE------------------------->

export default class ShortUrl {
  static endpoint = process.env.REACT_APP_BACKEND_SHORT_URL ?? 'https://api.aste.rs'

  // Passando un link e workspaceId ritorna shortUrl e i metadati
  static async getShortLinkAndMetadata(
    originUrl: string,
    type: 'shorten' | 'standard',
    ref?: string
  ): Promise<IShortUrl> {
    const workspaceId = AppStore.getWorkspaceId()

    const correctedUrl = originUrl.startsWith('http') ? originUrl : `https://${originUrl}`
    const data = {
      workspaceId,
      originUrl: correctedUrl,
      userDomain: AppStore.getUserDomain(),
      type,
      ref,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
    }

    return axios
      .post<IShortUrl>(`${this.endpoint}/shortUrl`, data, { headers })
      .then((response) => response.data)
      .catch((error) => {
        console.error('Errore nella chiamata a getShortLinkAndMetadata:', error)
        throw error
      })
  }

  static async modifyMetadata(urlId: string, metadata: MetaPreview) {
    const workspaceId = AppStore.getWorkspaceId()

    const data = {
      workspaceId,
      urlId,
      ...metadata,
    }

    return axios
      .post(`${this.endpoint}/metaData`, data)
      .then((response) => response.data)
      .catch((error) => {
        // Gestisci l'errore qui
        console.error('Errore nella chiamata a modifyMetadata:', error)
        throw error
      })
  }

  static async getMetadata(url: string): Promise<IShortUrl['metaData']> {
    return axios
      .get<IShortUrl['metaData']>(`${this.endpoint}/metaData?url=${url}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error
      })
  }
}
