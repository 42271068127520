/* ___________________ IMPORTS ___________________ */
import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Box } from 'components'
import { T } from 'utils'
import AppStore from 'utils/AppStore'
import { dateToString, getArticlebyKeyword, getArticlebyTopicList, simpleFormatDate } from '../../commonFunction'
import DiscoverSearchFilters from './DiscoverSearchFilters'
import ToggleFollowingTopicButton from 'pages/ai-discover/UI/ToggleFollowingTopicButton'
import { useDiscoverStore } from 'stores/DiscoverStore'
import { RefreshButton } from 'components/RefreshButton'
import { MAX_ARTICLE_NUMBER } from 'utils/Constants/Constants'
import { IArticle } from 'pages/ai-discover/aiDiscoverInterface'

interface Props {}

const FollowPageHeader = ({}: Props) => {
  const [dateLastUpdate, setDateLastUpdate] = useState<Date>(new Date())

  /* ____________________ ZUSTAND  ____________________ */

  const {
    activeTopicSidebar,
    trendingArticlesByFollowedTopicPage,
    setTrendingArticlesByFollowedTopic,
    setTrendingArticlesByFollowedTopicPage,
    languageFilterFollow,
    dateFilterFollow,
    setTrendingArticlesByTopicList,
  } = useDiscoverStore.getState()

  /* __________ METHODS __________ */
  const changeLastUpdate = () => {
    setDateLastUpdate(new Date())
  }

  const refreshAction = async () => {
    try {
      let updatedArticles
      // Calcola la prossima pagina da caricare
      const nextPage = trendingArticlesByFollowedTopicPage + 1

      // Formatta le date di inizio e fine per la ricerca
      const startDate = simpleFormatDate(dateFilterFollow[0])
      const endDate = simpleFormatDate(dateFilterFollow[1])

      // Se è stato selezionato un topic specifico, recupera gli articoli per quell'topic
      if (activeTopicSidebar) {
        updatedArticles = await getArticlebyKeyword(
          activeTopicSidebar.title,
          activeTopicSidebar.language,
          nextPage,
          MAX_ARTICLE_NUMBER,
          startDate,
          endDate
        )
      } else {
        // Altrimenti, recupera gli articoli per la lista degli topic seguiti
        const updatedArticlesTopicList: IArticle[] = await getArticlebyTopicList(
          nextPage,
          MAX_ARTICLE_NUMBER,
          startDate,
          endDate,
          languageFilterFollow[0]
        )
        setTrendingArticlesByTopicList(updatedArticlesTopicList)
      }

      // Aggiorna lo stato con i nuovi articoli
      setTrendingArticlesByFollowedTopic(updatedArticles)
      setTrendingArticlesByFollowedTopicPage(nextPage)
    } catch (error) {
      // Gestisce eventuali errori durante il recupero degli articoli
      console.error('Errore durante il refresh', error)
    }
  }

  /* __________ USEEFFECT __________ */
  useEffect(() => {
    changeLastUpdate()
  }, [activeTopicSidebar])

  return (
    <FollowPageHeaderContainer row className="discover-top">
      <BoxContainer>
        {activeTopicSidebar ? (
          <ArticleBox>
            <LastUpdateText>{T.AiDiscoverPage.followPage.followArticleFor}</LastUpdateText>
            <TextTitleHeader>{activeTopicSidebar.title}</TextTitleHeader>
            <ToggleFollowingTopicButton fromArticle={true} />
          </ArticleBox>
        ) : (
          <TextTitleHeader>{T.AiDiscoverPage.followPage.followArticle}</TextTitleHeader>
        )}
        <LastUpdateText>
          {T.AiDiscoverPage.lastUpdate}
          {dateToString(dateLastUpdate)}
        </LastUpdateText>
      </BoxContainer>

      <DiscoverSearchFilters />
      <RefreshButton
        refreshAction={refreshAction}
        style={{
          backgroundColor: `${AppStore.theme.o.lightestGrey}`,
          border: `1px solid ${AppStore.theme.o.grey}`,
          width: '44px',
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '14px',
        }}
      />
    </FollowPageHeaderContainer>
  )
}

export default FollowPageHeader

const FollowPageHeaderContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`

const ArticleBox = styled(Box)`
  flex-direction: row;
  display: flex;
  gap: 15px;
  align-items: center;
`

const BoxContainer = styled(Box)`
  height: 64px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  gap: 4px;
  margin-inline: auto;
  text-align: center;
`

const TextTitleHeader = styled.p`
  color: ${() => `${AppStore.theme.o.black}`};
  font-family: Aktiv Grotesk;
  font-size: 21px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.21px;

  overflow-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
  max-width: 230px;
  display: contents;
`

const LastUpdateText = styled.p`
  color: ${() => `${AppStore.theme.o.darkGrey}`};
  text-align: right;
  font-family: Aktiv Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1px;
`
