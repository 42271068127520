/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { TextContainer } from '../../GenericComponents/TextContainer'
import { PreviewResult } from '../../GenericComponents/PreviewResults'
import { ResultsPreviewInstagramStandard } from './ResultsPreviewInstagramStandard'
import { useState } from 'react'
import { ResultsPreviewInstagramReel } from './ResultsPreviewInstagramReel'

/* ------------------- INTERFACES  */
interface Props {
  active: string
}

export const InstagramStandard = ({ active }: Props) => {
  /* ----------------- STATE  */
  const [mediaIndex, setMediaIndex] = useState<number>(0)

  /* ----------------- VARIABLES  */
  const componentToRender =
    active === 'INSTAGRAM_STANDARD' ? (
      <ResultsPreviewInstagramStandard mediaIndex={mediaIndex} setMediaIndex={setMediaIndex} />
    ) : (
      <ResultsPreviewInstagramReel mediaIndex={mediaIndex} setMediaIndex={setMediaIndex} />
    )

  return (
    <InstagramStandardContainer>
      <PreviewResult component={componentToRender} />
      <TextContainer />
    </InstagramStandardContainer>
  )
}

const InstagramStandardContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 24px;
  position: relative;
`
