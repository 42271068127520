import styled from '@emotion/styled'
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { Icons } from 'components/Icons'
import { Text } from 'components/Text'
import { Component } from 'react'
import { AppStore } from 'utils'
import { navigate } from 'utils/router'

interface Props {
  goNext: any
  selected: string
  goBack: any
}
export default class ThirdPage extends Component<Props> {
  render() {
    return (
      <Box style={{ overflow: 'hidden' }} flex center>
        <Container>
          <MiniBox center>
            <Text weight={500} fontSize={16} style={{ textAlign: 'center', marginBottom: 8 }}>
              Siamo pronti! 🔥 <br /> Stai per vivere l'esperienza nella <br />
              creazione di un post grazie alla
              <br /> straordinaria potenza dell'intelligenza <br /> artificiale di Asters!
              <br /> Ciò che stai per vedere è una demo <br /> guidata dimostrativa. <br />
              Segui le semplici istruzioni a schermo <br />e buona creazione 🚀
            </Text>
          </MiniBox>

          <Selectble
            onClick={() => {
              this.props.goNext()
              !navigate('/demo/step4')
            }}
          >
            <Text style={{ fontSize: 16, fontWeight: 700, color: AppStore.theme.o.surface }}>
              Non vedo l'ora, partiamo!
            </Text>
          </Selectble>
        </Container>
        <Box mt={32} center>
          <Text weight={700} fontSize={16} color="#666666" onClick={this.props.goBack}>
            torna indietro
          </Text>
        </Box>
      </Box>
    )
  }
}

const Container = styled(Box)`
  width: 90vw;
  max-width: 375px;
  box-shadow: none !important;
  overflow: hide;
`
const Selectble = styled(Button)`
  box-shadow: none;
  margin-top: 16px;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  border-radius: 14px;
  flex: none;
  order: 1;
  flex-grow: 0;
`

const MiniBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 32px 24px;
  /* white */

  background: #ffffff;
  /* drop_light */

  border-radius: 14px;
`
