/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { SocialType } from 'api/Utils'
import { Box } from 'components'
import { SocialPicture } from './SocialPicture'
import { AppStore } from 'utils'

/* ------------------- INTERFACES  */
interface Props {
  picture: string
  provider: SocialType
  name: string
}

export const SocialUser = ({ picture, provider, name }: Props) => {
  return (
    <SocialUserContainer shadow={AppStore.theme.bigOuterShadow}>
      <SocialPicture picture={picture} provider={provider} />
      {name}
    </SocialUserContainer>
  )
}

const SocialUserContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 350px;
  height: 67px;
  padding: 12px 16px;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  background: ${() => AppStore.theme.o.surface};
  color: ${() => AppStore.theme.o.black};
  font-family: Aktiv Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
