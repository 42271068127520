import { ReactNode } from 'react'
import styled from '@emotion/styled'
import { Box } from 'components'
import { AppStore } from 'utils'

export interface Props {
  onChange: (e: any) => void
  children: ReactNode
  variant: 'default' | 'grey'
}

/**This is main Button!!*/
export const CheckBox = ({ children, variant = 'default', onChange, ...props }: Props) => {
  return (
    <Container vcenter flex row center variant={variant} {...props}>
      <CustomInput onChange={onChange} />
      <Text variant={variant}>{children}</Text>
    </Container>
  )
}

const Container = styled(Box)<{ variant: string }>`
  background: ${({ variant }) => (variant === 'default' ? AppStore.theme.o.background : 'red')};
  border-radius: 14px;
`

const Text = styled(Box)<{ variant: string }>`
  font-weight: 500;
  font-size: 12px;
  margin-left: 8px;
  color: ${({ variant }) => (variant === 'white' ? AppStore.theme.o.background : AppStore.theme.o.darkGrey)};
`
const CustomInput = styled.input`
  width: 10px;
  height: 10px;
  margin-right: 13px;
  background: ${AppStore.theme.o.grey};
  border-radius: 14px;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  :checked {
    background-color: ${AppStore.theme.o.darkGrey};
  }
`
