import styled from '@emotion/styled'
import { Box, Icons } from 'components'

interface Props {
  dimension: number
}

export const SpinnerAstersLogo = ({ dimension }: Props) => {
  return (
    <SpinnerContainer>
      <SpinnerLogo>
        <Icons.logo width={dimension} height={dimension} />
      </SpinnerLogo>
    </SpinnerContainer>
  )
}

const SpinnerContainer = styled(Box)`
  width: 100%;
  height: 100%;
`

const SpinnerLogo = styled(Box)`
  margin: auto auto;

  animation: spin 1.5s linear infinite;

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`
