import { AppStore, formatNumber } from 'utils'
import { mergeWithDefaultOptions } from '../defaultChartOptions'

const tooltip: Highcharts.TooltipOptions = {
  borderRadius: 14,
  backgroundColor: AppStore.theme.o.lightestGrey,
  headerFormat: `<span style="color:{point.color}">{point.name}</span><br/>`,
  pointFormatter: function () {
    return `<b style="color: ${AppStore.theme.o.darkGrey}"> ${this.series.name[0]}:</b> <b style="color: ${
      AppStore.theme.o.black
    };">${(this.category as string).split('-').join('/')}</b>
      </br><b style="color: ${AppStore.theme.o.darkGrey}">${this.series.name[1]}:</b> <b style="color: ${
      AppStore.theme.o.black
    }"> ${this.y !== undefined ? formatNumber(this.y) : 'NaN'}</b>`
  },
}

export const areasplineOptions: Highcharts.Options = mergeWithDefaultOptions({
  chart: {
    backgroundColor: 'transparent',
    type: 'areaspline',
    events: {
      load: function () {
        if (this.series.length === 1) {
          this.update({
            legend: {
              enabled: false, // Hide the legend when there is only one series
            },
          })
        }
      },
    },
    zoomType: 'xy',
  } as Highcharts.ChartOptions,

  plotOptions: {
    areaspline: {
      color: '#FFB805', // Change the color of the areaspline to green
      opacity: 0.5,
    },
  },

  //@ts-ignore
  xAxis: {
    gridLineWidth: 0.5, // Width of the x-axis grid lines
    gridLineColor: AppStore.theme.o.grey, // Color of the x-axis grid lines
    gridLineDashStyle: 'solid', // Dash style of the x-axis grid lines

    tickmarkPlacement: 'on',

    labels: {
      style: {
        fontSize: '10px', // Set the font size for the y-axis categories
        color: AppStore.theme.o.black,
      },
    },

    // tickInterval: 5,
  },

  //@ts-ignore
  yAxis: {
    gridLineWidth: 0.5, // Width of the y-axis grid lines
    gridLineColor: AppStore.theme.o.grey, // Color of the y-axis grid lines
    gridLineDashStyle: 'solid', // Dash style of the y-axis grid lines

    tickmarkPlacement: 'on',
    // tickInterval: 5,
  },

  tooltip: tooltip,

  // xAxis: {
  //   categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  // },
  // series: [
  //   {
  //     name: 'Data',
  //     data: [29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4],
  //   },
  // ] as Highcharts.SeriesOptionsType[],
})
