/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { IPostFilters, TPostMediaType } from 'api/Reports'
import { isExternalElementClicked } from 'api/Utils'
import { facebook } from 'assets/icons'
import { Box, Button, Icons, Text } from 'components'
import { CloseButton } from 'components/UI'
import { link } from 'fs'
import { useEffect, useRef, useState } from 'react'
import { AppStore, T } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  updateValues: (value: any) => void
  apiCallParams?: IPostFilters
  provider: string
}

const BaseTypologies: { id: TPostMediaType; label: string }[] = [
  {
    id: 'image',
    label: T.singleWord.image,
  },
  {
    id: 'video',
    label: 'Video',
  },
  {
    id: 'text',
    label: T.singleWord.text,
  },
]

const providerTypologies = {
  facebook: BaseTypologies,
  linkedin: BaseTypologies,
  tiktok: [
    {
      id: 'video',
      label: 'Video',
    },
  ],
  instagram: [
    {
      id: 'carousel',
      label: T.singleWord.carousel,
    },
    {
      id: 'video',
      label: 'Video',
    },
    {
      id: 'image',
      label: T.singleWord.image,
    },
  ],
}

export const FormatFilterTableGraph = ({ apiCallParams, updateValues, provider }: Props) => {
  /* ----------------- STATE  */
  const [openFilter, setOpenFilter] = useState<boolean>(false)
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const [selectedFormatType, setSelectedFormatType] = useState<TPostMediaType[]>(apiCallParams?.media_type ?? [])
  const [firstMount, setFirstMount] = useState(true)

  /* ----------------- REF  */
  const formatContainerRef = useRef<HTMLDivElement>(null)

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */
  const hasFormatsTypes: boolean = selectedFormatType.length > 0
  const variantType = hasFormatsTypes ? 'secondary' : 'neutral'
  const hoveredOrOpen = isHovered || openFilter

  const typologies: { id: TPostMediaType; label: string }[] = providerTypologies[provider]
  /* ----------------- METHODS  */

  const resetSelectedTypologies = (e) => {
    e.stopPropagation()
    setOpenFilter(false)
    setSelectedFormatType([])
    updateValues({
      media_type: [],
    })
  }

  const handleSelectType = (e, type: TPostMediaType) => {
    e.stopPropagation()
    if (selectedFormatType.includes(type)) {
      setSelectedFormatType((prev) => prev.filter((item) => item !== type))
    } else {
      setSelectedFormatType((prev) => [...prev, type])
    }
  }

  /**
   * Funzione per chiude il filtro delle format se si clicca all'esterno del contenitore
   * @param e Element where the click event was triggered
   */
  const handleClose = (e) => {
    const elToCheck = e.target as Element

    const labelRef = formatContainerRef?.current

    if (elToCheck.classList.contains('format_widget_filter')) return

    if (isExternalElementClicked(labelRef, elToCheck)) setOpenFilter(false)
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    if (openFilter) window.addEventListener('click', handleClose)
    return () => window.removeEventListener('click', handleClose)
  })

  useEffect(() => {
    const areValuesEqual = JSON.stringify(selectedFormatType) === JSON.stringify(apiCallParams?.media_type)

    if (!openFilter && !firstMount && !areValuesEqual) {
      updateValues({
        media_type: selectedFormatType,
      })
    }

    setFirstMount(false)
  }, [openFilter])

  /* ----------------- CONFIG  */

  return (
    <FormatFilterTableGraphContainer
      onClick={() => setOpenFilter((prev) => !prev)}
      variant={variantType}
      style={{
        width: hoveredOrOpen ? 210 : 56,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      forwardedRef={formatContainerRef}
      className="format_widget_filter"
    >
      {/* Icon */}
      <Icons.copySizer
        fill={hasFormatsTypes ? AppStore.theme.o.secondary : AppStore.theme.o.black}
        className="format_widget_filter"
      />

      {/* Title */}
      <Text
        style={{
          color: hasFormatsTypes ? AppStore.theme.o.secondary : AppStore.theme.o.black,
          marginLeft: hoveredOrOpen ? 8 : 0,
          opacity: hoveredOrOpen ? 1 : 0,
          display: hoveredOrOpen ? 'flex' : 'none',
          transition: '0.3s ease-in-out',
        }}
        classes="format_widget_filter"
      >
        {T.singleWord.format}
      </Text>

      {/* Arrow dropdown */}
      <Icons.dropdownArrow
        fill={hasFormatsTypes ? AppStore.theme.o.secondary : AppStore.theme.o.black}
        style={{
          transform: openFilter ? 'rotate(180deg)' : 'rotate(0deg)',
          transition: '0.3s ease-in-out',
          marginLeft: 'auto',
          display: hoveredOrOpen ? 'flex' : 'none',
        }}
        className="format_widget_filter"
      />

      {/* Dropdown */}
      {openFilter && (
        <DropdownContainer onClick={(e) => e.stopPropagation()} removeHover>
          {typologies.map((type) => (
            <DropDownItem
              key={type.id}
              onClick={(e) => handleSelectType(e, type.id)}
              selected={selectedFormatType.includes(type.id)}
            >
              {type.label}

              <Ball className="type_ball_selected" />
            </DropDownItem>
          ))}
        </DropdownContainer>
      )}

      {/* Remove selected "X" */}
      {hasFormatsTypes && <CloseButton action={resetSelectedTypologies} />}
    </FormatFilterTableGraphContainer>
  )
}

const FormatFilterTableGraphContainer = styled(Button)`
  display: flex;
  align-items: center;
  padding: 8px 16px !important;
  min-height: 40px !important;
  height: 40px !important;
  position: relative;
`

const DropdownContainer = styled(Box)`
  position: absolute;
  top: calc(100% + 8px);
  width: 210px;
  border-radius: 14px;
  padding: 8px;
  border: 1px solid ${() => AppStore.theme.o.lightGrey};
  background: ${() => AppStore.theme.o.surface};
  box-shadow: ${() => AppStore.theme.smallBoxShadow};
  gap: 2px;
  z-index: 100;
`

const DropDownItem = styled(Box)<{ selected: boolean }>`
  padding: 16px;
  height: 42px;
  color: ${() => AppStore.theme.o.black};
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border: 1px solid transparent;

  .type_ball_selected {
    background: ${() => AppStore.theme.o.lightGrey};
  }

  &:hover {
    background: ${() => AppStore.theme.o.lightGrey};

    .type_ball_selected {
      background: ${() => AppStore.theme.o.grey};
    }
  }

  ${(props) =>
    props.selected &&
    `
    border-color: ${AppStore.theme.o.lightGrey};

    .type_ball_selected {
      background: ${AppStore.theme.o.secondary} !important; 
    }
  `}
`

const Ball = styled(Box)`
  &.type_ball_selected {
    width: 12px;
    height: 12px;
  }
`
