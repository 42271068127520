/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { useState } from 'react'
import { ICollaborator } from 'types/PostInterface'
import { AppStore, T } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  collaborations: ICollaborator[]
}

export const CollaboratorsStatus = ({ collaborations }: Props) => {
  /* ----------------- STATE  */
  const [isHovered, setIsHovered] = useState(false)

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  /* ----------------- CONFIG  */
  const statusEnum = {
    Pending: {
      color: AppStore.theme.o.black,
      background: AppStore.theme.o.lightestGrey,
      text: T.singleWord.pending.toLowerCase(),
    },
    Accpeted: {
      color: AppStore.theme.o.on_secondary_container,
      background: AppStore.theme.o.secondary_container,
      text: T.singleWord.accepted.toLowerCase(),
    },
    Accepted: {
      color: AppStore.theme.o.on_secondary_container,
      background: AppStore.theme.o.secondary_container,
      text: T.singleWord.accepted.toLowerCase(),
    },
    Declined: {
      color: AppStore.theme.o.on_error_container,
      background: AppStore.theme.o.error_container,
      text: T.singleWord.declined.toLowerCase(),
    },
  }

  return (
    <CollaboratorsStatusContainer onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <IconContainer>
        <Icons.collabSizer fill={AppStore.theme.o.black} />
      </IconContainer>

      {isHovered && (
        <CollaboratorsStatusContent>
          <CollaborationTitle>{T.postPage.collaborationsStatus}:</CollaborationTitle>

          <CollaborationsStatus>
            {collaborations.map((collab) => (
              <CollabContainer>
                <CollabName>@{collab.name}</CollabName>
                <CollabStatus
                  style={{
                    color: statusEnum[collab.status!].color,
                    background: statusEnum[collab.status!].background,
                  }}
                >
                  {statusEnum[collab.status!].text}
                </CollabStatus>
              </CollabContainer>
            ))}
          </CollaborationsStatus>
        </CollaboratorsStatusContent>
      )}
    </CollaboratorsStatusContainer>
  )
}

const CollaboratorsStatusContainer = styled(Box)`
  z-index: 1;
  position: absolute;
  top: 52px;
  left: calc(100% + 12px);
  max-height: 44px;
  min-width: 44px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 0px;
`

const IconContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 44px;
  min-height: 44px;
  max-height: 44px;
  background: ${() => AppStore.theme.o.surface};

  cursor: help;
`

const CollaboratorsStatusContent = styled(Box)`
  display: flex;
  gap: 16px;
  padding: 16px;
  background: ${() => AppStore.theme.o.surface};
  border-radius: 14px;
  box-shadow: ${() => AppStore.theme.smallBoxShadow};
  min-width: 267px;
  border: 1px solid ${() => AppStore.theme.o.lightGrey};
  width: fit-content;

  position: absolute;
  top: 0;
  right: calc(100% + 28px);
  z-index: 1;
`

const CollaborationTitle = styled(Box)`
  color: ${() => AppStore.theme.o.primary};
  font-size: 14px;
  font-weight: 500;
`

const CollaborationsStatus = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const CollabContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 26px;
  gap: 8px;
`

const CollabName = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  font-size: 14px;
  font-weight: 500;
`

const CollabStatus = styled(Box)`
  font-size: 14px;
  font-weight: 400;
  padding: 4px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  width: fit-content;
`
