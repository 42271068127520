/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { useState } from 'react'
import { AppStore } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  onChangeSelect: (index: number) => void
  sortTypes: any
}

export const MediaSortFilter = ({ onChangeSelect, sortTypes }: Props) => {
  /* ----------------- STATE  */
  const [isOpen, setIsOpen] = useState(false)

  const [selectedFilter, setSelectedFilter] = useState(sortTypes[0].name)

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  const handleChange = async (index: number) => {
    onChangeSelect(index)
    setSelectedFilter(sortTypes[index].name)
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <MediaSortFilterContainer width={300} onClick={() => setIsOpen((prev) => !prev)} removeHover relative>
      <SelectedItem>
        {selectedFilter}

        <Icons.dropdownArrow
          width={32}
          height={32}
          fill={AppStore.theme.o.black}
          style={{
            transition: '300ms ease-in-out',
            rotate: isOpen ? '-180deg' : '0deg',
            marginLeft: 'auto',
          }}
        />
      </SelectedItem>

      {isOpen && (
        <Content>
          {sortTypes.map((type, index) => (
            <Option key={index} onClick={() => handleChange(index)}>
              {type.name}
            </Option>
          ))}
        </Content>
      )}
    </MediaSortFilterContainer>
  )
}

const MediaSortFilterContainer = styled(Box)``

const SelectedItem = styled(Box)`
  position: relative;
  background-color: ${() => AppStore.theme.o.lightGrey};
  height: 50px;
  color: ${() => AppStore.theme.o.black};
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
`

const Content = styled(Box)`
  position: absolute;
  top: calc(100% + 8px);
  right: 0px;
  width: 100%;
  background-color: ${() => AppStore.theme.o.lightestGrey};
  gap: 4px;
  padding: 16px;
  border: 1px solid ${() => AppStore.theme.o.lightGrey};
  z-index: 100;
`

const Option = styled(Box)`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  font-weight: 600;
  color: ${() => AppStore.theme.o.black};
  font-size: 14px;
  gap: 8px;
  flex-direction: row;
  border: 1px solid transparent;

  &:hover {
    background-color: ${() => AppStore.theme.o.surface};
    border: 1px solid ${() => AppStore.theme.o.lightGrey};
  }
`
