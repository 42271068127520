/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { IPostVariant } from 'types/PostInterface'
import { MediaContainerPost } from '../../GenericComponents/MediaContainerPost'
import { AppStore } from 'utils'
import { truncateText } from 'pages/ai-discover/commonFunction'

/* ------------------- INTERFACES  */
interface Props {
  postPreview?: IPostVariant
}

export const ResultsPreviewStory = ({ postPreview }: Props) => {
  /* ----------------- ZUSTAND  */
  const { activePost } = useCreatePostStore((s) => ({
    activePost: s.activePost as IPostVariant,
  }))

  /* ----------------- VARIABLES  */
  const post = postPreview ?? activePost

  const urls = post?.data?.urls

  const metadataVisibleIndex = urls && urls.findIndex((url) => url.metaData.visible === true)

  const metadata = urls && metadataVisibleIndex !== -1 && urls[metadataVisibleIndex!]

  return (
    <ResultsPreviewStoryContainer style={{ position: postPreview ? 'relative' : 'absolute' }}>
      {postPreview && !metadata && post?.data?.medias?.length === 0 ? (
        <></>
      ) : (
        <MediaContainerPost width={'100%'} height={'100%'} arrowsPositions={16} postPreview={postPreview} />
      )}

      <ProfileContainer>
        <ProfileImg src={post?.info?.account?.picture} />
        <ProfileName>{truncateText(post?.info?.account?.name, 40)}</ProfileName>
      </ProfileContainer>
    </ResultsPreviewStoryContainer>
  )
}

const ResultsPreviewStoryContainer = styled(Box)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  height: 614px;
  width: 347px;
`

const ProfileContainer = styled(Box)`
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

const ProfileImg = styled.img`
  width: 23px;
  height: 23px;
  border-radius: 50%;
`

const ProfileName = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.3px;
`
