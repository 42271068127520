import { AiDiscoverPage } from 'pages'

const DiscoverRouter = [
  {
    path: '/discover',
    element: <AiDiscoverPage />,
  },
]

export { DiscoverRouter }
