import { INotificationPermission } from 'types/NotifyInterface'
import AstersBaseResource from './AstersBaseResource'
import { api, responseErrorCheck } from './api'
import { notificationPermissionUpdate } from './api.Interfaces'

export default class NotifyPermission extends AstersBaseResource {
  static endpoint = '/notificationPermission'

  static async getNotificationPermissionByIds(workspaceId: string) {
    return api.post<any>(`${this.endpoint}/${workspaceId}/getNotificationPermissionByIds`).then(responseErrorCheck)
  }

  static async createNotificationSetting(workspaceId: string) {
    return api.post<any>(`${this.endpoint}/${workspaceId}/createNotificationSetting`).then(responseErrorCheck)
  }

  static async updateNotificationSetting({
    workspaceId,
    notificationPermission,
    setOnAll,
  }: notificationPermissionUpdate): Promise<INotificationPermission> {
    return api
      .post<any>(`${this.endpoint}/${workspaceId}/updateNotificationSetting`, {
        notificationPermission,
        setOnAll,
      })
      .then(responseErrorCheck)
  }

  //! Serve eliminare un setting delle notifiche?
  static async deleteNotificationSetting(notificationId: string) {
    return api.delete<any>(`${this.endpoint}/${notificationId}/deleteNotificationSetting`).then(responseErrorCheck)
  }
}
