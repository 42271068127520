/* ******************* IMPORT ******************* */
import styled from '@emotion/styled'
import { Button, Icons } from 'components'
import { useEffect, useState } from 'react'
import { AppStore, showInfo, T } from 'utils'
import { putUpdateAiDiscoverTopic, removeAiDiscoverTopic } from '../commonFunction'
import { useDiscoverStore } from 'stores/DiscoverStore'

/* ******************* INTERFACE ******************* */

interface Props {
  // Prop per questioni di stile quando è dentro l'input ed è focussato
  isInputFocusSelected?: boolean
  fromArticle?: boolean // se arriva da article page
}

const ToggleFollowingTopicButton = ({ isInputFocusSelected, fromArticle }: Props) => {
  /* ____________________ ZUSTAND  ____________________ */
  const { subPageActive, onForceUpdate, selectedTopic, activeTopicSidebar, setActiveTopicSidebar } = useDiscoverStore(
    (state) => ({
      subPageActive: state.subPageActive,
      onForceUpdate: state.onForceUpdate,
      selectedTopic: state.selectedTopic,
      activeTopicSidebar: state.activeTopicSidebar,
      setActiveTopicSidebar: state.setActiveTopicSidebar,
    })
  )

  /* ____________________ STATE ____________________ */

  // Stato per controllare se il topic è seguito dall'user
  const [followedTopic, setFollowedTopic] = useState<boolean>()

  /* ____________________ API CALL ____________________ */

  /**
   * ## handleFollowTopic
   *
   * Metodo per togglare un topic seguito / non seguito
   *
   * @param topicId  _id del topic
   */
  const handleFollowTopic = async (topicId: string) => {
    const check = await AppStore.checkPermission('manage_discover')
    if (!check) return showInfo(`${T.error.noPermissionTo} ${T.error.manageDiscover}`)

    if (followedTopic) {
      removeAiDiscoverTopic(topicId, onForceUpdate)
      setFollowedTopic(false)
    }

    if (!followedTopic) {
      putUpdateAiDiscoverTopic(topicId, onForceUpdate)
      setFollowedTopic(true)
    }
  }

  /* ____________________ USEEFFECT ____________________ */

  useEffect(() => {
    const userTopics = AppStore.discoverUser?.topicsId

    // Si controlla da dove arrivano i topic, in base a quello cambia la gestione degli id, se da "search" allora si prende "_id" (risultato tornato dalla creazione / ricerca del topic) se da "follow" di prende "id" (che sarebbe "_id" della creazione, quello usato per gestirlo)
    let topicIdToCheck = ''
    if (subPageActive === 'search' && selectedTopic) {
      topicIdToCheck = selectedTopic._id || ''
    } else if (subPageActive === 'follow' && activeTopicSidebar) {
      topicIdToCheck = activeTopicSidebar.id || ''
    }

    // Controlla i topic dentro discoverUser se hanno l'id di aiDiscover uguale a quello selezionato, se è vero sugnifica che il selected è dentro il discoverUser
    const isTopicFollowed =
      userTopics?.filter((item) => {
        return item.id === topicIdToCheck
      })[0]?.id ?? ''

    if (isTopicFollowed.length > 0) {
      // se topic esiste nell'user aiDiscover
      setFollowedTopic(true)
    } else {
      // se topic non esiste nell'user aiDiscover
      setFollowedTopic(false)
      fromArticle && setActiveTopicSidebar(null)
    }
  }, [selectedTopic, activeTopicSidebar])

  return (
    <ToggleFollowingTopicButtonContainer
      variant="secondary"
      onClick={() => {
        subPageActive === 'search' && selectedTopic && handleFollowTopic(selectedTopic._id!)
        subPageActive === 'follow' && activeTopicSidebar && handleFollowTopic(activeTopicSidebar.id!)
      }}
      className={`${followedTopic ? 'selected__topic' : ''} ${isInputFocusSelected ? 'hide__content' : ''}`}
    >
      {followedTopic ? <Icons.starFilled className="filled__star" /> : <Icons.starEmpty className="empty__star" />}
      <FollowText className={followedTopic ? 'selected__topic' : ''}>
        {followedTopic ? T.aiDiscoverPage.searchbar.following : T.aiDiscoverPage.searchbar.follow}
      </FollowText>
    </ToggleFollowingTopicButtonContainer>
  )
}

export default ToggleFollowingTopicButton

const ToggleFollowingTopicButtonContainer = styled(Button)`
  visibility: visible;
  opacity: 1;
  width: auto;

  min-height: 28px;
  padding: auto !important;
  padding-left: 20px !important;
  padding-right: 24px !important;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  svg {
    &.filled__star {
      fill: ${() => AppStore.theme.o.lightGreen};
    }

    &.empty__star {
      fill: ${() => AppStore.theme.o.green};
    }
  }

  &.selected__topic {
    background: ${() => AppStore.theme.o.green};
  }

  &.hide__content {
    transition: 0ms;
    width: 0;
    opacity: 0;
    visibility: hidden;
  }
`

const FollowText = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${() => AppStore.theme.o.green};

  &.selected__topic {
    color: ${() => AppStore.theme.o.lightGreen};
  }
`
