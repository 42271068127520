/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components/Box'
import { AppStore, formatNumber } from 'utils'

/* ------------------- INTERFACES  */
interface Props {
  valueLabel: string
  currentValue: string
}

export const ReferenceValue = ({ valueLabel, currentValue }: Props) => {
  return (
    <ReferenceValueContainer>
      {formatNumber(currentValue)}
      <SubLabel>{valueLabel}</SubLabel>
    </ReferenceValueContainer>
  )
}

const ReferenceValueContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  color: ${() => AppStore.theme.o.black};
  font-size: 16px;
  align-items: flex-start;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.16px;
  height: 27px;
`

const SubLabel = styled(Box)`
  color: ${() => AppStore.theme.o.darkGrey};
  font-size: 10px;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1px;
`
