import styled from '@emotion/styled'
import { Box, Button, SocialAccountRow, SocialIcon, Text } from 'components'
import { SocialIconsProvider } from 'components/SocialIcon'
import { useEffect } from 'react'
import { SocialAccount } from 'types'
import { AppStore, T, showInfo } from 'utils'
import TutorialModal from './TutorialModal'
import { Tracker } from 'api/Tracker'
import { TRACKEREVENTS } from 'utils/eventsTracker'
import {
  OWNER_SOCIAL_ACCOUNTS_LIMITS_AVAILABLE,
  OWNER_SOCIAL_ACCOUNTS_LIMITS_INFINITE,
  OWNER_SOCIAL_ACCOUNTS_LIMITS_USED,
} from 'utils/Constants/LimitUserConstants'
import { requestUpgradeModal } from 'components/HeaderUpgradeButton'

interface Props {
  social: SocialIconsProvider
  socialAccounts: SocialAccount[]
  onClick: () => void
  onSocialAccountClick?: (account: SocialAccount) => void
  onDeleteAccount?: (account: SocialAccount) => Promise<void>
  sugestedClickOnRefresh?: any
  commingSoon?: string
  handleForceUpdate?: () => void
}

export const SocialProviderContainer = ({
  social,
  socialAccounts,
  onClick,
  onSocialAccountClick,
  onDeleteAccount,
  sugestedClickOnRefresh,
  commingSoon,
  handleForceUpdate,
}: Props) => {
  const handleOnClickAddSocial = async () => {
    try {
      const check = await AppStore.checkPermission('link_social_account')
      if (!check) return showInfo(`${T.error.noPermissionTo} ${T.error.addSocials}`)

      if (!OWNER_SOCIAL_ACCOUNTS_LIMITS_INFINITE) {
        if (OWNER_SOCIAL_ACCOUNTS_LIMITS_USED >= OWNER_SOCIAL_ACCOUNTS_LIMITS_AVAILABLE) {
          //return showInfo(T.limitUser.max_reached_social_accounts)
          requestUpgradeModal()
        }
      }

      await onClick()

      Tracker.trackEvent(TRACKEREVENTS.SOCIAL_PROFILE_CONNECTION_INITIATED, { social_channel: social })
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    try {
      const TutorialToken = localStorage.getItem('TutorialToken')

      if (AppStore.socialAccounts.length > 0 && TutorialToken !== 'true') {
        AppStore.openModal({
          id: 'preview-Step-modal',
          body: <TutorialModal />,
          style: {
            minHeight: 541,
            minWidth: 660,
          },
        })
      }
    } catch (e) {
      console.error(e)
    }
  }, [])

  return (
    <Box mt={24} p={30} style={{ borderStyle: 'dashed', borderWidth: 1, borderColor: AppStore.theme.o.grey }}>
      <Box vcenter row justify={'space-between'}>
        <Box row center>
          <SocialIcon social={social} style={{ width: 64, height: 64 }} />
          <Text fontSize={21} style={{ marginLeft: 20, textTransform: 'capitalize' }}>
            {commingSoon === undefined ? (
              <b>{social} </b>
            ) : (
              <>
                <b style={{ marginRight: 8 }}>{commingSoon}</b>
                <b style={{ textTransform: 'none' }}>(coming soon)</b>
              </>
            )}
          </Text>
        </Box>
        {commingSoon === undefined && (
          <Box>
            <Button
              id={`${social}-add-social`}
              hideBoxShadowOnHover
              variant="secondary"
              size="small"
              onClick={handleOnClickAddSocial}
              disabled={social === 'google'}
            >
              {T.settings.addSocial}
            </Button>
          </Box>
        )}
      </Box>
      <Box mt={20} height={1} style={{ backgroundColor: AppStore.theme.o.grey }} />
      <GridContainer mt={20}>
        {socialAccounts.map((account, index) => (
          <SocialAccountRow
            key={`account-social-row-${index}`}
            skey={`account-social-row-${index}`}
            account={account}
            onClick={onSocialAccountClick}
            onDelete={onDeleteAccount}
            handleForceUpdate={handleForceUpdate}
          />
        ))}
      </GridContainer>
    </Box>
  )
}

const GridContainer = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
`
