import { IArticle } from 'pages/ai-discover/aiDiscoverInterface'
import { api, responseErrorCheck } from './api'
import AstersBaseResource from './AstersBaseResource'
import { AppStore } from 'utils'

// <-------------------------INFERFACE------------------------->

export default class Article extends AstersBaseResource {
  static endpoint = '/articles'

  static async createArticle(article: IArticle) {
    return api
      .post<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/${AppStore.discoverUser._id}/create`, { article })
      .then(responseErrorCheck)
  }

  static async getBoardArticles(boardId: string) {
    return api
      .get<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/${boardId}/getBoardArticles`)
      .then(responseErrorCheck)
  }

  static async translateArticle(article: IArticle, targetLanguage: string, newArticleLanguage: string) {
    return api
      .post<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/${AppStore.discoverUser._id}/translate`, {
        article,
        targetLanguage,
        newArticleLanguage,
      })
      .then(responseErrorCheck)
  }
}
