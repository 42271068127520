/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { useNavigate, useParams } from 'react-router-dom'
import { useAnalyticsStore } from 'stores/AnalyticsStore'
import { AppStore, T } from 'utils'

interface Props {
  completeComponent?: boolean
}

export const AddMetricOnReport = ({ completeComponent = false }: Props) => {
  const navigate = useNavigate()
  const { reportId } = useParams()

  const reportPreview = useAnalyticsStore((state) => state.reportPreview)

  const onClickMetricBtn = () => {
    navigate(`/analytics/createReport/${reportId ?? window.location.pathname.split('/')[3]}`, {
      state: { subsection: 'metrics' },
    })
  }

  return reportPreview ? (
    <AddMetricContainer onClick={onClickMetricBtn}>
      <Icons.add fill={AppStore.theme.o.black} width={20} height={20} />
    </AddMetricContainer>
  ) : null
}

const AddMetricContainer = styled(Box)`
  border-radius: 0px;
  display: flex;
  width: 44px;
  height: 40px;

  align-items: center;
  justify-content: center;
  color: ${() => AppStore.theme.o.black};
  background-color: ${() => AppStore.theme.o.lightestGrey};
  border: 1px solid ${() => AppStore.theme.o.grey};
  border-radius: 14px;
`
