/* ___________________ IMPORTS ___________________ */

import styled from '@emotion/styled'
import { Box } from 'components/Box'
import { Icons } from 'components/Icons'
import PopConfirm from 'components/PopConfirm'
import { dateToString } from 'pages/ai-discover/commonFunction'
import { IReply } from 'pages/ai-discover/aiDiscoverInterface'

import { useEffect, useRef } from 'react'
import { AppStore, T } from 'utils'
import asters_placeholder from 'assets/asters_placeholder.png'
import { ImageWithFallback } from 'components/UI/ImageWithFallback'
import MediaUtils from 'utils/MediaUtils'

/* ___________________ INTERFACE ___________________ */
interface Props {
  reply: IReply[]
  addNewComment: (commentText: string) => void
  addNewReply: (replyText: string) => void
  deleteComent: () => void
  isCreateComment?: () => void // Se è in una modale
}

const AiDiscoverArticleComments = ({
  reply = [],
  addNewComment,
  addNewReply,
  deleteComent,
  isCreateComment,
}: Props) => {
  /* ____________________ STATE ____________________ */

  /* ____________________ REF ____________________ */
  const inputRef = useRef<HTMLInputElement>(null)
  const scrollContainerRef = useRef<HTMLDivElement>(null)

  /* ____________________ VARIABLES ____________________ */

  /* ____________________ METHODS ____________________ */

  const onSubmitComment = (e) => {
    e.preventDefault()
    if (!inputRef.current) return
    if (inputRef.current.value.trim().length === 0) return

    if (reply.length === 0) addNewComment(inputRef.current.value)
    else addNewReply(inputRef.current.value)

    inputRef.current.value = ''

    isCreateComment && isCreateComment()
  }

  /**
   * serve per scrollare fino all'ultimo commento quando si apre il componente
   *
   * @returns se non si trova il ref, si richiama la funzione
   */
  const commentScrollRefInitialPosition = () => {
    if (scrollContainerRef.current) {
      //smooth scroll

      const scrollContainerHeight = scrollContainerRef.current.clientHeight
      scrollContainerRef.current.scrollBy({
        top: scrollContainerHeight,
      })
    } else {
      return commentScrollRefInitialPosition()
    }
  }
  /* ____________________ API CALL ____________________ */

  /* ____________________ USEEFFECT ____________________ */

  useEffect(() => {
    commentScrollRefInitialPosition()

    if (inputRef.current) inputRef.current.focus()
  }, [reply])

  return (
    <CommentWrapper>
      <div className="scroll-container" ref={scrollContainerRef}>
        {reply.map((a) => (
          <CommentCard>
            <CommentInfo>
              <UserInfo>
                <ImageWithFallback
                  fallbackSrc={MediaUtils.workspacePlaceholder}
                  src={a.user.image ?? asters_placeholder}
                  alt={`user-${a.user.name}`}
                  style={{ backgroundColor: `${AppStore.theme.o.lightGrey}` }}
                />
                <p>{a.user.name}</p>
              </UserInfo>
              <CommentDate>{dateToString(a.date)}</CommentDate>
            </CommentInfo>
            <CommentText>{a.replyText}</CommentText>
          </CommentCard>
        ))}
      </div>

      <InputContainer>
        <form onSubmit={onSubmitComment}>
          <input ref={inputRef} placeholder={T.AiDiscoverPage.addComment} type="text" />
          <Icons.linkedinSend onClick={onSubmitComment} />
        </form>
        {reply.length > 0 && reply[0].user.id === AppStore.loggedUser._id && (
          <TrashContaner className="delete-comment" onClick={deleteComent}>
            <Icons.trashBin />
          </TrashContaner>
        )}
      </InputContainer>
    </CommentWrapper>
  )
}

export default AiDiscoverArticleComments

const CommentWrapper = styled(Box)`
  border-radius: 0;

  .scroll-container {
    min-width: 100%;
    max-height: 666px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 0;
    margin-bottom: 52px;

    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`

const InputContainer = styled(Box)`
  width: 432px;
  height: 44px;
  max-height: 44px;
  color: ${() => AppStore.theme.o.black};
  background-color: ${() => AppStore.theme.o.lightestGrey};
  position: relative;
  padding: 9.92px 24px;
  border-radius: 14px;
  border: 1px solid ${() => AppStore.theme.o.grey};
  box-shadow: ${() => AppStore.theme.finalNavbarBoxShadow.slice(11)};
  position: absolute;
  bottom: 0;
  left: 12px;

  form {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }

  input {
    all: unset;
    min-width: 348px;
    width: 100%;
    height: 18px;
    padding: 0;

    &:placeholder {
      color: ${() => AppStore.theme.o.darkGrey};
      font-weight: 500;
      font-size: 14px;
    }
  }

  svg {
    fill: ${() => AppStore.theme.o.black};
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &:active {
      transform: scale(0.9);
    }
  }
`
const TrashContaner = styled(Box)`
  position: absolute;
  bottom: -2px;
  right: -184px;
  min-width: 44px;
  min-height: 44px;

  svg {
    min-width: 44px;
    min-height: 44px;

    rect {
      fill: ${() => AppStore.theme.o.lightGrey};
    }
    path {
      fill: ${() => AppStore.theme.o.darkGrey};
    }
  }

  &:hover {
    svg {
      rect {
        fill: ${() => AppStore.theme.o.lightRed};
      }
      path {
        fill: ${() => AppStore.theme.o.red};
      }
    }
  }
`

const CommentCard = styled(Box)`
  background-color: ${() => AppStore.theme.o.surface};
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px 32px 24px;
  border: 1px solid ${() => AppStore.theme.o.grey};
  /* box-shadow: ${() => AppStore.theme.finalNavbarBoxShadow.slice(11)}; */
`

const CommentInfo = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 0;
`

const UserInfo = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  font-size: 14px;
  font-weight: 500;
  line-height: 17.92px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  border-radius: 0;

  img {
    height: 24px;
    width: 24px;
    border-radius: 50%;
  }
`

const CommentDate = styled(Box)`
  color: ${() => AppStore.theme.o.darkGrey};
  font-size: 12px;
  font-weight: 500;
  line-height: 15.36px;
  border-radius: 0;
`

const CommentText = styled(Box)`
  border-radius: 0;
  color: ${() => AppStore.theme.o.black};
`
