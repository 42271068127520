/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import User from 'api/user'
import { Box, ZoomedContainer } from 'components'
import { LogoAstersFullDynamicTheme } from 'components/LogoAstersFullDynamicTheme'
import { useEffect, useState } from 'react'
import AppStore from './AppStore'
import MediaUtils from './MediaUtils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {}

export const NotificationEnabled = ({}: Props) => {
  /* ----------------- STATE  */

  const [text, setText] = useState('Notification enabled')

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */
  useEffect(() => {
    onMount()
  }, [])

  const onMount = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search)
      const myParam = urlParams.get('phone')
      if (myParam) {
        try {
          const res: any = await User.enableUserNotifications(myParam ?? '')
          if (res?.data?.message) {
            setText(res.data.message)
          }
        } catch (error) {
          console.error(error)
        }
      } else {
        setText('Number not found')
      }
    } catch (error) {
      console.error(error)
    }
  }

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  const backgroundImages = [MediaUtils.backgrounUrl]

  return (
    <NotificationAbilitateContainer background={backgroundImages[0]}>
      <ZoomedContainer style={{ alignItems: 'center', justifyContent: 'center' }}>
        <InnerContainer></InnerContainer>
        <AbsoluteContainer>
          <Box hcenter mb={20}>
            <LogoAstersFullDynamicTheme logoHeight={50} logoWidth={158} />
          </Box>
          <Text>{text}</Text>
        </AbsoluteContainer>
      </ZoomedContainer>
    </NotificationAbilitateContainer>
  )
}

const AbsoluteContainer = styled.div`
  position: absolute;
  @media (max-width: 1000px) {
    max-width: 200px !important;
  }
`

const Text = styled.p`
  color: ${() => AppStore.theme.o.black};
  font-size: 28px;
  font-weight: 900;

  @media (max-width: 1000px) {
    font-size: 20px;
  }
`
const InnerContainer = styled.div`
  position: relative;
  background-color: ${() => AppStore.theme.o.background};
  min-width: 600px;
  min-height: 200px;
  border-radius: 10px;

  @media (max-width: 1000px) {
    min-width: 320px;
    min-height: 200px;
  }
`

const NotificationAbilitateContainer = styled.div<{ background }>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center !important;
  align-items: center !important;
  justify-content: left;
  background: url(${(props) => props.background}) no-repeat center center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: background-image 0.5s ease-in-out;
`
