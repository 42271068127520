import styled from '@emotion/styled'
import { Box, Button, Icons } from 'components'
import { MotionStyle } from 'framer-motion'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { IPostVariant } from 'types/PostInterface'
import { AppStore } from 'utils'

type Props = {
  post: IPostVariant
  filterVideoUrl: string
  onVideoLoad: () => void
  setVideoCoverTimestampMsState: (newState: number) => void
  style?: MotionStyle
}

export const VideoPreview = forwardRef<HTMLVideoElement, Props & { videoAspectRatio?: string }>((props, ref) => {
  /* ----------------- VARIABLES  */
  const { post, onVideoLoad, filterVideoUrl, setVideoCoverTimestampMsState, videoAspectRatio, style } = props

  const { provider } = post.info.account

  /* ----------------- STATES  */
  const [isVideoPaused, setIsVideoPaused] = useState<boolean>(true)

  /* ----------------- METHODS  */
  const handlePlay = () => {
    if (ref && 'current' in ref && ref.current) {
      ref.current.play()
      setIsVideoPaused(ref.current.paused)
    }
  }
  const videoElementRef = useRef<HTMLVideoElement>(null)
  const handlePause = () => {
    if (ref && 'current' in ref && ref.current) {
      ref.current.pause()
      setIsVideoPaused(ref.current.paused)
    }
  }

  const handleTimeUpdate = () => {
    if (ref && 'current' in ref && ref.current) {
      setVideoCoverTimestampMsState(ref.current.currentTime * 1000)
    }
  }

  useEffect(() => {
    if (ref && 'current' in ref && ref.current) {
      ref.current.src = filterVideoUrl
    }
  }, [filterVideoUrl, ref])

  return (
    <Box gap={12} center style={{ ...style }}>
      {provider === 'tiktok' && (
        <VideoAndControls>
          <Box gap={12} borderRadius={8} relative width={195} height={339}>
            <video
              crossOrigin="anonymous"
              onLoadedMetadata={onVideoLoad}
              onTimeUpdate={handleTimeUpdate}
              loop
              muted
              ref={ref}
              style={{
                objectFit: 'cover',
                borderRadius: '8px',
                width: '100%',
                height: '100%',
              }}
              src={filterVideoUrl}
            />
          </Box>

          <Box row gap={12}>
            <ControlsButton
              ph={7.5}
              pv={7}
              onClick={handlePause}
              variant="neutral"
              style={{
                background: isVideoPaused ? AppStore.theme.o.lightGreen : AppStore.theme.o.lightGrey,
                borderColor: isVideoPaused ? AppStore.theme.o.green : AppStore.theme.o.darkGrey,
              }}
            >
              <Icons.pauseVideoIcon fill={isVideoPaused ? AppStore.theme.o.green : AppStore.theme.o.darkGrey} />
            </ControlsButton>
            <ControlsButton
              pv={7}
              ph={7}
              onClick={handlePlay}
              variant="neutral"
              style={{
                background: isVideoPaused ? AppStore.theme.o.lightGrey : AppStore.theme.o.lightGreen,
                borderColor: isVideoPaused ? AppStore.theme.o.darkGrey : AppStore.theme.o.green,
              }}
            >
              <Icons.playVideoIcon fill={isVideoPaused ? AppStore.theme.o.darkGrey : AppStore.theme.o.green} />
            </ControlsButton>
          </Box>
        </VideoAndControls>
      )}

      {provider === 'youtube' && (
        <VideoAndControls className="videoandcontrolls">
          <VideoWrapper
            className="VideoWrapper"
            gap={12}
            borderRadius={8}
            width={videoAspectRatio === '16:9' ? '339px' : '195px'}
            height={videoAspectRatio === '16:9' ? '195px' : '339px'}
            relative
          >
            <video
              crossOrigin="anonymous"
              onLoadedMetadata={onVideoLoad}
              onTimeUpdate={handleTimeUpdate}
              loop
              muted
              ref={ref}
              style={{
                objectFit: 'cover',
                borderRadius: '8px',
                width: videoAspectRatio !== '16:9' ? '100%' : '100%',
                height: videoAspectRatio === '16:9' ? '100%' : '100%',
              }}
              src={filterVideoUrl}
            />
          </VideoWrapper>
        </VideoAndControls>
      )}

      {provider === 'instagram' && (
        <VideoAndControls className="videoandcontrolls">
          <Box gap={12} borderRadius={8} relative width={195} height={339}>
            <video
              crossOrigin="anonymous"
              onLoadedMetadata={onVideoLoad}
              onTimeUpdate={handleTimeUpdate}
              loop
              muted
              ref={ref}
              style={{
                objectFit: 'cover',
                borderRadius: '8px',
                width: '100%',
                height: '100%',
              }}
              src={filterVideoUrl}
            />
          </Box>
        </VideoAndControls>
      )}
    </Box>
  )
})

const ControlsButton = styled(Button)`
  min-height: 24px;
  width: 24px;
  height: 24px;
  border-width: 0.5px;

  border-radius: 50%;
  transition: 200ms ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`

const VideoAndControls = styled(Box)`
  gap: 12px;
  align-items: center;
  justify-content: center;
`

const VideoWrapper = styled(Box)``
