/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Button, Icons } from 'components'
import { openPiningModal } from 'components/Modal/ModalManager'
import { IPostVariant } from 'types/PostInterface'
import { AppStore, T, showInfo } from 'utils'

/* ------------------- INTERFACES  */
interface Props {
  post: IPostVariant
}

export const Tags = ({ post }: Props) => {
  /* ------------------- VARIABLES  */
  const { medias } = post.data
  const canOpenTags = !medias.every((media) => media.mimetype.includes('video'))

  /* ------------------- METHODS  */
  const handleClick = () => {
    if (medias.length === 0) return showInfo(T.postPage.generalExtra.addAImageForUseTheTags)

    if (!canOpenTags) return showInfo(T.postPage.generalExtra.noTagsForVideos)

    openPiningModal(post.variantId)
  }

  return (
    <TagsContainer>
      <TagButton
        className="TagButton"
        variant="neutral"
        style={{
          maxHeight: '40px',
          minHeight: '40px',
        }}
        onClick={handleClick}
      >
        <Icons.usersSizer fill={AppStore.theme.o.black} />

        {T.postPage.extraTag}
      </TagButton>
    </TagsContainer>
  )
}

const TagsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const TagButton = styled(Button)`
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;

  display: flex;
  padding: 8px 48px !important;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: fit-content;
`
