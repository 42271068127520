import styled from '@emotion/styled'
import { Box } from 'components/Box'
import { useEffect, useState } from 'react'
import { useUserStore } from 'stores/UserStore'
import { PlanResource, SubscriptionPlan } from 'types/SubscriptionInterfaces'
import { AppStore, T } from 'utils'
import BillingPeriodSwitch from './BillingPeriodSwitch'
import { UpgradePlan } from './UpgradePlan'

export const UpgradeModalPlans = ({ loadedPlans }) => {
  const [plans, setPlans] = useState<SubscriptionPlan[]>([])
  const [billingPeriod, setBillingPeriod] = useState<'monthly' | 'annual'>('annual')
  const { currentPlan, user } = useUserStore((state) => ({
    currentPlan: state.user?.subscription?.activePlan,
    user: state.user,
  }))

  const trialEnabled = user?.subscription?.isTrialEnabled || false

  const getCurrentPlanOrder = () => {
    return currentPlan?.plan?.order
  }

  /**
   * Da gestire i vari status all'interno del componente
   * @param plan
   * @returns
   */
  const getPlanStatus = (plan: any) => {
    if (currentPlan && plan.identifier === currentPlan?.plan?.identifier) {
      if (currentPlan.expirationDate && new Date(currentPlan.expirationDate) < new Date()) return 'expired'
      return 'active'
    }
    return 'inactive'
  }

  useEffect(() => {
    const isYearly = billingPeriod === 'annual'
    const availablePlans = loadedPlans
      ?.sort((a, b) => a.order - b.order)
      .filter((plan: any) => plan.yearly === isYearly)
    setPlans(availablePlans), 100
  }, [billingPeriod])

  // Verifica se il piano è abilitato per la richiesta, rende impossibile il downgrade da frontend
  const isPlanEnabledToRequest = (plan: SubscriptionPlan): boolean => {
    return !currentPlan || plan.order > currentPlan?.plan?.order
  }

  const checkContainExpiredPlan = () => {
    return plans.some((plan) => getPlanStatus(plan) === 'expired')
  }

  const containExpiredPlan = checkContainExpiredPlan()

  return (
    <BillingContainer>
      <BillingHeader>
        <UpgradeTitle>{T.billing.plans.caption}</UpgradeTitle>
        <BillingPeriodSwitch value="annual" onChange={(value) => setBillingPeriod(value)} annualDiscount={20} />
      </BillingHeader>

      <PlansContainer>
        {plans.map((plan, index) => (
          <UpgradePlan
            containExpiredPlan={containExpiredPlan}
            currentPlanOrder={getCurrentPlanOrder()}
            activePlan={currentPlan?.plan}
            key={index}
            plan={plan}
            status={getPlanStatus(plan)}
            trialEnabled={trialEnabled}
            requestEnabled={isPlanEnabledToRequest(plan)}
          />
        ))}
      </PlansContainer>
    </BillingContainer>
  )
}

// ------------------ CSS STYLES ------------------------

const UpgradeTitle = styled.h2`
  font-size: 21px;
  font-weight: 700;
  color: ${AppStore.theme.o.primary};
`

const BillingContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-width: 100%;
  padding-top: 20px;
  align-items: center;
`

const BillingHeader = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`

const PlansContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 20px;
  left: 0;
  min-width: 100%;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
`

//// --------- Valori di test ---------
