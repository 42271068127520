import { gsap } from 'gsap'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { AppStore, T } from 'utils'

interface BillingPeriodSwitchProps {
  value: 'monthly' | 'annual'
  onChange: (value: 'monthly' | 'annual') => void
  annualDiscount?: number
}

const BillingPeriodSwitch: React.FC<BillingPeriodSwitchProps> = ({ value, onChange, annualDiscount }) => {
  const [currentValue, setCurrentValue] = useState(value)
  const backgroundRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const onChangeHandler = (val: 'monthly' | 'annual') => {
    onChange(val)
    setCurrentValue(val)
  }

  useEffect(() => {
    if (backgroundRef.current && containerRef.current) {
      const activeOption = containerRef.current.querySelector(`button[data-value="${currentValue}"]`) as HTMLElement
      if (activeOption) {
        gsap.to(backgroundRef.current, {
          duration: 0.3,
          width: activeOption.offsetWidth,
          x: activeOption.offsetLeft - 8,
          ease: 'power2.inOut',
        })
      }
    }
  }, [currentValue])

  return (
    <SwitchContainer ref={containerRef} gap={8}>
      <AnimatedBackground ref={backgroundRef} />

      <Option
        bc="red"
        active={currentValue === 'monthly'}
        onClick={() => onChangeHandler('monthly')}
        data-value="monthly"
      >
        {T.billing.plans.monthly}
      </Option>

      <Option active={currentValue === 'annual'} onClick={() => onChangeHandler('annual')} data-value="annual">
        {T.billing.plans.yearly}

        {annualDiscount && <DiscountTag>-{annualDiscount}%</DiscountTag>}
      </Option>
    </SwitchContainer>
  )
}

export default BillingPeriodSwitch

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${AppStore.theme.o.background};
  border: 1px solid ${AppStore.theme.o.lightGrey};
  border-radius: 14px;
  padding: 8px;
  position: relative;
`

const Option = styled.button<{ active: boolean }>`
  color: ${(props) => (props.active ? AppStore.theme.o.black : AppStore.theme.o.darkGrey)};
  background-color: transparent;
  border: none;
  padding: 8px 48px 8px 48px;
  border-radius: 14px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  transition: color 0.3s ease;
  position: relative;
  z-index: 2;
  &:focus {
    outline: none;
  }

  transition: 300ms ease-in-out;
  &:hover {
    background-color: ${() => AppStore.theme.o.lightestGrey};
  }
`

const AnimatedBackground = styled.div`
  position: absolute;

  height: 40px;
  background-color: ${(props) => (!props.active ? AppStore.theme.o.lightestGrey : 'transparent')};
  border-radius: 14px;
  z-index: 1;
  border: 1px solid ${() => AppStore.theme.o.lightGrey};
`

const DiscountTag = styled.span`
  background-color: #faeafe;
  color: #732187;
  padding: 6px 8px 6px 8px;
  border-radius: 12px;
  font-size: 11px;
  margin-left: 8px;
`
