import { createTheme } from '@mui/material/styles'

import { AppStore } from 'utils'

const currentTheme = AppStore.theme.o

export const muiTheme = createTheme({
  palette: {
    mode: AppStore.darkTheme ? 'dark' : 'light',
    background: {
      default: currentTheme.background,
      paper: currentTheme.surface,
    },
    primary: {
      main: currentTheme.black,
    },
    secondary: {
      main: currentTheme.green,
    },
    error: {
      main: currentTheme.red,
    },
    text: {
      primary: currentTheme.black,
      secondary: currentTheme.black,
    },
  },
})
