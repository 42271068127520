import { CrelloResponse } from 'types'
import { MixpanelEditorFiles } from '../utils/utilsInterfaces'

export function getEditorFileSaveData(fileSaveData: CrelloResponse): MixpanelEditorFiles {
  const data = {
    file_format: fileSaveData.format,
    file_size: `${fileSaveData.height} x ${fileSaveData.width}`,
    file_url: fileSaveData.url,
    file_weight: '-',
    video_length: '-',
  }

  return data
}
