import styled from '@emotion/styled'
import { Box } from 'components/Box'
import { truncateText } from 'pages/ai-discover/commonFunction'
import { AppStore } from 'utils'

interface Props {
  emptyText: string
  truncateTextNum?: number
}

const EmptySelectExtra = ({ emptyText, truncateTextNum = 30 }: Props) => {
  return (
    <SearchInputs className="empty-select__extra">
      <ItemName>{truncateText(emptyText, truncateTextNum)}</ItemName>
    </SearchInputs>
  )
}

export default EmptySelectExtra

const SearchInputs = styled(Box)`
  &.empty-select__extra {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    height: 42px;
    border: 1px solid transparent;
    transition: 200ms ease-in-out;
  }
`

const ItemName = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  text-align: left;

  font-family: Aktiv Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
