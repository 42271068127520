/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { useEffect, useState } from 'react'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { Media } from 'types'
import { getSelectedMedias } from 'utils/PostUtils/MediaPost'
import { updateActive } from 'utils/PostUtils/Post'
import DragDropImg from './DragDrop/DragDropImg'
import { LottieSadSearch } from './LottieSadSearch'

interface Props {
  section: string
}

export const PostMediaContent = ({ section }: Props) => {
  /* ----------------- USESTATE */
  const [refreshContent, setRefreshContent] = useState<boolean>(false)
  /* ----------------- ZUSTAND */
  const unsubRefreshMediaContent = useCreatePostStore.subscribe(
    (s) => s.refreshMediaContent !== refreshContent && setRefreshContent(s.refreshMediaContent)
  )

  /* ----------------- METHODS  */
  // Per crop img
  const onChangeMedia = (media: Media) => {
    const selected = getSelectedMedias()

    const mediaIndex = selected.findIndex((m) => m._id === media._id)

    if (mediaIndex === -1) {
      selected.push(media)
    } else {
      selected.splice(mediaIndex, 1)
    }

    updateActive({ medias: selected })
  }

  useEffect(() => {
    return () => unsubRefreshMediaContent()
  }, [])

  useEffect(() => {
    if (refreshContent) {
      setTimeout(() => {
        useCreatePostStore.getState().setRefreshMediaContent(false)
      }, 300)
    }
  }, [section, refreshContent])

  return (
    <PostMediaContentContainer>
      {!refreshContent && getSelectedMedias().length === 0 ? <LottieSadSearch width={400} height={400} /> : null}

      {refreshContent ? (
        <></>
      ) : (
        <DragDropImg
          onChangeMedia={(media: Media) => {
            onChangeMedia(media)
          }}
          AllMedia={getSelectedMedias()}
        />
      )}
    </PostMediaContentContainer>
  )
}

const PostMediaContentContainer = styled(Box)`
  padding: 12px 32px;
  width: 100%;
  height: 100%;
  position: relative;
`
