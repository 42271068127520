/* ******************* IMPORT ******************* */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { useState } from 'react'
import { AppStore, T } from 'utils'
import { IBoard } from '../aiDiscoverInterface'
import { previewImages, truncateText } from '../commonFunction'
import asters_placeholder from 'assets/asters_placeholder.png'
import AiDiscover from 'api/AiDiscover'
import { useDiscoverStore } from 'stores/DiscoverStore'

/* ******************* INTERFACE ******************* */

interface Props {
  activeBoard?: IBoard
  setActiveBoard: (activeBoard: IBoard) => void

  searchContent?: string
}

const DropdownBoards = ({ activeBoard, setActiveBoard, searchContent }: Props) => {
  /* ____________________ STATE ____________________ */

  const [hoveredBoard, setHoveredBoard] = useState<any>(null)

  /* ____________________ ZUSTAND  ____________________ */
  const { setActiveDropdown, onChangeSubPage, activeDropdown, openArticleModal } = useDiscoverStore((state) => ({
    setActiveDropdown: state.setActiveDropdown,
    onChangeSubPage: state.onChangeSubPage,
    activeDropdown: state.activeDropdown,
    openArticleModal: state.openArticleModal,
  }))
  /* ____________________ VARIABLES ____________________ */
  const boards = AppStore.discoverUserBoardList

  /* ____________________ API CALL ____________________ */

  const getArticlebyUri = async (articleId, board) => {
    try {
      const response = await AiDiscover.getArticlebyUri(articleId)

      openArticleModal(response, board, true)
    } catch (e) {}
  }

  return (
    <DropdownContainer>
      <DropdownItem key={'Dropdown_All'}>
        <DropdownTitle
          onClick={() => {
            setActiveDropdown('all')
            onChangeSubPage('board')
          }}
          className={`${activeDropdown === 'all' ? 'dropdown_active' : ''}`}
        >
          <span>
            <Icons.saveIcon /> {T.aiDiscoverPage.sidebar.all}
          </span>
          <Icons.dropdownArrow className={`dropdown_arrow`} />
        </DropdownTitle>
        {activeDropdown === 'all' && (
          <DropdownContent className={`${activeBoard ? 'reduced_max-height' : 'normal_max-height'}`}>
            {boards
              .filter((b) => b.boardTitle.toLowerCase().includes(searchContent!.toLowerCase()))
              .reverse()
              .map((item) => (
                <BoardItem
                  onMouseLeave={() => setHoveredBoard(null)}
                  key={item._id}
                  onClick={() => setActiveBoard(item)}
                  className={`${activeBoard?._id === item._id ? 'selected__content' : ''}
                  ${hoveredBoard === item._id ? 'hovered__content' : ''}`}
                >
                  <div className="board__item__header">
                    <span>
                      {item.private === true ? (
                        <Icons.padlock fill={AppStore.theme.o.black} />
                      ) : (
                        <Icons.collaboration fill={AppStore.theme.o.black} />
                      )}
                      {truncateText(item.boardTitle, 10)}
                    </span>
                    <PreviewImagesContainer onMouseEnter={() => setHoveredBoard(item._id)}>
                      {previewImages(item.articles.slice(Math.max(item.articles.length - 3, 0)))}
                    </PreviewImagesContainer>
                  </div>
                  {item.articles.length >= 1 && (
                    <span className={`board__tooltip ${hoveredBoard === item._id ? 'visible__board__tooltip' : ''}`}>
                      {item.articles
                        .map((article: any) => (
                          <TooltipItem
                            key={article._id}
                            onClick={(e) => {
                              getArticlebyUri(article.articleId, item)
                            }}
                          >
                            <img
                              src={article.image ?? asters_placeholder}
                              style={{ backgroundColor: `${AppStore.theme.o.lightGrey}` }}
                            />
                            <span>{truncateText(article.title, 20)}</span>
                          </TooltipItem>
                        ))
                        .reverse()}
                    </span>
                  )}
                </BoardItem>
              ))}
            {boards.filter((b) => b.boardTitle.includes(searchContent ?? '')).length <= 0 && (
              <NoResultsMessage>{T.aiDiscoverPage.sidebar.noResultsFound}</NoResultsMessage>
            )}
          </DropdownContent>
        )}
      </DropdownItem>
      <DropdownItem key={'Dropdown_Private'}>
        <DropdownTitle
          onClick={() => {
            setActiveDropdown('private')
            onChangeSubPage('board', 'private')
          }}
          className={`${activeDropdown === 'private' ? 'dropdown_active' : ''}`}
        >
          <span>
            <Icons.padlock /> {T.aiDiscoverPage.sidebar.private}
          </span>
          <Icons.dropdownArrow className={`dropdown_arrow`} />
        </DropdownTitle>

        {activeDropdown === 'private' && (
          <DropdownContent className={`${activeBoard ? 'reduced_max-height' : 'normal_max-height'}`}>
            {boards
              .filter((b) => b.boardTitle.toLowerCase().includes(searchContent!.toLowerCase()))
              .filter((item) => item.private === true)
              .reverse()
              .map((item) => (
                <BoardItem
                  onMouseLeave={() => setHoveredBoard(null)}
                  key={item._id}
                  onClick={() => setActiveBoard(item)}
                  className={`${activeBoard?._id === item._id ? 'selected__content' : ''}  ${
                    hoveredBoard === item._id ? 'hovered__content' : ''
                  }`}
                >
                  <div className="board__item__header">
                    <span>
                      {item.private === true ? (
                        <Icons.padlock fill={AppStore.theme.o.black} />
                      ) : (
                        <Icons.collaboration fill={AppStore.theme.o.black} />
                      )}
                      {truncateText(item.boardTitle, 10)}
                    </span>
                    <PreviewImagesContainer onMouseEnter={() => setHoveredBoard(item._id)}>
                      {previewImages(item.articles.slice(Math.max(item.articles.length - 3, 0)))}
                    </PreviewImagesContainer>
                  </div>
                  {item.articles.length >= 1 && (
                    <span className={`board__tooltip ${hoveredBoard === item._id ? 'visible__board__tooltip' : ''}`}>
                      {item.articles
                        .map((article: any) => (
                          <TooltipItem
                            key={article._id}
                            onClick={(e) => {
                              getArticlebyUri(article.articleId, item)
                            }}
                          >
                            <img
                              src={article.image ?? asters_placeholder}
                              style={{ backgroundColor: `${AppStore.theme.o.lightGrey}` }}
                            />
                            <span>{truncateText(article.title, 20)}</span>
                          </TooltipItem>
                        ))
                        .reverse()}
                    </span>
                  )}
                </BoardItem>
              ))}
            {boards
              .filter((item) => item.private === true)
              .filter((b) => b.boardTitle.toLowerCase().includes(searchContent!.toLowerCase())).length <= 0 && (
              <NoResultsMessage>{T.aiDiscoverPage.sidebar.noResultsFound}</NoResultsMessage>
            )}
          </DropdownContent>
        )}
      </DropdownItem>
      <DropdownItem key={'Dropdown_Public'}>
        <DropdownTitle
          onClick={() => {
            setActiveDropdown('shared')
            onChangeSubPage('board', 'shared')
          }}
          className={`${activeDropdown === 'shared' ? 'dropdown_active' : ''}`}
        >
          <span>
            <Icons.collaboration /> {T.AiDiscoverPage.word.shared}
          </span>
          <Icons.dropdownArrow className={`dropdown_arrow`} />
        </DropdownTitle>

        {activeDropdown === 'shared' && (
          <DropdownContent className={`${activeBoard ? 'reduced_max-height' : 'normal_max-height'}`}>
            {boards
              .filter((b) => b.boardTitle.toLowerCase().includes(searchContent!.toLowerCase()))
              .filter((item) => item.private !== true)
              .reverse()
              .map((item) => (
                <BoardItem
                  onMouseLeave={() => setHoveredBoard(null)}
                  key={item._id}
                  onClick={() => setActiveBoard(item)}
                  className={`${activeBoard?._id === item._id ? 'selected__content' : ''}  ${
                    hoveredBoard === item._id ? 'hovered__content' : ''
                  }`}
                >
                  <div className="board__item__header">
                    <span>
                      {item.private === true ? (
                        <Icons.padlock fill={AppStore.theme.o.black} />
                      ) : (
                        <Icons.collaboration fill={AppStore.theme.o.black} />
                      )}
                      {truncateText(item.boardTitle, 10)}
                    </span>
                    <PreviewImagesContainer onMouseEnter={() => setHoveredBoard(item._id)}>
                      {previewImages(item.articles.slice(Math.max(item.articles.length - 3, 0)))}
                    </PreviewImagesContainer>
                  </div>
                  {item.articles.length >= 1 && (
                    <span className={`board__tooltip ${hoveredBoard === item._id ? 'visible__board__tooltip' : ''}`}>
                      {item.articles
                        .map((article: any) => (
                          <TooltipItem
                            key={article._id}
                            onClick={(e) => {
                              getArticlebyUri(article.articleId, item)
                            }}
                          >
                            <img
                              src={article.image ?? asters_placeholder}
                              style={{ backgroundColor: `${AppStore.theme.o.lightGrey}` }}
                            />
                            <span>{truncateText(article.title, 20)}</span>
                          </TooltipItem>
                        ))
                        .reverse()}
                    </span>
                  )}
                </BoardItem>
              ))}
            {boards
              .filter((item) => item.private === false)
              .filter((b) => b.boardTitle.toLowerCase().includes(searchContent!.toLowerCase())).length <= 0 && (
              <NoResultsMessage>{T.aiDiscoverPage.sidebar.noResultsFound}</NoResultsMessage>
            )}
          </DropdownContent>
        )}
      </DropdownItem>
    </DropdownContainer>
  )
}

export default DropdownBoards

const DropdownContainer = styled(Box)`
  gap: 16px;
  max-height: 530px;
`

const DropdownItem = styled(Box)`
  gap: 12px;
`

const DropdownTitle = styled(Box)`
  margin-right: 16px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border-radius: 14px;

  height: 35px;

  border: 1px solid ${() => AppStore.theme.o.grey};
  background: ${() => AppStore.theme.o.lightestGrey};

  span {
    display: flex;
    align-items: center;
    gap: 4px;

    color: ${() => AppStore.theme.o.black};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    svg {
      margin: 4px;
      fill: ${() => AppStore.theme.o.black};
    }
  }

  .dropdown_arrow {
    transition: 200ms ease-in-out;
    fill: ${() => AppStore.theme.o.black};
  }

  &.dropdown_active {
    border: 1px solid ${() => AppStore.theme.o.blue};
    background: ${() => AppStore.theme.o.lightBlue};

    span {
      color: ${() => AppStore.theme.o.blue};

      svg {
        fill: ${() => AppStore.theme.o.blueOnLight};
      }
    }

    .dropdown_arrow {
      rotate: -180deg;
      fill: ${() => AppStore.theme.o.blueOnLight};
    }
  }
`

const DropdownContent = styled(Box)`
  padding-right: 10px;
  gap: 12px;

  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Questo serve per indicare la lunghezza del contenitore di risultati, questa cambia in base a se un elemento è stato selezionato (e quindi bisogna ridimenzionare le altezze) */
  &.normal_max-height {
    max-height: 350px;
  }

  &.reduced_max-height {
    max-height: 250px;
  }
`

const BoardItem = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  padding: 8px 16px;
  position: relative;

  border-radius: 14px;
  border: 1px solid ${() => AppStore.theme.o.grey};

  transition: all 500ms ease-in-out;

  & > .board__item__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    gap: 4px;
    color: ${() => AppStore.theme.o.black};
    font-size: 12px;
    font-weight: 700;
    line-height: normal;

    span {
      position: relative;
      top: 1px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      text-align: center;
    }
  }

  /* Quando è selezionato e diventa grigio */
  &.selected__content {
    transition: 200ms ease-in-out;
    background: ${() => AppStore.theme.o.lightGrey};

    & > .board__item__header {
      span {
        color: ${() => AppStore.theme.o.darkGrey};
      }
    }
  }

  :hover {
    background-color: ${() => AppStore.theme.o.lightGrey};

    &.hovered__content {
      background: ${() => AppStore.theme.o.surface};
    }
  }

  .board__tooltip {
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    visibility: hidden;
    opacity: 0;
    height: 0px;
    gap: 4px;

    transition: all 300ms ease-in-out;

    background: ${() => AppStore.theme.o.surface};

    color: ${() => AppStore.theme.o.black};

    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    &.visible__board__tooltip {
      margin-top: 8px;
      height: auto;
      max-height: 152px;
      visibility: visible;
      opacity: 1;
    }
  }
`

const TooltipItem = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 8px;
  gap: 12px;
  border-radius: 8px;
  transition: 300ms ease-in-out;
  border: 1px solid transparent;

  img {
    width: 24px;
    height: 24px;
    border-radius: 8px;
  }

  span {
    overflow: hidden;
    color: ${() => AppStore.theme.o.black};
    text-overflow: ellipsis;

    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;
    letter-spacing: 0.1px;
  }

  :hover {
    border: 1px solid ${() => AppStore.theme.o.grey};
    background: ${() => AppStore.theme.o.lightGrey};
  }
`

const PreviewImagesContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  .gray_preview_img {
    width: 24px;
    height: 24px;
    border: 2px solid ${() => AppStore.theme.o.surface};
    border-radius: 50%;
    background-color: ${() => AppStore.theme.o.grey};
  }

  div {
    img {
      width: 24px;
      height: 24px;
      border: 2px solid ${() => AppStore.theme.o.surface};
      border-radius: 50%;
    }
  }

  & div:nth-of-type(1) {
    position: relative;
    right: -24px;
    z-index: 5;
  }
  & div:nth-of-type(2) {
    position: relative;
    right: -12px;
  }
`

const NoResultsMessage = styled(Box)`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 14px;
  background: ${() => AppStore.theme.o.lightestGrey};

  color: ${() => AppStore.theme.o.black};
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
