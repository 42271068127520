/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { useState } from 'react'
import { useCreatePostStore } from 'stores/CreatePostStore'
import BackwardForwardIcons from './TagComponents/BackwardForwardIcons'
import { InfoTags } from './TagComponents/InfoTags'
import { PinningContainer } from './TagComponents/PinningContainer'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  variantId: string
}

export const Tagging = ({ variantId }: Props) => {
  /* ----------------- ZUSTAND  */
  const { posts } = useCreatePostStore((s) => ({
    posts: s.posts,
  }))

  /* ----------------- VARIABLES  */
  const workingPost = posts.find((post) => post.variantId === variantId)!

  const workingPostMedias = workingPost.data.medias

  const mediasOnlyImages = workingPostMedias?.filter((media) => !media.mimetype?.includes('video'))

  /* ----------------- STATE  */
  const [activeIndex, setActiveIndex] = useState<number>(0)

  /* ----------------- VARIABLES  */

  const workingMedia = mediasOnlyImages?.[activeIndex]

  const visibleTags = workingPost.data.tags?.filter((tag) => tag.mediaId === workingMedia._id)

  /* ----------------- METHODS  */
  const moveCarousel = (index: number) => {
    const mediasLength = mediasOnlyImages.length

    const nextIndex = (activeIndex + index + mediasLength) % mediasLength

    setActiveIndex(nextIndex)
  }

  /* ----------------- USEEFFECT  */

  return (
    <TaggingContainer>
      {/* Visible Media */}
      <MainImage src={workingMedia.url} />

      {/* Pinning container */}
      <PinningContainer post={workingPost} tags={visibleTags ?? []} selectedMediaId={workingMedia._id} />

      {/* Carousel controls arrows */}
      {mediasOnlyImages?.length > 1 && <BackwardForwardIcons moveCarousel={moveCarousel} />}

      {/* Container text with info */}
      <InfoTags />
    </TaggingContainer>
  )
}

const TaggingContainer = styled(Box)`
  position: relative;
  width: 800px;
  height: 800px;
`

const MainImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 16px;
`
