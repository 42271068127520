import gsap from 'gsap'
import { AppStore } from 'utils'

class PostAnimation {
  activChild: number

  constructor() {
    this.activChild = 0
  }

  moveMainSection() {
    try {
      const section = document.getElementById('main-section')

      gsap.set(section, {
        x: 120,
        // autoAlpha: 0.5,
      })
    } catch (e) {
      console.error(e)
    }
  }
  moveMainSectionBack() {
    try {
      const section = document.getElementById('main-section')
      gsap.set(section, {
        x: 0,
        autoAlpha: 1,
      })
    } catch (e) {
      console.error(e)
    }
  }

  reduceHeight(id, height, overflow: string) {
    this.MediaLoaded()
    try {
      const section = document.getElementById(id)
      gsap.set(section, {
        maxHeight: height,
        overflow: overflow,
        ease: 'power4.out',
      })
    } catch (e) {
      console.error(e)
    }
  }

  MediaLoaded() {
    try {
    } catch (e) {
      console.error(e)
    }
  }

  onHoverStartPreview() {
    gsap.to('#iconContainer', { duration: 0.3, scale: 2, y: -10, ease: 'power4.out' })
    gsap.to('#iconUpload', { duration: 0.3, fill: AppStore.theme.o.blueOnLight, ease: 'power4.out' })
    gsap.to('#textBox', { duration: 0.3, opacity: 1, ease: 'power4.out' })
  }

  onHoverEndPreview() {
    gsap.to('#iconContainer', { duration: 0.1, scale: 1, y: 0, ease: 'power4.out' })
    gsap.to('#iconUpload', {
      duration: 0.1,
      fill: AppStore.theme.o.darkGrey,
      scale: 1,
      ease: 'power4.out',
    })
    gsap.to('#textBox', { duration: 0.1, opacity: 0, ease: 'power4.out' })
  }
}

export default new PostAnimation()
