/* ******************* IMPORT ******************* */
import styled from '@emotion/styled'
import NotifyPermission from 'api/NotifyPermission'
import Utils from 'api/Utils'
import { Box, Button, Icons } from 'components'
import { useEffect, useState } from 'react'
import { Workspace } from 'types'
import { IDefaultNotificationSetting } from 'types/NotifyInterface'
import { AppStore, T, showInfo, showWarning } from 'utils'
import NotificationSetting from './NotificationSetting'
import { SpinnerAstersLogo } from './UI'

/* ******************* INTERFACE ******************* */
interface Props {
  changeSection: (section: string, workspaceToManage: Workspace) => void
  workspace: Workspace
  onWorkspaceChange: (workspace: Workspace) => void
}

const ManageNotifications = ({ changeSection, workspace }: Props) => {
  /* ____________________ REF ____________________ */

  /* ____________________ CONTEXT ____________________ */

  /* ____________________ VARIABLES ___________________ */
  const userPhoneNumberProperties = AppStore.loggedUser.phoneNumber
  const workspaceID = workspace._id

  /* ____________________ STATE ____________________ */

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [oldNotifyData, setOldNotifyData] = useState<IDefaultNotificationSetting[]>([])
  const [notifyData, setNotifyData] = useState<IDefaultNotificationSetting[]>([])

  const [hasUserWhatsapp, setHasUserWhatsapp] = useState<boolean>()

  const [forceUpdate, setForceUpdate] = useState<boolean>(false)

  const [notifyCallInfo, setNotifyCallInfo] = useState({
    category: {},
    isDefault: false,
    userId: '',
    workspaceId: '',
    _id: '',
  })

  /* ____________________ METHODS ____________________ */
  const onForceUpdate = () => {
    setForceUpdate((prev) => !prev)
  }

  /* ____________________ API CALL ____________________ */
  const checkIfUserHasWhatsapp = () => {
    let userHasWhatsapp = false

    //Se non ha la propietà number
    if (userPhoneNumberProperties?.number === undefined) {
      return (userHasWhatsapp = false)
    }

    //Se la verifica è in corso (e quindi esiste number e otpCode)
    if (userPhoneNumberProperties?.number && userPhoneNumberProperties?.otpCode) {
      return (userHasWhatsapp = false)
    }

    //Se ha un numero whatsapp collegato e verificato
    if (userPhoneNumberProperties?.number && !userPhoneNumberProperties?.otpCode) {
      return (userHasWhatsapp = true)
    }

    return userHasWhatsapp
  }

  // Prendere il settaggio delle notifiche dal BE
  const getNotifyData = async () => {
    setIsLoading(true)
    try {
      //TODO: BE: Cambiare i name con i nomi effettivi
      const checkActiveWorkspace = await NotifyPermission.getNotificationPermissionByIds(workspaceID!)
      const convertedNotifyData = checkActiveWorkspace?.category?.map((category) => {
        const convertedPermissions = category?.permissions.map((permission) => {
          return {
            ...permission,
            name: permission.name,
            description: permission.description,
            isDefault: permission.isDefault,
            type: {
              mail: permission.settingsValue.mail,
              app: permission.settingsValue.app,
              whatsapp: permission.settingsValue.whatsapp,
            },
          }
        })

        return {
          ...category,
          name: category.name,
          description: category.description,
          permissions: convertedPermissions,
          open: false,
        }
      })

      setNotifyData(convertedNotifyData as IDefaultNotificationSetting[])
      setOldNotifyData(convertedNotifyData as IDefaultNotificationSetting[])
      setNotifyCallInfo(checkActiveWorkspace)

      setIsLoading(false)
    } catch (e) {
      console.error(e)
    }
  }

  // Manda il settaggio delle notifiche al BE
  const postNotifyData = async ({
    notificationData,
    setOnAll,
  }: {
    notificationData: IDefaultNotificationSetting[]
    setOnAll: boolean
  }) => {
    try {
      const convertNotificationPermission = notificationData?.map((category) => {
        const convertedPermissions = category?.permissions.map((permission) => {
          return {
            ...permission,
            name: permission.name,
            isDefault: permission.isDefault,
            settingsValue: {
              mail: permission.type.mail,
              app: permission.type.app,
              whatsapp: permission.type.whatsapp,
            },
          }
        })

        return {
          ...category,
          name: category.name,
          permissions: convertedPermissions,
          open: false,
        }
      })

      // const response = await NotifyPermission.updateNotificationSetting(workspaceID!, {
      //   ...notifyCallInfo,
      //   category: convertNotificationPermission,
      //   },
      //   setOnAll,
      // })

      const response = await NotifyPermission.updateNotificationSetting({
        workspaceId: workspaceID!,
        notificationPermission: {
          ...notifyCallInfo,
          category: convertNotificationPermission,
        },
        setOnAll,
      })

      setOldNotifyData(notifyData)
      showInfo(T.settings.savedChangedNotify)
    } catch (e) {
      console.error(e)
    }
  }

  // check permission is changed
  const checkPermissionIsChanged = (): boolean => {
    let checkIsValid = true
    oldNotifyData.map((cat, i) => {
      cat.permissions.map((subCat, ind) => {
        if (subCat !== notifyData[i].permissions[ind]) {
          for (const [key, value] of Object.entries(subCat.type)) {
            if (value !== notifyData[i].permissions[ind].type[key]) return (checkIsValid = false)
          }
        }
      })
    })

    return checkIsValid
  }

  /* ____________________ USEEFFECT ____________________ */
  useEffect(() => {
    getNotifyData()
  }, [])

  useEffect(() => {
    setHasUserWhatsapp(checkIfUserHasWhatsapp())
  }, [forceUpdate])

  return (
    <ManageNotificationsContainer animation="fade" scroll>
      {/* Sta caricando i dati  */}
      {isLoading && <SpinnerAstersLogo dimension={100} />}

      {/* Mostra i dati  */}
      {!isLoading && (
        <>
          <HeaderNotificationContainer>
            <BackwardIcon
              onClick={() => {
                const check = checkPermissionIsChanged()
                if (check) changeSection('management', workspace)
                else showWarning(T.settings.notSavedChangedNotify)
              }}
            >
              <Icons.backward />
            </BackwardIcon>

            {/* {
              //TODO: Il button "Salva" appare solo la prima volta, quindi quando da backend non avremo una il dato "default" settato, per adesso si opera con "propietàancoradadefinire" perchè è ancora da definire nell'interfaccia.
              !notifyCallInfo?.isDefault && (
                <SaveButton variant="primary" onClick={() => postNotifyData(notifyData)}>
                  {T.settings.save}
                </SaveButton>
              )
            } */}

            <NotificationSaveButtonsContainer>
              {T.settings.saveFor}
              <SaveButton
                variant="neutral"
                onClick={() => postNotifyData({ notificationData: notifyData, setOnAll: true })}
              >
                {T.settings.allEnvironments}
              </SaveButton>
              <SaveButton
                variant="primary"
                onClick={() => postNotifyData({ notificationData: notifyData, setOnAll: false })}
              >
                {T.settings.thisEnvironment}
              </SaveButton>
            </NotificationSaveButtonsContainer>
          </HeaderNotificationContainer>

          <ContentNotificationContainer>
            {notifyData.map((notification, i) => (
              <NotificationSetting
                index={i}
                onOpen={() => {
                  const index = notifyData.indexOf(notification)
                  const newNotifyData = [...notifyData]

                  newNotifyData[index].open = !newNotifyData[index].open
                  setNotifyData(newNotifyData)
                }}
                key={Utils.generateRandomUIDKey()}
                notification={notification}
                notifyData={notifyData}
                setNotifyData={setNotifyData}
                hasUserWhatsapp={hasUserWhatsapp}
                onForceUpdate={onForceUpdate}
              />
            ))}
          </ContentNotificationContainer>
        </>
      )}
    </ManageNotificationsContainer>
  )
}

export default ManageNotifications

const ManageNotificationsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  height: 100%;
  padding: 66px 69px 56px 56px;
`

const BackwardIcon = styled.div`
  cursor: pointer;
  transition: 200ms ease-in-out;

  svg {
    width: 20px;
    height: 20px;
    fill: ${() => AppStore.theme.o.black};
  }

  :hover svg {
    fill: ${() => AppStore.theme.o.blueOnLight};
  }
`

const HeaderNotificationContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const SaveButton = styled(Button)`
  padding: 12px 24px;
  min-height: 41px;

  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

const NotificationSaveButtonsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;

  color: ${() => AppStore.theme.o.black};
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

const ContentNotificationContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
