/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons, Text } from 'components'
import { AppStore } from 'utils'

/* ------------------- INTERFACES  */
interface Props {
  name: string
  icon: JSX.Element
  action: () => void
}

export const DashboardBtn = ({ name, icon, action }: Props) => {
  return (
    <DashboardBtnContainer id="add-social-button" onClick={action}>
      <Text classes="add-social-text" fontSize={16} weight={700}>
        {name}
      </Text>

      <AddSocialIconContainer className="add-icon-container">{icon}</AddSocialIconContainer>
    </DashboardBtnContainer>
  )
}

const DashboardBtnContainer = styled(Box)`
  border: 2px solid ${() => AppStore.theme.o.lightGrey};
  width: 196px;
  height: 152px;
  border-radius: 14px;
  padding: 31px 24px 24px 24px;
  justify-content: space-between;
  align-items: center;

  .add-social-text {
    color: ${() => AppStore.theme.o.black};
  }

  .add-icon-container {
    height: 50px;
    width: -webkit-fill-available;
    background-color: ${() => AppStore.theme.o.lightGrey};
  }

  svg {
    path {
      fill: ${() => AppStore.theme.o.black};
    }
  }

  &:hover {
    background-color: ${() => AppStore.theme.o.on_secondary_container};

    .add-social-text {
      color: ${() => AppStore.theme.o.surface} !important;
    }

    .add-icon-container {
      background-color: ${() => AppStore.theme.o.surface};
    }

    svg {
      path {
        fill: ${() => AppStore.theme.o.on_secondary_container};
      }
    }
  }
`

const AddSocialIconContainer = styled(Box)`
  align-items: center;
  justify-content: center;
  svg {
    fill: ${() => AppStore.theme.o.black};
  }
`
