import { mergeWithDefaultOptions } from '../defaultChartOptions'

export const doughnutOptions = mergeWithDefaultOptions({
  chart: {
    type: 'pie',
  },
  legend: {
    layout: 'vertical',
    verticalAlign: 'middle',
    align: 'right',
  },
  plotOptions: {
    pie: {
      innerSize: '50%',
      size: 200,
      depth: 45,
      showInLegend: true,
      dataLabels: { enabled: false },
      borderColor: 'transparent',
    },
  },
  series: [
    {
      name: 'Data',
      data: [
        ['Category 1', 25],
        ['Category 2', 50],
        ['Category 3', 15],
        ['Category 4', 10],
      ],
    },
  ] as Highcharts.SeriesOptionsType[],
})
