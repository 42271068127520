import { useState } from 'react'
import styled from '@emotion/styled'
import { Box } from 'components'
import { AppStore, T } from 'utils'
import { IPostVariant } from 'types/PostInterface'
import { updatePost } from 'utils/PostUtils/Post'

/* ******************* INTERFACE ******************* */
interface Props {
  post: IPostVariant
}

export const YouTubeExtraOptions = ({ post }: Props) => {
  /* ____________________ STATE  ____________________ */
  const [isEmbedded, setIsEmbedded] = useState<boolean>(post.data.embedded ?? false)
  const [notifySubscribers, setNotifySubscribers] = useState<boolean>(post.data.notifySubscriber ?? false)

  /* ____________________ VARIABLES  ____________________ */
  const { variantId } = post

  /* ____________________ METHODS  ____________________ */
  const handleIsEmbedded = () => {
    setIsEmbedded((prev) => {
      updatePost(variantId, { embedded: !prev })

      return !prev
    })
  }

  const handleNotifySubscribersChange = () => {
    setNotifySubscribers((prev) => {
      updatePost(variantId, { notifySubscriber: !prev })

      return !prev
    })
  }

  return (
    <ExtraOptionsContainer>
      <OptionsContainer>
        <Title>
          <Box
            flex
            row
            center
            ph={16}
            pv={8}
            style={{
              backgroundColor: notifySubscribers ? AppStore.theme.o.lightGreen : AppStore.theme.o.lightestGrey,
              border: `1px solid ${notifySubscribers ? AppStore.theme.o.green : AppStore.theme.o.grey}`,
            }}
            onClick={handleNotifySubscribersChange}
          >
            <OptionText style={{ color: notifySubscribers ? AppStore.theme.o.green : AppStore.theme.o.black }}>
              {T.postPage.youtubeTubeExtra.notifySubscribers}
            </OptionText>
            <Box
              style={{
                width: 27,
                height: 16,
                backgroundColor: notifySubscribers ? AppStore.theme.o.green : AppStore.theme.o.grey,
              }}
            >
              <Box
                style={{
                  width: 16,
                  height: 16,
                  border: `1px solid ${notifySubscribers ? AppStore.theme.o.green : AppStore.theme.o.darkGrey}`,
                  backgroundColor: AppStore.theme.o.surface,
                  x: notifySubscribers ? 11 : 0,
                }}
              />
            </Box>
          </Box>
        </Title>

        <Title>
          <Box
            flex
            row
            center
            ph={16}
            pv={8}
            style={{
              backgroundColor: isEmbedded ? AppStore.theme.o.lightGreen : AppStore.theme.o.lightestGrey,
              border: `1px solid ${isEmbedded ? AppStore.theme.o.green : AppStore.theme.o.grey}`,
            }}
            onClick={handleIsEmbedded}
          >
            <OptionText style={{ color: isEmbedded ? AppStore.theme.o.green : AppStore.theme.o.black }}>
              {T.postPage.youtubeTubeExtra.embeddable}
            </OptionText>
            <Box
              style={{
                width: 27,
                height: 16,
                backgroundColor: isEmbedded ? AppStore.theme.o.green : AppStore.theme.o.grey,
              }}
            >
              <Box
                style={{
                  width: 16,
                  height: 16,
                  border: `1px solid ${isEmbedded ? AppStore.theme.o.green : AppStore.theme.o.darkGrey}`,
                  backgroundColor: AppStore.theme.o.surface,
                  x: isEmbedded ? 11 : 0,
                }}
              />
            </Box>
          </Box>
        </Title>
      </OptionsContainer>
    </ExtraOptionsContainer>
  )
}

const OptionsContainer = styled(Box)`
  flex-direction: row;
  gap: 24px;
`

const ExtraOptionsContainer = styled(Box)`
  align-items: center;
`

const Title = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

const OptionText = styled.p`
  font-weight: 500;
  line-height: normal;
  margin-right: 8px;
`
