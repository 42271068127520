import { useUpgradePlanStore } from 'stores/UpgradePlanStore'
import { SubscriptionPlan } from 'types/SubscriptionInterfaces'
import { AppStore, T } from 'utils'
import { useUserStore } from 'stores/UserStore'
import { calculateVAT } from 'utils/UsersUtils'
import { UpgradeOptionComponent } from './UpgradeOptionComponent'

/**
 * Component for displaying upgrade with prorate
 */

export interface IProrateData {
  currentPlan: {
    fullAmount: number
    proratedAmount: number
    name: string
    interval: string
  }
  newPlan: {
    fullAmount: number
    proratedAmount: number
    name: string
    interval: string
  }
  costDifference: number
  currency: string
  nextBillingDate: string
}

export const UpgradeWithProrateInterface: React.FC<{ plan: SubscriptionPlan }> = ({ plan }) => {
  // ------- STORE ------
  const { prorateData } = useUpgradePlanStore((state) => {
    return { prorateData: state.prorateData }
  })

  const { currentLanguage } = useUserStore((state) => ({
    currentLanguage: state.user?.language ?? 'it',
  }))

  // Amounts of operations
  const normalizedAmount = prorateData?.costDifference ?? 0
  const operationAmount = {
    amount: normalizedAmount,
    amountWithVAT: calculateVAT(normalizedAmount),
    amountTotal: normalizedAmount + Number(calculateVAT(normalizedAmount)),
  }
  // Configuration for prorate
  const prorateConfig = {
    isCredit: true,
    creditColor: AppStore.theme.o.green,
    newPlanName: plan?.data?.[currentLanguage]?.title ?? '-',
    oldPlanName: AppStore.loggedUser.subscription?.activePlan?.plan?.data?.[currentLanguage].title ?? '-',
  }

  // Information about current plan
  const fromPlan = {
    name: prorateConfig.oldPlanName,
    description: T.billing.upgradePlan.pricePlan,
    amount: prorateData?.currentPlan?.proratedAmount ?? 0,
    isCredit: prorateConfig.isCredit,
    creditColor: prorateConfig.creditColor,
  }

  // Information about new Plan
  const toPlan = {
    name: prorateConfig.newPlanName,
    description: T.billing.upgradePlan.pricePlan,
    amount: prorateData?.newPlan?.fullAmount ?? 0,
    creditColor: AppStore.theme.o.red,
  }

  return (
    <UpgradeOptionComponent
      fromPlan={fromPlan}
      toPlan={toPlan}
      type="prorate"
      options={operationAmount}
      title={T.billing.upgradePlan.upgrade}
      description={T.billing.upgradePlan.upgradeProrateDescription}
    />
  )
}
