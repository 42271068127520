import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Box } from 'components'
import { AppStore, T } from 'utils'
import Utils from 'api/Utils'
import { IPostVariant } from 'types/PostInterface'
import { ExtraTitle } from '../GenericExtras/ExtraTitle'
import { updatePost } from 'utils/PostUtils/Post'
import ExtraSelect from '../GenericExtras/ExtraSelect/ExtraSelect'

/* ******************* INTERFACE ******************* */
interface Props {
  post: IPostVariant
}

interface Playlist {
  id: string
  title: string
}

export const YouTubePlaylist = ({ post }: Props) => {
  /* ____________________ STATE  ____________________ */
  const [playlist, setPlaylist] = useState<Playlist[]>([])
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)
  const [selectedPlaylists, setSelectedPlaylists] = useState<Playlist[]>(post.data.playlist ?? [])

  /* ____________________ VARIABLES  ____________________ */
  const { variantId } = post

  /* ____________________ METHODS  ____________________ */
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev)
  }

  const handleSelectPlaylist = (selectedItem: Playlist) => {
    const playlistExists = selectedPlaylists.some((item) => item.id === selectedItem.id)

    if (playlistExists) {
      const updatedPlaylist = selectedPlaylists.filter((item) => item.id !== selectedItem.id)
      setSelectedPlaylists(updatedPlaylist)
      updatePost(variantId, { playlist: updatedPlaylist })
    } else {
      updatePost(variantId, { playlist: [...selectedPlaylists, selectedItem] })
      setSelectedPlaylists([...selectedPlaylists, selectedItem])
    }
  }

  const getDropdownHeaderText = (): string => {
    if (selectedPlaylists.length === 0) {
      return T.postPage.youtubeTubeExtra.selectPlaylist
    } else if (selectedPlaylists.length === 1) {
      return `${selectedPlaylists.length} ${T.postPage.youtubeTubeExtra.singlePlaylistSelected}`
    } else {
      return `${selectedPlaylists.length} ${T.postPage.youtubeTubeExtra.multiplePlaylistSelected}`
    }
  }
  /* ____________________ API CALL  ____________________ */
  const fetchCategories = async () => {
    //TODO: Da vedere se è l'id che è richeisto, prima passava "post.socialaccount?._id"
    const request = await Utils.getYouTubePlaylists('', post.info.account.account_id as string)

    const data = request.data as Playlist[]

    setPlaylist(data)
  }

  /* ____________________ USE-EFFECT  ____________________ */
  useEffect(() => {
    fetchCategories()
  }, [])

  return (
    <PlaylistContainer>
      <ExtraTitle text={T.postPage.youtubeTubeExtra.playlist} />

      <ExtraSelect
        classes="select-playlist-youtube"
        maxWidth={'50%'}
        selectWith="checkbox"
        toggleDropdown={toggleDropdown}
        isDropdownOpen={isDropdownOpen}
        items={playlist}
        dropdownHeaderText={getDropdownHeaderText()}
        onSelect={handleSelectPlaylist}
        selectedItems={selectedPlaylists}
        truncateTextNum={30}
      />
    </PlaylistContainer>
  )
}

const PlaylistContainer = styled(Box)`
  flex: 1;
  gap: 12px;
`
