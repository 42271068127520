import styled from '@emotion/styled'
import Utils from 'api/Utils'
import { Box, Button, ZoomedContainer } from 'components'
import { Icons } from 'components/Icons'
import { Text } from 'components/Text'
import { TextArea } from 'components/TextArea'
import { Component } from 'react'
import { ModalType } from 'types'
import { AppStore } from 'utils'

interface State {
  step: number

  textValue: string
  width: any
  height: any
}
interface Props {
  selectCategory: any
}
export default class OthersChoisePage extends Component<Props, State> {
  state: State = {
    step: 0,
    textValue: '',
    width: 0,
    height: 0,
  }

  componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<State>, snapshot?: any): void {
    // if (this.state.step === 0 && this.state.textValue !== '') this.setState({ step: 1 })
    if (this.state.step === 1 && this.state.textValue === '') this.setState({ step: 0 })
  }

  showInfo = (event?) => {
    if (event.key === 'Enter' || event === undefined) {
      if (this.state.step === 0 && this.state.textValue !== '') this.setState({ step: 1 })
      if (this.state.step === 1 && this.state.textValue === '') this.setState({ step: 0 })
      // document.getElementById('text-area-create-post')?.style.outline('none')
    }
  }

  choises = [
    'MarketingComunicazione',
    'SportBenessere',
    'FormazioneConsulenza',
    'IndustriaServizi',
    'Horeka e Turismo',
    'Vendita al dettaglio',
  ]
  render() {
    //UNA FUNZIONE DI DELAY
    function delay(time) {
      return new Promise((resolve) => setTimeout(resolve, time))
    }
    return (
      <Box
        flex
        style={{
          justifyContent: this.state.width > 1184 ? 'center' : 'none',
          alignItems: this.state.width > 1184 ? 'center' : 'none',
          marginBottom: this.state.width > 1184 ? '100px' : '0px',
        }}
      >
        <Box style={{ maxHeight: 'calc(100vh - 140px)' }} scroll>
          <Container>
            <InfoBox mt={24}>
              <Text weight={500} fontSize={16} style={{ textAlign: 'center' }}>
                Qual è il tuo settore?
              </Text>
            </InfoBox>
            <MiniBox p={24}>
              {/* ---------------------- FINTA TEXTAREA ( SOLO CLICABILE PER CREARE VALORI )  ---------------------- */}

              <FlexTextArea
                id="text-area-create-post"
                bgcolor={AppStore.theme.o.lightGrey}
                bgcoloractive={AppStore.theme.o.lightGrey}
                borderactivecolor={AppStore.theme.o.grey}
                bordercolor={'transparent'}
                borderwidth={'1px'}
                onKeyPress={this.showInfo}
                height={120}
                onChange={(e) => this.setState({ textValue: e })}
                onBlur={this.showInfo}
                placeholder="Scrivi qui il tuo settore specifico"
              />

              {/* ---------------------- GENERATE BUTTON ---------------------- */}
              <Box row style={{ justifyContent: 'space-between' }}></Box>
            </MiniBox>
          </Container>
          {this.state.step === 1 && (
            <InfoBox mt={24} animate={{ opacity: 1 }}>
              <Text weight={500} fontSize={16} style={{ textAlign: 'center' }}>
                Ci dispiace non poter personalizzare l’esperienza per il tuo settore. Per mostrarti le potenzialità di
                Asters, sceglieremo noi un percorso casuale
              </Text>
            </InfoBox>
          )}
          <VoglioQuesto
            onClick={async () => {
              this.props.selectCategory(this.choises[Math.floor(Math.random() * 5)])
              await Utils.saveOnAirtableAnswer({ value: this.state.textValue })
            }}
            mt={8}
          >
            <Text style={{ fontSize: 16, fontWeight: 700, color: AppStore.theme.o.surface }}>Ok procediamo!</Text>
          </VoglioQuesto>
        </Box>
      </Box>
    )
  }
}

const MiniBox = styled(Box)`
  flex-direction: column;
  margin-top: 2vh;
  padding: 24px;
  /* gap: 8px; */

  background: #ffffff;

  box-shadow: 4px 8px 16px rgba(213, 214, 218, 0.14);

  border-radius: 14px;
`

const SelectModalButton = styled(Box)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 4px;

  max-width: 133.5px;

  border-radius: 14px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 1;
  z-index: 0;
`

const GenerationButton = styled(Button)`
  width: 133.5px;
  height: 42px;
`
const FlexTextArea = styled(TextArea)`
  font-size: 12px !important;
  line-height: 160% !important;
`

const Container = styled(Box)`
  width: 95vw;

  max-width: 375px;
`
const InfoBox = styled(Box)`
  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  width: 95vw;
  max-width: 375px;

  /* white */

  background: #ffffff;
  /* drop-light */

  gap: 0 !important;
  box-shadow: 4px 8px 16px rgba(213, 214, 218, 0.14);

  border-radius: 14px;
`

const VoglioQuesto = styled(Button)`
  /* CTA */

  /* Auto layout */
  margin-top: 16px;

  display: flex;
  /* width: calc(100vw); */
  width: 100%;

  max-width: 375px;

  align-items: center;
  padding: 16px;
  background: #18818b;
  /* drop-light */
  height: 'calc(100vh - 80px)';
  box-shadow: 4px 8px 16px rgba(213, 214, 218, 0.14);
  border-radius: 14px;
  /* Inside auto layout */
`
