/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { SocialPicture } from 'components/UI/SocialPicture'
import { useAnalyticsStore } from 'stores/AnalyticsStore'
import { ISelectableSocial } from 'types/AnalyticsInferfaces'
import { AppStore } from 'utils'

/* ------------------- INTERFACES  */
interface Props {
  account: ISelectableSocial
  accountSelected: ISelectableSocial
}

export const CheckboxMetricElement = ({ account, accountSelected }: Props) => {
  /* ------------------- ZUSTAND  */
  const { setSelectedAccount } = useAnalyticsStore.getState()

  /* ----------------- VARIABLES  */
  const { picture, provider } = account

  /* ----------------- METHODS  */
  const onClickCheck = () => {
    setSelectedAccount({
      _id: account._id,
      picture: picture,
      provider: provider,
    })
  }

  return (
    <CheckboxMetricElementContainer
      className={`checbox_metric_element ${accountSelected._id === account._id ? 'selected' : 'not_selected'}`}
      onClick={onClickCheck}
    >
      <SocialPicture picture={picture!} provider={provider!} active={accountSelected._id === account._id} />
    </CheckboxMetricElementContainer>
  )
}

const CheckboxMetricElementContainer = styled(Box)`
  &.checbox_metric_element {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 14px;

    &.selected {
      background: ${() => AppStore.theme.o.lightGreen};
      border: 1px solid ${() => AppStore.theme.o.green};

      &:hover {
        background: ${() => AppStore.theme.o.lightGreen};
      }
    }

    &.not_selected {
      background: ${() => AppStore.theme.o.lightestGrey};
      border: 1px solid transparent;
    }

    &:hover {
      background: ${() => AppStore.theme.o.grey};
    }
  }
`
