import { AppStore, formatNumber } from 'utils'
import { mergeWithDefaultOptions } from '../defaultChartOptions'

const tooltip: Highcharts.TooltipOptions = {
  borderRadius: 14,
  backgroundColor: AppStore.theme.o.lightestGrey,
  headerFormat: `<span style="color:{point.color}">{point.name}</span><br/>`,
  pointFormatter: function () {
    return `<b style="color: ${AppStore.theme.o.darkGrey}">${this.name}:</b> <b style="color: ${
      AppStore.theme.o.black
    }">${this.y !== undefined ? formatNumber(this.y) : 'NaN'}</b>`
  },
}

export const pieOptions: Highcharts.Options = mergeWithDefaultOptions({
  chart: {
    type: 'pie',
  },
  legend: {
    layout: 'vertical',
    verticalAlign: 'middle',
    align: 'right',
    width: 220,
    labelFormatter: function () {
      // Format the legend label to include the percentage

      //@ts-ignore
      const percentage = this.percentage.toFixed(0)

      return `${percentage}% ${this.name}`
    },
  },
  tooltip: tooltip,
  plotOptions: {
    pie: {
      innerSize: '52%',
      size: 200,
      depth: 45,
      borderWidth: 1,
      borderRadius: 4,
      borderColor: AppStore.theme.o.background,
      showInLegend: true,
      dataLabels: { enabled: false },
      colors: [
        '#F57493',
        '#AD33FF',
        '#3773FF',
        '#9699F7',
        '#6AE1E9',
        '#5EC081',
        '#C9D472',
        '#FFC533',
        '#FF7A33',
        '#F53D3D',
        '#911D43',
      ],
    },
  },
})
