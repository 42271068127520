/* ------------------- IMPORTS */
import { MultiSelectRow } from 'components/UI/MultiSelectRow'
import { useDraftStore } from 'stores/DraftStore'
import { T } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {}

export const ContentFilterDraft = ({}: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */
  const { contentType } = useDraftStore((s) => ({ contentType: s.contentType }))

  const { setContentType } = useDraftStore()

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  /* ----------------- CONFIG  */
  const data = [
    {
      name: T.postPage.draftAll,
      value: 'all',
      action: () => setContentType('all'),
    },
    {
      name: T.postPage.draftPhotos,
      value: 'image',
      action: () => setContentType('image'),
    },
    {
      name: T.postPage.draftVideo,
      value: 'video',
      action: () => setContentType('video'),
    },
    {
      name: T.postPage.draftText,
      value: 'text',
      action: () => setContentType('text'),
    },
  ]

  return <MultiSelectRow active={contentType} options={data} />
}
