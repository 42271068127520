import { useState } from 'react'
import styled from '@emotion/styled'
import { AppStore, T } from 'utils'
import { Box, Icons } from 'components'
import BoardCard from './BoardCard'
import { dateToString, decodeHTML, onLoadImageError } from '../commonFunction'
import asters_placeholder from 'assets/asters_placeholder.png'
import { IArticle } from '../aiDiscoverInterface'
import InfoComments from '../UI/InfoComments'
import { useDiscoverStore } from 'stores/DiscoverStore'
import { PlaceHolderImgNotFound } from 'components/PlaceholderImgNotFound'
import { isAiDiscoverCommentsEnabled } from 'utils/DiscoverUtils'

/* ____________________ INTERFACE ____________________ */
interface Props {
  article: IArticle
  commentsNumber?: number
  index?: number
  onClick?: any
  isSelected?: boolean
  articleSelected?: boolean
}

const PreviewCard = ({ article, commentsNumber, index, onClick, isSelected, articleSelected }: Props) => {
  /* ____________________ STATE ____________________ */

  const [openBoard, setOpenBoard] = useState<boolean>(false)

  /* ____________________ ZUSTAND  ____________________ */
  const { activeBoard, subPageActive, openArticleModal } = useDiscoverStore((state) => ({
    activeBoard: state.activeBoard,
    subPageActive: state.subPageActive,
    openArticleModal: state.openArticleModal,
  }))

  /* ____________________ METHODS ____________________ */

  const onClickOpenBoard = () => setOpenBoard((prev) => !prev)

  const activeArticleHandler = (action: string) => {
    if (articleSelected) {
      onClick(article)
      return
    }

    if (!articleSelected && action === 'open_article') {
      openArticleModal(article, activeBoard, subPageActive === 'board')
      return
    }

    if (action === 'save_in_board') {
      onClickOpenBoard()
      return
    }
  }

  const showEstimatedReadingTime = (description) => {
    const numberOfWords = description.replace(/\s+/g, ' ').trim().split(' ').length
    if (description.length > 0) {
      const secondsToRead = Math.ceil(numberOfWords / 3.333)
      const minutes = Math.floor(secondsToRead / 60)
      const seconds = secondsToRead % 60
      return `${minutes}min ${seconds >= 10 ? seconds : `0${seconds}`}sec`
    } else {
      return '0min 0sec'
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <PreviewCardContainer
        className={`preview-card-container ${openBoard ? 'active' : ''}`}
        onClick={activeArticleHandler}
      >
        {/* ______________ ICON SAVE ARTICLE _______________ */}
        {!activeBoard && (
          <SaveArticle
            className={`save-article-container ${openBoard ? 'active' : ''}`}
            onClick={() => activeArticleHandler('save_in_board')}
          >
            <Icons.saveIcon fill="currentColor" />
          </SaveArticle>
        )}

        {activeBoard && (
          <CheckBoxDiv
            className={`checkbox_bottomBar ${isSelected ? 'selected' : ''}`}
            onClick={(e) => {
              e.stopPropagation()
              onClick(article)
            }}
          >
            {isSelected && <Icons.boxgreen fill={AppStore.theme.o.green} />}
            {!isSelected && <Icons.checkboxgrey fill={AppStore.theme.o.darkGrey} />}
          </CheckBoxDiv>
        )}

        {/* ______________ CARD _______________ */}
        <Card className="article-card" onClick={() => activeArticleHandler('open_article')}>
          {/* ____________________ SOURCE NAME/TITLE ____________________ */}
          <SourceName className="source-name">{article.source.title ?? T.AiDiscoverPage.word.source}</SourceName>

          {/* _____________ TITLE-DESCRIPTION CONTAINER _____________ */}
          <CardTitleDescContainer>
            {/* ____________________ TITLE ____________________ */}
            <CardTitle>{decodeHTML(article.title)}</CardTitle>
            {/* ____________________ DESCRIPTION ____________________ */}
            <CardDescription>{article.body}</CardDescription>
          </CardTitleDescContainer>
          {/* ____________________ IMAGE ____________________ */}
          <CardImage>
            {article.image ? (
              <img
                onError={onLoadImageError}
                className="card-img"
                src={article.image}
                alt="image"
                style={{ backgroundColor: `${AppStore.theme.o.lightGrey}` }}
              />
            ) : (
              <PlaceHolderImgNotFound variant="small" />
            )}
          </CardImage>
          {/* ____________________ INFO ( TEMPO DI LETTURA E DATA ARTICOLO ) ____________________ */}
          <CardSource>
            <EstimatedReadingTime>
              <Icons.readingTime fill={AppStore.theme.o.black} width={16} height={16} />
              {showEstimatedReadingTime(article.body)}
            </EstimatedReadingTime>

            <CardSourceDate>{dateToString(article.dateTimePub ?? article.dateTime, index ?? 10)}</CardSourceDate>
          </CardSource>
        </Card>

        {/* ______________ BOARD _______________ */}

        <BoardCard isOpen={openBoard} article={article} />
      </PreviewCardContainer>

      {/* ______________ NUMBER OF COMMENTS _______________ */}
      {isAiDiscoverCommentsEnabled && commentsNumber !== undefined && commentsNumber > 0 && (
        <InfoComments commentsLength={commentsNumber} />
      )}
    </div>
  )
}

export default PreviewCard

/* STYLE */

// aggiunta una classe di supporto per il carosello dove le card non in hover hanno opacitÃ  0.5 o meno e quelle in hover
const PreviewCardContainer = styled(Box)`
  &.preview-card-container {
    background-color: ${() => `${AppStore.theme.o.surface}`};
    width: 351px;
    height: 423px;
    max-height: 423px;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: ${() => `1px solid ${AppStore.theme.o.grey}`};
    border-radius: 24px;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    overflow: hidden;

    &:hover {
      border: 1px solid transparent;
      box-shadow: ${() => AppStore.theme.finalNavbarBoxShadow.slice(11)};
      .save-article-container {
        top: 10px;
      }
      .checkbox_bottomBar {
        top: 12px;
      }
    }

    &.active {
      border: 1px solid transparent;
      box-shadow: ${() => AppStore.theme.finalNavbarBoxShadow.slice(11)};
    }
  }
`

const SaveArticle = styled(Box)`
  transition: all 0.3s ease-in-out;
  z-index: 9;

  &.save-article-container {
    background-color: ${() => `${AppStore.theme.o.lightestGrey}`};
    color: ${() => `${AppStore.theme.o.darkGrey}`};
    width: 32px;
    height: 32px;
    position: absolute;
    top: -32px;
    right: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    &:hover {
      background-color: ${() => `${AppStore.theme.o.lightBlue}`};
      color: ${() => `${AppStore.theme.o.blue}`};
    }

    &.active {
      top: 10px;
      background-color: ${() => `${AppStore.theme.o.lightBlue}`};
      color: ${() => `${AppStore.theme.o.blue}`};
    }
  }

  svg {
    height: 24px;
    width: 24px;
  }
`

const CheckBoxDiv = styled(Box)`
  transition: all 0.3s ease-in-out;
  z-index: 9;

  &.checkbox_bottomBar {
    background-color: ${() => `${AppStore.theme.o.lightestGrey}`};
    width: 32px;
    height: 32px;
    position: absolute;
    top: -32px;
    right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: ${() => `${AppStore.theme.o.lightGreen}`};
      color: ${() => `${AppStore.theme.o.blue}`};
    }

    &.selected {
      top: 12px;
      background-color: ${() => `${AppStore.theme.o.lightGreen}`};
      color: ${() => `${AppStore.theme.o.blue}`};
    }
  }
`

const Card = styled(Box)`
  width: 100%;
  min-height: 100%;
  max-height: 423px;
  padding: 16px 24px;
  display: flex;
  gap: 16px;
  border-radius: 24px;

  &:hover {
    background-color: ${() => `${AppStore.theme.o.surface}`};
  }
`

const SourceName = styled(Box)`
  border-radius: 0;

  &.source-name {
    color: ${() => `${AppStore.theme.o.darkGrey}`};
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    max-width: 255px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    white-space: nowrap;
  }
`

const CardTitleDescContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const CardTitle = styled(Box)`
  color: ${() => `${AppStore.theme.o.black}`};
  width: 100%;
  max-width: 100%;
  max-height: 81px;
  font-size: 21px;
  font-weight: 600;
  line-height: 27.3px;
  letter-spacing: 0.21px;
  border-radius: 0;

  /*   per generare i puntini senza Javascript */
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* number of lines to show */

  white-space: normal;
`

const CardDescription = styled(Box)`
  color: ${() => `${AppStore.theme.o.darkGrey}`};
  width: 100%;
  max-height: 39px;
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 1%;
  border-radius: 0;

  /*   per generare i puntini senza Javascript */
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* number of lines to show */

  white-space: normal;
`

const CardImage = styled(Box)`
  width: 100%;
  height: 180px;
  flex-grow: 1;
  overflow: hidden;
  border-radius: 14px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const CardSource = styled(Box)`
  color: ${() => `${AppStore.theme.o.black}`};
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.36px;
  letter-spacing: 1%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  border-radius: 0;
`

const CardSourceDate = styled(Box)`
  border-radius: 0;
`

const EstimatedReadingTime = styled(Box)`
  color: ${() => `${AppStore.theme.o.black}`};
  font-size: 12px;
  font-weight: 400;
  line-height: 15.36px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
`

const CommentNumberContainer = styled(Box)`
  position: absolute;
  top: -12px;
  left: -5px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border-radius: 50%;
  padding: 6px;
  border: 1px solid ${() => `${AppStore.theme.o.blue}`};
  background: ${() => `${AppStore.theme.o.lightBlue}`};

  color: ${() => `${AppStore.theme.o.blue}`};
  text-align: center;

  font-family: AktivGrotesk-Regular;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
`
