/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Text } from 'components'
import { SocialUser } from 'components/UI'
import { IPostVariant } from 'types/PostInterface'
import { AppStore, T } from 'utils'
import { YoutubeCategory } from './YoutubeCategory'
import { YouTubeAudience } from './YouTubeAudience'
import { YouTubePlaylist } from './YoutubePlaylist'
import { YouTubeHashtags } from './YoutubeHashtags'
import { YouTubeExtraOptions } from './YouTubeExtraOptions'
import { GeneralPrivacyOptions } from '../GenericExtras/GeneralPrivacyOptions'
import { YOUTUBEPRIVACY } from 'utils/Constants/Constants'
import { getFirstMedia } from 'utils/PostUtils/Post'
import { PreviewVideoContainer } from '../GenericExtras/PreviewVideoContainer'

/* ------------------- INTERFACES  */
interface Props {
  post: IPostVariant
}

export const YoutubeExtrasContainer = ({ post }: Props) => {
  const { picture, provider, name } = post.info.account
  const { medias } = post.data

  // Trasforma l'oggetto in un array di opzioni
  const youtubePrivacyOptions = Object.entries(YOUTUBEPRIVACY()).map(([value, label]) => ({ value, label }))

  // Funzione per gestire la selezione delle opzioni di privacy
  const handlePrivacyOptionChange = () => {
    const initialPrivacyStatus = post.data.privacyStatus || youtubePrivacyOptions[0].value
    return initialPrivacyStatus
  }

  return (
    <YoutubeExtrasContainerContainer>
      <SocialUser picture={picture} provider={provider} name={name} />

      <ExtrasContent>
        <GeneralPrivacyOptions
          post={post}
          privacyOptions={youtubePrivacyOptions}
          onSelectOption={handlePrivacyOptionChange}
          disabledOptions={[]}
          titleText={T.postPage.youtubeTubeExtra.privacy}
        />
        <CategoryAudienceContainer>
          <YoutubeCategory post={post} /> {/* --> SELECT */}
          <YouTubeAudience post={post} />
        </CategoryAudienceContainer>

        <AdvancedOptions>
          <AdvancedOptionsText>{T.postPage.youtubeTubeExtra.advancedOptions}</AdvancedOptionsText>

          <PlaylistExtrasContainer>
            <YouTubePlaylist post={post} /> {/* --> SELECT */}
            {/* <YouTubeExtras post={post} /> */}
          </PlaylistExtrasContainer>

          {medias && medias.length > 0 && getFirstMedia(medias, 'video') && (
            <PreviewVideoContainer post={post} filterVideoUrl={getFirstMedia(medias, 'video')} />
          )}

          <YouTubeHashtags post={post} />
          <YouTubeExtraOptions post={post} />

          {/* //TODO: Da fa in futuro con roba bella nuova*/}
          {/* <YouTubeSubtitles post={post} onPostChange={onPostChange} /> */}
        </AdvancedOptions>
      </ExtrasContent>
    </YoutubeExtrasContainerContainer>
  )
}

const YoutubeExtrasContainerContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 40px;
`

const ExtrasContent = styled(Box)`
  width: 100%;
  max-width: 541px;
  flex-direction: column;
  gap: 24px;
  border: none;
`

const AdvancedOptionsText = styled((props) => <Text {...props} />)`
  position: absolute;
  top: -15px;
  background-color: ${() => AppStore.theme.o.lightestGrey};
  padding: 6px 16px;
  border-radius: 14px;
  font-size: 12px;
  color: ${() => AppStore.theme.o.darkGrey};
`

const AdvancedOptions = styled(Box)`
  margin-top: 12px;
  border: 1px dashed ${() => AppStore.theme.o.grey};
  padding: 40px 24px 24px;
  gap: 40px;
  position: relative;
`

const CategoryAudienceContainer = styled(Box)`
  flex-direction: row;
  gap: 24px;
`

const PlaylistExtrasContainer = styled(Box)`
  flex-direction: row;
  gap: 24px;
`
