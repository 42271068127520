import styled from '@emotion/styled'

import { Button, Icons } from 'components'
import { Box } from 'components/Box'
import { Modal } from 'components/Modal/Modal'
import { Component } from 'react'
import { ModalType } from 'types'
import { history } from 'utils/router'
import FifthPage from './FifthPage'
import FirstPage from './FirstPage'
import FourthPage from './FourthPage'
import Mock from './Jsons/Mock.json'
import OthersChoisePage from './OthersChoisePage'
import SecondPage from './SecondPage'
import ThirdPage from './ThirdPage'

import { FirstOne } from './Lotties/FirstOne'

import LottieAnimation from './LottieAnimation'

interface State {
  part: number
  selectedCategory: string //CATEGORIA SELEZIONATA
  typeSelected: number //TIPOLOGIA SELEZIONATA ES CONTEST GIVEAWAY
  textSelected: string //IL TESTO SELEZIONATO
  imgSelected: string //L'IMAGINE SELEZIONATA
  intestedMock: any //MOCK FILTRATO
  modals: ModalType[]
  value: any
  history: any
  activePath: string
  x: number
  y: number
  loop: boolean
  condition: boolean
  lotties: any
  paramsToPass: string
}

export interface NavigationOptions {
  replace?: boolean
  state?: any
  props?: { [key: string]: any }
}

export class Demo extends Component {
  state: State = {
    part: 1,
    selectedCategory: '',
    typeSelected: 0,
    textSelected: '',
    imgSelected: '',
    history: history,
    intestedMock: [],
    modals: [],
    value: 2,
    activePath: window.location.pathname,
    x: 0,
    y: 200,
    loop: false,
    condition: true,
    lotties: [],
    paramsToPass: '',
  }

  subscribe: any

  // componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{}>, snapshot?: any): void {
  //   window.addEventListener('PUSH', function (e: any) {
  //     const path = e!.target!.pathname ?? e!.target!.window.location.pathname
  //   })
  // }

  componentDidMount(): void {
    // document.createElement('script').innerHTML()

    //@ts-ignore
    if (window.location.pathname.includes('/demo')) import('./demo.css')

    addEventListener('click', (event) => {
      if (this.state.part === 5)
        this.setState({
          x: event.clientX,
          y: event.clientY,
          lotties: [...this.state.lotties, [event.clientX, event.clientY]],
        })

      setTimeout(() => this.setState({ loop: false }), 300)
    })

    this.subscribe = window.addEventListener('popstate', (event) => {
      if (this.state.part > 1) {
        this.setState({ lotties: [], condition: true })
        this.goBack()
      }
    })

    const urlParams = new URLSearchParams(window.location.search)
    const placement = urlParams.get('utm_source')
    const medium = urlParams.get('utm_medium')
    const campaign = urlParams.get('utm_campaign')
    const content = urlParams.get('utm_content')

    const paramToPass = `?utm_source=${placement}&utm_medium=${medium}&utm_campaign=${campaign}&utm_content=${content}`
    this.setState({ paramsToPass: paramToPass })
  }
  componentWillUnmount(): void {
    this.subscribe = null
  }

  openModal = (modal: ModalType) => {
    this.setState({
      modals: [...this.state.modals, { ...modal, closeIcon: <Icons.close height={13} width={13} /> }],
    })
  }

  closeAllModal = () => {
    this.setState({ modals: [] })
  }

  // for going on the next page
  goNext = () => {
    this.setState({ part: this.state.part + 1, activePath: window.location.pathname })
    document.getElementById('scrollConponent')!.scroll(0, 0)
  }

  // for going back on a page
  goBack = () => {
    this.setState({ part: this.state.part - 1 })
  }

  // seleziona categoria
  setCategory = async (e) => {
    if (e === 'other') await this.setState({ selectedCategory: e })
    else await this.setState({ selectedCategory: e.Section })
    this.filterMock()
  }

  filterMock = () => {
    let indexone = 0
    Mock.forEach((element, index) => {
      if (element.Section === this.state.selectedCategory) indexone = index
    })
    this.setState({ intestedMock: Mock[indexone] })
  }

  // set type
  setType = (e) => {
    this.setState({ typeSelected: e })
    this.filterMock()
  }

  // set type
  setSelectedText = (e) => {
    this.setState({ textSelected: e })
    this.filterMock()
  }

  //setta imagine
  setTextImg = async (img) => {
    await this.setState({ imgSelected: img })
  }

  groupName = () => {
    if (this.state.selectedCategory === 'MarketingComunicazione') return 'marketing'
    if (this.state.selectedCategory === 'SportBenessere') return 'sport'
    if (this.state.selectedCategory === 'FormazioneConsulenza') return 'formazione'
    if (this.state.selectedCategory === 'IndustriaServizi') return 'industria'
    if (this.state.selectedCategory === 'Horeka e Turismo') return 'horeca'
    if (this.state.selectedCategory === 'Vendita al dettaglio') return 'vendita'
    return ''
  }

  render() {
    const { modals } = this.state
    let timeoutHandle
    if (this.state.loop === false) {
      window.clearTimeout(timeoutHandle)
      timeoutHandle = setTimeout(() => this.setState({ loop: true }), 1500)
    }

    if (this.state.part === 5 && this.state.condition == true) {
      timeoutHandle = setTimeout(() => this.setState({ condition: false }), 2400)
    }

    //add https://asters.s3.eu-central-1.amazonaws.com/demo/background_demo.mp4 as background
    return (
      <Box flex>
        {this.state.part === 5 && (
          <>
            {this.state.lotties.map((e, index) => (
              <LottieAnimation index={index} x={e[0]} y={e[1]} eliminateIndex={() => {}} />
            ))}
          </>
        )}
        <Box flex center>
          {this.state.condition && this.state.part === 5 && (
            <>
              <Box
                center
                style={{
                  position: 'absolute',
                  minHeight: window.innerHeight,
                  minWidth: window.innerWidth,
                  maxHeight: ' 100vh',
                }}
              >
                <Box>
                  <FirstOne />
                </Box>
              </Box>
            </>
          )}

          <BackgroundVideo autoPlay muted playsInline loop id="background">
            <source src="https://asters.s3.eu-central-1.amazonaws.com/demo/background_demo.mp4" type="video/mp4" />
          </BackgroundVideo>
          <Iscriviti
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              justifyContent: 'space-between',
              padding: 48,
              paddingTop: 32,
              paddingBottom: 0,
            }}
            vcenter
            row
            mb={124}
          >
            <Icons.logoAstersFullSmall
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.setState({ part: 1, selectedCategory: '', lotties: [], condition: true })
              }}
            />

            <Button
              variant="neutral"
              size="small"
              onClick={() => {
                // navigate('dashborddemo')
                ;/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
                  ? window.open('https://lp.asters.ai/sito/registrazione' + this.state.paramsToPass)
                  : window.open('https://beta.asters.ai/' + this.state.paramsToPass)

                // reloadApp()
              }}
            >
              Iscriviti
            </Button>
          </Iscriviti>

          <PagesConteiner
            id={'scrollConponent'}
            style={{ marginTop: this.state.part === 4 ? '10vh' : this.state.part === 5 ? '15vh' : '' }}
          >
            {/* <Box flex  height={100} style={{ paddingBottom: '3vh', backgroundColor: 'red' }}> */}
            {this.state.part === 1 && <FirstPage goNext={this.goNext} />}
            {this.state.part === 2 && (
              <SecondPage
                selectCategory={this.setCategory}
                mock={Mock}
                goNext={this.goNext}
                goBack={this.goBack}
              ></SecondPage>
            )}

            {this.state.selectedCategory === 'other' && <OthersChoisePage selectCategory={this.setCategory} />}
            {this.state.part === 3 && this.state.selectedCategory !== 'other' && (
              <ThirdPage selected={this.state.selectedCategory} goNext={this.goNext} goBack={this.goBack}></ThirdPage>
            )}
            {this.state.part === 4 && (
              <FourthPage
                openModal={this.openModal}
                groupName={this.groupName()}
                mock={this.state.intestedMock}
                setTextImg={this.setTextImg}
                setType={this.setType}
                setSelectedText={this.setSelectedText}
                goNext={this.goNext}
                closeAllModal={this.closeAllModal}
                goBack={this.goBack}
              ></FourthPage>
            )}
            {this.state.part === 5 && (
              <FifthPage
                paramToPass={this.state.paramsToPass}
                textSelected={this.state.textSelected}
                imgSelected={this.state.imgSelected}
              ></FifthPage>
            )}

            {/* </Box> */}
          </PagesConteiner>

          {modals.map((modal) => {
            if ('modal' in modal) return modal.modal
            return (
              <Modal
                centered
                {...modal}
                afterClose={undefined}
                visible={true}
                key={modal.id}
                style={{ backgroundColor: 'transparent' }}
                getContainer={document.getElementById('root')!}
                onCancel={(e) => {
                  modal.visible = false
                  this.setState({ modals: modals.filter((m) => m.id !== modal.id) })
                  modal?.onCancel?.(e)
                }}
              >
                {typeof modal.body === 'function' ? modal.body() : modal.body}
              </Modal>
            )
          })}
        </Box>
      </Box>
    )
  }
}

const Iscriviti = styled(Box)`
  @media only screen and (max-width: 600px) {
    margin-bottom: 15px;
  }
`
const PagesConteiner = styled(Box)`
  overflow-y: scroll;
  overflow-x: hidden;
`

const BackgroundVideo = styled.video`
  position: fixed;
  z-index: -100;
  top: 0;
  min-width: 100vw;
  min-height: 100vh;
`
