/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { openPiningModal } from 'components/Modal/ModalManager'
import { truncateText } from 'pages/ai-discover/commonFunction'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { IPostVariant } from 'types/PostInterface'
import { AppStore, T } from 'utils'
import { MediaContainerPost } from '../../GenericComponents/MediaContainerPost'

/* ------------------- INTERFACES  */
interface Props {
  postPreview?: IPostVariant
  mediaIndex?: number
  setMediaIndex?: (index: number) => void
}

export const ResultsPreviewInstagramStandard = ({ postPreview, mediaIndex, setMediaIndex }: Props) => {
  /* ----------------- ZUSTAND  */
  const { activePost } = useCreatePostStore((s) => ({
    activePost: s.activePost as IPostVariant,
  }))

  /* ----------------- VARIABLES  */
  const post = postPreview ?? activePost

  const urls = post?.data?.urls

  const metadataVisibleIndex = urls && urls.findIndex((url) => url.metaData.visible === true)

  const metadata = urls && metadataVisibleIndex !== -1 && urls[metadataVisibleIndex!]

  // Extra location
  const location = post?.data?.place?.name

  // Extra sponsor
  const sponsor = post?.data?.brand && T.postPage.extraPaidSponsor

  // Minor info array container
  const minorInfoProfile = [location && location, sponsor && sponsor].filter((info) => info)

  const collaborators = post?.data?.collaborators

  const profileName = truncateText(post?.info?.account?.name, 40)

  const profileCollaborators = collaborators
    ? collaborators.length > 1
      ? AppStore.loggedUser.language === 'en'
        ? `${collaborators.length} ${T.singleWord.others.toLowerCase()}`
        : `${T.singleWord.others.toLowerCase()} ${collaborators.length}`
      : `${collaborators[0]?.name}`
    : ''

  const profileTitle =
    collaborators && collaborators.length > 0
      ? `${profileName} ${T.singleWord.and} ${profileCollaborators}`
      : profileName

  return (
    <ResultsPreviewStandardContainer>
      <ProfileContainer>
        <ProfileImg src={post?.info?.account?.picture} />

        <Box gap={2}>
          <ProfileTitle>{profileTitle}</ProfileTitle>

          {minorInfoProfile && (
            <ProfileLocation>
              {minorInfoProfile.map((info, i) => (
                <span>
                  {info} {i !== minorInfoProfile.length - 1 && <span>&#x2022;</span>}
                </span>
              ))}
            </ProfileLocation>
          )}
        </Box>
      </ProfileContainer>

      <MediaContainerPost
        width={'98%'}
        height={390}
        arrowsPositions={20}
        postPreview={postPreview}
        mediaIndex={mediaIndex}
        setMediaIndex={setMediaIndex}
      />

      {!postPreview && !metadata && post?.data?.medias?.length > 0 && (
        <TagLabel onClick={() => openPiningModal(post.variantId)}>
          <Icons.usersSizer fill={AppStore.theme.o.black} />
        </TagLabel>
      )}

      <IconsReactionsContainer>
        <Icons.instagramLike fill={AppStore.theme.o.black} />
        <Icons.instagramComment fill={AppStore.theme.o.black} />
        <Icons.instagramShare fill={AppStore.theme.o.black} />
        <Icons.instagramBookMark fill={AppStore.theme.o.black} style={{ marginLeft: 'auto' }} />
      </IconsReactionsContainer>

      <FooterPreview>
        <Likes>12,542 {T.postPage.likes}</Likes>

        <PreviewText>
          {post?.data?.text?.length > 100 ? (
            <>
              <ProfileNameSecondary>{post?.info?.account?.name}</ProfileNameSecondary>
              {post?.data?.text?.slice(0, 100)} <SeeMoreText>... {T.postPage.more}</SeeMoreText>
            </>
          ) : (
            <>
              <ProfileNameSecondary>{post?.info?.account?.name}</ProfileNameSecondary>
              {post?.data?.text}
            </>
          )}
        </PreviewText>

        <Comments>
          {T.postPage.viewAll} 123 {T.postPage.comments}
        </Comments>

        <FooterProfile>
          <RightSection>
            <ProfileImgSecondary src={post?.info?.account?.picture} />
            {T.postPage.addComent}
          </RightSection>

          {/* //TODO: Le png vanno riviste, si vedono male, anche l'icona e da chiedere una corretta */}
          <LeftSection>
            {/* <IconsImg src={handsIcon} />
            <IconsImg src={heartIcon} /> */}
            {/* <Icons.addInstagram width={40} /> */}
          </LeftSection>
        </FooterProfile>

        <DatePost>6 {T.postPage.daysAgo}</DatePost>
      </FooterPreview>
    </ResultsPreviewStandardContainer>
  )
}

const ResultsPreviewStandardContainer = styled(Box)`
  width: 100%;
  border: 1px solid ${() => AppStore.theme.o.grey};
  position: relative;
`

const ProfileContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 5px 10px;
`

const ProfileImg = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
`

const ProfileTitle = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.3px;
`

const IconsReactionsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 10.5px;
  gap: 13px;
`

const TagLabel = styled(Box)`
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  position: absolute;
  top: 390px;
  left: 20px;
  background: ${() => AppStore.theme.o.lightestGrey};
  border: 1px solid ${() => AppStore.theme.o.black};
  color: ${() => AppStore.theme.o.black};
  font-weight: 700;
  line-height: 12px;
  width: 32px;
  height: 32px;
  border-radius: 50%;

  svg {
    width: 18px;
  }

  &:hover {
    border-color: ${() => AppStore.theme.o.lightGrey};
  }
`

const FooterPreview = styled(Box)`
  display: flex;
  padding: 10.5px;
`

const Likes = styled(Box)`
  margin-bottom: 5px;
  color: ${() => AppStore.theme.o.black};
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 13.002px; /* 130.023% */
`

const ProfileNameSecondary = styled.span`
  color: ${() => AppStore.theme.o.black};
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 13.002px; /* 130.023% */
  margin-right: 8px;
`

const Comments = styled(Box)`
  color: ${() => AppStore.theme.o.darkGrey};
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.002px; /* 130.023% */
`

const FooterProfile = styled(Box)`
  margin: 10.5px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const RightSection = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  color: ${() => AppStore.theme.o.darkGrey};
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 13.002px; /* 130.023% */
`

const LeftSection = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 10.5px;
`

const DatePost = styled(Box)`
  color: ${() => AppStore.theme.o.darkGrey};
  font-family: Roboto;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 10.402px; /* 130.023% */
`

const PreviewText = styled(Box)`
  margin-bottom: 10.8px;
  color: ${() => AppStore.theme.o.black};
  border-radius: 0;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.002px;
  word-wrap: break-word;
`

const SeeMoreText = styled.span`
  margin-left: 4px;
  color: ${() => AppStore.theme.o.darkGrey};
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.002px;
`

const ProfileImgSecondary = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`

const IconsImg = styled.img`
  width: 100%;
  height: 100%;
`

const ProfileLocation = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  color: ${() => AppStore.theme.o.black};
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.26px;
`
