import { T } from 'utils'

export const objectiveTreeText = [
  {
    id: 'short',
    objective: [
      {
        value: 'Generic',
        name: T.textGenerator.short.tweet.generic,
        subSection: [
          T.textGenerator.short.tweet.humSatirical,
          T.textGenerator.short.tweet.interrogative,
          T.textGenerator.short.tweet.news,
        ],
      },
      //---------------------------------------------
      {
        value: 'Prodotto / Evento',
        name: T.textGenerator.short.prodEvent.prodEvent,
        subSection: [T.textGenerator.short.prodEvent.formal, T.textGenerator.short.prodEvent.creative],
      },
      //---------------------------------------------
      {
        value: 'Quotes',
        name: T.textGenerator.short.quotes.quotes,
        subSection: [
          T.textGenerator.short.quotes.inspiration,
          T.textGenerator.short.quotes.funny,
          T.textGenerator.short.quotes.motivational,
          T.textGenerator.short.quotes.educational,
        ],
      },
      //---------------------------------------------
    ],
  },
  {
    id: 'medium',
    objective: [
      {
        value: 'Sconto / Promozione',
        name: T.textGenerator.medium.discPromotion.discPromotion,
        subSection: [
          T.textGenerator.medium.discPromotion.inspiration,
          T.textGenerator.medium.discPromotion.funny,
          T.textGenerator.medium.discPromotion.professional,
        ],
      },
      {
        value: 'Contest / Giveaway',
        name: T.textGenerator.medium.contestGiveaway.contestGiveaway,
        subSection: [
          T.textGenerator.medium.contestGiveaway.engaging,
          T.textGenerator.medium.contestGiveaway.funny,
          T.textGenerator.medium.contestGiveaway.exclusive,
        ],
      },
      {
        value: 'Highlight Prodotto / Servizio',
        name: T.textGenerator.medium.highProduct.highProduct,
        subSection: [
          T.textGenerator.medium.highProduct.inspiration,
          T.textGenerator.medium.highProduct.funny,
          T.textGenerator.medium.highProduct.descriptive,
          T.textGenerator.medium.highProduct.professional,
        ],
      },
    ],
  },
  {
    id: 'longer',
    objective: [
      {
        value: 'Informativo',

        name: T.textGenerator.longer.informative.informative,
        subSection: [T.textGenerator.longer.informative.institutional, T.textGenerator.longer.informative.thoughtful],
      },
      {
        value: 'Riflessione',
        name: T.textGenerator.longer.reflexive.reflexive,
        subSection: [
          T.textGenerator.longer.reflexive.authorative,
          T.textGenerator.longer.reflexive.metaphorical,
          T.textGenerator.longer.reflexive.motivational,
        ],
      },
      {
        value: 'Promozione',
        name: T.textGenerator.longer.promotion.promotion,
        subSection: [
          T.textGenerator.longer.promotion.professional,
          T.textGenerator.longer.promotion.explicative,
          T.textGenerator.longer.promotion.persuasive,
        ],
      },
    ],
  },
]
