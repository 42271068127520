import { User } from 'types'
import { create } from 'zustand'

interface IUserStore {
  user?: User
  setUser: (user: User) => void
}

export const useUserStore = create<IUserStore>((set, get) => ({
  user: undefined,
  setUser: (user) => set({ user }),
}))
