/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons, Input } from 'components'
import { AppStore, T } from 'utils'

/* ------------------- INTERFACES  */
interface Props {
  textInputFilter: string
  setTextInputFilter: (text: string) => void
}

export const SocialHeader = ({ textInputFilter, setTextInputFilter }: Props) => {
  return (
    <SocialHeaderContainer className="social_header_container">
      <Input
        prefix={<Icons.search fill={AppStore.theme.o.darkGrey} />}
        placeholder={T.postPage.searchSocial}
        value={textInputFilter}
        onChange={(e) => setTextInputFilter(e)}
        style={{ minWidth: '100%' }}
      />
    </SocialHeaderContainer>
  )
}

const SocialHeaderContainer = styled(Box)`
  &.social_header_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: '0 auto';
  }
`
