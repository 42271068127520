import {
  FacebookPosts,
  InstagramPosts,
  LinkedInPosts,
  TikTokPosts,
} from 'components/Analytics/Graph/singleGraph/table/tableInterface'
import { ILabel } from 'components/CreatePost/PreviewComponents/LabelsComponents/LabelsContentComponents/Label'
import ReactGridLayout from 'react-grid-layout'
import { IReportConfig } from 'types/AnalyticsInferfaces'
import { AppStore } from 'utils'
import { api, responseErrorCheck } from './api'
import AstersBaseResource from './AstersBaseResource'

// -----INTERFACE-----
export interface IPostFilters {
  labels?: ILabelsFilter
  media_type?: TPostMediaType[]
  content_type?: TPostType[]
  date?: IDateFilter
  order?: IOrderFilter
}

export type TLabelsFilterType = 'and' | 'or'

export type TPostOrderKey =
  | keyof LinkedInPosts
  | keyof InstagramPosts
  | keyof FacebookPosts
  | keyof TikTokPosts
  | 'date'

export type TPostOrderDirection = 1 | -1 // 1 = asc, -1 = desc;

export type TPostType = 'post' | 'story' | 'reels' | 'carousel' | 'post_link' | 'feed' | 'short'

export type TPostMediaType = 'image' | 'video' | 'text'

export interface ILabelsFilter {
  type: TLabelsFilterType
  values: ILabel[]
}

export interface IDateFilter {
  from: Date
  to: Date
}

export interface IOrderFilter {
  key: TPostOrderKey
  direction: TPostOrderDirection
}

export interface IMetricParams {
  account: string
  metricId: string // ref
  since?: string
  until?: string
  filters?: IPostFilters
  page?: string
  limit?: string
}

export default class Reports extends AstersBaseResource {
  static endpoint = '/reports'

  static async createReport(reportConfig: IReportConfig) {
    return api
      .post<any>(`${this.endpoint}/create/${AppStore.getWorkspaceId()}`, {
        reportConfig,
        language: AppStore.getLanguageValue() ?? 'it',
      })
      .then(responseErrorCheck)
  }

  static async updateReport(reportConfig: IReportConfig) {
    return api
      .put<any>(`${this.endpoint}/update/${AppStore.getWorkspaceId()}/${reportConfig.reportId}`, {
        reportConfig,
        language: AppStore.getLanguageValue() ?? 'it',
      })
      .then(responseErrorCheck)
  }

  /**
   * Get metric datas of a report
   * endpoint -> report/:reportId/:workspaceId/:language
   * @param reportId
   * @param metricParams
   * @returns
   */
  static async getMetricData(reportId: string, metricParams: IMetricParams) {
    return api
      .patch<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/${reportId}`, {
        ...metricParams,
      })
      .then(responseErrorCheck)
  }

  static async getReportList() {
    return api
      .get<any>(`${this.endpoint}/reportList/${AppStore.loggedUser._id}/${AppStore.getWorkspaceId()}`)
      .then(responseErrorCheck)
  }

  static async getReport(reportId: string) {
    return api
      .get<any>(
        `${this.endpoint}/report/${reportId}/${AppStore.getWorkspaceId()}/${AppStore.getLanguageValue() ?? 'it'}`
      )
      .then(responseErrorCheck)
  }

  static async changeReportName(reportId: string, reportName: string, isReportCard: boolean) {
    return api
      .put<any>(`${this.endpoint}/changeName/${reportId}/${AppStore.getWorkspaceId()}`, {
        reportName,
        returnCompressed: isReportCard,
        language: AppStore.getLanguageValue() ?? 'it',
      })
      .then(responseErrorCheck)
  }

  static async changeReportLogo(reportId: string, reportName: string) {
    return api
      .put<any>(`${this.endpoint}/changeLogo/${reportId}/${AppStore.getWorkspaceId()}`, {
        reportName,
        language: AppStore.getLanguageValue() ?? 'it',
      })
      .then(responseErrorCheck)
  }

  static async deleteReport(reportId: string | string[]) {
    return api
      .delete<any>(`${this.endpoint}/delete/${AppStore.getWorkspaceId()}`, { reportId })
      .then(responseErrorCheck)
  }

  static async checkReportName(reportName: string): Promise<boolean> {
    return api
      .get<boolean>(`${this.endpoint}/checkReportName/${AppStore.getWorkspaceId()}/${reportName}`)
      .then(responseErrorCheck)
  }

  static async updateGraphPosition(reportLayout: ReactGridLayout.Layout[], reportId: string): Promise<IReportConfig> {
    return api
      .put<IReportConfig>(
        `${this.endpoint}/updatePosition/${AppStore.getWorkspaceId()}/${
          AppStore.getLanguageValue() ?? 'it'
        }/${reportId}`,
        {
          reportData: reportLayout,
        }
      )
      .then(responseErrorCheck)
  }
}
