/* ******************* IMPORT ******************* */
import styled from '@emotion/styled'
import { DatePicker } from 'antd'
import { Box, Icons } from 'components'
import { AppStore, T } from 'utils'
import { useState } from 'react'
import { ChangeLanguageButton } from 'pages/ai-discover/UI/ChangeLanguageButton'
import ClearFiltersButton from 'pages/ai-discover/UI/ClearFiltersButton'
import { useDiscoverStore } from 'stores/DiscoverStore'
import moment from 'moment'
import { FilterDropdown } from 'pages/ai-discover/UI/FilterDropdown'

const DiscoverSearchFilters = () => {
  /* ____________________ ZUSTAND  ____________________ */
  const {
    subPageActive,
    dateFilterSearch,
    dateFilterFollow,
    setDateFilterFollow,
    setDateFilterSearch,
    activeTopicSidebar,
  } = useDiscoverStore((state) => ({
    subPageActive: state.subPageActive,
    dateFilterSearch: state.dateFilterSearch,
    dateFilterFollow: state.dateFilterFollow,
    setDateFilterFollow: state.setDateFilterFollow,
    setDateFilterSearch: state.setDateFilterSearch,
    activeTopicSidebar: state.activeTopicSidebar,
  }))

  /* ____________________ STATE ____________________ */
  const [isDateBoxExpanded, setIsDateBoxExpanded] = useState<boolean>(false)
  const [hasValueSearch, setHasValueSearch] = useState<boolean>(!!dateFilterSearch && dateFilterSearch.length > 0)
  const [hasValueFollow, setHasValueFollow] = useState<boolean>(!!dateFilterFollow && dateFilterFollow.length > 0)

  /* ____________________ VARIABLES ____________________ */
  const disabledDatePast = (date) => moment(date).isBefore(moment().subtract(30, 'days'))
  const disabledDateFuture = (date) => moment(date).isAfter(moment())

  /* ____________________ METHODS ____________________ */
  const setDateFilter = (type: 'follow' | 'search') => {
    if (type === 'follow') {
      setDateFilterFollow([])
      setHasValueFollow(false)
    } else {
      setDateFilterSearch([])
      setHasValueSearch(false)
    }
  }

  return (
    <DiscoverSearchFilterContainer>
      {/* Filtro per lingua */}
      {!activeTopicSidebar && (
        <LanguageBoxsFilter>
          <ChangeLanguageButton />
        </LanguageBoxsFilter>
      )}

      {subPageActive === 'search' && (
        <DropdownContainer>
          <FilterDropdown invokedFrom="header_search" />
        </DropdownContainer>
      )}

      {subPageActive === 'follow' && (
        <DropdownContainer>
          <FilterDropdown invokedFrom="header_follow" />
        </DropdownContainer>
      )}

      {/* Filtro per date */}
      {subPageActive === 'search' && (
        <DateBox
          hasValue={hasValueSearch}
          isExpanded={isDateBoxExpanded}
          onClick={() => {
            if (!isDateBoxExpanded) setIsDateBoxExpanded(true)
          }}
        >
          {isDateBoxExpanded && (
            <Icons.close_icon
              width={14}
              height={14}
              style={{
                marginRight: '5px',
                fill: `${hasValueSearch ? AppStore.theme.o.green : AppStore.theme.o.black}`,
              }}
              onClick={() => {
                if (isDateBoxExpanded) setIsDateBoxExpanded(false)
              }}
            />
          )}

          {!isDateBoxExpanded && <Icons.calendarPost className="date-icon" />}

          {hasValueSearch && !isDateBoxExpanded && (
            <ClearFiltersButton defaultValue={[]} setFilter={() => setDateFilter('search')} />
          )}

          {/* Componente per selezionare le date di inizio e fine */}
          <RangePickerContainer isExpanded={isDateBoxExpanded}>
            {isDateBoxExpanded && (
              <CustomRangePicker
                disabledDate={(date) => disabledDatePast(date) || disabledDateFuture(date)}
                placeholder={[T.aiDiscoverPage.sidebar.StartDate, T.aiDiscoverPage.sidebar.EndDate]}
                format="DD/MM/YYYY"
                value={
                  dateFilterSearch && dateFilterSearch.length > 0 ? [dateFilterSearch[0], dateFilterSearch[1]] : null
                }
                onChange={(date) => {
                  if (date) {
                    setDateFilterSearch([date[0], date[1]])
                    setHasValueSearch(true)
                  }
                }}
              />
            )}
          </RangePickerContainer>
        </DateBox>
      )}

      {subPageActive === 'follow' && (
        <DateBox
          hasValue={hasValueFollow}
          isExpanded={isDateBoxExpanded}
          onClick={() => {
            if (!isDateBoxExpanded) setIsDateBoxExpanded(true)
          }}
        >
          {isDateBoxExpanded && (
            <Icons.close_icon
              width={14}
              height={14}
              style={{
                marginRight: '5px',
                fill: `${hasValueFollow ? AppStore.theme.o.green : AppStore.theme.o.black}`,
              }}
              onClick={() => {
                if (isDateBoxExpanded) setIsDateBoxExpanded(false)
              }}
            />
          )}

          {!isDateBoxExpanded && <Icons.calendarPost className="date-icon" />}

          {hasValueFollow && !isDateBoxExpanded && (
            <ClearFiltersButton defaultValue={[]} setFilter={() => setDateFilter('follow')} />
          )}

          {/* Componente per selezionare le date di inizio e fine */}
          <RangePickerContainer isExpanded={isDateBoxExpanded}>
            {isDateBoxExpanded && (
              <CustomRangePicker
                disabledDate={(date) => disabledDatePast(date) || disabledDateFuture(date)}
                placeholder={[T.aiDiscoverPage.sidebar.StartDate, T.aiDiscoverPage.sidebar.EndDate]}
                format="DD/MM/YYYY"
                value={dateFilterFollow ? [dateFilterFollow[0], dateFilterFollow[1]] : undefined}
                onChange={(date) => {
                  if (date) {
                    setDateFilterFollow([date[0], date[1]])
                    setHasValueFollow(true)
                  }
                }}
              />
            )}
          </RangePickerContainer>
        </DateBox>
      )}
    </DiscoverSearchFilterContainer>
  )
}

export default DiscoverSearchFilters

const DiscoverSearchFilterContainer = styled(Box)`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  position: relative;
  margin-right: 24px;
`

const DateBox = styled.div<{ isExpanded: boolean; hasValue: boolean }>`
  position: relative;
  width: ${({ isExpanded }) => (isExpanded ? '270px' : '44px')};
  height: 40px;
  padding: 10px 12px;
  gap: 8px;
  border: 1px solid ${({ hasValue }) => (hasValue ? AppStore.theme.o.green : AppStore.theme.o.grey)};
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: ${({ isExpanded }) => (isExpanded ? 'flex-start' : 'right')};
  border-radius: 14px;
  background-color: ${({ hasValue }) => (hasValue ? AppStore.theme.o.lightGreen : AppStore.theme.o.lightestGrey)};
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  z-index: 3;

  :hover {
    background-color: ${({ hasValue }) => (hasValue ? AppStore.theme.o.lightGreen : AppStore.theme.o.lightGrey)};
    border-color: ${() => AppStore.theme.o.grey};
  }

  & .date-icon {
    position: absolute;
    top: 50%;
    right: 8.5px;
    transform: translateY(-50%);
    fill: ${({ hasValue }) => (hasValue ? AppStore.theme.o.green : AppStore.theme.o.black)};
  }
`

const RangePickerContainer = styled.div<{ isExpanded: boolean }>`
  transition: opacity 200ms ease-in;
  transition-delay: 300ms;

  position: relative;
  right: 5px;
  opacity: ${({ isExpanded }) => (isExpanded ? 1 : 0)};
  visibility: ${({ isExpanded }) => (isExpanded ? 'visible' : 'hidden')};
`

const CustomRangePicker = styled(DatePicker.RangePicker)`
  display: flex !important;
  gap: 10px;
  padding-right: 0px !important;

  .ant-picker-input {
    &:first-child::after {
      padding-left: 8px;
      content: '-';
    }

    & > input {
      padding: auto !important;
      width: 90px !important;
    }
  }

  .ant-picker-active-bar {
    /* background: ${() => AppStore.theme.o.green};
    width: 30% !important; */
    display: none;
  }

  .ant-picker-suffix {
    /* color: ${() => AppStore.theme.o.lightGrey}; */
    display: none;
  }

  .ant-picker-clear {
    /* background: none;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s, color 0.3s; */
    display: none;
  }

  .ant-picker-range-separator {
    display: none;
  }
`

const LanguageBoxsFilter = styled(Box)`
  position: absolute;
  right: 230px;
  top: 50%;
  transform: translateY(-50%);
`

const DropdownContainer = styled(Box)`
  position: absolute;
  right: 60px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
`
