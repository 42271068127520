/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Button } from 'components'
import { AppStore } from 'utils'

/* ------------------- INTERFACES  */
interface Props {
  text: string
  icon: JSX.Element
  fill?: boolean // se esiste mette colore grigio all'svg
  textButton?: string
  action?: () => void
  noPaddingTop?: boolean
  styles?: React.CSSProperties
}

export const EmptyState = ({ text, icon, textButton, action, fill, noPaddingTop, styles }: Props) => {
  return (
    <EmptyStateContainer style={{ paddingTop: !noPaddingTop ? '48px' : 0 }}>
      <EmptyStateContent fill={fill ?? false} style={{ ...styles }}>
        {text}
        {icon}
      </EmptyStateContent>

      {textButton && (
        <EmptyStateButton onClick={action} variant="primary">
          {textButton}
        </EmptyStateButton>
      )}
    </EmptyStateContainer>
  )
}

const EmptyStateContainer = styled(Box)`
  margin: auto;
  width: 100%;
  border-radius: 24px;
  background: transparent;
  gap: 32px;
  display: flex;
  align-items: center;
`

const EmptyStateContent = styled(Box)<{ fill: boolean }>`
  min-width: 200px;
  display: flex;
  padding: 24px;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 14px;
  background: ${() => AppStore.theme.o.lightGrey};
  color: ${() => AppStore.theme.o.black};

  svg {
    fill: ${({ fill }) => (fill ? AppStore.theme.o.darkGrey : 'none')} !important;
  }
`

const EmptyStateButton = styled(Button)`
  min-height: 34px;
  padding: 8px 24px !important;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
