/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { containsArray } from 'api/Utils'
import { Box, Icons } from 'components'
import { socialFilters, TSocialFilter } from 'components/Calendar/Sidebar/SidebarInterface'
import { FilterBar } from 'components/FilterSideBar/FilterBar'
import { NoResultElement } from 'components/FilterSideBar/NoResultElement'
import { InputSearch } from 'components/InputSearch'
import { SidebarStructure } from 'components/UI'
import { SelectCard } from 'components/UI/SelectCard'
import { useEffect, useState } from 'react'
import { useDraftStore } from 'stores/DraftStore'
import { SocialAccount } from 'types'
import { AppStore, T } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {}

export const DraftSidebar = ({}: Props) => {
  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */
  const { socials, socialFilter } = useDraftStore((s) => ({
    socials: s.socials,
    socialFilter: s.socialFilter,
  }))

  const { setSocials, setSocialFilter } = useDraftStore()

  /* ----------------- STATE  */
  const [oldData, setOldData] = useState<JSX.Element[]>()
  const [filteredSocialsAccounts, setFilteredSocialsAccounts] = useState<SocialAccount[]>(
    AppStore.socialAccounts.filter((account) => account.provider === socialFilter)
  )

  /* ----------------- VARIABLES  */
  const selectAll: JSX.Element = (
    <SelectCard
      selected={socials.length === AppStore.socialAccounts.length}
      action={() => handleSelectAll('all')}
      title={T.postPage.all}
      icon={<Icons.userCircle fill={AppStore.theme.o.black} width={'100%'} />}
    />
  )

  const selectAllSocialElements: JSX.Element = (
    <SelectCard
      selected={containsArray(
        socials,
        filteredSocialsAccounts.map((acc) => acc._id!)
      )}
      action={() => handleSelectAll(socialFilter!)}
      title={T.postPage.all}
      icon={<Icons.userCircle fill={AppStore.theme.o.black} width={'100%'} />}
      styles={{ marginTop: 12 }}
    />
  )

  const socialsElements: JSX.Element[] = filteredSocialsAccounts.map((account) => (
    <SelectCard
      selected={socials.includes(account._id!)}
      action={() => handleSelectSocial(account._id!)}
      title={account.name}
      icon={<img style={{ width: 20, height: 20, borderRadius: '50%' }} src={`${account.picture}`} />}
      styles={{ marginTop: 16 }}
    />
  ))

  const data: JSX.Element[] =
    filteredSocialsAccounts.length > 0
      ? [selectAllSocialElements, ...socialsElements]
      : [<NoResultElement message={T.calendarPage.noProfiles} />]

  /* ----------------- METHODS  */
  /**
   * Function to change the active list on sidebar
   * @param key Selected list
   */
  const onChangeActiveList = (key: TSocialFilter) => {
    setSocialFilter(key)
    if (data !== oldData) setOldData(data)
  }

  /**
   * Function to handle the selection of socials ( add or remove the social)
   * @param id Social id
   */
  const handleSelectSocial = (id: string) => {
    const areAllSelected = socials.length === AppStore.socialAccounts.length

    if (areAllSelected) {
      const newSocials = [id]
      setSocials(newSocials)
    } else {
      const newSocials = socials.includes(id) ? socials.filter((social) => social !== id) : [...socials, id]
      setSocials(newSocials)
    }
  }

  /**
   * Function to select all socials
   * @param type Type of provider
   */
  const handleSelectAll = (type: TSocialFilter) => {
    if (type === 'all') {
      const allSocials =
        socials.length === AppStore.socialAccounts.length ? [] : AppStore.socialAccounts.map((account) => account._id!)
      setSocials(allSocials)
    } else {
      const allSocials =
        socials.length === filteredSocialsAccounts.length ? [] : filteredSocialsAccounts.map((account) => account._id!)
      setSocials(allSocials)
    }
  }

  /**
   * Funzione per gestire la ricerca di un social dall'input
   * @param str la stringa scritta nell'input
   */
  const handleSearch = (str: string) => {
    const filteredBySocial = AppStore.socialAccounts.filter((account) => account.provider === socialFilter)

    const filteredSocials = filteredBySocial.filter((account) => account.name.toLowerCase().includes(str.toLowerCase()))

    setFilteredSocialsAccounts(filteredSocials)
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */
  // UseEffect per settare al primo mounting  che tutti i social siano selezionati
  useEffect(() => {
    setSocials(AppStore.socialAccounts.map((account) => account._id!))
  }, [])

  useEffect(() => {
    setFilteredSocialsAccounts(AppStore.socialAccounts.filter((account) => account.provider === socialFilter))
  }, [socialFilter])

  return (
    <Box>
      <SidebarStructure
        header={<InputSearch title={T.calendarPage.profiles} placeholder={T.postPage.search} search={handleSearch} />}
        content={
          <FilterBar
            upperElement={selectAll}
            filters={socialFilters}
            selectedFilter={socialFilter}
            action={onChangeActiveList}
            oldData={oldData!}
            data={data}
          />
        }
      />
    </Box>
  )
}
