/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { useEffect, useRef, useState } from 'react'
import { IInstagramTag } from 'types/PostInterface'
import { AppStore, showInfo, T } from 'utils'
import { BtnDeleteTag } from './BtnDeleteTag'
import { BtnConfirmTag } from './BtnConfirmTag'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  newTagData: IInstagramTag
  updateTextNewTag: (e) => void
  updateTags: (e) => void
  resetNewTag: (e) => void
  style: any
  hideCursor: () => void
  showCursor: () => void
  tagWidth: number
  fullTagWidth: number
}

export const CreatingNewTag = ({
  newTagData,
  updateTextNewTag,
  updateTags,
  resetNewTag,
  style,
  hideCursor,
  showCursor,
  tagWidth,
  fullTagWidth,
}: Props) => {
  /* ----------------- STATE  */
  //Animation state
  const [animationWidth, setAnimationWidth] = useState<number | string>(tagWidth)

  /* ----------------- REF  */
  const tagInput = useRef<HTMLInputElement>(null)

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */
  /**
   * Funzione per dare focus all'input della scrittura del username tag
   */
  const focusInput = () => {
    if (tagInput.current) tagInput.current.focus()
  }

  /**
   * Funzione per chiudere la creazione di un nuovo tag e resettare i dati
   * @param e evento del click
   */
  const handleCloseCreation = (e) => {
    e.stopPropagation()

    setAnimationWidth(tagWidth)

    setTimeout(() => resetNewTag(e), 350)
  }

  /**
   *  Funzione per creare un nuovo tag
   * @param e  evento del click
   */
  const handleCreation = (e) => {
    e.stopPropagation()
    if (!newTagData.text) {
      focusInput()
      return showInfo(T.postPage.usernameRequired)
    }

    setAnimationWidth(tagWidth)
    setTimeout(() => {
      updateTags(e)
      resetNewTag(e)
    }, 350)
  }

  /**
   * Funzione per gestire il tasto enter
   */
  const handleOnKeyPressEnter = (e) => {
    if (e.key === 'Enter') {
      handleCreation(e)
    }
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */
  //On mounting animation width and focus input
  useEffect(() => {
    setTimeout(() => setAnimationWidth(fullTagWidth), 100)

    focusInput()
  }, [])

  return (
    <CreatingNewTagContainer
      style={{ ...style, width: animationWidth }}
      onMouseEnter={hideCursor}
      onMouseLeave={showCursor}
      onClick={(e) => e.stopPropagation()}
      removeHover
    >
      <ContainerInput>
        <Icons.user fill={AppStore.theme.o.black} width={24} height={24} style={{ marginRight: 8 }} />
        @
        <InputTag
          ref={tagInput}
          placeholder={T.postPage.username}
          onChange={updateTextNewTag}
          onClick={(e) => e.stopPropagation()}
          onKeyPress={handleOnKeyPressEnter}
        />
      </ContainerInput>

      <BtnDeleteTag action={handleCloseCreation} />
      <BtnConfirmTag action={handleCreation} />
    </CreatingNewTagContainer>
  )
}

const CreatingNewTagContainer = styled(Box)`
  position: absolute;
  transition: all 0s;
  transition: width 0.5s;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  overflow: hidden;
  z-index: 99999;
  cursor: default;
`

const ContainerInput = styled(Box)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px 8px 8px;
  border-radius: 16px;
  background-color: ${() => AppStore.theme.o.surface};
  color: ${() => AppStore.theme.o.black};
  border: 1px solid ${() => AppStore.theme.o.grey};
`

const InputTag = styled.input`
  margin-left: 4px;
  border: none;
  background-color: transparent;
  color: ${() => AppStore.theme.o.black};
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
`
