import styled from '@emotion/styled'
import { Box } from 'components'
import React from 'react'
import { AppStore } from 'utils'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { IPhoneNumber } from 'types/NotifyInterface'

interface Props {
  phoneNumber: IPhoneNumber
  handleChange: (value: string, country: any) => void // value = numero di telefono, country = oggetto con info sul paese
  isValid: boolean
  width?: string // default 344px
  canModify?: boolean // se true disabilita l'input
  onModal?: boolean
}

const InputFlagPhoneNumber = ({ phoneNumber, handleChange, isValid, width = '', canModify, onModal }: Props) => {
  /**
   * per gestire le differenze della lingua tra la libreria e la nostra inserire qui le eccezioni
   * @param code string ( en | it | ecc)
   * @returns
   */
  const onControlCountryCode = (code?: string) => {
    if (!code) return 'it'
    if (code === 'en') return 'gb'

    return code
  }

  return (
    <InputContainer
      className={`input-container ${onModal ? 'on-modal' : ''} ${canModify ? 'disabled' : ' '} ${
        isValid ? ' ' : 'error'
      }`}
      style={width ? { minWidth: `${width}` } : {}}
    >
      <PhoneInput
        country={onControlCountryCode(AppStore.loggedUser.language)}
        value={`${phoneNumber.prefix}${phoneNumber.number}`}
        onChange={handleChange}
        inputProps={{ required: true }}
        {...(canModify && { disabled: true })}
      />
    </InputContainer>
  )
}

export default InputFlagPhoneNumber

const InputContainer = styled(Box)`
  &.input-container {
    width: 100%;
    max-width: 344px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    position: relative;
    color: ${() => AppStore.theme.o.black};

    &.error {
      border: 2px solid ${() => AppStore.theme.o.red};

      &.shake {
        animation: 0.1s shake;
      }
    }

    &.disabled {
      input {
        color: #a3a1aa;
        border: none;
      }
    }

    .react-tel-input {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      background-color: ${() => AppStore.theme.o.lightGrey};
      border-radius: 10px;
      padding: 0 12px;

      .form-control {
        background: transparent;
        min-height: 48px;
        max-height: 48px;

        flex: 1;
        padding: 8px 8px 8px 34px;
        border: none;
      }
      .flag-dropdown {
        background-color: transparent !important;
        background: transparent !important;
        border: none;
        width: 32px;

        .country-list {
          color: ${() => AppStore.theme.o.black};
          background-color: ${() => AppStore.theme.o.lightGrey};

          .country {
            :hover {
              background-color: ${() => AppStore.theme.o.grey} !important;
            }
          }

          .highlight {
            background-color: ${() => AppStore.theme.o.grey} !important;
          }

          &::-webkit-scrollbar-thumb {
            background-color: ${() => AppStore.theme.o.grey}!important;
          }
        }

        .selected-flag {
          width: 32px;
          background-color: ${() => AppStore.theme.o.lightGrey};

          &:hover {
            background-color: transparent !important;
          }
        }

        .arrow {
          display: none;
        }
      }
    }
  }

  @keyframes shake {
    0% {
      rotate: 0deg;
    }
    40% {
      rotate: 3deg;
    }
    80% {
      rotate: -3deg;
    }
    100% {
      rotate: 0deg;
    }
  }
`
