import styled from '@emotion/styled'
import Auth from 'api/Auth'
import Workspaces from 'api/Workspaces'
import { useEffect, useState } from 'react'
import { AppStore, T, showError, showInfo } from 'utils'
import { LoginPage } from 'pages'
import { AnimatedPlaceholderLogo } from './AnimatedPlaceholderLogo'

export const AcceptColabAccount = ({ onLoginCallback }: { onLoginCallback?: (user: any) => void }) => {
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    checkIfUserIsLogged()
  }, [])

  const checkIfUserIsLogged = async () => {
    return new Promise<boolean>(async (resolve) => {
      const url = new URL(window.location.href)
      const tempToken = url.searchParams.get('tempToken') ?? ''
      const workspace = url.searchParams.get('workspaceId') ?? ''
      if (localStorage.getItem('token')) {
        await Workspaces.acceptColaboration(tempToken, workspace)
        setTimeout(() => {
          localStorage.setItem('workspaceId', workspace)
          window.location.href = '/dashboard'
          resolve(true)
        }, 500)
      } else {
        try {
          const isUserRegistered = await checkIfUserIsRegistered(tempToken, workspace)
          if (!isUserRegistered) {
            window.location.pathname = '/createColabAccount'
          } else {
            setLoader(false)
          }
        } catch (error) {
          showError(T.error.genericError)
        }
      }
      resolve(false)
    })
  }

  const checkIfUserIsRegistered = async (tempToken: string, workspace: string): Promise<boolean> => {
    return Auth.isUserRegistered(tempToken, workspace)
  }

  const acceptColaboration = async () => {
    const url = new URL(window.location.href)
    const tempToken = url.searchParams.get('tempToken') ?? ''
    const workspace = url.searchParams.get('workspaceId') ?? ''
    try {
      await Workspaces.acceptColaboration(tempToken, workspace)
    } catch (error) {
      showError(T.error.genericError)
    }
  }

  return loader ? <AnimatedPlaceholderLogo /> : <LoginPage onLoginCallback={acceptColaboration} />
}

export default AcceptColabAccount

const TextSecondary = styled.p`
  font-weight: 700;
  font-size: 16px;
  color: ${() => AppStore.theme.o.darkGrey};
`
const TextMain = styled.p`
  font-weight: 700;
  font-size: 16px;
  color: ${() => AppStore.theme.o.darkGrey};
`

const ButtonText = styled.p`
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
`

const LoginPageContainer = styled.div<{ background }>`
  display: flex;
  align-items: center;
  justify-content: left;
  background: url(${(props) => props.background}) no-repeat center center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: background-image 0.5s ease-in-out;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
`

const CardContainer = styled.div`
  display: flex;
  padding: 24px 24px;
  background-color: ${() => AppStore.theme.o.surface};
  border-radius: 16px;
  box-shadow: 0px 20px 20px 0px rgb(87 87 87 / 20%);
  width: 440px;
`

const WorkspaceText = styled.p`
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: ${() => AppStore.theme.o.black};
`
