/* ******************* IMPORT ******************* */
import 'swiper/swiper-bundle.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import styled from '@emotion/styled'
import { Box, Button, Icons } from 'components'
import { AppStore, T } from 'utils'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Autoplay } from 'swiper'
import PreviewCard from './PreviewCard'
import { getFeedTrendArticles } from '../commonFunction'
import PreviewBoard from './PreviewBoard'
import { useDiscoverStore } from 'stores/DiscoverStore'

/* ******************* INTERFACE ******************* */

interface Props {
  carouselType: string
}

const DiscoverCarousel = ({ carouselType }: Props) => {
  /* ____________________ ZUSTAND  ____________________ */
  const { onChangeSubPage, onForceUpdate } = useDiscoverStore((state) => ({
    onChangeSubPage: state.onChangeSubPage,
    onForceUpdate: state.onForceUpdate,
  }))

  /* ____________________ VARIABLES ____________________ */
  const trendArticlesList = AppStore.discoverTrendArticleList
  const boardList = AppStore.discoverUserBoardList
  /* ____________________ METHODS ____________________ */

  const focusInput = () => {
    document.getElementById('inputSearchBar')!.focus()
  }

  /**
   * Setta la board attiva e fa il cambio pagina verso la pagina delle board
   * @param board
   */
  const onRedirectToBoardList = () => {
    onChangeSubPage('board')
  }

  /**
   * ## generateCarouselSlidesArticles
   *
   * Genera massimo 3 slide contententi ognuna massimo 3 articoli
   *
   * @param contents  la prop "content" che in questo caso contiene tutti gli articoli in trend
   */
  const generateCarouselSlidesArticles = (contents) => {
    // Max 3 slides
    const limitedContent = contents.slice(-9).reverse()

    const slides: any = []

    for (let i = 0; i < limitedContent.length; i += 3) {
      const slideContent = limitedContent.slice(i, i + 3)

      const slide = (
        <CarouselSlide key={`slide_article_${i}`}>
          {slideContent.map((article, index) => (
            <PreviewCard key={`card_article_${i + index}`} article={article} index={index} />
          ))}
        </CarouselSlide>
      )

      slides.push(slide)
    }

    return slides
  }

  /**
   * ## generateCarouselSlidesBoards
   *
   * Genera slide contententi ognuna massimo 3 articoli (le prime slide contengono le ultime board aggiunte)
   *
   * @param contents  la prop "content" che in questo caso contiene tutti le board
   */
  const generateCarouselSlidesBoards = (contents) => {
    // Max 7 slides
    const limitedContent = contents.slice(-21).reverse()

    const slides: any = []

    const groupSize = 3

    for (let i = 0; i < limitedContent.length; i += groupSize) {
      const slideContent = limitedContent.slice(i, i + groupSize)

      const slide = (
        <CarouselSlide key={`slide_board_${i}`}>
          {slideContent.map((board, index) => (
            <PreviewBoard
              key={`card_board_${index}`}
              board={board}
              index={index}
              isOnCarousel={true}
              onClick={onRedirectToBoardList}
            />
          ))}
        </CarouselSlide>
      )

      slides.push(slide)
    }

    return slides
  }

  return (
    <ContainerCarousel>
      {/* ARTICLES */}
      {/* ARTICLES CAROUSEL HEADER */}
      {trendArticlesList && trendArticlesList.length >= 1 && carouselType === 'Topics' && (
        <ContainerHeaderCarousel>
          <button className={`ButtonPrev_${carouselType}`}>
            <Icons.arrowLeft />
          </button>

          <ContentHeaderCarousel>
            <TitleCarousel>{T.AiDiscoverPage.carousel.trendingArticles}</TitleCarousel>

            <div className={`NavigationIndicator_${carouselType}`} />
          </ContentHeaderCarousel>

          <button className={`ButtonNext_${carouselType}`}>
            <Icons.arrowRight />
          </button>
        </ContainerHeaderCarousel>
      )}

      {/* ARTICLES CAROUSEL  */}
      {trendArticlesList && trendArticlesList.length >= 1 && carouselType === 'Topics' && (
        <Carousel
          slidesPerView={1}
          spaceBetween={30}
          grabCursor={true}
          loop={true}
          pagination={{
            el: `.NavigationIndicator_${carouselType}`,
            clickable: true,
          }}
          navigation={{
            prevEl: `.ButtonPrev_${carouselType}`,
            nextEl: `.ButtonNext_${carouselType}`,
          }}
          modules={[Pagination, Navigation, Autoplay]}
          className={`mySwiper_${carouselType}`}
        >
          {generateCarouselSlidesArticles(trendArticlesList)}
        </Carousel>
      )}

      {/* ARTICLES CAROUSEL BUTTONS */}
      {trendArticlesList && trendArticlesList.length >= 1 && carouselType === 'Topics' && (
        <ContainerBottomCarousel>
          {/* Button for refresh trending articles */}
          <RefreshButtonContainer
            onClick={() => {
              AppStore.discoverTrendArticleList = undefined
              onForceUpdate()
              getFeedTrendArticles(onForceUpdate)
            }}
          >
            <Icons.refresh />
            {T.AiDiscoverPage.carousel.refreshArticles}
          </RefreshButtonContainer>

          {/* Button for show more */}
          {/* <ShowMoreButtonContainer onClick={() => onChangeSubPage('follow')}>
            <Icons.starDiscover />
            {T.AiDiscoverPage.carousel.showAllArticles}
          </ShowMoreButtonContainer> */}
        </ContainerBottomCarousel>
      )}

      {/* BOARDS */}
      {/* BOARDS CAROUSEL HEADER */}
      {boardList.length >= 1 && carouselType === 'Boards' && (
        <ContainerHeaderCarousel>
          {boardList.length > 3 && (
            <button className={`ButtonPrev_${carouselType}`}>
              <Icons.arrowLeft />
            </button>
          )}

          <ContentHeaderCarousel>
            <TitleCarousel>{T.AiDiscoverPage.carousel.yourBoards}</TitleCarousel>

            <div className={`NavigationIndicator_${carouselType}`} />
          </ContentHeaderCarousel>

          {boardList.length > 3 && (
            <button className={`ButtonNext_${carouselType}`}>
              <Icons.arrowRight />
            </button>
          )}
        </ContainerHeaderCarousel>
      )}

      {/* BOARDS CAROUSEL  */}
      {boardList.length >= 1 && carouselType === 'Boards' && (
        <Carousel
          slidesPerView={1}
          spaceBetween={30}
          grabCursor={true}
          loop={true}
          pagination={{
            el: `.NavigationIndicator_${carouselType}`,
            clickable: true,
          }}
          navigation={{
            prevEl: `.ButtonPrev_${carouselType}`,
            nextEl: `.ButtonNext_${carouselType}`,
          }}
          modules={[Pagination, Navigation, Autoplay]}
          className={`mySwiper_${carouselType}`}
        >
          {generateCarouselSlidesBoards(boardList)}
        </Carousel>
      )}

      {/* BOARDS CAROUSEL BUTTONS */}
      {boardList.length >= 1 && carouselType === 'Boards' && (
        <ContainerBottomCarousel>
          {/* Button for show more */}
          <ShowMoreButtonContainer onClick={() => onChangeSubPage('board')}>
            <Icons.boardsDiscover />
            {T.AiDiscoverPage.carousel.showAllBoards}
          </ShowMoreButtonContainer>
        </ContainerBottomCarousel>
      )}

      {/* Empty state */}
      {/* Topics HEADER */}
      {trendArticlesList?.length === 0 && carouselType === 'Topics' && (
        <ContainerHeaderCarousel>
          <ContentHeaderCarousel>
            <TitleCarousel>{T.AiDiscoverPage.carousel.trendingArticles}</TitleCarousel>
          </ContentHeaderCarousel>
        </ContainerHeaderCarousel>
      )}

      {/* Boards HEADER */}
      {boardList.length === 0 && carouselType === 'Boards' && (
        <ContainerHeaderCarousel>
          <ContentHeaderCarousel>
            <TitleCarousel>{T.AiDiscoverPage.carousel.yourBoards}</TitleCarousel>
          </ContentHeaderCarousel>
        </ContainerHeaderCarousel>
      )}

      {/* Topics BODY*/}
      {trendArticlesList?.length === 0 && carouselType === 'Topics' && (
        <EmptyCarousel>
          <NoContentContainer>
            {T.AiDiscoverPage.carousel.noTrendsAvailable}

            <Icons.sadStar fill={AppStore.theme.o.darkGrey} />
          </NoContentContainer>

          <FollowFirstTopic onClick={focusInput}>{T.AiDiscoverPage.carousel.followYourFirstTopic}</FollowFirstTopic>
        </EmptyCarousel>
      )}

      {/* Boards BODY*/}
      {boardList.length === 0 && carouselType === 'Boards' && (
        <EmptyCarousel>
          <NoContentContainer>
            {T.AiDiscoverPage.carousel.noBoardsAvailable}

            <Icons.sadBoard fill={AppStore.theme.o.darkGrey} />
          </NoContentContainer>

          <FollowFirstTopic onClick={() => onChangeSubPage('board')}>
            {T.AiDiscoverPage.carousel.createYourFirstBoard}
          </FollowFirstTopic>
        </EmptyCarousel>
      )}
    </ContainerCarousel>
  )
}

export default DiscoverCarousel

// Gli stili delle classi utilizzate nelle frecce e buttoni di navigazione li trovate in index.css e theme-light.css / theme-dark.css, questo perchè sono stili sovrascritti dalla libreria swiper

const EmptyCarousel = styled(Box)`
  margin-top: 16px;
  display: flex;
  width: 1164px;
  height: 400px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  border-radius: 24px;
  background: ${() => AppStore.theme.o.lightestGrey};
`

const NoContentContainer = styled(Box)`
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  border-radius: 14px;
  background: ${() => AppStore.theme.o.lightGrey};

  color: ${() => AppStore.theme.o.black};
  text-align: center;
  font-family: Aktiv Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

const FollowFirstTopic = styled(Button)`
  padding: 8px 24px !important;
  min-height: 24px !important;
  color: ${() => AppStore.theme.o.blue};
  text-align: center;
  font-family: Aktiv Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

const Carousel = styled(Swiper)`
  margin-top: 16px;
  border-radius: 24px;
  background: ${() => AppStore.theme.o.lightestGrey};
  height: 471px;
  width: 1180px;

  &.mySwiper_Topics {
    height: 471px;
  }

  &.mySwiper_Boards {
    height: 360px;
  }
`

const CarouselSlide = styled(SwiperSlide)`
  gap: 32px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const ContainerCarousel = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ContainerHeaderCarousel = styled(Box)`
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 32px;
  height: 40px;
`

const ContentHeaderCarousel = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const TitleCarousel = styled(Box)`
  color: ${() => AppStore.theme.o.black};

  font-family: Aktiv Grotesk;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.21px;
`

const ContainerBottomCarousel = styled(Box)`
  margin-top: 8px;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
`

const RefreshButtonContainer = styled(Box)`
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  color: ${() => AppStore.theme.o.darkGrey};
  text-align: center;

  font-family: Aktiv Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  border: 1px solid transparent;
  transition: 300ms ease-in-out;

  svg {
    fill: ${() => AppStore.theme.o.darkGrey};
  }

  &:hover {
    border: 1px solid ${() => AppStore.theme.o.grey};
    background: ${() => AppStore.theme.o.lightestGrey};
  }
`

const ShowMoreButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  color: ${() => AppStore.theme.o.darkGrey};
  text-align: center;

  font-family: Aktiv Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  border: 1px solid transparent;
  transition: 300ms ease-in-out;

  svg {
    fill: ${() => AppStore.theme.o.darkGrey};
  }

  &:hover {
    border: 1px solid ${() => AppStore.theme.o.grey};
    background: ${() => AppStore.theme.o.lightestGrey};
  }
`
