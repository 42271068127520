/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { AppStore } from 'utils'

/* ------------------- TYPES  */
export type TDirections = 'top' | 'right' | 'bottom' | 'left'

/* ------------------- INTERFACES  */
interface Props {
  aiTagComplete: boolean // booleano per mostrare o meno il tag versione completa "Astera AI" o  "AI"
  aiTagDirection: TDirections // direzione del tag

  // Per evitare di mettere le direzioni ma insierire direttamente lo spostamento
  top?: string | number
  bottom?: string | number
  right?: string | number
  left?: string | number
}

export const AiTagCard = ({ aiTagComplete, aiTagDirection, top, bottom, right, left }: Props) => {
  /* ------------------- METHODS  */
  const getAbsoltePosition = (direction: TDirections) => {
    switch (direction) {
      case 'top':
        return {
          top: '0',
          left: '50%',
        }
      case 'right':
        return {
          top: '50%',
          right: '-15px',
        }
      case 'bottom':
        return {
          bottom: '0',
          left: '50%',
        }
      case 'left':
        return {
          top: '50%',
          left: '-20px',
        }
    }
  }

  const getTranslation = (direction: TDirections) => {
    switch (direction) {
      case 'top':
        return 'translate(-50%, -50%)'
      case 'right':
        return 'translate(0%, -50%)'
      case 'bottom':
        return 'translate(-50%, 50%)'
      case 'left':
        return 'translate(0%, -50%)'
    }
  }

  /* ------------------- VARIABLES  */
  // Informazioni sul posizionamento del tag (top, right, bottom, left)
  const absolutePosition = getAbsoltePosition(aiTagDirection)

  // Informazioni sulla transizione che deve eseguire per posizionarsi correttamente
  const translation = getTranslation(aiTagDirection)

  return (
    <AiTagCardContainer
      style={{
        top: top ? top : absolutePosition.top,
        right: right ? right : absolutePosition.right,
        bottom: bottom ? bottom : absolutePosition.bottom,
        left: left ? left : absolutePosition.left,

        transform: translation,
      }}
      className="ai_tag_card"
    >
      {aiTagComplete ? 'Asters AI' : 'AI'}
    </AiTagCardContainer>
  )
}

const AiTagCardContainer = styled(Box)`
  &.ai_tag_card {
    display: flex;
    padding: 2px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: ${() => AppStore.theme.o.primary_container};
    color: ${() => AppStore.theme.o.primary};
    font-family: 'Aktiv Grotesk';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    position: absolute;
    z-index: 100;
  }
`
