/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { TextContainer } from '../../GenericComponents/TextContainer'
import { ResultPreviewGlobal } from './ResultPreviewGlobal'
import { PreviewResult } from '../../GenericComponents/PreviewResults'

/* ------------------- INTERFACES  */
interface Props {}

export const Global = ({}: Props) => {
  return (
    <GlobalContainer>
      <PreviewResult component={<ResultPreviewGlobal />} />
      <TextContainer />
    </GlobalContainer>
  )
}

const GlobalContainer = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
`
