/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Text } from 'components'
import { ILabel } from 'components/CreatePost/PreviewComponents/LabelsComponents/LabelsContentComponents/Label'
import { SpinnerAstersLogo } from 'components/UI'
import { useEffect, useState } from 'react'
import { ModifyLabelCard } from './ModifyLabelCard'
import { AppStore, T } from 'utils'
import Labels from 'api/Labels'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  inputValue: string
  workspaceLabels: ILabel[]
  selectedLabelIds: string[]
  handleSelectLabel: (id: string) => void
}

export const ContentLabelModal = ({ inputValue, workspaceLabels, selectedLabelIds, handleSelectLabel }: Props) => {
  /* ----------------- STATE  */

  const [filteredLabels, setFilteredLabels] = useState<ILabel[]>([])

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  // Filtra le label in base all'input dell'utente
  useEffect(() => {
    const filteredValue = workspaceLabels.filter((label) =>
      label.label.toLowerCase().includes(inputValue.toLowerCase())
    )

    setFilteredLabels(filteredValue)
  }, [workspaceLabels, inputValue])

  return (
    <ContentLabelModalContainer>
      {/* Results displayed */}
      {filteredLabels.length > 0 &&
        filteredLabels.map((label, i) => (
          <ModifyLabelCard
            key={label._id + i}
            id={label._id}
            label={label.label}
            color={label.color}
            isSelected={selectedLabelIds.find((id) => id === label._id) ? true : false}
            handleSelectLabel={handleSelectLabel}
          />
        ))}

      {/* Empty State */}
      {filteredLabels.length <= 0 && (
        <EmptyState>
          <Text>{T.postPage.labelsSection.noLabelIsPresent}</Text>
        </EmptyState>
      )}
    </ContentLabelModalContainer>
  )
}

const ContentLabelModalContainer = styled(Box)`
  width: 100%;
  height: 515px;
  overflow-y: scroll;
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 14px;
  border: 1.5px dashed ${() => AppStore.theme.o.grey};

  &::-webkit-scrollbar {
    display: none;
  }
`

const EmptyState = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${() => AppStore.theme.o.background};
  padding: 32px;
`
