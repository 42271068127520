import EmojiPicker from 'emoji-picker-react'
import { Component } from 'react'
import { Box } from 'components/Box'
import { T } from 'translation/i18n'
import { AppStore, showError } from 'utils'
import { IPostData, IPostVariant } from 'types/PostInterface'
import { getActiveDescription, getActiveText, updateActive } from 'utils/PostUtils/Post'

interface Props {
  post: IPostVariant | IPostData
  identifier?: string
}

interface State {
  value: string
}

export default class Emoji extends Component<Props, State> {
  state: State = {
    value: '',
  }

  // componentDidMount(): void {
  //   AppStore.removeBlurEffect()
  // }

  componentWillUnmount(): void {
    try {
      const curPos = document.getElementById(this.props.identifier ?? 'text-area-create-post')
      const isDescription = this.props.identifier?.includes('-description')
      const currentText = !isDescription ? getActiveText() : getActiveDescription()
      //@ts-ignore
      const sliceStart = currentText.substring(0, curPos.selectionStart)

      const vari = currentText!.replace(sliceStart, sliceStart + this.state.value)
      const updateValue = isDescription ? { description: vari } : { text: vari }
      updateActive(updateValue)
    } catch (er) {
      showError(er)
      console.error(er)
    }
  }

  render() {
    const EmojiCategory = [
      ['suggested', T.emoji.suggested],
      ['smileys_people', T.emoji.smileys_people],
      ['animals_nature', T.emoji.animals_nature],
      ['food_drink', T.emoji.food_drink],
      ['travel_places', T.emoji.travel_places],
      ['activities', T.emoji.activities],
      ['objects', T.emoji.objects],
      ['symbols', T.emoji.symbols],
      ['flags', T.emoji.flags],
    ]
    return (
      <Box row>
        <Box p={24} height={450} width={500} center>
          <EmojiPicker
            width={500}
            previewConfig={{
              defaultCaption: 'CIAO', // defaults to: "What's your mood?"
              showPreview: false, // defaults to: true
            }}
            categories={[
              {
                // @ts-ignore
                category: EmojiCategory[0][0],
                name: EmojiCategory[0][1],
              },

              {
                // @ts-ignore
                category: EmojiCategory[1][0],
                name: EmojiCategory[1][1],
              },

              {
                // @ts-ignore
                category: EmojiCategory[2][0],
                name: EmojiCategory[2][1],
              },

              {
                // @ts-ignore
                category: EmojiCategory[3][0],
                name: EmojiCategory[3][1],
              },

              {
                // @ts-ignore
                category: EmojiCategory[4][0],
                name: EmojiCategory[4][1],
              },
              {
                // @ts-ignore
                category: EmojiCategory[5][0],
                name: EmojiCategory[5][1],
              },
              {
                // @ts-ignore
                category: EmojiCategory[6][0],
                name: EmojiCategory[6][1],
              },
              {
                // @ts-ignore
                category: EmojiCategory[7][0],
                name: EmojiCategory[7][1],
              },
              {
                // @ts-ignore
                category: EmojiCategory[8][0],
                name: EmojiCategory[8][1],
              },
            ]}
            height={450}
            onEmojiClick={async (e) => {
              this.setState({ value: this.state.value + e.emoji })
              AppStore.closeAllModal()
            }}
          />
        </Box>
      </Box>
    )
  }
}
