/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { ILabel, Label } from './Label'
import { useEffect, useState } from 'react'
import { SpinnerAstersLogo } from 'components/UI'
import { EmptyStateLabel } from './EmptyStateLabel'
import Labels from 'api/Labels'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  handleAddLabel: (newValue: ILabel) => void
  inputValueFilter: string // valore dell'input con la quale filtriamo nel workspaceLabels
  workspaceLabels: ILabel[]
  selectedLabels: ILabel[]
  refreshLabels: () => void
  addLabel: (specificLabel?: ILabel) => void
}

export const LabelContentContainer = ({
  handleAddLabel,
  inputValueFilter,
  workspaceLabels,
  selectedLabels,
  refreshLabels,
  addLabel,
}: Props) => {
  /* ----------------- STATE  */

  const [filteredLabels, setFilteredLabels] = useState<ILabel[]>([])

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  // Filtra le label in base all'input dell'utente
  useEffect(() => {
    const filteredValue = workspaceLabels.filter((label) =>
      label.label.toLowerCase().includes(inputValueFilter.toLowerCase())
    )

    setFilteredLabels(filteredValue)
  }, [workspaceLabels, inputValueFilter])

  return (
    <LabelContentContainerContainer>
      {/* Results displayed */}
      {filteredLabels.length > 0 &&
        filteredLabels.map((label, i) => (
          <Label
            key={label.label + i}
            id={label._id}
            label={label.label}
            color={label.color}
            action={handleAddLabel}
            disabled={selectedLabels?.find((lb) => lb._id === label._id) ? true : false}
          />
        ))}

      {/* Empty State */}
      {
        <EmptyStateLabel
          workspaceLabels={workspaceLabels}
          inputValueFilter={inputValueFilter}
          filteredLabels={filteredLabels}
          refreshLabels={refreshLabels}
          addLabel={addLabel}
        />
      }
    </LabelContentContainerContainer>
  )
}

const LabelContentContainerContainer = styled(Box)`
  width: 100%;
  gap: 16px;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 24px;
  flex: 1;
  overflow-y: scroll;
  border-radius: none;
`
