/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { AppStore } from 'utils'

/* ------------------- INTERFACES  */
interface Props {
  placeholder: string
  search: (value: string) => void
  title?: string
  totalEntries?: number
}

export const InputSearch = ({ placeholder, search, title, totalEntries }: Props) => {
  return (
    <InputSearchContainer>
      {title !== '' && (
        <TitleContainer row>
          <Title>{title}</Title>
          <Number>{totalEntries}</Number>
        </TitleContainer>
      )}
      <InputContainer>
        <Icons.search
          width={16}
          height={16}
          fill={AppStore.theme.o.darkGrey}
          onClick={() => {
            document.getElementById('search-report-input')?.focus()
          }}
        />
        <Input
          id="search-report-input"
          maxLength={20}
          type="search"
          placeholder={placeholder}
          onChange={(e) => search(e.target.value)}
        />
      </InputContainer>
    </InputSearchContainer>
  )
}

const InputSearchContainer = styled(Box)`
  gap: 24px;
  padding-right: 19px;
`

const Input = styled.input`
  background-color: transparent;
  border: none;
  height: 20px;
  width: 160px;
`

const InputContainer = styled(Box)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 8px;
  border-radius: 14px;
  width: 200px;
  height: 40px;
  border: 1px solid ${() => AppStore.theme.o.lightGrey};
  background: ${() => AppStore.theme.o.lightestGrey};

  &:hover,
  &:focus {
    background: ${() => AppStore.theme.o.lightGrey};
  }
`

const TitleContainer = styled(Box)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Title = styled(Box)`
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.21px;
  color: ${() => AppStore.theme.o.black};
`

const Number = styled(Box)`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: ${() => AppStore.theme.o.darkGrey};
`
