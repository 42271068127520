/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { openFeedbackModal } from 'components/Modal/ModalManager'
import { useState } from 'react'
import { AppStore } from 'utils'

/* ------------------- INTERFACES  */
interface Props {}

export const GenerationFeedbacks = ({}: Props) => {
  /* ------------------- STATE  */
  const [isLiked, setIsLiked] = useState<boolean>(false)
  const [isNotLiked, setIsNotLiked] = useState<boolean>(false)

  return (
    <GenerationFeedbacksContainer>
      <FeedbackIcon
        onClick={() => {
          setIsLiked((prev) => !prev)
          if (!isLiked) openFeedbackModal(true)
        }}
        style={{
          backgroundColor: isLiked ? AppStore.theme.o.lightGreen : AppStore.theme.o.lightestGrey,
        }}
      >
        <Icons.thumbUp fill={isLiked ? AppStore.theme.o.green : AppStore.theme.o.black} />
      </FeedbackIcon>

      <FeedbackIcon
        onClick={() => {
          setIsNotLiked((prev) => !prev)
          if (!isNotLiked) openFeedbackModal(false)
        }}
        style={{
          backgroundColor: isNotLiked ? AppStore.theme.o.lightRed : AppStore.theme.o.lightestGrey,
        }}
      >
        <Icons.thumbDown fill={isNotLiked ? AppStore.theme.o.red : AppStore.theme.o.black} />
      </FeedbackIcon>
    </GenerationFeedbacksContainer>
  )
}

const GenerationFeedbacksContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
`

const FeedbackIcon = styled(Box)`
  cursor: pointer;
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
  width: 24px;
  height: 24px;

  &:hover {
    background-color: ${() => AppStore.theme.o.lightBlue}!important;
    svg {
      transition: all 0.3s ease-in-out;
      fill: ${() => AppStore.theme.o.blue};
    }
  }
`
