import AppStore from './AppStore'

export const isProd = window.location.hostname === 'beta.asters.ai' || window.location.hostname === 'app.asters.ai'

export const isDevelopment = window.location.hostname === 'localhost'

export const isQty =
  window.location.hostname === 'qty.asters.ai' || window.location.hostname === 'romantic-booth-e26b41.netlify.app'

export const isAlpha = window.location.hostname === 'alpha.asters.ai'

/* ---------------------
 ARRAY DI ELEMENTI CHE NON VOGLIAMO FAR VEDERE 
 ES: "header.autopilot.btn" --> se siamo in prod e non vogliamo farlo vedere aggiungere nell'array questa stringa
--------------------- */

const prodNotVisibleElement = [
  'header.autopilot.btn', // bottone autopilot
  'settings.workspace.apiBtn', // bottone api
  'settings.workspace.manageNotificationBtn', // bottone notifiche
  'menu.versionText', // versione in menu
  'referralComponent.firstLoad.shareLink', // link referral
  'analytics.reporHeader.downloadreportBtn', // bottone download report
  'analytics.reporHeader.dateCompareDropdown', // dropdown date compare
  'calendar.socialProfile.showQueueModalBtn', // bottone show queue modal
  'createPost.aiText.generationFeedbacks', // generazione feedbacks
  'referralCodeModal.shareLink', // link referral
  'header.notifyIcon', // icona notifiche
  'settings.account.titleWhatsApp', // titolo whatsapp
  'settings.account.requestNumberWhatsApp', // richiesta numero whatsapp
  'settings.myPlan.referralComponent.navigateOpt', // naviga referral
  'settings.myPlan.changeAddOn', // cambia add on
  'createPost.instagramExtra.tags', // tag instagram
  'superUtils', // super utils
]
const qtyNotVisibleElement = [
  // 'header.autopilot.btn',
  // 'settings.workspace.apiBtn',
  'menu.versionText',
  'index.mobiledetection',
  'analytics.reporHeader.downloadreportBtn',
  'analytics.reporHeader.dateCompareDropdown',
  'calendar.socialProfile.showQueueModalBtn',
  'createPost.aiText.generationFeedbacks',
  'superUtils',
]

const alphaNotVisibleElement = [
  // 'header.autopilot.btn',
  // 'settings.workspace.apiBtn',
  'menu.versionText',
  'index.mobiledetection',
  'analytics.reporHeader.downloadreportBtn',
  'analytics.reporHeader.dateCompareDropdown',
  'calendar.socialProfile.showQueueModalBtn',
  'createPost.aiText.generationFeedbacks',
  'superUtils',
]

const developNotVisibleElement = ['index.mobiledetection']

/* ---------------------
  METODO CHE RITORNA UN ARRAY IN BASE ALL'AMBIENTE IN CUI SIAMO 
--------------------- */
export const whatAmbientNotVisibleArray = () => {
  if (isProd) return prodNotVisibleElement
  if (isQty) return qtyNotVisibleElement
  if (isAlpha) return alphaNotVisibleElement
  if (isDevelopment) return developNotVisibleElement
}

////// ----------------------------------

export enum RemoteConfigValues {
  Analyticsdisabled = 'general.analytics.all',
  NotificationDisabled = 'general.notifications.all',
  NotificationManageButtonDisabled = 'settings.workspace.manageNotificationBtn',
  APIManageButtonDisabled = 'settings.workspace.apiBtn',
  AutopilotButtonDisabled = 'header.autopilot.btn',
  EditorMediasTemplatesDisabled = 'editor.medias.templates',
  HeaderIframePartnersDisabled = 'header.iframePartner',
}

/* ---------------------
  METODO ---ISVISIBLE()--- controlla se una stringa è nell'array degli elementi da non visualizzare in un determinato ambiente.

  se non viene passata una stringa è sempre visibile un elemento... messa questa opzione per il futuro in cui i controlli potrebbero essere fatti attraverso altri mezzi come "firebase ecc"
--------------------- */
/**
 *
 * @param str stringa ( OPZIONALE )
 * @returns
 */
export const isVisible = (str?: string): boolean => {
  if (!str) return true
  let arrayToCheck = whatAmbientNotVisibleArray()
  try {
    // Se l'array è stato caricato da remoto allora viene sovrascritto da l'array di default
    if (Array.isArray(AppStore.notAllowedSections)) arrayToCheck = AppStore.notAllowedSections ?? []
  } catch (e) {
    console.error(e)
  }
  if (str) return !arrayToCheck?.some((s) => s === str)
  return true
}
