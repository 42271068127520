/* ------------------- IMPORTS */
import { Tracker } from 'api/Tracker'
import { Page } from 'components'
import { CalendarContent } from 'components/Calendar/CalendarContent'
import { CalendarSidebar } from 'components/Calendar/CalendarSidebar'
import { PageStructure } from 'components/UI'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { TCalendarSection, useCalendarStore } from 'stores/CalendarStore'
import { T } from 'utils'
import { TRACKEREVENTS } from 'utils/eventsTracker'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {}

export const CalendarPage = ({}: Props) => {
  /* ----------------- ROUTER  */
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const view = searchParams.get('view') as TCalendarSection
  const date = searchParams.get('date') as string

  const dateYear = Number(date?.split('-')[0])
  const dateMonth = Number(date?.split('-')[1]) - 1
  const dateDay = date?.split('-')[2] ? Number(date?.split('-')[2]) : undefined

  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */
  const { setCalendarSection, setDay, setMonth, setYear, setHasMounted } = useCalendarStore()

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /**
   * Funzione per determinare se i parametri passati nell'url esistono
   * @returns  {boolean} - true se i parametri esistono, false altrimenti
   */
  const checkParams = () => {
    if (!view || !date) return false

    return true
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  // MixPanel tracking
  useEffect(() => {
    Tracker.trackEvent(TRACKEREVENTS.CALENDAR_PAGE_VIEWED, [])

    setHasMounted(true)
  }, [])

  // Mantiene le variabili updated con i valori passati nell'url
  useEffect(() => {
    // Se non esistono i params nell'url, si settano del giorno corrente
    if (!checkParams()) {
      const now = new Date()
      const year = now.getFullYear()
      const month = now.getMonth()
      navigate(`/calendar?view=month&date=${year}-${month + 1}`)
    }

    // Se esistono i params aggiorna i vari state
    if (checkParams()) {
      setCalendarSection(view)
      setYear(dateYear)
      setMonth(dateMonth)
      dateDay && setDay(dateDay)
    }
  }, [view, date])

  return (
    <Page title={T.calendarPage.calendar} checkPermission="calendar">
      <PageStructure sidebar={<CalendarSidebar />} content={<CalendarContent />} gap={32} margin={'18px 0px 0px 0px'} />
    </Page>
  )
}
