/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { DropdownFilter } from 'components/UI/DropdownFilter'
import { useAnalyticsStore } from 'stores/AnalyticsStore'
import { IReport, TActiveReportFilter, TActiveReportList } from 'types/AnalyticsInferfaces'
import { AppStore, T, capitalizeFirstLetter } from 'utils'
import { filterDataAnalytics, filteredListAnalytics } from '../config/FilterConfig'
import { IFilterDropdownData } from 'types'

interface Props {
  activeReportList: TActiveReportList | null
  filteredReportList: IReport[] | null
  activeReportFilter: TActiveReportFilter | null
}

export const HeaderDashboardAnalytics = ({ activeReportList, filteredReportList, activeReportFilter }: Props) => {
  /* ------------------- ZUSTAND  */
  const { setFilteredReportList, setActiveReportFilter } = useAnalyticsStore.getState()

  /* ------------------- METHOD  */
  const getHeaderTitle = () => {
    if (activeReportList === 'all' || activeReportList === null) return T.analytics.myReports
    return `${T.analytics.reportWith} ${capitalizeFirstLetter(activeReportList)}`
  }

  const handleDropdownFilter = (filterFunction: IFilterDropdownData) => {
    const { type, variableToSort } = filterFunction
    const list = filteredReportList

    const sortedList = filteredListAnalytics(list!, type, variableToSort)

    setFilteredReportList(sortedList)
    setActiveReportFilter(type)
  }

  return (
    <HeaderDashboardAnalyticsContainer className="header_dashboard_analytics">
      <HeaderText className="header_text">{getHeaderTitle()}</HeaderText>

      <DropdownFilter
        forSection="analytics"
        activeFilter={activeReportFilter!}
        onFilterClick={handleDropdownFilter}
        filterData={filterDataAnalytics}
      />
    </HeaderDashboardAnalyticsContainer>
  )
}

const HeaderDashboardAnalyticsContainer = styled(Box)`
  &.header_dashboard_analytics {
    width: 100%;
    position: relative;
    z-index: 2;

    .dropdown_filter {
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
    }
  }
`

const HeaderText = styled(Box)`
  &.header_text {
    margin: 0 auto;
    color: ${() => AppStore.theme.o.black};
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.21px;
  }
`
