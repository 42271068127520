/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Icons } from './Icons'
import { AppStore } from 'utils'
import { CSSProperties } from 'react'

/* ------------------- INTERFACES  */
interface Props {
  logoHeight?: number
  logoWidth?: number
  style?: CSSProperties
}

export const LogoAstersFullDynamicTheme = ({ logoHeight, logoWidth, style }: Props) => {
  return (
    <LogoAstersFullDynamicThemeContainer style={style}>
      <Icons.logoAstersFullSmall height={logoHeight ?? 70} width={logoWidth ?? 200} />
    </LogoAstersFullDynamicThemeContainer>
  )
}

const LogoAstersFullDynamicThemeContainer = styled.div`
  // GIVING COLOR TO LETTERS
  svg {
    g {
      path {
        // JUST FOR LETTERS
        &:nth-child(-n + 6) {
          fill: ${AppStore.theme.o.black};
        }
      }
    }
  }
`
