/* ******************* IMPORT ******************* */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { AppStore } from 'utils'
import { TSidebarType } from '../aiDiscoverInterface'
import { previewImages, truncateText } from '../commonFunction'
import { useDiscoverStore } from 'stores/DiscoverStore'

/* ******************* INTERFACE ******************* */

interface Props {
  sidebarType: TSidebarType
  activeContentSidebar: any
  setActiveContentSidebar: (activeContentSidebar: any) => void
}

const SelectedContent = ({ sidebarType, activeContentSidebar, setActiveContentSidebar }: Props) => {
  /* ____________________ ZUSTAND  ____________________ */
  const { setSelectedTopic, setInputSearchbar } = useDiscoverStore((state) => ({
    setSelectedTopic: state.setSelectedTopic,
    setInputSearchbar: state.setInputSearchbar,
  }))

  return (
    <ContainerSelected>
      <ContentSelected>
        <Icons.closeIcon
          onClick={() => {
            if (sidebarType === 'suggested') {
              // Stesso comportamento di tornare indetro dato dalla funzione onBack in discoverSearchBar
              setSelectedTopic(null)
              setInputSearchbar('')
            }
            setActiveContentSidebar(null)
          }}
        />

        {sidebarType === 'follow' && (
          <SelectedTopicContent className="selected__text">
            <SelectedText>
              <Icons.starDiscover /> {truncateText(activeContentSidebar.title, 8)}
              <div className="language__box__container">{activeContentSidebar.language.toUpperCase()}</div>
            </SelectedText>
          </SelectedTopicContent>
        )}

        {sidebarType === 'suggested' && (
          <SelectedTopicContent className="selected__text">
            <SelectedText>
              <Icons.starDiscover /> {truncateText(activeContentSidebar.title, 8)}
              <div className="language__box__container">{activeContentSidebar.language.toUpperCase()}</div>
            </SelectedText>
          </SelectedTopicContent>
        )}

        {sidebarType === 'board' && (
          <SelectedBoardContent className="selected__text">
            <SelectedText>
              {activeContentSidebar.private === true ? <Icons.padlock /> : <Icons.collaboration />}
              {truncateText(activeContentSidebar.boardTitle, 7)}
            </SelectedText>
            <PreviewImagesContainer>
              {previewImages(
                activeContentSidebar.articles.slice(Math.max(activeContentSidebar.articles.length - 3, 0))
              )}
            </PreviewImagesContainer>
          </SelectedBoardContent>
        )}
      </ContentSelected>
    </ContainerSelected>
  )
}

export default SelectedContent

const ContainerSelected = styled(Box)`
  display: flex;
  padding-right: 16px;

  &::after {
    content: '';
    border: 1px solid ${() => AppStore.theme.o.lightGrey};
    margin: 24px 0 12px;
    width: 100%;
  }
`

const ContentSelected = styled(Box)`
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  gap: 4px;

  .selected__text {
    transition: all 250ms ease-in-out !important;

    .language__box__container {
      transition: border-color 250ms ease-in-out;
      margin-left: auto;

      display: flex;
      justify-content: center;
      align-items: center;

      width: 30px;
      height: 20px;
      border-radius: 4px;

      font-size: 8px;
      letter-spacing: 1px;
      border: 1px solid ${() => AppStore.theme.o.green};
    }
  }

  /* Quando hovero sulla l'icona X applica questi stili al container  */
  svg {
    cursor: pointer;

    :hover + .selected__text {
      color: ${() => AppStore.theme.o.red};
      border: 1px solid ${() => AppStore.theme.o.red};
      background: ${() => AppStore.theme.o.lightRed};

      svg {
        fill: ${() => AppStore.theme.o.red};
      }

      .language__box__container {
        border-color: ${() => AppStore.theme.o.red};
      }
    }
  }
`

const SelectedTopicContent = styled(Box)`
  display: flex;
  flex-direction: row;
  padding: 8px;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 14px;
  border: 1px solid ${() => AppStore.theme.o.green};
  background: ${() => AppStore.theme.o.lightGreen};

  color: ${() => AppStore.theme.o.green};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  transition: 250ms ease-in-out;
`

const SelectedBoardContent = styled(Box)`
  font-size: 12px;
  position: relative;

  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 14px;
  border: 1px solid ${() => AppStore.theme.o.green};
  background: ${() => AppStore.theme.o.lightGreen};

  color: ${() => AppStore.theme.o.green};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  transition: 250ms ease-in-out;
`

const SelectedText = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 4px;
  width: 100%;
  text-align: center;

  transition: all 0ms ease-in-out;

  svg {
    fill: ${() => AppStore.theme.o.green};
    transition: 250ms ease-in-out;
  }
`

const PreviewImagesContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);

  .gray_preview_img {
    width: 24px;
    height: 24px;
    border: 2px solid ${() => AppStore.theme.o.surface};
    border-radius: 50%;
    background-color: ${() => AppStore.theme.o.grey};
  }

  div {
    img {
      width: 24px;
      height: 24px;
      border: 2px solid ${() => AppStore.theme.o.surface};
      border-radius: 50%;
    }
  }

  & div:nth-of-type(1) {
    position: relative;
    right: -24px;
    z-index: 5;
  }
  & div:nth-of-type(2) {
    position: relative;
    right: -12px;
  }
`
