import { getUserPositionData } from 'utils/eventsTracker'
import { IGeolocationData, IPositionCoordinates } from 'utils/eventsTracker/utils/utilsInterfaces'

export function geolocationApiCall(): Promise<IGeolocationData> {
  return new Promise((success, fail) => {
    getUserPositionData()
      .then(async (locationData: IPositionCoordinates) => {
        const lat: number = locationData.latitude
        const lon: number = locationData.longitude
        const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`

        try {
          const response = await fetch(url)
          const result = (response as any).json()
          success(result)
        } catch (error) {
          fail(error)
        }
      })
      .catch((error) => {
        fail(error)
      })
  })
}
