/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { IDateFilter, ILabelsFilter, IOrderFilter, IPostFilters, TPostMediaType, TPostType } from 'api/Reports'
import { Box } from 'components'
import { FormatFilterTableGraph } from './FormatFilterTableGraph'
import { LabelsFilterTableGraph } from './LabelsFilterTableGraph'
import { TypologyFilterTableGraph } from './TypologyFilterTableGraph'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  apiCallParams?: IPostFilters
  updateValues: (values: ILabelsFilter | TPostMediaType[] | TPostType[] | IDateFilter | IOrderFilter) => void
  provider: string
}

export const TableHeader = ({ updateValues, provider, apiCallParams }: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <TableHeaderContainer>
      <LabelsFilterTableGraph apiCallParams={apiCallParams} updateValues={updateValues} />

      <TypologyFilterTableGraph apiCallParams={apiCallParams} updateValues={updateValues} provider={provider} />

      <FormatFilterTableGraph apiCallParams={apiCallParams} updateValues={updateValues} provider={provider} />
    </TableHeaderContainer>
  )
}

const TableHeaderContainer = styled(Box)`
  flex-direction: row;
  gap: 16px;
  margin-left: auto;
`
