/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { AppStore } from 'utils'

/* ------------------- INTERFACES  */
interface Props {
  content: React.ReactNode
  header?: React.ReactNode
  footer?: React.ReactNode
  width?: number | string
  centered?: boolean
  absoluteFromContent?: React.ReactNode
  absoluteContainerWidth?: string | number
  height?: string | number
  gap?: string | number
  styles?: React.CSSProperties
}

export const ContentStructure = ({
  header = false,
  content,
  footer = false,
  width,
  centered = false,
  absoluteFromContent,
  absoluteContainerWidth,
  height,
  gap,
  styles,
}: Props) => {
  return (
    <ContentStructureContainer
      className="structure_page_content"
      style={{
        margin: 'auto',
        maxWidth: width ? `${width}px` : '1165px',
        height: height ?? '100%',
        maxHeight: '100%',
        marginTop: '0',
        marginBottom: 'auto',
        gap: gap,
        ...styles,
      }}
    >
      {header && header}

      {absoluteFromContent ? (
        <div
          style={{
            position: 'relative',
            transition: 'all 0.3s ease-in-out',
            width: absoluteContainerWidth,
            margin: '0 auto',
          }}
        >
          <ContainerAbsolute>{absoluteFromContent}</ContainerAbsolute>

          {content}
        </div>
      ) : (
        content
      )}

      {footer && footer}
    </ContentStructureContainer>
  )
}

const ContentStructureContainer = styled(Box)`
  &.structure_page_content {
    margin: 0 auto;
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    border-radius: 0px;
    color: ${() => AppStore.theme.o.black};
  }
`

const ContainerAbsolute = styled(Box)`
  position: absolute;
  top: 0px;
  left: -192px;
`
