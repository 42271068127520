/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { formatMaxNumber, SocialType } from 'api/Utils'
import { Box, SocialIcon, Text } from 'components'
import { AppStore } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  provider: SocialType
  number: number
}

export const SocialCellCard = ({ provider, number }: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <SocialCellCardContainer>
      <SocialIcon social={provider} style={{ width: 24, height: 24 }} />

      <Text fontSize={14} color={AppStore.theme.o.darkGrey} style={{ position: 'relative', top: '1px' }}>
        {formatMaxNumber(number, 99)}
      </Text>
    </SocialCellCardContainer>
  )
}

const SocialCellCardContainer = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 33%;
  gap: 1px;
`
