/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { PreviewResult } from '../../GenericComponents/PreviewResults'
import { TextContainer } from '../../GenericComponents/TextContainer'
import { ResultsPreviewX } from './ResultsPreviewX'

/* ------------------- INTERFACES  */
interface Props {}

export const X = ({}: Props) => {
  return (
    <XContainer>
      <PreviewResult component={<ResultsPreviewX />} />
      <TextContainer />
    </XContainer>
  )
}

const XContainer = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
`
