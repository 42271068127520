import { SearchOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Tracker } from 'api/Tracker'
import Workspaces from 'api/Workspaces'
import { Box, Button, Input, SocialAccountRow, Text } from 'components'
import { useEffect, useState } from 'react'
import { T } from 'translation/i18n'
import { SocialAccount, Workspace } from 'types'
import { AppStore, showError } from 'utils'
import { RemoteConfigValues, isVisible } from 'utils/CheckVisibleElement'
import { TRACKEREVENTS } from 'utils/eventsTracker'
import { Icons } from './Icons'
import TimezoneModal from './Modal/TimezoneModal'
import { useNavigate } from 'react-router-dom'
import { ImageWithFallback } from './UI/ImageWithFallback'
import MediaUtils from 'utils/MediaUtils'

interface Props {
  handleForceUpdate?: () => void
  searchText: string
  openWorkSpaceModal: (workspace?: Workspace) => void
  workspaces: Workspace[]
  socialAccounts: SocialAccount[]
  searchChange: (text: string) => void
  changeSection: (section: string, workspaceToManage?: Workspace) => void
  onWorkspaceChange: (workspace: Workspace) => void
}

interface ICheckEditPermission {
  [key: string]: boolean
}

export default function WorkspaceComponent({
  searchText,
  openWorkSpaceModal,
  workspaces,
  socialAccounts,
  searchChange,
  changeSection,
  onWorkspaceChange,
  handleForceUpdate,
}: Props) {
  const navigate = useNavigate()

  const [editPermission, setEditPermission] = useState<ICheckEditPermission>({})

  const onEditClick = (workspace?: Workspace) => {
    openWorkSpaceModal(workspace)
  }

  const onSearchChange = (txt: string) => {
    searchChange(txt)
  }

  const onChangeSection = (section: string, workspaceToManage: Workspace) => {
    changeSection(section, workspaceToManage)
  }

  //Aggiunta della timezone e del controllo dell'ora legale nell'appstore locale
  const updateTimezone = (timezone: Workspace['timezone'], workspaceId: string) => {
    if (workspaceId === AppStore.workspace._id) {
      AppStore.workspace.timezone = timezone
    } else {
      const index = AppStore.loggedUser.workspaces.findIndex((workspace) => workspace._id === workspaceId)
      AppStore.loggedUser.workspaces[index].timezone = timezone
    }
  }

  function formatTimezone(timezone: Workspace['timezone']) {
    if (timezone === undefined) return
    const { name, value } = timezone
    const [, city] = name.split('/')

    const gmtOffset = value.slice(3, 6)

    return (
      <Box center row gap={0}>
        <Box style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', borderRadius: 0 }}>
          {city}
        </Box>
        <Box>: GMT{gmtOffset}</Box>
      </Box>
    )
  }

  const openTimezoneModal = (workspace: Workspace | undefined) => {
    if (workspace?._id === undefined) {
      showError(T.error.errorAccount)
      return
    }
    AppStore.openModal({
      id: 'timezone-modal',
      body: (
        <Box relative>
          <Box absolute>
            <TimezoneModal updateTimezone={updateTimezone} workspace={workspace} />
          </Box>
        </Box>
      ),
      style: {
        minWidth: 500,
        maxWidth: 500,
        minHeight: 538,
        maxHeight: 538,
      },
    })
  }

  const checkEditPermission = async (workspace: Workspace[]): Promise<ICheckEditPermission> => {
    let permissions: ICheckEditPermission = {}
    await Promise.all(
      workspace.map(async (w) => {
        try {
          const editPermissionCheck = await Workspaces.checkPermission('edit_workspaces', w._id)
          permissions = { ...permissions, [w._id?.toString() ?? '']: !!editPermissionCheck }
        } catch (error) {
          permissions = { ...permissions, [w._id?.toString() ?? '']: false }
        }
      })
    )
    return permissions
  }

  useEffect(() => {
    checkEditPermission(workspaces).then((permissions) => {
      setEditPermission(permissions)
    })
    const numWorkspaces: number = AppStore.loggedUser.workspaces.length
    Tracker.trackEvent(TRACKEREVENTS.WORKSPACE_PAGE_VIEWED, { num_workspaces: numWorkspaces })
  }, [workspaces])

  //// Change section
  /**
   * Open the api keys dialog
   */
  const onApiClicked = () => {
    // navigate('/settings/workspace/api-keys')

    changeSection('api-keys')
  }

  //// Render

  return (
    <Box scroll animation="fade" flex pv={64} ph={80} removeHover style={{ position: 'relative' }}>
      <Text fontSize={21} style={{ color: AppStore.theme.o.green }}>
        <b>{T.settings.environment}</b>
      </Text>
      <Box mb={0} mt={20} row style={{ justifyContent: 'space-between' }}>
        <Input
          style={{ width: 400 }}
          placeholder={T.settings.searchEnv}
          prefix={<SearchOutlined />}
          onChange={(txt) => onSearchChange(txt)}
        />

        {AppStore.workspace?.user?.toString() === AppStore.loggedUser._id.toString() && (
          <SettingsButtonsContainer>
            {/******* -------- API BUTTON -------- *******/}
            {isVisible(RemoteConfigValues.APIManageButtonDisabled) && (
              <ButtonContainerNeutral variant="neutral" onClick={onApiClicked}>
                {T.settings.apiButton}
              </ButtonContainerNeutral>
            )}

            {/******* -------- ADD ENVIRONMENT BUTTON -------- *******/}
            <ButtonContainer variant="primary" onClick={() => onEditClick()} width={240}>
              {T.settings.addEnv}
            </ButtonContainer>
          </SettingsButtonsContainer>
        )}
      </Box>

      <Box mt={30} style={{ border: `1px dotted ${AppStore.theme.o.darkGrey}` }} removeHover shadow="none">
        {workspaces
          .filter((w) => w.name.toLowerCase().includes(searchText.toLowerCase()))
          .map((workspace, index) => {
            return (
              <Box key={index} mt={24} p={30}>
                <Box vcenter row flex style={{ justifyContent: 'space-between' }}>
                  <Box vcenter row style={{ position: 'relative' }}>
                    {workspace.image && (
                      <ImageWithFallback
                        style={{ marginRight: 20, borderRadius: 50 }}
                        height={50}
                        width={50}
                        fallbackSrc={MediaUtils.workspacePlaceholder}
                        src={
                          workspace.image?.presignedUrl ??
                          `https://asters.s3.eu-central-1.amazonaws.com/${workspace.image}`
                        }
                      />
                    )}
                    <Box>
                      <Box row gap={16}>
                        <Text fontSize={21}>
                          <b>{workspace.name}</b>
                        </Text>

                        {/* {AppStore.loggedUser.workspaces.map((ws) => ws._id).includes(workspace._id) && (
                          <EditIcon
                            id={`workspace-row-${index}`}
                            onClick={() => onEditClick(workspace)}
                            width={30}
                            height={30}
                          />
                        )} */}
                      </Box>

                      {/* questo da modifica e centrare */}
                      {workspace.default && (
                        <div>
                          <Text color={AppStore.theme.o.darkGrey} weight={700}>
                            {AppStore.loggedUser.workspaces.map((ws) => ws._id).includes(workspace._id)
                              ? T.settings.defoutWorkspace
                              : T.settings.defoutWorkspaceNotOwner}
                          </Text>
                        </div>
                      )}
                    </Box>
                  </Box>

                  {editPermission[workspace?._id?.toString() ?? ''] && (
                    <Box row>
                      <Button
                        id={'btn_timezone_workspace'}
                        onClick={() => openTimezoneModal(workspace)}
                        variant="neutral"
                        size="smaller"
                        width={180}
                        height={40}
                        mr={12}
                        style={{ boxSizing: 'border-box', paddingInline: 16 }}
                      >
                        <TimezoneText>
                          {workspace.timezone !== undefined
                            ? formatTimezone(workspace.timezone)
                            : T.settings.setTimeZone}
                        </TimezoneText>
                      </Button>
                      <Button
                        width={128}
                        height={42}
                        variant="neutral"
                        style={{ fontWeight: 700, fontSize: 14, lineHeight: '18px', minHeight: '40px', gap: '10px' }}
                        onClick={async () => {
                          const workspaceCurrent = (await Workspaces.checkPermission(
                            undefined,
                            workspace._id
                          )) as Workspace

                          onChangeSection('management', { ...workspaceCurrent, image: workspace.image })
                        }}
                      >
                        <TeamMembersIcon />
                        {T.teamMembers.manageWorkspace}
                      </Button>
                    </Box>
                  )}
                </Box>

                <Box mt={20} height={1} style={{ backgroundColor: AppStore.theme.o.grey }} />

                <GridContainer mt={20}>
                  {socialAccounts
                    .filter((acc) => acc.workspace === workspace._id)
                    .map((account, i) => {
                      return (
                        <SocialAccountRow
                          skey={`${account._id}-${i}`}
                          account={account}
                          changeWorkspace
                          handleForceUpdate={handleForceUpdate}
                        />
                      )
                    })}
                </GridContainer>
              </Box>
            )
          })}
      </Box>
    </Box>
  )
}
const WorkspaceSettingButton = styled(Button)`
  /* margin-right: 16px; */
  min-height: 42px !important;
  padding: 12px 24px !important;

  position: absolute;
  right: 280px;
`
const EditIcon = styled(Icons.edit)`
  cursor: pointer;
  fill: ${() => AppStore.theme.o.black};
  :hover {
    fill: ${() => AppStore.theme.o.blueOnLight};
  }
`

const SettingsButtonsContainer = styled(Box)`
  flex-direction: row;
`

const EditContainer = styled(Box)`
  padding: 0px 10px;
`

const GridContainer = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
`

const EditButtonText = styled.p`
  margin-left: 10px;
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${() => AppStore.theme.o.black};
`

const TeamMembersIcon = styled(Icons.teamMembers)`
  fill: ${() => AppStore.theme.o.black};
  width: 24px;
  height: 24px;
`

const TimezoneText = styled.p`
  font-family: Aktiv Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${() => AppStore.theme.o.darkGrey};
  border-radius: 0px;
`

const ButtonText = styled.p`
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
`

const ButtonContainerNeutral = styled(Button)`
  gap: 25px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  min-height: 42px;
  margin-bottom: 12px;
  height: 42px;
  margin-left: 10px;
  width: 84px;
`

const ButtonContainer = styled(Button)`
  gap: 25px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  min-height: 42px;
  margin-bottom: 12px;
  height: 42px;
  margin-left: 10px;
  width: 184px;
`
