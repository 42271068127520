import { BackwardIcon, Box, Icons, Text } from 'components'
import { Component } from 'react'
import { AppStore } from 'utils'

import { T } from 'translation/i18n'

interface Props {
  path: string
  changePath: () => void
  returnToClickPath: (index: number) => void
  //   repleacePath: (path: string) => void
}

export default class MediaPaths extends Component<Props> {
  render() {
    const { path, changePath, returnToClickPath } = this.props
    return (
      <>
        {path !== `${AppStore.workspace._id}/` && (
          <Box vcenter row>
            <BackwardIcon onClick={() => changePath()} />
            <Box row vcenter p={10}>
              {path
                .replace(AppStore.workspace._id + '/' ?? '/', '')
                .split('/')
                .map((item, index, arr) => {
                  if (!item) return null
                  return (
                    <>
                      <Text
                        key={index}
                        fontSize={21}
                        onClick={() => {
                          returnToClickPath(index)
                        }}
                        weight={
                          path.replace(AppStore.workspace._id + '/' ?? '/', '').split('/').length - 2 === index
                            ? 700
                            : 400
                        }
                        style={{
                          color:
                            path.replace(AppStore.workspace._id + '/' ?? '/', '').split('/').length - 2 === index
                              ? AppStore.theme.o.black
                              : AppStore.theme.o.darkGrey,
                        }}
                      >
                        {item}
                      </Text>
                      {index + 2 < arr.length && (
                        <Icons.forward
                          style={{ marginLeft: 10, marginRight: 10 }}
                          width={10}
                          fill={AppStore.theme.o.black}
                        />
                      )}
                    </>
                  )
                })}
            </Box>
          </Box>
        )}
        {path === `${AppStore.workspace._id}/` && (
          <Text style={{ padding: 10 }} fontSize={21} weight={700}>
            {T.mediaPage.folders}
          </Text>
        )}
      </>
    )
  }
}
