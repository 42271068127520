import styled from '@emotion/styled'
import { Box } from 'components/Box'
import { AppStore } from 'utils'

const UpgradeModalContainer = styled(Box)`
  margin: 0 40px;
  width: 449px;
  height: 242px;
`

const UpgradeModalTextBlue = styled.p`
  color: ${AppStore.theme.o.primary};
  font-weight: 500;
  font-size: 16px;
`

const UpgradeModalNormalText = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${AppStore.theme.o.textDarkGrey};
`

const UpgradeModalLighterText = styled.p`
  font-size: 12px;
  font-weight: 200;
  color: ${AppStore.theme.o.textDarkGrey};
`

const UpgradeModalOptionButton = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
  color: ${AppStore.theme.o.textDarkGrey};

  &:before {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 8px;
    background-color: ${(props) => (props.selected ? AppStore.theme.o.primary : AppStore.theme.o.grey)};
  }
`

const AmountRowElement = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px;
  margin: 0px;
  justify-content: space-between;
  color: ${AppStore.theme.o.textDarkGrey};
`

export {
  UpgradeModalLighterText,
  AmountRowElement,
  UpgradeModalContainer,
  UpgradeModalTextBlue,
  UpgradeModalNormalText,
  UpgradeModalOptionButton,
}
