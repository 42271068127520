/* ___________________ IMPORTS ___________________ */

import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { enumPrivateOrShared } from 'pages/ai-discover/commonFunction'
import { useDiscoverStore } from 'stores/DiscoverStore'
import { T, AppStore } from 'utils'

/* ___________________ INTERFACE ___________________ */
interface Props {
  onOpenNewBoard: any
}

const BoardPageHeader = ({ onOpenNewBoard }: Props) => {
  /* ____________________ ZUSTAND  ____________________ */
  const { activeBoard, activeDropdown, setActiveBoard, setActiveDropdown } = useDiscoverStore((state) => ({
    activeBoard: state.activeBoard,
    activeDropdown: state.activeDropdown,
    setActiveBoard: state.setActiveBoard,
    setActiveDropdown: state.setActiveDropdown,
  }))

  return (
    <BoardHeader className="board-page-header">
      {/*  SE SIAMO NELLE NELLA PAGINA DELLE BOARD E NON CI SONO BOARD ATTIVE SI METTE LE DOPPIE ICONE */}
      {!activeBoard && (
        <IconContainer removeHover>
          {activeDropdown === 'private' && <Icons.privateBoard className="private" width={32} height={32} />}
          {activeDropdown === 'shared' && <Icons.publicBoard className="shared" width={32} height={32} />}
          {activeDropdown === 'all' && <Icons.saveIcon className="all" width={32} height={32} />}
        </IconContainer>
      )}

      <TextTitle>
        {activeBoard && (
          <IconContainer
            className="arrow-left-container"
            onClick={() => {
              setActiveBoard(undefined)
              setActiveDropdown('all')
            }}
          >
            <Icons.backward className="arrow-left" />
          </IconContainer>
        )}
        <TitleActiveBoard>
          {activeBoard?.boardTitle ??
            `${T.AiDiscoverPage.boardCard.yourBoard} ${enumPrivateOrShared[activeDropdown].toLowerCase()}`}
        </TitleActiveBoard>
      </TextTitle>

      {!activeBoard && (
        <IconContainer removeHover>
          {activeDropdown === 'private' && <Icons.privateBoard className="private" width={32} height={32} />}
          {activeDropdown === 'shared' && <Icons.publicBoard className="shared" width={32} height={32} />}
          {activeDropdown === 'all' && <Icons.saveIcon className="all" width={32} height={32} />}
        </IconContainer>
      )}

      {activeBoard && (
        <IconContainer removeHover>
          {(activeBoard?.private === true || activeDropdown === 'private') && (
            <Icons.privateBoard className="private" width={32} height={32} />
          )}
          {(activeBoard?.private === false || activeDropdown === 'shared') && (
            <Icons.publicBoard className="shared" width={32} height={32} />
          )}
        </IconContainer>
      )}

      {/* Button to add a new board */}
      {!activeBoard && (
        <NewBoardButton onClick={onOpenNewBoard}>
          <Icons.addBoard fill={`${AppStore.theme.o.blue}`} />
          <TextBoard>{T.AiDiscoverPage.boardCard.newBoard}</TextBoard>
        </NewBoardButton>
      )}
    </BoardHeader>
  )
}

export default BoardPageHeader

const BoardHeader = styled(Box)`
  width: 100%;
  position: relative;
  min-height: 34px;
  border-radius: 0px;
  display: flex;
  gap: 12px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
`

const IconContainer = styled(Box)`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    &.arrow-left {
      fill: ${() => `${AppStore.theme.o.darkGrey}`};
      height: 14px;
      width: 14px;
      cursor: pointer;
    }

    &.private {
      background: ${() => `${AppStore.theme.o.lightestGrey}`};

      rect {
        stroke: ${() => `${AppStore.theme.o.grey}`};
      }

      path {
        fill: ${() => `${AppStore.theme.o.black}`};
      }
    }

    &.shared {
      border: 0.5px solid ${() => `${AppStore.theme.o.grey}`};
      border-radius: 8px;
      rect {
        fill: ${() => `${AppStore.theme.o.lightestGrey}`};
      }
      path {
        fill: ${() => `${AppStore.theme.o.black}`} !important;
      }
    }

    &.all {
      background-color: ${() => `${AppStore.theme.o.lightestGrey}`};
      border: 0.5px solid ${() => `${AppStore.theme.o.grey}`};
      border-radius: 8px;
      padding: 4px;
      rect {
        fill: ${() => `${AppStore.theme.o.lightestGrey}`};
      }
      path {
        fill: ${() => `${AppStore.theme.o.black}`} !important;
      }
    }
  }
`
const TextBoard = styled.p`
  text-align: center;
  font-family: Aktiv Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${() => `${AppStore.theme.o.blue}`};
`
const TextTitle = styled.p`
  color: ${() => `${AppStore.theme.o.black}`};
  font-family: Aktiv Grotesk;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.21px;

  position: relative;

  .arrow-left-container {
    position: absolute;
    top: 50%;
    left: -32px;
    transform: translate(-50%, -50%);
  }
`
const NewBoardButton = styled.button`
  border: 0.5px solid ${() => `${AppStore.theme.o.blue}`};
  width: 164px;
  height: 34px;
  padding: 8px 24px;
  min-height: 34px;
  position: absolute;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  top: 50%;
  box-shadow: none;
  gap: 8px;
  border-radius: 14px;
  background-color: ${() => `${AppStore.theme.o.lightBlue}`};
  transition: all 0.4s;
  cursor: pointer;
  &:hover {
    border: 0.5px solid transparent;
    box-shadow: none;
    background-color: ${() => `${AppStore.theme.o.lightBlue}`};
  }
`
const TitleActiveBoard = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  overflow-wrap: break-word;
  display: block;
  max-width: 500px;
`
