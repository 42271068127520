/* ******************* IMPORT ******************* */
import styled from '@emotion/styled'
import { Button, Icons } from 'components'
import { AppStore, T } from 'utils'
import { useNavigate } from 'react-router-dom'

interface Props {
  onReadAll: () => void
}

const FourDotsMenu = ({ onReadAll }: Props) => {
  const navigate = useNavigate()
  /* ____________________ METHODS ____________________ */
  const openNotificationSettings = () => {
    navigate(`/settings/workspace/notification/${AppStore.workspace._id}`)
  }

  return (
    <FourDotsMenuContainer>
      <Button className="btn" size="smaller" variant="neutral" onClick={onReadAll}>
        <Icons.closedMail />
        {T.notifyTexts.markAllAsRead}
      </Button>
      <Button className="btn" size="smaller" variant="neutral" onClick={openNotificationSettings}>
        <Icons.settings />
        {T.notifyTexts.notificationSettings}
      </Button>
    </FourDotsMenuContainer>
  )
}

export default FourDotsMenu

const FourDotsMenuContainer = styled.div`
  z-index: 100;
  right: 50px;
  display: inline-flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 14px;
  border: 1px solid ${() => AppStore.theme.o.grey};
  background: ${() => AppStore.theme.o.lightestGrey};
  background-color: ${() => AppStore.theme.o.surface};

  position: absolute;
  top: 60px;

  .btn {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: flex-start;
    border: none;
    background-color: ${() => AppStore.theme.o.surface};

    width: 100%;

    font-family: Aktiv Grotesk;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;

    :hover {
      background-color: ${() => AppStore.theme.o.lightestGrey};
      border: none;
    }
  }
`
