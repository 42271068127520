import styled from '@emotion/styled'
import React from 'react'
import { Box } from './Box'
import { Icons } from './Icons'
import { Text } from './Text'
import { AppStore, T } from 'utils'
import ReferralCodeModal from './ReferralCodeModal'

export const HeaderButtonEarn = () => {
  return (
    <ButtonEarn
      style={{ width: 150, height: 40, backgroundColor: AppStore.theme.o.lightGreen }}
      center
      vcenter
      id={'refferal-button'}
      onClick={() => {
        AppStore.openModal({
          id: 'preview-modal',
          body: <ReferralCodeModal />,
          style: {
            minWidth: 980,
          },
        })
      }}
    >
      <Box row vcenter>
        <Icons.referalGift fill={AppStore.theme.o.green} />
        <Text style={{ color: AppStore.theme.o.green }}>{T.referral.referral}</Text>
      </Box>
    </ButtonEarn>
  )
}

const ButtonEarn = styled(Box)``
