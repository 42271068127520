import { ConsoleSqlOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Box, Button, ZoomedContainer } from 'components'
import { Icons } from 'components/Icons'
import { Text } from 'components/Text'
import { TextArea } from 'components/TextArea'
import { Component } from 'react'
import { ModalType } from 'types'
import { AppStore } from 'utils'
import { navigate } from 'utils/router'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { T } from 'translation/i18n'

interface Props {
  goBack: any
  goNext: any
  setTextImg: any
  mock: any
  // selectedCategory: any
  setType: any
  setSelectedText: any
  groupName: string
  openModal: (modal: ModalType) => any
  closeAllModal: () => any
}

interface State {
  cliked: boolean
  selected: string
  step: number
  value: string
  textValue: string
  intestedMock: any
  width: any
  height: any

  changer: boolean
}

// TUTTE LE TIPOLOGIA DI GENERAZIONI
const typeGeneration = [
  { key: 'Visibilità', value: 0 },
  { key: 'Info Utili', value: 1 },
  { key: 'Evento / Festività', value: 2 },
  { key: 'Promozione / Sconto', value: 3 },
  { key: 'Contest / Giveaway', value: 4 },
]

// TUTTE LE INFO TEXT
const InfoTexts = [
  <Text weight={500} fontSize={16} style={{ textAlign: 'center' }}>
    Per prima cosa premi il box testo evidenziato.
    <br /> In questa demo guidata abbiamo scelto noi per te alcune parole chiave da dare in pasto alla nostra
    intelligenza artificiale.
  </Text>,
  <Text weight={500} fontSize={16} style={{ textAlign: 'center' }}>
    Ci siamo, ora tocca a te! 🖐
    <br /> Premi il tasto "Obiettivo" e seleziona l obiettivo del post che stai creando.
  </Text>,
  <Text weight={500} fontSize={16} style={{ textAlign: 'center' }}>
    Ottima scelta 😉
    <br /> È arrivato il momento di vedere con i tuoi occhi la potenza e velocità della nostra intelligenza artificiale
    👀 <br /> Premi il tasto "Genera" e libera la magia!
  </Text>,
  <Text weight={500} fontSize={16} style={{ textAlign: 'center' }}>
    La nostra intelligenza artificiale sta creando i testi per te 🧐 Attendi qualche secondo...
  </Text>,
  <Text weight={500} fontSize={16} style={{ textAlign: 'center' }}>
    I testi per il tuo post sono pronti! 🤩 Scrolla qui sotto ⬇️ Scegli il testo che più fa per te! 👀
  </Text>,
]

//LE CHOISES CHE VERANNO GENERATE DOPO AVER SCELTO LA TIPOLOGIA
const Choises = [['']]

//UNA FUNZIONE DI DELAY
function delay(time) {
  return new Promise((resolve) => setTimeout(resolve, time))
}
export default class FourthPage extends Component<Props, State> {
  state: State = {
    cliked: false,
    selected: 'Obiettivo',
    step: 0,
    value: 'Oggi ho comprato il mio nuovo iPhone',
    textValue: '',
    intestedMock: [],
    width: 0,
    height: 0,
    changer: true,
  }

  imgPath = ['']

  //PRE PRENDERE IL WIDTH E HEIGHT DELLA PAGINA
  // ------------------------------------------------------------------

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    // try {
    //   if (this.state.changer === true)
    this.setState({ width: window.innerWidth, height: window.innerHeight })
    // } catch {
    //   this.setState({ changer: true })
    // }
  }

  // ------------------   MODALE PER CHIEDERE LA TIPOLOGIA  --------------------------

  opneTypeSelectModal = () => {
    this.props.openModal({
      width: 500,
      id: 'media-modal-selector',

      body: (
        <ZoomedContainer
          center
          flex
          style={{ position: 'relative', padding: '16px 12px', maxWidth: 1800, opacity: 'none' }}
        >
          <Box width={302} height={74} bgPrimaryColor center>
            <Text weight={500} fontSize={16}>
              Scegli un obiettivo per il tuo post
            </Text>
          </Box>
          {typeGeneration.map((e) => (
            <Selectble
              onClick={async () => {
                await this.setState({ selected: e.key, step: 2 })
                this.props.setType(e.value)
                this.findValues()
                this.props.closeAllModal()
              }}
            >
              <Text style={{ fontSize: 16, fontWeight: 700, color: AppStore.theme.o.surface }}>{e.key}</Text>
            </Selectble>
          ))}
        </ZoomedContainer>
      ),

      style: {
        backgroundColor: 'transparent',
        minWidth: 302,
        overflow: 'hidden',
        overflowY: 'unset',
      },
    })
  }

  // ------------------   PER TROVARE VALORI DOPO LA SCELTA DELLA TIPOLOGIA  --------------------------
  findValues = async () => {
    let textIndex = -1
    let typeIndex = -1
    Choises.shift()
    Choises.shift()
    Choises.shift()
    try {
      this.props.mock.Inputs.forEach((element, index) => {
        if (element.Title === this.state.textValue) textIndex = index
      })
      this.props.mock.Inputs[textIndex].Objectives.forEach((element, index) => {
        if (element.Type === this.state.selected) typeIndex = index
      })
      this.props.mock.Inputs[textIndex].Objectives[typeIndex].Texts.forEach((element, index) => {
        const TextString = ''
        Choises.push(element)
      })
    } catch { }
  }

  // ------------------   PER TROVARE L'INTERA STRINGA PER MANDARE ALLA PAGINA SUCCESIVA  --------------------------

  fullString = (elements: any) => {
    let TextString = ''
    elements.forEach((string) => {
      TextString = TextString + string
    })
    return TextString
  }

  // ------------------   MODALE PER LA SCELTA DELL IMAGINE  --------------------------
  openImgModal = () => {
    this.props.openModal({
      width: 500,
      id: 'media-modal-dasdas',

      body: (
        <ZoomedContainer
          center
          flex
          style={{ position: 'relative', padding: '16px 12px', maxWidth: 1800, opacity: 'none', overflowX: 'hidden' }}
        >
          <Box flex center vcenter>
            <Box style={{ backgroundColor: 'white' }} p={24} center height={154}>
              <Text weight={500} fontSize={16} style={{ textAlign: 'center' }}>
                Super! 💪
                <br /> È arrivato il momento di scegliere <br />
                l'immagine per il tuo post 🌅 <br /> Ne abbiamo preparate qualcuna per te.
              </Text>
            </Box>

            <ImgBox center ml={10}>
              <Box flex style={{ minHeight: 0, margin: 8 }}>
                <Box row style={{ flexWrap: 'wrap', columns: 2 }}>
                  {this.imgPath.map((img, index) => (
                    <>
                      <Box flex>
                        <LazyLoadImage
                          onClick={() => {
                            this.props.setTextImg(img)
                            !navigate('/demo/step5')
                            this.props.goNext()

                            this.props.closeAllModal()
                          }}
                          effect="blur"
                          style={{
                            borderRadius: 14,
                            cursor: 'pointer',
                            height: 152, // 100% sul imaggine
                            width: 152,
                            marginBottom: 10,
                          }}
                          src={`https://asters.s3.eu-central-1.amazonaws.com/demo/${img}`}
                        // src={img}
                        />
                      </Box>
                    </>
                  ))}
                </Box>
              </Box>
            </ImgBox>
          </Box>
        </ZoomedContainer>
        // <Text>ciaop</Text>
      ),

      style: {
        backgroundColor: 'transparent',
        minWidth: 0,
        overflow: 'hidden',
        overflowX: 'hidden',
        overflowY: 'unset',
      },
    })
  }

  textRrender = (e) => {
    if (e === '') {
      return <br />
    }
    return <span> {e}</span>
  }
  render() {
    //PER TROVARE LE PATH DELLE IMAGINI

    const array = ['']
    for (let i = 1; i <= 6; i++) {
      array.push(this.props.groupName + i + '.jpeg')
    }
    this.imgPath = array
    this.imgPath.shift()

    //----------------------------------
    return (
      <Box
        flex
        style={{
          justifyContent: this.state.width > 1184 ? 'center' : 'none',
          alignItems: this.state.width > 1184 ? 'center' : 'none',
          maxHeight: '100vh',
        }}
        scroll
      >
        <Conteiner height={this.state.width > 1184 ? '' : '80vh'}>
          <InnerContainer height={this.state.step === 4 ? '' : '80vh'}>
            <MiniBox p={24}>
              <Box center>
                <Text weight={700} fontSize={16}>
                  Crea Post
                </Text>
              </Box>
              <Text weight={700} fontSize={16}>
                Il tuo testo
              </Text>

              <Box
                infiniteAnnimation={this.state.step === 0}
                infiniteAnnimationColor={this.state.step === 0 ? 'rgba(41, 130, 140, 1)' : undefined}
                style={{ borderRadius: 10, borderColor: 'red ' }}
                onClick={() => {
                  let randomNumber = Math.floor(Math.random() * this.props.mock.Inputs.length - 1)
                  randomNumber === -1 ? (randomNumber = 0) : null
                  if (this.state.step === 4) return
                  this.setState({
                    textValue:
                      this.state.step === 2
                        ? this.state.textValue
                        : this.props.mock.Inputs[Math.floor(randomNumber)].Title, //SCELTA DELLA IMAGINE ( RANDOM )
                    step: this.state.step === 2 ? 2 : this.state.step === 4 ? 2 : 1,
                  })

                  if (this.state.step === 2) this.findValues()

                  this.props.setSelectedText(this.state.textValue)
                }}
              >
                {/* ---------------------- FINTA TEXTAREA ( SOLO CLICABILE PER CREARE VALORI )  ---------------------- */}

                <FlexTextArea
                  readOnly
                  bordered
                  borderstyle="10px red solid"
                  id="text-area-create-post"
                  bgcolor={AppStore.theme.o.lightGrey}
                  bgcoloractive={AppStore.theme.o.lightGrey}
                  borderactivecolor={AppStore.theme.o.grey}
                  bordercolor={this.state.step === 0 ? ' rgba(41, 130, 140, 1)' : AppStore.theme.o.lightGrey}
                  borderwidth={'1px'}
                  height={160}
                  value={this.state.textValue}
                  placeholder={T.settings.insertSomeKeyWord}
                />
              </Box>
              {/* ---------------------- GENERATE BUTTON ---------------------- */}
              <Box row style={{ justifyContent: 'space-between' }}>
                <Box
                  infiniteAnnimation={this.state.step === 2}
                  infiniteAnnimationColor={this.state.step === 2 ? 'rgba(41, 130, 140, 1)' : undefined}
                >
                  <GenerationButton
                    disable={this.state.step < 2}
                    size="small"
                    variant={this.state.step === 2 ? undefined : 'neutral'}
                    disabled={this.state.step < 2}
                    style={{ cursor: this.state.step === 2 ? 'pointer' : 'not-allowed' }}
                    onClick={async () => {
                      await this.setState({ step: 3 })
                      delay(3000).then(() => this.setState({ step: 4 }))
                    }}
                  >
                    {T.postPage.generate}
                  </GenerationButton>
                </Box>
                {/* ---------------------- BOTTONE PER LA SCELTA DELLA TIPOLOGIA ---------------------- */}
                <SelectModalButton
                  infiniteAnnimation={this.state.step === 1}
                  infiniteAnnimationColor={this.state.step === 1 ? 'rgba(41, 130, 140, 1)' : undefined}
                  style={{
                    backgroundColor: this.state.step === 1 ? '#18818B' : '#f2f2f3 ',
                    cursor: this.state.step >= 1 ? 'pointer' : 'not-allowed',
                  }}
                  onClick={() => {
                    this.state.step === 1 ? this.opneTypeSelectModal() : null
                  }}
                >
                  <Text
                    color={this.state.step === 1 ? 'white' : 'black'}
                    style={{
                      maxWidth: 100,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {this.state.selected}
                  </Text>
                  <Icons.downIcon fill={this.state.step === 1 ? 'white' : 'black'} />
                </SelectModalButton>
              </Box>
            </MiniBox>

            {/* ---------------------- UNA CONDIZIONE NEL CASO SIA STEP 4 E IL SCHERMO PIU GRANDE DI 1184 PIXEL  ---------------------- */}
            {this.state.width > 1184 && this.state.step === 4 ? (
              <></>
            ) : (
              <>
                {/* ---------------------- TEXT DEL INFO DEI PASSAGI DA FARE   ---------------------- */}
                <InfoBox mt={24}>
                  <Text weight={500} fontSize={16} style={{ textAlign: 'center' }}>
                    {InfoTexts[this.state.step]}
                  </Text>
                  {this.state.step === 3 && (
                    <AnimationRotatedLogo spinTime={'2s'}>
                      <Icons.logo width={40} height={40}></Icons.logo>
                    </AnimationRotatedLogo>
                  )}
                </InfoBox>
                {/* {this.state.step === 1 && <Box height={'6.3vh'}></Box>} */}
              </>
            )}

            {/* ---------------------- UNA CONDIZIONE PER MOSTRARE IL RISULTATO NEL CASO  IL SCHERMO SI MINORE DI 1184PX ---------------------- */}
            {this.state.width < 1184 && (
              <Box flex center style={{ columns: 2 }}>
                {this.state.step === 4 &&
                  Choises.map((e, index) => (
                    <Box center flex>
                      <InfoBox mt={24}>
                        <Text>
                          {e.map((e2) => (
                            <p>{this.textRrender(e2)}</p>
                          ))}
                        </Text>
                      </InfoBox>

                      <VoglioQuesto
                        onClick={() => {
                          this.openImgModal()
                          this.props.setSelectedText(e)
                          // this.setState({ textValue: this.fullString(e) })
                        }}
                        mt={8}
                      >
                        <Text style={{ fontSize: 16, fontWeight: 700, color: AppStore.theme.o.surface }}>
                          Voglio questo!
                        </Text>
                      </VoglioQuesto>
                    </Box>
                  ))}
                {/* --------- SUPER DUPER EMERGENZA --------- */}
                <Box center mt={'6vh'}>
                  <Text weight={700} fontSize={16} color="#666666" onClick={this.props.goBack}>
                    torna indietro
                  </Text>
                </Box>
                <Box center height={'20vh'} />
              </Box>
            )}
          </InnerContainer>
        </Conteiner>

        {/* ---------------------- UNA CONDIZIONE PER MOSTRARE IL RISULTATO NEL CASO  IL SCHERMO SI MAGGIORE DI 1184PX ---------------------- */}

        {this.state.width >= 1184 && (
          <Box row center flex style={{ flexWrap: 'wrap', columns: 2, alignItems: 'baseline' }}>
            {this.state.step === 4 && (
              <>
                {Choises.map((e, index) => (
                  <>
                    <Box ml={index !== 0 ? 20 : 0} style={{ maxWidth: 375 }}>
                      <InfoBox scroll mt={24} style={{ maxHeight: ' 30vh', minHeight: 200 }}>
                        <Text weight={500} fontSize={16} style={{ whiteSpace: 'pre-wrap' }}>
                          {e.map((e2) => (
                            <p>{this.textRrender(e2)}</p>
                          ))}
                        </Text>
                      </InfoBox>

                      <Selectble
                        onClick={() => {
                          this.openImgModal()
                          this.props.setSelectedText(e)
                          this.setState({ textValue: this.fullString(e) })
                        }}
                      >
                        <Text style={{ fontSize: 16, fontWeight: 700, color: AppStore.theme.o.surface }}>
                          Voglio questo!
                        </Text>
                      </Selectble>
                    </Box>
                  </>
                ))}
              </>
            )}
          </Box>
        )}
        {this.state.width >= 1184 && <>{this.state.step === 4 && <Box height={'6vh'} width={1} />}</>}
      </Box>
    )
  }
}

const VoglioQuesto = styled(Button)`
  margin-top: 16px;
  display: flex;
  width: 100%;
  max-width: 355px;
  align-items: center;
  padding: 16px;
  background: #18818b;
  box-shadow: 4px 8px 16px rgba(213, 214, 218, 0.14);
  border-radius: 14px;
`

const AnimationRotatedLogo = styled(Box) <{ spinTime: string }>`
  animation: spin ${({ spinTime }) => spinTime} linear infinite;
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`

const ImgBox = styled(Box)`
  min-width: 350px !important;
  width: 60vw;
  height: 60vh;
  max-width: 337px !important;
`

const InnerContainer = styled(Box)`
  width: 95vw;
  max-width: 375px;
`

const Conteiner = styled(Box)`
  width: 96vw;
  max-width: 375px;
`

interface DisableButton {
  disable: boolean
}

const GenerationButton = styled(Button) <DisableButton>`
  width: 133.5px;
  height: 42px;
  cursor: pointer;
  :hover {
    ${({ disable }) => (disable ? 'animation: shake 0.4s ease-out;' : 'animation: none')}
  }
  @keyframes shake {
    0% {
      transform: translateX(-5px);
    }

    25% {
      transform: translateX(5px);
    }

    50% {
      transform: translateX(-5px);
    }

    75% {
      transform: translateX(5px);
    }

    50% {
      transform: translateX(-5px);
    }
  }
`

const MiniBox = styled(Box)`
  flex-direction: column;
  padding: 24px;
  background-color: red;
  gap: 8px;
  height: 325px;
  background: #ffffff;
  box-shadow: 4px 8px 16px rgba(213, 214, 218, 0.14);
  border-radius: 14px;
`

const FlexTextArea = styled(TextArea)`
  font-size: 12px !important;
  cursor: pointer;
  line-height: 160% !important;
`
const InfoBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 8px;
  max-width: 390px;
  background: #ffffff;
  box-shadow: 4px 8px 16px rgba(213, 214, 218, 0.14);
  border-radius: 14px;
`
const SelectModalButton = styled(Box)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 4px;
  max-width: 133.5px;
  border-radius: 14px;
  flex: none;
  order: 1;
  flex-grow: 1;
  z-index: 0;
`

const Selectble = styled(Button)`
  margin-top: 16px;
  min-width: 302px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  background: #18818b;
  box-shadow: 4px 8px 16px rgba(38, 42, 72, 0.14);
  border-radius: 14px;
  flex: none;
  order: 1;
  flex-grow: 0;
`
