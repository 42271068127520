/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { HeaderLabelModal } from './HeaderLabelModal'
import { ContentLabelModal } from './ContentLabelModal'
import { AppStore, T } from 'utils'
import { useEffect, useState } from 'react'
import { ILabel } from 'components/CreatePost/PreviewComponents/LabelsComponents/LabelsContentComponents/Label'
import Labels from 'api/Labels'
import { SpinnerAstersLogo } from 'components/UI'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {}

export const ManageLabels = ({}: Props) => {
  /* ----------------- STATE  */
  const [inputValue, setInputValue] = useState<string>('')

  // Tutte le label del workspace
  const [workspaceLabels, setWorkspaceLabels] = useState<ILabel[]>([])

  const [selectedLabelIds, setSelectedLabelIds] = useState<string[]>([])

  const [isAllSelected, setIsAllSelected] = useState<boolean>(false)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [refresh, setRefresh] = useState(false)

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */
  const refreshLabels = () => {
    setRefresh((prev) => !prev)
  }

  const handleSelectAll = () => {
    if (selectedLabelIds.length === 0) {
      setSelectedLabelIds(workspaceLabels.map((label) => label._id))
    } else if (selectedLabelIds.length === workspaceLabels.length) {
      setSelectedLabelIds([])
    } else {
      setSelectedLabelIds(workspaceLabels.map((label) => label._id))
    }
  }

  const handleSelectLabel = (id: string) => {
    if (selectedLabelIds.includes(id)) {
      setSelectedLabelIds((prev) => prev.filter((labelId) => labelId !== id))
    } else {
      setSelectedLabelIds((prev) => [...prev, id])
    }
  }

  /* ----------------- API CALL  */
  const handleDeleteClick = async () => {
    const ids = {
      labelsId: selectedLabelIds,
    }

    try {
      const res = await Labels.deleteLabels(ids)
      refreshLabels()
    } catch (e) {
      console.error(e)
    }
  }

  const getLabels = async () => {
    setIsLoading(true)
    try {
      const res = await Labels.getWorkspaceLabels()
      setWorkspaceLabels(res)
    } catch (e) {
      console.error(e)
    }

    setIsLoading(false)
  }

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    if (workspaceLabels.length > 0) {
      setIsAllSelected(workspaceLabels.length === selectedLabelIds.length)
    }
  }, [selectedLabelIds.length])

  // Fetch le labels del workspace
  useEffect(() => {
    getLabels()
  }, [refresh])

  return (
    <ManageLabelsContainer>
      <HeaderLabelModal
        inputValue={inputValue}
        setInputValue={setInputValue}
        numberOfLabels={workspaceLabels.length}
        handleSelectAll={handleSelectAll}
        isAllSelected={isAllSelected}
      />

      {/* Loader */}
      {isLoading && (
        <Box relative width={'100%'} height={200}>
          <SpinnerAstersLogo dimension={50} />
        </Box>
      )}

      {/* Labels*/}
      {!isLoading && (
        <ContentLabelModal
          workspaceLabels={workspaceLabels}
          inputValue={inputValue}
          selectedLabelIds={selectedLabelIds}
          handleSelectLabel={handleSelectLabel}
        />
      )}

      {selectedLabelIds.length > 0 && <DeleteButton onClick={handleDeleteClick}>{T.postPage.delete}</DeleteButton>}
    </ManageLabelsContainer>
  )
}

const ManageLabelsContainer = styled(Box)`
  position: relative;
  display: flex;
  width: 100%;
  height: 742px;
  padding: 32px 40px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 14px;
  background: ${() => AppStore.theme.o.surface};
`

const DeleteButton = styled(Box)`
  position: absolute;
  bottom: -51px;
  left: 50%;
  translate: -50%;

  min-height: 42px;
  height: 42px;
  padding: 12px 24px;
  border-radius: 8px;
  background: ${() => AppStore.theme.o.error_container};
  color: ${() => AppStore.theme.o.on_error_container};
  border: 1px solid ${() => AppStore.theme.o.on_error_container};
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  cursor: pointer;

  &:hover {
    background: ${() => AppStore.theme.o.error};
    color: ${() => AppStore.theme.o.on_error};
  }
`
