/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { truncateText } from 'pages/ai-discover/commonFunction'
import { Workspace } from 'types'

/* ----------------- ENUM  */
// Background è utilizzato per il colore di background, color è utilizzato per il border e il testo
export const colorEnumLabel = {
  purple: {
    background: '#FCF3FD',
    color: '#A12DBC',
  },
  blue: {
    background: '#EAFCFE',
    color: '#008086',
  },
  red: {
    background: '#FFEEEF',
    color: '#BC4753',
  },
  green: {
    background: '#EAFEF7',
    color: '#27825E',
  },
  yellow: {
    background: '#FFFAEA',
    color: '#B65B02',
  },
}

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */
export type TColorLabel = 'purple' | 'blue' | 'red' | 'green' | 'yellow'

export interface ILabel {
  _id: string
  label: string // the text of the label
  color: TColorLabel
  workspace?: Workspace
}

interface Props {
  id: string
  label: string
  color: TColorLabel
  action?: (value: ILabel) => void
  removeLabel?: (value: ILabel) => void
  disabled?: boolean
}

export const Label = ({ id, label, color, action, removeLabel, disabled }: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */
  const value = {
    _id: id,
    label: label,
    color: color,
  }

  /* ----------------- METHODS  */

  const handleOnClick = () => {
    action && action(value)
  }

  const handleRemove = () => {
    removeLabel && removeLabel(value)
  }

  const handleCursorStyle = () => {
    if (disabled) {
      return 'not-allowed'
    } else if (action) {
      return 'pointer'
    } else {
      return 'default'
    }
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <LabelContainer
      id={id}
      onClick={handleOnClick}
      style={{
        backgroundColor: colorEnumLabel[color].background,
        border: `1px solid ${colorEnumLabel[color].color}`,
        color: colorEnumLabel[color].color,
        filter: disabled ? 'grayscale(100%)' : 'none',
        cursor: handleCursorStyle(),
      }}
    >
      <LabelText center>{truncateText(label, 20)}</LabelText>

      {removeLabel && (
        <CloseIcon
          onClick={handleRemove}
          background={colorEnumLabel[color].background}
          color={colorEnumLabel[color].color}
        >
          <Icons.sizerClose fill={colorEnumLabel[color].color} />
        </CloseIcon>
      )}
    </LabelContainer>
  )
}

const LabelContainer = styled(Box)`
  display: flex;
  height: 32px;
  padding: 4px 12px 4px 16px;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:hover {
    border-color: transparent !important;
  }
`

const LabelText = styled(Box)`
  text-align: center;
  font-family: 'Aktiv Grotesk';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 4px;
`

const CloseIcon = styled(Box)<{ background: string; color: string }>`
  transition: 300ms ease-in-out;
  background: ${(props) => props.background};

  &:hover {
    background: ${(props) => props.color};
    svg {
      transition: 300ms ease-in-out;
      fill: ${(props) => props.background};
    }
  }
`
