/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Button, Text } from 'components'
import { ILabel, Label, TColorLabel } from './Label'
import { AppStore, T } from 'utils'
import Labels from 'api/Labels'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  workspaceLabels: ILabel[]
  inputValueFilter: string
  filteredLabels: ILabel[]
  refreshLabels: () => void
  addLabel: (specificLabel?: ILabel) => void
}

export const EmptyStateLabel = ({
  workspaceLabels,
  inputValueFilter,
  filteredLabels,
  refreshLabels,
  addLabel,
}: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /**
   *  Funzione che genera un colore random per le label
   * @returns Un colore random tra TColorLabel
   */
  const generateRandomColor = (): TColorLabel => {
    const colors: TColorLabel[] = ['purple', 'blue', 'red', 'green', 'yellow']
    return colors[Math.floor(Math.random() * colors.length)]
  }

  /**
   * Funzione che gestisce cosa mostrare come empty state in base agli stati di workspaceLabels e filteredLabels e inputValueFilter
   * @returns Un JSX.Element
   */
  const handleEmptyState = (): JSX.Element => {
    if (workspaceLabels.length === 0 && inputValueFilter === '') {
      return (
        <EmptyStateLabelContainer>
          <Text>{T.postPage.labelsSection.noLabelIsPresent}</Text>
        </EmptyStateLabelContainer>
      )
    }

    const isInputEqualToOneLabel = filteredLabels.find((lb) => lb.label === inputValueFilter)

    if (inputValueFilter !== '' && !isInputEqualToOneLabel) {
      const label = {
        _id: 'creating_label',
        label: inputValueFilter,
        color: generateRandomColor(),
      }

      return (
        <EmptyStateLabelContainer>
          <Box
            row
            center
            gap={12}
            style={{
              flexWrap: 'wrap',
            }}
          >
            <Text>{T.postPage.labelsSection.noLabelIsPresent}</Text>
            <Label id={label._id} label={label.label} color={label.color} />
          </Box>

          <CreateButton variant="primary" onClick={() => handleCreateLabel(label)}>
            {T.teamMembers.create}
          </CreateButton>
        </EmptyStateLabelContainer>
      )
    }

    return <></>
  }

  /* ----------------- API CALL  */
  const handleCreateLabel = async (obj: ILabel) => {
    const createLabelParam = JSON.parse(JSON.stringify(obj))
    createLabelParam.workspaceId = AppStore.getWorkspaceId()
    delete createLabelParam._id

    try {
      const res = await Labels.createLabel(createLabelParam)

      addLabel(res)
      refreshLabels()
    } catch (e) {
      console.error(e)
    }
  }

  /* ----------------- USEEFFECT  */

  return handleEmptyState()
}

const EmptyStateLabelContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 160px;
  padding: 12px 16px;
  flex-direction: column;
  gap: 12px;
  border-radius: 14px;
  background: ${() => AppStore.theme.o.lightestGrey};
  color: ${() => AppStore.theme.o.black};
`

const CreateButton = styled(Button)`
  height: 40px !important;
  min-height: 40px !important;
  padding: 8px 48px !important;
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
