import { Workspace } from 'types'
import { AppStore, showInfo, T } from 'utils'
import AstersBaseResource from './AstersBaseResource'
import { api } from './api'
import AWSUtils from 'utils/AWSUtils'
import { message } from 'antd'

export default class Medias extends AstersBaseResource {
  static endpoint = '/medias'

  //upload with form data where file is the file to upload
  static upload(data: FormData) {
    return api.post<{ presignedUrl: string; filename: string }>(`${this.endpoint}/upload`, data)
  }

  static async createFile(
    formData: FormData,
    onUploadProgress?: (progressEvent: ProgressEvent) => void,
    isPublic?: boolean
  ) {
    const filename = (formData.get('file') as File).name
    const data = await AWSUtils.uploadFileInChunks(formData, filename, onUploadProgress, isPublic)
    return data
  }

  static createFolder(data: { name: string; path: string; workspace?: Workspace; type?: 'file' | 'folder' }) {
    data.workspace = AppStore.workspace
    //remove workspace id from path
    data.path = data.path.replace(`${AppStore.workspace._id}/`, '')
    return api.post(`${this.endpoint}/create/folder`, data)
  }

  static rename(id, name) {
    return api.post(`${this.endpoint}/rename/${id}/${name}`)
  }

  static changePath(data: { media: any; path: string }) {
    return api.post(`${this.endpoint}/changePath`, data)
  }
  static getFolderAWS(workspaceId: string) {
    const data = { workspaceId }
    return api.post(`${this.endpoint}/getFolderSize`, data)
  }

  static getUrl(media) {
    return api.post(`${this.endpoint}/getUrl/${media._id}`)
  }

  static addToStorage(data) {
    return api.post(`${this.endpoint}/addToStorage`, data)
  }

  static verifyUploadLimits(data) {
    return api.post(`${this.endpoint}/elegibleForUpload`, data)
  }

  ///-----------------  NEW METHODS -----------------///

  static initializeMultipartUpload = async (filename, isPublic) => {
    return api.post(`${this.endpoint}/upload/initialize`, { filename, isPublic })
  }

  static getPresignedUrl = async (filename, uploadId, partNumber) => {
    const { data } = await api.post(`${this.endpoint}/upload/presignedUrl`, {
      filename,
      uploadId,
      partNumber,
    })
    return data
  }

  static uploadChunk = async (formData) => {
    return api.post(`${this.endpoint}/upload/uploadPart`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }

  static completeMultipartUpload = async (filename, uploadId, parts, isPublic) => {
    return api.post(`${this.endpoint}/upload/complete`, { filename, uploadId, parts, isPublic })
  }
}
