/* ------------------- IMPORTS */
import { MultiSelectRow } from 'components/UI/MultiSelectRow'
import { useCalendarStore } from 'stores/CalendarStore'
import { T } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {}

export const StatusFilterCalendar = ({}: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */
  const { status } = useCalendarStore((s) => ({ status: s.status }))

  const { setStatus } = useCalendarStore()

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  /* ----------------- CONFIG  */
  const statusFilter = [
    {
      name: T.status.all,
      value: 'all',
      action: () => setStatus('all'),
    },
    {
      name: T.status.planned,
      value: 'planned',
      action: () => setStatus('planned'),
    },
    {
      name: T.status.published,
      value: 'published',
      action: () => setStatus('published'),
    },
    {
      name: T.status.notPublished,
      value: 'error',
      action: () => setStatus('error'),
    },
  ]

  return <MultiSelectRow active={status} options={statusFilter} />
}
