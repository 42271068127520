/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Button, Icons } from 'components'
import { openPartnersModal } from './Modal/ModalManager'
import { AppStore } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {}

export const HeaderPartnersButton = ({}: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <HeaderPartnersButtonContainer
      variant={'neutral'}
      onClick={openPartnersModal}
      size="smaller"
      style={{ width: 189, height: 40, fontWeight: 500 }}
    >
      <Icons.partnersSizer fill={AppStore.theme.o.black} /> Partners
    </HeaderPartnersButtonContainer>
  )
}

const HeaderPartnersButtonContainer = styled(Button)`
  gap: 4px;
`
