/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { IMediaCalendar, IMetadataPost } from 'api/PostV2'
import { getDomain } from 'api/Utils'
import { BackwardIcon, Box, ForwardIcon, Icons } from 'components'
import { ImageWithFallback } from 'components/UI/ImageWithFallback'
import { truncateText } from 'pages/ai-discover/commonFunction'
import { useState } from 'react'
import { AppStore, T } from 'utils'
import MediaUtils from 'utils/MediaUtils'
import { nextMedia, prevMedia } from 'utils/PostUtils/Post'
import HoverPlayVideo from './HoverPlayVideo'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  media: IMediaCalendar[]
  metadata?: IMetadataPost
}

export const MediaCalendarCard = ({ media, metadata }: Props) => {
  /* ----------------- STATE  */
  const [index, setIndex] = useState<number>(0)

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  const mediaDuration = media[index]?.videoDuration ?? 0
  const currentImageUrl = media[index]?.url ?? metadata?.imageUrl
  return (
    <MediaCalendarCardContainer>
      {/* EMPTY */}
      {!metadata && media.length === 0 && (
        <Empty>
          <Icons.medias fill={AppStore.theme.o.darkGrey} />
          {T.postPage.noMediaInserted}
        </Empty>
      )}

      {/* METADATA */}
      {metadata && (
        <MediaContainer>
          <img src={currentImageUrl} loading="lazy" />

          {/* Metadata info */}
          <MetadataInfo>
            <MetadataUrl>{truncateText(getDomain(metadata.url)!, 23)}</MetadataUrl>
            <MetadataTitle>{truncateText(metadata.title, 23)}</MetadataTitle>
          </MetadataInfo>
        </MediaContainer>
      )}

      {/* MEDIA */}
      {!metadata && media.length > 0 && (
        <MediaContainer>
          {media[index]?.type === 'image' && <img src={media[index]?.url} loading="lazy" />}
          {media[index]?.type === 'video' && <HoverPlayVideo url={media[index]?.url} />}
          {/* Carousel counter */}

          {/* Video timing */}
          {mediaDuration && mediaDuration > 0 && (
            <VideoTiming>{mediaDuration && Math.floor(media[index]?.videoDuration as number) + 's'}</VideoTiming>
          )}
        </MediaContainer>
      )}

      {/* Carousel controls */}
      {media?.length > 1 && (
        <ContainerArrow>
          <BackwardIcon
            onClick={(e) => prevMedia(index, setIndex, media, e)}
            style={{ position: 'relative', left: -14 }}
          />
          <ForwardIcon
            onClick={(e) => nextMedia(index, setIndex, media, e)}
            style={{ position: 'relative', right: -14 }}
          />
        </ContainerArrow>
      )}
    </MediaCalendarCardContainer>
  )
}

const MediaCalendarCardContainer = styled(Box)`
  position: relative;
  flex: 1;
  width: 100%;
  height: 100%;
  max-height: 100%;
`

const MediaContainer = styled(Box)`
  position: relative;
  flex: 1;
  max-height: 100%;
  border-radius: 14px;

  img {
    border-radius: 14px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const VideoTiming = styled(Box)`
  position: absolute;
  bottom: 8px;
  right: 8px;
  background-color: ${() => AppStore.theme.o.lightGrey};
  color: ${() => AppStore.theme.o.black};
  padding: 8px;
  border-radius: 8px;
  z-index: 1;
`

const Empty = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 100%;
  width: 100%;
  border-radius: 14px;
  background: ${() => AppStore.theme.o.lightestGrey};
  color: ${() => AppStore.theme.o.darkGrey};
  font-family: 'Aktiv Grotesk';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const ContainerArrow = styled(Box)`
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const MetadataInfo = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0px 0px 14px 14px;
  border-right: 1px solid ${() => AppStore.theme.o.lightGrey};
  border-bottom: 1px solid ${() => AppStore.theme.o.lightGrey};
  border-left: 1px solid ${() => AppStore.theme.o.lightGrey};
  background: ${() => AppStore.theme.o.lightestGrey};
`

const MetadataUrl = styled(Box)`
  font-family: 'Aktiv Grotesk';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${() => AppStore.theme.o.darkGrey};
  text-transform: uppercase;
`

const MetadataTitle = styled(Box)`
  font-family: 'Aktiv Grotesk';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${() => AppStore.theme.o.black};
`
