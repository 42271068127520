import { SubscriptionOption } from 'pages/settings/Subscription/SubscriptionProvider'
import { SocialAccount, User } from 'types'
import { IPostVariant } from 'types/PostInterface'
import { SubscriptionPlanMode } from 'types/SettingsInterfaces'
import AppStore from 'utils/AppStore'
import { OWNER_POST_LIMITS_AVAILABLE, OWNER_POST_LIMITS_USED } from 'utils/Constants/LimitUserConstants'
import {
  MixCheckoutData,
  MixpanelAccountSettings,
  MixpanelCalendar,
  MixpanelDashboard,
  MixpanelMediaStorage,
  MixpanelSocialPageData,
  MixpanelSubscriptionData,
} from './pageViewInterfaces'
import { IResponseCalendarPosts } from 'api/PostV2'

export function getDashboardData(dashboardData: User): MixpanelDashboard {
  const postInfo = AppStore?.workspace?.owner?.postInfos
  const data = {
    num_scheduled_posts: postInfo?.planned ?? 0,
    num_published_posts: postInfo?.published ?? 0,
    num_not_published_posts: postInfo?.notPublished ?? 0,
    num_used_ai_captions: OWNER_POST_LIMITS_USED,
    num_available_ai_captions: OWNER_POST_LIMITS_AVAILABLE,
    num_used_social_profiles: OWNER_POST_LIMITS_USED,
    num_available_social_profiles: OWNER_POST_LIMITS_AVAILABLE,
  }

  return data
}

export function getCalendarData(calendarPosts: IPostVariant[]): MixpanelCalendar {
  return {
    num_facebook_post: calendarPosts.filter((post) => post.info.account.provider === 'facebook').length,
    num_instagram_post: calendarPosts.filter((post) => post.info.account.provider === 'instagram').length,
    num_linkedin_post: calendarPosts.filter((post) => post.info.account.provider === 'linkedin').length,
    num_tiktok_post: calendarPosts.filter((post) => post.info.account.provider === 'tiktok').length,
    num_x_post: calendarPosts.filter((post) => post.info.account.provider === 'x').length,
    num_youtube_post: calendarPosts.filter((post) => post.info.account.provider === 'youtube').length,
  }
}

export const sumPostsByProvider = (data: IResponseCalendarPosts[]) => {
  const providerTotals = {}

  for (const item of data) {
    for (const social of item.socials) {
      const provider = social.provider
      const totalPost = social.totalPost

      const key = `num_${provider}_posts`
      if (!providerTotals[key]) {
        providerTotals[key] = 0
      }

      providerTotals[key] += totalPost
    }
  }

  return providerTotals
}

export function getMediaStorageData(mediaData, currentPath: string): MixpanelMediaStorage {
  return {
    available_storage: mediaData.available_storage,
    used_storage: mediaData.used_storage,
    files_order: mediaData.files_order,
    num_files: mediaData.medias.filter((media) => media.type === 'file').length ?? 0,
    num_folders: mediaData.medias.filter((media) => media.type === 'folder').length ?? 0,
    num_subfolders: mediaData.medias.filter((media) => {
      media.type === 'folder' && media.path !== currentPath
    }).length,
  }
}

export function getAccountSettings(): MixpanelAccountSettings {
  return {
    software_language: AppStore.loggedUser.language === 'it' ? 'it' : 'en',
    software_theme: AppStore.darkTheme ? 'Dark Mode' : 'Light Mode',
  }
}

export function getSocialPageData(socialData: SocialAccount[]): MixpanelSocialPageData {
  return {
    num_expired_token: socialData.filter((account) => account.expired === true).length,
    num_facebook_profiles: socialData.filter((account) => account.provider === 'facebook').length,
    num_instagram_profiles: socialData.filter((account) => account.provider === 'instagram').length,
    num_linkedin_profiles: socialData.filter((account) => account.provider === 'linkedin').length,
    num_tiktok_profiles: socialData.filter((account) => account.provider === 'tiktok').length,
    num_x_profiles: socialData.filter((account) => account.provider === 'x').length,
    num_youtube_profiles: socialData.filter((account) => account.provider === 'youtube').length,
  }
}

export function getSubscriptionData(
  subscriptionMode: SubscriptionPlanMode,
  activeOpt: SubscriptionOption
): MixpanelSubscriptionData {
  let price: number

  // Calcola il prezzo in base alla modalità di abbonamento
  if (subscriptionMode === 'annual') {
    // Se la modalità di abbonamento è annuale, usa il costo dell'offerta
    price = activeOpt.offer?.cost || activeOpt.price.annual
  } else {
    price = activeOpt.price.monthly
  }

  return {
    product_name: activeOpt.label,
    price: '€' + price,
    recurring_interval: subscriptionMode === 'annual' ? 'Yearly' : 'Monthly',
  }
}

export function getSubscriptionCheckoutData(
  checkoutData: MixCheckoutData,
  activeOpt: SubscriptionOption
): MixpanelSubscriptionData {
  // Ottieni dati  dall'opzione attiva
  let price = 0

  // Calcola il prezzo in base alla modalità di abbonamento
  if (checkoutData.mode === 'annual') {
    // Se la modalità di abbonamento è annuale, usa il costo dell'offerta
    price = activeOpt.offer?.cost || activeOpt.price.annual
  } else {
    price = activeOpt.price.monthly
  }

  const productExtraStorageQuantity =
    checkoutData.additionalOpt.find((option) => option.key === 'storage')?.quantity || 0
  const productExtraSocialProfileQuantity =
    checkoutData.additionalOpt.find((option) => option.key === 'socials')?.quantity || 0

  let productExtraName = ''

  // Crea una stringa concatenata dei nomi extra
  if (productExtraStorageQuantity > 0) {
    productExtraName = '5GB of extra cloud storage'
  }

  if (productExtraSocialProfileQuantity > 0) {
    if (productExtraName.length > 0) {
      productExtraName += ', '
    }
    productExtraName += 'Extra Social Profile'
  }
  const productExtraStorageCost = productExtraStorageQuantity * 3 // Costo storage per unità
  const productExtraSocialProfileCost = productExtraSocialProfileQuantity * 2 // Costo social profiles per unità

  // Calcola il costo totale delle opzioni extra
  const totalAdditionalCost = productExtraStorageCost + productExtraSocialProfileCost

  // Calcola il subtotal (prezzo del prodotto + costo extra)
  const subtotal = price + totalAdditionalCost

  const ivaRate = 1.22 // Tasso IVA (22%)
  const iva = ((price + totalAdditionalCost) * (ivaRate - 1)).toFixed(2)

  // Calcola il total (subtotal + IVA)
  const total = (subtotal + parseFloat(iva)).toFixed(2)
  // Registra l'evento Mixpanel con le proprietà degli eventi calcolati

  return {
    product_extra_name: productExtraName,
    product_extra_storage_quantity: productExtraStorageQuantity,
    product_extra_social_profile_quantity: productExtraSocialProfileQuantity,
    subtotal: '€' + subtotal,
    total: '€' + total,
  }
}
