/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { IPostVariant, IUrl } from 'types/PostInterface'
import { AppStore } from 'utils'
import { updateActive, updatePost } from 'utils/PostUtils/Post'

/* ------------------- INTERFACES  */

interface Props {
  url: IUrl
  post: IPostVariant
  section: string
  setPostState?: any
}

export const PreviewMetadataItem = ({ url, post, section, setPostState }: Props) => {
  /* ----------------- VARIABLES  */
  const { ogDescription, ogUrl, ogImg, ogTitle, visible } = url.metaData

  const changePreviewMetadata = (idLink: string) => {
    if (section === 'preview') {
      const updateVisibilyMetadatas = post.data.urls?.map((iurl) => ({
        ...iurl,
        metaData: { ...iurl.metaData, visible: iurl.id === idLink },
      }))
      updateActive({ urls: updateVisibilyMetadatas })

      setPostState({ ...post, data: { ...post.data, urls: updateVisibilyMetadatas } })
    }

    if (section === 'extra') {
      const updateVisibilyMetadatas = post.data.firstComment?.urls?.map((iurl) => ({
        ...iurl,
        metaData: { ...iurl.metaData, visible: iurl.id === idLink },
      }))
      updatePost(post.variantId, { firstComment: { ...post.data.firstComment, urls: updateVisibilyMetadatas } })

      setPostState({
        ...post,
        data: { ...post.data, firstComment: { ...post.data.firstComment, urls: updateVisibilyMetadatas } },
      })
    }
  }

  return (
    <PreviewMetadataItemContainer onClick={() => changePreviewMetadata(url.id)}>
      <MetadataImage
        onClick={() => {
          window.open(ogUrl, '_blank')
        }}
        src={ogImg ?? ''}
      />
      <MetadataContent>
        <MedataTitle>{ogTitle}</MedataTitle>
        <Divider />
        <MedataDescription>{ogDescription}</MedataDescription>
      </MetadataContent>

      <CheckPreview className={visible ? 'preview_metadata_checkbox_selected' : 'preview_metadata_checkbox'} />
    </PreviewMetadataItemContainer>
  )
}

const PreviewMetadataItemContainer = styled(Box)`
  width: 100%;
  max-height: 750px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 16px;
  border: 1px solid ${() => AppStore.theme.o.grey};
  position: relative;

  &:hover {
    .preview_metadata_checkbox {
      border-color: ${() => AppStore.theme.o.grey};
      &::after {
        opacity: 1;
      }
    }
  }
`

const MetadataImage = styled.img`
  width: 180px;
  height: 180px;
  border-radius: 24px;
  cursor: pointer;
  border: 2px solid ${() => AppStore.theme.o.black};
`

const MetadataContent = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 450px;
  border-radius: 0px;
`

const MedataTitle = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  font-family: Aktiv Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  border-radius: 0px;
  max-height: 40px;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  padding: 8px;
`

const Divider = styled(Box)`
  width: calc(100%-8px);
  height: 0.5px;
  background-color: ${() => AppStore.theme.o.grey};
  margin: 4px 0px 4px 8px;
`

const MedataDescription = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 8px;
  max-height: 110px;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
`

const CheckPreview = styled(Box)`
  transition: 300ms ease-in-out;

  &.preview_metadata_checkbox {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: ${() => AppStore.theme.o.lightGrey};
    border: 1px solid ${() => AppStore.theme.o.lightGrey};
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      content: '';
      background-color: ${() => AppStore.theme.o.grey};
      width: 14px;
      height: 14px;
      border-radius: 50%;
      opacity: 0;
    }
  }

  &.preview_metadata_checkbox_selected {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: ${() => AppStore.theme.o.lightBlue};
    border: 1px solid ${() => AppStore.theme.o.blueOnLight};
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      content: '';
      background-color: ${() => AppStore.theme.o.blueOnLight};
      width: 14px;
      height: 14px;
      border-radius: 50%;
    }
  }
`
