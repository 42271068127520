import styled from '@emotion/styled'
import { Box, Text } from 'components'
import { Component } from 'react'
import { T } from 'translation/i18n'
import { AppStore } from 'utils'
import {
  INFINITE_SYMBOL,
  OWNER_STORAGE_LIMITS_AVAILABLE,
  OWNER_STORAGE_LIMITS_INFINITE,
  OWNER_STORAGE_LIMITS_USED,
} from 'utils/Constants/LimitUserConstants'

export default class GigaCounter extends Component {
  render() {
    return (
      <Box style={{ marginLeft: '25px' }}>
        <Box mb={8}>
          <Text color={AppStore.theme.o.black}>
            {T.mediaPage.storageSpaceAvailable} {OWNER_STORAGE_LIMITS_USED.toFixed(2)} GB {T.mediaPage.storageSpaceUsed}
            <span style={{ marginLeft: 4 }} />
            {OWNER_STORAGE_LIMITS_INFINITE ? INFINITE_SYMBOL : OWNER_STORAGE_LIMITS_AVAILABLE} GB
          </Text>
        </Box>
        <Line>
          <Box
            style={{
              backgroundColor:
                (OWNER_STORAGE_LIMITS_USED / OWNER_STORAGE_LIMITS_AVAILABLE) * 100 > 75
                  ? AppStore.theme.o.red
                  : (OWNER_STORAGE_LIMITS_USED / OWNER_STORAGE_LIMITS_AVAILABLE) * 100 > 35
                  ? AppStore.theme.o.yellow
                  : AppStore.theme.o.green,
              minHeight: 4,
              maxWidth: `${(OWNER_STORAGE_LIMITS_USED / OWNER_STORAGE_LIMITS_AVAILABLE) * 100}%`,
            }}
          ></Box>
        </Line>
      </Box>
    )
  }
}

const Line = styled(Box)`
  width: 290px;
  height: 4px;
  position: relative;

  background-color: #d8dce0;
  border-radius: 99px;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: -4px 0px;
`
