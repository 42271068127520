/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { SocialType } from 'api/Utils'
import { Box, SocialIcon } from 'components'
import { AppStore } from 'utils'

/* ------------------- INTERFACES  */
interface Props {
  picture: string
  provider: SocialType
  active?: boolean
  dimensionPicture?: number
  dimensionProvider?: number
  frame?: boolean
  isTokenExpired?: boolean
}

export const SocialPicture = ({
  picture,
  provider,
  active = true,
  dimensionPicture = 36,
  dimensionProvider = 28,
  frame = false,
  isTokenExpired = false,
}: Props) => {
  return (
    <SocialPictureContainer className="social_picture_container">
      <Box infiniteAnnimation={isTokenExpired} infiniteAnnimationColor={AppStore.theme.o.red}>
        <ProfilePicture
          src={picture}
          className={`profile_picture ${active ? 'active' : 'not_active'} ${frame ? 'frame' : ''}`}
          style={{ width: dimensionPicture + 'px', height: dimensionPicture + 'px' }}
        />
      </Box>
      <ProviderContainer className="provider_container">
        <SocialIcon
          social={provider}
          active={active}
          style={{ width: dimensionProvider + 'px', height: dimensionProvider + 'px' }}
        />
      </ProviderContainer>
    </SocialPictureContainer>
  )
}

const SocialPictureContainer = styled(Box)`
  &.social_picture_container {
    position: relative;
  }
`

const ProfilePicture = styled.img`
  &.profile_picture {
    border-radius: 50%;

    &.active {
    }

    &.not_active {
      filter: grayscale(100%);
    }

    &.frame {
      border: 2px solid ${() => AppStore.theme.o.surface};
    }
  }
`

const ProviderContainer = styled(Box)`
  &.provider_container {
    position: absolute;
    bottom: -6px;
    left: -8px;
  }
`
