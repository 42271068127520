import styled from '@emotion/styled'
import React, { useState } from 'react'
import { useNotifyStore } from 'stores/NotifyStore'
import { Workspace } from 'types'
import { NotificationLimit } from 'types/NotifyInterface'
import { AppStore } from 'utils'
import { Box, BoxProps } from './Box'
import { NotificationIcon } from './UI'
import { truncateText } from 'pages/ai-discover/commonFunction'
import { ImageWithFallback } from './UI/ImageWithFallback'
import MediaUtils from 'utils/MediaUtils'

export const WorkspaceIcon: React.FC<{
  workspace: Workspace
  hideName?: boolean
  style?: React.CSSProperties
  boxProps?: BoxProps
}> = ({ workspace, style, hideName = false, boxProps }) => {
  const [error, setError] = useState(false)

  const { notificationCount } = useNotifyStore((s) => ({
    notificationCount:
      s.allWorkspacesNotifications?.filter((n) => n.id === AppStore.getWorkspaceId())?.[0]?.notificationLength ?? 0,
  }))

  return (
    <WorkspaceIconBox
      row={!hideName}
      center
      style={{ flexDirection: hideName ? 'column' : 'row', alignItems: 'center', color: AppStore.theme.o.black }}
    >
      <ImageWithFallback
        fallbackSrc={MediaUtils.workspacePlaceholder}
        style={{
          borderRadius: 35,
          display: error ? 'none' : 'unset',
          ...style,
        }}
        width={35}
        height={35}
        src={workspace?.image?.presignedUrl ?? ''}
      />

      {!hideName ? (
        <>
          <WorkspaceTextContainer>
            <WorkspaceText>{truncateText(workspace?.name, 18)}</WorkspaceText>
          </WorkspaceTextContainer>
        </>
      ) : notificationCount > 0 ? (
        <NotificationIcon
          style={{ position: 'absolute', bottom: '-10px' }}
          count={notificationCount}
          limit={NotificationLimit.DEFAULT}
        />
      ) : null}
    </WorkspaceIconBox>
  )
}

const WorkspaceTextContainer = styled.div`
  max-width: 170px; // Imposta una larghezza massima appropriata
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
const WorkspaceIconBox = styled(Box)`
  position: relative;
`

const WorkspaceText = styled.b`
  margin-left: 20px;
`
