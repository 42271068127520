import { SocialType } from 'api/Utils'
import { subscribeWithSelector } from 'zustand/middleware'
import {
  AnalyticsDate,
  IReport,
  IReportConfig,
  ISelectableSocial,
  TActiveReportFilter,
  TActiveReportList,
} from 'types/AnalyticsInferfaces'
import { create } from 'zustand'
import { getReport } from 'utils/AnalyticsUtils'

interface AnalyticsStore {
  activeReportList: TActiveReportList | null
  setActiveReportList: (activeReportList: TActiveReportList | null) => void

  reportList: IReport[] | [] | null
  setReportList: (reportList: IReport[] | []) => void

  filteredReportList: IReport[] | [] | null
  setFilteredReportList: (filteredReportList: IReport[] | [] | null) => void

  activeReportFilter: TActiveReportFilter | null
  setActiveReportFilter: (activeReportFilter: TActiveReportFilter) => void

  selectedSocial: SocialType
  setSelectedSocial: (selectedSocial: SocialType) => void

  selectedAccount: ISelectableSocial
  setSelectedAccount: (selectedAccount: ISelectableSocial) => void

  /* -------------- PREVIEW REPORT */
  reportPreview: IReportConfig | null
  setReportPreview: (previewReport: IReportConfig | null) => void

  reportPreviewName: string
  onSetReportName: (newReportName: string) => void

  selectedDatesReports: AnalyticsDate | null
  setSelectedDatesReports: (selectedDatesReports: AnalyticsDate | null) => void

  isReportUpdating: boolean | null
  setIsReportUpdating: (isReportUpdating: boolean | null) => void

  reportUpdateDate: Date | undefined
  setReportUpdateDate: (reportUpdateDate: Date | undefined) => void

  compareDatesReports: AnalyticsDate | null
  setCompareDatesReports: (selectedDatesReports: AnalyticsDate | null) => void

  compareDatesEnabled: boolean
  setCompareDatesEnabled: (compareDatesEnabled: boolean) => void

  clearStore: () => void

  refreshReport: (reportId: string) => void
}

export const useAnalyticsStore = create(
  subscribeWithSelector<AnalyticsStore>((set) => ({
    activeReportList: null,
    setActiveReportList: (newActiveReportList: TActiveReportList | null) => {
      set({ activeReportList: newActiveReportList })
    },

    reportList: null,
    setReportList: (newReportList: IReport[]) => {
      set({ reportList: newReportList })
    },

    filteredReportList: null,
    setFilteredReportList: (newFilteredReportList: IReport[] | null) => {
      set({ filteredReportList: newFilteredReportList })
    },

    activeReportFilter: null,
    setActiveReportFilter: (newActiveReportFilter: TActiveReportFilter) => {
      set({ activeReportFilter: newActiveReportFilter })
    },

    selectedSocial: SocialType.instagram,
    setSelectedSocial: (newSelectedSocial: SocialType) => {
      set({ selectedSocial: newSelectedSocial })
    },

    selectedAccount: {},
    setSelectedAccount: (newSelectedAccount: ISelectableSocial) => {
      set({ selectedAccount: newSelectedAccount })
    },

    /* -------------- PREVIEW REPORT */
    reportPreview: null,
    setReportPreview: (Report: IReportConfig | null) => {
      if (Report?.toString() !== useAnalyticsStore.getState().reportPreview?.toString()) set({ reportPreview: Report })
    },

    reportPreviewName: '',
    onSetReportName: (newReportName: string) => {
      set({ reportPreviewName: newReportName })
    },

    selectedDatesReports: null,
    setSelectedDatesReports: (selectedDatesReports: AnalyticsDate | null) => {
      set({
        selectedDatesReports: selectedDatesReports,
      })
    },

    isReportUpdating: null,
    setIsReportUpdating: (isReportUpdating: boolean | null) => {
      set({ isReportUpdating: isReportUpdating })
    },

    reportUpdateDate: undefined,
    setReportUpdateDate: (reportUpdateDate: Date | undefined) => {
      set({ reportUpdateDate: reportUpdateDate })
    },

    compareDatesReports: null,
    setCompareDatesReports: (compareDatesReports: AnalyticsDate | null) => {
      set({ compareDatesReports: compareDatesReports })
    },

    compareDatesEnabled: true,
    setCompareDatesEnabled: (compareDatesEnabled: boolean) => {
      set({ compareDatesEnabled: compareDatesEnabled })
    },

    refreshReport: async (reportId: string) => {
      // alert('refreshReport')
      const idReport = reportId
      const addReport: any = await getReport(idReport)
      // console.log('addReport', addReport)
      set({ reportPreviewName: addReport.info.reportName!, reportPreview: addReport })
    },

    clearStore: () => {
      set({
        activeReportList: null,
        reportList: null,
        filteredReportList: null,
        activeReportFilter: null,
        selectedSocial: SocialType.instagram,
        selectedAccount: {},
        reportPreview: null,
        reportPreviewName: '',
        selectedDatesReports: null,
        isReportUpdating: null,
        reportUpdateDate: undefined,
        compareDatesReports: null,
        compareDatesEnabled: true,
      })
    },
  }))
)
