import Medias from 'api/Medias'
import { Tracker } from 'api/Tracker'
import Utils from 'api/Utils'
import { useAiGenerationStore } from 'stores/AiGenerationStore'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { IPostData } from 'types/PostInterface'
import { T, getHumanReadableDate, showError, showInfo, showSuccess } from 'utils'
import AppStore from 'utils/AppStore'
import { OWNER_COPIES_USED, getWorkspaceOwnerData } from 'utils/Constants/LimitUserConstants'
import { TRACKEREVENTS, getAiCaptionGeneratedData } from 'utils/eventsTracker'
import { MixpanelGeneratedCaption } from 'utils/eventsTracker/aiSection/aiSectionInterfaces'
import { getActiveData, updateActive } from './Post'

export const resetCurrentGenerationState = () => {
  useCreatePostStore.setState({
    currentGenerationState: {
      brandvoice: false,
      neutro: false,
      link: false,
    },
  })
}

export function checkGeneratedText(text: string) {
  if (text.startsWith(':')) {
    return text.slice(1)
  } else return text
}

export const convertBase64ToBlob = async (base64Image: string, extension?: string) => {
  const ext = extension ? extension : 'jpeg'
  const base64Response = await fetch(`data:image/${ext};base64,${base64Image}`)
  const blob = await base64Response.blob()
  return blob
}

export const createFormData = async (Url: string) => {
  try {
    const owner = AppStore.workspace.user
    const res64 = (await Utils.getBlobData(Url)) as any
    const blob = await convertBase64ToBlob(res64.data)
    const formData = new FormData()
    formData.append('file', blob, `${owner}_image-generated.jpg`)
    formData.append('name', `${owner}_image-generated.jpg`)
    formData.append('path', `${AppStore.workspaceId}/`)
    formData.append('type', 'image/jpeg')
    const media: any = await Medias.createFile(formData)

    const url = await Medias.getUrl(media?.data)
    media.data.url = url?.data
    return media
  } catch (e) {
    console.error(e)
  }
}

/**
 * Funzione per tradurre i testi di Brand Voice e Generazione di testo
 * @param {string[]} texts Stringa con i testi da tradurre
 * @param outputLanguage Lingua a cui tradurre
 * @returns
 */
export const translateText = async (texts: string[], outputLanguage: string) => {
  // // Traduzione dei risultati
  const traslatedResults = (await Utils.translate(texts, outputLanguage)) as any
  const detectedLanguage: string[] = ['', '', '']

  const newText: string[] = texts.map((e, index) => traslatedResults.data.translations[index].text)

  const detectedLanguageCall: string[] = texts.map(
    (e, index) => traslatedResults.data.translations[index].detected_source_language
  )

  return newText
}

export const generateParameters = (selLength, selObjective, selTone) => {
  const socialLength = selLength === 0 ? 'x' : selLength === 1 ? 'Instagram/Facebook' : 'LinkedIn'

  let objectives: string[] = []

  if (socialLength === 'x') {
    objectives = ['Tweet', 'Contest', 'Product / Event', 'Quotes']
  } else if (socialLength === 'Instagram/Facebook') {
    objectives = [
      'Discount / Promotion',
      'Seasonal / Holidays',
      'Contest / Giveway',
      'Highlight Product / Service',
      'Tips / Userful info',
    ]
  } else if (socialLength === 'LinkedIn') {
    objectives = ['Informative', 'Educative', 'Reflexive', 'Promotion']
  }

  const selectedObj = objectives[selObjective]

  let voiceTone: string[] = []

  // Twitter
  if (selectedObj === 'Tweet') {
    voiceTone = ['Humorous / Satirical', 'Interrogative', 'Inspiration / Motivaion', 'News']
  } else if (selectedObj === 'Contest') {
    voiceTone = ['Engaging', 'Creative', 'Competitive', 'Friendly']
  } else if (selectedObj === 'Product / Event') {
    voiceTone = ['Explicative', 'Informative', 'Formal', 'Creative']
  } else if (selectedObj === 'Quotes') {
    voiceTone = ['Inspiration', 'Funny', 'Motivational', 'Educational']
  }

  // Instagram/Facebook
  else if (selectedObj === 'Discount / Promotion') {
    voiceTone = ['Inspiring', 'Funny', 'Descriptive', 'Professional']
  } else if (selectedObj === 'Seasonal / Holidays') {
    voiceTone = ['Enthusiastic / Playful', 'Festive / Celebrative', 'Educational / Informative', 'Gratitude']
  } else if (selectedObj === 'Contest / Giveway') {
    voiceTone = ['Enthusiastic', 'Engaging', 'Funny', 'Exlusive']
  } else if (selectedObj === 'Highlight Product / Service') {
    voiceTone = ['Inspiration', 'Funny', 'Descriptive', 'Professional']
  } else if (selectedObj === 'Tips / Userful info') {
    voiceTone = ['Educational / Informative', 'Friendly', 'Educative', 'Professional']
  }

  // LinkedIn
  else if (selectedObj === 'Informative') {
    voiceTone = ['Institutional', 'Objective', 'Techniciam', 'Thoughtful']
  } else if (selectedObj === 'Educative') {
    voiceTone = ['Academic', 'Informative', 'Motivational', 'Technician']
  } else if (selectedObj === 'Reflexive') {
    voiceTone = ['Inspirational', 'Authorative', 'Metaphorical', 'Motivational']
  } else if (selectedObj === 'Promotion') {
    voiceTone = ['Professional', 'Explicative', 'Persuasive', 'Emotional']
  }

  return {
    socialLength,
    objectives: selectedObj,
    voiceTone: voiceTone[selTone],
  }
}

export const generateByBrandVoice = async (text, outputLanguage, captionSettings: MixpanelGeneratedCaption) => {
  try {
    // Verifica del permesso 'ai_copy_generator'
    const check = await AppStore.checkPermission('ai_copy_generator')
    if (!check) return showInfo(`${T.error.noPermissionTo} ${T.error.generateText}`)

    // Verifica se l'utente è validato ed ha copie disponibili
    if (!AppStore.userValidated && OWNER_COPIES_USED >= 3) return showError(T.error.youFinishedYourTrial)
    // ---

    const { generatedByTextResults, setGeneratedByTextResults } = useAiGenerationStore.getState()

    // Ottengo workspace id e info su account attivo
    const { activePost, requestTextGeneration } = useCreatePostStore.getState()

    const workspaceId = AppStore.workspaceId

    // ---------- INIZIO GENERAZIONE
    showSuccess(T.success.generationInProgress)

    // Avvio richiesta di generazione
    if ('info' in activePost) {
      const social = activePost?.info.account

      const res = await requestTextGeneration('brandvoice', {
        prompt: text,
        workspaceId: workspaceId ?? '',
        socialAccountId: social.socialId ?? '',
        provider: social.provider.replace('x', 'twitter'),
        environment: process.env.REACT_APP_ENVIRONMENT,
      })

      if (res.error) return showError(T.error.serverError)
      const responses = res.responses
      // Mappa le generazioni nuove
      const names = responses.map(
        (e) => `AI-${getHumanReadableDate(new Date().getTime())}-${Utils.generateRandomUIDKey()}`
      )

      const outputLang = outputLanguage[0]

      // Traduci il testo
      const translationResults = await translateText(responses, outputLang)

      const newGenerations = res.responses.map((generation, i) => {
        return {
          name: names[i],
          text: generation,
          like: false,
          isNotLike: false,
          saved: false,
          modelId: '',
          generatedTexts: translationResults[i],
          feedbackNotes: '',
          feedbackId: undefined,
          isBrandVoice: true,
        }
      })

      // To detect the source language
      const ogg = (await Utils.translate([text], 'it')) as any

      // la lingua da cui è stato tradotto
      const detectedLangInput = await ogg.data.translations[0].detected_source_language

      // Ritorna il nome della lingua in cui è stato tradotto (in inglese)
      const inputLanguage = new Intl.DisplayNames(['en'], { type: 'language' }).of(detectedLangInput) ?? ''

      // Aggiorno il numero di copie utilizzate

      // Setta le nuove generazioni insieme a quelle vecchie
      setGeneratedByTextResults([...newGenerations, ...generatedByTextResults])

      captionSettings.ai_caption_input_language = inputLanguage
      Tracker.trackEvent(TRACKEREVENTS.AI_CAPTION_GENERATED, captionSettings)

      const result = await getWorkspaceOwnerData({})

      const { generationsQueue, setGenerationsQueue } = useAiGenerationStore.getState()
      setGenerationsQueue(generationsQueue - 1)
    }
  } catch (e) {
    resetCurrentGenerationState()
    console.error(e)
  }
}

export const generateByText = async (
  selectedObjective,
  selectTone,
  selectedLegth,
  argument,
  outputLanguage,
  captionSettings: MixpanelGeneratedCaption
) => {
  try {
    // Verifica del permesso 'ai_copy_generator'
    const check = await AppStore.checkPermission('ai_copy_generator')
    if (!check) return showInfo(`${T.error.noPermissionTo} ${T.error.generateText}`)

    // Zustand
    const { generatedByTextResults, setGeneratedByTextResults } = useAiGenerationStore.getState()
    const { requestTextGeneration } = useCreatePostStore.getState()

    // Verifica se l'utente è validato ed ha copie disponibili
    if (!AppStore.userValidated && OWNER_COPIES_USED >= 3) return showError(T.error.youFinishedYourTrial)
    // ---

    // Controlla se ha selezionato un'obiettivo
    if (selectedObjective === -1) return showError(T.error.selectObjectiveFirst)

    // Controlla se ha selezionato un tono di voce
    if (selectTone === -1) return showError(T.error.selectAToneOfVoice)

    // Controlla se ha scritto qualcosa
    if (!argument) return showError(T.error.addTextToGenerate)

    //Controlla se ha scritto un numero minimo di caratteri
    if (argument.split(' ').filter((a) => a !== '').length < 4) return showInfo(T.info.insert_at_least_4_words)

    // ---------- INIZIO GENERAZIONE
    showSuccess(T.success.generationInProgress)

    // ----------------------------------------
    // TRADUZIONE PER AIRTABLE
    const ogg = (await Utils.translate([argument], 'it')) as any

    // Il argomento che è stato tradotto
    const argumentTranslated = await ogg.data.translations[0].text

    // la lingua da cui è stato tradotto
    const detectedLangInput = await ogg.data.translations[0].detected_source_language
    // ----------------------------------------

    // Ritorna il nome della lingua in cui è stato tradotto (in inglese)
    const inputLanguage = new Intl.DisplayNames(['en'], { type: 'language' }).of(detectedLangInput) ?? ''

    // Richiedo generazioni
    const results: any = await requestTextGeneration('neutro', {
      argument: argument,
      lenght: selectedLegth,
      type: selectedObjective,
      voicetone: selectTone,
    })

    if (results.data) return showError(T.error.serverError)

    // Per AIRTABLE
    const copyResults = JSON.parse(JSON.stringify(results))

    // Modifico le generazioni
    results?.forEach((element, index) => {
      results[index] = results[index].trim()
    })
    copyResults?.forEach((element, index) => {
      copyResults[index] = copyResults[index].trim()
    })

    const outputLang = outputLanguage[0]

    const translationResults = await translateText(copyResults, outputLang)

    // Manda i risultati ad Airtable
    await Utils.textGeneratedSave({
      finalText: undefined,
      promptText: argument,
      arryText: translationResults,
      argumentType: 'none',
      fromLanguage: detectedLangInput,
      toLanguage: outputLang,
      argumentTrasnlated: argumentTranslated,
      copyResult: copyResults,
      socialAccont: undefined,
      media: undefined,
      date: undefined,
    })

    // Setta l'ultimo testo tradotto sul appStore per publish  per chiamata poi ad airtable(???)
    AppStore.lastTextPropt = argumentTranslated

    // Genera dei nomi random per le generazioni
    const names = results.map((e) => `AI-${getHumanReadableDate(new Date().getTime())}-${Utils.generateRandomUIDKey()}`)

    // Per airtable
    const { socialLength, objectives, voiceTone } = generateParameters(selectedLegth, selectedObjective, selectTone)

    // Mappa le generazioni nuove
    const newGenerations = results.map((generation, i) => {
      return {
        name: names[i],
        text: argument,
        like: false,
        isNotLike: false,
        saved: false,
        modelId: '',
        socialAcc: socialLength,
        ArgumentType: objectives,
        toneofVoice: voiceTone,
        From: detectedLangInput,
        toLanguage: outputLang,
        generatedTexts: translationResults[i],
        feedbackNotes: '',
        feedbackId: undefined,
      }
    })

    // Setta le nuove generazioni insieme a quelle vecchie
    setGeneratedByTextResults([...newGenerations, ...generatedByTextResults])

    captionSettings.ai_caption_input_language = inputLanguage
    Tracker.trackEvent(TRACKEREVENTS.AI_CAPTION_GENERATED, getAiCaptionGeneratedData(captionSettings))

    // Aggiorno il numero di copie utilizzato
    const res = await getWorkspaceOwnerData({})

    const { generationsQueue, setGenerationsQueue } = useAiGenerationStore.getState()
    setGenerationsQueue(generationsQueue - 1)
  } catch (e) {
    resetCurrentGenerationState()
    console.error(e)
  }
}

const includePartially = (text: string, array: string[]) => {
  return array.some((e) => text.toLowerCase().includes(e.toLowerCase()))
}

export const generateByLink = async (params: {
  selectedObjective: number
  selectedTone: number
  selectedLength: number
  text: string
  outputLanguage: any
}) => {
  try {
    // Verifica del permesso 'ai_copy_generator'
    const check = await AppStore.checkPermission('ai_copy_generator')
    if (!check) return showInfo(`${T.error.noPermissionTo} ${T.error.generateText}`)

    const { selectedObjective, selectedTone, selectedLength, outputLanguage } = params
    const argument = params.text

    // Zustand
    const { generatedByLinkResults, setGeneratedByLinkResults } = useAiGenerationStore.getState()
    if (!AppStore.userValidated && OWNER_COPIES_USED >= 3) return showError(T.error.youFinishedYourTrial)

    if (selectedObjective === -1) return showError(T.error.selectObjectiveFirst)

    if (selectedTone === -1) return showError(T.error.selectAToneOfVoice)

    const social = selectedLength === 0 ? 'x' : selectedLength === 1 ? 'Instagram' : 'LinkedIn'
    showInfo(T.info.gettingDataFromSite)

    // Prende img e la mette dentro media

    const metaData = await Utils.getMetaTags({ text: argument })

    const { canImportImageFromLink, whitelistedSiteForImportImage } = AppStore.workspace.settings.linkGeneration

    // const whitelistedSiteForImportImage= ['https://www.instagram.com/', 'https://www.facebook.com/']

    if ((canImportImageFromLink || includePartially(argument, whitelistedSiteForImportImage)) && metaData?.ogImage) {
      const media = await createFormData(metaData?.ogImage.ogUrl)

      const activeData = getActiveData() as IPostData

      updateActive({ medias: [...activeData.medias, media.data] })
    }

    // Generate text by link
    const siteData: any = await Utils.getSiteData(
      argument,
      social,
      selectedLength,
      selectedObjective,
      selectedTone,
      outputLanguage[0]
    )

    if (siteData === null || siteData === undefined) return showError(T.error.retrieveDataFromSite)

    // Genera nomi alle generazioni
    const names = siteData.post.map(
      (e) => `AI-${getHumanReadableDate(new Date().getTime())}-${Utils.generateRandomUIDKey()}`
    )

    const { socialLength, objectives, voiceTone } = generateParameters(selectedLength, selectedObjective, selectedTone)

    // Mappa le generazioni nuove
    const newGenerations = siteData.post.map((text, i) => {
      text = text.replace(/%23/g, '#')
      text = text.replace(/%0/g, '')
      return {
        name: names[i],
        text: argument,
        like: false,
        isNotLike: false,
        saved: false,
        modelId: '',
        socialAcc: socialLength,
        ArgumentType: objectives,
        toneofVoice: voiceTone,
        From: 'Link',
        toLanguage: outputLanguage[0],
        generatedTexts: text,
        feedbackNotes: '',
        feedbackId: undefined,
      }
    })

    // Setta le nuove generazioni insieme a quelle vecchie
    setGeneratedByLinkResults([...newGenerations, ...generatedByLinkResults])

    // Inizia generazione
    showSuccess(T.success.postGenerated)

    // Aggiorno il numero di copie utilizzate
    const res = await getWorkspaceOwnerData({})

    const { generationsQueue, setGenerationsQueue } = useAiGenerationStore.getState()
    setGenerationsQueue(generationsQueue - 1)
  } catch (e) {
    resetCurrentGenerationState()
    console.error(e)
  }
}
