/* ******************* TYPE ******************* */
import styled from '@emotion/styled'
import Workspaces from 'api/Workspaces'

import { Box, Button, Input, MultiToggleButton, Text, ZoomedContainer } from 'components'
import { TextArea } from 'components/TextArea'

import { useState } from 'react'
import { AppStore, T, showError, showSuccess } from 'utils'
import { addItem } from 'utils/PostUtils/TemplatePost'
import { Tracker } from 'api/Tracker'
import { TRACKEREVENTS } from 'utils/eventsTracker'
/* ******************* INTERFACE ******************* */

interface Props {}

const CreateHashtagGroup = ({}: Props) => {
  const [modalName, setModalName] = useState('')
  const [modalText, setModalText] = useState('')
  const [type, setType] = useState(T.postPage.caption)

  const checkValidation = () => {
    if (modalName === '') return showError('modalName vuoto')
    if (modalText === '') return showError('modalText vuoto')
    if (type === '') return showError('type vuoto')
  }

  const onSavaHashtagGroup = async () => {
    checkValidation()
    const res = await Workspaces.addHashtagToWorkspace(
      {
        text: modalText,
        name: modalName,
        type: type,
      },
      AppStore.workspace._id ?? ''
    )
    addItem(res.pop() as any)

    showSuccess('Model Group creato con successo')
    AppStore.closeAllModal()
    Tracker.trackEvent(TRACKEREVENTS.POST_TEMPLATE_COMPLETED, { post_template_type: type })
  }

  return (
    <>
      <ZoomedContainer p={40} bgPrimaryColor zoom={AppStore.zoom} height={411} ml={16} mr={16}>
        <Box row mb={40}>
          <Box style={{ minWidth: 425 }} mr={32}>
            <Text weight={700}>{T.postPage.modalName}</Text>
            <InputText
              style={{ minHeight: 56, marginTop: 8, padding: 200 }}
              placeholder={T.postPage.modalName}
              onChange={(e) => setModalName(e)}
            />
          </Box>
          <Box>
            <Text weight={700}>{T.postPage.modeltype} </Text>
            <MultiToggleButton
              style={{ marginTop: 8 }}
              values={[T.postPage.caption, T.postPage.hashtag]}
              onChange={(e) => setType(e)}
            />
          </Box>
        </Box>
        <Text weight={700}>{T.postPage.modalText}</Text>
        <CustomTextArea
          style={{ marginTop: 8 }}
          bgcolor={AppStore.theme.o.lightGrey}
          bgcoloractive={AppStore.theme.o.lightGrey}
          defaultValue={''}
          onChange={(e) => setModalText(e)}
          height={179}
          width={675}
        />
      </ZoomedContainer>
      <Box
        center
        style={{
          position: 'absolute',
          bottom: -96,
          zIndex: 9999999,
          borderRadius: 20,
        }}
        height={100}
        width={'100%'}
      >
        <Button onClick={() => onSavaHashtagGroup()} style={{ minWidth: 253, borderRadius: 14, maxHeight: 44 }} center>
          {T.settings.confirm}
        </Button>
      </Box>
    </>
  )
}

export default CreateHashtagGroup

const InputText = styled(Input)`
  border: none !important;
  max-height: 90px;
  padding: 16px !important;
  min-height: 56px !important;
`

const CustomTextArea = styled(TextArea)`
  :hover {
    border: 1.5px solid ${() => AppStore.theme.o.lightBlue}!important;
    background-color: ${() => AppStore.theme.o.background}!important;
  }
`
