/* ------------------- IMPORTS  */
import { FilterBar } from 'components/FilterSideBar/FilterBar'
import { SidebarStructure } from 'components/UI/SidebarStructure'
import { useAnalyticsStore } from 'stores/AnalyticsStore'
import { IReport, TActiveReportList, TSection } from 'types/AnalyticsInferfaces'
import { InputSearch } from 'components/InputSearch'
import { T } from 'utils'
import { useEffect, useState } from 'react'
import { FilterElementData } from 'components/FilterSideBar/FilterElementData'
import { useNavigate } from 'react-router-dom'
import { SocialPicture } from 'components/UI/SocialPicture'
import { socialFilters } from './config/AnalyticsSidebarConfig'
import { NoResultElement } from 'components/FilterSideBar/NoResultElement'

/* ------------------- INTERFACES  */
interface Props {
  section: TSection
}

const AnalyticsSidebar = ({ section }: Props) => {
  /* ------------------- ROUTER  */
  const navigate = useNavigate()

  /* ------------------- STATE  */
  const [reportListActive, setReportListActive] = useState<TActiveReportList | null>(null)
  const [reportsList, setReportsList] = useState<IReport[] | null>([])
  const [reportListFiltered, setReportListFiltered] = useState<IReport[] | null>([])
  const [oldData, setOldData] = useState<JSX.Element[]>([])

  /* ------------------- ZUSTAND  */
  const { setActiveReportList, setFilteredReportList } = useAnalyticsStore.getState()

  const unsubActiveReportList = useAnalyticsStore.subscribe((state) => {
    state.activeReportList !== reportListActive && setReportListActive(state.activeReportList!)
  })

  const unsubReportList = useAnalyticsStore.subscribe((state) => {
    state.reportList !== reportsList && setReportsList(state.reportList)
  })

  const unsubFilteredReportList = useAnalyticsStore.subscribe((state) => {
    state.filteredReportList !== reportListFiltered && setReportListFiltered(state.filteredReportList)
  })

  /* ------------------- VARIABLE RENDER  */
  /**
   * Checks for reportData, if its length it's 0 returns NoResultElement, the data otherwise
   */
  const reportData: JSX.Element[] =
    reportListFiltered?.length !== 0
      ? (reportListFiltered || []).map((report: IReport) => (
          <FilterElementData
            name={report.info.reportName!}
            icon={getIcons(report)}
            action={() => navigate(`/analytics/report/${report._id}`)}
          />
        ))
      : [<NoResultElement message={T.analytics.noReports} />]

  /* ------------------- METHODS  */
  const onChangeActiveReportList = (key: TActiveReportList) => {
    setActiveReportList(key)
    if (reportData !== oldData) setOldData(reportData!)
  }

  const searchReports = (str: string) => {
    if (reportListActive !== 'all' || reportListActive !== null) {
      const filteredReports = reportsList?.filter((report) => {
        return report.social.some((account) => reportListActive?.includes(account.provider))
      })
      const newReportList = filteredReports!.filter((report) =>
        report.info.reportName!.toLowerCase().includes(str.toLowerCase())
      )
      setFilteredReportList(newReportList)

      if (str === '' && filteredReports) {
        setFilteredReportList(filteredReports)
      }
    }

    if (reportListActive === null || reportListActive === 'all') {
      const newReportList = reportsList?.filter((report) =>
        report.info.reportName!.toLowerCase().includes(str.toLowerCase())
      )
      setFilteredReportList(newReportList ?? [])

      if (str === '') {
        setFilteredReportList(reportsList ?? [])
      }
    }
  }

  function getIcons(report: IReport) {
    const lastThreeAccounts = report?.social?.slice(-2)
    return lastThreeAccounts?.map((account) => {
      return (
        <SocialPicture
          picture={account.picture}
          provider={account.provider as any}
          frame
          dimensionProvider={20}
          dimensionPicture={28}
        />
      )
    })
  }

  /* ------------------- USEEFFECT  */
  useEffect(() => {
    return () => {
      unsubActiveReportList()
      unsubReportList()
      unsubFilteredReportList()
    }
  }, [])

  /* ------------------- RENDER CONTENT  */
  const sidebar = {
    analytics: (
      <SidebarStructure
        mt={82}
        showSidebar={true}
        header={
          <InputSearch
            title={T.analyticsPage.myReports}
            placeholder={T.postPage.search}
            search={searchReports}
            totalEntries={reportListFiltered?.length}
          />
        }
        content={
          <FilterBar
            filters={socialFilters}
            selectedFilter={reportListActive}
            action={onChangeActiveReportList}
            oldData={oldData}
            data={reportData}
          />
        }
      />
    ),
    createReport: <SidebarStructure showSidebar={false} />,
    report: <SidebarStructure showSidebar={false} />,
  }
  return sidebar[section]
}

export { AnalyticsSidebar }
