/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Text } from 'components'
import { AppStore } from 'utils'
import { CalendarNavigation } from './CalendarNavigation'
import { useCalendarStore } from 'stores/CalendarStore'
import { formatMonth } from 'api/Utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {}

export const LowerSectionCalendarHeader = ({}: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */
  const { year, month } = useCalendarStore((s) => ({
    year: s.year,
    month: s.month,
  }))

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <LowerSectionCalendarHeaderContainer>
      <CalendarTitle>
        {formatMonth(month)} {year}
      </CalendarTitle>

      <CalendarNavigation />
    </LowerSectionCalendarHeaderContainer>
  )
}

const LowerSectionCalendarHeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
`

const CalendarTitle = styled(Text)`
  color: ${() => AppStore.theme.o.black};
  text-align: center;
  font-family: 'Aktiv Grotesk';
  font-size: 24px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
  letter-spacing: 0.24px;
`
