import { Box, Icons, SocialButtonWithImage, Text } from 'components'
import { SocialAccount } from 'types'
import { AppStore, T, getSizeZoomed } from 'utils'
import { Days } from './Days'
import { TimePicker } from 'antd'
import moment from 'moment'
import styled from '@emotion/styled'
import { modifyModal } from 'utils/SettingPageConfiguration'

interface Props {
  account: SocialAccount
  key: any
  onClick?: (account: SocialAccount) => void
  onDelete?: (account: SocialAccount) => Promise<void>
  style?: any
}

// SAREBBE IL SOCIAL ACCOUNT ROW MA SENSA I BOX E ICONE DEI VARI SOCIAL
export const SpecialSocialAccountRow = ({ account, key, onClick, onDelete, style }: Props) => {
  return (
    <Box
      vcenter
      row
      width={'100%'}
      key={key}
      pv={10}
      ph={20}
      onClick={onClick}
      style={{ border: `1px solid ${AppStore.theme.o.grey}`, ...style }}
    >
      {/* STAMPA  NOME  */}
      <SocialButtonWithImage socialAccount={account} />
      <Text style={{ marginLeft: 10, marginRight: 10, flex: 1 }}>
        <b>{account.name}</b>
      </Text>
      <Text fontSize={12} weight={400} color={AppStore.theme.o.darkGrey}>
        {T.socialProfileType[account?.profileType ?? 'profile']}
      </Text>
      <Days />

      <Box vcenter justify={'space-evenly'} flex row ml={18} mr={18}>
        <IconContainer
          row
          hideBoxShadowOnHover
          center
          style={{
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 24,
            paddingRight: 24,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <Text onClick={modifyModal}>{T.settings.modify}</Text>
          <Icons.edit />
        </IconContainer>
      </Box>
    </Box>
  )
}

const IconContainer = styled(Box)`
  border-radius: 14px;
  transition: background-color 0.5s ease-in-out;

  :hover {
    background-color: ${() => AppStore.theme.o.background};
  }
`
