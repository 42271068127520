/* ******************* TYPE ******************* */
import styled from '@emotion/styled'
import Workspaces from 'api/Workspaces'

import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { Spacer } from 'components/Spacer'
import { Text } from 'components/Text'
import { TextArea } from 'components/TextArea'
import { ZoomedContainer } from 'components/ZoomedContainer'
import React from 'react'
import { useAppStore } from 'stores/AppStore'
import { T } from 'translation/i18n'
import { IItem } from 'types'
import { AppStore, showSuccess } from 'utils'
import { updateItem } from 'utils/PostUtils/TemplatePost'

/* ******************* INTERFACE ******************* */

interface Props {
  item: IItem
  index: number
}

const ZoomedModal = ({ item, index }: Props) => {
  const [text, setText] = React.useState(item.text)
  const onSave = () => {
    const { activeWorkspace, setActiveWorkspace } = useAppStore.getState()

    const newItem: IItem = { ...item, text }
    updateItem(item, newItem)

    Workspaces.modifyModelToWorkspace(AppStore.workspace._id ?? '', item._id, { text })
    showSuccess('modal updated')
    AppStore.closeModal('zoom')
  }
  return (
    <Box style={{ position: 'relative' }}>
      <ZoomedContainer zoom={AppStore.zoom} p={40} height={580} style={{ position: 'relative' }}>
        <Box row style={{ minWidth: 400 }} center>
          <Text weight={700} style={{ fontSize: 24, fontFamily: 'Aktiv Grotesk' }}>
            {item.name}
          </Text>
        </Box>
        <Spacer />
        <Spacer />
        <Box scroll height={400}>
          <FlexTextArea
            style={{ textAlign: 'left', color: 'black', fontSize: 18, maxWidth: 900 }}
            id="text-area-create-post"
            borderwidth={'2px'}
            defaultValue={item.text}
            bgcolor={AppStore.theme.o.lightGrey}
            bgcoloractive={AppStore.theme.o.lightGrey}
            height={800}
            onChange={(update) => {
              setText(update)
            }}
          >
            {item.text}
          </FlexTextArea>
        </Box>
      </ZoomedContainer>
      <ExternalBUtton mt={10}>
        <Button center style={{ minWidth: 253, borderRadius: 14, maxHeight: 44 }} onClick={() => onSave()}>
          {T.settings.confirmModify}
        </Button>
      </ExternalBUtton>
    </Box>
  )
}

export default ZoomedModal

const FlexTextArea = styled(TextArea)`
  min-height: 88px;
  line-height: 24pt !important;
  max-height: 264px !important;
`

const ExternalBUtton = styled(Box)`
  position: absolute;
  bottom: -96px;
  z-index: 999999;
  border-radius: 20;
  height: 100px;
  width: 120px;
  right: 51%;
  bottom: -119px;
`
