import { IFilterDropdownData, TFilterDropdownType } from 'types'
import { IReport } from 'types/AnalyticsInferfaces'
import { T } from 'utils'

const filterTitleAnalytics = {
  null: T.aiDiscoverPage.sidebar.moreRecent,
  more_recent: T.aiDiscoverPage.sidebar.moreRecent,
  less_recent: T.aiDiscoverPage.sidebar.lessRecent,
  a_z: 'A - Z',
  z_a: 'Z - A',
}

const filterDataAnalytics: IFilterDropdownData[] = [
  {
    type: 'more_recent',
    variableToSort: 'updatedAt',
  },
  {
    type: 'less_recent',
    variableToSort: 'updatedAt',
  },
  {
    type: 'a_z',
    variableToSort: 'reportName',
  },
  {
    type: 'z_a',
    variableToSort: 'reportName',
  },
]

const filteredListAnalytics = (list: IReport[], type: TFilterDropdownType, variableToSort: string): IReport[] => {
  switch (type) {
    case 'more_recent':
      return list.sort(
        (a, b) => new Date(b.info[variableToSort]).getTime() - new Date(a.info[variableToSort]).getTime()
      )

    case 'less_recent':
      return list.sort(
        (a, b) => new Date(a.info[variableToSort]).getTime() - new Date(b.info[variableToSort]).getTime()
      )

    case 'a_z':
      return list.sort((a, b) => a.info[variableToSort].localeCompare(b.info[variableToSort]))

    case 'z_a':
      return list.sort((a, b) => b.info[variableToSort].localeCompare(a.info[variableToSort]))

    default:
      return []
  }
}

export { filterTitleAnalytics, filterDataAnalytics, filteredListAnalytics }
