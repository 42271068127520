/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons, Input } from 'components'
import { AppStore, T } from 'utils'
import { useEffect, useState } from 'react'
import { DEFAULT_LANGUAGE } from 'utils/Constants/Constants'
import { objectiveTreeLink } from './configSettings'
import { LanguageFilter } from '../AiGeneric/LanguageFilter'
import { GenerateButton } from '../AiGeneric/GenerateButton'
import { GenerationTone } from '../AiGeneric/GenerationTone'
import { GenerationObjective } from '../AiGeneric/GenerationObjective'
import { GenerationLength } from '../AiGeneric/GenerationLength'
import { useParams } from 'react-router-dom'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { MixpanelGeneratedCaption } from 'utils/eventsTracker/aiSection/aiSectionInterfaces'
import { objectiveTreeText } from '../AiTextComponents/configSettings'
import { Tracker } from 'api/Tracker'
import { TRACKEREVENTS, getAiCaptionGeneratedData } from 'utils/eventsTracker'

/* ------------------- INTERFACES  */
interface Props {}

export const AiLinkSettings = ({}: Props) => {
  /* ----------------- STATE  */
  const [text, setText] = useState<string>('')
  const [selectedLength, setSelectedLength] = useState<number>(0)
  const [selectedObjective, setSelectedObjective] = useState<number>(-1)
  const [selectedTone, setSelectedTone] = useState<number>(-1)
  const [outputLanguage, setOutputLanguage] = useState(DEFAULT_LANGUAGE)
  const [disabled, setDisabled] = useState<boolean>(true)
  const [captionSettings, setCaptionSettings] = useState<MixpanelGeneratedCaption>({
    ai_caption_source_type: 'URL',
    ai_caption_source: text,
    ai_caption_lenght: '',
    ai_caption_goal: '',
    ai_caption_tone_of_voice: '',
    ai_caption_input_language: '',
    ai_caption_output_language: outputLanguage[1] ?? '',
    ai_caption_brand_voice: false,
  })

  const { requestTextGeneration } = useCreatePostStore.getState()
  /* ----------------- VARIABLES  */
  const configSettings = objectiveTreeLink[selectedLength].objective

  /* ----------------- ROUTER  */
  const { text: urlText } = useParams()

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    setDisabled(!(selectedLength !== -1 && selectedObjective !== -1 && selectedTone !== -1 && text.length > 0))
  }, [selectedLength, selectedObjective, selectedTone, text])

  // Setta il testo in base al link preso dall'url
  useEffect(() => {
    if (urlText) {
      setText(urlText ?? '')
    }
  }, [])

  useEffect(() => {
    setCaptionSettings((prev) => ({
      ...prev,
      ai_caption_lenght: objectiveTreeText[selectedLength]?.id ?? '',
      ai_caption_source: text,
      ai_caption_goal: configSettings[selectedObjective]?.value ?? '',
      ai_caption_tone_of_voice: configSettings[selectedObjective]?.subSection[selectedTone] ?? '',
      ai_caption_output_language: outputLanguage[1],
    }))
  }, [selectedLength, selectedObjective, selectedTone, text, outputLanguage])

  /* ----------------- METHODS  */
  const requestLinkGeneration = async () => {
    const params = { selectedObjective: selectedObjective, selectedTone, selectedLength, text, outputLanguage }
    await requestTextGeneration('link', params)
    Tracker.trackEvent(TRACKEREVENTS.AI_CAPTION_GENERATED, getAiCaptionGeneratedData(captionSettings))
  }

  return (
    <AiLinkSettingsContainer>
      {/* Top Settings section  */}
      <TopSettings>
        <GenerationLength selectedLength={selectedLength} setSelectedLength={setSelectedLength} />

        <GenerationObjective
          selectedLength={selectedLength}
          selectedObjective={selectedObjective}
          setSelectedObjective={setSelectedObjective}
          configSettings={configSettings}
        />

        <GenerationTone
          selectedObjective={selectedObjective}
          selectedTone={selectedTone}
          setSelectedTone={setSelectedTone}
          configSettings={configSettings}
        />
      </TopSettings>

      {/* TextArea section */}
      <TextArea
        style={{
          height: 42,
          border: `1px dotted ${AppStore.theme.o.grey}`,
          borderRadius: 16,
        }}
        placeholder={T.postPage.insertLinkToGenerateText}
        onChange={(e) => {
          setText(e)
        }}
        value={text}
        prefix={<Icons.smallLink fill={AppStore.theme.o.grey} />}
      />

      {/* Bottom Settings section  */}
      <BottomSettings>
        <GenerateBtnContainer>
          {/* GENERATE BTN */}
          <GenerateButton
            type="link"
            key={`generate_btn_link`}
            disabled={disabled}
            aiGenerateFunction={requestLinkGeneration}
          />

          {/* QUESTION MARK */}
          {/* {isDevelopment && <TourGenerateText />} */}
        </GenerateBtnContainer>

        {/* LANGUAGE */}
        <LanguageFilter outputLanguage={outputLanguage} setOutputLanguage={setOutputLanguage} />
      </BottomSettings>
    </AiLinkSettingsContainer>
  )
}

const AiLinkSettingsContainer = styled(Box)`
  display: flex;
  border-radius: none;
  gap: 16px;
`

const TopSettings = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: none;
  height: 94px;
`

const BottomSettings = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const GenerateBtnContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-direction: row;
`

const TextArea = styled(Input)`
  padding: 12px 16px;
  border-radius: 14px;
  border-color: ${() => AppStore.theme.o.darkGrey};
  background-color: ${() => AppStore.theme.o.lightGrey};
  resize: none;

  &::placeholder {
    font-weight: 700;
  }
`
