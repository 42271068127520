import styled from '@emotion/styled'
import { Box, Button } from 'components'
import React, { useState } from 'react'
import { AppStore, T, showSuccess, showWarning } from 'utils'
import InputFlagPhoneNumber from './InputFlagPhoneNumber'
import { IPhoneCountry, IPhoneNumber } from 'types/NotifyInterface'
import { postAddNumber } from './utils/apiCall'
import OtpField from './OtpField'

interface Props {
  inputWidth?: string // default 344px
  onForceUpdate?: () => void
}

const WhatsAppOnModalRequest = ({ inputWidth, onForceUpdate }: Props) => {
  /* ____________________ STATE ____________________ */
  const [phoneNumber, setPhoneNumber] = useState<IPhoneNumber>({ prefix: '', number: '' })
  const [valid, setValid] = useState<boolean>(true)
  const [canSendOtp, setCanSendOtp] = useState<boolean>(false)
  const [openOtp, setOpenOtp] = useState<boolean>(false)

  /* ____________________ METHODS ____________________ */
  /**
   *
   * ## handleChange
   *  - setta lo stato del numero di telefono e se è valido
   *
   *  - richiamo funzione logica e settaggio se il numero è valido in base al prefisso ed al formato del paese
   *
   * @param value string numero con prefisso
   * @param country { name: "italia", dialCode: "39", countryCode: "it", format: "+.. ... ......."}
   *
   * @returns object {prefix: "39", phone: "3346985763"}
   */
  const handleChange = (value: string, country: IPhoneCountry) => {
    const validation = countryCodeVerifyNum(value, country)

    setValid(validation)
    setPhoneNumber({ prefix: country.dialCode, number: value.replace(country.dialCode, '') })
  }

  /**
   * ## countryCodeVerifyNum
   *
   * logica per la verifica se il numero è valido in base al prefisso ed al formato del paese
   *
   * - se è valido setta lo stato di canSendOtp a true altrimenti a false
   *
   * @param num string numero con prefisso
   * @param countryData { name: "italia", dialCode: "39", countryCode: "it", format: "+.. ... ......."}
   * @returns boolean
   */
  const countryCodeVerifyNum = (num: string, countryData: IPhoneCountry) => {
    const { dialCode, format } = countryData

    const formatLength = format.split('.').length - 1
    const numLengthIsValid = num.split('').length === formatLength
    const numPrefixIsValid = num.startsWith(dialCode)

    const validation = numLengthIsValid && numPrefixIsValid

    validation && setCanSendOtp(true)
    !validation && setCanSendOtp(false)

    return validation
  }

  const shakeInvalidInput = () => {
    const input = document.querySelector('.input-container.on-modal') as HTMLElement

    input?.classList.add('shake')

    setTimeout(() => {
      input?.classList.remove('shake')
    }, 300)
  }

  /**
   *
   * @param otp stringa dell'otp string che ritorna dal componente OtpField
   * @param isValid boolean che ritorna dal componente OtpField per sapere se è valido o meno il codice inserito e già verificato tramite api
   */
  const retriveOtp = (otp: string, isValid: boolean) => {
    if (isValid) {
      onForceUpdate && onForceUpdate()
      setOpenOtp(false)
      AppStore.closeModal('whatsapp-number-modal')
    }
  }

  /* ____________________ API ____________________ */
  const onSendWhatsappOtp = async () => {
    if (!canSendOtp) {
      showWarning(T.settings.otpSendError)
      return shakeInvalidInput()
    }

    const sendMsg = await postAddNumber(String(phoneNumber.prefix), String(phoneNumber.number))

    if (sendMsg) {
      setOpenOtp(true)
      showSuccess(T.settings.otpSendSuccess)
      return
    }
  }

  const onSubmitPhoneNumber = () => {
    if (!canSendOtp) return shakeInvalidInput()

    onSendWhatsappOtp()
  }

  return (
    <UserNumberContainerOnModal className="whatsapp-modal">
      <InfoContainer>
        <Label>{T.settings.noNumber}</Label>
      </InfoContainer>
      {!openOtp && (
        <InputFlagPhoneNumber
          phoneNumber={phoneNumber}
          handleChange={handleChange}
          isValid={valid}
          width={inputWidth}
          onModal={true}
        />
      )}

      {openOtp && <OtpField returnData={retriveOtp} />}
      {!openOtp && (
        <Button variant="primary" onClick={onSubmitPhoneNumber} size="smaller" ph={24} style={{ fontWeight: 500 }}>
          {T.settings.sendCode}
        </Button>
      )}

      {openOtp && <ButtonNewOtpCode onClick={onSubmitPhoneNumber}>{T.settings.resendCode}</ButtonNewOtpCode>}
    </UserNumberContainerOnModal>
  )
}

export default WhatsAppOnModalRequest

const UserNumberContainerOnModal = styled(Box)`
  height: 249px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 32px 40px;
  border-radius: 0;
`

const InfoContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  border-radius: 0;
  margin-bottom: 6px;
`

const Label = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: ${() => AppStore.theme.o.black};
`

const ButtonNewOtpCode = styled.p`
  color: ${() => AppStore.theme.o.darkGrey};
  font-size: 12px;
  font-weight: 500;
  line-height: 15.36px;
  letter-spacing: 0.12px;
  cursor: pointer;
`
