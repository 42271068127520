/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { T } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  text?: string
}

export const ComingSoon = ({ text }: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  const handleRender = () => {
    if (!text) {
      return <ComingSoonContainer>{text ?? T.analytics.comingSoon}</ComingSoonContainer>
    } else {
      return <ComingSoonContainer dangerouslySetInnerHTML={{ __html: text.replace(/ /g, '&nbsp;') }} />
    }
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return handleRender()
}

const ComingSoonContainer = styled(Box)`
  width: max-content;
  height: 19px;
  display: inline-flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 0.5px solid #732187;
  background: #faeafe;
  color: #732187;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  pointer-events: none;
`
