import React from 'react'
import { IPhoneNumber } from 'types/NotifyInterface'
import WhatsAppRequest from './WhatsAppRequest'
import WhatsAppOnModalRequest from './WhatsAppOnModalRequest'

/* ___________________ TYPE ___________________ */
type TRequestNumberType = 'whatsapp' | 'whatsappModal'
/* ___________________ INTERFACE ___________________ */
interface Props {
  type: TRequestNumberType
  onReturnData?: (data, isValid) => void // funzione per tornare i dati al padre
  onReturnOtp?: (otp: string, isValid: boolean) => void // funzione per tornare i dati dell'otp al padre
  inputWidth?: string
  phoneNumber?: IPhoneNumber
  canModifyBtn?: boolean
  onForceUpdate?: () => void
}

const RequestNumber = ({
  type,
  onReturnData,
  onReturnOtp,
  inputWidth,
  phoneNumber,
  canModifyBtn,
  onForceUpdate,
}: Props) => {
  /* ____________________ ENUM ____________________ */
  const enumType = {
    whatsapp: (
      <WhatsAppRequest
        onReturnData={onReturnData!}
        onReturnOtp={onReturnOtp!}
        inputWidth={inputWidth}
        phone={phoneNumber}
        canModifyBtn={canModifyBtn}
      />
    ),
    whatsappModal: <WhatsAppOnModalRequest inputWidth={inputWidth} onForceUpdate={onForceUpdate} />,
  }

  return enumType[type]
}

export default RequestNumber
