/* ------------------- IMPORTS */
import { AppStore } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  style: any
}

export const TagCursor = ({ style }: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <div
      className="tag_cursor"
      style={{
        ...style,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        pointerEvents: 'none',
        fontSize: 14,
        borderRadius: '50%',
        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.2)',
        color: AppStore.theme.o.black,
        background: AppStore.theme.o.surface,
      }}
    >
      @
    </div>
  )
}
