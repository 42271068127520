/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Button, Icons } from 'components'
import { openManageLabelModal } from 'components/Modal/ModalManager'
import { AppStore, T } from 'utils'
import { TFromSection, TLabelsFilters } from '../LabelsContent'
import { SwitchLabelFilter } from './SwitchLabelFilter'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  numberOfLabels: number
  refreshLabels: () => void
  fromSection: TFromSection
  labelsFilter?: TLabelsFilters
  setLabelsFilter?: (filter: TLabelsFilters) => void
}

export const LabelContentHeader = ({
  numberOfLabels,
  refreshLabels,
  fromSection,
  labelsFilter,
  setLabelsFilter,
}: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <LabelContentHeaderContainer>
      <Title>{T.postPage.labelsSection.applyLabels}</Title>

      {fromSection === 'createPost' && numberOfLabels > 0 && (
        <ManageButton variant="neutral" onClick={() => openManageLabelModal(refreshLabels)}>
          <Icons.settingsIcon /> {T.singleWord.manage}
        </ManageButton>
      )}

      {(fromSection === 'calendar' || fromSection === 'draft' || fromSection === 'widget_analytics') && (
        <SwitchLabelFilter labelsFilter={labelsFilter} setLabelsFilter={setLabelsFilter} />
      )}
    </LabelContentHeaderContainer>
  )
}

const LabelContentHeaderContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const Title = styled(Box)`
  color: ${() => AppStore.theme.o.on_primary_container};
  font-family: 'Aktiv Grotesk';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

const ManageButton = styled(Button)`
  display: flex;
  min-height: 40px !important;
  height: 40px !important;
  padding: 8px 48px !important;
  justify-content: center;
  align-items: center;
  gap: 8px;

  color: ${() => AppStore.theme.o.darkGrey};
  text-align: center;
  font-family: 'Aktiv Grotesk';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`
