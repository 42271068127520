import gsap from 'gsap'

// refferalInputBox è id del coupon che spawnera on click del aply cuppon
// refferalCodeBox è id del container che contiene di cuppon
//  ArrowIcon è id dell'icona che cambia quando si clicca sul coupon

//FullLogInCard tutto contenitore di login
//FullSingUpCard tutto contenitore di signup
class SignUpAnimation {
  howManyAnimations = 0

  showRefferal(refferalInputBox: string, refferalCodeBox: string) {
    try {
      gsap.to(`#${refferalInputBox}`, { duration: 0.3, y: 8, opacity: 1 })
      gsap.to(`#${refferalCodeBox}`, { duration: 0.3, height: 60 })
      gsap.to(`#ArrowIcon`, { duration: 0.2, rotate: 90 })
    } catch (e) {
      console.error(e)
    }
  }
  hideRefferal(refferalInputBox: string, refferalCodeBox: string) {
    try {
      gsap.to(`#${refferalInputBox}`, { duration: 0.3, y: -100, opacity: 0 })
      gsap.to(`#${refferalCodeBox}`, { duration: 0.3, height: 10 })
      gsap.to(`#ArrowIcon`, { duration: 0.3, rotate: 0 })
    } catch (e) {
      console.error(e)
    }
  }

  swapFromLoginToSignUp(FullLogInCard: string, FullSingUpCard: string) {
    try {
      gsap.to(`#FullLogInCard`, { duration: 0.3, x: -500 })
      gsap.to(`#${FullSingUpCard}`, { duration: 0.3, x: 0 })
    } catch (e) {
      console.error(e)
    }
  }

  swapFromSignUpToLogin(FullLogInCard: string, FullSingUpCard: string) {
    try {
      gsap.to(`#${FullLogInCard}`, { duration: 0.3, x: 0 })
      gsap.to(`#${FullSingUpCard}`, { duration: 0.3, x: +500 })
    } catch (e) {
      console.error(e)
    }
  }
}

export default new SignUpAnimation()
