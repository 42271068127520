import { create } from 'zustand'
import {
  IArticle,
  IBoard,
  ITopic,
  ITopicDB,
  TDiscoverSubPage,
  TDropdownActive,
} from 'pages/ai-discover/aiDiscoverInterface'
import { AppStore } from 'utils'
import { ArticlePreviewModal } from '../pages/ai-discover/Modals/ArticlePreviewModal'
import { deleteComment } from 'pages/ai-discover/commonFunction'
import { openLanguageSelectModal } from 'pages/ai-discover/Modals/LanguageSelectModal'

interface DiscoverStore {
  subPageActive?: TDiscoverSubPage
  onChangeSubPage: (subPageActive: TDiscoverSubPage, dropdownActive?: TDropdownActive) => void
  onSelectBoard: (boardListSelected: string[], setNewSelectedList: any, boardId: string) => void
  createComment: boolean
  updateCommentCounter: () => void
  openArticleModal: (article: IArticle, board?: IBoard, isOnBoard?: boolean) => void
  onDeleteComment: (commentsIdSelected: string, board_id: string, article_uri: string) => any
  activeTopicSidebar: ITopic | null
  setActiveTopicSidebar: (activeTopicSidebar: ITopic | null) => void
  activeTopicSuggestedSidebar?: ITopicDB
  setActiveTopicSuggestedSidebar: (activeTopicSuggestedSidebar: ITopicDB) => void
  activeBoard?: IBoard
  setActiveBoard: (activeBoard: IBoard | undefined | any) => void
  inputSearchbar?: string
  setInputSearchbar: (topicSidebar: string) => void
  activeDropdown: TDropdownActive
  setActiveDropdown: (string: TDropdownActive) => void
  forceUpdate: boolean
  onForceUpdate: () => void
  suggestedTopicList: ITopicDB[]
  setSuggestedTopicList: (any) => void
  selectedTopic: ITopicDB | null
  setSelectedTopic: (selected: ITopicDB | null) => void
  languageFilterSearch: string[]
  setLanguageFilterSearch: any
  dateFilterSearch: any[]
  setDateFilterSearch: (dateFilterSearch: any[]) => void
  languageFilterFollow: string[]
  setLanguageFilterFollow: any
  dateFilterFollow: any[]
  setDateFilterFollow: (dateFilterFollow: any[]) => void

  //-
  trendingArticlesByTopicList: IArticle[] | null
  setTrendingArticlesByTopicList: (trendingArticlesByTopicList: IArticle[] | null) => void
  trendingArticlesByTopicListPage: number
  setTrendingArticlesByTopicListPage: (trendingArticlesByTopicListPage: number) => void

  //-
  trendingArticlesByFollowedTopic: IArticle[] | null
  setTrendingArticlesByFollowedTopic: (trendingArticlesByFollowedTopic: IArticle[] | null) => void
  trendingArticlesByFollowedTopicPage: number
  setTrendingArticlesByFollowedTopicPage: (trendingArticlesByFollowedTopicPage: number) => void

  //-
  searchedArticle: IArticle[] | null
  setSearchedArticle: (searchedArticle: IArticle[] | null) => void
  searchedArticlePage: number
  setSearchedArticlePage: (searchedArticlePage: number) => void

  numberOfFollowedTopic: number | null
  setNumberOfFollowedTopic: (numberOfFollowedTopic: number | null) => void

  firstMount: boolean
  setFirstMount: (firstMount: boolean) => void
}

const lang = localStorage.getItem('defaultLang')
const languageMappings = {
  it: ['ita', 'Italian'],
  en: ['eng', 'English'],
}
export const defaultLanguage = lang ? languageMappings[lang] : ['ita', 'Italian']

export const useDiscoverStore = create<DiscoverStore>((set, get) => ({
  /*
   * ## Gestore selezione board
   *
   * Questa funzione gestisce la selezione delle board da parte dell'utente dove al click di una board viene aggiunta alla lista delle board selezionate se invece è già selezionata la deseleziona
   *
   * se si vuole resettare la lista nel componente dove la si utilizza  fare come nell'esempio -> ( es: setList([]) ) e si resettano anche le board selezionate
   *
   * @param boardListSelected  array di id delle board selezionate
   * @param setNewSelectedList state per settare la nuova lista di board selezionate
   * @param boardId nuovo Id da aggiungere o rimouvere
   */
  onSelectBoard: (boardListSelected: string[], setNewSelectedList: any, boardId: string) => {
    const boardListIdSelected = [...boardListSelected]

    if (boardListIdSelected.includes(boardId)) {
      // deseleziona board
      const index = boardListIdSelected.indexOf(boardId)
      if (index > -1) {
        boardListIdSelected.splice(index, 1)
      }
    } else {
      boardListIdSelected.push(boardId)
    }

    setNewSelectedList([...boardListIdSelected])
  },

  onDeleteComment: async (commentsIdSelected: string, board_id: string, article_uri: string) => {
    const isDeleted = await deleteComment(commentsIdSelected, board_id, article_uri)

    isDeleted && get().updateCommentCounter()
    return isDeleted
  },

  /**
   * ## apre il modal per la preview dell'articolo
   *
   * @param article
   */
  openArticleModal: (article: IArticle, board?: IBoard, isOnBoard = false) => {
    const modalBody = () => (
      <ArticlePreviewModal
        article={article}
        onSelectBoard={get().onSelectBoard}
        isOnBoard={isOnBoard}
        board={board}
        openLanguage={openLanguageSelectModal}
        isCreateComment={get().updateCommentCounter}
        onCommentDelete={get().onDeleteComment}
      />
    )

    AppStore.openModal({
      id: 'preview-modal-article',
      body: modalBody(),
      style: {
        minHeight: '900px',
        // maxHeight: 800,
        minWidth: 820,
        zIndex: 102,
      },
      closable: false,
      centered: true,
    })
  },

  subPageActive: 'search',
  onChangeSubPage: (subPageClicked: TDiscoverSubPage, dropdownActive?: TDropdownActive) => {
    set({
      subPageActive: subPageClicked,
      activeDropdown: dropdownActive ?? 'all',
      activeBoard: undefined,
    })
  },

  createComment: false,
  updateCommentCounter: () => {
    set((state) => {
      const updatedCreateComment = !state.createComment
      return { createComment: updatedCreateComment }
    })
  },

  activeTopicSidebar: null,
  setActiveTopicSidebar: (activeTopicSidebarValue: ITopic | null) => {
    set({ activeTopicSidebar: activeTopicSidebarValue })
  },

  activeTopicSuggestedSidebar: undefined,
  setActiveTopicSuggestedSidebar: (activeTopicSuggestedSidebarValue: ITopicDB | undefined) => {
    set({ activeTopicSuggestedSidebar: activeTopicSuggestedSidebarValue })
  },

  activeBoard: undefined,
  setActiveBoard: (activeBoardValue: IBoard | undefined) => {
    set({ activeBoard: activeBoardValue })
  },

  inputSearchbar: '',
  setInputSearchbar: (inputSearchbarValue: string) => {
    set({ inputSearchbar: inputSearchbarValue })
  },

  activeDropdown: 'all',
  setActiveDropdown: (activeDropdownValue: TDropdownActive) => {
    set({ activeDropdown: activeDropdownValue })
  },

  forceUpdate: false,
  onForceUpdate: () => {
    set((state) => {
      const updatedForceUpdate = !state.forceUpdate
      return { forceUpdate: updatedForceUpdate }
    })
  },

  suggestedTopicList: [],
  setSuggestedTopicList: (suggestedTopicListValue: ITopicDB[]) => {
    set({ suggestedTopicList: suggestedTopicListValue })
  },

  selectedTopic: null,
  setSelectedTopic: (selectedTopicValue: ITopicDB | null) => {
    set({ selectedTopic: selectedTopicValue })
  },

  languageFilterSearch: defaultLanguage,
  setLanguageFilterSearch: (languageFilterSearchValue: string[]) => {
    set({ languageFilterSearch: languageFilterSearchValue })
  },

  dateFilterSearch: [],
  setDateFilterSearch: (dateFilterSearchValue: any[]) => {
    set({ dateFilterSearch: dateFilterSearchValue })
  },

  languageFilterFollow: defaultLanguage,
  setLanguageFilterFollow: (languageFilterFollowValue: string[]) => {
    set({ languageFilterFollow: languageFilterFollowValue })
  },

  dateFilterFollow: [],
  setDateFilterFollow: (dateFilterFollowValue: any[]) => {
    set({ dateFilterFollow: dateFilterFollowValue })
  },

  //-- Trend articles by topic list
  trendingArticlesByTopicList: null,
  setTrendingArticlesByTopicList: (trendingArticlesByTopicListValue: IArticle[] | null) => {
    set({ trendingArticlesByTopicList: trendingArticlesByTopicListValue })
  },

  trendingArticlesByTopicListPage: 1,
  setTrendingArticlesByTopicListPage: (trendingArticlesByTopicListPageValue: number) => {
    set({ trendingArticlesByTopicListPage: trendingArticlesByTopicListPageValue })
  },

  //-- Trend articles by followed topic
  trendingArticlesByFollowedTopic: null,
  setTrendingArticlesByFollowedTopic: (trendingArticlesByFollowedTopicValue: IArticle[] | null) => {
    set({ trendingArticlesByFollowedTopic: trendingArticlesByFollowedTopicValue })
  },

  trendingArticlesByFollowedTopicPage: 1,
  setTrendingArticlesByFollowedTopicPage: (trendingArticlesByFollowedTopicPageValue: number) => {
    set({ trendingArticlesByFollowedTopicPage: trendingArticlesByFollowedTopicPageValue })
  },

  //-- Articles by searched topic
  searchedArticle: null,
  setSearchedArticle: (searchedArticleValue: IArticle[] | null) => {
    set({ searchedArticle: searchedArticleValue })
  },

  searchedArticlePage: 1,
  setSearchedArticlePage: (searchedArticlePageValue: number) => {
    set({ searchedArticlePage: searchedArticlePageValue })
  },

  numberOfFollowedTopic: null,
  setNumberOfFollowedTopic: (numberOfFollowedTopicValue: number | null) => {
    set({ numberOfFollowedTopic: numberOfFollowedTopicValue })
  },

  firstMount: true,
  setFirstMount: (firstMountValue: boolean) => {
    set({ firstMount: firstMountValue })
  },
}))
