/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Text } from 'components'
import { useNavigate } from 'react-router-dom'
import { AppStore, T } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {}

export const Banner = ({}: Props) => {
  /* ----------------- ROUTER  */
  const navigate = useNavigate()

  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */
  const hasExpired = AppStore.socialAccountList.some((account) => account.expired)

  /* ----------------- METHODS  */
  const handleClickBanner = () => {
    navigate('/settings/social')
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return hasExpired ? (
    <BannerContainer onClick={handleClickBanner}>
      <Text color={AppStore.theme.o.error}>{T.error.lostConnection}</Text>
    </BannerContainer>
  ) : (
    <></>
  )
}

const BannerContainer = styled(Box)`
  height: 40px;
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  background-color: ${() => AppStore.theme.o.error_container};
  cursor: pointer;
  border: 1px solid transparent;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  z-index: 1;
  line-height: normal;
  margin-left: auto;

  &:hover {
    border-color: ${() => AppStore.theme.o.error};
    background-color: ${() => AppStore.theme.o.error_container};
  }
`
