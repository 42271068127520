import { useAppStore } from 'stores/AppStore'
import { IItem } from 'types'

export const updateItem = (item, newItem) => {
  const { setActiveWorkspace, activeWorkspace } = useAppStore.getState()

  const hashtagsIndex = activeWorkspace?.hashtags?.findIndex((h) => h._id === item._id)
  if (activeWorkspace?.hashtags === undefined) return

  const newActiveWorkspace = {
    ...activeWorkspace,
    hashtags: [
      ...activeWorkspace?.hashtags?.slice(0, hashtagsIndex),
      newItem,
      ...activeWorkspace?.hashtags?.slice((hashtagsIndex ?? 0) + 1),
    ],
  }

  setActiveWorkspace(newActiveWorkspace)
}

export const deleteItem = (item) => {
  const { setActiveWorkspace, activeWorkspace } = useAppStore.getState()

  const hashtagsIndex = activeWorkspace?.hashtags?.findIndex((h) => h._id === item._id)
  if (activeWorkspace?.hashtags === undefined) return

  const newActiveWorkspace = {
    ...activeWorkspace,
    hashtags: activeWorkspace.hashtags.filter((_, index) => index !== hashtagsIndex),
  }

  setActiveWorkspace(newActiveWorkspace)
}

export const addItem = (item: IItem) => {
  const { setActiveWorkspace, activeWorkspace } = useAppStore.getState()

  const newActiveWorkspace = {
    ...activeWorkspace,
    hashtags: [...(activeWorkspace?.hashtags ?? []), item ?? null],
  }

  setActiveWorkspace(newActiveWorkspace as any)
}
