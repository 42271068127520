/* ******************* IMPORTS ******************* */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import AppStore from 'utils/AppStore'
import ManageContentModal from '../DiscoverAside/ManageContentModal'
import { T } from 'utils'
import { deleteBoard, postRemoveArticle } from '../commonFunction'
import { IBoard, IArticle } from '../aiDiscoverInterface'
import { useDiscoverStore } from 'stores/DiscoverStore'

/* ******************* TYPES ******************* */

type TBottomBarType = 'manage-board' | 'manage-articles'

/* ******************* INTERFACE ******************* */

interface Props {
  isAllSelected: boolean
  onSelectAll: (type: string) => void
  selected: IBoard[] | IArticle[] | []
  setSelected
  type: TBottomBarType
  refreshArticleOnBoard?: () => void
  onMouseEnter: () => void
  onMouseLeave: () => void
}

export default function BottomBar({
  onSelectAll,
  isAllSelected,
  selected,
  setSelected,
  type,
  refreshArticleOnBoard,
  onMouseEnter,
  onMouseLeave,
}: Props) {
  /* ******************* STATE ******************* */

  /* ____________________ ZUSTAND  ____________________ */
  const { activeBoard, onForceUpdate, setActiveBoard } = useDiscoverStore((state) => ({
    activeBoard: state.activeBoard,
    onForceUpdate: state.onForceUpdate,
    setActiveBoard: state.setActiveBoard,
  }))

  /* ******************* METHODS ******************* */

  const getIds = (items) => {
    if (type === 'manage-board') {
      return items.map((board) => board._id)
    }

    if (type === 'manage-articles') {
      return items.map((article) => article.uri)
    }
  }

  /* ******************* API CALLS ******************* */

  const handleDelete = async (ids: string[]) => {
    if (type === 'manage-board') {
      await deleteBoard(ids)
      onForceUpdate()
      setSelected([])
    }

    if (type === 'manage-articles') {
      if (activeBoard && activeBoard._id) {
        const articleBoard = await postRemoveArticle(activeBoard._id, ids)
        setActiveBoard(articleBoard)
        refreshArticleOnBoard && refreshArticleOnBoard()
        onForceUpdate()
      }
      setSelected([])
    }
  }

  return (
    <Box
      style={{
        height: 0,
        position: 'absolute',
        zIndex: 10,
        bottom: 0,
        left: 0,
        right: 0,
      }}
      center
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <UpBox
        id={type}
        style={{
          height: 56,
          borderRadius: '14px',
          backgroundColor: `${AppStore.theme.o.surface}`,
          padding: '8px 24px',
          gap: '24px',
        }}
        removeTransition
        row
      >
        {/* Select all */}
        <BottomBarButton onClick={onSelectAll} className="selecteAll__button">
          {T.AiDiscoverPage.boardCard.selectionAll}{' '}
          <CheckBoxDiv className={isAllSelected ? 'selected ' : ''} center>
            {isAllSelected && <Icons.boxgreen width={16} height={16} fill={AppStore.theme.o.green} />}
            {!isAllSelected && <Icons.checkboxgrey width={16} height={16} fill={AppStore.theme.o.darkGrey} />}
          </CheckBoxDiv>
        </BottomBarButton>

        {type === 'manage-board' && (
          <>
            {/* Manage  */}
            <BottomBarButton
              className="manage__button"
              onClick={() => {
                AppStore.openModal({
                  id: 'manage-content-modal',
                  body: (
                    <ManageContentModal
                      sidebarType={'board'}
                      selectedBoards={getIds(selected)}
                      setSelectedBoard={setSelected}
                    />
                  ),
                  style: {
                    maxWidth: 430,
                  },
                })
              }}
            >
              {T.AiDiscoverPage.boardCard.Manage} <Icons.settingsIcon width={16} height={16} />
            </BottomBarButton>
          </>
        )}

        {/* Delete */}
        <BottomBarButton
          className="delete__button"
          onClick={() => {
            if (selected.length <= 0) return
            handleDelete(getIds(selected))
          }}
          style={{ cursor: selected.length > 0 ? 'pointer' : 'not-allowed' }}
        >
          {T.AiDiscoverPage.boardCard.Delete} <Icons.delete width={20} height={20} fill={AppStore.theme.o.red} />
        </BottomBarButton>
      </UpBox>
    </Box>
  )
}

const UpBox = styled(Box)`
  box-shadow: ${() => AppStore.theme.finalNavbarBoxShadow.slice(11)};
`

const BottomBarButton = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 12px;

  cursor: pointer;
  padding: 4px 16px;

  text-align: right;
  font-family: Aktiv Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  color: ${() => `${AppStore.theme.o.black}`};
  background-color: ${() => `${AppStore.theme.o.surface}`};

  &:hover {
    color: ${() => `${AppStore.theme.o.green}`};
    background-color: ${() => `${AppStore.theme.o.lightGreen}`};
  }

  &.selectAll__button {
    &:hover {
      .selected {
        background-color: ${() => `${AppStore.theme.o.red}`};
      }
    }
  }

  &.manage__button {
    &:hover {
      svg path {
        transition: 300ms ease;
        stroke: ${() => `${AppStore.theme.o.green}`};
      }
    }
  }

  &.delete__button {
    color: ${() => `${AppStore.theme.o.red}`};

    &:hover {
      background-color: ${() => `${AppStore.theme.o.lightRed}`};
    }
  }
`

const CheckBoxDiv = styled(Box)`
  width: 32px;
  height: 32px;
  padding: 4px;
  border-radius: 8px;

  background-color: ${() => `${AppStore.theme.o.lightestGrey}`};

  &:hover {
    background-color: ${() => `${AppStore.theme.o.lightGreen}`};
  }

  &.selected {
    background-color: ${() => `${AppStore.theme.o.lightGreen}`};
  }
`
