import { Box } from 'components/Box'
import { Icons } from 'components/Icons'
import { SubscriptionPlan } from 'types/SubscriptionInterfaces'
import { AppStore } from 'utils'
import { UpgradeOptionsPhase } from '../UpgradeOptionsModal'

/**
 * Component for the back button
 */
export const GoBackButton: React.FC<{
  plan: SubscriptionPlan
  handleSelect: (optionSelected: UpgradeOptionsPhase) => void
}> = ({ handleSelect }) => {
  return (
    <Box
      wh={50}
      style={{ position: 'absolute', top: 0, left: '-90px', backgroundColor: AppStore.theme.o.surface }}
      onClick={() => handleSelect(UpgradeOptionsPhase.OPTIONS)}
      center
    >
      <Icons.backward style={{ maxWidth: 16, maxHeight: 16 }} fill={AppStore.theme.o.black} />
    </Box>
  )
}
