import Workspaces from 'api/Workspaces'
import { Box } from 'components/Box'
import { Input } from 'components/Input'
import moment from 'moment-timezone'

import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Workspace } from 'types'
import { AppStore, T, showError, showSuccess } from 'utils'
import { Button } from 'components/Button'
import { SearchOutlined } from '@ant-design/icons'

interface Props {
  updateTimezone: (element: any, id: string) => void
  workspace: Workspace
}

export default function TimezoneModal(props: Props) {
  const { updateTimezone, workspace } = props
  //Dati di riferimento per i timezone
  const options = getTimezonesWithOffsets()
  const [timezone, setTimezone] = useState<{ name: string; value: string } | undefined>(undefined)
  const [searchText, setSearchText] = useState('')
  const [selectedItem, setSelectedItem] = useState<any>(undefined)

  function getTimezonesWithOffsets() {
    const timezones = moment.tz.names()
    const timezonesWithOffsets = timezones.map((momentTimezone: any) => ({
      name: momentTimezone,
      value: `UTC${moment.tz(momentTimezone).format('Z')}`,
    }))

    return timezonesWithOffsets
  }

  const changeWorkspace = async (e) => {
    const localWorkspace = workspace
    if (timezone !== undefined) {
      //Salvataggio su AppStore locale
      localWorkspace.timezone = timezone

      //Salvataggio su backend delle modifiche
      try {
        e.preventDefault()
        await Workspaces.changeTimezone(timezone, localWorkspace._id ?? '')
        // await Workspaces.save(localWorkspace)
        showSuccess(T.success.settedTimezone)
        // await AppStore.refreshLoggedUser()
      } catch (error) {
        showError(T.error.genericError)
      }
      AppStore.closeAllModal()
    } else {
      showError(T.error.selectTimezoneFirst)
    }
  }

  return (
    <Box center style={{ backgroundColor: AppStore.theme.o.surface }}>
      <Box center p={24}>
        <Box center>
          <Input
            variant="whiteDotted"
            style={{ width: 450, height: 40, zIndex: 1000 }}
            onChange={(e) => setSearchText(e)}
            placeholder={T.teamMembers.setTimeZone}
            prefix={<SearchOutlined />}
            removeHover={true}
            borderColor={AppStore.theme.o.grey}
            pixelBorder={1}
          />
        </Box>

        <Box
          hcenter
          style={{ border: `1px dashed ${AppStore.theme.o.grey}`, padding: 24, boxSizing: 'border-box' }}
          width={450}
          height={488}
          scroll
          hideBar
          mt={12}
        >
          {options &&
            options
              .filter((element) => element.name.toLowerCase().includes(searchText.toLowerCase()))
              .map((element) => (
                <Box style={{ alignItems: 'center' }}>
                  <SelectableItem
                    className={selectedItem === element.name ? 'active' : ''}
                    center
                    ph={24}
                    onClick={() => {
                      setTimezone(element)
                      setSelectedItem(element.name)
                    }}
                    style={{
                      border: `1px solid ${
                        selectedItem === element.name ? AppStore.theme.o.green : AppStore.theme.o.grey
                      }`,
                    }}
                  >
                    <Box
                      vcenter
                      row
                      style={{
                        justifyContent: 'space-between',
                      }}
                      width={'100%'}
                    >
                      <TimezoneItem>{`${element.name}`}</TimezoneItem>
                      <TimezoneItemTime>{`(${element.value})`}</TimezoneItemTime>
                      <Box
                        center
                        style={{
                          backgroundColor:
                            selectedItem !== element.name ? AppStore.theme.o.grey : AppStore.theme.o.green,
                          borderRadius: 50,
                        }}
                        width={12}
                        height={12}
                      />
                    </Box>
                  </SelectableItem>
                  {/* Barbatrucco per risoluzione problema margini/scroll */}
                  <div style={{ height: 12, width: 450, zIndex: 999 }}></div>
                </Box>
              ))}
        </Box>
        <Box absolute>
          <Button
            variant={timezone !== undefined ? 'primary' : 'neutral'}
            mt={700}
            height={42}
            size="small"
            onClick={changeWorkspace}
          >
            <ConfirmButtonText isReady={timezone !== undefined ? true : false}>
              {T.settings.setTimeZone}
            </ConfirmButtonText>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

const SelectableItem = styled(Box)`
  width: 430px;
  height: 40px;
  z-index: 9999;
  background-color: ${() => AppStore.theme.o.surface};
  cursor: pointer;
  &:hover {
    background-color: ${() => AppStore.theme.o.lightGrey};
  }
  &.active {
    background-color: ${() => AppStore.theme.o.lightGreen}!important;
    &.hover {
      background-color: ${() => AppStore.theme.o.lightGreen};
    }
  }
`

const ConfirmButtonText = styled.p<{ isReady: boolean }>`
  text-align: center;
  font-family: Aktiv Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${(props) => (props.isReady ? AppStore.theme.o.blue : AppStore.theme.o.grey)};
`

const TimezoneItem = styled.p`
  font-family: Aktiv Grotesk;
  font-size: 12px;
  flex: 1;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${() => AppStore.theme.o.black};
`

const TimezoneItemTime = styled.p`
  font-family: Aktiv Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  flex: 1;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${() => AppStore.theme.o.black};
`
