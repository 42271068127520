/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Text } from 'components'
import { useEffect, useState } from 'react'
import { IPlace } from 'types/PostInterface'
import { AppStore } from 'utils'
import MediaUtils from 'utils/MediaUtils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  handleAddPlace: (place: IPlace) => void
  place: IPlace
  getPlaceImage: (id: string) => Promise<string>
}

export const LocationItem = ({ handleAddPlace, place, getPlaceImage }: Props) => {
  /* ----------------- STATE  */
  const [urlImg, setUrlImg] = useState<string>(MediaUtils.workspacePlaceholder)

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */
  const setImg = async () => {
    const url = await getPlaceImage(place.id)
    setUrlImg(url)
  }

  /* ----------------- USEEFFECT  */

  useEffect(() => {
    setImg()
  }, [place])

  return (
    <LocationItemContainer onClick={() => handleAddPlace(place)}>
      <PlaceImg src={urlImg} />

      <NamePlace>{place.name}</NamePlace>
    </LocationItemContainer>
  )
}

const LocationItemContainer = styled(Box)`
  padding: 8px 16px 8px 8px;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  border: 1px solid transparent;
  color: ${() => AppStore.theme.o.black};

  &:hover {
    border-color: ${() => AppStore.theme.o.grey};
    background-color: ${() => AppStore.theme.o.surface};
    cursor: pointer;
  }
`

const PlaceImg = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`

const NamePlace = styled(Text)`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: ${() => AppStore.theme.o.black};
`
