/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import Labels from 'api/Labels'
import { Box } from 'components'
import { SpinnerAstersLogo } from 'components/UI'
import { useEffect, useState } from 'react'
import { ILabel } from './LabelsContentComponents/Label'
import { LabelContentContainer } from './LabelsContentComponents/LabelContentContainer'
import { LabelContentHeader } from './LabelsContentComponents/LabelContentHeader'
import { LabelContentInput } from './LabelsContentComponents/LabelContentInput'

/* ------------------- TYPES  */
export type TFromSection = 'createPost' | 'previewPost' | 'calendar' | 'draft' | 'widget_analytics'
export type TLabelsFilters = 'and' | 'or'

/* ------------------- INTERFACES  */

interface Props {
  selectedLabels: ILabel[]
  setSelectedLabels: (labels: ILabel[]) => void
  handleAddLabel: (label: ILabel) => void
  handleRemoveLabel: (label: ILabel) => void
  fromSection: TFromSection
  labelsFilter?: TLabelsFilters
  setLabelsFilter?: (filter: TLabelsFilters) => void
}

export const LabelsContent = ({
  selectedLabels,
  setSelectedLabels,
  handleAddLabel,
  handleRemoveLabel,
  fromSection,
  labelsFilter,
  setLabelsFilter,
}: Props) => {
  /* ----------------- STATE  */
  const [inputValueFilter, setInputValueFilter] = useState('')

  // Tutte le label del workspace
  const [workspaceLabels, setWorkspaceLabels] = useState<ILabel[]>([])

  const [refresh, setRefresh] = useState<boolean>(false)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */
  /**
   *Aggiorna le proprietà di una label che è stata modificata
   * @param newWorkspaceLabels ILabel[]
   */
  const checkSelectedPost = (newWorkspaceLabels: ILabel[]) => {
    const newSelectedLabels = newWorkspaceLabels.filter((label) => {
      return selectedLabels.find((lb) => lb._id === label._id)
    })
    setSelectedLabels(newSelectedLabels)
  }

  const refreshLabels = () => {
    setRefresh((prev) => !prev)
  }

  /**
   * Funzione che gestisce l'evento "enter" sull'input, aggiungendo la label se esiste.
   * @param e Evento onKeyDown dell'input
   */
  const onKeyDownLabel = (e) => {
    // Resolve bug propagation
    if (e.key === ' ') {
      e.preventDefault()
      setInputValueFilter((prev) => prev + ' ')
    }

    if (e.key === 'Enter') {
      addLabel()
    }
  }

  /**
   * Funzione che aggiunge una label ai selectedLabels e resetta input
   */
  const addLabel = (specificLabel?: ILabel) => {
    // Se gli viene passata una label la aggiunge
    if (specificLabel) {
      handleAddLabel(specificLabel)
      setInputValueFilter('')
      return
    }

    //Se non gli viene passata una label cerca la label nell'array di label del workspace in base all'input dell'utente
    const label = workspaceLabels.find((lb) => lb.label === inputValueFilter)

    if (label) {
      handleAddLabel(label)
      setInputValueFilter('')
    }
  }

  /* ----------------- API CALL  */
  const getLabels = async () => {
    setIsLoading(true)
    try {
      const res = await Labels.getWorkspaceLabels()
      checkSelectedPost(res)
      setWorkspaceLabels(res)
    } catch (e) {
      console.error(e)
    }

    setIsLoading(false)
  }

  /* ----------------- USEEFFECT  */
  // Fetch le labels del workspace
  useEffect(() => {
    getLabels()
  }, [refresh])

  return (
    <LabelsContentContainer
      style={{
        maxHeight: fromSection !== 'previewPost' ? '100%' : '650px',
        padding: fromSection !== 'previewPost' ? '24px' : '16px 40px',
      }}
      data-html2canvas-ignore="true"
    >
      {/* Header */}
      <LabelContentHeader
        numberOfLabels={workspaceLabels.length}
        fromSection={fromSection}
        refreshLabels={refreshLabels}
        labelsFilter={labelsFilter}
        setLabelsFilter={setLabelsFilter}
      />

      {/* Input */}
      <LabelContentInput
        onKeyDownLabel={onKeyDownLabel}
        inputValueFilter={inputValueFilter}
        setInputValueFilter={setInputValueFilter}
        handleRemoveLabel={handleRemoveLabel}
        selectedLabels={selectedLabels}
      />

      {/* Loader */}
      {isLoading && (
        <Box relative width={'100%'} height={200}>
          <SpinnerAstersLogo dimension={50} />
        </Box>
      )}

      {/* Labels container */}
      {!isLoading && (
        <LabelContentContainer
          handleAddLabel={handleAddLabel}
          inputValueFilter={inputValueFilter}
          workspaceLabels={workspaceLabels}
          selectedLabels={selectedLabels}
          refreshLabels={refreshLabels}
          addLabel={addLabel}
        />
      )}
    </LabelsContentContainer>
  )
}

const LabelsContentContainer = styled(Box)`
  width: 100%;
  overflow: hidden;
  margin-bottom: auto;
`
