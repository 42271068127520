/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { AppStore, T } from 'utils'

/* ------------------- TYPES  */
type TPlaceholderImgVariant = 'full' | 'small'

/* ------------------- INTERFACES  */
interface Props {
  variant: TPlaceholderImgVariant
}

export const PlaceHolderImgNotFound = ({ variant }: Props) => {
  return (
    <PlaceHolderImgNotFoundContainer className={`placeholder-image-${variant}`}>
      <Icons.logo />
      {T.AiDiscoverPage.imageNotFound}
    </PlaceHolderImgNotFoundContainer>
  )
}

const PlaceHolderImgNotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  width: 100%;
  background-color: ${() => AppStore.theme.o.lightestGrey};
  color: ${() => AppStore.theme.o.darkGrey};
  gap: 16px;

  svg {
    g {
      path {
        fill: ${() => AppStore.theme.o.darkGrey};
      }
    }
  }

  &.placeholder-image-small {
    height: 180px;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    svg {
      width: 40px;
      height: 40px;
    }
  }

  &.placeholder-image-full {
    height: 408px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    svg {
      width: 120px;
      height: 120px;
    }
  }
`
