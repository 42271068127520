import { MixpanelWorkspaceCreation, MixpanelWorkspaceSettingsPage, MixpanelWorkspaceInvitationSubmitted, MixpanelWorkspaceTeam } from './workspaceInterfaces'

export function createMixpanelWorkspaceTeamData(data: MixpanelWorkspaceTeam): MixpanelWorkspaceTeam {
  return {
    num_roles: data.num_roles,
    role_name: data.role_name,
    role_permission: data.role_permission,
    workspace_name: data.workspace_name,
    email: data.email,
    is_multi_invite: data.is_multi_invite,
  }
}

export function getMixpanelWorkspaceCreationData(data: MixpanelWorkspaceCreation): MixpanelWorkspaceCreation {
  return {
    workspace_name: data.workspace_name,
    workspace_profile_url: data.workspace_profile_url,
    time_zone: data.time_zone,
  }
}

export function getWorkspaceInvitationSubmittedData(
  data: MixpanelWorkspaceInvitationSubmitted
): MixpanelWorkspaceInvitationSubmitted {
  return {
    email: data.email,
    role_name: data.role_name,
    workspace_name: data.workspace_name,
    is_multi_invite: data.is_multi_invite,
  }
}

export function getWorkspaceSettingsPageData(data: MixpanelWorkspaceSettingsPage): MixpanelWorkspaceSettingsPage {
  return {
    num_users: data.num_users,
    workspace_name: data.workspace_name,
    time_zone: data.time_zone,
    workspace_status: data.workspace_status,
  }
}
