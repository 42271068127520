import styled from '@emotion/styled'
import Auth from 'api/Auth'
import Workspaces from 'api/Workspaces'
import asters_logo from 'assets/asters_logo.svg'
import { useEffect, useState } from 'react'
import { AppStore, T, showError, showInfo } from 'utils'
import { reloadApp } from 'utils/router'
import { Box } from './Box'
import { Button } from './Button'
import { Input } from './Input'
import { AnimatedPlaceholderLogo } from 'components'
import { Checkbox, Form } from 'antd'
import MediaUtils from 'utils/MediaUtils'

export const CreateColabAccount = ({ onLoginCallback }: { onLoginCallback?: (user: any) => void }) => {
  const backgroundImages = MediaUtils.backgrounUrl
  const [loader, setLoader] = useState(true)
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')
  const [workspaceId, setWorkspaceId] = useState('')
  const [token, setToken] = useState('')
  const [workspaceName, setWorkspaceName] = useState('')
  const [rememberMe, setRememberMe] = useState(false)
  const [email, setEmail] = useState('')
  const [privacyCheckbox, setPrivacyCheckbox] = useState(false)
  const [termsCheckbox, setTermsCheckbox] = useState(false)

  useEffect(() => {
    checkIfUserIsLogged()
    const url = new URL(window.location.href)
    const tokenUrl = url.searchParams.get('tempToken') ?? ''
    const workspaceIdUrl = url.searchParams.get('workspaceId') ?? ''
    const workspaceNameUrl = url.searchParams.get('workspaceName') ?? ''
    const emailUrl = url.searchParams.get('email') ?? ''
    setWorkspaceId(workspaceIdUrl)
    setToken(tokenUrl)
    setWorkspaceName(workspaceNameUrl)
    setEmail(emailUrl)
    AppStore.setActiveWorkspace(workspaceIdUrl, false)
  }, [])

  const checkIfUserIsLogged = async () => {
    return new Promise<boolean>(async (resolve) => {
      const url = new URL(window.location.href)
      const tempToken = url.searchParams.get('tempToken') ?? ''
      const workspace = url.searchParams.get('workspaceId') ?? ''
      const emailAddress = url.searchParams.get('email') ?? ''
      if (localStorage.getItem('token')) {
        await Workspaces.acceptColaboration(tempToken, workspace)
        setTimeout(() => {
          localStorage.setItem('workspaceId', workspace)
          window.location.href = '/dashboard'
          resolve(true)
        }, 500)
      } else {
        try {
          const isUserRegistered = await checkIfUserIsRegistered(tempToken, workspace)
          if (isUserRegistered) window.location.pathname = '/acceptColaboration'
        } catch (error) {
          AppStore.parampsOption!.defaultEmail = emailAddress
          if (emailAddress && emailAddress.length > 0) window.location.href = '/?email=' + encodeURIComponent(emailAddress)
          showError(T.error.colaboratorError)
        } finally {
          setLoader(false)
        }
      }
      resolve(false)
    })
  }

  const checkIfUserIsRegistered = async (tempToken: string, workspace: string): Promise<boolean> => {
    return Auth.isUserRegistered(tempToken, workspace)
  }

  const onSubmit = async () => {
    if (!termsCheckbox) return showError({ message: T.error.acceptTerm })
    if (!privacyCheckbox) return showError({ message: T.error.acceptPrivay })

    if (password.length < 1) return showError(T.error.validPassword)
    if (name.length < 2) return showError(T.error.validName)
    const user = (await Workspaces.acceptColaboration(token, workspaceId, password, name)) as any
    try {
      const res = await Auth.login(email, password)
      await AppStore.setToken(res.access_token)
      window.location.href = '/'
      AppStore.loggedUser = await Auth.loggedUser()

      showInfo(T.info.arrived)
      if (!!AppStore.loggedUser) {
        return reloadApp()
      }
    } catch (error) {
      showError(
        (error as any)?.message === 'ThrottlerException: Too Many Requests'
          ? T.error.toManyRequest
          : T.error.user_or_password_wrong
      )
    }
  }

  return loader ? (
    <AnimatedPlaceholderLogo />
  ) : (
    <LoginPageContainer background={backgroundImages}>
      <Box center flex>
        <CardContainer>
          <Box gap={12}>
            <TextSecondary>{T.info.youHaveBeenInvitedToTheWorkspace}</TextSecondary>
            <Box pv={8} style={{ backgroundColor: AppStore.theme.o.lightestGrey }} center>
              <WorkspaceText>{workspaceName}</WorkspaceText>
            </Box>
            <TextSecondary>{T.info.completeYourRegistrationOn}</TextSecondary>
          </Box>

          {/* Asters logo */}
          <Box mt={20} mb={32}>
            <img style={{ height: 50, width: 158 }} src={asters_logo} alt="" />
          </Box>

          {/* Form */}
          <Box gap={16} width={'100%'}>
            {/* /------ /------  NAME INPUT   /------/------/------  */}
            <Input
              flex
              variant="name"
              style={{ width: '100%' }}
              onChange={(e) => {
                setName(e)
              }}
            />

            {/* /------ /------  PASSWORD INPUT   /------/------/------  */}
            <Input
              flex
              style={{ width: '100%' }}
              variant="password"
              onChange={(e) => {
                setPassword(e)
              }}
            />
          </Box>

          <Box vcenter style={{ justifyContent: 'space-between', width: '100%' }} mt={8}>
            <Box row vcenter onClick={() => setRememberMe(!rememberMe)} removeHover>
              <Box
                style={{ borderRadius: 50, border: `1px solid ${AppStore.theme.o.grey}` }}
                width={14}
                height={14}
                center
                mr={4}
              >
                {rememberMe && (
                  <Box
                    style={{ borderRadius: 50, backgroundColor: AppStore.theme.o.darkGrey }}
                    width={9}
                    height={9}
                  ></Box>
                )}
              </Box>
              <Box style={{ color: AppStore.theme.o.darkGrey }}>{T.labels.remember}</Box>
            </Box>
          </Box>

          <Box mt={16}>
            <Form.Item style={{ width: '100%' }} name="terms">
              <Checkbox
                onClick={() => setTermsCheckbox(!termsCheckbox)}
                value={termsCheckbox}
                style={{
                  fontWeight: 400,
                  fontSize: 14,
                  color: '#A3A1AA',
                }}
              >
                <Box row style={{ fontSize: 14 }}>
                  {T.placeholder.iHaveReadUnderstoodAndAcceptedThe} {T.articles.the}
                  <a href="https://asters.ai/terms-of-use" target={'_blank'}>
                    <b style={{ color: AppStore.theme.o.green, marginLeft: 4 }}> {T.settings.confirmPrivacy2}</b>
                  </a>
                </Box>
              </Checkbox>
            </Form.Item>
            <Form.Item style={{ width: '100%' }} name="privacy">
              <Checkbox
                onClick={() => setPrivacyCheckbox(!privacyCheckbox)}
                value={privacyCheckbox}
                style={{
                  fontWeight: 400,
                  fontSize: 14,
                  color: '#A3A1AA',
                }}
              >
                <Box row style={{ fontSize: 14 }}>
                  {T.placeholder.iHaveReadUnderstoodAndAcceptedThe} {T.articles.there}
                  <a href="https://asters.ai/privacy-policy" target={'_blank'}>
                    <b style={{ color: AppStore.theme.o.green, marginLeft: 4 }}> {T.logIn.privacyPolicy}</b>
                  </a>
                </Box>
              </Checkbox>
            </Form.Item>
          </Box>

          <Button
            mt={30}
            width={230}
            height={44}
            size="small"
            shadow="none"
            variant="primary"
            onClick={() => {
              onSubmit()
            }}
          >
            <ButtonText>{T.info.register}</ButtonText>
          </Button>
        </CardContainer>
      </Box>
    </LoginPageContainer>
  )
}

export default CreateColabAccount

const TextSecondary = styled.p`
  font-weight: 700;
  font-size: 16px;
  color: ${() => AppStore.theme.o.darkGrey};
`

const ButtonText = styled.p`
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
`

const LoginPageContainer = styled.div<{ background }>`
  display: flex;
  align-items: center;
  justify-content: left;
  background: url(${(props) => props.background}) no-repeat center center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: background-image 0.5s ease-in-out;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
`

const CardContainer = styled.div`
  display: flex;
  padding: 24px 32px;
  background-color: ${() => AppStore.theme.o.surface};
  border-radius: 16px;
  box-shadow: 0px 20px 20px 0px rgb(87 87 87 / 20%);
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const WorkspaceText = styled.p`
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: ${() => AppStore.theme.o.black};
`
