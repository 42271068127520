/* ******************* IMPORT ******************* */
import styled from '@emotion/styled'
import { Box } from 'components'
import DiscoverCarousel from './DiscoverCarousel'
import { AppStore } from 'utils'
import SkeletonCarousel from './SkeletonCarousel'
import Utils from 'api/Utils'

const SearchContentNoInput = () => {
  /* ____________________ VARIABLES ____________________ */

  const trendArticlesList = AppStore.discoverTrendArticleList
  const boardList = AppStore.discoverUserBoardList

  return (
    <ContainerSearchContentNoInput>
      {/* Trend Articles Carousel */}
      {trendArticlesList === undefined && (
        <SkeletonCarousel key={Utils.generateRandomUIDKey()} carouselType={'Topics'} />
      )}

      {trendArticlesList && <DiscoverCarousel carouselType={'Topics'} />}

      {/* Boards Carousel */}
      {boardList === undefined && <SkeletonCarousel key={Utils.generateRandomUIDKey()} carouselType={'Boards'} />}

      {boardList && <DiscoverCarousel carouselType={'Boards'} />}
    </ContainerSearchContentNoInput>
  )
}

export default SearchContentNoInput

const ContainerSearchContentNoInput = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 48px;

  width: 100%;
  max-height: 100%;
  flex: 1;

  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`
