import { AppStore } from 'utils'
import AstersBaseResource from './AstersBaseResource'
import { api, responseErrorCheck } from './api'

// <-------------------------INFERFACE------------------------->

export default class Notify extends AstersBaseResource {
  static endpoint = '/notification'

  static async getNotification(pagination: { page: number } = { page: 1 }) {
    return api
      .get<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/getNotifications/${pagination.page}`)
      .then(responseErrorCheck)
  }

  static async getWorkspacesNotifications() {
    return api.get<any>(`${this.endpoint}/getWorkspacesNotifications`).then(responseErrorCheck)
  }

  static async markNotificationAsRead(notificationId: string) {
    const userId = AppStore.loggedUser._id
    return api
      .post<any>(`${this.endpoint}/markNotificationAsRead`, {
        notificationId,
        userId,
      })
      .then(responseErrorCheck)
  }

  static async markAllNotificationsAsRead() {
    return api
      .post<any>(`${this.endpoint}/markAllNotificationsAsRead`, {
        workspaceId: AppStore.getWorkspaceId(),
      })
      .then(responseErrorCheck)
  }
}
