/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { AppStore } from 'utils'

/* ------------------- INTERFACES  */
interface Props {
  name: string
  icon: JSX.Element[]
  action?: () => void
}

export const FilterElementData = ({ name, action, icon }: Props) => {
  /* ------------------- RENDER METHODS  */

  /**
   * Used to render the correct icon / icons of social accounts
   * @returns Icon / Icons component
   */
  const renderIcons = () => {
    return (
      icon && (
        <FilterDataIcons
          className={`filter_data_icons 
            ${icon.length === 1 ? 'one_icon' : 'multiple_icons'}`}
        >
          {icon}
        </FilterDataIcons>
      )
    )
  }

  return (
    <FilterElementDataContainer className="filter_element_data_container" onClick={action}>
      <FilterDataName className="filter_data_name">{name}</FilterDataName>
      {renderIcons()}
    </FilterElementDataContainer>
  )
}

const FilterElementDataContainer = styled(Box)`
  &.filter_element_data_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    height: 38px;
    padding: 8px 16px;
    gap: 4px;
    border-radius: 14px;
    border: 1px solid ${() => AppStore.theme.o.grey};
    cursor: pointer;
    margin-top: 16px;
  }
`

const FilterDataName = styled(Box)`
  &.filter_data_name {
    color: ${() => AppStore.theme.o.black};
    text-overflow: ellipsis;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    overflow-wrap: break-word;
    max-width: 110px;
    display: block;
    border-radius: 0;
  }
`

const FilterDataIcons = styled(Box)`
  &.filter_data_icons {
    display: flex;
    flex-direction: row;
    position: relative;

    &.multiple_icons > div:first-child {
      position: absolute;
      left: -15px;
    }
  }
`
