/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Component, CSSProperties } from 'react'
import { AppStore } from 'utils'
import { Box } from './Box'
import { Text } from './Text'

/* ------------------- TYPES  */
export type TStepOption = { key: string; label: string; done?: boolean; active?: boolean }

/* ------------------- INTERFACES  */
interface Props {
  steps: TStepOption[]
  style: CSSProperties
}

export const Stepper = ({ steps, style }: Props) => {
  return (
    <StepperContainer flex style={style} row>
      {steps.map((step, index) => (
        <StepContainer flex hcenter>
          <Text weight={'bold'} fontSize={16} color={step.active ? AppStore.theme.o.black : AppStore.theme.o.darkGrey}>
            {index + 1}. {step.label}
          </Text>
          <UnderLineStep mt={step.active ? 16 : 8} {...step} />
        </StepContainer>
      ))}
    </StepperContainer>
  )
}

const StepperContainer = styled(Box)``

const UnderLineStep = styled(Box)<{ done?: boolean }>`
  height: 4px;
  width: 100%;
  background-color: ${({ done }) => (done ? AppStore.theme.o.blueOnLight : AppStore.theme.o.darkGrey)};
  transition: all 0.3s ease-in-out;
`

const StepContainer = styled(Box)`
  justify-content: end;
  margin-left: 4px;
  &:nth-child(1) {
    margin-left: 0px;
  }
`
