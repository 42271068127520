/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Button } from 'components'
import { AppStore, showInfo, T } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  message?: any
}

export const ReportButton = ({ message }: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */
  const handleOnClickReport = () => {
    // Only Post ID
    // const queryString = window.location.search
    // const params = new URLSearchParams(queryString)
    // const postId = params.get('postId')
    // const prefillMessage = `Post ID : ${postId}`

    const prefillMessage = window.location.href

    // Copy the message to the clipboard
    navigator.clipboard.writeText(prefillMessage).catch((err) => {
      console.error('Failed to copy message:', err)
    })

    // @ts-ignore
    window.Intercom('showNewMessage', prefillMessage)
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return <ReportButtonContainer onClick={handleOnClickReport}>{T.error.reportAProblem}</ReportButtonContainer>
}

const ReportButtonContainer = styled(Button)`
  position: absolute;
  top: calc(100% + 16px);
  right: 0px;
  display: flex;
  height: 40px !important;
  min-height: 40px !important;
  padding: 8px 48px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 14px !important;
  border: 1px solid ${() => AppStore.theme.o.on_error_container};
  background: ${() => AppStore.theme.o.error_container};
  color: ${() => AppStore.theme.o.on_error_container};

  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &:hover {
    background: ${() => AppStore.theme.o.error_container};
  }
`
