import { ReactComponent as Grid } from 'assets/icons/grid.svg'
import { ReactComponent as List } from 'assets/icons/list.svg'

import styled from '@emotion/styled'

import { useState } from 'react'
import { Box } from './Box'
import { AppStore } from 'utils'

export function ToggleButton() {
  const [selected, setSelected] = useState(true)
  return (
    <Container onClick={() => setSelected(!selected)}>
      <Box center flex row>
        <IconContainer selected={selected} center mt={7.5} mb={7.5} ml={9} p={7.5}>
          <MyGrid selected={selected} />
        </IconContainer>
        <IconContainer selected={!selected} center mt={7.5} mb={7.5} mr={9} p={7.5}>
          <MyList selected={!selected} />
        </IconContainer>
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  width: fit-content;
  background-color: ${() => AppStore.theme.o.lightGrey};
  border-radius: 14px;
`

const IconContainer = styled(Box)<{ selected: any }>`
  border-radius: 14px;
  background-color: ${({ selected }) => (selected ? AppStore.theme.o.background : 'transparent')};
`

const MyGrid = styled(Grid)<{ selected: any }>`
  max-width: 18px;
  max-height: 18px;
  path {
    fill: ${({ selected }) => (selected ? 'lightblue' : AppStore.theme.o.darkGrey)};
  }
`
const MyList = styled(List)<{ selected: any }>`
  max-width: 18px;
  max-height: 18px;
  path {
    fill: ${({ selected }) => (selected ? 'lightBlue' : AppStore.theme.o.darkGrey)};
  }
`
