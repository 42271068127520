/* ------------------- IMPORTS */
import Lottie from 'react-lottie'
import LoadingVideoTiktok from '../../../../assets/lotties/PreviewVideoExtras.json'

const LoadingVideoTiktokOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingVideoTiktok,
}

export const LottiePreviewVideo = () => {
  return <Lottie isClickToPauseDisabled options={LoadingVideoTiktokOptions} width={'50%'} height={'50%'} />
}
