/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { GenerationSettingTitle } from './GenerationSettingTitle'
import { AppStore, T } from 'utils'
import Select from 'components/Select'
import { useState } from 'react'

/* ------------------- INTERFACES  */
interface Props {
  selectedLength: number
  selectedObjective: number
  setSelectedObjective: (index: number) => void
  configSettings: any
  dropdownClass?: string
}

export const GenerationObjective = ({
  selectedLength,
  selectedObjective,
  setSelectedObjective,
  configSettings,
  dropdownClass,
}: Props) => {
  /* ----------------- STATE  */
  const [openObjective, setOpenObjective] = useState(false)

  /* ----------------- VARIABLES  */
  const typeGenerationSelected = configSettings.map((item) => {
    return { value: item.value, name: item.name }
  })

  return (
    <GenerationObjectiveContainer>
      <GenerationSettingTitle text={T.textGenerator.selectObjective} />

      <Select
        objective={typeGenerationSelected[selectedObjective]?.name ?? T.textGenerator.goal}
        icon={<Icons.dropdownArrow fill={AppStore.theme.o.darkGrey} width={28} height={28} />}
        width={276}
        options={typeGenerationSelected}
        open={openObjective}
        setOpen={setOpenObjective}
        setObjective={setSelectedObjective}
        active={selectedLength !== -1}
        dropdownClass={dropdownClass}
      />
    </GenerationObjectiveContainer>
  )
}

const GenerationObjectiveContainer = styled(Box)``
