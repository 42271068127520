import React from 'react'
import AiDiscoverArticleComments from './AiDiscoverArticleComments'
import { IComments } from 'pages/ai-discover/aiDiscoverInterface'
import { isAiDiscoverCommentsEnabled } from 'utils/DiscoverUtils'

/* ___________________ TYPE ___________________ */
type TComments = 'on-article'

/* ___________________ INTERFACE ___________________ */
interface Props {
  commentsType: TComments
  comments?: IComments
  addNewComment: (commentText: string) => void
  addNewReply: (replyText: string) => void
  deleteComent: () => void
  isCreateComment?: () => void // Se si è in una modale
}

const IndexComments = ({
  commentsType,
  comments,
  addNewComment,
  addNewReply,
  deleteComent,
  isCreateComment,
}: Props) => {
  /* ____________________ STATE ____________________ */

  /* ____________________ REF ____________________ */

  /* ____________________ CONTEXT ____________________ */

  /* ____________________ VARIABLES ____________________ */

  /* ____________________ METHODS ____________________ */

  /* ____________________ API CALL ____________________ */

  /* ____________________ USEEFFECT ____________________ */

  // useEffect  per caricare i dati all'apertura della pagina la prima volta

  // useEffect che si attiva ad ogni cambiamento di variabili

  // useEffect che si attiva ad ogni cambiamento

  switch (commentsType) {
    case 'on-article':
      let reply

      const firstComment = {
        user: comments?.user,
        id: comments?._id ?? '',
        replyText: comments?.comment,
        date: comments?.date,
      }
      if (comments) reply = [firstComment, ...comments?.reply]
      else reply = []

      return isAiDiscoverCommentsEnabled ? (
        <AiDiscoverArticleComments
          reply={reply}
          addNewComment={addNewComment}
          addNewReply={addNewReply}
          deleteComent={deleteComent}
          isCreateComment={isCreateComment}
        />
      ) : null

    default:
      return null
  }
}

export default IndexComments
