import styled from '@emotion/styled'
import { Box, Button, Icons } from 'components'
import { AppStore } from 'utils'

type Props = {
  videoCoverTimestampMsState: number
  maxVideoCoverTimestampMs: number
  changeTimestamp: (value: number) => void
}

export const ControlButtons: React.FC<Props> = (props) => {
  const { videoCoverTimestampMsState, maxVideoCoverTimestampMs, changeTimestamp } = props

  const doubleArrowValueInMs = 5000
  const singleArrowValueInMs = 500

  function formatTime(): string {
    const seconds = Math.floor((videoCoverTimestampMsState / 1000) % 60)
    const minutes = Math.floor((videoCoverTimestampMsState / (1000 * 60)) % 60)
    const hours = Math.floor(videoCoverTimestampMsState / (1000 * 60 * 60))
    let time = ''
    if (hours > 0) {
      time += hours + ':'
    }
    return (time += (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds)
  }

  return (
    <Box gap={8} row vcenter style={{ height: '24px !important' }}>
      <ControlsButtons
        variant="neutral"
        pv={2}
        ph={16}
        borderRadius={14}
        disabled={videoCoverTimestampMsState <= 0}
        onClick={() => changeTimestamp(-doubleArrowValueInMs)}
      >
        <Icons.doubleArrowLeft fill={AppStore.theme.o.darkGrey} />
      </ControlsButtons>

      <ControlsButtons
        variant="neutral"
        pv={2}
        ph={16}
        borderRadius={14}
        disabled={videoCoverTimestampMsState <= 0}
        onClick={() => changeTimestamp(-singleArrowValueInMs)}
      >
        <Icons.arrowLeft fill={AppStore.theme.o.darkGrey} />
      </ControlsButtons>

      <Box width={80} height={24} center borderRadius={14} style={{ border: `1px solid ${AppStore.theme.o.grey}` }}>
        <TimeText>{formatTime()}</TimeText>
      </Box>

      <ControlsButtons
        variant="neutral"
        pv={2}
        ph={16}
        borderRadius={14}
        disabled={videoCoverTimestampMsState >= maxVideoCoverTimestampMs}
        onClick={() => changeTimestamp(singleArrowValueInMs)}
      >
        <Icons.arrowRight fill={AppStore.theme.o.darkGrey} />
      </ControlsButtons>

      <ControlsButtons
        variant="neutral"
        pv={2}
        ph={16}
        borderRadius={14}
        disabled={videoCoverTimestampMsState >= maxVideoCoverTimestampMs}
        onClick={() => changeTimestamp(doubleArrowValueInMs)}
      >
        <Icons.doubleArrowRight fill={AppStore.theme.o.darkGrey} />
      </ControlsButtons>
    </Box>
  )
}

const ControlsButtons = styled(Button)`
  max-width: 44px;
  min-height: 24px;
`

const TimeText = styled.p`
  color: ${() => AppStore.theme.o.black};

  max-width: 32;
  min-height: 20;

  font-family: 'Aktiv Grotesk';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  position: relative;
  top: 0.5px;
`
