import styled from '@emotion/styled'

import Utils from 'api/Utils'
import { Component } from 'react'
import { AppStore, T, showInfo } from 'utils'
import { Box } from './Box'
import { Icons } from './Icons'
import { Input } from './Input'
import { Tracker } from 'api/Tracker'
import { TRACKEREVENTS, trackReferralVisited } from 'utils/eventsTracker'
import { isVisible } from 'utils/CheckVisibleElement'

interface State {
  data: any
}
export default class ReferralCodeModal extends Component<{}, State> {
  state: State = {
    data: {
      share_clicks_count: 0,
      share_signups_count: 0,
      share_link: '',
      balance: 0,
    },
  }

  howItWorks = [
    {
      icon: <Icons.share fill={AppStore.theme.o.darkGrey} />,
      text: T.referral.step_1,
    },
    {
      icon: <Icons.card fill={AppStore.theme.o.darkGrey} />,
      text: T.referral.step_2,
    },
    {
      icon: <Icons.referalGift fill={AppStore.theme.o.green} />,
      text: T.referral.step_3,
      important: true,
    },
    {
      icon: <Icons.reshare fill={AppStore.theme.o.darkGrey} />,
      text: T.referral.step_4,
    },
  ]

  async componentDidMount() {
    const data: any = (await Utils.getPrefineryData()).data
    if (isVisible('referralCodeModal.shareLink'))
      data.share_link = data.share_link.replace(`https://beta.asters.ai`, 'https://romantic-booth-e26b41.netlify.app')

    this.setState({ data })
    const referralData = trackReferralVisited(data, AppStore.loggedUser)
    Tracker.trackEvent(TRACKEREVENTS.REFERRAL_VISITED, referralData)
  }

  renderInnerBox(
    numberValue: number,
    textValue: string,
    icon: JSX.Element,
    green?: boolean,
    euro?: boolean,
    grey?: boolean
  ) {
    return (
      <Box center style={{ minWidth: 107 }}>
        <span
          style={{
            color: grey ? AppStore.theme.o.darkGrey : green ? AppStore.theme.o.green : AppStore.theme.o.black,
            fontSize: 21,
            fontWeight: 700,
          }}
        >
          {euro && '€ '}
          {numberValue}
        </span>
        <Box row vcenter style={{ gap: 4 }}>
          {icon}
          <span
            style={{
              color: grey ? AppStore.theme.o.darkGrey : green ? AppStore.theme.o.green : AppStore.theme.o.black,
              fontSize: 16,
              fontWeight: 400,
            }}
          >
            {textValue}
          </span>
        </Box>
      </Box>
    )
  }

  render() {
    const { data } = this.state

    const socials = [
      {
        icon: <Icons.whatAppIconFillAble fill={AppStore.theme.o.blueOnLight} />,
        name: T.common.socials.whatsapp,
        link: `whatsapp://send?text=${T.referral.preText} ${this.state.data.share_link}`,
      },
      {
        icon: <Icons.telegramIconFillAble fill={AppStore.theme.o.blueOnLight} />,
        name: T.common.socials.telegram,
        link: `https://telegram.me/share/url?url=${this.state.data.share_link}&text=${T.referral.preText}${this.state.data.share_link}`,
      },
      {
        icon: <Icons.emailIconFillAble fill={AppStore.theme.o.blueOnLight} />,
        name: 'Email',
        link: `mailto:user@example.com?subject=Subject&body=${T.referral.preText}${this.state.data.share_link}`,
      },
      {
        icon: <Icons.linkedinIconFillAble fill={AppStore.theme.o.blueOnLight} />,
        name: T.common.socials.linkedin,
        link: `http://www.linkedin.com/shareArticle?mini=true&title=${T.referral.preText}&url=${this.state.data.share_link}&text=${T.referral.preText}`,
      },
      {
        icon: <Icons.facebookIconFillAble fill={AppStore.theme.o.blueOnLight} />,
        name: T.common.socials.facebook,
        link: `http://www.facebook.com/sharer.php?s=100&p[title]=${T.referral.preText}s&p[summary]=${T.referral.preText}&p[url]=${this.state.data.share_link}$p[description]=YOUR_IMAGE_TO_SHARE_OBJECT`,
        // link: 'http://www.facebook.com/dialog/feed?link=http://developers.facebook.com/docs/reference/dialogs/&picture=http://fbrell.com/f8.jpg&name=Facebook%20Dialogs&caption=Reference%20Documentation&description=Dialogs%20provide%20a%20simple,%20consistent%20interface%20for%20applications%20to%20interact%20with%20users.&message=Facebook%20Dialogs%20are%20so%20easy!&redirect_uri=http://www.example.com/response
      },
      // &p[images][0]=YOUR_IMAGE_TO_SHARE_OBJECT
      {
        icon: <Icons.xIcon fill={AppStore.theme.o.blueOnLight} />,
        name: T.common.socials.xTwitter,
        link: `https://twitter.com/intent/tweet?text=${T.referral.preText}${this.state.data.share_link}`,
      },
    ]

    return (
      <Box style={{ minWidth: 940 }} pv={32} ph={64}>
        <SectionText style={{ color: AppStore.theme.o.blueOnLight, marginBottom: 8, marginTop: 8 }}>
          {T.DashboardPage.video_review}
        </SectionText>
        <Box style={{ gap: 4, marginBottom: 31 }} row flex vcenter relative hcenter>
          <hr
            style={{
              border: `1.2px dotted ${AppStore.theme.o.darkGrey}`,
              position: 'absolute',
              width: '100%',
              top: 65,
            }}
          />
          <Box row style={{ gap: 12 }} flex>
            <Box style={{ y: -3 }}>
              <Icons.playVideo />
            </Box>
            <StepText style={{ color: AppStore.theme.o.black }}>
              {T.DashboardPage.tell_us_what_you_think}
              <span
                style={{
                  color: AppStore.theme.o.green,
                  fontSize: 16,
                  fontWeight: 700,
                  marginLeft: 4,
                }}
              >
                {T.DashboardPage.earn_50}
              </span>
            </StepText>
          </Box>
          <CopyLinkButton
            row
            center
            removeHover
            onClick={() => {
              window.open('https://form.asters.ai/video-recensione', '_blank')
              Tracker.trackEvent(TRACKEREVENTS.REFERRAL_VIDEO_REVIEW_INITIATED, {})
            }}
          >
            <Icons.star fill={AppStore.theme.o.white} />
            <span style={{ color: AppStore.theme.o.white, fontSize: 14, fontWeight: 700 }}>
              {T.DashboardPage.lets_go}
            </span>
          </CopyLinkButton>
        </Box>
        {/* Come funziona? -------____-------____-------____-------____-------____-------____-------____-------____*/}
        <SectionText style={{ color: AppStore.theme.o.blueOnLight, marginBottom: 15.5, marginTop: 15.5 }}>
          {T.referral.how_it_works}
        </SectionText>

        <Box vcenter style={{ gap: 4 }}>
          {this.howItWorks.map((item, index) => (
            <Box style={{ gap: 4 }}>
              <Box row style={{ gap: 12 }} vcenter>
                <Box>{item.icon}</Box>
                <StepText
                  style={{
                    color: item.important ? AppStore.theme.o.green : AppStore.theme.o.black,
                    fontWeight: item.important ? 700 : 400,
                  }}
                >
                  {item.text}
                </StepText>
              </Box>
              {index !== 3 && <DashedLine ml={15} />}
            </Box>
          ))}
        </Box>
        {/* Coupon code -------____-------____-------____-------____-------____-------____-------____-------____*/}
        <Box row style={{ gap: 16, position: 'relative' }} mt={24} mb={56}>
          <Box flex>
            <Input style={{ height: 52 }} placeholder={data.share_link} disabled />
          </Box>

          <CopyLinkButton
            row
            center
            removeHover
            onClick={() => {
              navigator.clipboard.writeText(data.share_link)
              showInfo(T.info.linkCopied)
              Tracker.trackEvent(TRACKEREVENTS.REFERRAL_LINK_SHARED_INITIATED, { referral_share_type: 'Copied Link' })
            }}
          >
            <Icons.copyLink fill={AppStore.theme.o.white} />
            <span style={{ color: AppStore.theme.o.white, fontSize: 14, fontWeight: 700 }}>{T.referral.copy_link}</span>
          </CopyLinkButton>
          <span style={{ position: 'absolute', color: AppStore.theme.o.black, fontSize: 12, bottom: -24 }}>
            {T.referral.by_joining}
            <span
              style={{ textDecoration: 'underline', marginLeft: 4 }}
              onClick={() => {
                window.open('https://asters.ai/terms-of-use', '_blank')
              }}
            >
              {T.referral.terms_and_conditions}
            </span>
          </span>
        </Box>
        {/* Condividi tramite social -------____-------____-------____-------____-------____-------____-------____-------____*/}
        <Box flex>
          <SectionText style={{ color: AppStore.theme.o.blueOnLight, marginBottom: 24 }}>
            {T.referral.share_via_social}
          </SectionText>

          <CollumBox>
            {socials.map((item, index) => (
              <SocialButton
                flex
                onClick={() => {
                  window.open(item.link, '_blank')
                  Tracker.trackEvent(TRACKEREVENTS.REFERRAL_LINK_SHARED_INITIATED, { referral_share_type: item.name })
                }}
              >
                <Box row center style={{ gap: 4 }}>
                  {item.icon}
                  <span style={{ color: AppStore.theme.o.blueOnLight, fontSize: 14, fontWeight: 700 }}>
                    {item.name}
                  </span>
                </Box>
              </SocialButton>
            ))}
          </CollumBox>
        </Box>
        {/* Progresso -------____-------____-------____-------____-------____-------____-------____-------____*/}
        <Box mt={24}>
          <SectionText style={{ marginBottom: 16 }}>{T.referral.progress}</SectionText>

          <Box row style={{ gap: 24 }}>
            {/* VIsite link e iscrizioni -------____-------____-------____-------____-------____-------____-------____-------____-------____-------____-------____-------____-------____-------____-------____-------____*/}
            <BorderContainerBox ignoreGradiant row style={{ gap: 0 }}>
              <Box ml={21}>
                {this.renderInnerBox(
                  data.share_clicks_count,
                  T.referral.link_visiter,
                  <Icons.link fill={AppStore.theme.o.black} />
                )}
              </Box>
              <DashedLine style={{ minHeight: 46, padding: 0 }} mh={10} ml={15} />
              <Box mr={21}>
                {this.renderInnerBox(
                  data.share_signups_count,
                  T.referral.subscriber,
                  <Icons.user fill={AppStore.theme.o.black} />
                )}
              </Box>
            </BorderContainerBox>
            {/* abbonamenti e crediti -------____-------____-------____-------____-------____-------____-------____-------____-------____-------____-------____-------____-------____-------____-------____-------____*/}
            <BorderContainerBox row style={{ gap: 34 }}>
              <Box style={{ marginLeft: 21 }}>
                {this.renderInnerBox(
                  AppStore.loggedUser.invitedNumSubscribers ?? 0,
                  T.referral.subscriber_plan,
                  <Icons.referalGift fill={AppStore.theme.o.green} />,
                  true
                )}
              </Box>
              <DashedLine style={{ minHeight: 46 }} green />
              {this.renderInnerBox(
                (data.balance / 100) * -1,
                'Disponibili',
                <Icons.creditCard fill={AppStore.theme.o.green} />,
                true,
                true
              )}
              <DashedLine style={{ minHeight: 46 }} />
              <Box style={{ marginRight: 21 }}>
                {this.renderInnerBox(
                  (AppStore.loggedUser.invitedNumSubscribers ?? 0) * 10,
                  'Guadagnati',
                  <Icons.creditCard fill={AppStore.theme.o.darkGrey} />,
                  false,
                  true,
                  true
                )}
              </Box>
            </BorderContainerBox>
          </Box>
        </Box>
      </Box>
    )
  }
}

const BorderContainerBox = styled(Box)<{ green?: boolean; ignoreGradiant?: boolean }>`
  border: 1px solid ${AppStore.theme.o.darkGrey};

  ${(props) => props.green && `border: 1px solid ${AppStore.theme.o.green};`}
  /* width: 311px; */
    ${(props) =>
    props.ignoreGradiant === undefined &&
    `
    background: linear-gradient(${AppStore.theme.o.surface}, ${AppStore.theme.o.surface}) padding-box,
                linear-gradient(to right, ${AppStore.theme.o.green}, ${AppStore.theme.o.darkGrey}) border-box;
    border-radius: 1em;
    border: 1px solid transparent;
    `}
    ${(props) =>
    props.ignoreGradiant !== undefined &&
    `
    background: linear-gradient(${AppStore.theme.o.background}, ${AppStore.theme.o.background}) ,

    `}


  padding: 8px;
  gap: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SocialButton = styled(Box)`
  justify-content: center;
  align-items: center;

  height: 48px;
  margin-bottom: 4px;
  margin-right: 16px;
  min-width: 275px;
  background: ${() => AppStore.theme.o.lightBlue};
  border-radius: 14px;
`

const CollumBox = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-gap: 2px;
  gap: 2px;
  column-gap: 90px;
  /* justify-content: space-between; */
`
const CopyLinkButton = styled(Box)`
  width: 154px !important;
  height: 52px !important;
  gap: 8px !important;
  background-color: ${() => AppStore.theme.o.blueOnLight};
`

const StepText = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: ${() => AppStore.theme.o.black};
`
const SectionText = styled.p`
  font-size: 21px;
  font-weight: 700;
  color: ${() => AppStore.theme.o.black};
`

/* ${({ flex }) => flexIfDefined(flex)}; */
const DashedLine = styled(Box)<{ green?: boolean }>`
  border: 1px dashed ${AppStore.theme.o.darkGrey};
  ${({ green }) => green && `border: 1px dashed ${AppStore.theme.o.green};`}
  width: 0px;
  height: 24px;
  left: 16px;
  top: 4px;
`
