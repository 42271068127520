/* ------------------- IMPORTS */
import { useCreatePostStore } from 'stores/CreatePostStore'
import { LabelsContent } from './LabelsContent'
import { useEffect, useState } from 'react'
import { ILabel } from './LabelsContentComponents/Label'
import { getActiveLabels, updateActive } from 'utils/PostUtils/Post'
import { showInfo, T } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {}

export const LabelsContainer = ({}: Props) => {
  /* ----------------- STATE  */
  const [selectedLabels, setSelectedLabels] = useState<ILabel[]>(getActiveLabels())

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */
  const { activePost } = useCreatePostStore((s) => ({ activePost: s.activePost }))

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */
  const handleAddLabel = (label: ILabel) => {
    // Verifica se la label è già stata aggiunta
    const isAlreadyAdded = selectedLabels?.find((lb) => lb._id === label._id)

    // Se la label è già stata aggiunta, non fare nulla
    if (isAlreadyAdded) {
      return showInfo(T.postPage.labelsSection.alreadyAdded)
    }

    // Altrimenti, aggiungi la label ai label selezionati
    const newSelected = [...selectedLabels!, { ...label }]

    updateActive({ labels: newSelected })
  }

  const handleRemoveLabel = (label: ILabel) => {
    const newSelected = selectedLabels?.filter((lb) => lb._id !== label._id)

    updateActive({ labels: newSelected })
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  useEffect(() => {
    setSelectedLabels(getActiveLabels()!)
  }, [activePost])

  return (
    <LabelsContent
      selectedLabels={selectedLabels}
      setSelectedLabels={setSelectedLabels}
      handleAddLabel={handleAddLabel}
      handleRemoveLabel={handleRemoveLabel}
      fromSection="createPost"
      data-html2canvas-ignore='true'
    />
  )
}
