/* ******************* IMPORTS ******************* */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { useState } from 'react'
import { AppStore, T } from 'utils'
import SelectedContent from './SelectedContent'
import DropdownBoards from './DropdownBoards'
import { TSidebarType } from '../aiDiscoverInterface'
import { truncateText } from '../commonFunction'
import { useDiscoverStore } from 'stores/DiscoverStore'

/* ******************* INTERFACE ******************* */

interface Props {
  searchContent?: string
  sidebarType: TSidebarType
}

const AsideContent = ({ searchContent, sidebarType }: Props) => {
  /* ____________________ STATE ____________________ */

  const [hoveredTopic, setHoveredTopic] = useState<any>(null)

  /* ____________________ ZUSTAND  ____________________ */
  const {
    setActiveTopicSidebar,
    suggestedTopicList,
    activeTopicSidebar,
    onChangeSubPage,
    activeTopicSuggestedSidebar,
    setActiveTopicSuggestedSidebar,
    setSelectedTopic,
    activeBoard,
    setActiveBoard,
  } = useDiscoverStore((state) => ({
    setActiveTopicSidebar: state.setActiveTopicSidebar,
    suggestedTopicList: state.suggestedTopicList,
    activeTopicSidebar: state.activeTopicSidebar,
    onChangeSubPage: state.onChangeSubPage,
    activeTopicSuggestedSidebar: state.activeTopicSuggestedSidebar,
    setActiveTopicSuggestedSidebar: state.setActiveTopicSuggestedSidebar,
    setSelectedTopic: state.setSelectedTopic,
    activeBoard: state.activeBoard,
    setActiveBoard: state.setActiveBoard,
  }))

  /* ____________________ VARIABLES ____________________ */
  const topics = AppStore.discoverUser?.topicsId ?? []

  return (
    <AsideContentContainer className={`${sidebarType === 'board' ? 'sidebar_board' : ''}`}>
      {/* Content sidebar Seguiti */}
      {sidebarType === 'follow' && (
        <>
          {/* Se un topic è selezionato va qua*/}
          {activeTopicSidebar && (
            <SelectedContent
              sidebarType={sidebarType}
              activeContentSidebar={activeTopicSidebar}
              setActiveContentSidebar={setActiveTopicSidebar}
            />
          )}

          {/* Lista dei topic*/}
          {topics
            .filter((topic) => topic.title?.toLowerCase().includes(searchContent!.toLowerCase()))
            .reverse()
            .map((item) => (
              <TopicItem
                onMouseLeave={() => setHoveredTopic(null)}
                key={item.id}
                onClick={() => {
                  onChangeSubPage('follow')
                  setActiveTopicSidebar(item)
                }}
                className={`${activeTopicSidebar?._id === item._id ? 'selected__content' : ''}
              ${hoveredTopic === item.id ? 'hovered__content' : ''}`}
              >
                <div className="topic__item__header">
                  <div>
                    <Icons.starDiscover /> {truncateText(item.title, 11)}
                  </div>

                  <LanguageBoxContainer>{item.language.toUpperCase()}</LanguageBoxContainer>

                  {item.description && (
                    <div
                      className={`info__icon ${hoveredTopic ? 'info__icon__hovered' : ''}`}
                      onMouseEnter={() => setHoveredTopic(item.id)}
                    >
                      <Icons.info fill={AppStore.theme.o.black} />
                    </div>
                  )}
                </div>

                <span className={`topic__tooltip ${hoveredTopic === item.id ? 'visible__topic__tooltip' : ''}`}>
                  {item.description}
                </span>
              </TopicItem>
            ))}

          {/* Se non trovi risultati dopo il filtraggio */}
          {topics.filter((topic) => topic.title?.toLowerCase().includes(searchContent!.toLowerCase())).length <= 0 && (
            <NoResultsMessage>{T.aiDiscoverPage.sidebar.noResultsFound}</NoResultsMessage>
          )}
        </>
      )}

      {/* Content sidebar Suggeriti */}
      {sidebarType === 'suggested' && (
        <>
          {/* Se un topic è selezionato va qua*/}
          {activeTopicSuggestedSidebar && (
            <SelectedContent
              sidebarType={sidebarType}
              activeContentSidebar={activeTopicSuggestedSidebar}
              setActiveContentSidebar={setActiveTopicSuggestedSidebar}
            />
          )}
          {/* Qui le vengono i contenuti */}
          {suggestedTopicList.map((item) => (
            <TopicItem
              onMouseLeave={() => setHoveredTopic(null)}
              key={item._id}
              onClick={() => {
                setSelectedTopic(item)
                setActiveTopicSuggestedSidebar(item)
              }}
              className={`${activeTopicSuggestedSidebar?._id === item._id ? 'selected__content' : ''}
              ${hoveredTopic === item._id ? 'hovered__content' : ''}`}
            >
              <div className="topic__item__header">
                <div>
                  <Icons.starDiscover /> {truncateText(item.title, 11)}
                </div>

                <LanguageBoxContainer>{item.language.toUpperCase()}</LanguageBoxContainer>

                {item.description && (
                  <div
                    className={`info__icon ${hoveredTopic ? 'info__icon__hovered' : ''}`}
                    onMouseEnter={() => setHoveredTopic(item._id)}
                  >
                    <Icons.info fill={AppStore.theme.o.black} />
                  </div>
                )}
              </div>

              <span className={`topic__tooltip ${hoveredTopic === item._id ? 'visible__topic__tooltip' : ''}`}>
                {item.description}
              </span>
            </TopicItem>
          ))}
        </>
      )}

      {/* Content sidebar Board */}
      {sidebarType === 'board' && (
        <>
          {/* Se una board è selezionato va qua*/}
          {activeBoard && (
            <SelectedContent
              sidebarType={sidebarType}
              activeContentSidebar={activeBoard}
              setActiveContentSidebar={setActiveBoard}
            />
          )}

          {/* Drop down con 3 categorie, con le varie board all'interno */}
          <DropdownBoards activeBoard={activeBoard} setActiveBoard={setActiveBoard} searchContent={searchContent} />
        </>
      )}
    </AsideContentContainer>
  )
}

export default AsideContent

const AsideContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 500px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Tolta scrollbar quando siamo nella board per problemi di switching quando viene messo e tolto un elemento */
  &.sidebar_board {
    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: 0px;
    }
  }
`

const TopicItem = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  padding: 8px 12px 8px 8px;
  margin-right: 10px;

  border-radius: 14px;
  border: 1px solid ${() => AppStore.theme.o.grey};
  background: ${() => AppStore.theme.o.lightestGrey};

  transition: all 500ms ease-in-out;

  svg {
    fill: ${() => AppStore.theme.o.black} !important;
  }

  /* Quando il contenuto è selezionato e diventa grigio */
  &.selected__content {
    transition: 200ms ease-in-out;
    background: ${() => AppStore.theme.o.lightGrey} !important;
    border: transparent;

    & > .topic__item__header {
      color: ${() => AppStore.theme.o.darkGrey} !important;
      svg {
        fill: ${() => AppStore.theme.o.darkGrey} !important;
      }
      .info__icon {
        background-color: transparent;
        svg {
          fill: ${() => AppStore.theme.o.darkGrey} !important;
        }
      }
    }

    .topic__tooltip {
      color: ${() => AppStore.theme.o.darkGrey} !important;
    }
  }

  & .topic__item__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${() => AppStore.theme.o.black};

    font-size: 14px;

    font-weight: 700;
    line-height: normal;

    div {
      gap: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .info__icon {
    width: 20px;
    height: 20px;
    border-radius: 8px;
    opacity: 0;
  }

  :hover {
    &.hovered__content {
      background: ${() => AppStore.theme.o.surface};
    }
    .info__icon {
      opacity: 1;
    }

    .info__icon__hovered {
      background-color: ${() => AppStore.theme.o.lightGreen};
      svg {
        fill: ${() => AppStore.theme.o.green};
      }
    }
  }

  .topic__tooltip {
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    height: 0px;

    transition: all 300ms ease-in-out;

    border-radius: 14px;
    background: ${() => AppStore.theme.o.lightGrey};

    color: ${() => AppStore.theme.o.black};

    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    &.visible__topic__tooltip {
      margin-top: 8px;
      padding: 16px;
      height: auto;
      visibility: visible;
      opacity: 1;
    }
  }
`

const LanguageBoxContainer = styled(Box)`
  margin-left: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 30px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid ${() => AppStore.theme.o.grey};

  font-size: 8px;
  letter-spacing: 1px;
`

const NoResultsMessage = styled(Box)`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 14px;
  background: ${() => AppStore.theme.o.lightestGrey};

  color: ${() => AppStore.theme.o.black};
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
