import styled from '@emotion/styled'
import Utils from 'api/Utils'
import { postMarkAllNotificationAsRead } from 'api/service/notifyServices'
import { Box } from 'components/Box'
import { Icons } from 'components/Icons'
import { useEffect, useState } from 'react'
import { useNotifyStore } from 'stores/NotifyStore'
import { AppStore, T } from 'utils'
import { possibleTypeOfNotifyEN } from 'utils/mock/notifyMock'
import ChipNotify from './ChipNotify'
import FourDotsMenu from './FourDotsMenu'
import ScrollNotifyContainer from './ScrollNotifyContainer'
import ToggleNotify from './ToggleNotify'

/* ___________________ INTERFACE ___________________ */
interface Props {
  isOpen: boolean
  isFourDotMenuOpen: boolean
  handleFourDotMenuOpen: () => void
}

const NotifyContainerAbsolute = ({ isOpen, isFourDotMenuOpen, handleFourDotMenuOpen }: Props) => {
  /* ____________________ ZUSTAND ____________________ */
  const { addAllNotificationReader, clearNotifications } = useNotifyStore.getState()

  /* ____________________ STATE ____________________ */
  const [activeCategory, setActiveCategory] = useState<string[]>([])
  const [onlyUnread, setOnlyUnread] = useState<boolean>(false)

  /* ____________________ VARIABLES ____________________ */
  const notifyCategory = possibleTypeOfNotifyEN

  /* ____________________ METHODS ____________________ */
  /**
   *  gestisce lo state con l'array di stringhe che contiene le categorie attive
   *
   * @param cat string
   * @returns
   */
  const handleActiveCategory = (cat: string) => {
    if (activeCategory.includes(cat)) {
      setActiveCategory((prev) => prev.filter((el) => el !== cat))
      return
    }
    setActiveCategory((prev) => [...prev, cat])
  }

  const handleToggle = () => {
    setOnlyUnread((prev) => !prev)
  }

  const resetAll = () => {
    setActiveCategory([])
    setOnlyUnread(false)
  }

  /* ____________________ API CALL ____________________ */
  const postMarkAllNotificationsToReaded = () => {
    clearNotifications()
    postMarkAllNotificationAsRead()
  }

  /* ____________________ USEEFFECT ____________________ */
  useEffect(() => {
    if (!isOpen) resetAll()
  }, [isOpen])

  return (
    <ContainerAbsolute className={`notify-container ${isOpen ? 'visible' : 'not-visible'}`}>
      {/* ____________ TITLE / DOT_MENU_ICON CONTAINER ____________ */}
      <NotifyTitleContainer>
        <h3 className="title-notify">{T.notifications}</h3>
        <IconMenuNotify id="four-dots-menu" className="icon-menu">
          <Icons.fourDotMenu onClick={handleFourDotMenuOpen} />
          {isFourDotMenuOpen && <FourDotsMenu onReadAll={postMarkAllNotificationsToReaded} />}
        </IconMenuNotify>
      </NotifyTitleContainer>

      {/* ____________ NOTIFY CATEGORY CONTAINER ____________ */}
      <ChipContainer id="chip-container-notify">
        {notifyCategory.map((cat) => (
          <ChipNotify
            key={Utils.generateRandomUIDKey()}
            name={cat.toLowerCase()}
            activeCategory={activeCategory}
            handleActiveCategory={handleActiveCategory}
          />
        ))}
      </ChipContainer>

      {/* ____________ TOGGLE NOTIFY READED ____________ */}
      <ToggleContainer>
        {T.notifyTexts.showUnreadNotifications} <ToggleNotify isOn={onlyUnread} handleToggle={handleToggle} />
      </ToggleContainer>

      {/* ____________ NOTIFY SCROLL CONTAINER ____________ */}
      <ScrollNotifyContainer filters={{ activeCategory, onlyUnread }} />
    </ContainerAbsolute>
  )
}

export default NotifyContainerAbsolute

const ContainerAbsolute = styled(Box)`
  background-color: ${() => AppStore.theme.o.surface};
  position: absolute;
  top: calc(100% - 24px);
  right: 0;
  width: 0;
  min-height: 0;
  max-height: 0;
  overflow: hidden;
  border-radius: 14px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
  box-shadow: ${() => AppStore.theme.finalNavbarBoxShadow.slice(11)};
  transition: 0.3s ease-in-out;
  filter: blur(5px);

  div {
    opacity: 0;
    transition: 0.3s ease-in-out;
  }

  &.visible {
    width: 492px;
    min-height: 325px;
    max-height: 750px;
    padding: 32px 40px 24px 40px;
    transform: translateY(36px);
    filter: blur(0);

    div {
      opacity: 1;
    }
  }
`
const NotifyTitleContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  max-height: 27px;
  border-radius: 0;

  h3 {
    font-size: 21px;
    font-weight: 700;
    line-height: 26.88px;
    letter-spacing: 0.21px;
    color: ${() => AppStore.theme.o.black};
    margin: 0;
  }
`
const IconMenuNotify = styled(Box)`
  width: fit-content;
  height: fit-content;
  cursor: pointer;

  svg {
    width: 15px !important;
    height: 15px !important;
    min-width: 15px;
    min-height: 15px;
    transition: all 0.2s ease-in-out;

    path {
      fill: ${() => AppStore.theme.o.darkGrey} !important;
    }

    &:hover {
      scale: 1.1;
    }

    &:active {
      scale: 0.9;
    }
  }
`
const ChipContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  overflow: hidden;
  max-height: 64px;
  border-radius: 0;
`
const ToggleContainer = styled(Box)`
  color: ${() => AppStore.theme.o.darkGrey};
  font-size: 12px;
  font-weight: 600;
  line-height: 15.36px;
  letter-spacing: 0.12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 0;
  border-radius: 0;
`
