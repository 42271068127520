/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Calendar as AntCalendar } from 'antd'
import locale from 'antd/es/date-picker/locale/it_IT'
import PostV2 from 'api/PostV2'
import Utils, { getPostsByDate } from 'api/Utils'
import { Box } from 'components'
import moment from 'moment'
import SkeletonCard from 'pages/ai-discover/UI/SkeletonCard'
import { useEffect, useState } from 'react'
import { useCalendarStore } from 'stores/CalendarStore'
import { T } from 'utils'
import { CalendarCell } from './CalendarCell'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {}

export const CalendarMonth = ({}: Props) => {
  /* ----------------- STATE  */
  const [loading, setLoading] = useState<boolean>(true)

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */
  const {
    update,
    status,
    labelType,
    labels,
    socials,
    month,
    year,
    startDateCalendar,
    endDateCalendar,
    calendarPost,
    calendarSection,
    createdFrom,
  } = useCalendarStore((s) => ({
    update: s.update,
    status: s.status,
    labelType: s.labelType,
    labels: s.labels,
    socials: s.socials,
    month: s.month,
    year: s.year,
    startDateCalendar: s.startDateCalendar,
    endDateCalendar: s.endDateCalendar,
    calendarPost: s.calendarPost,
    calendarSection: s.calendarSection,
    createdFrom: s.createdFrom,
  }))

  const { setStartDateCalendar, setEndDateCalendar, forceUpdate, setCalendarPost } = useCalendarStore()

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */
  /**
   * Function to get the first and last day of the calendar
   */
  const getFirstAndLastDay = () => {
    const calendar = document.getElementsByClassName('custom-content')
    if (!calendar) return

    const tbody = calendar[0]?.childNodes[1]

    const firstRow = tbody?.childNodes[0]
    const lastRow = tbody?.childNodes[5]

    const firstDay = firstRow?.childNodes[0]
    const lastDay = lastRow?.childNodes[6]

    if (firstDay instanceof HTMLElement && firstDay.hasAttribute('title')) {
      const firstDayTitle = firstDay.title
      const firstDateFormat = new Date(firstDayTitle)
      setStartDateCalendar(firstDateFormat)
    }

    if (lastDay instanceof HTMLElement && lastDay.hasAttribute('title')) {
      const lastDayTitle = lastDay.title
      const lastDateFormat = new Date(lastDayTitle)
      setEndDateCalendar(lastDateFormat)
    }

    forceUpdate()
  }

  /**
   * Funzione per renderizzare i post all'interno delle celle del calendario
   * @param date Data presa dal calendar
   * @returns Componente da renderizzare nella cella
   */
  const handleCellRender = (date: moment.Moment) => {
    if (loading) {
      return <SkeletonCard key={Utils.generateRandomUIDKey()} num={1} type="calendarCell" />
    } else {
      return <CalendarCell date={date} postData={getPostsByDate(date, calendarPost)} />
    }
  }

  /* ----------------- API CALL  */
  const getMonthPostsWithFilters = async () => {
    if (!startDateCalendar || !endDateCalendar) return

    setLoading(true)

    const labelParam =
      labels.length === 0
        ? undefined
        : {
            type: labelType,
            labels: labels.map((label) => label._id),
          }

    const params = {
      startDate: startDateCalendar,
      endDate: endDateCalendar,
      filters: {
        social: socials,
        status: status,
        labels: labelParam,
        createdFrom: createdFrom,
      },
    }

    try {
      const res = await PostV2.getPostsWithFilters(params)
      setCalendarPost(res.data!)
    } catch (e) {
      console.error(e)
    }

    setLoading(false)
  }

  /* ----------------- USEEFFECT  */
  // Setting the start and end date of the calendar
  useEffect(() => {
    //Set Timeout per evitare che il DOM non sia ancora stato caricato
    setLoading(true)
    setTimeout(() => {
      getFirstAndLastDay()
    }, 0)
  }, [month])

  // UseEffect per fetchare i post del mese
  useEffect(() => {
    if (calendarSection !== 'month') return
    getMonthPostsWithFilters()
  }, [update, socials, status, labelType, calendarSection, createdFrom])

  //  Settaggio della lingua
  useEffect(() => {
    moment.updateLocale('it', {
      weekdaysMin: T.calendarPage.weekdaysMin,
    })
  }, [])

  return (
    <AntCalendar
      value={moment({ year, month, day: 1 })}
      locale={locale}
      headerRender={() => <></>}
      dateFullCellRender={(date: moment.Moment) => handleCellRender(date)}
      prefixCls={'custom'}
    />
  )
}
