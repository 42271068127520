import { Box, Text } from 'components'
import { useState } from 'react'
import { AppStore } from 'utils'
import { initialDays } from 'utils/SettingPageUtils'

interface Props {
  social?: boolean
}

export const Days = ({ social }: Props) => {
  const [days, setdays] = useState(JSON.parse(JSON.stringify(initialDays)))

  return (
    <Box vcenter row ml={10}>
      {days.map((day, index) => (
        <Text
          key={index}
          style={{ marginRight: 30 }}
          color={day.active ? AppStore.theme.o.black : AppStore.theme.o.darkGrey}
          weight={day.active ? 'bold' : 'normal'}
          onClick={() => {
            const newDays = JSON.parse(JSON.stringify(days))
            newDays[index].active = !newDays[index].active
            setdays(newDays)
          }}
        >
          {day.day}
        </Text>
      ))}
    </Box>
  )
}
