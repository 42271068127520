/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { SelectButton } from 'components/UI/SelectButton'
import { useState } from 'react'
import { AppStore, T } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  inputValue: string
  setInputValue: (value: string) => void
  numberOfLabels: number
  handleSelectAll: () => void
  isAllSelected: boolean
}

export const HeaderLabelModal = ({
  inputValue,
  setInputValue,
  numberOfLabels,
  handleSelectAll,
  isAllSelected,
}: Props) => {
  /* ----------------- STATE  */

  const [isHover, setIsHover] = useState<boolean>(false)

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */
  const onChangeInput = (e) => {
    setInputValue(e.target.value)
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <HeaderLabelModalContainer>
      <TitleContainer>
        <Title>{T.postPage.labelsSection.manageLabels}</Title>

        <LabelNumber>{numberOfLabels}</LabelNumber>
      </TitleContainer>

      <SearchInputContainer>
        <Icons.search fill={AppStore.theme.o.darkGrey} />

        <SearchInput value={inputValue} onChange={onChangeInput} placeholder={T.postPage.search} />
      </SearchInputContainer>

      <SelectAllButtonContainer
        onClick={handleSelectAll}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        style={{
          backgroundColor: isAllSelected ? AppStore.theme.o.secondary_container : AppStore.theme.o.surface,
          borderColor: isAllSelected ? AppStore.theme.o.on_secondary_container : AppStore.theme.o.grey,
          color: isAllSelected ? AppStore.theme.o.on_secondary_container : AppStore.theme.o.black,
        }}
      >
        {T.calendarPage.selectAll}

        <SelectButton action={handleSelectAll} selected={isAllSelected} containerIsHover={isHover} />
      </SelectAllButtonContainer>
    </HeaderLabelModalContainer>
  )
}

const HeaderLabelModalContainer = styled(Box)`
  width: 100%;
  gap: 16px;
`

const TitleContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Title = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.21px;
`

const LabelNumber = styled(Box)`
  color: ${() => AppStore.theme.o.darkGrey};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

const SearchInputContainer = styled(Box)`
  gap: 12px;
  padding: 12px 16px;
  align-items: center;
  flex-direction: row;

  border-radius: 14px;
  border: 1px solid ${() => AppStore.theme.o.grey};
  background: ${() => AppStore.theme.o.lightestGrey};
`

const SearchInput = styled.input`
  width: 100%;
  border: none;
  background: transparent;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${() => AppStore.theme.o.black};
`

const SelectAllButtonContainer = styled(Box)`
  height: 48px;
  display: flex;
  padding: 8px 16px 8px 16px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 14px;
  border: 1px solid ${() => AppStore.theme.o.grey};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;

  &:hover {
    background: ${() => AppStore.theme.o.lightestGrey} !important;
  }
`
