/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Stepper } from 'components'
import { IReportConfig, TSubsection } from 'types/AnalyticsInferfaces'
import { useEffect, useState } from 'react'
import { CheckboxSocialContainer } from '../UI/CheckboxSocialContainer'
import { CheckboxMetricContainer } from '../UI/CheckboxMetricContainer'
import { TStepOption } from 'components/Stepper'
import { T } from 'utils'
import { useParams } from 'react-router-dom'
import { useAnalyticsStore } from 'stores/AnalyticsStore'

/* ------------------- INTERFACES  */
interface Props {
  subsection: TSubsection
  accountIdList: string[]
}

export const CreateReportHeader = ({ subsection, accountIdList }: Props) => {
  /* ____________________ ROUTER ____________________ */
  const { reportId } = useParams()

  /* ____________________ ZUSTAND ____________________ */
  const reportPreview = useAnalyticsStore((state) => state.reportPreview)

  /* ----------------- STATE  */
  const [reportSteps, setReportSteps] = useState<TStepOption[]>([
    {
      key: 'setup',
      label: 'Set-up',
      done: true,
      active: true,
    },
    {
      key: 'social',
      label: 'Social',
      done: false,
      active: false,
    },
    {
      key: 'metrics',
      label: T.analytics.metrics,
      done: false,
      active: false,
    },
  ])

  /* ----------------- METHODS  */
  const updateReportSteps = () => {
    const newReportSteps = reportSteps.map((step) => {
      if (step.key === subsection) {
        return {
          ...step,
          active: true,
          done: true,
        }
      } else {
        return {
          ...step,
          active: false,
          done: false,
        }
      }
    })

    setReportSteps(newReportSteps)
  }

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    updateReportSteps()
  }, [subsection])

  return (
    <CreateReportHeaderContainer className="create_report_header">
      {/* SE REPORTID O IL REPORT GIà ESISTE NON FACCIO VEDERE GLI STEPPER */}
      {reportId || reportPreview ? (
        <></>
      ) : (
        <Stepper style={{ width: 550, minHeight: 40, maxHeight: 40 }} steps={reportSteps} />
      )}
      {subsection === 'social' && <CheckboxSocialContainer />}
      {subsection === 'metrics' && <CheckboxMetricContainer accountIdList={accountIdList} />}
    </CreateReportHeaderContainer>
  )
}

const CreateReportHeaderContainer = styled(Box)`
  &.create_report_header {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
