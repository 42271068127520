import styled from '@emotion/styled'
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { Icons } from 'components/Icons'
import { Text } from 'components/Text'
import { Component, ErrorInfo } from 'react'
import { AppStore } from 'utils'
import { navigate } from 'utils/router'
interface Props {
  goNext: any
}

export default class FirstPage extends Component<Props> {
  render() {
    return (
      <Box flex center>
        <Container>
          <Box center>
            <Text style={{ marginBottom: 8 }} weight={700} fontSize={16}>
              Un caloroso benvenuto in
            </Text>
            <MiniBox>
              <Icons.logoAstersFullSmall style={{ position: 'absolute', minWidth: 120, minHeight: 90 }} />
            </MiniBox>
            <Text style={{ fontSize: 16, fontWeight: 700, textAlign: 'center' }}>
              Asters è l'assistente social con intelligenza artificiale al tuo servizio
            </Text>
          </Box>
        </Container>
        <Box center mt={72}>
          <Button
            variant="primary-small"
            width={329}
            height={52}
            onClick={() => {
              !navigate('/demo/step2')
              this.props.goNext()
            }}
          >
            <Text style={{ fontSize: 16, fontWeight: 700, color: AppStore.theme.o.surface, zIndex: 999 }}>
              Prova la magia
            </Text>
          </Button>
        </Box>
        {/* <Box width={'2vw'} height={'15vh'}></Box> */}
      </Box>
    )
  }
}

const Container = styled(Box)`
  width: 90vw;
  max-width: 375px;
  padding: 20px;
  background: #ffffff;
`
const MiniBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  min-width: 232px;
  min-height: 82px;
  margin-bottom: 56px;
  background: #ffffff;
  position: relative;
  box-shadow: 4px 8px 16px rgba(38, 42, 72, 0.14);
  border-radius: 14px;
`
