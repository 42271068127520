import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { validateSubscription } from 'utils/UsersUtils'

/**
 * Componente per la verifica della subscription
 * @returns
 */
export function SubscriptionChecker() {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    validateSubscription(navigate)
  }, [location.pathname])

  return null
}
