/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { DEFAULT_POST } from 'utils/Constants/CreatePostConstants'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { IPostVariant } from 'types/PostInterface'
import { AppStore, showInfo } from 'utils'
import { getPostByAccountIdType } from 'utils/PostUtils/Post'
import { checkIsAccountInPosts, onClickSocialCard } from 'utils/PostUtils/SocialPost'

/* ------------------- INTERFACES  */
interface Props {}

export const YoutubeSwitchPreview = ({}: Props) => {
  /* ----------------- ZUSTAND  */
  const { activePost, setActivePost, activePreview, setActivePreview, setActivePreviewYT } = useCreatePostStore(
    (s) => ({
      activePost: s.activePost as IPostVariant,
      setActivePost: s.setActivePost,
      activePreview: s.activePreview,
      setActivePreview: s.setActivePreview,
      setActivePreviewYT: s.setActivePreviewYT,
    })
  )

  const isActivePost = activePreview === 'YOUTUBE_STANDARD'
  const isActiveShort = activePreview === 'YOUTUBE_SHORT'

  // const isVariantPostPresent = checkIsAccountInPosts(activePost?.info?.account?.account_id, 'STANDARD')
  // const isVariantShortPresent = checkIsAccountInPosts(activePost?.info?.account?.account_id, 'SHORT')

  // const isOnePresent =
  //   (isVariantPostPresent && !isVariantShortPresent) || (!isVariantPostPresent && isVariantShortPresent)

  // const onActiveShort = () => {
  //   if (isOnePresent && isVariantPostPresent) return showInfo('Almeno uno dei due deve essere presente')
  //   if (isVariantPostPresent)
  //     setActivePost(getPostByAccountIdType(activePost.info.account.account_id, 'SHORT') ?? DEFAULT_POST)

  //   onClickSocialCard(
  //     {
  //       _id: activePost.info.account.account_id,
  //       name: activePost.info.account.name,
  //       picture: activePost.info.account.picture,
  //       provider: activePost.info.account.provider,
  //     },
  //     'STANDARD'
  //   )
  // }

  // const onActivePost = () => {
  //   if (isOnePresent && isVariantShortPresent) return showInfo('Almeno uno dei due deve essere presente')

  //   if (isVariantShortPresent)
  //     setActivePost(getPostByAccountIdType(activePost.info.account.account_id, 'STANDARD') ?? DEFAULT_POST)

  //   onClickSocialCard(
  //     {
  //       _id: activePost.info.account.account_id,
  //       name: activePost.info.account.name,
  //       picture: activePost.info.account.picture,
  //       provider: activePost.info.account.provider,
  //     },
  //     'SHORT'
  //   )
  // }

  // const onPostSwitch = () => {
  //   if (!checkIsAccountInPosts(activePost.info.account.account_id, 'STANDARD')) {
  //     onClickSocialCard(
  //       {
  //         _id: activePost.info.account.account_id,
  //         name: activePost.info.account.name,
  //         picture: activePost.info.account.picture,
  //         provider: activePost.info.account.provider,
  //       },
  //       'STANDARD'
  //     )
  //   }

  //   setActivePost(getPostByAccountIdType(activePost.info.account.account_id, 'STANDARD') ?? DEFAULT_POST)
  // }

  // const onShortSwitch = () => {
  //   if (!checkIsAccountInPosts(activePost.info.account.account_id, 'SHORT'))
  //     onClickSocialCard(
  //       {
  //         _id: activePost.info.account.account_id,
  //         name: activePost.info.account.name,
  //         picture: activePost.info.account.picture,
  //         provider: activePost.info.account.provider,
  //       },
  //       'SHORT'
  //     )

  //   setActivePost(getPostByAccountIdType(activePost.info.account.account_id, 'SHORT') ?? DEFAULT_POST)
  // }

  return (
    <YoutubeSwitchPreviewContainer>
      <ContainerCheckBoxs>
        {/* Set Post */}
        {/* <CheckBoxButton active={isVariantPostPresent} onClick={() => onActiveShort()}>
          <Check active={isVariantPostPresent} />
        </CheckBoxButton> */}

        {/* Set SHORT */}
        {/* <CheckBoxButton active={isVariantShortPresent} onClick={() => onActivePost()}>
          <Check active={isVariantShortPresent} />
        </CheckBoxButton> */}
        <Spacer />
      </ContainerCheckBoxs>

      <YoutubeSwitchContent>
        <ContainerButton
          onClick={() => {
            setActivePreview('YOUTUBE_STANDARD')
            setActivePreviewYT('YOUTUBE_STANDARD')
          }}
          active={isActivePost}
        >
          <Icons.metaGrid /> Video
        </ContainerButton>

        <ContainerButton
          onClick={() => {
            setActivePreview('YOUTUBE_SHORT')
            setActivePreviewYT('YOUTUBE_SHORT')
          }}
          active={isActiveShort}
        >
          <Icons.metaStories />
          Short
        </ContainerButton>
      </YoutubeSwitchContent>
    </YoutubeSwitchPreviewContainer>
  )
}

const YoutubeSwitchPreviewContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`

const YoutubeSwitchContent = styled(Box)`
  width: 130px;
  border-radius: 14px;
  background: ${() => AppStore.theme.o.surface};
  padding: 16px;
  gap: 12px;
  box-shadow: ${() => AppStore.theme.smallOuterShadow};
`

const ContainerButton = styled(Box)<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  border-radius: 14px;
  padding: 4px 16px;
  text-align: center;
  font-family: Aktiv Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;

  background: ${({ active }) => (active ? () => AppStore.theme.o.lightGreen : () => AppStore.theme.o.lightGrey)};
  color: ${({ active }) => (active ? () => AppStore.theme.o.green : () => AppStore.theme.o.black)};

  &:hover {
    background: ${() => AppStore.theme.o.lightGreen};
    color: ${() => AppStore.theme.o.green};
  }
`

const ContainerCheckBoxs = styled(Box)`
  gap: 12px;
`

const CheckBoxButton = styled(Box)<{ active: boolean }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: ${() => AppStore.theme.smallOuterShadow};
  background: ${({ active }) => (active ? () => AppStore.theme.o.lightGreen : () => AppStore.theme.o.surface)};

  &:hover {
    background: ${() => AppStore.theme.o.lightGreen};
  }
`

const Check = styled(Box)<{ active: boolean }>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  cursor: pointer;
  background: ${({ active }) => (active ? () => AppStore.theme.o.green : () => AppStore.theme.o.grey)};

  &:hover {
    background: ${() => AppStore.theme.o.green};
  }
`

const Spacer = styled(Box)`
  width: 30px;
`
