import { useAppStore } from 'stores/AppStore'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { AppStore, showInfo, T } from 'utils'
import AstersBaseResource from './AstersBaseResource'
import { api, responseErrorCheck } from './api'
import { IPostVariant } from 'types/PostInterface'

export default class PostHistory extends AstersBaseResource {
  static endpoint = '/postHistory'

  static paginate<T>(searchParams: any, paginationConfig?: null, requestConfig?: undefined) {
    //add workspace to searchParams
    searchParams.workspace = AppStore.workspace._id
    return super.paginate<T>(searchParams, paginationConfig, requestConfig)
  }

  static publishV2(singlePost?: IPostVariant) {
    try {
      const { activeWorkspace } = useAppStore.getState()
      const { posts } = useCreatePostStore.getState()

      const data = singlePost ? [singlePost] : posts

      const newPost = JSON.parse(JSON.stringify(data))

      newPost.forEach((post) => {
        if (post.data.status !== 'draft') {
          post.data.date = post.data.date ? post.data.date : new Date()
        }
      })

      const dataObj = {
        posts: newPost,
      }

      return api.post(`/postHistory/${activeWorkspace?._id}/createNewPosts`, dataObj).then(responseErrorCheck)
    } catch (err) {
      console.error(err)
    }
  }

  // -----------------  DELETE IL POSTONE  -----------------
  static DeletePost(id) {
    return api.delete(`${this.endpoint}/${id.id._id}`).then(responseErrorCheck)
  }

  // -----------------  DELETE IL POSTONE  -----------------
}
