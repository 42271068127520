/* ___________________ IMPORTS ___________________ */
import styled from '@emotion/styled'
import { Box, Button, Icons } from 'components'
import { AppStore, T } from 'utils'
import { TEmptyState } from '../aiDiscoverInterface'
import { enumPrivateOrSharedSingular } from '../commonFunction'
import { useDiscoverStore } from 'stores/DiscoverStore'

/* ___________________ INTERFACE ___________________ */
interface Props {
  type: TEmptyState
  openNewBoardHandler?: () => void
}

const EmptyState = ({ type, openNewBoardHandler }: Props) => {
  /* ____________________ ZUSTAND  ____________________ */
  const { onChangeSubPage, activeDropdown } = useDiscoverStore((state) => ({
    onChangeSubPage: state.onChangeSubPage,
    activeDropdown: state.activeDropdown,
  }))

  /* ____________________ METHODS ____________________ */
  const focusInput = () => {
    onChangeSubPage('search')
    setTimeout(() => document.getElementById('inputSearchBar')!.focus(), 150)
  }

  /* ____________________ ENUM ____________________ */
  const EnumEmptyState = {
    follow: (
      <EmptyContainer>
        <NoContentContainer>
          {T.AiDiscoverPage.followPage.noFollowAvailable}

          <Icons.sadStar fill={AppStore.theme.o.darkGrey} />
        </NoContentContainer>

        <FollowFirstTopic onClick={focusInput}>{T.AiDiscoverPage.followPage.followYourFirstTopic}</FollowFirstTopic>
      </EmptyContainer>
    ),
    board: (
      <EmptyContainer bgGrey>
        <NoContentContainer>
          {T.AiDiscoverPage.boardCard.noBoardText}

          <Icons.sadBoard fill={AppStore.theme.o.darkGrey} />
        </NoContentContainer>
        <FollowFirstTopic onClick={openNewBoardHandler}>
          {T.AiDiscoverPage.boardCard.createNewBoard} {enumPrivateOrSharedSingular[activeDropdown].toLowerCase()}
        </FollowFirstTopic>
      </EmptyContainer>
    ),
    boardArticle: (
      <EmptyContainer>
        <NoContentContainer>
          {T.AiDiscoverPage.boardPage.noArticle}

          <Icons.sadBoard fill={AppStore.theme.o.darkGrey} />
        </NoContentContainer>

        <FollowFirstTopic onClick={focusInput}>{T.AiDiscoverPage.boardPage.SearchFirstArticle}</FollowFirstTopic>
      </EmptyContainer>
    ),
    noResults: (
      <EmptyContainer>
        <NoContentContainer>
          {T.aiDiscoverPage.searchContent.noResults}

          <Icons.sadSearchbar fill={AppStore.theme.o.darkGrey} />
        </NoContentContainer>
      </EmptyContainer>
    ),
  }

  return EnumEmptyState[type]
}

export default EmptyState

const EmptyContainer = styled(Box)`
  margin-top: 16px;
  display: flex;
  width: 1164px;
  max-width: 100%;
  height: 400px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  border-radius: 24px;
  background: ${() => AppStore.theme.o.lightestGrey};
`

const NoContentContainer = styled(Box)`
  min-width: 200px;
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  border-radius: 14px;
  background: ${() => AppStore.theme.o.lightGrey};

  color: ${() => AppStore.theme.o.black};
  text-align: center;
  font-family: Aktiv Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

const FollowFirstTopic = styled(Button)`
  padding: 8px 24px !important;
  min-height: 24px !important;
  color: ${() => AppStore.theme.o.blue};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`
