import styled from '@emotion/styled'
import { Component } from 'react'
import { AnimatedPlaceholderLogo } from './AnimatedPlaceholderLogo'
import { Box } from './Box'

interface State {
  loading: boolean
}

export class MediaImage extends Component<any, State> {
  state: State = {
    loading: !this.props.media?.mimetype?.includes('video') ?? false,
  }

  render() {
    const { media, finishLoad, fullRadius, ...rest } = this.props
    const { loading } = this.state

    return (
      <>
        {loading && (
          <Box {...(rest as any)}>
            <AnimatedPlaceholderLogo style={{ width: 70, height: 70 }} />
          </Box>
        )}
        {!media?.mimetype?.includes('video') && (
          <Image
            onLoad={() => this.setState({ loading: false })}
            style={{
              objectFit: 'cover',
              borderTopLeftRadius: 14,
              borderTopRightRadius: 14,
              borderBottomLeftRadius: fullRadius ? 14 : fullRadius ? 14 : 0,
              borderBottomRightRadius: fullRadius ? 14 : 0,
              padding: 1,
              display: loading ? 'none' : 'unset ',
            }}
            src={media?.url}
            {...rest}
          />
        )}
        {media?.mimetype?.includes('video') && (
          <video
            {...rest}
            className="video-media"
            controls
            onLoadedData={() => {
              try {
                finishLoad()
              } catch (e) {}
            }}
            style={{
              borderTopLeftRadius: 14,
              borderTopRightRadius: 14,
              borderBottomLeftRadius: fullRadius ? 14 : 0,
              borderBottomRightRadius: fullRadius ? 14 : 0,
            }}
            src={media.url}
          />
        )}
      </>
    )
  }
}

const Image = styled.img`
  min-height: 100px !important;
  max-height: 400px;
`
