/* ___________________ IMPORTS ___________________ */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'

const InfiniteLoader = () => {
  return (
    <SpinnerContainer>
      <SpinnerLogo>
        <Icons.logo />
      </SpinnerLogo>
    </SpinnerContainer>
  )
}

export default InfiniteLoader

const SpinnerContainer = styled(Box)`
  width: 100%;
  margin: 0 auto;
  padding: 24px;
`

const SpinnerLogo = styled(Box)`
  margin: 0 auto;

  animation: spin 1.5s linear infinite;

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`
