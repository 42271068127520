import styled from '@emotion/styled'
import { Box } from 'components'
import { AppStore } from 'utils'

/* ******************* TYPE ******************* */

/* ******************* INTERFACE ******************* */
interface Props {
  options: string[]
  styles: React.CSSProperties
  selectedOption: string
  onOptionChange: (option: string) => void
  children
}

export const TextSelector = ({ options, styles, selectedOption, onOptionChange, children }: Props) => {
  return (
    <TextSelectorContainer style={styles}>
      {options.map((option, index) => (
        <OptionContainer
          key={index}
          onClick={() => onOptionChange(option)}
          className={option === selectedOption ? 'selected' : ''}
        >
          {option}
        </OptionContainer>
      ))}
      {children}
    </TextSelectorContainer>
  )
}

const OptionContainer = styled(Box)`
  width: 109px;
  height: 26px;
  text-align: center;
  cursor: pointer;
  color: ${() => AppStore.theme.o.black};
  font-weight: 500;
  font-size: 14px;
  border-radius: 14px;
  white-space: nowrap;
  padding: 4px 16px;
  justify-content: center;

  &:hover {
    background-color: ${() => AppStore.theme.o.lightBlue};
  }

  &.selected {
    background-color: ${() => AppStore.theme.o.lightBlue};
    border: 1px solid ${() => AppStore.theme.o.primary};
    color: ${() => AppStore.theme.o.primary};
  }
`

const TextSelectorContainer = styled(Box)`
  flex-direction: row;
  padding: 9px 12px;
  background-color: ${() => AppStore.theme.o.surface};
  gap: 8px;

  z-index: 1;
  &::before {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    width: calc(100% + 2px);
    border-radius: 16px;
    background-image: linear-gradient(90deg, #00747c, #66d1ff);
    z-index: -1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 16px;
    background-color: ${() => AppStore.theme.o.background};
    z-index: -1;
  }
`
