/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Text } from 'components'
import { AppStore, T } from 'utils'
import { truncateText } from 'pages/ai-discover/commonFunction'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  text: string
}

export const TextCalendarCard = ({ text }: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <TextCalendarCardContainer>
      {/* EMPTY */}
      {text.length === 0 && <Empty>{T.postPage.noTextInserted}</Empty>}

      {/* TEXT */}
      <Text fontSize={12}>{truncateText(text, 300)}</Text>
    </TextCalendarCardContainer>
  )
}

const TextCalendarCardContainer = styled(Box)`
  position: relative;
  flex: 1;
  height: 100%;
`

const Empty = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 100%;
  width: 100%;

  border-radius: 14px;
  background: ${() => AppStore.theme.o.lightestGrey};

  color: ${() => AppStore.theme.o.darkGrey};
  font-family: 'Aktiv Grotesk';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`
