/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { SpecificDayHeader } from './SpecificDayHeader'
import { SpecificDayContent } from './SpecificDayContent'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { openPreviewModal } from 'components/Modal/ModalManager'
import { AppStore } from 'utils'
import { useCalendarStore } from 'stores/CalendarStore'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {}

export const DayContent = ({}: Props) => {
  /* ----------------- ROUTER  */
  const [searchParams] = useSearchParams()
  const paramsPostId = searchParams.get('postId') as string
  const location = useLocation()
  const navigate = useNavigate()

  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */
  const { hasMounted } = useCalendarStore((s) => ({
    hasMounted: s.hasMounted,
  }))

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */
  const handleOpenPreviewModal = () => {
    paramsPostId &&
      setTimeout(
        () => {
          openPreviewModal(paramsPostId, closePreviewModal)
        },
        hasMounted ? 0 : 200 // Delay opening della modale se siamo al primo mounting del calendar perché altrimenti non troverebbe il theme dell'app
      )

    !paramsPostId && closePreviewModal()
  }

  /**
   * Funzione per rimuovere il params search di "postId" alla chiusura della modale
   */
  const closePreviewModal = () => {
    searchParams.delete('postId')

    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    })

    AppStore.closeModal('preview-post-modal')
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    handleOpenPreviewModal()
  }, [paramsPostId])

  return (
    <DayContentContainer>
      <SpecificDayHeader />
      <SpecificDayContent />
    </DayContentContainer>
  )
}

const DayContentContainer = styled(Box)`
  gap: 10px;
  height: calc(100% - 32px);
  max-height: calc(100% - 32px);
`
