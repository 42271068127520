import Auth from 'api/Auth'

import { AppStore, T, showWarning } from 'utils'

const postAddNumber = async (prefix: string, number: string) => {
  try {
    const res = await Auth.addNumber(prefix, number)
    if (res === 200) return true
  } catch (error: any) {
    return error.message
  }
}

const getVerifyNumber = async (code: string) => {
  try {
    const res = (await Auth.verifyNumber(code)) as any

    if (typeof res === 'string') {
      if (res.includes('Code is not valid')) {
        return false
      }
      if (res.includes('Code is expired ')) {
        showWarning(T.settings.otpExpired)
        return false
      }
      if (res.includes('Number is already verified')) {
        showWarning(T.settings.otpJustVerified)
        return true
      }
    }
    if (res.phoneNumber) {
      AppStore.loggedUser.phoneNumber = res.phoneNumber
      return true
    }
  } catch (error: any) {
    return false
  }
}

export { postAddNumber, getVerifyNumber }
