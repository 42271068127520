/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { SocialType } from 'api/Utils'
import { Box, SocialIcon } from 'components'
import { useEffect, useState } from 'react'
import { useAnalyticsStore } from 'stores/AnalyticsStore'
import { AppStore } from 'utils'

/* ------------------- INTERFACES  */
interface Props {
  socialName: SocialType
  available: boolean
}

export const CheckboxSocialElement = ({ socialName, available }: Props) => {
  /* ------------------- ZUSTAND  */
  const { setSelectedSocial } = useAnalyticsStore.getState()

  /* ----------------- STATE  */
  const [socialSelected, setSocialSelected] = useState<SocialType>(useAnalyticsStore.getState().selectedSocial)

  /* ------------------- ZUSTAND SUBSCRIPTION  */
  const unsubSocialSelected = useAnalyticsStore.subscribe((state) => {
    state.selectedSocial !== socialSelected && setSocialSelected(state.selectedSocial)
  })

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    return () => {
      unsubSocialSelected()
    }
  }, [])

  return (
    <CheckboxSocialElementContainer
      className={`checkbox_social_element ${socialSelected === socialName ? 'selected' : 'not_selected'}`}
      style={available ? { cursor: 'pointer' } : { cursor: 'not-allowed' }}
      onClick={() => {
        if (!available) return
        setSelectedSocial(socialName)
      }}
    >
      <SocialIcon social={socialName} active={available} style={{ width: 40, height: 40 }} />
    </CheckboxSocialElementContainer>
  )
}

const CheckboxSocialElementContainer = styled(Box)`
  &.checkbox_social_element {
    padding: 12px 16px;
    border-radius: 14px;

    &.selected {
      background: transparent;
      border: 1px solid ${() => AppStore.theme.o.grey};
    }

    &.not_selected {
      background: ${() => AppStore.theme.o.lightestGrey};
      border: 1px solid transparent;
    }
  }
`
