import { useState } from 'react'
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import styled from '@emotion/styled'
import { TextArea } from 'components/TextArea'
import { T } from 'translation/i18n'
import { AppStore } from 'utils'

type Props = {
  setFeedbackTextCheck(value: string): void
  setIsNotLiked(value: boolean): void
  setIsLiked(value: boolean): void
  modelName: string

  sendFeedback(
    id: string | undefined,
    text: string,
    thumbUp: boolean,
    socialAcc: string,
    ArgumentType: string,
    toneofVoice: string,
    From: string,
    toLanguage: string,
    generatedTexts: string,
    feedbackNotes: string
  ): void
  storeItem: any
}

export const FeedbackModal = (props: Props) => {
  const { setFeedbackTextCheck, sendFeedback, setIsNotLiked, setIsLiked, modelName, storeItem } = props

  const [feedbackText, setFeedbackText] = useState<string>('')

  return (
    <Box relative>
      <Box pv={40} ph={56} gap={24}>
        <TextModal style={{ margin: '0 auto' }}>{T.textGenerator.feedbackModalTitle}</TextModal>

        <TextArea
          id="feedback-modal"
          borderwidth={'1px'}
          borderstyle={'dashed'}
          bordercolor={AppStore.theme.o.darkGrey}
          placeholder={T.textGenerator.feedbackPlaceholder}
          bgcolor={AppStore.theme.o.lightGrey}
          bgcoloractive={AppStore.theme.o.lightGrey}
          height={180}
          onChange={(update) => {
            setFeedbackTextCheck(update)
            setFeedbackText(update)
          }}
        >
          {feedbackText}
        </TextArea>
      </Box>
      <Box absolute style={{ bottom: -60, width: '100%' }}>
        <Button
          style={{ minHeight: 44, width: 268, margin: '0 auto', fontSize: 16, fontWeight: 700 }}
          disabled={feedbackText.length <= 0}
          variant="primary"
          onClick={() => {
            AppStore.closeModal('feedback-modal')

            const item = storeItem.find((i) => i.name === modelName)
            if (item) {
              item.isNotLike = true
              item.like = false
              item.feedbackNotes = feedbackText
              setIsNotLiked(true)
              setIsLiked(false)

              sendFeedback(
                item.feedbackId,
                item.text,
                false,
                item.socialAcc,
                item.ArgumentType,
                item.toneofVoice,
                item.From,
                item.toLanguage,
                item.generatedTexts,
                item.feedbackNotes
              )
            }
          }}
        >
          {T.textGenerator.sendFeedback}
        </Button>
      </Box>
    </Box>
  )
}

const TextModal = styled.p`
  font-size: 21px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  letter-spacing: 0.21px !important;
  color: ${AppStore.theme.o.darkGrey};
`
