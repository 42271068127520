import { Box } from 'components/Box'
import { SubscriptionPlan } from 'types/SubscriptionInterfaces'
import {
  UpgradeModalContainer,
  UpgradeModalNormalText,
  UpgradeModalOptionButton,
  UpgradeModalTextBlue,
} from './UpgradeOptions.style'
import { UpgradeOptionsPhase } from './UpgradeOptionsModal'
import { T } from 'utils'

/**
 * Component for displaying upgrade options
 */
export const UpgradeOptionsSelector: React.FC<{
  plan: SubscriptionPlan
  selected: UpgradeOptionsPhase
  handleSelect: (optionSelected: UpgradeOptionsPhase) => void
}> = ({ plan, handleSelect, selected }) => {
  return (
    <UpgradeModalContainer gap={8}>
      <UpgradeModalTextBlue>{T.billing.upgradePlan.upgrade}</UpgradeModalTextBlue>
      <UpgradeModalNormalText>{T.billing.upgradePlan.optionsDescription}</UpgradeModalNormalText>
      <Box>
        <UpgradeModalOptionButton
          selected={selected === UpgradeOptionsPhase.CANCEL_AT_END}
          onClick={() => handleSelect(UpgradeOptionsPhase.CANCEL_AT_END)}
        >
          {T.billing.upgradePlan.applyNextRenewal}{' '}
        </UpgradeModalOptionButton>
        <UpgradeModalOptionButton
          selected={selected === UpgradeOptionsPhase.PRORATE}
          onClick={() => handleSelect(UpgradeOptionsPhase.PRORATE)}
        >
          {T.billing.upgradePlan.applyWithProrate}{' '}
        </UpgradeModalOptionButton>
      </Box>
    </UpgradeModalContainer>
  )
}
