/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { CSSProperties } from 'react'
import { AppStore, T } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

export type TPage = 'publication' | 'discover' | 'analytics' | 'media' | 'calendar'

interface Props {
  page: TPage
}

export const NotPermissionPage = ({ page }: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ------------------- CONFIG  */

  const iconStyle: CSSProperties = {
    minWidth: 40,
    minHeight: 40,
  }

  const config = {
    publication: {
      id: 'publication',
      icon: <Icons.createPostSizer fill="#072528" style={iconStyle} />,
      backgroundColor: '#4B9EAA',
      description: T.permissions.post,
    },
    calendar: {
      id: 'calendar',
      icon: <Icons.calendarPostSizer fill="#6d4a05" style={iconStyle} />,
      backgroundColor: '#EBBA59',
      description: T.permissions.calendar,
    },
    media: {
      id: 'media',
      icon: <Icons.mediasPostSizer fill="#114d31" style={iconStyle} />,
      backgroundColor: '#90E1B3',
      description: T.permissions.medias,
    },
    analytics: {
      id: 'analytics',
      icon: <Icons.analyticsPostSizer fill="#380d16" style={iconStyle} />,
      backgroundColor: '#C96275',
      description: T.permissions.analytics,
    },
    discover: {
      id: 'discover',
      icon: <Icons.discoverPostSizer fill="#6d4a05" style={iconStyle} />,
      backgroundColor: '#EBBA59',
      description: T.permissions.discover,
    },
  }

  /* ----------------- VARIABLES  */

  const settings = config[page]

  const { id, description, backgroundColor, icon } = settings

  const info = T.permissions.contactAdmin

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <NotPermissionPageContainer id={`not_permission_page_${id}`}>
      <Circle style={{ background: backgroundColor }}>{icon}</Circle>

      <OpacityContainer>{description}</OpacityContainer>

      <InfoContainer>{info}</InfoContainer>
    </NotPermissionPageContainer>
  )
}

const InfoContainer = styled(Box)`
  padding: 8px 48px;
  background-color: ${() => AppStore.theme.o.warning_container};
  color: ${() => AppStore.theme.o.warning};
  gap: 24px;
  font-size: 14px;
  font-weight: 600;
`

const OpacityContainer = styled(Box)`
  background-color: ${() => AppStore.theme.o.lightestGrey};
  padding: 24px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 24px;

  font-size: 16px;
  color: ${() => AppStore.theme.o.black};
  font-weight: 400;
  line-height: 16px;
`

const Circle = styled(Box)`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
`

const NotPermissionPageContainer = styled(Box)`
  flex: 1;
  align-items: center;
  justify-content: center;
  gap: 24px;
  z-index: 1;
`
