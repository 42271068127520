import { Page } from 'components'
import { ComingSoonComponent } from 'components/ComingSoonComponent'
import { T } from 'translation/i18n'

export const InboxPage = () => {
  return (
    <Page title={T.indexPage.index}>
      <ComingSoonComponent />
    </Page>
  )
}
