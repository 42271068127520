import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { Box } from 'components/Box'
import { SubscriptionPlan } from 'types/SubscriptionInterfaces'
import { SpinnerAstersLogo } from 'components/UI'
import { useUpgradePlanStore } from 'stores/UpgradePlanStore'
import { GoBackButton } from './components/GoBackButton'
import { GoToNextButton } from './components/GoToNextButton'
import { UpgradeOptionsSelector } from './UpgradeOptionsSelector'
import { UpgradeWithCancelInterface } from './UpgradeWithCancel'
import { UpgradeWithProrateInterface } from './UpgradeWithProrate'
import { AppStore, showError, showInfo, T } from 'utils'
import Auth from 'api/Auth'

// Enum for different phases of the upgrade process
export enum UpgradeOptionsPhase {
  OPTIONS = 'OPTIONS',
  PRORATE = 'PRORATE',
  CANCEL_AT_END = 'CANCEL_AT_END',
}

// Props for the main UpgradeOptionsModal component
interface UpgradeOptionsModalProps {
  plan: SubscriptionPlan
}

/**
 * Main component for upgrade options modal
 * Manages the state and flow of the upgrade process
 */

export const UpgradeOptionsModal: React.FC<UpgradeOptionsModalProps> = ({ plan }) => {
  const [currentPhase, setCurrentPhase] = useState<UpgradeOptionsPhase>(UpgradeOptionsPhase.OPTIONS)

  const { loading } = useUpgradePlanStore((state) => {
    return { loading: state.processing }
  })

  // Richiede al backend upgrade del piano
  const makeUpgrade = useCallback(async (prorate: boolean) => {
    const { error } = await useUpgradePlanStore.getState().requestUpgradePlan(plan._id, prorate)
    useUpgradePlanStore.getState().setProcessing(true)
    if (error) {
      const errorMessage =
        error === 'PLAN_LIMIT_EXCEEDED' ? T.billing.upgradePlan.planLimitExceeded : T.billing.upgradePlan.upgradeError
      showError(errorMessage)
      useUpgradePlanStore.getState().setProcessing(false)
      return
    }
    setTimeout(async () => {
      AppStore.loggedUser = await Auth.loggedUser()
      showInfo(T.billing.upgradePlan.upgradeSuccess)
      AppStore.closeAllModal() //upgrade-modal-confirm
      useUpgradePlanStore.getState().setProcessing(false)
    }, 4000)
    // AppStore.refreshPage()
  }, [])

  // Handlers for selection changes
  const handleNextSelect = (optionSelected: UpgradeOptionsPhase) => {
    if (currentPhase != UpgradeOptionsPhase.OPTIONS) {
      makeUpgrade(optionSelected === UpgradeOptionsPhase.PRORATE)
    } else setCurrentPhase(optionSelected)
  }

  // Handler for phase selection
  const handleSelection = useCallback(
    (optionSelected: UpgradeOptionsPhase) => {
      setCurrentPhase(optionSelected)
    },
    [currentPhase]
  )

  useEffect(() => {
    useUpgradePlanStore.getState().requestProrateData(plan._id)
  }, [])

  // Memoized component for current phase
  const CurrentPhaseComponent = () => {
    switch (currentPhase) {
      case UpgradeOptionsPhase.OPTIONS:
        return <UpgradeOptionsSelector plan={plan} handleSelect={handleSelection} selected={currentPhase} />
      case UpgradeOptionsPhase.CANCEL_AT_END:
        return <UpgradeWithCancelInterface plan={plan} />
      case UpgradeOptionsPhase.PRORATE:
        return <UpgradeWithProrateInterface plan={plan} />
      default:
        return null
    }
  }

  return (
    <ModalContainer TotalHeight={currentPhase === UpgradeOptionsPhase.OPTIONS ? '242px' : '440px'}>
      {!loading && (
        <>
          {currentPhase !== UpgradeOptionsPhase.OPTIONS && <GoBackButton plan={plan} handleSelect={handleSelection} />}
          <GoToNextButton handleSelect={handleNextSelect} selectedSection={currentPhase} />
          <CurrentPhaseComponent />
        </>
      )}
      {loading && <LoadingSpinner />}
    </ModalContainer>
  )
}

/**
 * Loading spinner component
 */
const LoadingSpinner: React.FC = () => <SpinnerAstersLogo dimension={100} />

// Styled components
const ModalContainer = styled(Box)<{ TotalHeight?: string }>`
  display: flex;
  padding: 40px;
  width: 449px;
  height: ${(props) => props.TotalHeight ?? '380px'};
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-radius: 10px;
`
