/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import Socialaccounts from 'api/Socialaccounts'
import { Box, Icons, Text } from 'components'
import { useEffect, useState } from 'react'
import { IFBPage, IPostVariant } from 'types/PostInterface'
import { AppStore, showInfo, T } from 'utils'
import { updatePost } from 'utils/PostUtils/Post'
import { BranItem } from './BranItem'
import { ExtraTitle } from './ExtraTitle'
import { InfoExtra } from './InfoExtra'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  post: IPostVariant
}

export const Brand = ({ post }: Props) => {
  /* ----------------- STATE  */
  const [brands, setBrands] = useState<IFBPage[]>([])
  const [inputValue, setInputValue] = useState<string>('')
  const [inputFocus, setInputFocus] = useState<boolean>(false)
  const [urlImg, setUrlImg] = useState<string>('')

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */
  const canUseBrandFunctionality = post?.data?.medias && post?.data?.medias?.length > 0
  const selectedBrand = post?.data?.brand

  /* ----------------- METHODS  */
  const setImg = async (id: string) => {
    const url = await getPageImage(id)
    setUrlImg(url)
  }

  const handleOnChangeBrandInput = (e) => {
    if (!canUseBrandFunctionality) return e.preventDefault()
    setInputValue(e.target.value)
  }

  /**
   *  Aggiunge un brand al post
   * @param brand
   */
  const handleAddBrand = (brand: IFBPage) => {
    updatePost(post.variantId, { brand })
    setInputValue('')
    setInputFocus(false)
  }

  /**
   * Rimuove il brand dal post
   */
  const handleRemoveBrand = () => {
    updatePost(post.variantId, { brand: null })
  }

  const handleOnPaste = (e) => {
    if (!canUseBrandFunctionality) return showInfo(T.postPage.extraBrandWarning)

    setInputFocus(true)
    const copyText = e.clipboardData.getData('text')

    if (!copyText.includes('www.facebook.com')) return // sta incollando del testo normale
    const value = copyText.split('www.facebook.com/')[1].split('/')[0] // sta incollando un link di facebook
    fetchBrands(value)
  }

  const handleInputClick = (e) => {
    if (!canUseBrandFunctionality) {
      showInfo(T.postPage.extraBrandWarning)
      e.preventDefault()
    }
  }

  /* ----------------- API CALL  */
  /**
   * Recupera i brand in base all'input
   */
  const fetchBrands = async (value?: string) => {
    try {
      const res = await Socialaccounts.getPages(post.info.account.account_id!, value ?? inputValue)
      setBrands(res)
    } catch (e) {
      console.error(e)
    }
    return ''
  }

  /**
   * Recupera l'immagine del brand
   * @param id string id del brand
   */
  const getPageImage = async (id: string) => {
    try {
      const res = await fetch(`https://graph.facebook.com/${id}/picture`)
      return res.url
    } catch (e) {
      console.error(e)
    }
    return ''
  }

  /* ----------------- USEEFFECT  */
  /**
   * Effettua la chiamata per recuperare i profili
   */
  useEffect(() => {
    if (inputValue === '') {
      setBrands([])
    }

    const delayDebounceFn = setTimeout(() => {
      fetchBrands()
    }, 200)

    return () => clearTimeout(delayDebounceFn)
  }, [inputValue])

  useEffect(() => {
    selectedBrand && setImg(selectedBrand.id!)
  }, [selectedBrand])

  // Reset brand based on medias
  useEffect(() => {
    if (!canUseBrandFunctionality) {
      updatePost(post.variantId, { brand: null })
    }
  }, [])

  return (
    <BrandContainer>
      <Box row gap={8} vcenter>
        <ExtraTitle text={T.postPage.extraBrand} />

        <InfoExtra infoText={T.postPage.extraBrandTooltip} />
      </Box>

      <ContainerBrand canUseBrandFunctionality={canUseBrandFunctionality} onClick={(e) => handleInputClick(e)}>
        {!!selectedBrand && (
          <SelectedItem>
            <Box row center gap={10}>
              <BrandImg src={urlImg} />
              <Text fontSize={14}>{selectedBrand.name}</Text>
            </Box>
            <RemoveBrand onClick={handleRemoveBrand}>
              <Icons.sizerClose fill={AppStore.theme.o.darkGrey} />
            </RemoveBrand>
          </SelectedItem>
        )}

        {!selectedBrand && (
          <InputBrand
            canUseBrandFunctionality={canUseBrandFunctionality}
            onFocus={() => canUseBrandFunctionality && setInputFocus(true)}
            onBlur={() => setInputFocus(false)}
            onPaste={(e) => handleOnPaste(e)}
            value={inputValue}
            onChange={(e) => handleOnChangeBrandInput(e)}
            placeholder={T.postPage.extraBrandPlaceholder}
          />
        )}
      </ContainerBrand>

      {inputValue?.length > 0 && brands?.length > 0 && (
        <ResultsContainer onMouseDown={(e) => e.preventDefault()}>
          {brands.map((brand) => (
            <BranItem key={brand.id} handleAddBrand={handleAddBrand} brand={brand} getPageImage={getPageImage} />
          ))}
        </ResultsContainer>
      )}
    </BrandContainer>
  )
}

const BrandContainer = styled(Box)`
  gap: 16px;
  width: 100%;
  position: relative;
`

const ContainerBrand = styled(Box)<{ canUseBrandFunctionality: boolean }>`
  width: 100%;
  font-size: 16px;
  color: ${() => AppStore.theme.o.black};
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  border: 1px dashed ${() => AppStore.theme.o.darkGrey};
  background: ${() => AppStore.theme.o.lightGrey};
  height: 54px;
  padding-left: 24px;
  cursor: ${({ canUseBrandFunctionality }) => (canUseBrandFunctionality ? 'auto' : 'not-allowed')};
`

const SelectedItem = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px 4px 16px;
  height: 32px;
  border: 1px solid ${() => AppStore.theme.o.grey};
  color: ${() => AppStore.theme.o.black};
  background: ${() => AppStore.theme.o.background};
`

const InputBrand = styled.input<{ canUseBrandFunctionality: boolean }>`
  background-color: transparent;
  border: none;
  flex: 1;
  pointer-events: ${({ canUseBrandFunctionality }) => (canUseBrandFunctionality ? 'auto' : 'none')};
`

const RemoveBrand = styled(Box)`
  margin-left: 10px;
  border-radius: 50%;
  cursor: pointer;
  background: ${() => AppStore.theme.o.background};
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: ${() => AppStore.theme.o.lightGrey};
  }
`

const ResultsContainer = styled(Box)`
  width: 100%;
  padding: 8px 16px;
  background-color: ${() => AppStore.theme.o.lightestGrey};
  border: 1px solid ${() => AppStore.theme.o.grey};
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  z-index: 100;
  gap: 4px;
  max-height: 300px;
  overflow-y: scroll;
  padding-right: 8px;
`

const BrandImg = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`
