/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Button, Icons } from 'components'
import { useNavigate } from 'react-router-dom'
import { AppStore, T } from 'utils'

/* ------------------- INTERFACES  */
interface Props {}

export const AddSocialButton = ({}: Props) => {
  /* ----------------- ROUTER  */
  const navigate = useNavigate()

  return (
    <AddSocialButtonContainer
      variant="secondary"
      onClick={() => navigate(`/post/socials`)}
      shadow={AppStore.theme.smallShadow}
      pv={16}
      ph={24}
      style={{ minHeight: 52, height: 10 }}
    >
      <Icons.addSocial fill={AppStore.theme.o.green} width={32} /> {T.settings.selectSocialCreatePost}
    </AddSocialButtonContainer>
  )
}

const AddSocialButtonContainer = styled(Button)`
  font-size: 16px;
  font-weight: 700;
  gap: 8px;
`
