/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { AppStore } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */
interface IOption {
  name: string
  value: string
  action: () => void
}

interface Props {
  active: string // se combacia con il value dell'opzione
  options: IOption[]
}

export const MultiSelectRow = ({ active, options }: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <MultiSelectRowContainer>
      {options.map((option, i) => {
        return (
          <Option
            key={option.name + i}
            onClick={option.action}
            className={active === option.value ? 'active_option' : ''}
          >
            {option.name}
          </Option>
        )
      })}
    </MultiSelectRowContainer>
  )
}

const MultiSelectRowContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 8px;
  align-items: flex-start;
  border-radius: 14px;
  border: 1px solid ${() => AppStore.theme.o.lightGrey};
`

const Option = styled(Box)`
  display: flex;
  height: 40px;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 14px;
  text-align: center;
  font-family: 'Aktiv Grotesk';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: 1px solid transparent;

  color: ${() => AppStore.theme.o.grey};
  background: transparent;
  border-color: transparent;

  &:hover {
    color: ${() => AppStore.theme.o.black};
    background: ${() => AppStore.theme.o.lightestGrey};
  }

  &.active_option {
    color: ${() => AppStore.theme.o.black};
    background: ${() => AppStore.theme.o.lightestGrey};
    border-color: ${() => AppStore.theme.o.lightGrey};
  }
`
