/* ------------------- IMPORTS */

import { Box } from 'components'
import { RowFormatValue } from './RowFormatValue'
import { configColumns, IColumn, TablePost } from './tableInterface'
import { TableRowFirstColumn } from './TableRowFirstColumn'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

export const MAX_TABLE_PAGE_SIZE = 5

export class TableBuilder {
  /**
   * Costruisce una riga della tabella
   * @param postData
   * @returns
   */
  static buildRow(postData: TablePost) {
    const createRowObject = (columns: IColumn[], post) => {
      const firstColValue = <TableRowFirstColumn post={post} />

      const rowObject = {
        id: post._id,
        date: firstColValue,
      }

      columns?.forEach((col, i) => {
        if (i !== 0) {
          rowObject[col.id] = <RowFormatValue value={post[col.id]} type={col.type} />
        }
      })

      return rowObject
    }

    // Trova le colonne appropriate in base al social
    const columnsConfig = configColumns.find((col) => col.social === postData.provider)?.columns
    if (!columnsConfig) return null

    return createRowObject(columnsConfig, postData)
  }

  /**
   * Restituisce le colonne in base al provider
   * @param provider
   * @returns
   */
  static getColumnsType(provider: string) {
    return configColumns.find((col) => col.social === provider)?.columns
  }

  /**
   * Restituisce le colonne in base al provider
   * @param provider
   * @returns
   */
  static getColumns(provider: string) {
    return (
      this.getColumnsType(provider)?.map((col) => {
        return {
          field: col.id,
          headerName: col.headerName,
          width: col.width,
          headerClassName: 'mui-custom-header',
          renderCell: (params) => {
            const text = params.value
            return (
              <Box center flex>
                {text}
              </Box>
            )
          },
        }
      }) ?? []
    )
  }
}
