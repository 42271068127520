/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { useLocation } from 'react-router-dom'
import { TSection } from 'types/CreatePostInferfaces'
import { IPostVariant, IUrl } from 'types/PostInterface'
import { AppStore } from 'utils'
import { updateActive, updatePost } from 'utils/PostUtils/Post'
import { onClickOpenSelectPreviewMetadataModal } from './SelectPreviewMetadataModal'
import { onClickOpenEditMetadataModal } from './EditMetadataModal'

/* ------------------- INTERFACES  */
interface Props {
  post: IPostVariant
  visibleLink: IUrl
}

export const MetadataButtons = ({ post, visibleLink }: Props) => {
  /* ----------------- ROUTER  */
  const location = useLocation()
  const section = (location.pathname.split('/')[2] ?? 'preview') as TSection

  /* ----------------- METHODS  */
  const deletePreviewMetadata = (idLink: string) => {
    if (section === 'preview') {
      // Elimina i metadata di quel link
      const newUrls = post?.data?.urls?.map((url) => {
        if (url.id === idLink) {
          url.metaData = {
            ogDescription: undefined,
            ogTitle: undefined,
            ogImg: undefined,
            ogSiteName: undefined,
            ogUrl: undefined,
            visible: false,
          }
        }
        return url
      })

      // Setta l'ultimo link come metadato visibile, e se era l'unico con la quale stavo lavorando tiene in false
      if (newUrls && newUrls?.length > 1) {
        const findOneActive = newUrls.find((url) => url.metaData.ogDescription !== undefined)

        if (findOneActive) {
          findOneActive.metaData.visible = true
        }
      }

      updateActive({ urls: newUrls })
    }

    if (section === 'extra') {
      // Elimina i metadata di quel link
      const newUrls = post.data.firstComment?.urls?.filter((url) => url.id !== idLink)

      // Setta l'ultimo link come metadato visibile, e se era l'unico con la quale stavo lavorando tiene in false
      if (newUrls && newUrls?.length > 0) {
        newUrls[newUrls.length - 1].metaData.visible = true
      }

      updatePost(post.variantId, {
        firstComment: {
          ...post.data.firstComment,
          urls: newUrls,
        },
      })
    }
  }

  return (
    <MetadataButtonsContainer>
      {/* //TODO: DA SCOMMENTARE QUANDO SI SISTEMA IL CAMBIAMENTO DEI METADATI */}
      <MetadataButton onClick={() => onClickOpenEditMetadataModal(post, visibleLink, section)}>
        <Icons.pen />
      </MetadataButton>

      <MetadataButton
        onClick={() => {
          onClickOpenSelectPreviewMetadataModal(post, section)
        }}
      >
        <Icons.changeLink />
      </MetadataButton>

      <MetadataButton
        onClick={() => {
          deletePreviewMetadata(visibleLink.id)
        }}
      >
        <Icons.cancel />
      </MetadataButton>
    </MetadataButtonsContainer>
  )
}

const MetadataButtonsContainer = styled(Box)`
  position: absolute;
  top: 7px;
  right: 7px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
`

const MetadataButton = styled(Box)`
  cursor: pointer;
  border-radius: 50%;

  :hover {
    background-color: ${() => AppStore.theme.o.grey};
  }

  svg {
    width: 24px;
    height: 24px;
    fill: ${() => AppStore.theme.o.darkGrey};
  }
`
