/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { SocialPicture } from 'components/UI/SocialPicture'
import { truncateText } from 'pages/ai-discover/commonFunction'
import { useEffect, useState } from 'react'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { AppStore } from 'utils'
import { formatTitle } from 'utils/PostUtils/MediaPost'

interface Props {}

export const ActivePostCard = ({}: Props) => {
  /* ----------------- STATES  */
  const [activeTitle, setActiveTitle] = useState<string>()

  /* ----------------- ZUSTAND */
  const { activePost } = useCreatePostStore((s) => ({
    activePost: s.activePost,
  }))

  /* ----------------- VARIABLES  */
  const isActivePost = activePost && 'info' in activePost

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    setActiveTitle(formatTitle())
  }, [activePost])

  return (
    <ActivePostCardContainer>
      {isActivePost && (
        <SocialPicture
          picture={activePost.info.account.picture}
          provider={activePost.info.account.provider}
          dimensionPicture={40}
          dimensionProvider={24}
        />
      )}
      <SocialName> {activeTitle} </SocialName>
      {isActivePost && <TypeCard>{activePost.info?.type === 'STANDARD' ? 'POST' : activePost.info?.type}</TypeCard>}
    </ActivePostCardContainer>
  )
}

const ActivePostCardContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`

const TypeCard = styled(Box)`
  display: flex;
  padding: 4px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: ${() => AppStore.theme.o.green};
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  border-radius: 4px;
  border: 1px solid ${() => AppStore.theme.o.green};
  background: ${() => AppStore.theme.o.lightGreen};
  height: 20px;
`

const SocialName = styled.p`
  white-space: nowrap; /* Non permette al testo di andare a capo */
  overflow: hidden; /* Nasconde il testo che eccede l'elemento */
  text-overflow: ellipsis; /* Aggiunge i puntini di sospensione */
  max-width: 210px;
`
