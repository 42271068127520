import { MixpanelEditorFiles } from '../utils/utilsInterfaces'

export function getUploadedFileData(fileData: MixpanelEditorFiles): MixpanelEditorFiles {
  return {
    file_weight: fileData.file_weight,
    file_format: fileData.file_format,
    file_url: fileData.file_url,
    file_size: fileData.file_size,
    video_length: fileData.video_length,
  }
}
