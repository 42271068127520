import { T } from 'utils'
import { SocialType } from '../../api/Utils'
import { SocialMedias } from 'types'

export const PLACEHOLDER_DATE = '--'
export const PRODUCTION_HOSTNAMES = ['app.asters.ai', 'beta.asters.ai']
export const QUALITY_HOSTNAMES = ['qty.asters.ai', 'romantic-booth-e26b41.netlify.app']
export const DEVELOPMENT_HOSTNAMES = ['dev.asters.ai', 'localhost']

/** Retrieve cooming soon socials for asters app */
export const exportCoomingSocials = () => {
  const defaultSocials = [SocialType.pinterest, SocialType.google]
  if (PRODUCTION_HOSTNAMES.includes(window.location.hostname))
    return [SocialType.pinterest, SocialType.google]
  return defaultSocials
}

/** Retrieve available socials for asters app */
export const exportAvailableSocials = () => {
  const defaultSocials = [
    SocialType.facebook,
    SocialType.instagram,
    SocialType.tiktok,
    SocialType.linkedin,
    SocialType.xTwitter,
    SocialType.youtube,
  ]
  if (PRODUCTION_HOSTNAMES.includes(window.location.hostname))
    return [SocialType.facebook, SocialType.instagram, SocialType.tiktok, SocialType.linkedin, SocialType.xTwitter, SocialType.youtube]
  return defaultSocials
}
export const mapSocialTypeToSocialMedias = (socialType: SocialType): SocialMedias => {
  switch (socialType) {
    case SocialType.facebook:
      return 'facebook'
    case SocialType.instagram:
      return 'instagram'
    case SocialType.tiktok:
      return 'tiktok'
    case SocialType.linkedin:
      return 'linkedin'
    case SocialType.xTwitter:
      return 'x'
    case SocialType.youtube:
      return 'youtube'

    default:
      throw new Error(`Unrecognized social type: ${socialType}`)
  }
}
export const MAX_ARTICLE_NUMBER = 24

// Lista dei social disponibili alla connessione
export const AVAILABLE_SOCIALS = exportAvailableSocials()
// Lista dei social non ancora disponibili alla connessione
export const COOMING_SOCIALS = exportCoomingSocials()

export const YOUTUBEPRIVACY = () => {
  return {
    PUBLIC: T.postPage?.youtubeTubeExtra.public,
    PRIVATE: T.postPage?.youtubeTubeExtra.private,
    UNLISTED: T.postPage?.youtubeTubeExtra.unlisted,
  }
}

export const TIKTOKPRIVACY = () => {
  return {
    FOLLOWER_OF_CREATOR: T.postPage.tiktokExtra.follower,
    MUTUAL_FOLLOW_FRIENDS: T.postPage.tiktokExtra.friends,
    SELF_ONLY: T.postPage.tiktokExtra.private,
    //PUBLIC_TO_EVERYONE: T.postPage.tiktokExtra.public,
  }
}

const lang = localStorage.getItem('defaultLang')
const languageMappings = {
  it: ['IT', 'Italian'],
  en: ['EN', 'English'],
}

export const DEFAULT_LANGUAGE = lang ? languageMappings[lang] : ['IT', 'Italian']

export const borderGradientStyles = {
  border: '1px solid transparent',
  borderRadius: '16px',
  background: 'linear-gradient(to right, #00747C , #66D1FF)',
  backgroundClip: 'padding-box',
  padding: '1px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}
