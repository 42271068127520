import styled from '@emotion/styled'
import Workspaces from 'api/Workspaces'
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { T } from 'translation/i18n'
import { AppStore, showInfo, showSuccess } from 'utils'
import { getSinglePostById, updateActive, updatePost } from 'utils/PostUtils/Post'
import { deleteItem, updateItem } from '../../../../utils/PostUtils/TemplatePost'
import { IItem } from 'types'
import { useNavigate } from 'react-router-dom'
import { Tracker } from 'api/Tracker'
import { TRACKEREVENTS } from 'utils/eventsTracker'
import { IPostData } from 'types/PostInterface'

/* ******************* INTERFACE ******************* */
interface Props {
  saveButton: boolean
  setSaveButton: (value: boolean) => void
  value: string
  item: IItem
  variantId?: string
}

export const TempleteButtons = ({ saveButton, value, item, setSaveButton, variantId }: Props) => {
  /* ******************* ROUTER ******************* */
  const navigate = variantId ? undefined : useNavigate()
  /* ******************* STATE ******************* */
  const workspaceId = AppStore.workspace._id ?? ''

  /* ******************* FUNCTIONS ******************* */
  const saveModal = async () => {
    if (value === '') {
      showInfo('Aggiungi del testo')
      return
    }

    const newItem = { ...item, text: value }
    updateItem(item, newItem)
    setSaveButton(false)
    Workspaces.modifyModelToWorkspace(workspaceId ?? '', item._id, { text: newItem.text })
    showSuccess(T.success.theModalCreated)
  }

  const deleteModal = async () => {
    await Workspaces.deleteModalGruop(workspaceId, item._id)
    deleteItem(item)
    showSuccess(T.success.modalEliminated)
    Tracker.trackEvent(TRACKEREVENTS.POST_TEMPLATE_DELETED, { post_template_type: item.type })
  }

  const addModel = () => {
    const { activePost } = useCreatePostStore.getState()
    if (variantId) {
      const { data } = getSinglePostById(variantId) ?? {}
      const firstComment = data?.firstComment
      updatePost(variantId, { firstComment: { ...firstComment, text: firstComment?.text + value } })
      AppStore.closeModal('add-template-to-extra')
    } else {
      if ('_id' in activePost && activePost._id === 'GLOBAL') {
        const text = (activePost as IPostData).text + value
        updateActive({ text: text + value })
      }
      if ('variantId' in activePost) {
        const textOrDescription = activePost.info.account.provider === 'youtube' ? 'description' : 'text'
        updateActive({ [textOrDescription]: activePost.data.text + value })
      }

      // VariantId esiste solo quando siamo sulla modale, è sulle modali nn possiamo utilizzare il router

      navigate && navigate('/post/preview/basic')
    }
  }

  /* ******************* ******************* *******************  */

  return (
    <Box hcenter style={{ marginLeft: 'auto' }}>
      <ButtonUpdateDelete size="small" variant="secondary-small" onClick={() => addModel()}>
        {T.postPage.add}
      </ButtonUpdateDelete>
      <Box mv={16}>
        {saveButton && (
          <ButtonUpdateDelete size="small" variant="neutral" onClick={() => saveModal()}>
            {T.settings.save}
          </ButtonUpdateDelete>
        )}
      </Box>
      <ButtonUpdateDelete size="small" variant="delete-small" onClick={() => deleteModal()}>
        {T.postPage.delete}
      </ButtonUpdateDelete>
    </Box>
  )
}

const ButtonUpdateDelete = styled(Button)`
  margin-left: 40px;
  width: 130px;
  height: 40px;
`
