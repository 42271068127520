import { api, responseErrorCheck } from './api'
import AstersBaseResource from './AstersBaseResource'
import { AppStore } from 'utils'

// <-------------------------INFERFACE------------------------->

export default class Topic extends AstersBaseResource {
  static endpoint = '/topic'

  static async getTopicByTitle(topicTitle: string) {
    return api
      .get<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/${AppStore.discoverUser?._id}/${topicTitle}/getTopicByTitle`)
      .then(responseErrorCheck)
  }

  // Prende tutti i topic seguiti dall'user
  static async getUserTopics() {
    return api
      .get<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/${AppStore.discoverUser?._id}/userTopics`)
      .then(responseErrorCheck)
  }

  // Per ricerca topic dentro searchbar
  static async getFilteredTopics(language?: string, title?: string) {
    return api
      .get<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/${AppStore.discoverUser?._id}/getFilteredTopics`, {
        language,
        title,
      })
      .then(responseErrorCheck)
  }

  // Per creare un topic nel DB dopo averlo cercato
  static async create(title: string, language: string) {
    return api
      .post<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/${AppStore.discoverUser?._id}/create`, {
        title,
        language,
      })
      .then(responseErrorCheck)
  }

  // Mostra tutti i topic nel DB
  static async getAllTopic() {
    return api
      .get<any>(`${this.endpoint}/${AppStore.getWorkspaceId()}/${AppStore.discoverUser?._id}/all`)
      .then(responseErrorCheck)
  }
}
