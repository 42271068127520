import { SearchOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Box } from 'components/Box'
import { Input } from 'components/Input'
import { Text } from 'components/Text'
import { Component } from 'react'
import { T } from 'translation/i18n'
import { AppStore } from 'utils'
import { LanguageButton } from './LanguageButton'

interface Props {
  InputOutput: boolean
  language: any
  onClick: any
  fromLanguagrSelectModal?: boolean
}
interface State {
  selected: any
  filteredLanguaged: any
}
export class LanguageBox extends Component<Props, State> {
  langDir = T.postPage.language

  languageAbbreviations: { [key: string]: string } = {
    AR: 'ara',
    BG: 'bul',
    CS: 'ces',
    DA: 'dan',
    DE: 'deu',
    EL: 'ell',
    EN: 'eng',
    ES: 'spa',
    ET: 'est',
    FI: 'fin',
    FR: 'fra',
    HU: 'hun',
    ID: 'ind',
    IT: 'ita',
    JA: 'jpn',
    KO: 'kor',
    LT: 'lit',
    LV: 'lav',
    NB: 'nob',
    NL: 'nld',
    PL: 'pol',
    PT: 'por',
    RO: 'ron',
    RU: 'rus',
    SK: 'slk',
    SL: 'slv',
    SV: 'swe',
    TR: 'tur',
    UK: 'ukr',
    ZH: 'zho',
  }

  languages = [
    { traslationValue: 'AR', language: this.langDir.AR },
    { traslationValue: 'BG', language: this.langDir.BG },
    { traslationValue: 'CS', language: this.langDir.CS },
    { traslationValue: 'DA', language: this.langDir.DA },
    { traslationValue: 'DE', language: this.langDir.DE },
    { traslationValue: 'EL', language: this.langDir.EL },
    { traslationValue: 'EN', language: this.langDir.EN },
    { traslationValue: 'ES', language: this.langDir.ES },
    { traslationValue: 'ET', language: this.langDir.ET },
    { traslationValue: 'FI', language: this.langDir.FI },
    { traslationValue: 'FR', language: this.langDir.FR },
    { traslationValue: 'HU', language: this.langDir.HU },
    { traslationValue: 'ID', language: this.langDir.ID },
    { traslationValue: 'IT', language: this.langDir.IT },
    { traslationValue: 'JA', language: this.langDir.JA },
    { traslationValue: 'KO', language: this.langDir.KO },
    { traslationValue: 'LT', language: this.langDir.LT },
    { traslationValue: 'LV', language: this.langDir.LV },
    { traslationValue: 'NB', language: this.langDir.NB },
    { traslationValue: 'NL', language: this.langDir.NL },
    { traslationValue: 'PL', language: this.langDir.PL },
    { traslationValue: 'PT', language: this.langDir.PT },
    { traslationValue: 'RO', language: this.langDir.RO },
    { traslationValue: 'RU', language: this.langDir.RU },
    { traslationValue: 'SK', language: this.langDir.SK },
    { traslationValue: 'SL', language: this.langDir.SL },
    { traslationValue: 'SV', language: this.langDir.SV },
    { traslationValue: 'TR', language: this.langDir.TR },
    { traslationValue: 'UK', language: this.langDir.UK },
    { traslationValue: 'ZH', language: this.langDir.ZH },
  ]

  state: State = {
    selected: this.props.language,
    filteredLanguaged: this.languages,
  }
  setInputOutputLanguage = async (language: string) => {
    await this.setState({
      selected: language,
    })
  }

  setSelected = async (e) => {
    console.error(e)

    this.setState({ selected: [this.state.selected[0], e] })
  }

  componentDidMount(): void {
    const { InputOutput, onClick, language } = this.props
    const { selected, filteredLanguaged } = this.state
    const text = language[1]

    if (language[1] === 'undefined') {
      const newArray = this.languages.find(function (el) {
        return el.traslationValue === language[0]
      })

      if (selected !== text) this.setSelected(newArray?.language as any)
    }
  }

  render() {
    const { InputOutput, onClick, language, fromLanguagrSelectModal } = this.props
    const { selected, filteredLanguaged } = this.state
    return (
      <Box style={{ width: 650, minHeight: 420, maxHeight: 370 }} flex ph={32} pv={31}>
        {/* ------------------------ DIVISIONE IN 2 A DUE DELLE LINGUE --------------------------------------------- */}

        {/*------------------------ HEADER OF THE BOX ------------------------*/}

        <Box id={'headerLanguage'} row vcenter mb={30}>
          <Box flex>
            <Text weight={500} fontSize={16} style={{ color: AppStore.theme.o.darkGrey }}>
              {InputOutput ? T.postPage.writeIn : T.postPage.generateIn} :
              <span style={{ color: AppStore.theme.o.black, fontWeight: 700, fontSize: 21 }}> {selected[1]}</span>
            </Text>
          </Box>

          <CustomInput
            placeholder={T.postPage.search}
            prefix={<SearchOutlined />}
            onChange={(filt) =>
              this.setState({
                filteredLanguaged: this.languages.filter((e) => e.language.toLowerCase().includes(filt)),
              })
            }
          />
        </Box>

        {/*------------------------ GRID CONTAINER ------------------------*/}
        <Grid>
          {filteredLanguaged.map((value, index) => (
            <LanguageButton
              language={value}
              selected={this.state.selected[1] === value.language}
              onClickLanguage={(e) =>
                onClick(
                  fromLanguagrSelectModal ? [this.languageAbbreviations[value.traslationValue], value.language] : e
                )
              }
            />
          ))}
        </Grid>
      </Box>
    )
  }
}

const CustomInput = styled(Input)`
  background: ${() => AppStore.theme.o.grey};
  border-radius: 14px;
  width: 224px;
  height: 44px;
`

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: 90px 90px 90px 90px;
  grid-gap: 18px;

  justify-content: space-between;
`
