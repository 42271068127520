/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { useState } from 'react'
import { CrelloResponse } from 'types'
import { AppStore, T } from 'utils'
import { isVisible, RemoteConfigValues } from 'utils/CheckVisibleElement'
import { MyProjectSection } from './MyProjectSection'
import NewProjectSection, { TTemplate } from './NewProjectSection'
import { TemplateSection } from './TemplateSection'

/* ------------------- TYPES  */
type TEditorSection = 'new-project' | 'my-projects' | 'templates'

/* ------------------- INTERFACES  */

interface TEditorOptions {
  id: TEditorSection
  name: string
  isVisible: boolean
}

interface Props {
  onTemplateClick: (template: TTemplate) => void
  onCrelloSave?: (crelloResponse: CrelloResponse) => void
  selectedMedia?: string
}

export const MediaTemplateModal = ({ onTemplateClick, onCrelloSave, selectedMedia }: Props) => {
  /* ----------------- STATE  */
  const [selectedSection, setSelectedSection] = useState<TEditorSection>('new-project')

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  /* ----------------- CONFIG  */
  const defaultEditorOptions: TEditorOptions[] = [
    {
      id: 'new-project',
      name: T.error.newProject,
      isVisible: true,
    },
  ]

  const crelloEditorOptions: TEditorOptions[] = [
    defaultEditorOptions[0],
    {
      id: 'my-projects',
      name: T.error.myProjects,
      isVisible: true,
    },
    {
      id: 'templates',
      name: T.error.templates,
      isVisible: isVisible(RemoteConfigValues.EditorMediasTemplatesDisabled),
    },
  ]

  const editorOptions: TEditorOptions[] =
    selectedMedia && selectedMedia.length > 0 ? defaultEditorOptions : crelloEditorOptions

  return (
    <MediaTemplateModalContainer>
      <ContainerSwitchSection>
        {editorOptions.map((option) => {
          return (
            option.isVisible && (
              <SwitchBtn
                onClick={() => setSelectedSection(option.id)}
                style={{
                  backgroundColor: selectedSection === option.id ? AppStore.theme.o.lightestGrey : 'transparent',
                  borderColor: selectedSection === option.id ? AppStore.theme.o.lightGrey : 'transparent',
                }}
              >
                {option.name}
              </SwitchBtn>
            )
          )
        })}
      </ContainerSwitchSection>

      <ContainerSection>
        {selectedSection === 'new-project' && <NewProjectSection onTemplateClick={onTemplateClick} />}

        {selectedSection === 'my-projects' && <MyProjectSection onCrelloSave={onCrelloSave} />}

        {selectedSection === 'templates' && <TemplateSection onCrelloSave={onCrelloSave} />}
      </ContainerSection>
    </MediaTemplateModalContainer>
  )
}

const MediaTemplateModalContainer = styled(Box)`
  padding: 48px 80px 48px 80px;
  gap: 32px;
`

const ContainerSwitchSection = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin: 0 auto;
  padding: 8px;
  border: 1px solid ${() => AppStore.theme.o.lightGrey};
  background-color: ${() => AppStore.theme.o.surface};
`

const SwitchBtn = styled(Box)`
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  padding: 8px 48px 8px 48px;
  color: ${() => AppStore.theme.o.black};
  transition: 0.3s;
  border: 1px solid transparent;

  &:hover {
    background-color: ${() => AppStore.theme.o.lightGrey} !important;
  }
`

const ContainerSection = styled(Box)`
  display: flex;
  gap: 16px;
  height: 600px;
  /* overflow-y: scroll; */
`
