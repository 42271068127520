/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { ISocialProfile } from 'api/PostV2'
import { Box, Icons, SocialIcon } from 'components'
import { TIconPost } from 'components/Modal/PreviewPostModal/PreviewPostModal'
import { AppStore } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  socialProfile: ISocialProfile
  mediaType: TIconPost
}

export const SocialInfoCalendarCard = ({ socialProfile, mediaType }: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  /* ----------------- ENUM  */
  const IconEnum = {
    STANDARD: <Icons.postIcon fill={AppStore.theme.o.black} />,
    CAROUSEL: <Icons.carouselIcon fill={AppStore.theme.o.black} />,
    VIDEO: <Icons.videoIcon fill={AppStore.theme.o.black} />,
    REEL: <Icons.reelIcon fill={AppStore.theme.o.black} />,
    SHORT: <Icons.shortStoryIcon fill={AppStore.theme.o.black} />,
    STORY: <Icons.shortStoryIcon fill={AppStore.theme.o.black} />,
    LINK: <Icons.link fill={AppStore.theme.o.black} />,
  }

  return (
    <SocialInfoCalendarCardContainer>
      <SocialIcon social={socialProfile.provider} style={{ width: 32, height: 32 }} />

      <ProfileImg src={socialProfile.profileImage} />

      <TypeContentIcon>{IconEnum[mediaType]}</TypeContentIcon>
    </SocialInfoCalendarCardContainer>
  )
}

const SocialInfoCalendarCardContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 18px;
  flex: 1;
`

const ProfileImg = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
`

const TypeContentIcon = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  padding: 4px;
  background-color: ${() => AppStore.theme.o.lightGrey};
`
