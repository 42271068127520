/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { filterTitleAnalytics } from 'components/Analytics/config/FilterConfig'
import { useState } from 'react'
import { IFilterDropdownData, TFilterDropdownType, TForSectionInDropdownFilter } from 'types'
import { AppStore } from 'utils'

interface Props {
  forSection: TForSectionInDropdownFilter
  activeFilter: TFilterDropdownType
  filterData: IFilterDropdownData[]
  onFilterClick: (filterFunction: IFilterDropdownData) => void
}

export const DropdownFilter = ({ forSection, activeFilter, filterData, onFilterClick }: Props) => {
  /* ------------------- STATE  */
  const [openFilter, setOpenFilter] = useState<boolean>(false)

  /* ------------------- ENUM  */
  const enumTypeTitle = {
    analytics: { filterTitle: filterTitleAnalytics },
  }

  return (
    <DropdownFilterContainer className="dropdown_filter" onClick={() => setOpenFilter((prev) => !prev)}>
      {/* ___________________________________ ICON ELEMENT  */}
      <IconFilter className="dropdown_icon_filter">
        <Icons.dropdownFilter fill={AppStore.theme.o.black} stroke={AppStore.theme.o.black} />
      </IconFilter>

      {/* ___________________________________ TITLE ACTIVE FILTER */}
      <ActiveFilterText className="dropdown_filter_active_text dropdown_filter_conditional_show">
        {enumTypeTitle[forSection].filterTitle[activeFilter]}
      </ActiveFilterText>

      {/* ___________________________________ ARROW ICON  */}
      <IconArrow
        className={`dropdown_icon_arrow dropdown_filter_conditional_show ${
          openFilter ? 'arrow_point_bottom' : 'arrow_point_top'
        }`}
      >
        <Icons.dropdownArrow fill={AppStore.theme.o.black} />
      </IconArrow>

      {/* ___________________________________ DIVIDER PER HOVER  */}
      <Divider className="dropdown_filter_divider" />

      {/* ___________________________________ DROPDOWN ITEM */}
      <ContainerFilters
        className={`dropdown_filter_container dropdown_filter_conditional_show ${openFilter ? 'open' : ''}`}
      >
        {filterData.map((filter: IFilterDropdownData) => (
          <DropdownFilterButton onClick={() => onFilterClick(filter)} className="dropdown_filter_button">
            {[enumTypeTitle[forSection].filterTitle[filter.type]]}
          </DropdownFilterButton>
        ))}
      </ContainerFilters>
    </DropdownFilterContainer>
  )
}

const DropdownFilterContainer = styled(Box)`
  &.dropdown_filter {
    position: relative;
    height: 38px;
    width: 50px;
    cursor: pointer;
    padding: 16px 16px;
    border-radius: 14px;
    border: 1px solid ${() => AppStore.theme.o.grey};
    background: ${() => AppStore.theme.o.lightestGrey};
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: ${() => AppStore.theme.smallBoxShadow};

    .dropdown_filter_conditional_show {
      transition: 0ms;
      transition-delay: 0ms;
      visibility: 0;
      opacity: 0;
      pointer-events: none;
    }

    &:hover,
    &:active {
      width: 210px;

      .dropdown_filter_conditional_show {
        transition: 300ms ease-in-out;
        transition-delay: 300ms;
        visibility: 1;
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
`

const IconFilter = styled(Box)`
  &.dropdown_icon_filter {
  }
`

const ActiveFilterText = styled(Box)`
  &.dropdown_filter_active_text {
    color: ${() => AppStore.theme.o.black};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 4px;
  }
`

const IconArrow = styled(Box)`
  &.dropdown_icon_arrow {
    margin-left: auto;

    &.arrow_point_bottom {
      rotate: 0deg;
    }

    &.arrow_point_top {
      rotate: -180deg;
    }
  }
`

const Divider = styled(Box)`
  &.dropdown_filter_divider {
    background: transparent;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 8px;
  }
`

const ContainerFilters = styled(Box)`
  &.dropdown_filter_container {
    box-shadow: ${() => AppStore.theme.smallBoxShadow};
    border-radius: 14px;
    background: ${() => AppStore.theme.o.lightestGrey};
    padding: 0;
    width: 100%;
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    display: flex;
    flex-direction: column;
    gap: 4px;
    overflow: hidden;
    max-height: 0;

    &.open {
      padding: 8px;
      max-height: 500px;
    }
  }
`

const DropdownFilterButton = styled(Box)`
  &.dropdown_filter_button {
    color: ${() => AppStore.theme.o.black};
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 16px;
    border: 1px solid transparent;

    &:hover {
      background: ${() => AppStore.theme.o.surface};
      border: 1px solid ${() => AppStore.theme.o.grey};
    }
  }
`
