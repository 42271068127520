import styled from '@emotion/styled'
import { Empty } from 'antd'
import { Box } from 'components/Box'
import { CustomCheckbox } from 'components/UI'
import { truncateText } from 'pages/ai-discover/commonFunction'
import React from 'react'
import { AppStore, T } from 'utils'
import EmptySelectExtra from './EmptySelectExtra'
import { array } from 'prop-types'

interface Props {
  selectWith?: 'checkbox' | 'default'
  items?: any[]
  selectedItems: any
  onSelect: (arg: any) => void
  truncateTextNum?: number
}

const ExtraSelectDropdownItem = ({ selectWith = 'default', items, selectedItems, onSelect, truncateTextNum = 30 }) => {
  /* ---------------------- NO ITEM RETURN EMPTY  */
  if (!items || isObjectEmpty(items))
    return (
      <SearchResultsBox>
        <SearchInputs className="empty-select-item">
          <EmptySelectExtra emptyText={T.postPage.extraSelectNotAvailable} />
        </SearchInputs>
      </SearchResultsBox>
    )
  /* ---------------------- METHODS */
  // fatta una funzione perché soggetta ad hoisting
  function isObjectEmpty(obj) {
    if (obj.status) return true
    if (typeof obj !== 'object') return 1
    return Object.entries(obj).length === 0
  }

  /* ---------------------- SWITCH CASE - checkbox or default dropdownItem */
  const dropdownItem = () => {
    let elToDisplay = null
    switch (selectWith) {
      case 'default':
        elToDisplay = items.map((item) => {
          return (
            <SearchInputs className={selectedItems?.id === item.id ? 'selected' : ''} onClick={() => onSelect(item)}>
              <ItemName>{truncateText(item.title, truncateTextNum)}</ItemName>
            </SearchInputs>
          )
        })
        break
      case 'checkbox':
        elToDisplay = items.map((item) => (
          <SearchInputs key={item.id} onClick={() => onSelect(item)}>
            <CustomCheckbox
              checked={selectedItems.some((selectedItem) => selectedItem.id === item.id)}
              onChange={() => {}}
            />
            <ItemName>{truncateText(item.title, truncateTextNum)}</ItemName>
          </SearchInputs>
        ))
        break

      default:
        break
    }
    return elToDisplay
  }

  return <SearchResultsBox className="select-dropdown-container">{dropdownItem()}</SearchResultsBox>
}

export default ExtraSelectDropdownItem

const SearchResultsBox = styled(Box)`
  z-index: 1000;
  position: absolute;
  top: 120%;
  left: 0px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  max-height: 255px;
  padding: 8px 16px;
  margin: 0 auto;
  background-color: ${() => AppStore.theme.o.lightestGrey};
  border: 1px solid ${() => AppStore.theme.o.grey};
  border-radius: 14px;
  box-shadow: 4px 4px 32px 0px rgba(38, 42, 72, 0.05);

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`

const ItemName = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  text-align: left;

  font-family: Aktiv Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

const SearchInputs = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  height: 42px;
  border: 1px solid transparent;
  transition: 200ms ease-in-out;
  padding: 16px;

  &.selected {
    border: 1px solid ${() => AppStore.theme.o.grey};
    background-color: ${() => AppStore.theme.o.surface};
  }

  &:hover {
    border: 1px solid ${() => AppStore.theme.o.grey};
    background-color: ${() => AppStore.theme.o.surface};
  }
`
