import styled from '@emotion/styled'
import Auth from 'api/Auth'
import { Tracker } from 'api/Tracker'
import { Box, Icons } from 'components'
import React, { useEffect, useState } from 'react'
import { useModalNavigationStore } from 'stores/ModalNavigationStore'
import { useUserStore } from 'stores/UserStore'
import { AppStore, T } from 'utils'
import { TRACKEREVENTS } from 'utils/eventsTracker'
import PROFILE_PLACEHOLDER from '../assets/profile_placeholder.png'
import { TogglerUI } from './TogglerUI'
import { openFrill } from 'api/Utils'

type TSections =
  | 'quick-start-en'
  | 'quick-start-it'
  | 'help-support'
  | 'roadmap'
  | 'academy'
  | 'docs'
  | 'settings'
  | 'billing'
  | 'dark-mode'
  | 'logout'

export interface AccountConfigSection {
  id: TSections
  icon: JSX.Element
  title: string
  onClick: () => void
  toggler?: JSX.Element
  hasDivider: boolean
  isVisible: boolean
}

interface Props {}

export const HeaderButtonAccount: React.FC<Props> = () => {
  const [isOpen, setIsOpen] = useState(false)
  const accountRef = React.useRef<HTMLDivElement>(null)
  const { user } = useUserStore((s) => ({ user: s.user }))
  const language = user?.language ?? 'en'
  const isDarkTheme = AppStore.darkTheme

  const navigateTo = (parameter: string, addIt?: boolean) => {
    const url = addIt && user?.language === 'it' ? `${parameter}it/` : parameter
    window.open(url, '_blank')
  }

  const handleLogOut = () => {
    try {
      Auth.logout()
      Tracker.trackEvent(TRACKEREVENTS.LOGOUT_SUCCESSFUL, {})
      localStorage.clear()
    } catch (error) {
      Tracker.trackEvent(TRACKEREVENTS.LOGOUT_FAILED, { error_message: 'An error occurred. Please try again later' })
    }
  }

  const navigateInternal = (parameter: string) => {
    useModalNavigationStore.getState().setNavigatePage(parameter)
  }

  const handleClose = (e: MouseEvent) => {
    const elToCheck = e.target as Element
    const settings = accountRef?.current

    if (settings && !settings.contains(elToCheck)) setIsOpen(false)
  }

  useEffect(() => {
    if (isOpen) window.addEventListener('click', handleClose)
    return () => window.removeEventListener('click', handleClose)
  })

  const accountConfigSections: AccountConfigSection[] = [
    {
      isVisible: true,
      id: `quick-start-${language}`,
      icon: <Icons.flag fill={AppStore.theme.o.black} />,
      title: T.upperSettings.quickStart,
      onClick: () => {
        const url = new URL(window.location.href)
        if (language === 'it') url.searchParams.set('cb-eid', 'm9694')
        else url.searchParams.set('cb-eid', 'm9678')
        window.history.pushState({}, '', url.toString())
      },
      hasDivider: false,
    },
    {
      isVisible: true,
      id: 'help-support',
      icon: <Icons.circleQuestion fill={AppStore.theme.o.black} />,
      title: T.upperSettings.helpSupport,
      onClick: () => navigateTo('https://help.asters.ai/', true),
      hasDivider: false,
    },
    {
      isVisible: true,
      id: 'roadmap',
      icon: <Icons.roadmap fill={AppStore.theme.o.black} />,
      title: T.upperSettings.roadmap,
      onClick: openFrill,
      hasDivider: false,
    },
    {
      isVisible: true,
      id: 'academy',
      icon: <Icons.academy fill={AppStore.theme.o.black} />,
      title: T.upperSettings.academy,
      onClick: () => navigateTo(' https://academy.asters.ai/', true),
      hasDivider: false,
    },
    {
      isVisible: true,
      id: 'docs',
      icon: <Icons.docs fill={AppStore.theme.o.black} />,
      title: T.upperSettings.docs,
      onClick: () => navigateTo('https://docs.asters.ai/'),
      hasDivider: true,
    },
    {
      isVisible: true,
      id: 'settings',
      icon: <Icons.settingsSizer fill={AppStore.theme.o.black} />,
      title: T.upperSettings.settings,
      onClick: () => navigateInternal('/settings/account'),
      hasDivider: false,
    },
    {
      isVisible: user?.enterprise || user?.owner ? false : true,
      id: 'billing',
      icon: <Icons.billingSizer fill={AppStore.theme.o.black} />,
      title: T.upperSettings.billing,
      onClick: () => navigateInternal('/settings/subscription'),
      hasDivider: false,
    },
    {
      isVisible: true,
      id: 'dark-mode',
      icon: <Icons.darkModeSizer fill={AppStore.theme.o.black} />,
      title: T.upperSettings.darkMode,
      onClick: () => AppStore.changeTheme(),
      toggler: <TogglerUI isActive={isDarkTheme} />,
      hasDivider: true,
    },
    {
      isVisible: true,
      id: 'logout',
      icon: <Icons.logOutSizer fill={AppStore.theme.o.black} />,
      title: T.upperSettings.logout,
      onClick: () => handleLogOut(),
      hasDivider: false,
    },
  ]

  return (
    <HeaderButtonAccountContainer onClick={() => setIsOpen((prev) => !prev)} forwardedRef={accountRef} removeHover>
      <img
        src={useUserStore.getState().user?.profileImage ?? PROFILE_PLACEHOLDER}
        alt="profile"
        width={32}
        height={32}
        style={{ borderRadius: '50%' }}
      />

      {isOpen && (
        <AccountContent onClick={(e) => e.stopPropagation()} removeHover>
          {accountConfigSections.map(
            (section) =>
              section.isVisible && (
                <React.Fragment key={section.id}>
                  <Section
                    onClick={() => {
                      section.onClick()
                      setIsOpen(false)
                    }}
                    id={section.id}
                  >
                    {section.icon}
                    {section.title}

                    <Box ml={'auto'}>{section.toggler && section.toggler}</Box>
                  </Section>
                  {section.hasDivider && <Divider />}
                </React.Fragment>
              )
          )}
        </AccountContent>
      )}
    </HeaderButtonAccountContainer>
  )
}

const HeaderButtonAccountContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 14px;
  position: relative;

  &:hover {
    background-color: ${() => AppStore.theme.o.primary_container};
  }
`

const AccountContent = styled(Box)`
  position: absolute;
  top: calc(100% + 8px);
  right: -32px;
  width: 260px;
  background-color: ${() => AppStore.theme.o.lightestGrey};
  gap: 2px;
  padding: 16px;
  border: 1px solid ${() => AppStore.theme.o.lightGrey};
`

const Section = styled(Box)`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  font-weight: 600;
  color: ${() => AppStore.theme.o.black};
  font-size: 14px;
  gap: 8px;
  flex-direction: row;
  border: 1px solid transparent;

  &:hover {
    background-color: ${() => AppStore.theme.o.surface};
    border: 1px solid ${() => AppStore.theme.o.lightGrey};
  }
`

const Divider = styled(Box)`
  width: 100%;
  margin-top: 4px;
  margin-bottom: 4px;
  height: 1px;
  background-color: ${() => AppStore.theme.o.grey};
`
