/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { AppStore } from 'utils'
import { TMode } from '../TextPostContent'
import { useState } from 'react'
import { StatusTooltip } from './StatusTooltip'

/* ------------------- INTERFACES  */
interface Props {
  generationMode: TMode
}

export const GenerationStatus = ({ generationMode }: Props) => {
  /* ------------------- STATES  */
  // Booleano per definire se mostrare o meno il tooltip
  const [hover, setHover] = useState<boolean>(false)

  return (
    <GenerationStatusContainer>
      <StatusIcon onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <Icons.info fill={AppStore.theme.o.black} />
      </StatusIcon>

      <StatusTextContainer
        style={{
          color: generationMode === 'Learning mode' ? AppStore.theme.o.darkGrey : AppStore.theme.o.black,
        }}
      >
        <IconContainer>
          {generationMode === 'Learning mode' ? (
            <Icons.refresh fill={AppStore.theme.o.darkGrey} />
          ) : (
            <Icons.sun fill={AppStore.theme.o.black} />
          )}
        </IconContainer>
        <StatusText>{generationMode}...</StatusText>
      </StatusTextContainer>

      <StatusTooltipContainer style={{ display: hover ? 'block' : 'none' }}>
        <StatusTooltip />
      </StatusTooltipContainer>
    </GenerationStatusContainer>
  )
}

const GenerationStatusContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  position: relative;
`

const StatusIcon = styled(Box)`
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: ${() => AppStore.theme.o.lightestGrey};
  cursor: help;

  &:hover {
    background: ${() => AppStore.theme.o.lightGrey};
  }
`

const StatusTextContainer = styled(Box)`
  display: flex;
  height: 36px;
  padding: 8px 24px;
  border-radius: 14px;
  background: ${() => AppStore.theme.o.lightestGrey};
  flex-direction: row;
  align-items: center;
  gap: 4px;
`

const StatusText = styled(Box)`
  text-align: center;
  font-family: 'Aktiv Grotesk';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

const StatusTooltipContainer = styled(Box)`
  position: absolute;
  left: 34px;
  top: 0px;
`

const IconContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`
