import { AppStore } from 'utils'
import RequestNumber from '../RequestNumber'

/**
 *  ### apre il modal per la preview dell'articolo
 * @param article
 */
const onOpenWhatsappModal = (onForceUpdate, inputWidth = '100%') => {
  AppStore.openModal({
    id: 'whatsapp-number-modal',

    body: <RequestNumber type={'whatsappModal'} inputWidth={inputWidth} onForceUpdate={onForceUpdate} />,

    style: {
      minHeight: 234,
      maxHeight: 234,
      minWidth: 429,
      maxWidth: 429,
      zIndex: 102,
    },
  })
}

export { onOpenWhatsappModal }
