/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { darkTheme, lightTheme } from 'config/themes'
import html2canvas from 'html2canvas'
import { PDFDocument, rgb } from 'pdf-lib'
import { useNavigate, useParams } from 'react-router-dom'
import { useAnalyticsStore } from 'stores/AnalyticsStore'
import { AppStore } from 'utils'

interface Props {
  completeComponent?: boolean
}

export const DownloadReportButton = ({ completeComponent = false }: Props) => {
  const navigate = useNavigate()
  const { reportId } = useParams()
  const reportPreview = useAnalyticsStore((state) => state.reportPreview)

  const onClickMetricBtn = () => {
    const report = document.getElementsByClassName('asters-report-container')[0] as HTMLElement
    html2canvas(report).then(function (canvas) {
      const data = canvas.toDataURL()
      const img = document.createElement('img')
      img.setAttribute('download', 'image.png')
      embedImageInPdfAndDownload(data)
    })
  }

  const downloadFile = async (blob) => {
    const URL = window.URL.createObjectURL(blob)
    const el = document.createElement('a')
    el.download = `asters-report-${reportId}.pdf`
    el.href = URL
    el.click()
    window.URL.revokeObjectURL(URL)
  }

  const hexToRgb = (hex: string) => {
    return rgb(
      parseInt(hex.slice(1, 3), 16) / 255,
      parseInt(hex.slice(3, 5), 16) / 255,
      parseInt(hex.slice(5, 7), 16) / 255
    )
  }

  const embedImageInPdfAndDownload = async (buffer: any) => {
    const yOffset = 50
    const pdfDoc = await PDFDocument.create()
    // buffer image to base64
    const base64 = buffer.toString('base64')

    const image = await pdfDoc.embedPng(base64)
    const page = pdfDoc.addPage()

    const pageHeight = image.scale(0.5).height * 1.5
    const pageWidth = image.scale(0.5).width
    const imageHeight = image.scale(0.5).height

    page.setHeight(pageHeight)
    page.drawRectangle({
      x: 0,
      y: 0,
      width: pageWidth + 50,
      height: pageHeight,
      color: hexToRgb(AppStore.darkTheme ? darkTheme.o.lightestGrey : lightTheme.o.lightestGrey),
    })

    page.drawImage(image, {
      width: pageWidth,
      height: imageHeight,
      x: 25,
      y: page.getHeight() - imageHeight - yOffset,
    })

    let b64Chunk = await pdfDoc.saveAsBase64()
    b64Chunk = 'data:application/pdf;base64,' + b64Chunk
    const blob = await (await fetch(b64Chunk)).blob()
    downloadFile(blob)
  }

  return reportPreview ? (
    <AddMetricContainer onClick={onClickMetricBtn}>
      <Icons.download fill={AppStore.theme.o.black} width={20} height={20} />
    </AddMetricContainer>
  ) : null
}

const AddMetricContainer = styled(Box)`
  border-radius: 0px;
  display: flex;
  width: 44px;
  height: 40px;

  align-items: center;
  justify-content: center;
  color: ${() => AppStore.theme.o.black};
  background-color: ${() => AppStore.theme.o.lightestGrey};
  border: 1px solid ${() => AppStore.theme.o.grey};
  border-radius: 14px;
`
