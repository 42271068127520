/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { SocialPicture } from 'components/UI/SocialPicture'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { IVariantSocial } from 'types/PostInterface'
import { AppStore, showWarning } from 'utils'
import { getPostByAccountId } from 'utils/PostUtils/Post'
import { checkPostValidation } from 'utils/PostUtils/ValidationPost'

/* ------------------- INTERFACES  */
interface Props {
  account: IVariantSocial
}

export const AccountCheck = ({ account }: Props) => {
  /* ----------------- VARIABLES  */
  const { picture, provider } = account

  /* ----------------- ZUSTAND  */
  const { activePost, uniqueVariants, activeAccountId, hasClickedPublish } = useCreatePostStore((s) => ({
    posts: s.posts,
    activePost: s.activePost as any,
    uniqueVariants: s.uniqueVariants,
    activeAccountId: s.activeAccountId,
    hasClickedPublish: s.hasClickedPublish,
  }))

  /* ----------------- METHODS  */
  const checkIfAccountIsActive = (accountInfo: IVariantSocial) => {
    if (accountInfo === undefined) return false

    if (accountInfo.account_id === activeAccountId) return true

    const post = getPostByAccountId(accountInfo.account_id)

    let postVariant

    if (post === undefined) return false

    if ('_id' in post && post?._id === 'GLOBAL') postVariant === 'GLOBAL'
    if ('variantId' in post) postVariant = post.variantId

    if (activePost?._id === 'GLOBAL' && !uniqueVariants.includes(postVariant)) return true

    if (activePost?._id === 'GLOBAL' && uniqueVariants.includes(postVariant)) return false

    return false
  }

  const checkIfValid = (accountId: string, showProblems?: boolean) => {
    // Queste funzioni partono dopo che l'utente ha cliccato la prima volta la funzione publish
    if (!hasClickedPublish) return false

    const post = getPostByAccountId(accountId)
    let problems: string[] = []

    if (post === undefined) return true
    if ('_id' in post && post?._id === 'GLOBAL') return true

    if ('variantId' in post) problems = checkPostValidation(post) ?? []

    // per mostrare notifica dell'errore
    if (problems.length > 0) {
      // if (showProblems) {
      //   problems.forEach((problem, index) => {
      //     // Per non far comparire tutti i problemi insieme
      //     setTimeout(() => {
      //       showWarning(problem)
      //     }, 500 * index)
      //   })
      // }
      return true
    }

    return false
  }

  const isActive = checkIfAccountIsActive(account)
  const isValid = checkIfValid(account.account_id)

  return (
    <AccountCheckContainer
      style={{
        background: isActive ? AppStore.theme.o.lightGreen : isValid ? '#FFF0C2' : AppStore.theme.o.surface,
        borderColor: isValid ? '#E1631E' : isActive ? AppStore.theme.o.green : AppStore.theme.o.surface,
      }}
      onClick={() => {
        const { setActiveAccountId } = useCreatePostStore.getState()
        setActiveAccountId(account.account_id)

        checkIfValid(account.account_id, true)
      }}
      shadow={isActive ? AppStore.theme.smallOuterShadow : undefined}
    >
      <SocialPicture
        picture={picture}
        provider={provider}
        dimensionPicture={36}
        dimensionProvider={24}
        active={isActive}
      />

      {isValid && (
        <ContainerIconWarning>
          <Icons.postWarning />
        </ContainerIconWarning>
      )}
    </AccountCheckContainer>
  )
}

const AccountCheckContainer = styled(Box)`
  display: flex;
  padding: 12px 16px;
  cursor: pointer;
  width: 74px;
  height: 66px;
  border-radius: 16px;
  border: 1px solid;
  align-items: center;
  justify-content: center;
  position: relative;
`

const ContainerIconWarning = styled(Box)`
  position: absolute;
  top: -4px;
  right: -4px;

  border-radius: 4px;
  background: #fff0c2;
`
